// tslint:disable:max-line-length
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';
import { LangCodes } from '../../types/lang-codes.enum';

export interface ITemplateConfig {
  layout: {
    variant: string; // options: Dark, Light & Transparent
    menuPosition: string; // options: Side, Top (Note: Use 'Side' for Vertical Menu & 'Top' for Horizontal Menu )
    navbar: {
      type: string; // options: Static & Fixed
    };
    sidebar: {
      // Options for Vertical Side menu
      collapsed: boolean; // options: true, false
      size: string; // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
      backgroundColor: string; // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

      /**
       * If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option:
       * bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
       */
      backgroundImage: boolean; // Options: true, false | Set true to show background image
      backgroundImageURL: string;
    };
  };
  language?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public templateConf: ITemplateConfig = null;
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();

  SEARCH_DEBOUNCE_TIME = 500;
  MESSAGES_PER_PAGE = 20;
  THREADS_PER_PAGE = 30;
  MAX_IMAGE_FILE_SIZE = 52428800;
  MAX_FILE_SIZE = 1073741824; // 1GB
  MAX_LINED_UP_BOARD_MEMBERS = 4;
  MAX_LINED_UP_BOARD_MEMBERS_MOBILE = 2;
  ENDLESS_SCROLL_RATIO = 0.1;
  NOTIFICATION_SOUND = 'assets/sounds/blop.mp3';
  CALL_SOUND = 'assets/sounds/marimba.mp3';
  CUSTOM_RECENT_EMOJIS = [
    'grinning',
    'wink',
    'blush',
    'sweat_smile',
    'joy',
    'rolling_on_the_floor_laughing',
    'exploding_head',
    'heart_eyes',
    'star-struck',
    'face_with_rolling_eyes',
    'flushed',
    'stuck_out_tongue_winking_eye',
    'zany_face',
    '+1',
    '-1',
    'ok_hand',
    'wave',
    'handshake',
    'pray',
    'raised_hands',
    'muscle',
    'facepunch',
    'point_up_2',
    'white_check_mark',
    'heart',
    'eyes',
  ];

  constructor(private localStorage: LocalStorageService) {
    this.setConfigValue();
  }

  /**
   * Template configuration for dark theme
   */
  setConfigValue() {
    this.templateConf = {
      layout: {
        variant: this.localStorage.get('uiTheme') || 'Light',
        menuPosition: 'Side',
        navbar: {
          type: 'Static',
        },
        sidebar: {
          collapsed: false,
          size: 'sidebar-md',
          backgroundColor: 'man-of-steel',
          backgroundImage: false,
          backgroundImageURL: 'assets/img/sidebar-bg/01.jpg',
        },
      },
      language: this.localStorage.get(LocalStorageKeys.language) || LangCodes.english,
    };
    this.templateConfSubject.next(this.templateConf);
  }

  applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
    this.localStorage.set('uiTheme', tempConfig.layout.variant);

    if (tempConfig.language) {
      this.localStorage.set(LocalStorageKeys.language, tempConfig.language);
    }
  }
}
