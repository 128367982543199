<div [ngClass]="{
       'message-text-light': layoutVariant === 'Light',
       'message-text-dark': layoutVariant === 'Dark'
       }"
>
  <!-- gitlab merge request message -->
  <app-webhook-pr-message
    *ngIf="webhookMessageData?.eventName === GitLabEventTypes.MERGE_REQUEST_OPEN ||
           webhookMessageData?.eventName === GitLabEventTypes.MERGE_REQUEST_CLOSE ||
           webhookMessageData?.eventName === GitLabEventTypes.MERGE_REQUEST_MERGE"
    [repositoryName]="mrMessageData?.project?.name"
    [userName]="mrMessageData?.user?.username"
    [userUrl]="null"
    [prActionTitle]="mrActionTitle"
    [prTitle]="mrMessageData?.object_attributes?.title"
    [prDescription]="mrMessageData?.object_attributes?.description"
    [prNumber]="mrMessageData?.object_attributes?.iid"
    [prUrl]="mrMessageData?.object_attributes?.url"
    [sourceBranch]="mrMessageData?.object_attributes?.source_branch"
    [targetBranch]="mrMessageData?.object_attributes?.target_branch"
    [showDetails]="showMRDetails"
    [assignees]="mrMessageData?.assignees"
    [reviewers]="mrMessageData?.reviewers"
  ></app-webhook-pr-message>

  <!-- gitlab push message -->
  <app-webhook-push-message
    *ngIf="webhookMessageData?.eventName === GitLabEventTypes.PUSH"
    [pusherName]="pushMessageData?.user_username"
    [description]="pushMsgDescription"
    [repositoryName]="pushMessageData?.project?.path_with_namespace"
    [commitId]="headCommit?.id"
    [commitUrl]="headCommit?.url"
    [commitToFullPath]="fullBranchPath"
  ></app-webhook-push-message>

  <app-webhook-job-status
    *ngIf="webhookMessageData?.eventName === GitLabEventTypes.PIPELINE"
    [state]="statusLabel"
    [jobType]="jobTypeLabel"
    [pipeName]="pipeNumberLabel"
    [repoFullName]="pipeMsgData.project.path_with_namespace"
    [isFailed]="isPipeFailed"
  ></app-webhook-job-status>
</div>
