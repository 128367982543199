<div
  class="draft"
  [class.draft-dark]="isDarkTheme"
  (click)="draftService.openDraft(draft._id)"
>
  <svg-icon
    src="assets/icons/common/file-check.svg"
    [svgStyle]="{ 'width.px': '13', 'height.px': '14' }"
  ></svg-icon>
  <span class="draft-title">{{ nameDraft }}</span>
</div>
