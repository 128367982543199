import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TicketsCreate, TicketUpdateChecklist } from '../../../store/actions/board.action';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { CheckListItemsDbDto } from '../../../../api/models/check-list-items-db-dto';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BoardsState } from '../../../store/states/boards.state';
import { ToastrService } from 'ngx-toastr';
import { TicketData } from '../../../../modals/board-ticket/board-ticket';
import { PriorityEnum } from '../../../../pages/full-pages/board/kanban-board/enums/priority.enum';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss'],
})
export class ChecklistItemComponent implements OnInit, OnDestroy {
  @Input() item: Partial<CheckListItemsDbDto>;
  @Input() ticket: TicketData;
  destroy$: Subject<void> = new Subject<void>();

  public title;
  public status;
  constructor(private store: Store, private toastr: ToastrService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.title = new FormControl(this.item.text);
    this.status = new FormControl(this.item.isCompleted);
    this.status.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
      const checklist = this.store.selectSnapshot(BoardsState.getChecklist);
      const filterChecklist = checklist.map((item) => (item === this.item ? { ...item, isCompleted: value } : item));
      this.store.dispatch(new TicketUpdateChecklist(filterChecklist)).pipe(takeUntil(this.destroy$));
    });

    this.title.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe((value) => {
      const checklist = this.store.selectSnapshot(BoardsState.getChecklist);
      const filterChecklist = checklist.map((item) => (item === this.item ? { ...item, text: value } : item));
      this.store.dispatch(new TicketUpdateChecklist(filterChecklist)).pipe(takeUntil(this.destroy$));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  deleteItem(isConvert: boolean = false) {
    const checklist = this.store.selectSnapshot(BoardsState.getChecklist);
    const filterChecklist = checklist.filter((item) => item !== this.item);
    this.store
      .dispatch(new TicketUpdateChecklist(filterChecklist))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const message = isConvert
          ? this.translocoService.translate('toastr.checklist-item-converted')
          : this.translocoService.translate('toastr.checklist-item-deleted');
        this.toastr.success(message, this.translocoService.translate('toastr.title-success'));
      });
  }
  convertInTicket() {
    const columnList = this.store.selectSnapshot(BoardsState.getColumnsList);
    const tickets = this.store.selectSnapshot(BoardsState.getTicketsList);
    const todoColumnId = columnList.find((item) => item.title === 'TODO')?._id;
    const columnArchiveId = columnList.find((column) => column.title === 'ARCHIVE')._id;

    const ticketsInBoardLength = tickets.filter(
      (t) =>
        (!this.ticket.sprintId
          ? !t.sprintId && t.columnId !== this.ticket.objectId && t.columnId === todoColumnId
          : t.sprintId === this.ticket.sprintId) &&
        !t.isDeleted &&
        t.columnId !== columnArchiveId &&
        !t.isEpic,
    ).length;

    const ticketsInBacklogLength = tickets.filter(
      (t) =>
        (!this.ticket.sprintId
          ? !t.sprintId && t.columnId !== this.ticket.objectId
          : t.sprintId === this.ticket.sprintId) &&
        !t.isDeleted &&
        t.columnId !== columnArchiveId &&
        !t.isEpic,
    ).length;

    this.store
      .dispatch(
        new TicketsCreate({
          object: this.ticket.object,
          objectId: this.ticket.objectId,
          title: this.title.value,
          epicId: this.ticket.data.epicId || 'null',
          sprintId: this.ticket.data.sprintId,
          priority: PriorityEnum.Medium,
          order: ticketsInBoardLength,
          orderInBacklog: ticketsInBacklogLength,
          columnId: todoColumnId,
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.deleteItem(true);
      });
  }
}
