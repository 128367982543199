import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  OnChanges,
} from '@angular/core';

import { UsersDbDto } from '../../../api/models/users-db-dto';
import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { DocumentsService } from '../../services/documents.service';
import { RolesGet } from '../../store/actions/roles.action';
import { SpaceGetUsersList } from '../../store/actions/spaces.action';
import { ProjectGetUsersList } from '../../store/actions/projects.action';
import { DocPermissionsGet } from '../../store/actions/documents-permissions.action';
import { AuthState } from '../../store/states/auth.state';
import { RolesState } from '../../store/states/roles.state';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { DocumentsPermissionsState } from '../../store/states/documents-permissions.state';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-access-control-documents',
  templateUrl: './access-control-documents.component.html',
  styleUrls: ['./access-control-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessControlDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() object: string;
  @Input() objectId: string;
  @Input() access: string;
  @Input() invitedMembers: string[];
  @Input() invitedRoles: string[];
  @Input() document: any;
  @Input() documentType: 'file' | 'folder' | 'link';
  @Output() accessChanged = new EventEmitter<string>();
  @Output() invitedMembersChanged = new EventEmitter<string[]>();
  @Output() invitedRolesChanged = new EventEmitter<string[]>();

  accessViews = [
    {
      value: 'public',
      label: this.translateService.translate('data-room.public-success'),
    },
    {
      value: 'private',
      label: this.translateService.translate('data-room.private-success'),
    },
  ];

  destroy$: Subject<void> = new Subject<void>();

  user: UsersPublicFieldsResDto;
  users: Observable<any[]>;
  roles: Observable<any[]>;

  constructor(
    private store: Store,
    private documentsService: DocumentsService,
    private translateService: TranslocoService,
  ) {}

  ngOnInit() {
    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.user = res));

    if (this.document?.type === 'private') {
      this.access = 'private';
      this.store.dispatch(
        new DocPermissionsGet({
          documentId: this.document['_id'],
          type: this.documentsService.documentIs(this.document).toLowerCase(),
        }),
      );

      this.store
        .select(DocumentsPermissionsState.getDocPermissions)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          setTimeout(() => {
            const invitedMembers = res.permittedUsers
              ? res.permittedUsers.filter((item) => item !== (this.user._id as string))
              : [];
            this.invitedMembersChanged.emit(invitedMembers);
            this.invitedRolesChanged.emit(res.permittedRoles);
          });
        });

      this.getUsersList(this.objectId);
      this.getRolesList(this.object);
    }
  }

  ngOnChanges() {
    this.getUsersList(this.objectId);
    this.getRolesList(this.object);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get capitalizeFirstLetter() {
    return this.documentType?.charAt(0).toUpperCase() + this.documentType?.slice(1);
  }

  membersChange() {
    this.invitedMembersChanged.emit(this.invitedMembers);
  }

  rolesChange() {
    this.invitedRolesChanged.emit(this.invitedRoles);
  }

  accessChange() {
    this.accessChanged.emit(this.access);
    this.users = null;
    if (this.access === 'private') {
      this.getUsersList(this.objectId);
      this.getRolesList(this.object);
    }
  }

  getUsersList(objectId: string = null) {
    let users$: Observable<UsersDbDto[]>;
    if (this.object === 'spaces') {
      this.store.dispatch(new SpaceGetUsersList({ id: objectId, exists: true }));
      users$ = this.store.select(SpacesState.getUsers);
    } else if (this.object === 'projects') {
      this.store.dispatch(new ProjectGetUsersList({ id: objectId, exists: true }));
      users$ = this.store.select(ProjectsState.getUsers);
    }

    this.users = users$?.pipe(
      map((users: []) =>
        users?.filter((item: any) => this.user._id !== item['_id'] && !(item.isAssistant || item.userName === 'Quinn')),
      ),
    );
  }

  getRolesList(object: string = null) {
    this.store.dispatch(new RolesGet());
    this.roles = this.store
      .select(RolesState.getRoles)
      .pipe(map((items) => items.filter((item) => item.object === object)));
  }
}
