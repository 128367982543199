/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { HealthService } from './services/health.service';
import { BoardsService } from './services/boards.service';
import { BoardsSprintsService } from './services/boards-sprints.service';
import { BoardsFieldsService } from './services/boards-fields.service';
import { BoardsTicketsService } from './services/boards-tickets.service';
import { BoardsSettingsService } from './services/boards-settings.service';
import { BoardsDraftsService } from './services/boards-drafts.service';
import { SpacesService } from './services/spaces.service';
import { TenantsService } from './services/tenants.service';
import { TenantsSettingsService } from './services/tenants-settings.service';
import { ChatsService } from './services/chats.service';
import { CalendarEventsService } from './services/calendar-events.service';
import { ProjectsService } from './services/projects.service';
import { AuthService } from './services/auth.service';
import { TfaOptionsService } from './services/tfa-options.service';
import { TfaQuestionsService } from './services/tfa-questions.service';
import { ActionsLogsService } from './services/actions-logs.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { NotesService } from './services/notes.service';
import { UsersService } from './services/users.service';
import { EmojisService } from './services/emojis.service';
import { VideoCallsService } from './services/video-calls.service';
import { WikiPagesService } from './services/wiki-pages.service';
import { NotepadsService } from './services/notepads.service';
import { FilesService } from './services/files.service';
import { BillingDetailsService } from './services/billing-details.service';
import { SubscriptionsService } from './services/subscriptions.service';
import { PaymentsService } from './services/payments.service';
import { RolesService } from './services/roles.service';
import { WebhooksService } from './services/webhooks.service';
import { LaneBoardsService } from './services/lane-boards.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    HealthService,
    BoardsService,
    BoardsSprintsService,
    BoardsFieldsService,
    BoardsTicketsService,
    BoardsSettingsService,
    BoardsDraftsService,
    SpacesService,
    TenantsService,
    TenantsSettingsService,
    ChatsService,
    CalendarEventsService,
    ProjectsService,
    AuthService,
    TfaOptionsService,
    TfaQuestionsService,
    ActionsLogsService,
    PushNotificationsService,
    NotesService,
    UsersService,
    EmojisService,
    VideoCallsService,
    WikiPagesService,
    NotepadsService,
    FilesService,
    BillingDetailsService,
    SubscriptionsService,
    PaymentsService,
    RolesService,
    WebhooksService,
    LaneBoardsService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
