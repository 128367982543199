import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';

import { AuthState } from '../../shared/store/states/auth.state';
import { DeleteChatGptApiKey, SetChatGptApiKey } from '../../shared/store/actions/tenant-settings.action';
import { ChatsGetMembers } from '../../shared/store/actions/chats.action';
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-gpt-settings-modal',
  styleUrls: ['chat-gpt-settings.component.scss'],
  templateUrl: 'chat-gpt-settings.component.html',
})
export class ChatGptSettingsModalComponent implements OnInit {
  apiKey = new FormControl(null);

  isConnected = false;
  isUploading = false;
  platform: string;
  chatId: string;
  config: ITemplateConfig;

  constructor(
    private store: Store,
    private activeModal: NgbActiveModal,
    private configService: ConfigService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);

    if (!!this.apiKey.value) {
      this.isConnected = !!this.apiKey.value;
      this.apiKey.disable({ onlySelf: true });
    }
  }

  get settingsTitle() {
    return this.isConnected
      ? this.translocoService.translate('modals.chat-gpt-settings.chat-gpt')
      : this.translocoService.translate('modals.chat-gpt-settings.connect-chat-gpt');
  }

  get isDarkMode() {
    return this.config?.layout.variant === 'Dark';
  }

  close() {
    this.activeModal.close();
  }

  onInviteCompleted() {
    if (this.chatId) {
      this.store.dispatch(new ChatsGetMembers(this.chatId));
    }
  }

  saveChatGptApiKey() {
    this.store
      .dispatch(new SetChatGptApiKey({ openAiApiKey: this.apiKey.value }))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onInviteCompleted();
        this.activeModal.close();
      });
  }

  removeChatGptApiKey() {
    this.store
      .dispatch(new DeleteChatGptApiKey())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onInviteCompleted();
        this.activeModal.close();
      });
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }
}
