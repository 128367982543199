<!-- image cropper modal -->
<ng-container *transloco="let t; read: 'modals.webhook-create-update'">
  <ng-template #imageCropperModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title">{{ t('edit-image-title') }}</h5>
      <button type="button"
              class="close"
              aria-label="Close"
              (click)="closeCropperModal()"
      >
        <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
      </button>
    </div>
    <div class="modal-body">
      <app-image-cropper
        [image]="imageChangedEvent"
        [aspectRatio]="1"
        [resizeToWidth]="128"
        (avatarImageFile)="setFile($event)"
        (avatarImagePreview)="setImagePrev($event)"
        (closeCropperModal)="closeCropperModal()"
      ></app-image-cropper>
    </div>
  </ng-template>

  <input
    #avatarImageInput
    type="file"
    style="display: none"
    accept=".jpg, .jpeg, .png"
    (change)="avatarChangeEvent($event)"
    t-id="avatar-image-show-edit-upload-file"
  />

  <div class="d-flex flex-column justify-content-center align-items-center mt-2">
    <div
      class="avatar-image-area d-flex flex-column justify-content-center align-items-center"
      *ngIf="!avatarImagePreview"
      (click)="avatarImageInput.click()"
    >
      <div class="image-area-icon">
        <i class="fa fa-image"></i>
      </div>
      <div class="avatar-image-area-text">{{ t('avatar-image') }}</div>
    </div>

    <i
      class="fa fa-times-circle avatar-image-remove-icon"
      *ngIf="avatarImagePreview"
      (click)="deleteAvatarImage()"
    ></i>
    <img
      *ngIf="avatarImagePreview"
      [src]="avatarImagePreview"
      class="avatar-image-preview"
      alt="Webhook avatar Image"
      width="160"
      height="160"
      (click)="avatarImageInput.click()"
    />
  </div>
</ng-container>
