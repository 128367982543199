<section class="wiki__header" [class.dark]="isDark" *transloco="let t; read: 'wiki'">
  <div class="wiki__header-info">
    <div class="wiki__header-title">{{title}}</div>
    <div class="wiki__header-date">
      <p>{{ t('created-by', { date }) }} <span (click)="openUserProfile()">{{userName}}</span></p>
      <app-avatar [userId]="userId" [width]="24" [height]="24"></app-avatar>
    </div>
  </div>
  <div class="wiki__header-actions">
    <div class="wiki__header-container" ngbTooltip="{{ t('tooltip-open-thread') }}" placement="bottom" (click)="toggleThread()">
      <svg-icon *ngIf="!wikiService.isLoadingThreadCreate" [src]="threadSvg"  class="wiki__header-thread"></svg-icon>
      <i *ngIf="wikiService.isLoadingThreadCreate" class="fa fa-spinner fa-spin connection_spinner mr-1" aria-hidden="true"></i>
      <span class="wiki__header-counter">{{wikiService.totalCount}}</span>
    </div>
    <svg-icon [src]="linkSvg" (click)="copyLink()" class="wiki__header-link" ngbTooltip="{{ t('tooltip-copy-link') }}" placement="bottom"></svg-icon>
    <button type="button" class="btn btn-solid btn-save" (click)="toggleEditMode()">{{ t('btn-edit') }}</button>
  </div>
</section>
