import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { BoardTicketModalComponent } from '../../../../../modals/board-ticket/board-ticket.component';
import { GenericMessageComponent } from '../generic-message/generic-message.component';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';
import { FilesHelper } from '../../../../utils/files-helper';
import { ConfigService } from '../../../../services/config.service';
import { ChatsTicketTooltip } from '../../../../store/actions/chats.action';
import { takeUntil } from 'rxjs/operators';
import { MinimizeService } from '../../../../services/minimize.service';
import { DraftService } from '../../../../services/draft.service';
import { ChatService, ChatTypeEnum } from '../../chat.service';
import { ImageService } from '../../../../services/image.service';

@Component({
  selector: 'app-text-message',
  templateUrl: './text-message.component.html',
  styleUrls: ['./text-message.component.scss'],
  providers: [SafeHtmlPipe, ToastrService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextMessageComponent extends GenericMessageComponent implements OnInit, AfterViewInit {
  @ViewChildren('textMessage') textMessageChildren: QueryList<ElementRef>;
  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target.matches('.highlight-chat-tickets')) {
      // close tooltip - linked ticket number
      this.store.dispatch(
        new ChatsTicketTooltip({
          tooltipTop: 0,
          tooltipLeft: 0,
          tooltipMessage: '',
          showTicketTooltip: false,
        }),
      );

      // open board modal - linked ticket number
      this.viewTicket(event.target.innerText);
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event) {
    if (event.target.matches('.highlight-chat-tickets')) {
      let tooltipWidth;
      let LeftToRight = true;
      const ticketNumber = event.target.innerText;
      const elementOffset = this.getOffset(event.target);
      const tooltipTop = elementOffset.top.toFixed(0) - 45;
      const tooltipLeft = elementOffset.left.toFixed(0) - 5;

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const viewLeftSpace = vw - tooltipLeft;

      if (viewLeftSpace < 400) {
        LeftToRight = false;
      } else if (viewLeftSpace < 700) {
        tooltipWidth = 370;
      }

      // show tooltip - linked ticket number
      this.store.dispatch(
        new ChatsTicketTooltip({
          tooltipTop,
          tooltipLeft,
          tooltipRight: viewLeftSpace - 40,
          tooltipTitle: this.getTicket(ticketNumber.trim()).title,
          showTicketTooltip: true,
          tooltipWidth,
          LeftToRight,
        }),
      );
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event) {
    if (event.target.matches('.highlight-chat-tickets')) {
      // close tooltip - linked ticket number
      this.store.dispatch(new ChatsTicketTooltip({ showTicketTooltip: false }));
    }
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    cdr: ChangeDetectorRef,
    configService: ConfigService,
    store: Store,
    public filesHelper: FilesHelper,
    private toastr: ToastrService,
    private modal: NgbModal,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private chatService: ChatService,
    private imageService: ImageService,
    private translocoService: TranslocoService,
  ) {
    super(cdr, filesHelper, configService, store);
  }

  ngOnInit(): void {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.markForCheck();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.chatService.currentPage && this.chatService.currentPage <= 1) {
      const children = this.textMessageChildren?.last?.nativeElement?.children[0]?.children;
      if (children?.length) {
        for (const item of children) {
          if (item instanceof HTMLImageElement) {
            this.imageService.imageLoading(item as HTMLElement, this.chatType);

            item.onload = () => {
              setTimeout(() => {
                this.imageService.imageLoadedOrError(item as HTMLElement, this.chatType);
              }, 100);
            };

            item.onerror = () => {
              setTimeout(() => {
                this.imageService.imageLoadedOrError(item as HTMLElement, this.chatType);
              }, 100);
            };
          }
        }
      }
    }
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  isEmojisOnly = (message) => {
    const emojisRegex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\u2764-\uFE0F]|[\u1F92-\u200D-\u2642-\uFE0F])/g;

    if (!message) {
      return;
    }

    return (
      message
        .replace(emojisRegex, '')
        .replace(/\s/g, '')
        .replace(/(<([^>]+)>)/gi, '').length === 0
    );
  };

  viewTicket(ticketNumber) {
    const ticket = this.getTicket(ticketNumber.trim());

    if (ticket) {
      const { object, objectId, _id } = ticket;
      const draft = this.draftService.isHasActiveDraft(_id);

      if (draft) {
        this.draftService.openTicketOrDraft(draft, {
          id: _id,
          object,
          objectId,
        });
      } else {
        const modalRef = this.modal.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          scrollable: true,
          keyboard: false,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = {
          id: _id,
          object,
          objectId,
        };

        this.minimizeService.minimizeInit(modalRef);
      }
    } else {
      this.toastr.error(
        this.translocoService.translate('toastr.ticket-not-found', { ticketNumber }),
        this.translocoService.translate('toastr.title-error'),
      );
    }
  }

  getTicket(ticketNumber) {
    return this.message.chatTickets
      .filter((item) => item.boardAbbreviation + '-' + item.counter === ticketNumber)
      .pop();
  }
}
