import {
  EventEmitter,
  ChangeDetectorRef,
  forwardRef,
  Component,
  Input,
  Output,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnInit,
} from '@angular/core';
import {
  getSeconds,
  getMinutes,
  getHours,
  getDate,
  getMonth,
  getYear,
  setSeconds,
  setMinutes,
  setHours,
} from 'date-fns';
import { NgbDateStruct, NgbTimeStruct, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { REGEXP_TIME_WITH_COLON, REGEXP_TIME_WITHOUT_COLON } from '../../data/regexp';
import * as moment from 'moment-timezone';
import { separateFormatPicker } from '../customize-ticket-fields/const/separeteDate';
import { CustomDateParserFormatter } from './date-time-picker.service';

export const DATE_TIME_PICKER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    DATE_TIME_PICKER_CONTROL_VALUE_ACCESSOR,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() allDay: boolean;
  @Input() changeEvent?: void;
  @Input() mobile: boolean;
  @Input() mobileCalendarEvent: boolean;
  @Input() startOrEnd?: 'start' | 'end';
  @Input() tabIndexes: string[];
  @Input() invitedUser: boolean;
  @Input() dateControlOnly = false;
  @Input() isDisabled: boolean;
  @Input() withoutDefaultTime: boolean;
  @Input() allDayEvent: boolean;
  @Input() format = 'DD.MM.YYYY HH:mm';
  @Input() scrollToDatePicker = true;
  @Input() isPastDateDisabled = false;
  @Input() pastDateStartFrom = new Date().toISOString();

  @Output() clicked: EventEmitter<string> = new EventEmitter(null);
  @Output() setStartTimeInputsValid = new EventEmitter<boolean>();
  @Output() setEndTimeInputsValid = new EventEmitter<boolean>();
  @Output() dateWithoutTime = new EventEmitter<boolean>();
  @Output() setIsPastDate = new EventEmitter<boolean>();

  @ViewChild('datePicker') datePicker: NgbInputDatepicker;

  date: Date;
  dateStruct: NgbDateStruct;
  time: string;
  timeStruct: NgbTimeStruct;
  dateFormat: string;
  isPastDate: boolean;
  initCompleted = false;

  private onChangeCallback: (date: Date) => void = () => {};

  constructor(private cdr: ChangeDetectorRef, private picker: NgbDateParserFormatter) {}

  ngOnInit() {
    this.dateFormat = this.format ? separateFormatPicker(this.format) : '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.readonly?.currentValue) {
      this.readonly = this.readOnly;
    }
    this.handleAllDayChanges(changes);
    this.handlePastDateStartFromChanges(changes);
  }

  ngAfterViewInit() {
    if (this.mobileCalendarEvent) {
      setTimeout(() => {
        this.mobileCalendarEvent = false;
      }, 1000);
      this.cdr.detectChanges();
    }

    if (this.allDayEvent) {
      this.clearTimeField();
    }

    if (this.format) {
      // @ts-ignore
      this.picker.setFormat(this.dateFormat[0]);
    }

    this.setMinDate();
    this.initCompleted = true;
  }

  inputFocused(elem): void {
    if (this.scrollToDatePicker) {
      setTimeout(() => {
        elem.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }, 1000);
    }
  }

  clearTimeField(): void {
    this.time = '';
    this.updateTime(this.time);
  }

  writeValue(date: Date): void {
    if (!this.withoutDefaultTime) {
      this.date = date;
      this.dateStruct = {
        day: getDate(date),
        month: getMonth(date) + 1,
        year: getYear(date),
      };
      this.timeStruct = {
        second: getSeconds(date),
        minute: getMinutes(date),
        hour: getHours(date),
      };

      const dateTime = date ? new Date(date) : '';
      this.time = dateTime ? `${this.toFixedTime(getHours(dateTime))}:${this.toFixedTime(getMinutes(dateTime))}` : '';
      if (this.format && dateTime) {
        this.time = moment(this.time, ['hh:mm']).format(this.dateFormat[1]);
      }

      if (!this.mobileCalendarEvent) {
        if (this.startOrEnd === 'start') {
          this.setStartTimeInputsValid.emit(true);
          this.setEndTimeInputsValid.emit(true);
        } else if (this.startOrEnd === 'end') {
          this.setEndTimeInputsValid.emit(true);
        }
      }
      this.cdr.detectChanges();
    }
  }

  get readOnly() {
    return Boolean(this.time?.match(REGEXP_TIME_WITH_COLON));
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  updateDate(): void {
    if (this.dateStruct !== null && typeof this.dateStruct === 'object') {
      this.date = this.prepareDate();
      this.onChangeCallback(this.date);
    } else {
      this.date = null;
      this.onChangeCallback(this.date);
    }

    if (!this.time) {
      this.dateWithoutTime.emit(true);
    }

    this.changedInputValue();
  }

  updateTime(time): void {
    this.time = time;
    if (this.time === '') {
      this.updateDate();
    } else if (!this.time.match(REGEXP_TIME_WITH_COLON) && !this.time.match(REGEXP_TIME_WITHOUT_COLON)) {
      this.onChangeCallback(null);
      if (this.startOrEnd === 'start') {
        this.setStartTimeInputsValid.emit(false);
      } else if (this.startOrEnd === 'end') {
        this.setEndTimeInputsValid.emit(false);
      }
    } else {
      const timeStruct = this.createTimeStruct(time);
      const newDate: Date = setHours(
        setMinutes(setSeconds(this.date, timeStruct.second), timeStruct.minute),
        timeStruct.hour,
      );

      if (this.startOrEnd === 'start') {
        this.setStartTimeInputsValid.emit(true);
        this.setEndTimeInputsValid.emit(true);
        this.dateWithoutTime.emit(false);
      } else if (this.startOrEnd === 'end') {
        this.setEndTimeInputsValid.emit(true);
        this.dateWithoutTime.emit(false);
      }
      this.onChangeCallback(newDate);
    }

    if (time.match(REGEXP_TIME_WITHOUT_COLON)) {
      this.time = `${time.substring(0, 2)}:${time.substring(2, time.length)}`;
    }
    const checkTimeFormat = this.format && this.dateFormat[1].includes('A') && this.time.length === 7;

    if (checkTimeFormat) {
      this.time = this.time.slice(0, 4);
    }

    const timeChar: number[] = this.time.split('').map(Number);

    const changedChars = timeChar.map((char, index) => {
      if (index === 0 && char > 2) {
        return 2;
      } else if (index === 1 && timeChar[0] > 1 && char > 3) {
        return 3;
      } else if (index === 2 && char && char > 5) {
        return 5;
      } else if (index === 3 && char > 5) {
        return 5;
      } else if (isNaN(char)) {
        return ':';
      }
      return char;
    });

    this.time = changedChars.join('');

    if (this.format && this.time.length === 5) {
      this.time = moment(this.time, ['hh:mm']).format(this.dateFormat[1]);
    }
    this.cdr.detectChanges();
    this.changedInputValue();
  }

  createTimeStruct(time): NgbTimeStruct {
    const timeStruct: NgbTimeStruct = {
      hour: 0,
      minute: 0,
      second: 0,
    };

    if (time.match(REGEXP_TIME_WITH_COLON)) {
      timeStruct.hour = Number(time.substring(0, 2));
      timeStruct.minute = Number(time.substring(3, time.length));
    } else if (time.match(REGEXP_TIME_WITHOUT_COLON)) {
      timeStruct.hour = Number(time.substring(0, 2));
      timeStruct.minute = Number(time.substring(2, time.length));
    }

    return timeStruct;
  }

  toFixedTime(time) {
    return time < 10 ? '0' + time : time;
  }

  onClicked(dataTabindex: string, type = null): void {
    if (!this.isDisabled) {
      if (type === 'date') {
        this.datePicker.toggle();
      }
      this.clicked.emit(dataTabindex);
    }
  }

  getPartOfDate(date?: string) {
    const preparedDate = date ? moment(date) : moment();
    return preparedDate
      .format('DD-MM-YYYY')
      .split('-')
      .map((value) => Number(value));
  }

  changedInputValue() {
    if (this.isPastDateDisabled) {
      this.isPastDate = this.checkIsPastDate();
      this.setIsPastDate.emit(this.isPastDate);
    }
  }

  prepareDate() {
    const date = moment(`${this.dateStruct?.year}-${this.dateStruct?.month}-${this.dateStruct?.day}`, 'YYYY-MM-DD');
    if (this.time) {
      let abr;
      const [hours, minutesWithAbr] = this.time.split(':');
      const formattedHours = hours.substring(0, 2);

      if (minutesWithAbr) {
        const [minutes, timeAbr] = minutesWithAbr.split(' ');
        abr = timeAbr;
        date.add(minutes, 'minutes');
      }

      const hoursInDigits = Number(formattedHours);
      let updatedHours = formattedHours;

      if ((abr === 'PM' && hoursInDigits !== 12) || (abr === 'AM' && hoursInDigits === 12)) {
        updatedHours = `${hoursInDigits + 12}`;
      }

      date.add(updatedHours, 'hours');
    }

    return date.toDate();
  }

  checkIsPastDate() {
    const dateToCheck = this.prepareDate();
    return !this.date || moment(dateToCheck).diff(moment(this.pastDateStartFrom), 'seconds') <= 0;
  }

  handleAllDayChanges(changes: SimpleChanges) {
    if (changes?.allDay && this.startOrEnd === 'end') {
      this.editEndDayIfNecessary();
    }
  }

  handlePastDateStartFromChanges(changes: SimpleChanges) {
    if (changes?.pastDateStartFrom?.currentValue) {
      this.setMinDate();
    }
  }

  setMinDate() {
    if (this.datePicker && this.isPastDateDisabled) {
      const [day, month, year] = this.getPartOfDate(this.pastDateStartFrom);
      this.datePicker.minDate = { day, month, year };
      this.editEndDayIfNecessary();
    }
  }

  editEndDayIfNecessary() {
    if (this.initCompleted) {
      if (this.checkIsPastDate()) {
        // in case when start date greater than end date we change end date
        const newInputDate = moment(this.pastDateStartFrom).add(30, 'minutes').toDate();
        this.writeValue(newInputDate);
        this.onChangeCallback(newInputDate);
      }
      this.changedInputValue();
    }
  }
}
