<!-- data room modal -->
<ng-template #dataRoomModalTemplate let-c="close" let-d="dismiss">
  <app-chat-dataroom-location
    [platform]="platform"
    [object]="object"
    [objectId]="objectId"
    [file]="selectedFileToSaveInDataRoom"
    [messageType]="selectedMessageType"
    (closeDataRoomModal)="closeDataRoomModal()"
  ></app-chat-dataroom-location>
</ng-template>

<!-- image from text full-size preview modal -->
<ng-template #imagePreviewModal let-c="close" let-d="dismiss">
  <app-media-preview
    [platform]="platform"
    [tz]="currTzAbbr"
    [media]="messagesMedia"
    [object]="object"
    [objectId]="objectId"
    [currentMediaPath]="currentMediaPath"
    (closeMediaPreview)="closeImagePreview()"
  ></app-media-preview>
</ng-template>

<div #messagesContainer class="chats h-100" [class.open-pinned]="isOpenPinned && !isThread && isNotDirectMessage" appDragDrop
     [component]="'Chats'"
     (draggingFile)="setIsDraggingFile(true)" *transloco="let t; read: 'chat'">
  <app-drag-drop-view
    [component]="'Chats'"
    [isDraggingFile]="isDraggingFile"
    (draggingFile)="setIsDraggingFile($event)"
    (FileDropped)="fileDropped($event)"></app-drag-drop-view>
  <ng-scrollbar
    #scrollableMessages="ngScrollbar"
    class="scroll-event"
    thumbClass="white-scrollbars"
    [visibility]="'always'"
    (reachedTop)="scrolledToEnd()"
    (reachedBottom)="scrolledToEnd()"
  >
    <div #chatMessagesWrapper class="chat-messages" t-id="chat-message-scrolling" (click)="openImage($event)">
      <div class="main-message-wrapper" *ngIf="!isPreview && mainMessage && mainMessage.userId && (readOnly || (!readOnly && numberOfReplies > 0))">
        <div id="main-message{{readOnly ? '' : '-separate'}}" class="main-message">
          <div class="chat-avatar">
            <app-avatar *ngIf="!mainMessage.webhookId" [userId]="mainMessage.userId" [width]="36" [height]="36"></app-avatar>
            <app-webhook-avatar
              *ngIf="mainMessage.webhookId"
              [width]="36"
              [height]="36"
              [emojiFontSize]="30"
              [name]="mainMessage.webhookName"
              [webhookId]="mainMessage.webhookId"
              [chatId]="mainMessage.chatId"
            ></app-webhook-avatar>
          </div>

          <div class="chat-body">
            <div class="chat-content">
              <app-chat-message-details [message]="mainMessage" [tz]="currTzAbbr"></app-chat-message-details>

              <app-chat-message-content
                [platform]="platform"
                [object]="object"
                [objectId]="objectId"
                [message]="mainMessage"
                [mentionChatMembers]="mentionChatMembers"
                context="thread-message"
                [isThreadCore]="true"
                (imageClicked)="openImagePreview($event, true)"
              ></app-chat-message-content>

              <div *ngIf="mainMessage?.linkObject || getUrl(mainMessage)" class="with-linked-object d-flex">
                <!-- VIEW URL TICKET -->
                <ng-container *ngFor="let urlTicket of mainMessage.ticketsUrlData" >
                  <app-linked-object-badge
                    [platform]="platform"
                    urlReplace="ticket"
                    [message]="mainMessage"
                    [data]="urlTicket"
                    [tz]="currTzAbbr"
                    [withView]="true"
                    [disabledAction]="!hasPermissionToTicket(urlTicket)"
                    class="mr-2"
                  ></app-linked-object-badge>
                </ng-container>
                <!-- VIEW URL TICKET -->

                <!-- VIEW URL WIKI -->
                <ng-container *ngFor="let wiki of mainMessage.wikiPagesUrlData" >
                  <app-linked-object-badge
                    [object]="object"
                    [objectId]="objectId"
                    [platform]="platform"
                    urlReplace="wiki"
                    [message]="mainMessage"
                    [data]="wiki"
                    [tz]="currTzAbbr"
                    [withView]="true"
                    [disabledAction]="!hasPermissionToTicket(wiki)"
                    class="mr-2"
                  ></app-linked-object-badge>
                </ng-container>
                <!-- VIEW URL WIKI -->

                <!-- VIEW FILES -->
                <div *ngIf="getIsFilesObject(mainMessage)">
                  <app-message-files [isMobile]="isMobile" [message]="mainMessage" [platform]="platform" [object]="object" [objectId]="objectId"></app-message-files>
                </div>
                <!-- VIEW FILES -->

              </div>


              <div *ngIf="mainMessage.pollId">
                <app-message-poll [message]="mainMessage"></app-message-poll>
              </div>


              <div class="shared-message" *ngIf="mainMessage.sharedMessage">
                <div><i class="fa fa-share"></i></div>
                <blockquote>
                  <div class="chat-avatar">
                    <app-avatar [userId]="mainMessage.sharedMessage.userId" [width]="36" [height]="36"></app-avatar>
                  </div>

                  <app-chat-message-details [message]="mainMessage.sharedMessage" [tz]="currTzAbbr"></app-chat-message-details>

                  <app-chat-message-content
                    [object]="object"
                    [objectId]="objectId"
                    [platform]="platform"
                    [message]="mainMessage.sharedMessage"
                    [mentionChatMembers]="mentionChatMembers"
                    context="thread-message"
                  ></app-chat-message-content>

                  <div *ngIf="mainMessage.sharedMessage.isDeleted" class="badge badge-info pull-right mt-1">
                    <i class="ft-info"></i> {{ t('message-was-deleted') }}
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="numberOfReplies > 0" class="number-of-replies">
          <div>
            <span>{{ numberOfReplies }} {{ numberOfReplies === 1 ? t('reply') : t('replies') }}</span>
          </div>
          <hr />
        </div>
      </div>

      <div
        *ngIf="(showNoMessagesContainer || (isThread && !readOnly && numberOfReplies === 0)) && !isSeparate"
        class="thread-comments my-3"
        [ngClass]="{ 'mx-3': !isThread || readOnly, 'mx-0': !readOnly }"
      >
        {{ t('no-message-yet') }}
      </div>

      <div
        *ngFor="let message of orderMessage; let i = index; trackBy: trackByMessages"
        t-id="chat-messages-chat-message-panel"
        id="msg-{{ message._id }}"
        class="chat-message chat-left"
        [ngClass]="{
          'without-details': !message.showDetails,
          'chat-web': platform === 'web',
          'chat-mobile': platform !== 'web',
          'chat-light': config?.layout.variant === 'Light',
          'chat-message-pinned': message | checkIsPin: pinnedMessage: 'messages',
          'chat-message-pinned-dark': config?.layout.variant === 'Dark' && !isSeparate && (message | checkIsPin: pinnedMessage: 'messages'),
          'chat-dark': config?.layout.variant === 'Dark' && !isSeparate,
          'edit-mode-light': config?.layout.variant === 'Light' && editingMessage?._id === message._id,
          'edit-mode-dark': config?.layout.variant === 'Dark' && editingMessage?._id === message._id,
          'file-media-thread': this.getIsFilesObject(message)
        }"
        (mouseover)="messageHovered(message._id)"
        (mouseleave)="platform === 'web' ? messageHoveredOut() : null"
      >
        <div *ngIf="message.showDetails" class="chat-avatar">
          <app-avatar
            *ngIf="!message.webhookId"
            [userId]="message?.type === 'thread' ? message?.message?.userId : message?.userId"
            [width]="36"
            [height]="36"
          ></app-avatar>
          <app-webhook-avatar
            *ngIf="message.webhookId"
            [width]="36"
            [height]="36"
            [emojiFontSize]="30"
            [name]="message.webhookName"
            [chatId]="message.chatId"
            [webhookId]="message.webhookId"
          ></app-webhook-avatar>
        </div>

        <div class="chat-body hoverable message-{{ i }}">
          <div *ngIf="!message.showDetails" class="message-created-time">
            {{ message.created_at | date : 'HH:mm' : currTzAbbr }}
          </div>

          <div class="chat-content">

            <!-- PINNED USER -->
            <div *ngIf="message | checkIsPin: pinnedMessage: 'messages'" class="chat-pinned">
              <svg-icon
                class="chat-pinned-icon"
                src="assets/icons/pin/pin-action.svg"
                [applyClass]="true"
              ></svg-icon>
              <span>{{ t('pinned-by') }} {{getPinUserName(message)}}</span>
            </div>
            <!-- PINNED USER -->

            <!-- MESSAGE DETAILS -->
            <app-chat-message-details [message]="message?.type === 'thread' ? message?.message : message" [tz]="currTzAbbr">
            </app-chat-message-details>
            <!-- MESSAGE DETAILS -->

            <ng-container *ngIf="editingMessage?._id !== message._id">
              <app-chat-message-content
                *ngIf="!isImageMessage(message) && !isAudioMessage(message) && !isVideoMessage(message) && !isVideoOrDocMessage(message)"
                [object]="object"
                [objectId]="objectId"
                [platform]="platform"
                [message]="message?.type === 'thread' ? message?.message : message"
                [mentionChatMembers]="mentionChatMembers"
                [context]="isThread ? 'thread' : 'chat'"
                (imageClicked)="openImagePreview($event)"
              ></app-chat-message-content>

              <app-image-message
                *ngIf="isImageMessage(message)"
                [message]="message"
                [platform]="platform"
                [context]="isThread ? 'thread' : 'chat'"
                [mentionChatMembers]="mentionChatMembers"
                (imageClicked)="openImagePreview($event)"
              ></app-image-message>

              <app-audio-message
                *ngIf="isAudioMessage(message)"
                [message]="message"
                [platform]="platform"
                [context]="isThread ? 'thread' : 'chat'"
                [mentionChatMembers]="mentionChatMembers"
              ></app-audio-message>

              <app-video-message
                *ngIf="isVideoMessage(message)"
                [message]="message"
                [platform]="platform"
                [context]="isThread ? 'thread' : 'chat'"
                [mentionChatMembers]="mentionChatMembers"
              ></app-video-message>

              <app-doc-message
                *ngIf="isVideoOrDocMessage(message) && !isVideoMessage(message)"
                [object]="object"
                [objectId]="objectId"
                [message]="message"
                [platform]="platform"
                [context]="isThread ? 'thread' : 'chat'"
                [mentionChatMembers]="mentionChatMembers"
              ></app-doc-message>

              <div *ngIf="message.pollId">
                <app-message-poll [message]="message"></app-message-poll>
              </div>

              <div *ngIf="getIsFilesObject(message)">
                <app-message-files [isMobile]="isMobile" [message]="message" [platform]="platform" [object]="object" [objectId]="objectId"></app-message-files>
              </div>

              <div *ngIf="message?.linkObject || getUrl(message)" class="with-linked-object d-flex">
                <!-- VIEW LINKED ENTITY -->
                <app-linked-object-badge
                  *ngIf="showLinkObject(message)"
                  [object]="object"
                  [objectId]="objectId"
                  [message]="message"
                  [platform]="platform"
                  [tz]="currTzAbbr"
                  [withView]="true"
                  [disabledAction]="!hasPermissionToTheBoard(message)"
                  class="mr-2"
                ></app-linked-object-badge>
                <!-- VIEW LINKED ENTITY -->

                <!-- VIEW URL TICKET -->
                <ng-container *ngFor="let urlTicket of message.ticketsUrlData" >
                  <app-linked-object-badge
                    [platform]="platform"
                    urlReplace="ticket"
                    [message]="message"
                    [data]="urlTicket"
                    [tz]="currTzAbbr"
                    [withView]="true"
                    [disabledAction]="!hasPermissionToTicket(urlTicket)"
                    class="mr-2"
                  ></app-linked-object-badge>
                </ng-container>
                <!-- VIEW URL TICKET -->

                <!-- VIEW URL WIKI -->
                <ng-container *ngFor="let wiki of message.wikiPagesUrlData" >
                  <app-linked-object-badge
                    [object]="object"
                    [objectId]="objectId"
                    [platform]="platform"
                    urlReplace="wiki"
                    [message]="message"
                    [data]="wiki"
                    [tz]="currTzAbbr"
                    [withView]="true"
                    [disabledAction]="!hasPermissionToTicket(wiki)"
                    class="mr-2"
                  ></app-linked-object-badge>
                </ng-container>
                <!-- VIEW URL WIKI -->


                <!-- JOIN VIDEO CALL -->
                <app-linked-object-badge
                  *ngIf="message.linkObjectData && message?.linkObjectData?.videoCallId && !isThread"
                  [object]="object"
                  [objectId]="objectId"
                  [message]="message"
                  [platform]="platform"
                  [withView]="true"
                  [isCallLabel]="true"
                  class="mr-2"
                ></app-linked-object-badge>
                <!-- JOIN VIDEO CALL -->
              </div>

              <!-- MESSAGE EMOJI REACTIONS INFO -->
              <div *ngIf="message.aggregatedEmojis?.length > 0" class="d-flex mt-1">
                <span *ngFor="let emoji of message.aggregatedEmojis">
                  <ng-template #tipContent>
                    <span style="font-size: 20px">{{ emoji.emojiName }}</span>
                    <br>
                    <span style="font-weight: 600">{{ getEmojiUsers(emoji) }}</span>
                  </ng-template>
                  <span
                    t-id="chat-message-emoji-reactions"
                    [ngClass]="{
                      'emoji-reactions-light': config?.layout.variant === 'Light',
                      'emoji-reactions-dark': config?.layout.variant === 'Dark',
                      'current-user-reaction': emoji.isCurrentUserReaction && emoji.users.length === 1,
                      'current-user-with-other-reaction': emoji.isCurrentUserReaction && emoji.users.length > 1
                    }"
                    [ngbTooltip]="tipContent"
                    placement="top"
                    container="body"
                    (click)="emojiClicked(emoji, message)"
                  >
                    {{ emoji.emojiName }}
                    <span
                      [ngClass]="{
                        'number-of-emojis-light': config?.layout.variant === 'Light',
                        'number-of-emojis-dark': config?.layout.variant === 'Dark'
                      }"
                    >
                      {{ emoji.users.length }}
                    </span>
                  </span>
                </span>

                <button
                  id="add-quick-reaction-{{ isThread ? 'thread-' + i : i }}"
                  class="btn btn-sm emoji-reactions-button"
                  [ngClass]="{
                    'emoji-reactions-button-light': config?.layout.variant === 'Light',
                    'emoji-reactions-button-dark': config?.layout.variant === 'Dark'
                  }"
                  [ngbTooltip]="t('add-reaction-tooltip')"
                  placement="top"
                  container="body"
                  (click)="openQuickReaction($event, message, i)"
                >
                  <app-svg name="add-smile" width="21" height="18"></app-svg>
                </button>
              </div>
              <!-- MESSAGE EMOJI REACTIONS INFO -->

              <!-- MESSAGE THREADS INFO -->
              <div
                *ngIf="!isThread && message?.threadId && message?.threadsMessagesInfo?.messagesCount"
                class="reply-content d-flex align-items-center"
                [class.has-unread-replies]="message.threadsMessagesInfo?.numberOfUnreadMentions > 0 || message.threadsMessagesInfo?.numberOfUnreadMessages > 0"
              >
                <div class="thread-members overflow-hidden d-flex">
                  <ng-container *ngFor="let userId of message.threadsMessagesInfo.userIds; let i = index">
                    <ng-container *ngIf="i < threadAvatarsLimitation">
                      <app-avatar
                        [userId]="userId"
                        [width]="avatarSize"
                        [height]="avatarSize"
                        [statusSize]="statusSize"
                      ></app-avatar>
                    </ng-container>
                  </ng-container>
                </div>

                <ng-container *ngIf="message.threadsMessagesInfo.userIds.length > threadAvatarsLimitation">
                  <div ngbDropdown container="body" autoClose="outside">
                    <div
                      ngbDropdownToggle
                      class="d-flex align-items-center justify-content-center thread-members-avatar-toggle-btn"
                      [ngClass]="{
                      'more-members-button-light': config?.layout.variant === 'Light',
                      'more-members-button-dark': config?.layout.variant === 'Dark'
                      }"
                    >+{{ message.threadsMessagesInfo.userIds.length - threadAvatarsLimitation }}</div>
                    <div ngbDropdownMenu>
                      <ng-container *ngFor="let userId of message.threadsMessagesInfo.userIds; let i = index">
                        <ng-container *ngIf="i >= threadAvatarsLimitation">
                          <div>
                            <button ngbDropdownItem style="width: 100%">
                              <app-avatar
                                [userId]="userId"
                                [width]="avatarSize"
                                [height]="avatarSize"
                                [statusSize]="statusSize"
                              ></app-avatar>
                            </button>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <div class="d-flex align-items-center flex-grow-1" (click)="openThreadSidebar(message)">
                  <div class="reply-text" t-id="chat-messages-reply-button">
                    {{ message.threadsMessagesInfo.messagesCount }}
                    {{ message.threadsMessagesInfo.messagesCount > 1 ? t('replies') : t('reply') }}
                  </div>

                  <div class="last-reply">{{ t('last-reply') }} {{ getLastReplyText(message.lastReply) }}</div>
                  <div class="view-thread">
                    {{ t('view-in-thread') }}
                    <app-svg name="right" width="12" height="11"></app-svg>
                  </div>

                  <div class="flex-grow-1"></div>

                  <div class="d-flex">
                    <div *ngIf="message.threadsMessagesInfo?.numberOfUnreadMentions > 0" class="primary-circle ml-1">
                      {{ message.threadsMessagesInfo.numberOfUnreadMentions }}
                    </div>
                    <div *ngIf="message.threadsMessagesInfo?.numberOfUnreadMessages > 0" class="grey-circle ml-1">
                      {{ message.threadsMessagesInfo.numberOfUnreadMessages }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- MESSAGE THREADS INFO -->
            </ng-container>

            <!-- SHARED MESSAGE -->
            <div class="shared-message" *ngIf="message.sharedMessage">
              <div><i class="fa fa-share"></i></div>
              <blockquote>
                <div class="chat-avatar">
                  <app-avatar [userId]="message.sharedMessage.userId" [width]="36" [height]="36"></app-avatar>
                </div>

                <app-chat-message-details [message]="message.sharedMessage" [tz]="currTzAbbr"></app-chat-message-details>

                <app-chat-message-content
                  [platform]="platform"
                  [object]="object"
                  [objectId]="objectId"
                  [message]="message.sharedMessage"
                  [mentionChatMembers]="mentionChatMembers"
                  context="shared-message"
                  (imageClicked)="openImagePreview($event)"
                ></app-chat-message-content>

                <div *ngIf="message.sharedMessage.isDeleted" class="badge badge-info pull-right mt-1">
                  <i class="ft-info"></i> {{ t('message-was-deleted') }}Message was deleted
                </div>
              </blockquote>
            </div>
            <!-- SHARED MESSAGE -->

            <!-- EDIT TEXT -->
            <div *ngIf="editingMessage?._id === message._id" #messageEdit class="edit-message-wrapper">
              <app-message-edit
                class="edit-message"
                [ngClass]="{ 'edit-message-mobile': platform !== 'web' }"
                [platform]="platform"
                [isThread]="isThread"
                [editingMessage]="editingMessage"
                [mentionChatMembers]="mentionChatMembers"
                (onMessageEditSubmit)="onMessageEditSubmit($event)"
                (onMessageEditCancel)="stopEditMessage()"
              ></app-message-edit>
            </div>
            <!-- EDIT TEXT -->

            <!-- ERROR BADGE -->

            <!-- START OFFLINE -->

            <div *ngIf="getCheckOffline(message)" class="help-block ml-1 mt-1 text-danger">
              <i class="ft-alert-circle align-middle"></i> {{ t('couldnt-send-message') }}
            </div>
            <!-- ERROR BADGE -->

            <!-- END OFFLINE -->

          </div>
        </div>

        <!-- MESSAGE ACTIONS BUTTONS -->
        <app-message-actions-buttons
          *ngIf="!getCheckOffline(message)"
          [message]="message"
          [messageIndex]="i"
          [objectId]="objectId"
          [user]="user"
          [isThread]="isThread"
          [isLoadingPin]="isLoadingPin"
          [platform]="platform"
          (openThreadSidebar)="openThreadSidebar($event)"
          (downloadFile)="downloadFile($event)"
          (convertMessage)="convertMessage($event)"
          (onMessageForward)="onMessageForward($event)"
          (openDataRoomModal)="openDataRoomModal($event)"
          (onMessageDelete)="onMessageDelete($event)"
          (onMessageEditStart)="onMessageEditStart($event)"
          (openMessageReaction)="openMessageReaction($event)"
          (openActionsSheet)="openActionsSheet($event)"
          (pinMessage)="onPinMessage($event)"
          (unPinMessage)="onUnPinMessage($event)"
        ></app-message-actions-buttons>
        <!-- MESSAGE ACTIONS BUTTONS -->
      </div>
    </div>
  </ng-scrollbar>

  <div
    class="chat-hint"
    [class.chat-hint-active]="previousPage > 5"
    [class.chat-hint-dark]="config?.layout.variant === 'Dark'"
  >
    {{ t('youre-viewing-older-messages') }}
    <span class="chat-hint-action" (click)="scrollToBottom()">
      {{ t('jump-to-present') }}
    </span>
  </div>
</div>

<div
  *ngIf="!isVisible || (isVisible && !showNoMessagesContainer && messages === null)"
  class="loader"
  [class.loader-dark]="config?.layout.variant === 'Dark'"
>
  <app-svg name="spinner" width="38" height="38" fill="#C6C8D7" class="loading-icon"></app-svg>
</div>

<ng-container *ngIf="isBottomSheetOpened">
  <app-bottom-sheet
    #bottomSheetComponent
    [options]="options"
    [isFullSize]="isFullSizeSheet"
    (toggleSize)="toggleBottomSheetSize()"
    (close)="closeBottomSheet()"
    *transloco="let t; read: 'chat'"
  >
    <div
      *ngIf="options.title === '-'"
      [ngClass]="{ 'bottom-sheet-light': options.theme === 'Light', 'bottom-sheet-dark': options.theme === 'Dark' }"
    >
      <div class="emoji-bar">
        <div *ngFor="let emoji of frequentlyUsedEmojis" class="emoji-btn" (click)="_emojiClicked(emoji, options.message)">
          {{emoji}}
        </div>
        <div class="emoji-btn add-smile" (click)="_openMessageReaction($event, options.message, options.messageIndex)">
          <app-svg name="add-smile" width="21" height="18"></app-svg>
        </div>
      </div>
      <hr class="bottom-sheet-divider">
      <ng-container *ngIf="!options?.message?.pollId">
        <div class="convert-title">{{ t('convert-into') }}</div>
        <div
          *ngFor="let item of convertIntoList"
          class="bottom-sheet-action"
          (click)="_convertMessage(item.action, options.message)"
        >
          <app-svg name="{{ item.icon }}" width="16" height="16"></app-svg>
          <span class="ml-2">{{ item.title }}</span>
        </div>
        <hr class="bottom-sheet-divider">
      </ng-container>

      <div
        *ngIf="options.message.userId === user?._id && options.message.text"
        class="bottom-sheet-action"
        (click)="_onMessageEditStart(options.message, options.messageIndex)"
      >
        <app-svg name="pen" width="16" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-edit') }}</span>
      </div>
      <div
        *ngIf="options.message.text"
        class="bottom-sheet-action"
        (click)="copyMessageToClipboard(options.message)"
      >
        <app-svg name="copy" width="16" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-copy-message') }}</span>
      </div>
      <div
        *ngIf="options.message.userId === user?._id && options.message.linkObject !== 'tickets'"
        class="bottom-sheet-action"
        (click)="_onMessageDelete(options.message, isThread)"
      >
        <app-svg name="trash" width="14" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-delete') }}</span>
      </div>

      <div
        *ngIf="options.message.fileData"
        class="bottom-sheet-action"
        (click)="_downloadFile(options.message)"
      >
        <app-svg name="download" width="16" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-download') }}</span>
      </div>
      <div
        *ngIf="options.message.sharedMessage?.fileData"
        class="bottom-sheet-action"
        (click)="_downloadFile(options.message.sharedMessage)"
      >
        <app-svg name="download" width="16" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-download-shared-file') }}</span>
      </div>

      <div
        *ngIf="options.message.fileData"
        class="bottom-sheet-action"
        (click)="_openDataRoomModal(options.message)"
      >
        <app-svg name="folder-plus" width="16" height="14"></app-svg>
        <span class="ml-2">{{ t('btn-save-to-dataroom') }}</span>
      </div>
      <div
        *ngIf="!isThread && !options.message.sharedMessage && !space?.isPersonal && !options.message.pollId"
        class="bottom-sheet-action"
        (click)="_onMessageForward(options.message)"
      >
        <app-svg name="share" width="17" height="16"></app-svg>
        <span class="ml-2">{{ t('btn-share') }}</span>
      </div>
      <div
        *ngIf="!isThread"
        class="bottom-sheet-action"
        (click)="_openThreadSidebar(options.message)"
      >
        <app-svg name="comment-dots" width="17" height="14"></app-svg>
        <span class="ml-2">{{ t('btn-reply-in-thread') }}</span>
      </div>

      <div
        *ngIf="canUnpinMessage && (options.message | checkIsPin: pinnedMessage: 'messages') && !isThread; else pinMessage"
        class="bottom-sheet-action"
        (click)="onUnPinMessage(options.message)"
      >
        <svg-icon
          class="pin-icon-action"
          src="assets/icons/pin/unpin-message.svg"
          [applyClass]="true"
        ></svg-icon>
        <span class="ml-2">{{ t('btn-unpin-message') }}</span>
      </div>
      <ng-template #pinMessage>
        <div
          *ngIf="checkMessageMax && !(options.message | checkIsPin: pinnedMessage: 'messages') && !isThread && !isDirect"
          class="bottom-sheet-action"
          (click)="onPinMessage(options.message)"
        >
          <svg-icon
            class="pin-icon-action"
            src="assets/icons/pin/pin-action.svg"
            [applyClass]="true"
          ></svg-icon>
          <span class="ml-2">{{ t('btn-pin-message') }}</span>
        </div>
      </ng-template>

    </div>

    <div *ngIf="options.title !== '-' && touchedMessage">
      <div class="bottom-sheet-action" (click)="downloadTouchedFile(touchedMessage)">{{ t('btn-download') }}</div>
      <div class="bottom-sheet-action" (click)="openDataRoomModal(touchedMessage)">{{ t('btn-save') }}</div>
    </div>
  </app-bottom-sheet>
</ng-container>
