import { Component, EventEmitter, OnInit } from '@angular/core';
import { DesktopCapturerSource } from '../../shared/services/record.service';

export enum PreviewTab {
  Screen = 'screen',
  Application = 'window',
}

@Component({
  selector: 'app-screen-picker',
  templateUrl: './screen-picker-modal.component.html',
  styleUrls: ['./screen-picker-modal.component.scss'],
})
export class ScreenPickerModalComponent implements OnInit {
  streams: DesktopCapturerSource[] = [];
  mediaStream: EventEmitter<Promise<MediaStream> | null> = new EventEmitter<Promise<MediaStream> | null>();

  filteredStreams: DesktopCapturerSource[] = [];
  selectedStream: DesktopCapturerSource;
  previewTab = PreviewTab;
  activeTab: string;

  constructor() {}

  ngOnInit(): void {
    this.filterStreams(this.previewTab.Application);
  }

  pickStream(): void {
    const constraints = {
      audio: {
        mandatory: {
          chromeMediaSource: 'desktop',
        },
      },
      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: this.selectedStream.id,
          minWidth: 1280,
          minHeight: 720,
        },
      },
    };
    // @ts-ignore
    this.mediaStream.emit(navigator.mediaDevices.getUserMedia(constraints));
  }

  cancelSelection(): void {
    this.mediaStream.emit(null);
  }

  selectStream(stream: DesktopCapturerSource): void {
    this.selectedStream = stream;
  }

  filterStreams(filter: PreviewTab): void {
    this.activeTab = filter;
    this.filteredStreams = this.streams.filter((stream) => stream.id.includes(filter));
  }
}
