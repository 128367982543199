<ng-container *ngIf="!isSearchActive">
  <div class="actions-bar d-flex justify-content-between" *transloco="let t; read: 'data-room'">
    <div class="d-flex align-items-center flex-grow-1">
      <i *ngIf="!documentsIsLoaded" class="breadcrumbs-spinner fa fa-spinner fa-spin mr-1"
         aria-hidden="true"></i>
      <nav aria-label="breadcrumb" *ngIf="breadcrumbs?.length > 0">
        <ol
          class="breadcrumb"
          [ngClass]="{
        'background-light': !isDark,
        'background-dark': isDark
       }"
        >
          <li
            *ngFor="let breadcrumb of breadcrumbs; let i = index; trackBy: trackById"
            class="breadcrumb-item"
            [class.last]="i === breadcrumbs.length - 1"
          >
            <a (click)="breadcrumbClick(breadcrumb._id)">
              {{
              i > 0
                ? breadcrumb.name
                : currRootFolder !== treeType.chat &&
                currRootFolder !== treeType.board &&
                currRootFolder !== treeType.projectFiles &&
                currRootFolder !== treeType.laneBoard ? t('storage-folder') : currRootFolder
              }}
            </a>
          </li>
        </ol>
      </nav>
    </div>

    <div class="document__header">
      <div *ngIf="isEnabledAction" class="documents-sort ml-auto">
        <app-sorting-toggle
          *ngIf="documents.length"
          [sortByList]="sortByList"
          [sortBy]="sortBy"
          [direction]="sortDirection"
          (sortChange)="sortChange($event)"
        ></app-sorting-toggle>
      </div>

      <!--Search-->
      <svg-icon
        (click)="setSearchState()"
        src="assets/icons/common/search.svg"
        class="document__header-icon"
        [applyClass]="true"
      ></svg-icon>

      <!--View-->
      <svg-icon
        *ngIf="isListView; else isGridView"
        (click)="changeViewDataRoom()"
        src="assets/icons/data-room/grid-data.svg"
        class="document__header-icon"
        t-id="data-room-header-change-view-list"
        [applyClass]="true"
      ></svg-icon>
      <ng-template #isGridView>
        <svg-icon
          (click)="changeViewDataRoom()"
          src="assets/icons/data-room/list-view.svg"
          class="document__header-icon document__header-grid"
          t-id="data-room-header-change-view-grid"
          [applyClass]="true"
        ></svg-icon>
      </ng-template>

      <!--Actions-->
      <div *ngIf="isEnabledAction" ngbDropdown class="document__header-action" t-id="data-room-header-add-button">
        <button type="button" class="btn btn-solid btn-save" ngbDropdownToggle>
          {{ t('btn-add') }}
        </button>
        <div ngbDropdownMenu>
          <button class="document__header-item" ngbDropdownItem (click)="uploadClick()" t-id="data-room-header-add-files-button">
            <svg-icon
              class="document__header-dropIcon"
              src="assets/icons/data-room/upload.svg"
              [applyClass]="true"
            ></svg-icon>
            <span>{{ t('btn-add-files') }}</span>
          </button>
          <button class="document__header-item" ngbDropdownItem (click)="addFolder()" t-id="data-room-header-add-folder-button">
            <svg-icon
              class="document__header-dropIcon"
              src="assets/icons/data-room/folder-plus.svg"
              [applyClass]="true"
            ></svg-icon>
            <span>{{ t('btn-add-folders') }}</span>
          </button>
          <button class="document__header-item" ngbDropdownItem (click)="addLink()" t-id="data-room-header-add-link-button">
            <svg-icon
              class="document__header-dropIcon"
              src="assets/icons/common/clip.svg"
              [applyClass]="true"
            ></svg-icon>
            <span>{{ t('btn-add-link') }}</span>
          </button>
          <button class="document__header-item" ngbDropdownItem (click)="addDocument()" t-id="data-room-header-add-documents-button">
            <svg-icon
              class="document__header-dropIcon"
              src="assets/icons/data-room/file-plus.svg"
              [applyClass]="true"
            ></svg-icon>
            <span>{{ t('btn-add-document') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isSearchActive" (keyup.enter)="searchDocument()">
  <div class="document__search" *transloco="let t; read: 'data-room'">
    <div class="document__search-wrapper">
      <div class="document__search-container">
        <svg-icon
          src="assets/icons/common/search.svg"
          class="document__search-icon"
          [applyClass]="true"
        ></svg-icon>
        <input
          class="document__search-input"
          type="text"
          [formControl]="searchValue"
        >
        <svg-icon
          *ngIf="searchValue.value"
          src="assets/icons/common/times-circle.svg"
          class="document__search-delete"
          [applyClass]="true"
          (click)="deleteSearch()"
        ></svg-icon>
      </div>
      <button
        type="button"
        [disabled]="searchValue.invalid"
        (click)="searchDocument()"
        class="document__search-button btn btn-solid btn-save"
      >
        {{ t('search') }}
      </button>
    </div>
    <svg-icon
      (click)="backToAction()"
      src="assets/icons/common/cross.svg"
      class="document__search-back"
      [applyClass]="true"
    ></svg-icon>
  </div>
</ng-container>
