<div class="modal-header" *transloco="let t; read: 'modals.space'">
	<h4 class="modal-title">{{ t('title-space-update') }}</h4>
	<button type="button" class="close" aria-label="Close" (click)="close()">
    <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
	</button>
</div>
<div class="modal-body space-modal">
  <app-project-settings
	  #projectSettingsComponent
	  object="spaces"
	  [objectId]="objectId"
	  [chatId]="chatId"
	  [isModalWindow]="true"
	  (close)="close($event)">
  </app-project-settings>
</div>
