/** Create calendar event **/
export class CalendarEventCreate {
  static readonly type = '[CalendarEvents] Create';
  constructor(public payload) {}
}

/** Get calendar events **/
export class CalendarEventsSet {
  static readonly type = '[CalendarEvents] Set list';
  constructor() {}
}

/** Get calendar events **/
export class CalendarEventsGet {
  static readonly type = '[CalendarEvents] Get events list';
  constructor(public payload) {}
}
/** Get calendar tickets **/
export class CalendarTicketsGet {
  static readonly type = '[CalendarEvents] Get tickets list';
  constructor(public payload) {}
}

/** Set calendar tickets **/
export class CalendarTicketsSet {
  static readonly type = '[CalendarTickets] Set list';
  constructor(public payload) {}
}

/** Get calendar single event **/
export class CalendarEventGet {
  static readonly type = '[CalendarEvents] Get single';
  constructor(public payload) {}
}

/** Get calendar event by id **/
export class CalendarEventsGetById {
  static readonly type = '[CalendarEvents] GetById';
  constructor(public payload) {}
}

/** updated calendar event status **/
export class CalendarEventsUpdateStatus {
  static readonly type = '[CalendarEvents] Update status';
  constructor(public payload) {}
}

/** Delete calendar event **/
export class CalendarEventDelete {
  static readonly type = '[CalendarEvents] Delete';
  constructor(public payload) {}
}

/** Remove calendar event by socket notification **/
export class CalendarEventRemove {
  static readonly type = '[CalendarEvents] Remove';
  constructor(public payload) {}
}

/** Remove calendar exception event by socket notification **/
export class CalendarEventExceptions {
  static readonly type = '[CalendarEvents] Remove exception';
  constructor(public payload) {}
}

/** Delete calendar single event from series **/
export class CalendarEventDeleteSingle {
  static readonly type = '[CalendarEvents] Delete Single Event';
  constructor(public payload) {}
}

/** Update calendar event **/
export class CalendarEventsUpdate {
  static readonly type = '[CalendarEvents] send update request';
  constructor(public payload) {}
}

/** Update calendar events data **/
export class CalendarEventsDataUpdate {
  static readonly type = '[CalendarEvents] Update events data by socket event';
  constructor(public payload) {}
}

/** Update calendar single event from  series **/
export class CalendarEventsUpdateSingle {
  static readonly type = '[CalendarEvents] Update Single';
  constructor(public payload) {}
}

export class CalendarEventsCheckedCalendarsUpdate {
  static readonly type = '[CalendarEvents] Update Checked Calendars';
  constructor(public payload) {}
}

export class CalendarEventsCheckedCalendarsGet {
  static readonly type = '[CalendarEvents] Get Checked Calendars';
  constructor(public payload) {}
}

export class CalendarEventsStopRemind {
  static readonly type = '[CalendarEvents] Stop Remind';
  constructor(public payload) {}
}
