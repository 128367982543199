import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WebhooksState } from '../../../../store/states/webhook.state';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { WebhooksDbDto } from '../../../../../api/models/webhooks-db-dto';

@Component({
  selector: 'app-webhook-avatar',
  templateUrl: './webhook-avatar.component.html',
  styleUrls: ['./webhook-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookAvatarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() name: string;

  @Input() chatId: string;

  @Input() webhookId: string;

  @Input() width = 40;

  @Input() height = 40;

  @Input() emojiFontSize = 37;

  public prefix: string;

  public avaUrl: string;

  public avaEmoji: string;

  private webhookInfo$: Subscription;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.initAvatar();
  }

  ngOnChanges() {
    this.initAvatar();
  }

  private initAvatar(): void {
    this.prefix = this.name?.charAt(0).toUpperCase();
    this.webhookInfo$?.unsubscribe();

    this.store
      .select(WebhooksState.getWebhooksListByChatId)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.chatId)),
      )
      .subscribe((res: WebhooksDbDto[]) => {
        const webHookData = res?.find((wh) => wh._id === this.webhookId);

        if (webHookData) {
          this.avaUrl = webHookData.avatarUrl ? webHookData.avatarUrl : undefined;
          this.avaEmoji = webHookData.emoji ? webHookData.emoji : undefined;
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.webhookInfo$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
