import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { TabIndex } from '../../custom-fields.component';
import { ConfigService } from '../../../../../shared/services/config.service';

@Component({
  selector: 'app-input-select',
  templateUrl: 'input-select.component.html',
})
export class InputSelectComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;
  unitFormGroup: FormGroup;
  inputFocused: (name: string) => void;
  setFocusedControl: (tabIndex: string) => void;
  tabIndexes: Array<TabIndex>;
  config: any = {};
  displayName: string;

  estimationTypes = [
    { unit: 'h', title: 'Hours' },
    { unit: 'd', title: 'Days' },
    { unit: 'sp', title: 'Storypoints' },
  ];

  constructor(private configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  get isEstimation() {
    return this.fieldConfig.title === 'Estimation';
  }

  get uniqFieldId() {
    return `${this.fieldConfig.title.trim()}-${this.fieldConfig.type.trim()}-${this.fieldConfig.order}`;
  }

  ngOnInit(): void {
    this.unitFormGroup = this.form.controls[this.fieldConfig.title] as FormGroup;
  }

  clearUnit() {
    this.unitFormGroup.controls.unit.reset();
  }

  checkNumber(event) {
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  }
}
