<quill-editor
  #editMessageInput
  t-id="message-edit-input"
  class="edit-message-input"
  bounds="self"
  [ngClass]="{
    'edit-message-input-light': config?.layout.variant === 'Light',
    'edit-message-input-dark': config?.layout.variant === 'Dark',
    'ql-editor-mobile': platform !== 'web'
  }"
  [modules]="editorModules"
  [appFocus]="true"
  [required]="true"
  [sanitize]="true"
  [trimOnValidation]="true"
  [(ngModel)]="message.text"
  (onEditorCreated)="editorCreated($event)"
  (onContentChanged)="contentChanged($event)"
  (keydown.enter)="platform === 'web' ? _onMessageEditSubmit() : null"

  [mention]="mentionChatMembers"
  [mentionConfig]="{ dropUp: false }"
  (itemSelected)="mentionItemSelected($event)"
  (opened)="mentionSelectOpened()"
  (closed)="mentionSelectClosed()"
></quill-editor>
<div class="edit-message-actions" *transloco="let t; read: 'chat'">
  <button
    (click)="onMessageEditCancel.emit()"
    class="btn mr-1"
    [ngClass]="{
      'btn-light': config?.layout.variant === 'Light',
      'btn-dark': config?.layout.variant === 'Dark'
    }"
    ngbButtonLabel
  >
    {{ t('btn-cancel') }}
  </button>

  <button
    t-id="message-edit-save-action"
    [disabled]="!message?.text?.length"
    (click)="_onMessageEditSubmit()"
    class="btn btn-solid"
    ngbButtonLabel
  >
    {{ t('btn-save') }}
  </button>
</div>
