<ng-container *transloco="let t">
  <div class="modal-header d-flex align-items-center">
    <h6 class="modal-title mb-0">{{ object === 'projects' ? t('modals.page-details.title-project-details') : t('modals.page-details.title-space-details') }}</h6>
    <app-svg
      t-id="page-details-title-action"
      class="cursor-pointer cancel-icon svg-icon"
      name="times"
      width="10"
      height="10"
      aria-label="Close"
      (click)="close()"
    ></app-svg>
  </div>

  <div class="modal-body page-details">
    <ul
      ngbNav
      #pageDetailsTab="ngbNav"
      [(activeId)]="currentTab"
      (navChange)="onNavChange($event)"
      class="nav-tabs d-flex justify-content-start"
    >
      <li [ngbNavItem]="'info'">
        <a ngbNavLink>{{ t('modals.page-details.nav-info') }}</a>

        <ng-template ngbNavContent>
          <section *ngIf="object === 'spaces'" class="space-page">
            <app-project-settings
              #projectSettingsComponent
              [object]="object"
              [objectId]="objectId"
              [chatId]="chatId"
              [isModalWindow]="true"
              (close)="close($event)"
            >
            </app-project-settings>
          </section>

          <section *ngIf="object === 'projects'" class="project-page">
            <app-project-settings
              #projectSettingsComponent
              [object]="object"
              [objectId]="objectId"
              [chatId]="chatId"
              [isModalWindow]="true"
              (close)="close($event)">
            </app-project-settings>
          </section>
        </ng-template>
      </li>

      <li [ngbNavItem]="'members'" [disabled]="!object || !objectId">
        <a ngbNavLink>{{ t('modals.page-details.nav-members') }}</a>

        <ng-template ngbNavContent>
          <app-users-list [object]="object" [objectId]="objectId" (close)="close($event)"></app-users-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="'activity'" [disabled]="!object || !objectId">
        <a ngbNavLink>{{ t('modals.page-details.nav-activity') }}</a>

        <ng-template ngbNavContent>
          <app-action-logs [object]="object" [objectId]="objectId" [isNavbar]="true"></app-action-logs>
        </ng-template>
      </li>

      <li [ngbNavItem]="'integration'">
        <a ngbNavLink t-id="page-details-modal-integrations-tab">{{ t('modals.page-details.nav-integrations') }}</a>

        <ng-template ngbNavContent>
          <app-integrations [spaceId]="space?._id" [chatId]="chatId" [platform]="platform"></app-integrations>
        </ng-template>
      </li>
      <li [ngbNavItem]="'tickets'" *ngIf="object === 'projects' && ('projects::' + this.objectId + '::projectUpdate' | checkPermission)">
        <a ngbNavLink t-id="page-details-modal-tickets-tab">{{ t('modals.page-details.nav-tickets') }}</a>

        <ng-template ngbNavContent>
          <app-customize-ticket-fields
            [object]="object"
            [objectId]="objectId"
            [platform]="platform"
            (close)="close($event)"
          ></app-customize-ticket-fields>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="pageDetailsTab"></div>
  </div>
</ng-container>
