import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  OnInit,
  SimpleChanges,
  RendererFactory2,
  Renderer2,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { filter, Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import { Emoji, EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { QuillModulesForChat } from '../../../data/quill-configuration';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UsersPublicFieldsResDto } from '../../../../api/models/users-public-fields-res-dto';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { ConfigService } from '../../../services/config.service';
import { QuillInitializeService } from '../../../services/quill/quill-init.service';
import { SocketsService } from '../../../services/sockets.service';
import { TauriService } from '../../../services/tauri.service';
import { RecordService, RecordStartedFrom, RecordType } from '../../../services/record.service';
import {
  ChatsClearMessageDraft,
  ChatsClearEditor,
  ChatsEditLastMessageWithArrowUp,
  ChatsEmojiPicker,
  ChatsNewMessageToUpload,
  ChatsSetEditorFocus,
  ChatsSetEmoji,
  ChatsSocketNewMessage,
  ChatsThreadSetEmoji,
  ChatsUpdateMessageDraft,
  ChatsUploadFile,
  ChatsGet,
  ChatsMessageCreate,
} from '../../../store/actions/chats.action';
import { LaneBoardCreate } from '../../../store/actions/lane-board.action';
import { AuthState } from '../../../store/states/auth.state';
import { ChatsState } from '../../../store/states/chats.state';
import { LaneBoardState } from '../../../store/states/lane-board.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PollModalComponent } from '../../../../modals/poll-modal/poll-modal.component';
import { SpacesDbDto } from '../../../../api/models/spaces-db-dto';
import { SpacesState } from '../../../store/states/spaces.state';
import { SelectionChange } from 'ngx-quill';
import { MentionDirective } from 'angular-mentions';
import { UploadFileService } from '../../../services/upload-file.service';
import { removedBlanks } from '../../../utils/blunk-change';
import { LocalStorageService } from 'ngx-localstorage';
import { replaceLastSrcWithUrl } from '../../../utils/replace-src-helper';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-app-form',
  templateUrl: './chat-app-form.component.html',
  styleUrls: ['./chat-app-form.component.scss'],
})
export class ChatAppFormComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('chatInput', { static: false }) quill: any;
  @ViewChild(MentionDirective, { static: false }) mentions: MentionDirective;

  @Input() readOnly: boolean;
  @Input() platform: string;
  @Input() object: string;
  @Input() apiUrl: string;
  @Input() objectId: string;
  @Input() chatId: string;
  @Input() mentionChatMembers: any[];

  @Output() audioRecorded = new EventEmitter();
  @Output() fileChanged = new EventEmitter();
  @Output() scrollBottom = new EventEmitter();
  @Output() messageChanged = new EventEmitter();

  currentUser: UsersPublicFieldsResDto;
  config: any = {};
  editorModules: any;
  editor;
  threadEditor: any;
  activeEditor: any;
  space: SpacesDbDto;
  showEditorToolbar = false;
  files: File[];
  newMessage = '';
  threadMessage = '';
  isTyping = false;
  editorContent: any;
  isThreadTyping = false;
  laneBoardId = null;
  editorChange: Subject<any> = new Subject<any>();

  isVoiceRecording = false;
  isChatFileRecording = false;
  isThreadFileRecording = false;
  isTicketFileRecording = false;
  isWikiFileRecording = false;
  recordStartedFrom = RecordStartedFrom;
  screenRecordTimeSub: Subscription;
  screenRecordTime = '00:00';

  emojiSearchMode = false;
  emojiRegex: RegExp = /(^|\s)(?::)(.*?)(?=:|$)/;
  emojiSearchResults: EmojiData[] = [];
  emojiMatch: string;
  emojiPickerImage = 'assets/img/emojis/emojis.png';

  isOpenEmojiPicker = false;
  isOpenThreadEmojiPicker = false;
  uploadFiles = false;
  arrowKeyLocation = 0;
  caretPosition = 0;
  isThread = false;
  isReplace = false;
  isMentionSelectOpened = false;
  onFocus = false;
  mentionChatMembers$: Subject<any[]> = new Subject();

  initEditor = false;
  private renderer: Renderer2;
  needToHandleScrollPosition = false;
  pastedEditorData$: Subject<ClipboardEvent> = new Subject();
  isSendBtnDisabled = false;

  constructor(
    protected socketsService: SocketsService,
    protected toastr: ToastrService,
    protected routerTenantPipe: RouterTenantPipe,
    protected store: Store,
    private router: Router,
    protected actions: Actions,
    private emojiSearch: EmojiSearch,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    public uploadFileService: UploadFileService,
    public cdr: ChangeDetectorRef,
    public recordService: RecordService,
    public tauriService: TauriService,
    private modalsService: NgbModal,
    private translocoService: TranslocoService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    this.config = this.configService.templateConf;
    this.editorModules = { ...QuillModulesForChat, magicUrl: true };

    const bindings = {
      arrowUp: {
        key: 38,
        handler: () => {
          if ((!this.newMessage && !this.isThread) || (!this.threadMessage && this.isThread)) {
            this.store.dispatch(new ChatsEditLastMessageWithArrowUp({ isThread: this.isThread }));
          }
          return true;
        },
      },
      enter: {
        key: 13,
        handler: () => {
          if (this.isThread ? !this.threadMessage?.length : !this.newMessage?.length) {
            return !this.isMentionSelectOpened;
          } else if (!this.isMentionSelectOpened) {
            if (this.emojiSearchMode) {
              this.emojiSelectedByEnter();
            } else if (this.platform === 'web' && !this.uploadFiles) {
              this.onAddMessage('keydown');
            } else {
              return true;
            }
          }
        },
      },
    };
    this.editorModules.keyboard = { bindings };
  }

  ngOnInit() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.actions
      .pipe(untilDestroyed(this), ofActionDispatched(ChatsSetEmoji))
      .subscribe(({ payload: { selectedEmoji } }) => {
        if (!this.isThread) {
          this.insertEmojiToEditor(selectedEmoji.native, 'chat');
        }
        this.closeEmojiPicker();
      });

    this.uploadFileService.files.pipe(untilDestroyed(this)).subscribe((files) => {
      this.files = files;
      this.defineIsSendBtnDisabled();
      this.cdr.detectChanges();
    });

    this.actions
      .pipe(untilDestroyed(this), ofActionDispatched(ChatsThreadSetEmoji))
      .subscribe(({ payload: { selectedEmoji } }) => {
        if (this.isThread) {
          this.insertEmojiToEditor(selectedEmoji.native, 'thread');
        }
        this.closeThreadEmojiPicker();
      });

    this.actions.pipe(untilDestroyed(this), ofActionSuccessful(ChatsUploadFile)).subscribe(() => {
      (this.isThread ? this.threadEditor : this.editor)?.focus();

      this.textChanged('', true);
    });

    this.actions.pipe(untilDestroyed(this), ofActionSuccessful(ChatsClearEditor)).subscribe(() => {
      this.textChanged('', true);
    });

    this.actions.pipe(untilDestroyed(this), ofActionSuccessful(ChatsSetEditorFocus)).subscribe(({ payload }) => {
      setTimeout(() => {
        if (payload === 'chat' && this.editor) {
          this.editor.focus();
        } else if (payload === 'thread' && this.threadEditor) {
          this.threadEditor.focus();
        }
      }, 0);
    });

    this.store
      .select(LaneBoardState.getLaneBoardId)
      .pipe(untilDestroyed(this))
      .subscribe((id) => (this.laneBoardId = id));

    if (this.recordService.isRecording) {
      this.recordListener();
    }

    this.uploadFileService.clearChat.pipe(untilDestroyed(this)).subscribe(() => {
      this.textChanged('', true);
      this.scrollBottom.emit();
    });

    this.currentUser = this.store.selectSnapshot(AuthState.getUser);

    this.mentionChatMembers$.next(this.getMentionMembers());
    this.editorUpdater();

    this.mentions?.opened.pipe(untilDestroyed(this), delay(0)).subscribe(() => {
      this.handleMentionListPosition();
      const mentionListElement = this.defineMentionListElement();
      if (mentionListElement) {
        this.renderer.setStyle(mentionListElement, 'opacity', 1);
      }
    });

    this.mentions?.closed.pipe(untilDestroyed(this)).subscribe(() => {
      const mentionListElement = this.defineMentionListElement();
      if (mentionListElement) {
        this.renderer.setStyle(mentionListElement, 'opacity', 0);
      }
    });

    this.pastedEditorData$
      .pipe(
        untilDestroyed(this),
        filter((contentChaneEventData) => this.needToHandleScrollPosition && !!contentChaneEventData),
        delay(0),
      )
      .subscribe((contentChaneEventData) => {
        this.needToHandleScrollPosition = false;
        this.handleScrollToCaretPositionOnEditorPastData(contentChaneEventData);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chatId && !!changes.chatId.currentValue) {
      this.space = this.store.selectSnapshot(SpacesState.getLoadedSpaces).find((space) => space._id === this.objectId);
      this.checkDraftMessage();
      this.mentions?.stopSearch();
    }
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(untilDestroyed(this)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  // switch/select between suggested emojis
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.emojiSearchMode) {
      switch (event.code) {
        case 'ArrowUp':
          if (this.arrowKeyLocation > 0) {
            this.arrowKeyLocation--;
          }
          event.preventDefault();
          break;

        case 'ArrowDown':
          if (this.arrowKeyLocation < this.emojiSearchResults.length - 1) {
            this.arrowKeyLocation++;
          }
          event.preventDefault();
          break;

        case 'Enter':
        case 'NumpadEnter':
          this.emojiSelectedByEnter();
          event.preventDefault();
          break;
      }
    }
  }

  editorCreated(editor: any) {
    this.editor = editor;

    // TODO: what is this for?
    //   - move to QuillInitializeService.handleEditorCreated if possible
    this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.forEach((e) => {
        if (e.attributes) {
          e.attributes.color = '';
          e.attributes.background = '';
        }
      });
      return delta;
    });

    QuillInitializeService.handleEditorCreated(editor);

    this.checkDraftMessage();
  }

  createPoll() {
    const modalRef = this.modalsService.open(PollModalComponent, {
      size: 'md',
      centered: true,
      windowClass: 'poll',
    });

    modalRef.componentInstance.pollData = {
      object: this.object,
      objectId: this.objectId,
      chatId: this.chatId,
      currentUser: this.currentUser,
    };
  }

  contentChanged(event) {
    if (event.html !== undefined && event.html !== null) {
      this.textChanged(event.html);
    }

    this.mentionChatMembers$.next(this.getMentionMembers());
    this.editorChange.next(event);

    if (this.needToHandleScrollPosition) {
      this.pastedEditorData$.next(event);
    }
  }

  editorFocused(event) {
    this.onFocus = true;
    if (!this.initEditor && this.isNotMobile) {
      setTimeout(() => (this.initEditor = true), 100);
      this.editor.blur();
    }

    const children = Array.prototype.slice.call(event.editor.container.parentElement.children);
    children.forEach((item: any) => {
      if (item.classList.contains('ql-toolbar')) {
        this.activeEditor = event;
      }
    });
    this.mentionChatMembers$.next(this.getMentionMembers());

    if (this.isThread) {
      this.scrollToCenterOfElement(this.threadEditor?.container?.parentElement);
    }
  }

  editorBlurred(event) {
    this.onFocus = false;
    const children = Array.prototype.slice.call(event.editor.container.parentElement.children);
    children.forEach((item: any) => {
      if (item.classList.contains('ql-toolbar')) {
        this.activeEditor = null;
      }
    });
    this.mentionChatMembers$.next(this.getMentionMembers());
  }

  get checkIsPersonal() {
    return this.object === 'spaces' ? this.space && !this.space.isPersonal : true;
  }

  get checkDirectMessage() {
    return !this.router.url.includes('page=dm');
  }

  get isNotMobile(): boolean {
    return this.platform !== 'web';
  }

  get isSafariScreenshot(): boolean {
    return this.editorContent && this.editorContent.includes('<img src="//:0">');
  }

  getEscapedText(text, withTrim = true) {
    return withTrim ? text?.replace(/<[^>]+>/g, '').trim() : text?.replace(/<[^>]+>/g, '');
  }

  getNewMessage(message, text, type) {
    this.isReplace = true;

    (type === 'thread' ? this.threadEditor : this.editor)?.insertText(this.caretPosition, text);

    message = type === 'thread' ? this.threadEditor?.root.innerHTML : this.editor?.root.innerHTML;
    this.textChanged(message);

    this.caretPosition += text.length;
  }

  insertEmojiToEditor(text: string, type: string) {
    this.getNewMessage(type === 'thread' ? this.threadMessage : this.newMessage, text, type);
  }

  emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) => this.emojiPickerImage;

  startScreenRecord(isRecordWithMic: boolean, recordStartFrom: RecordStartedFrom): void {
    if (!this.recordService.recordedFile) {
      this.recordService.startScreenRecord(isRecordWithMic, recordStartFrom, this.chatId);
      this.recordListener();
    }
  }

  startAudioRecord(recordStartFrom: RecordStartedFrom): void {
    if (!this.recordService.recordedFile) {
      this.recordListener();
      this.recordService.startAudioRecord(recordStartFrom, this.chatId);
    }
  }

  resetRecord() {
    this.recordService.stopRecording().then(() => {
      this.recordService.clearRecord();
      this.resetRecordOptions();
      this.cdr.detectChanges();
    });
  }

  resetRecordOptions(): void {
    this.isVoiceRecording = false;
    this.isChatFileRecording = false;
    this.isThreadFileRecording = false;
    this.isTicketFileRecording = false;
    this.screenRecordTimeSub.unsubscribe();
  }

  proceedRecording() {
    this.recordService.stopRecording().then((blob) => {
      if (blob.size > 5000) {
        let extension = 'wav';
        if (this.recordService.recordType === RecordType.Video) {
          if (blob.type.includes('video/mp4')) {
            extension = 'mp4';
          } else if (blob.type.includes('video/webm')) {
            extension = 'webm';
          }
        }

        const file = new File([blob], `${new Date().getTime()}.${extension}`, {
          lastModified: new Date().getTime(),
        });
        const toast = this.toastr.info(
          this.translocoService.translate('toastr.record-saving-started'),
          this.translocoService.translate('toastr.title-info'),
          {
            disableTimeOut: true,
            positionClass: 'toast-top-right',
          },
        );

        this.audioRecorded.emit({ toastId: toast.toastId, file });
        this.resetRecordOptions();
        this.recordService.clearRecord();
      } else {
        this.toastr.warning(
          this.translocoService.translate('toastr.empty-message-cannot-be-saved'),
          this.translocoService.translate('toastr.title-warning'),
        );
      }
    });
  }

  /* suggest emojis */
  suggestEmoji(type: string) {
    const newMsg = this.getEscapedText(type === 'thread' ? this.threadMessage : this.newMessage, false);
    if (newMsg?.trim().length > 0) {
      this.emojiSearchMode = !newMsg.includes('`') && this.emojiRegex.test(newMsg);

      const editor = type === 'thread' ? this.threadEditor : this.editor;

      if (this.emojiSearchMode) {
        editor.keyboard.quill.root.addEventListener('keydown', this.blockUpDownKeys);
        const caretPosition = this.caretPosition;
        const testText = newMsg.slice(0, caretPosition);
        const lastColPos = testText.lastIndexOf(':');

        if (lastColPos > 0 && !testText.substr(lastColPos + 1, caretPosition - lastColPos)) {
          const filteredEmoji =
            this.emojiSearchResults?.filter((item) => item.colons === `:${this.emojiMatch}:`).pop() || undefined;

          if (filteredEmoji) {
            this.replaceCodeToEmoji(filteredEmoji, true, type);
          }
        }

        this.emojiMatch = testText.substr(lastColPos + 1, caretPosition - lastColPos);
        if (this.emojiMatch?.includes(' ')) {
          this.resetEmojiSearch(type);
        } else {
          let results = this.emojiSearch.search(`:${this.emojiMatch}`);

          if (results.length < 5) {
            const resultsByKey = this.emojiSearch.search(this.emojiMatch);
            results = [...results, ...resultsByKey];
          }

          if (results?.length > 0) {
            this.emojiSearchResults = results.slice(0, 5);
          } else {
            this.resetEmojiSearch(type);
          }
        }
      }
    } else if (this.emojiSearchMode) {
      this.emojiSearchMode = false;
    }
  }

  blockUpDownKeys(event) {
    const blockedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

    if (blockedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  getCountChartsForReplace(text: string, caretPosition: number) {
    let counter = 0;
    const slicedText = text.slice(0, caretPosition);
    for (let i = caretPosition - 1; i >= 0; i--) {
      counter++;
      if (slicedText[i] === ':') {
        return counter;
      }
    }
  }

  replaceCodeToEmoji(emoji: EmojiData, isFull: boolean, type: string) {
    this.isReplace = true;
    this.needToHandleScrollPosition = true;
    const editor = type === 'thread' ? this.threadEditor : this.editor;

    const text: string = editor.getText();

    const countToDelete = this.getCountChartsForReplace(text, this.caretPosition) || 1;

    editor?.insertText(this.caretPosition, emoji.native);
    editor.deleteText(this.caretPosition - countToDelete, countToDelete);

    setTimeout(
      () => editor?.setSelection(this.caretPosition - countToDelete + 1),
      editor.keyboard.quill.root.removeEventListener('keydown', this.blockUpDownKeys),
      0,
    );
  }

  emojiSelected(emoji: EmojiData) {
    this.replaceCodeToEmoji(emoji, false, 'chat');
    this.resetEmojiSearch('chat');
  }

  emojiSelectedByEnter() {
    this.emojiSelected(this.emojiSearchResults[this.arrowKeyLocation]);
  }

  resetEmojiSearch(type: string) {
    if (this.emojiSearchMode) {
      const editor = type === 'thread' ? this.threadEditor : this.editor;
      this.arrowKeyLocation = 0;
      this.emojiSearchMode = false;
      this.emojiSearchResults = null;
      editor.keyboard.quill.root.removeEventListener('keydown', this.blockUpDownKeys);
      editor.focus();
    }
  }

  // close emoji picker when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event) {
    const chatActions = document.querySelectorAll('.chat-actions button');
    const emojiPickerButtonElement = document.querySelector('.emoji-picker-icon');

    for (let i = 0; i < chatActions.length; i++) {
      if (chatActions[i]?.contains(event.target) && this.activeEditor) {
        this.editorBlurred(this.activeEditor);
      }
    }

    if (this.isOpenEmojiPicker && !emojiPickerButtonElement?.contains(event.target)) {
      const emojiPickerGeneralElement = document.querySelector('.emoji-picker-general');
      const emojiPickerElement = document.querySelector('.emoji-picker');
      const emojiMartElement = document.querySelector('.emoji-mart');

      if (
        !emojiPickerGeneralElement?.contains(event.target) &&
        !emojiPickerElement?.contains(event.target) &&
        !emojiMartElement?.contains(event.target)
      ) {
        this.closeEmojiPicker();
      }
    }
  }

  toggleEditorToolbar() {
    this.editor.focus();
    this.showEditorToolbar = !this.showEditorToolbar;
  }

  createLaneBoard() {
    this.store.dispatch(new LaneBoardCreate({ object: this.object, objectId: this.objectId })).subscribe(async () => {
      await this.router.navigate([
        this.routerTenantPipe.transform(`/notes-board/${this.laneBoardId}/${this.objectId}`),
      ]);
    });
  }

  emojiPickerToggle() {
    this.editor.focus();
    this.isOpenEmojiPicker = !this.isOpenEmojiPicker;
    this.needToHandleScrollPosition = this.isOpenEmojiPicker;
    this.store.dispatch(new ChatsEmojiPicker({ emojiPickerIsOpen: this.isOpenEmojiPicker }));

    if (!this.isOpenEmojiPicker) {
      this.editor.focus();
      this.setIsTyping();
    }
  }

  closeEmojiPicker() {
    this.isOpenEmojiPicker = false;
    this.store.dispatch(new ChatsEmojiPicker({ emojiPickerIsOpen: false }));
    this.setIsTyping();
    this.editor?.focus();
  }

  closeThreadEmojiPicker() {
    this.isOpenThreadEmojiPicker = false;
    this.store.dispatch(new ChatsEmojiPicker({ emojiPickerIsOpen: false, isThread: true }));
    this.isThreadTyping = this.testInput(this.threadMessage);
    this.threadEditor?.focus();
  }

  testInput(text) {
    return this.getEscapedText(text) !== '' || /<img /.test(text);
  }

  textChanged(text, isSet = false) {
    if (this.editor && isSet && text !== undefined && text !== null) {
      this.editor.root.innerHTML = text;
    }
    this.newMessage = text;
    this.setIsTyping(text, true);
    this.messageChanged.emit(text);
  }

  editorUpdater(): void {
    this.editorChange.pipe(untilDestroyed(this), debounceTime(500)).subscribe((event: any) => {
      if (event.html !== undefined) {
        this.textChanged(event.html);
      }

      // step 1: set caret position
      this.changeCaretPosition(event);
      // step 2: suggest emoji
      this.suggestEmoji('chat');
      // step 3: save draft
      this.store.dispatch(new ChatsUpdateMessageDraft({ chatId: this.chatId, text: event.html }));
    });
  }

  changeCaretPosition(event) {
    const range = event.editor.getSelection();
    if (range) {
      if (this.isReplace) {
        event.editor.setSelection(this.caretPosition);
        this.isReplace = false;
      } else {
        this.caretPosition = range.index;
      }
    }
  }

  fileChange(event: any): void {
    this.fileChanged.emit(Array.from(event.target.files));
    this.fileInput.nativeElement.value = '';
  }

  mentionFormat(item: any) {
    return `@${item.label} `;
  }

  mentionSelectOpened() {
    this.isMentionSelectOpened = true;
  }

  mentionSelectClosed() {
    this.isMentionSelectOpened = false;
  }

  changeMentionSearch(event) {
    const searchMention = this.mentionChatMembers.filter((member) =>
      member.toLowerCase().startsWith(event.toLowerCase()),
    );
    if (event.includes('@') || !searchMention.length) {
      this.isMentionSelectOpened = false;
    } else if (!event.includes('@') && searchMention.length) {
      this.isMentionSelectOpened = true;
    }
  }

  recordListener(): void {
    this.recordService.recordStartedFrom.pipe(untilDestroyed(this)).subscribe((place) => {
      this.isVoiceRecording = this.recordService.recordType === RecordType.Audio;
      this.isChatFileRecording = this.recordStartedFrom.Chat === place;
      this.isThreadFileRecording = this.recordStartedFrom.Thread === place;
      this.isTicketFileRecording = this.recordStartedFrom.Ticket === place;
      this.isWikiFileRecording = this.recordStartedFrom.Wiki === place;

      this.screenRecordTimeSub = this.recordService.recordDuration.pipe(untilDestroyed(this)).subscribe((res) => {
        this.cdr.markForCheck();
        this.screenRecordTime = res;
      });
    });
  }

  /**
   Post message handler
   */
  onAddMessage(submitType: string) {
    this.closeEmojiPicker();

    const messageFetch = this.quill.valueGetter(this.quill.quillEditor, this.quill.editorElem);
    this.textChanged(messageFetch);

    if (this.files?.length) {
      const text = this.newMessage && removedBlanks(this.newMessage);
      this.uploadFileService.sendFiles({ chatId: this.chatId, text, apiUrl: this.apiUrl || '' }).then(() => {
        this.setIsUploadFiles(false);
      });
      this.setIsUploadFiles(true);
      return;
    }

    if (this.newMessage && this.testInput(this.newMessage)) {
      const socket = this.socketsService.get();
      const time = new Date();

      // remove blank lines at the start and the end
      const text = removedBlanks(this.newMessage);

      const message = {
        chatId: this.chatId,
        text,
        type: 'general',
        userId: this.currentUser._id,
        userName: this.currentUser.userName,
        created_at: time.toISOString(),
        timestamp: time.getTime(),
        isUploading: true,
      };
      this.store.dispatch([
        new ChatsNewMessageToUpload({ message }),
        new ChatsSocketNewMessage({ message }),
        new ChatsClearMessageDraft({ chatId: this.chatId }),
      ]);

      this.store.dispatch(new ChatsMessageCreate(message));

      if (this.platform !== 'web') {
        this.store.dispatch(new ChatsGet());
      }

      this.textChanged('', true);
      this.resetEmojiSearch('chat');
      this.showEditorToolbar = false;
    }
  }

  checkDraftMessage(): void {
    if (this.chatId) {
      const draft = this.store.selectSnapshot(ChatsState.getMessageDraft)(this.chatId);
      this.caretPosition = !!draft ? draft.replace(/<[^>]+>/g, '').length : 0;

      this.textChanged(draft || '', true);

      if (this.platform === 'web') {
        setTimeout(() => this.editor?.setSelection(this.caretPosition), 0);
      }
    }
  }

  selectionChanged($event: SelectionChange) {
    if ($event.range?.index) {
      this.caretPosition = $event.range.index;
    }
  }
  handlePaste(event) {
    const clipboardItems = event.clipboardData.items;
    const imageItem: any = Array.from(clipboardItems).find((item: any) => item.type.indexOf('image') !== -1);

    this.needToHandleScrollPosition = true;
    if (imageItem) {
      // Take image from clipboard
      const imageBlob = imageItem.getAsFile();
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      // Make blob to base64
      reader.onloadend = () => {
        const data = reader.result as string;
        if (this.isSafariScreenshot) {
          // Replace broken url to base64 url
          this.editorContent = replaceLastSrcWithUrl(this.editorContent, data);
        }
      };
    }
  }

  getMentionMembers() {
    return this.onFocus && this.editorContent?.includes('@') ? this.mentionChatMembers : [];
  }

  handleMentionListPosition() {
    const mentions: any = this.mentions;
    if (mentions.searchList?.hidden === false) {
      const mentionList = mentions.searchList?.list?.nativeElement;
      if (!mentionList) {
        return;
      }

      const mentionListWidth = mentionList.clientWidth;
      const startListPosition = mentions.searchList?.coords?.left;
      const totalListWidth = mentionListWidth + startListPosition;

      let paddingLeft = 0;
      const wrapper = this.document.querySelector('.ql-container');
      if (wrapper) {
        const padding = this.document.defaultView?.getComputedStyle(wrapper, null).getPropertyValue('padding');
        const paddingValue = padding && parseInt(padding.split(' ')?.[1]);
        paddingLeft = paddingValue || paddingLeft;
      }

      const contentElementWidth = mentions._element.nativeElement.clientWidth - paddingLeft * 2; // padding left and right the same we need to exclude them
      const mentionListElement = this.defineMentionListElement();
      if (!startListPosition || !contentElementWidth || !mentionListElement) {
        return;
      }

      const defaultMentionMargin = 2; // defined experimental - better cursor position used
      if (startListPosition > contentElementWidth + defaultMentionMargin) {
        // cursor moved to the new row
        const defaultRowHeight = 20;
        const updatedTop = mentions.searchList?.coords?.top
          ? mentions.searchList.coords.top + defaultRowHeight
          : defaultRowHeight;
        mentionListElement.style.top = `${updatedTop}px`;
        this.renderer.setStyle(mentionListElement, 'left', `${paddingLeft}px`);
      } else if (totalListWidth > contentElementWidth + defaultMentionMargin) {
        // move list left to see the content
        this.renderer.setStyle(mentionListElement, 'left', `${startListPosition - mentionListWidth}px`);
      }
    }
  }

  defineMentionListElement() {
    const mentions: any = this.mentions;
    return mentions?.searchList?.element?.nativeElement;
  }

  scrollToCenterOfElement(element: HTMLElement) {
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  handleScrollToCaretPositionOnEditorPastData(contentChaneEventData) {
    let element: HTMLElement | HTMLImageElement;
    const insert = contentChaneEventData.delta?.ops?.[1]?.insert;
    const elementsList: HTMLElement[] = Array.from(
      this.isThread ? this.threadEditor.root.children : this.editor.root.children,
    );

    if (typeof insert === 'string') {
      const elements = elementsList.filter((el) => el.textContent === insert);
      if (elements?.length === 1) {
        element = elements[0];
      } else if (elements?.length > 1) {
        // case when we have couple elements with the same content need to define to which scroll to
        const editorContent = contentChaneEventData.content?.ops?.[0]?.insert;
        const contentBeforeCaretPosition = editorContent?.slice(0, this.caretPosition);
        const countOfPartsDividedBySeparator = contentBeforeCaretPosition?.split(insert);
        const currentElementIndex = countOfPartsDividedBySeparator?.length
          ? countOfPartsDividedBySeparator.length - 1
          : 0;
        element = elements[currentElementIndex];
      } else {
        element = elementsList.find((el) => el.textContent?.includes(insert));
      }
    } else if (typeof insert === 'object' && insert.image) {
      // need to find exact image element
      elementsList.forEach((el: HTMLImageElement) => {
        if (el.children?.length) {
          element = Array.from(el.children).find((image: HTMLImageElement) => {
            return image.src === insert.image;
          }) as HTMLImageElement;
        } else {
          if (el.src === insert.image) {
            element = el;
          }
        }
      });
    }

    if (!element && elementsList?.length) {
      // take last one element to scroll down
      element = elementsList[elementsList.length - 1];
    }

    this.scrollToCenterOfElement(element);
  }

  setIsTyping(text = '', fromText = false) {
    this.isTyping = fromText ? !!text : this.testInput(this.newMessage);
    this.defineIsSendBtnDisabled();
  }

  setIsUploadFiles(isUploadFiles: boolean) {
    this.uploadFiles = isUploadFiles;
    this.defineIsSendBtnDisabled();
  }

  defineIsSendBtnDisabled(): void {
    this.isSendBtnDisabled = (!this.isTyping && !this.files?.length) || this.uploadFiles;
  }
}
