<div class="card__item" [class.dark]="isDark" *transloco="let t; read: 'modals.payment'">
  <div class="card__item-images">
      <img
        *ngFor="let src of feature.image"
        [ngSrc]="src"
        width="42"
        height="42"
        alt="image"
      />
  </div>
  <img [ngSrc]="infinitySvg" width="20" height="10" alt="infinity" />
  <p>{{ feature.label }}</p>
</div>
