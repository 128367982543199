/** Get Push Notifications Settings action **/
export class PushNotificationsSettingsGet {
  static readonly type = '[Notifications Settings] Get';
  constructor(public payload) {}
}

/** Create Push Notifications Settings action **/
export class PushNotificationsSettingsCreate {
  static readonly type = '[Notifications Settings] Create';
  constructor(public payload) {}
}

/** Video call set play ringtone **/
export class NotificationsSetPlayRingtone {
  static readonly type = '[Notifications Settings] Set Play Ringtone';
  constructor(public payload) {}
}

/** Set is playing ringtone **/
export class NotificationsSetIsPlaying {
  static readonly type = '[Notifications Settings] Set Is Playing';
  constructor(public payload) {}
}
