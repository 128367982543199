import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[appExternalLink]',
})
export class ExternalLinkDirective implements AfterViewChecked {
  static isLinkExternal(href: string) {
    return !href.includes(location.hostname);
  }

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    Array.from(this.el.nativeElement.querySelectorAll('a')).forEach((el: any) => {
      if (ExternalLinkDirective.isLinkExternal(el.getAttribute('href'))) {
        el.setAttribute('rel', 'noopener');
        el.setAttribute('target', '_blank');
      }
    });
  }
}
