import { Component, Input, HostListener, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { endOfDay, startOfDay } from 'date-fns';

import { ConversionActionType, NoteModalComponent } from '../../../modals/note/note.component';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { CalendarEventModalComponent } from '../../../modals/calendar-event/calendar-event.component';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { ConfigService } from '../../services/config.service';
import { ToggleSidebar } from '../../store/actions/configs.action';
import { NotesGet, NotesLabelsGet } from '../../store/actions/notes.action';
import { TicketsCreate, TicketsDelete } from '../../store/actions/board.action';
import { CalendarEventCreate, CalendarEventDelete } from '../../store/actions/calendar-events.action';
import { ConfigsState } from '../../store/states/configs.state';
import { NotesState } from '../../store/states/notes.state';
import { AuthState } from '../../store/states/auth.state';
import { MinimizeService } from '../../services/minimize.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [SafeHtmlPipe],
})
export class NotesComponent implements OnInit, OnDestroy {
  @Input() object = 'users';
  @Input() objectId: string;

  config: any = {};
  platform = 'web';
  isMobile = false;
  sidebarConfig = null;
  dragulaSub = new Subscription();
  destroy$: Subject<void> = new Subject<void>();
  notesContainer = 'note-dragula-container';

  showMoreButton;
  showLessButton;

  user: any;
  notes: any;
  allNotes: any;
  labels = [];
  selectedLabels = [];
  convertDropdownOpen = false;
  convertIntoList: { title: string; icon: string; action: ConversionActionType }[] = [
    { title: 'Event', icon: 'calendar', action: ConversionActionType.Event },
    { title: 'Ticket', icon: 'board', action: ConversionActionType.Ticket },
  ];

  constructor(
    private store: Store,
    private actions: Actions,
    private toastr: ToastrService,
    private configService: ConfigService,
    private dragulaService: DragulaService,
    private minimizeService: MinimizeService,
    private translacoService: TranslocoService,
    protected readonly modal: NgbModal,
    public cdr: ChangeDetectorRef,
  ) {
    this.config = this.configService.templateConf;

    if (this.platform === 'web') {
      this.initDragula();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    if (e.keyCode === 84 && e.ctrlKey && e.altKey) {
      // handle Ctrl+Alt+T for create a note
      e.preventDefault();
      this.addNote();
    }
  }

  ngOnInit() {
    this.dispatchApiRequests();

    this.store
      .select(NotesState.getNotes)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.allNotes = [...res].sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        this.filterNotesByLabels();

        this.showMoreButton = Array(this.notes.length).fill(null);
        this.showLessButton = Array(this.notes.length).fill(null);

        this.cdr.detectChanges();
      });

    this.store.dispatch(new NotesLabelsGet({}));
    this.store
      .select(NotesState.getNotesLabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.labels = res));

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => (this.user = user));

    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(TicketsCreate, TicketsDelete, CalendarEventCreate, CalendarEventDelete),
      )
      .subscribe((res) => this.dispatchApiRequests());

    if (this.platform === 'web') {
      this.store
        .select(ConfigsState.getSidebarConfigs)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => (this.sidebarConfig = res));
    }
  }

  ngOnDestroy() {
    this.dragulaSub.unsubscribe();
    this.dragulaService.destroy(this.notesContainer);

    this.destroy$.next();
    this.destroy$.complete();
  }

  initDragula(): void {
    this.dragulaService?.destroy(this.notesContainer);
    this.dragulaService.createGroup(this.notesContainer, {
      revertOnSpill: true,
      copy: true,
      accepts: (el, target, source, sibling) => {
        return true;
      },
      moves: (el, container, handle) =>
        el.classList.contains('is-draggable') && !handle.classList.contains('show-more-text'),
    });

    this.dragulaSub.add(
      this.dragulaService
        .drag(this.notesContainer)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ name, el, source }) => {
          el.classList.add('list-group-item-dragging');
          return el.classList.contains('list-group-item');
        }),
    );

    this.dragulaSub.add(
      this.dragulaService
        .drop(this.notesContainer)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ name, el, source }) => {
          const listItem = source.getElementsByClassName('list-group-item-dragging');
          if (listItem[0]) {
            listItem[0].classList.remove('list-group-item-dragging');
          }
        }),
    );
  }

  dispatchApiRequests = () => {
    this.store.dispatch(new NotesGet({ object: this.object, objectId: this.objectId }));
  };

  filterNotesByLabels() {
    if (this.selectedLabels?.length) {
      this.notes = this.allNotes.filter((note) => {
        if (note.labels?.length) {
          for (const label of note.labels) {
            if (this.selectedLabels.includes(label)) {
              return true;
            }
          }
        }
        return false;
      });
    } else {
      this.notes = this.allNotes;
    }
  }

  public getSelectedItemsCount(selected: any[], labels: any[]) {
    return selected.filter((s) => labels.map((c) => c.id).includes(s.id)).length;
  }

  addNote() {
    const noteModalRef = this.modal.open(NoteModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    noteModalRef.componentInstance.modalData = {
      action: this.translacoService.translate('notes.create-note'),
      object: this.object,
      objectId: this.objectId,
      note: {
        _id: null,
        title: '',
        text: '',
        labels: [],
        created_at: null,
      },
    };
  }

  viewNote(note) {
    const noteModalRef = this.modal.open(NoteModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    noteModalRef.componentInstance.modalData = {
      action: this.translacoService.translate('notes.edit-note'),
      object: this.object,
      objectId: this.objectId,
      note,
    };
  }

  showMoreClicked(index) {
    const noteElement = document.getElementById('note-' + index);
    const textElement = document.getElementById('note-text-' + index);

    if (textElement.offsetHeight > 300) {
      noteElement.style.maxHeight = 'unset';
      this.showLessButton[index] = true;
      this.showMoreButton[index] = false;
    }

    this.cdr.detectChanges();
  }

  showLessClicked(index) {
    const element = document.getElementById('note-' + index);

    element.style.maxHeight = '300px';
    this.showMoreButton[index] = true;
    this.showLessButton[index] = false;

    this.cdr.detectChanges();
  }

  isOverflown(index) {
    const element = document.getElementById('note-' + index);

    this.showMoreButton[index] = element.scrollHeight > 300;

    return element.scrollHeight > 300;
  }

  convertDropdownToggle(e) {
    this.convertDropdownOpen = e;
  }

  convertNote(action: ConversionActionType, noteId: string, title: string, text: string): void {
    if (action === ConversionActionType.Ticket) {
      const ticketModalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => ticketModalRef.componentInstance.closeImagePreview(true),
      });
      ticketModalRef.componentInstance.ticketData = {
        noteId: noteId,
        data: { title: title, description: text },
        ticketCreator: this.user._id,
        showToastMessage: true,
      };
      this.minimizeService.minimizeInit(ticketModalRef);
    }

    if (action === ConversionActionType.Event) {
      const eventModalRef = this.modal.open(CalendarEventModalComponent, {
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
      });

      eventModalRef.componentInstance.modalData = {
        action: 'Add new event',
        displayName: this.translacoService.translate('calendar.add-new-event'),
        noteId: noteId,
        event: {
          title: title,
          description: text,
          repeat: 'never',
          reminder: 'without',
          object: this.object,
          start: startOfDay(new Date()),
          end: endOfDay(new Date()),
          spaceId: undefined,
          projectId: undefined,
          allDay: false,
          workdays: false,
        },
      };
    }
  }

  closeSidebar() {
    this.store.dispatch(new ToggleSidebar({ isOpened: false, sidebarContentType: 'notes' }));
    this.cdr.detectChanges();
  }
}
