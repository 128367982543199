import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: number): string {
    const limit = args || 10;
    const trail = '...';

    return value?.length > 0 && value?.length > limit ? value?.substring(0, limit) + trail : value;
  }
}
