import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GenericMessageComponent } from '../generic-message/generic-message.component';

@Component({
  selector: 'app-video-message',
  templateUrl: './video-message.component.html',
  styleUrls: ['./video-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoMessageComponent extends GenericMessageComponent {}
