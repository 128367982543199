import { Input, Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { ImageService } from '../services/image.service';
import { ChatTypeEnum } from '../components/chat/chat.service';

@Directive({
  selector: '[appImgChat]',
})
export class ImgDirective implements OnInit {
  @Input() appImgChat: ChatTypeEnum;
  constructor(private el: ElementRef, private imageService: ImageService) {}

  ngOnInit(): void {
    this.imageService.imageLoading(this.el.nativeElement, this.appImgChat);
  }

  @HostListener('load')
  onLoad() {
    setTimeout(() => this.imageService.imageLoadedOrError(this.el.nativeElement, this.appImgChat), 100);
  }

  @HostListener('error')
  onError() {
    setTimeout(() => this.imageService.imageLoadedOrError(this.el.nativeElement, this.appImgChat), 100);
  }
}
