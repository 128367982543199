import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MediaService } from './media.service';
import { FilesHelper } from '../utils/files-helper';
import { AuthState } from '../store/states/auth.state';
import { DocumentDownloadBase64 } from '../store/actions/documents.action';
import { FilesGetBase64ResDto } from '../../api/models/files-get-base-64-res-dto';

export interface IUser {
  _id?: string;
  userName?: string;
  email?: string;
}

interface IOptions {
  user?: IUser;
  updated_at?: string;
}

interface IFileData {
  url: string;
  fileName: string;
  originalFileName: string;
  fileType: string;
  updated_at?: string;
  created_at?: string;
  isBase64?: boolean;
}

interface ImagePreview {
  userId: string;
  userName: string;
  fileData: IFileData;
}

interface IDataRoomDownloadFiles {
  _id: string;
  url?: string;
  originalFileName?: string;
  fromDataRoom?: boolean;
}

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class FullImagePreviewService {
  imagesData: ImagePreview[] = [];
  updateEvent: EventEmitter<any> = new EventEmitter();
  imagePreview: ImagePreview;
  isFirstPreviewImage = true;
  isLastPreviewImage = true;
  maxZoomValue = 9;
  zoomState = 0;
  currentImageElement: HTMLImageElement = null;
  private renderer: Renderer2;
  imageElementRotateDeg = 0;
  zoomOutTransformState = 'matrix(1, 0, 0, 1, 0, 0)';
  platform: string;
  user: IUser;
  private downloadedBase64File$$ = new BehaviorSubject<FilesGetBase64ResDto>(null);
  public downloadedBase64File$ = this.downloadedBase64File$$.asObservable();
  modalId = null;
  imagePerModal: Record<string, any> = null;

  constructor(
    private mediaService: MediaService,
    private filesHelper: FilesHelper,
    rendererFactory: RendererFactory2,
    private store: Store,
  ) {
    this.initSubscribers();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openImagePreview(imageUrl: string) {
    const imagePreview = this.imagesData.find((image) => image.fileData.url === imageUrl);
    if (imagePreview) {
      this.imagePreview = imagePreview;
      const currentImageIndex = this.getParticularMessage();
      this.invisibleImagePreviewArrows(currentImageIndex);
    }
  }

  updateModal(): void {
    this.updateEvent.next({
      imagePreview: this.imagePreview,
      isLastPreviewImage: this.isLastPreviewImage,
      isFirstPreviewImage: this.isFirstPreviewImage,
    });
  }

  invisibleImagePreviewArrows(imageIndex: number): void {
    this.isFirstPreviewImage = imageIndex === 0;
    this.isLastPreviewImage = imageIndex === this.imagesData.length - 1;
  }

  setImagesData(imagesData: ImagePreview[]): void {
    this.imagesData = imagesData;
  }

  prepareImages(description: string, options?: IOptions): void {
    const preparedImages = [];
    const imagesUrls = this.mediaService.fetchImagesFromString(description);

    if (imagesUrls.length) {
      imagesUrls.forEach((imageUrl) => {
        const fileName = this.filesHelper.getFileNameWithoutExtensionHttp(imageUrl);
        let originalFileName = this.filesHelper.getFileNameWithoutExtensionHttp(imageUrl);
        const fileType = this.filesHelper.getFileType(imageUrl);

        let isBase64 = false;
        if (!originalFileName) {
          // base 64 type
          originalFileName = this.filesHelper.getFileNameFromBase64(imageUrl);
          isBase64 = true;
        }

        preparedImages.push({
          userId: options?.user?._id || this.user?._id,
          userName: options?.user?.userName || this.user?.userName,
          fileData: {
            url: imageUrl,
            fileName,
            originalFileName,
            fileType,
            updated_at: options?.updated_at,
            created_at: '',
            isBase64,
          },
        });
      });

      preparedImages[0].isFirstPreviewMedia = true;
      preparedImages[preparedImages.length - 1].isLastPreviewMedia = true;
    }

    this.setImagesData(preparedImages);
  }

  getParticularMessage(): number {
    return this.imagesData.findIndex((image) => image.fileData.url === this.imagePreview?.fileData?.url);
  }

  goToPreviousImage(): void {
    this.goToAnotherImage('left');
  }

  goToNextImage(): void {
    this.goToAnotherImage('right');
  }

  goToAnotherImage(direction: 'left' | 'right'): void {
    if (this.platform === 'mobile' && this.isZoomedImage()) {
      // disabled swipe to next image when picture is zoomed
      return;
    }

    this.onResetOptions();
    const currentImageIndex = this.getParticularMessage();
    const followToIndex = direction === 'left' ? currentImageIndex - 1 : currentImageIndex + 1;

    if (this.platform !== 'mobile') {
      this.invisibleImagePreviewArrows(followToIndex);
    }

    this.imagePreview = { ...this.imagesData[followToIndex] };

    this.updateModal();
  }

  downloadFile(): void {
    if (this.imagePreview?.fileData?.isBase64) {
      this.filesHelper.downloadFileFromBase64(
        this.imagePreview.fileData.url,
        this.imagePreview.fileData.originalFileName,
      );
      return;
    }

    if (this.imagePreview?.fileData?.url) {
      this.filesHelper.downloadFile(
        this.imagePreview.fileData.url,
        this.imagePreview.fileData.originalFileName,
        this.platform,
      );
    }
  }

  createMouseWheelEvent(type: 'increase' | 'decrease') {
    this.initImageElement();
    if (!this.currentImageElement) {
      return;
    }

    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;

    const newEvent = new WheelEvent('wheel', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: innerWidth / 2,
      clientY: innerHeight / 2,
      deltaY: type === 'increase' ? -120 : 120,
    });

    this.currentImageElement.dispatchEvent(newEvent);
  }

  setZoomState(event: WheelEvent) {
    this.initImageElement();

    if (!this.currentImageElement || event.target !== this.currentImageElement) {
      return;
    }

    const { deltaY } = event;
    if (deltaY) {
      if (deltaY < 0 && this.zoomState < this.maxZoomValue) {
        // increase state
        this.zoomState += 1;
      }

      if (deltaY > 0 && this.zoomState > 0) {
        // decrease state
        this.zoomState -= 1;
      }
    }
  }

  onRange(inputValue: string | number) {
    this.initImageElement();
    this.createMouseWheelEvent(Number(inputValue) > this.zoomState ? 'increase' : 'decrease');
  }

  initImageElement() {
    if (this.modalId) {
      // if opened from redesigned modal
      const imagePerModel = this.imagePerModal?.[this.modalId];
      if (imagePerModel) {
        this.currentImageElement = imagePerModel;
      } else {
        this.currentImageElement = document.getElementById(this.modalId) as HTMLImageElement;
        if (this.currentImageElement) {
          this.imagePerModal = {
            [this.modalId]: this.currentImageElement,
          };
        }
      }
    } else if (!this.currentImageElement) {
      this.currentImageElement = document.querySelector('.pinch-zoom-content img');
    }
  }

  onRotateImage() {
    this.initImageElement();
    if (!this.currentImageElement) {
      return;
    }

    this.imageElementRotateDeg = this.imageElementRotateDeg > 180 ? 0 : this.imageElementRotateDeg + 90;
    const container: HTMLDivElement = this.getPinchZoomContentContainer();
    if (container) {
      if (this.imageElementRotateDeg === 90 || this.imageElementRotateDeg === 270) {
        this.currentImageElement.style['max-width'] = `${container.clientHeight}px`;
      }
      if (this.imageElementRotateDeg === 0 || this.imageElementRotateDeg === 180) {
        this.currentImageElement.style['max-width'] = '100%';
      }
    }

    this.renderer.setStyle(this.currentImageElement, 'transform', `rotate(${this.imageElementRotateDeg}deg)`);
  }

  onResetOptions(disableMobileOption = false) {
    if (this.platform === 'mobile' && this.isZoomedImage() && !disableMobileOption) {
      // disabled swipe to next image when picture is zoomed
      return;
    }

    if (this.imageElementRotateDeg !== 0) {
      this.imageElementRotateDeg = 270;
      this.onRotateImage();
    }

    if (this.isZoomedImage() || this.modalId || this.zoomState !== 0) {
      for (let i = this.maxZoomValue; i > 0; i -= 1) {
        this.createMouseWheelEvent('decrease');
      }
      this.zoomState = 0;
    }

    this.currentImageElement = null;
    this.modalId = null;
    this.imagePerModal = null;
  }

  getPinchZoomContentContainer(): HTMLDivElement {
    return document.querySelector('.pinch-zoom-content');
  }

  isZoomedImage(): boolean {
    const container = this.getPinchZoomContentContainer();
    const isContainerHasTransformStyle = container?.style['transform'];
    return isContainerHasTransformStyle && isContainerHasTransformStyle !== this.zoomOutTransformState;
  }

  public setDownloadedBase64File(file: FilesGetBase64ResDto) {
    this.downloadedBase64File$$.next(file);
  }

  dataRoomDownloadFiles({ _id: id, url, originalFileName, fromDataRoom = true }: IDataRoomDownloadFiles) {
    const isFileWithPreSignedUrl = url ? this.filesHelper.isPreSignedUrl(url) : true;
    if (isFileWithPreSignedUrl) {
      // different config for data room and common buckets also different file types
      this.store.dispatch(new DocumentDownloadBase64({ id, fromDataRoom }));
    } else {
      this.filesHelper.downloadFile(url, originalFileName, 'web');
    }
  }

  initSubscribers() {
    this.store
      .select(AuthState.getPlatform)
      .pipe(untilDestroyed(this))
      .subscribe((platform) => {
        this.platform = platform;
      });

    this.store
      .select(AuthState.getUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });

    this.downloadedBase64File$.pipe(untilDestroyed(this)).subscribe((file) => {
      if (file) {
        const { data: url, fileName } = file;
        this.filesHelper.downloadFileFromBase64(url, fileName);
        this.downloadedBase64File$$.next(null);
      }
    });
  }

  initInModal(modalId: string) {
    this.modalId = modalId;
    this.onResetOptions();
    this.modalId = modalId;
    this.initImageElement();
  }
}
