import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

import { TwoFARoutes } from './two-factor-authentication.constants';
import { LocalStorageService } from 'ngx-localstorage';
import { TwoFaGetOption } from '../../../shared/store/actions/auth.action';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum VerificationSteps {
  Login = 'login',
  CheckVerificationCode = 'check-verification-code',
  RecoveryPhoneNumber = 'recovery-access',
  PhoneNumber = 'phone-number',
  RecoveryComplete = 'recovery-complete',
  EnableTwoFa = 'enable-two-fa',
  DisableTwoFa = 'disable-two-fa',
}

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class TwoFactorAuthenticationService {
  public page = '';
  public isAuthenticated = false;
  public loginData: {
    tfaRecoveryToken: string;
    phoneNumber: string;
    question: string;
    tenant: string;
    email: string;
    password: string;
  };
  public verificationStep: VerificationSteps;
  public twoFaOptions = new Subject<boolean>();
  public isLoaded = new BehaviorSubject(false);
  public isMobile = false;

  private onlyAuthPages = [TwoFARoutes.PhoneNumber, TwoFARoutes.Question, TwoFARoutes.VerificationCode];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  init() {
    this.isMobile = Capacitor.getPlatform() !== 'web';
    this.isAuthenticated = !!this.localStorageService.get('accessToken');
    if (this.isAuthenticated) {
      this.store.dispatch(new TwoFaGetOption()).subscribe((store) => {
        const options = store.Auth.twoFaOptions;

        const isDisableTwoFACompleted = this.localStorageService.get(VerificationSteps.DisableTwoFa);

        if (isDisableTwoFACompleted) {
          this.twoFaOptions.next(store.Auth.twoFaOptions);
          return;
        }

        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
          if (
            options &&
            !this.router.url.includes(TwoFARoutes.Disable) &&
            !this.router.url.includes(TwoFARoutes.Question)
          ) {
            this.router.navigate(['/']);
          } else {
            this.twoFaOptions.next(store.Auth.twoFaOptions);
          }
        });
      });
    } else if (!this.loginData && !this.onlyAuthPages.includes(this.page)) {
      this.router.navigate(['/']);
    }
    this.isLoaded.next(true);
  }
}
