<!--Notifications Table starts-->
<ng-container *ngIf="object + '::' + objectId + '::actionsLogGetList' | checkPermission">
  <div
    class="card notifications-container"
    *transloco="let t"
    [ngClass]="{
    'action-log-light': config?.layout.variant === 'Light',
    'action-log-dark': config?.layout.variant === 'Dark',
    'navbar-activity-stream': isNavbar
  }"
  >
    <div *ngIf="object !== 'users' && !isNavbar" class="card-header">
      <div class="card-header__content">
        <h4 class="card-title">{{ t('full-layout.activity-stream') }}</h4>
      </div>
    </div>

    <div *ngIf="object === 'users'" class="notifications-header d-flex align-items-center justify-content-between">
      <span class="notify-title">{{ t('full-layout.notifications') }}</span>

      <span class="text-right">
        <button class="btn btn-sm btn-text" (click)="markAsRead()">{{ t('full-layout.btn-mark-as-read') }}</button>
        <button
          *ngIf="!isMobile"
          class="btn btn-sm btn-text pin-notifications ml-2"
          [ngbTooltip]="sidebarConfig?.isOpened ? t('full-layout.tooltip-close-notifications') : t('full-layout.tooltip-open-notifications')"
          placement="bottom-right"
          (click)="toggleSidebar()"
        >
          {{ sidebarConfig?.isOpened ? t('full-layout.btn-close-notifications') : t('full-layout.btn-open-notifications') }}
        </button>
      </span>
    </div>

    <div class="card-content scrollable-container">
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
        <div *ngIf="isLogLoaded; else loading" class="notification-tab-content">
          <div *ngFor="let action of actionsLogs; trackBy: trackById;" class="media d-flex align-items-center w-100">
            <div *ngIf="action.isUnread" class="not-read"></div>

            <div class="media-left mx-2">
              <app-avatar [userId]="action.ownerUserId" [width]="36" [height]="36"></app-avatar>
            </div>

            <div class="media-body">
              <div class="d-flex align-items-center mb-1">
                <div class="time-from-now cursor-pointer" (click)="actionButton(action)">
                  {{ action.created_at | fromNow: lang }}
                </div>
                <div *ngIf="object === 'users' && action.breadcrumbs?.length" class="breadcrumbs">
                  <div class="bull">&bull;</div>
                  <ng-container *ngFor="let crumb of action.breadcrumbs; index as i">
                    <span *ngIf="i !== 0" class="angle-right"> > </span>
                    <a
                      (click)="crumb.object === 'users' ? openUserChat(crumb.objectId) : null"
                      [routerLink]="crumb.chatId ? ('/chat/' + crumb.chatId | routerTenant) : null"
                      [class.breadcrumbs-disabled]="(!crumb.chatId && crumb.object !== 'users') ||
                   (crumb.object === 'users' && userData.userName === crumb.name)"
                    >{{ crumb.name || crumb.chatName }}</a>
                  </ng-container>
                </div>
              </div>

              <div class="noti-text m-0" [innerHTML]="action.htmlText" (click)="actionButton(action)"></div>
            </div>
          </div>

          <div *ngIf="actionsLogs?.length === 0" class="py-2"><span>{{ t('full-layout.actions-list-is-empty') }}</span></div>
        </div>
      </ng-scrollbar>
      <!-- Loading -->
      <ng-template #loading>
        <div class="loading" [class.loading-dark]="config?.layout.variant === 'Dark'">
          <app-svg name="spinner" width="38" height="38" fill="#C6C8D7" class="loading-icon"></app-svg>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
<!--Notifications Table Ends-->
