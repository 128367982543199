<div [ngClass]="{
       'message-text-light': layoutVariant === 'Light',
       'message-text-dark': layoutVariant === 'Dark'
       }"
>
  <!-- github pull request message -->
  <app-webhook-pr-message
    *ngIf="webhookMessageData?.eventName === GithubEventTypes.PULL_REQUEST_OPENED ||
           webhookMessageData?.eventName === GithubEventTypes.PULL_REQUEST_CLOSED"
    [repositoryName]="prMessageData?.repository?.full_name"
    [userName]="prMessageData?.pull_request?.user?.login"
    [userUrl]="prMessageData?.pull_request?.user?.html_url"
    [prActionTitle]="prActionTitle"
    [prTitle]="prMessageData?.pull_request?.title"
    [prDescription]="prMessageData?.pull_request?.body"
    [prNumber]="prMessageData?.pull_request?.number"
    [prUrl]="prMessageData?.pull_request?.html_url"
    [sourceBranch]="prMessageData?.pull_request?.head?.ref"
    [targetBranch]="prMessageData?.pull_request?.base?.ref"
    [showDetails]="showMRDetails"
    [assignees]="assignees"
    [reviewers]="reviewers"
  ></app-webhook-pr-message>

  <!-- github push message -->
  <app-webhook-push-message
    *ngIf="webhookMessageData?.eventName === GithubEventTypes.PUSH"
    [pusherName]="pushMessageData?.pusher?.name"
    [description]="pushMsgDescription"
    [repositoryName]="pushMessageData?.repository?.full_name"
    [commitId]="pushMessageData?.head_commit?.id"
    [commitUrl]="pushMessageData?.head_commit?.url"
    [commitToFullPath]="fullBranchPath"
  ></app-webhook-push-message>

  <app-webhook-job-status
    *ngIf="webhookMessageData?.eventName === GithubEventTypes.WORKFLOW_JOB_IN_PROGRESS ||
           webhookMessageData?.eventName === GithubEventTypes.WORKFLOW_JOB_COMPLETED"
    [state]="statusLabel"
    [jobType]="jobMessageData?.workflow_job?.name"
    [pipeName]="workflowName"
    [pipeUrl]="jobMessageData?.workflow_job?.html_url"
    [repoFullName]="jobMessageData?.repository?.full_name"
    [isFailed]="isJobFailed"
  ></app-webhook-job-status>
</div>
