<section class="billing" [class.mobile]="(platform | async) !== 'web'" *transloco="let t">
  <div class="billing__header">
    <h4 class="billing__subtitle">{{ t('modals.plan.edit-billing') }}</h4>
  </div>
  <form [formGroup]="billingForm" (ngSubmit)="handleSubmit()">
    <div class="billing__body">
      <h4 class="billing__subtitle">{{ t('modals.plan.billing-info') }}</h4>

      <div class="billing__fields">
        <div class="billing__box">
          <app-input-text formControlName="firstName" [legend]="t('fields.first-name')" [placeHolder]="t('fields.placeholder-first-name')" [widthFull]="true"/>
          <app-input-text formControlName="lastName" [legend]="t('fields.last-name')" [placeHolder]="t('fields.placeholder-last-name')" [widthFull]="true"/>
        </div>

        <div class="billing__box">
          <app-input-text formControlName="companyName" [legend]="t('fields.company')" [placeHolder]="t('fields.placeholder-company')" [widthFull]="true"/>
          <app-input-text formControlName="address" [legend]="t('fields.address')" [placeHolder]="t('fields.placeholder-address')" [widthFull]="true"/>
        </div>

        <div class="billing__box">
          <app-input-selector
            [items]="countryItems"
            formControlName="countryId"
            bindLabel="nameEn"
            bindValue="id"
            [legend]="t('fields.country')"
            [placeHolder]="t('fields.placeholder-select')"
            [widthFull]="true"
          />
          <app-input-text formControlName="vatId" [legend]="t('fields.vat-id')" [placeHolder]="t('fields.placeholder-vat-id')" [widthFull]="true"/>
        </div>

        <div class="billing__box">
          <app-input-text formControlName="city" [legend]="t('fields.city')" [placeHolder]="t('fields.placeholder-city')" [widthFull]="true"/>
          <app-input-text formControlName="postalCode" [legend]="t('fields.postal-code')" [placeHolder]="t('fields.placeholder-postal-code')" [widthFull]="true"/>
        </div>

        <app-input-text formControlName="billingEmail" [legend]="t('fields.billing-email')" [placeHolder]="t('fields.placeholder-email')" [widthFull]="true"/>
      </div>
    </div>
    <div class="billing__footer">
      <app-button [label]="t('common.btn-cancel')" customClasses="font-size-16" (handleClick)="closeModal()" variantButton="secondary" />
      <app-button [isLoading]="isLoading" [label]="t('modals.admin-user-edit.btn-save-changes')" [disabled]="!isValid" customClasses="font-size-16" typeButton="submit" />
    </div>
  </form>
</section>
