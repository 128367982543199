import { ThreadSocketData } from '../../interfaces/thread-socket-data';
import { Message } from '../../components/chat/chat.model';
import { PollUpdateReqDto } from '../../../api/models/poll-update-req-dto';
import { PinnedMessageUpsertReqDto } from '../../../api/models/pinned-message-upsert-req-dto';
import { PinnedMessagesOrderUpdateReqDto } from '../../../api/models/pinned-messages-order-update-req-dto';
import { PinnedMessagesDbDto } from '../../../api/models/pinned-messages-db-dto';
import { PinnedMessageOrderUpdateReqDto } from '../../../api/models/pinned-message-order-update-req-dto';
import { ChatsMessageCreateReqDto } from '../../../api/models/chats-message-create-req-dto';
import { GroupChatOrderUpdateReqDto } from '../../../api/models/group-chat-order-update-req-dto';
import { IChatFilesPaginationQuery } from '../../data/pagination';
import { CalendarEventsDbDto } from '../../../api/models/calendar-events-db-dto';

/** Set chat active video-call rooms **/
export class ChatsSetActiveVideoCallRooms {
  static readonly type = '[Chats] SetActiveVideoCallRooms';
}

/** Participant joined to video-call room **/
export class ChatsVideoCallParticipantJoined {
  static readonly type = '[Chats] VideoCallParticipantJoined';

  constructor(public payload) {}
}

/** Participant left video-call room **/
export class ChatsVideoCallParticipantLeft {
  static readonly type = '[Chats] VideoCallParticipantLeft';

  constructor(public payload) {}
}

/** Set chatId **/
export class ChatsSetChatId {
  static readonly type = '[Chats] Set ChatId';

  constructor(public payload) {}
}

/** Clear chat **/
export class ChatsClear {
  static readonly type = '[Chats] Clear';

  constructor() {}
}

export class ChatMessagesClear {
  static readonly type = '[Chats] Clear Messages';

  constructor(public payload: { chatId: string }) {}
}

/** New message by socket **/
export class ChatsSocketNewMessage {
  static readonly type = '[Chats] SocketNewMessage';

  constructor(public payload) {}
}

export class ChatsNewMessageToUpload {
  static readonly type = '[Chats] ChatsNewMessageToUpload';

  constructor(public payload) {}
}

/** Message updated by socket **/
export class ChatsSocketUpdatedMessage {
  static readonly type = '[Chats] SocketUpdatedMessage';
  constructor(public payload) {}
}

/** Poll updated by socket **/
export class ChatsSocketUpdatedPoll {
  static readonly type = '[Chats] SocketUpdatedPoll';
  constructor(public payload) {}
}

/** Message deleted by socket **/
export class ChatsSocketDeletedMessage {
  static readonly type = '[Chats] SocketDeletedMessage';

  constructor(public payload) {}
}

/** Delete out of limit messages **/
export class ChatsDeleteMessages {
  static readonly type = '[Chats] DeleteMessages';

  constructor(public payload) {}
}

/** Set initial messages (used by sockets) **/
export class ChatsSetMessages {
  static readonly type = '[Chats] SetMessages';

  constructor(public payload) {}
}

/** Get chat messages via REST API **/

export class ChatsGetMessages {
  static readonly type = '[Chats] GetMessages';

  constructor(public payload) {}
}

/** Get thread messages via REST API **/

export class ThreadGetMessages {
  static readonly type = '[Chats] ThreadGetMessages';

  constructor(public payload) {}
}
/** Get thread counters via REST API **/

export class ThreadGetCounters {
  static readonly type = '[Chats] ThreadGetCounters';

  constructor(public payload?) {}
}
/** Get thread list via REST API **/

export class ThreadGetList {
  static readonly type = '[Chats] ThreadGetList';

  constructor(public payload?) {}
}
/** Get chats by user id via REST API **/
export class ChatsGetMembers {
  static readonly type = '[Chats] GetChatsMembers';

  constructor(public payload) {}
}

/** Get chats by user id via REST API **/
export class ChatsGet {
  static readonly type = '[Chats] GetChats';

  constructor(public payload?) {}
}

export class ChatsUpdateCounter {
  static readonly type = '[Chats] ChatsUpdateCounter';

  constructor(public payload) {}
}

/** Set search results (used by sockets) **/
export class ChatsSetSearchResults {
  static readonly type = '[Chats] SetSearchResults';

  constructor(public payload) {}
}

/** Search messages in chat **/
export class ChatsSearchMessages {
  static readonly type = '[Chats] SearchMessages';

  constructor(public payload) {}
}

/** Set chats (used by sockets) **/
export class ChatsSet {
  static readonly type = '[Chats] Set Chats';

  constructor() {}
}

/** Clear upload files **/
export class ChatsClearUploadFiles {
  static readonly type = '[Chats] Clear upload files';

  constructor() {}
}

/** ChangeStatus **/
export class ChatsReconnectStatus {
  static readonly type = '[Chats] Change reconnect status';

  constructor(public payload) {}
}

/** Set chats (used by sockets) **/
export class ChatsSetChats {
  static readonly type = '[Chats] SetChats';

  constructor(public payload) {}
}

/** Set chats members (used by sockets) **/
export class ChatsSetChatsMembers {
  static readonly type = '[Chats] SetChatsMembers';

  constructor(public payload) {}
}

/** Remove chats members (used by sockets) **/
export class ChatsRemoveChatsMembers {
  static readonly type = '[Chats] RemoveChatsMembers';

  constructor(public payload: { chatId: string }) {}
}

/** Update chats members after change some user data (used by sockets) **/
export class ChatsUpdateChatsMembers {
  static readonly type = '[Chats] UpdateChatsMembers';

  constructor(public payload) {}
}

/** delete chats members after change some user data (used by sockets) **/
export class ChatsDeleteChatsMembers {
  static readonly type = '[Chats] DeleteChatsMembers';

  constructor(public payload: { chatId: string; userId: string }) {}
}

/** Set chats as read **/
export class ChatsMarkAsRead {
  static readonly type = '[Chats] MarkAsRead';

  constructor(public payload) {}
}

/** Set thread as read **/
export class ThreadMarkAsRead {
  static readonly type = '[Chats] ThreadMarkAsRead';

  constructor(public payload) {}
}

/** Set thread as unread **/
export class ThreadsMarkAsUnRead {
  static readonly type = '[Chats] ThreadMarkAsUnRead';

  constructor(public payload) {}
}

export class ChatsEmojiPicker {
  static readonly type = '[Chats] EmojiPicker';

  constructor(public payload) {}
}

export class ChatsSetEmoji {
  static readonly type = '[Chats] SetEmoji';

  constructor(public payload) {}
}

/** Upload file via chat **/
export class ChatsUploadFile {
  static readonly type = '[Chats] UploadFile';

  constructor(public payload) {}
}

/** Download chat file **/
export class ChatsFileDownload {
  static readonly type = '[Chats] FileDownload';

  constructor(public payload) {}
}

/** Chat file download loading **/
export class ChatsFileDownloadLoading {
  static readonly type = '[Chats] FileDownloadLoading';

  constructor(public payload) {}
}

export class ChatsSetCurrentChatName {
  static readonly type = '[Chats] SetCurrentChatName';

  constructor(public payload) {}
}

export class ChatsUpdateMessageDraft {
  static readonly type = '[Chats] UpdateMessagesDraft';

  constructor(public payload) {}
}

export class SetOfflineStatus {
  static readonly type = '[Chats] Update status';

  constructor(public payload) {}
}

export class ChatsClearMessageDraft {
  static readonly type = '[Chats] ClearMessageDraft';

  constructor(public payload) {}
}

export class ChatsThreadSetEmoji {
  static readonly type = '[Chats] ThreadSetEmoji';

  constructor(public payload) {}
}
export class ChatsInit {
  static readonly type = '[Chats] Init';

  constructor(public payload) {}
}

export class ChatsAddEmojiReaction {
  static readonly type = '[Chats] AddEmojiReaction';

  constructor(public payload) {}
}

export class ChatsDeleteEmojiReaction {
  static readonly type = '[Chats] DeleteEmojiReaction';

  constructor(public payload) {}
}

export class ChatsResetEmojiReactionIsUpdated {
  static readonly type = '[Chats] ResetEmojiReactionIsUpdated';

  constructor() {}
}

export class ChatsSetThreadsList {
  static readonly type = '[Chats] Set ThreadsList';
  constructor(public payload) {}
}

export class ChatsUpdateThreadsList {
  static readonly type = '[Chats] Update Threads List';
  constructor(public payload: ThreadSocketData) {}
}

export class ChatsUpdateThreadInList {
  static readonly type = '[Chats] Update Thread in List';
  constructor(public payload: Message) {}
}

export class ChatsSetThreadsCounters {
  static readonly type = '[Chats] SetThreadsCounters';
  constructor(public payload) {}
}

export class ChatsEditLastMessageWithArrowUp {
  static readonly type = '[Chats] EditLastMessageWithArrowUp';
  constructor(public payload) {}
}

export class ChatsTicketTooltip {
  static readonly type = '[Chats] TicketTooltip';
  constructor(public payload) {}
}

export class ChatsEditGroupName {
  static readonly type = '[Chats] EditGroupName';
  constructor(public payload) {}
}

export class ChatsThreadsListMarkAllAsRead {
  static readonly type = '[Chats] ThreadsListMarkAllAsRead';
  constructor(public payload) {}
}

export class ChatsMarkAllAsRead {
  static readonly type = '[Chats] MarkAllAsRead';
  constructor(public payload) {}
}

export class ChatsOpenThreadSidebar {
  static readonly type = '[Chats] OpenThreadSidebar';
  constructor(public payload) {}
}

export class ChatsPrivateMessagesIsOpening {
  static readonly type = '[Chats] PrivateMessagesIsOpening';
  constructor(public payload) {}
}

export class ChatsSetIsThreadsForChat {
  static readonly type = '[Chats] SetIsThreadsForChat';
  constructor(public payload) {}
}

export class ChatsOnlyUnreadThreadsFilter {
  static readonly type = '[Chats] Set Only Unread Threads Filter';
  constructor(public payload) {}
}

export class ChatsSetCurrentChatPage {
  static readonly type = '[Chats] SetCurrentChatPage';
  constructor(public payload) {}
}

export class ChatsClearEditor {
  static readonly type = '[Chats] ClearEditor';
  constructor(public payload) {}
}

export class ChatsUpdatePoll {
  static readonly type = '[Chats] ChatsUpdatePoll';
  constructor(public payload: { id: string; body: PollUpdateReqDto }) {}
}

export class ChatsSetEditorFocus {
  static readonly type = '[Chats] SetEditorFocus';
  constructor(public payload) {}
}

export class ChatsGetFilesList {
  static readonly type = '[Chats] GetFilesList';
  constructor(public payload) {}
}

export class ChatsGetFilesListPagination {
  static readonly type = '[Chats] GetFilesListPagination';
  constructor(public payload: IChatFilesPaginationQuery) {}
}

export class ChatsClearFilesList {
  static readonly type = '[Chats] ClearFilesList';
  constructor(public payload) {}
}

export class ChatsGetPin {
  static readonly type = '[Chats] GetPinMessage';
  constructor(public payload: { id: string; isInternalState?: boolean }) {}
}

export class ChatsGetFile {
  static readonly type = '[Chats] GetFileMessage';
  constructor(public payload: { chatId?: string; threadId?: string; mainMessage?: any; chatFiles?: any }) {}
}

export class ChatsUpdateFile {
  static readonly type = '[Chats] ChatsUpdateFile';
  constructor(public payload) {}
}

export class ChatsClearFile {
  static readonly type = '[Chats] GetClearMessage';
  constructor() {}
}

export class ChatsCreatePin {
  static readonly type = '[Chats] CreatePinMessage';
  constructor(public payload: { id: string; body: PinnedMessageUpsertReqDto }) {}
}

export class ChatsDeletePin {
  static readonly type = '[Chats] DeletePinMessage';
  constructor(public payload: { id: string; pinnedMessageId: string; object: string; objectId: string }) {}
}

export class ChatsOrderPin {
  static readonly type = '[Chats] OrderPinMessage';
  constructor(public payload: { id: string; body: PinnedMessagesOrderUpdateReqDto }) {}
}

export class ChatsUpdatePin {
  static readonly type = '[Chats] UpdatePinMessage';
  constructor(public payload: { id: string; pinnedMessageId: string; body: PinnedMessageUpsertReqDto }) {}
}

export class ChatsUpdateSocketPin {
  static readonly type = '[Chats] UpdateSocketPinMessage';
  constructor(public payload: { type: string; message: PinnedMessagesDbDto }) {}
}

export class ChatsUpdateOrderSocketPin {
  static readonly type = '[Chats] UpdateSocketOrderPinMessage';
  constructor(public payload: PinnedMessageOrderUpdateReqDto[]) {}
}

export class PinnedScrollingMessage {
  static readonly type = '[Chats] PinnedScrollingMessage';
  constructor(public payload: { id: string; messageId: string; perPage: number; takeCountOfPageAfter: number }) {}
}

export class ChatsMessageCreate {
  static readonly type = '[Chats] ChatsMessageCreate';
  constructor(public payload: ChatsMessageCreateReqDto | any) {}
}

export class ChatsOrdersUpdate {
  static readonly type = '[Chats] ChatsOrdersUpdate';
  constructor(public payload: { actionGroupChatId?: string; groupChats: Array<GroupChatOrderUpdateReqDto> }) {}
}

export class SocketUpdateCalendarEvent {
  static readonly type = '[Chats] SocketUpdateCalendarEvent';
  constructor(public payload: CalendarEventsDbDto) {}
}
