import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthState } from '../../store/states/auth.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
  @Input() public image;
  @Input() aspectRatio = 1;
  @Input() resizeToWidth = 128;

  @Output() avatarImagePreview = new EventEmitter<any>();
  @Output() avatarImageFile = new EventEmitter<any>();
  @Output() closeCropperModal = new EventEmitter<any>();

  platform = 'web';
  avatarImage: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL: string;

  constructor(private store: Store) {}

  ngOnInit() {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.imageChangedEvent = this.image;
  }

  saveImage() {
    this.avatarImagePreview.emit(this.croppedImage);
    this.avatarImageFile.emit(this.avatarImage);
    this.closeCropperModal.emit();
  }

  imageCropped(event: ImageCroppedEvent) {
    const now = Date.now();

    // preview
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    this.croppedImage = event.objectUrl;
    this.avatarImage = new File([event.blob], now + fileBeforeCrop.name, { type: fileBeforeCrop.type });
  }

  imageLoaded() {
    this.showCropper = true;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
}
