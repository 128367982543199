<section
  class="editor"
  [ngClass]="{
  'dark': isDark,
  'isMobile': isMobile,
  'isEditMobile': isMobile && isEditMode,
  'isAndroid': platform === 'android' && isMobile && isEditMode,
  }"
  *transloco="let t; read: 'wiki'"
>
  <section class="editor__toolbar" [class.visible]="isEditMode" [class.mobile]="isMobile">
    <div class="editor__toolbar--left">
      <div *ngIf="!isMobile" class="editor__toolbar--history">
        <button
          class="editor__toolbar--history-btn"
          [class.active]="isUndoActive"
          (click)="doUndo()"
        >
          <svg-icon [class.dark]="isDark" src="assets/icons/wiki/undo.svg"></svg-icon>
        </button>
        <button
          class="editor__toolbar--history-btn"
          [class.active]="isRedoActive"
          (click)="doRedo()"
        >
          <svg-icon [class.dark]="isDark" src="assets/icons/wiki/redo.svg"></svg-icon>
        </button>
      </div>
    </div>
    <section #toolbar class="editor__toolbar--ql-toolbar" [class.mobile]="isMobile"></section>

    <div *ngIf="!isMobile" class="editor__toolbar--right">
      <div class="wiki-users overflow-hidden d-flex">
        <ng-container *ngFor="let userId of users; let i = index">
          <ng-container *ngIf="i < avatarsLimitation">
            <app-avatar
              [userId]="userId"
              [width]="avatarSize"
              [height]="avatarSize"
              [statusSize]="statusSize"
            ></app-avatar>
          </ng-container>
        </ng-container>
      </div>
      <button class="btn btn-outline" type="button" (click)="handleCancel()">{{ t('btn-cancel') }}</button>
      <button type="button" class="btn btn-solid btn-save" (click)="save()" [disabled]="!isChangedQuill || isLoadingButton">
        <i *ngIf="isLoadingButton" class="fa fa-spinner fa-spin connection_spinner sidebar__spinner" aria-hidden="true"></i>
        {{ isLoadingButton ? '' : t('btn-save') }}
      </button>
    </div>
  </section>
  <section [formGroup]="editorForm" class="editor__wrapper" [class.isOpenThread]="isOpenThread">
    <input
      class="editor__title"
      [placeholder]="initTitle || 'New document'"
      [(ngModel)]="editorTitle"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="!isEditMode"
    />
    <quill-editor
      scrollViewport
      #editor
      t-id="wiki-note-input"
      class="content-editor customize-editor"
      [class.dark]="isDark"
      bounds="self"
      [ngClass]="toolbarPlacement"
      [modules]="editorModules"
      [placeholder]="t('placeholder-editor')"
      formControlName="content"
      (onContentChanged)="editorChanged($event)"
      (onEditorCreated)="editorCreated($event)"
      (dblclick)="isEditMode && handleClickInEditor($event)"
      (click)="!isEditMode && handleClickInEditor($event)"
    ></quill-editor>
  </section>
</section>
