<div *ngIf="message.showDetails" class="message-details">
  <span
    class="message-username"
    [ngClass]="{
      'message-username-light': config?.layout.variant === 'Light',
      'message-username-dark': config?.layout.variant === 'Dark'
    }"
  >
    {{ message.webhookName ? message.webhookName : message.userName }}
  </span>
  <span
    *ngIf="message.created_at !== ''"
    class="message-date ml-1"
    [ngClass]="{
      'message-date-light': config?.layout.variant === 'Light',
      'message-date-dark': config?.layout.variant === 'Dark'
    }"
  >
    {{ message.created_at | date : 'dd.MM.yy - HH:mm' }}
  </span>
</div>
