import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { TranslocoService } from '@ngneat/transloco';

import { ChatsDbDto } from '../../../api/models/chats-db-dto';
import { WebhooksDbDto } from '../../../api/models/webhooks-db-dto';
import { ConfirmAlert } from '../../alerts/alerts';
import { WebhookDelete, WebhooksByChatIdGet } from '../../store/actions/webhook.actions';
import { WebhooksState } from '../../store/states/webhook.state';
import { AuthState } from '../../store/states/auth.state';

@Component({
  selector: 'app-webhooks-list',
  templateUrl: './webhooks-list.component.html',
  styleUrls: ['./webhooks-list.component.scss'],
})
export class WebhooksListComponent implements OnInit, OnDestroy {
  @Input() chatId: string;
  @Input() spaceId: string;
  @Input() darkMode = false;

  @Output() editeModalOpen = new EventEmitter<WebhooksDbDto>();

  private destroy$: Subject<void> = new Subject<void>();
  public chat: ChatsDbDto;
  public webhooks: WebhooksDbDto[] = [];

  constructor(
    private store: Store,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if (this.chatId) {
      this.store.dispatch(new WebhooksByChatIdGet(this.chatId));
    }

    this.store
      .select(WebhooksState.getWebhooksByChatIdMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res[this.chatId]) {
          const wHooks = [...res[this.chatId]];
          this.webhooks = wHooks?.sort((a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at)));
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openEditModal(wh: WebhooksDbDto): void {
    this.editeModalOpen.emit(wh);
  }

  public delete(webhook: WebhooksDbDto): void {
    ConfirmAlert(this.translocoService.translate('alert.webhook-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
    }).then(
      () => {
        this.store
          .dispatch(new WebhookDelete({ id: webhook._id, chatId: this.chatId }))
          .pipe(take(1))
          .subscribe(() => this.cdr.detectChanges());
      },
      (err) =>
        err?.message && this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error')),
    );
  }
}
