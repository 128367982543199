import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatTypeEnum } from '../components/chat/chat.service';

@Injectable({ providedIn: 'root' })
export class ImageService {
  private images: Map<HTMLElement, boolean> = new Map();
  private imagesThread: Map<HTMLElement, boolean> = new Map();

  public _imagesLoading = new Subject<number>();
  public imagesLoading = 0;

  public _threadImagesLoading = new Subject<number>();
  public threadImagesLoading = 0;

  constructor() {}

  imageLoading(img: HTMLElement, chatType: ChatTypeEnum) {
    if (chatType === ChatTypeEnum.Chat && (!this.images.has(img) || this.images.get(img))) {
      this.images.set(img, false);
      this.imagesLoading++;

      this._imagesLoading.next(this.imagesLoading);
    }

    if ((chatType === ChatTypeEnum.Thread && !this.imagesThread.has(img)) || this.imagesThread.get(img)) {
      this.imagesThread.set(img, false);
      this.threadImagesLoading++;

      this._threadImagesLoading.next(this.threadImagesLoading);
    }
  }

  imageLoadedOrError(img: HTMLElement, chatType: ChatTypeEnum) {
    if (
      chatType === ChatTypeEnum.Chat &&
      !this._imagesLoading.isStopped &&
      this.images.has(img) &&
      !this.images.get(img)
    ) {
      this.images.set(img, true);
      this.imagesLoading--;

      this._imagesLoading.next(this.imagesLoading);

      if (this.imagesLoading === 0) {
        this._imagesLoading.complete();
      }
    }

    if (
      chatType === ChatTypeEnum.Thread &&
      !this._threadImagesLoading.isStopped &&
      this.imagesThread.has(img) &&
      !this.imagesThread.get(img)
    ) {
      this.imagesThread.set(img, true);
      this.threadImagesLoading--;

      this._threadImagesLoading.next(this.threadImagesLoading);

      if (this.threadImagesLoading === 0) {
        this._threadImagesLoading.complete();
      }
    }
  }

  renewImagesSubject(): void {
    this.imagesLoading = 0;
    this._imagesLoading = new Subject<number>();
  }

  renewThreadImagesSubject(): void {
    this.threadImagesLoading = 0;
    this._threadImagesLoading = new Subject<number>();
  }
}
