import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HtmlRenderer, Parser } from 'commonmark';

@Component({
  selector: 'app-webhook-pr-message',
  templateUrl: './webhook-pr-message.component.html',
  styleUrls: ['./webhook-pr-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookPRMessageComponent {
  @Input() repositoryName: string;

  @Input() userName: string;

  @Input() userUrl: string;

  @Input() prActionTitle: string;

  @Input() prTitle: string;

  @Input() prDescription: string;

  @Input() prNumber: number;

  @Input() prUrl: string;

  @Input() sourceBranch: string;

  @Input() targetBranch: string;

  @Input() showDetails = false;

  @Input() assignees: { name: string }[];

  @Input() reviewers: { name: string }[];

  private reader = new Parser();

  private writer = new HtmlRenderer();

  public parseMarkedText(text: string): string {
    const parsed = this.reader.parse(text);
    return this.writer.render(parsed);
  }
}
