<div class="modal-header" *transloco="let t; read: 'modals.tenant'">
	<h5 class="modal-title">{{ t('title-members-list') }}</h5>
	<button type="button" class="close" aria-label="Close" (click)="close()">
    <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
	</button>
</div>

<div class="modal-body tenant-members-list">
	<app-chat-users-list [membersChat]="allUsers" platform="web" (viewDirectChat)="viewDirectChat($event)"></app-chat-users-list>
</div>
