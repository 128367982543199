<!-- image cropper modal -->
<ng-container *transloco="let t; read: 'modals.admin-user-edit'">
  <ng-template #imageCropperModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title modal-title-text">{{ t('edit-image') }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeCropperModal()">
        <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
      </button>
    </div>
    <div class="modal-body">
      <app-image-cropper
        [image]="selectedUserAvatar"
        (avatarImageFile)="updateUserAvatar($event)"
        (closeCropperModal)="closeCropperModal()"
      ></app-image-cropper>
    </div>
  </ng-template>

  <div class="modal-header">
    <h5 class="modal-title">{{titleModal}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <section class="users-edit">
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
        <div class="row">
          <div class="col-12 users-edit-unset">
            <!-- Nav-tabs -->
            <ul ngbNav #nav="ngbNav" [activeId]="activeId" class="nav-tabs justify-content-left">
              <li [ngbNavItem]="1">
                <a ngbNavLink class="nav-link d-flex align-items-center">
                  <i class="ft-user mr-1"></i>
                  <span class="d-none d-sm-block">{{ t('account') }}</span>
                </a>
                <ng-template ngbNavContent>
                  <!-- Account content starts -->

                  <!--PERFECT-->
                  <div class="profile-responsive" >
                    <div class="mt-3 account" id="account">
                      <div class="row wrapper-profile">
                        <div class="col-12 col-md-6">
                          <!-- Media object starts -->
                          <div class="media pt-0" [class.align-center]="isAssistant">
                            <app-avatar
                              height="85"
                              width="85"
                              [userId]="user?._id"
                              [ngClass]="{ 'user-image-uploading': isAvatarImageUploading, 'mr-2': true }"
                              [notShowProfile]="true"
                            ></app-avatar>

                            <div *ngIf="isAvatarImageUploading" class="user-avatar-image-spinner">
                              <app-custom-spinner [smallSize]="true"></app-custom-spinner>
                            </div>

                            <div class="media-body" t-id="admin-user-edit-personal-info">
                              <div *ngIf="isEditable" class="px-0 mb-sm-2">
                                <div class="user-avatar-title mb-1">{{ t('avatar') }}</div>
                                <input
                                  t-id="admin-user-edit-change-avatar"
                                  type="button"
                                  [value]="t('change-avatar')"
                                  class="btn btn-solid mb-1 mb-sm-0 "
                                  (click)="avatarInput.click()"
                                />
                                <div class="user-avatar-description mt-1">{{ t('photo-size-warning', { size: '500' }) }}</div>
                              </div>

                              <div *ngIf="!isEditable" class="px-0 mb-sm-2">
                                <h4>{{ user?.userName }}</h4>
                                <button *ngIf="!isAssistant" type="button" class="btn btn-sm btn-solid" (click)="viewDirectChat(user._id)" t-id="admin-user-edit-message">
                                  <i class="ft-message-circle mr-1"></i>
                                  <span>{{ t('message') }}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- Media object ends -->
                        </div>

                      </div>

                      <!-- Account form starts -->
                      <form *ngIf="userForm" novalidate [formGroup]="userForm">
                        <div class="wrapper-edit" [class.wrapper-edit-line]="isEditable">
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-name" class="profile-input-title">{{ t('name') }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-name"
                                    class="form-control"
                                    placeholder="{{ t('name') }}"
                                    formControlName="name"
                                    aria-invalid="false"
                                    [readonly]="!isEditable"
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-email" class="profile-input-title">{{ t('e-mail') }}</label>
                                  <input
                                    type="email"
                                    id="users-edit-email"
                                    class="form-control"
                                    placeholder="{{ t('placeholder-email') }}"
                                    formControlName="email"
                                    aria-invalid="false"
                                    [readonly]="!isEditable"
                                  />
                                  <i
                                    *ngIf="userForm.value.email"
                                    class="ft-copy copy-email"
                                    ngbTooltip="{{ t('tooltip-copy-email') }}"
                                    container="body"
                                    placement="top"
                                    (click)="copyEmail()"
                                  ></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-username" class="profile-input-title">{{ t('username') }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-username"
                                    class="form-control"
                                    placeholder="{{ t('username') }}"
                                    formControlName="userName"
                                    aria-invalid="false"
                                    [readonly]="!isEditable"
                                  />
                                </div>
                              </div>

                              <!--<div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-status">Status</label>
                                  <select
                                    id="users-edit-status"
                                    class="form-control"
                                    required
                                    [disabled]="!isEditable || !isAdmin(user)"
                                  >
                                    <option value="">SelectLabelsTags Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Away</option>
                                    <option value="3">Close</option>
                                  </select>
                                </div>
                              </div>-->

                              <div class="form-group">
                                <div class="controls">
                                  <label for="timeZone" class="profile-input-title">{{ t('timezone') }}</label>
                                  <ng-select
                                    *ngIf="isEditable"
                                    [items]="timezones"
                                    id="timeZone"
                                    required
                                    bindLabel="label"
                                    bindValue="value"
                                    placeholder="{{ t('placeholder-select-timezone') }}"
                                    name="timezone"
                                    appendTo="body"
                                    formControlName="timezone"
                                    t-id="admin-user-change-localtime"
                                  ></ng-select>
                                </div>
                                <div *ngIf="isEditable" class="mt-1">
                                  <div class="located-info" t-id="admin-user-user-located-in">{{ t('local-timezone') }}: {{ dtHelper.getTimezoneName(currTz) }}</div>
                                  <button
                                    *ngIf="userForm.value.timezone !== currTz"
                                    class="h-100 btn btn-xs btn-solid set-tz mt-1"
                                    (click)="setCurrentTz()"
                                    t-id="admin-user-set-this-localtime"
                                  >
                                    {{ t('btn-set-this') }}
                                  </button>
                                </div>
                                <div *ngIf="!isEditable" class="mt-1">
                                  <span *ngIf="user.timezone">{{ dtHelper.getTimezoneName(user.timezone) }}</span>
                                  <span *ngIf="!user.timezone">{{ t('timezone-not-set') }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div *ngIf="user.timezone" class="mb-2">
                              <div class="user-time-label mb-1" [class.dark]="isDark">{{ t('user-local-time') }}</div>
                              <div class="user-time" [class.dark]="isDark">{{ localTime }}</div>
                              <div *ngIf="hoursDiff !== 0">{{ t('difference') }} <b>{{ hoursDiff }}h</b></div>
                            </div>
                          </div>

                          <hr *ngIf="isEditable"/>

                          <section *ngIf="isEditable && !(isTwoFaLoading$ | async)" class="users-edit-two-fa">
                          <span class="users-edit-two-fa__title">
                          <span>{{ t('tfa') }}</span>
                          <svg-icon *ngIf="twoFaOptions" src="assets/icons/common/checked.svg"></svg-icon>
                          <svg-icon *ngIf="!twoFaOptions" src="assets/icons/common/warning.svg"></svg-icon>
                        </span>

                            <span class="users-edit-two-fa__sub-title">{{ t(twoFaOptions ? 'tfa-enabled-title' : 'tfa-disabled-title') }}</span>
                            <span *ngIf="!twoFaOptions" class="users-edit-two-fa__sub-title">{{ t('tfa-recommends') }}</span>

                            <button *ngIf="!twoFaOptions; else disableTwoFaBtn" type="button" class="btn btn-outline" (click)="navigateToTwoFaSetup()">{{ t('btn-tfa-enable') }}</button>
                            <ng-template #disableTwoFaBtn>
                              <button type="button" class="btn btn-subtle" (click)="navigateToTwoFaDisable()">{{ t('btn-tfa-disable') }}</button>
                            </ng-template>

                            <span *ngIf="!twoFaOptions" class="users-edit-two-fa__sub-title">
                            <span>{{ t('tfa-note-title') }} </span>
                            <span class="users-edit-two-fa__sub-title__link">{{ t('tfa-note-sub-title') }}</span>
                        </span>
                          </section>

                          <hr *ngIf="isEditable && !(isTwoFaLoading$ | async)"/>

                          <section *ngIf="isEditable" class="users-edit-language">
                            <span class="users-edit-language__title">{{ t('language') }}</span>
                            <span class="users-edit-language__sub-title">
                              <span>{{ t('choose-the-language') }}</span>
                              <br>
                              <span>{{ t('your-language-currently-is') }}: <span class="users-edit-language__sub-title_bold" [class.dark]="isDark">{{ t('current-language') }}.</span></span>
                            </span>
                            <ng-select
                              [items]="languages"
                              [clearable]="false"
                              [searchable]="false"
                              class="users-edit-language__select"
                              bindLabel="label"
                              bindValue="value"
                              placeholder="Select language"
                              appendTo="body"
                              [ngModel]="currentLanguage"
                              t-id="admin-user-change-language"
                              formControlName="localization"
                            ></ng-select>
                          </section>

                          <hr *ngIf="isEditable"/>

                          <div class="account-delete" *ngIf="isEditable">
                            <h3 class="account-delete-title" [class.dark]="isDark">{{ t('delete-account') }}</h3>
                            <p class="account-delete-description">{{ t('delete-description') }}</p>
                            <button type="button" class="btn btn-subtle" (click)="delete()">{{ t('btn-delete-my-account') }}</button>
                          </div>
                        </div>

                        <div *ngIf="isEditable" class="button-user-edit d-flex flex-row justify-content-end mt-2">
                          <button type="button" class="btn btn-subtle" (click)="close()">{{ t('btn-cancel') }}</button>
                          <button type="submit" class="btn btn-solid ml-2" (click)="save()" t-id="admin-user-edit-save-changes">
                            {{ t('btn-save-changes') }}
                          </button>
                        </div>
                      </form>

                      <!-- Account form ends -->
                    </div>
                  </div>

                  <!-- Account content ends -->
                </ng-template>
              </li>

              <li [ngbNavItem]="2">
                <a ngbNavLink class="nav-link d-flex align-items-center">
                  <i class="ft-info mr-1"></i>
                  <span class="d-none d-sm-block">{{ t('memberships') }}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="table-responsive" >
                    <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars" track="all">
                      <table class="table text-center m-0">
                        <thead>
                        <tr>
                          <th>{{ t('space') }}</th>
                          <th>{{ t('project') }}</th>
                          <th>{{ t('role') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let space of spaces">
                          <tr *ngIf="space.roleName">
                            <td>
                              <div class="d-flex align-items-center">
                                <app-space-avatar containerHeight="24" containerWidth="24" [space]="space" class="space-avatar mr-1"></app-space-avatar>
                                <div class="cell-line-height">
                                  <span class="line-height-1 mb-0">{{ space.spaceName }}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">-</div>
                            </td>
                            <td>{{ space.roleName }}</td>
                          </tr>

                          <tr *ngFor="let project of space.projects">
                            <td>
                              <div class="d-flex align-items-center">
                                <app-space-avatar containerHeight="24" containerWidth="24" [space]="project.space" class="space-avatar mr-1"></app-space-avatar>
                                <div class="cell-line-height table-text-left">
                                  <span class="line-height-1 mb-0">{{ space?.spaceName }}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <app-project-avatar [project]="project" class="project-avatar mr-1"></app-project-avatar>
                                <div class="cell-line-height table-text-left">
                                  <span class="line-height-1 mb-0">{{ project.projectName }}</span>
                                </div>
                              </div>
                            </td>
                            <td>{{ project.roleName }}</td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </ng-scrollbar>
                  </div>
                </ng-template>
              </li>

              <!--PAYMENTS-->
              <li *ngIf="isAdmin(user) && isPaymentActive" [ngbNavItem]="3">
                <a ngbNavLink class="nav-link d-flex align-items-center">
                  <span class="d-none d-sm-block">{{ t('my-subscription') }}</span>
                </a>
                <ng-template ngbNavContent>
                  <app-user-plan></app-user-plan>
                </ng-template>
              </li>

            </ul>

            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </ng-scrollbar>
    </section>
  </div>

  <input #avatarInput type="file" style="display: none" accept=".jpg, .jpeg, .png" (change)="avatarImageChange($event)" t-id="admin-user-edit-file-input"/>
</ng-container>
