import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { NotificationsStateModel } from '../models/NotificationsState';
import {
  NotificationsSetIsPlaying,
  NotificationsSetPlayRingtone,
  PushNotificationsSettingsGet,
} from '../actions/notifications.action';
import { PushNotificationsSettingsCreate } from '../actions/notifications.action';
import { PushNotificationsService } from '../../../api/services/push-notifications.service';

@State<NotificationsStateModel>({
  name: 'Notifications',
  defaults: {
    filters: {}, // filter: "ALL ACTIONS" | "MENTIONS" | "NO CALLS" | "NOTHING", noRingtone: boolean
    needPlayRingtone: false,
    isPlaying: false,
  },
})
@Injectable()
export class NotificationsState {
  /**
   * get filter
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getFilter(state: NotificationsStateModel) {
    return (chatId: string) => state.filters[chatId];
  }

  /**
   * get needPlayRingtone
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getNeedPlayRingtone(state: NotificationsStateModel) {
    return state.needPlayRingtone;
  }

  /**
   * get isPlaying
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getIsPlaying(state: NotificationsStateModel) {
    return state.isPlaying;
  }

  constructor(private notificationsService: PushNotificationsService, private store: Store) {}

  /**
   * Get projects action handler
   * @param {getState, setState}: StateContext<NotificationsStateModel>
   * @param action
   */
  @Action(PushNotificationsSettingsGet)
  get_notifications_filters(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    action: PushNotificationsSettingsGet,
  ) {
    const { filters } = getState();

    return this.notificationsService.notificationFiltersGet(action.payload).pipe(
      tap(
        (res) => {
          if (res && res.chatId) {
            patchState({
              filters: { ...filters, [res.chatId]: { filter: res.filter || null, noRingtone: res.noRingtone || null } },
            });
          }

          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create push notifications filter action handler
   * @param  {getState, patchState}: StateContext<NotificationsStateModel>
   * @param  {PushNotificationsSettingsCreate} action
   */
  @Action(PushNotificationsSettingsCreate)
  pn_settings_create(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    action: PushNotificationsSettingsCreate,
  ) {
    const { filters } = getState();

    return this.notificationsService.notificationFiltersCreate({ body: action.payload }).pipe(
      tap(
        (res) => {
          patchState({
            filters: {
              ...filters,
              [res.chatId]: { filter: res.filter || null, noRingtone: res.noRingtone || false },
            },
          });

          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Change video call needPlayRingtone handler
   * @param  stateContext: StateContext<NotificationsStateModel>
   * @param  {NotificationsSetPlayRingtone} action
   */
  @Action(NotificationsSetPlayRingtone)
  set_need_play_ringtone({ patchState }: StateContext<NotificationsStateModel>, action: NotificationsSetPlayRingtone) {
    return patchState({ needPlayRingtone: action.payload.needPlay });
  }

  /**
   * Change isPlaying handler
   * @param  stateContext: StateContext<NotificationsStateModel>
   * @param  {NotificationsSetIsPlaying} action
   */
  @Action(NotificationsSetIsPlaying)
  set_is_playing({ patchState }: StateContext<NotificationsStateModel>, action: NotificationsSetIsPlaying) {
    return patchState({ isPlaying: action.payload });
  }
}
