<div class="progress" [class.isPoll]="isPoll" >
  <div class="progress-bar"
       role="progressbar"
       [style.width.%]="progress"
       [attr.aria-valuenow]="progress"
       [class.progress-bar-half]="progress >= 50"
       [class.progress-bar-success]="progress === 100"
       aria-valuemin="0"
       aria-valuemax="100"></div>
</div>
