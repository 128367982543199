// Common
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Capacitor } from '@capacitor/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../mobile/src/environments/environment';

// Services
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { WikiService } from '../../pages/full-pages/wiki/wiki.service';
import { EditorService } from '../../shared/components/editor/editor.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-wiki-mobile-modal',
  templateUrl: './wiki-mobile-modal.component.html',
  styleUrls: ['./wiki-mobile-modal.component.scss'],
})
export class WikiMobileModalComponent implements OnInit {
  public config: ITemplateConfig;
  public isThread = false;
  public title: string;
  public content: string;
  public platform = 'mobile';
  public showLink = false;
  public heightKeyboard = 0;
  public message = null;
  public isEdit = false;
  public readonly apiUrl = environment.api_root;

  constructor(
    public wikiService: WikiService,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private editorService: EditorService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.initializeData();
    this.wikiService.heightKeyBoard.pipe(untilDestroyed(this)).subscribe((value: number) => {
      setTimeout(() => {
        this.heightKeyboard = value;
        this.cdr.detectChanges();
      }, 0);
    });
  }

  // Getters
  public get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get isOpenThread(): boolean {
    return this.wikiService.mentionThreadMembers && this.isThread;
  }

  // Methods
  public closeModal(): void {
    this.wikiService.activeWiki.next(null);
    this.activeModal.close();
  }

  public toggleMode(): void {
    this.wikiService.setEditMode();
  }

  public onEmitChangesStatus($event: boolean) {
    this.wikiService.hasUnsavedChanges = $event;
  }

  public closeThread(): void {
    this.wikiService.handleOpenThread.next('');
  }

  public initializeData(): void {
    this.platform = Capacitor.getPlatform();

    this.wikiService.handleOpenThread.pipe(untilDestroyed(this)).subscribe((value: any) => {
      this.isThread = value !== '' ? value : !this.isThread;
    });

    this.wikiService.message.pipe(untilDestroyed(this)).subscribe((msg) => {
      this.message = msg;
    });

    this.wikiService.isEditMode$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isEdit = value;
    });

    this.wikiService.activeWiki$.pipe(untilDestroyed(this)).subscribe((wiki) => {
      if (wiki) {
        this.title = wiki.title || this.translocoService.translate('modals.wiki.new-doc');
        this.content = wiki.content;
      }
    });
  }

  public showToastLink(): void {
    this.showLink = true;
    setTimeout(() => (this.showLink = false), 3000);
  }

  public onSave(): void {
    const title = this.editorService.getEditorTitle();
    const content = this.editorService.getEditorContent();

    this.wikiService.save(title, content);
  }

  public get isChanged() {
    return this.title !== this.editorService.getEditorTitle() || this.content !== this.editorService.getEditorContent();
  }
}
