import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NotepadStateModel } from '../models/NotepadState';
import { NotepadGet, NotepadUpdate } from '../actions/notepad.action';
import { NotepadsService } from '../../../api/services/notepads.service';

@State<NotepadStateModel>({
  name: 'Notepad',
  defaults: {
    object: null,
    objectId: null,
    content: '',
  },
})
@Injectable()
export class NotepadState {
  /**
   * get events
   * @param  {NotepadStateModel} state
   */
  @Selector()
  static getNotepad(state: NotepadStateModel) {
    return state.content;
  }

  constructor(private notepadService: NotepadsService, private store: Store) {}

  /**
   * Update notepad action handler
   * @param  {getState, setState, patchState}: StateContext<NotepadStateModel>
   * @param  {NotepadUpdate} action
   */
  @Action(NotepadUpdate)
  note_create({ getState, setState, patchState }: StateContext<NotepadStateModel>, action: NotepadUpdate) {
    return this.notepadService.notepadsSet(action.payload).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            content: state.content,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get notepad action handler
   * @param  {patchState}: StateContext<NotepadStateModel>
   * @param  {NotepadGet} action
   */
  @Action(NotepadGet)
  notepad_get({ patchState }: StateContext<NotepadStateModel>, action: NotepadGet) {
    patchState({ content: '' });
    return this.notepadService.notepadsGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            // @ts-ignore
            content: result ? result.content : '',
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
