/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthAuthByTokenReqDto } from '../models/auth-auth-by-token-req-dto';
import { AuthConfirmForgotPasswordReqDto } from '../models/auth-confirm-forgot-password-req-dto';
import { AuthForgotPasswordReqDto } from '../models/auth-forgot-password-req-dto';
import { AuthInviteReqDto } from '../models/auth-invite-req-dto';
import { AuthLoginReqDto } from '../models/auth-login-req-dto';
import { AuthLoginResDto } from '../models/auth-login-res-dto';
import { AuthLogoutReqDto } from '../models/auth-logout-req-dto';
import { AuthLogoutResDto } from '../models/auth-logout-res-dto';
import { AuthSwitchTenantReqDto } from '../models/auth-switch-tenant-req-dto';
import { CommonConfirmInviteReqDto } from '../models/common-confirm-invite-req-dto';
import { CommonDeleteResDto } from '../models/common-delete-res-dto';
import { CommonEmailDto } from '../models/common-email-dto';
import { CommonPathTokenReqDto } from '../models/common-path-token-req-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { IntersectionAuthRegisterUserReqDtoCommonOptionalFileReqDto } from '../models/intersection-auth-register-user-req-dto-common-optional-file-req-dto';
import { PushNotificationsDbDto } from '../models/push-notifications-db-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = '/auth/login';

  /**
   * login user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthLoginReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * login user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthLoginReqDto;
  }): Observable<AuthLoginResDto> {
    return this.login$Response(params).pipe(map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto));
  }

  /**
   * Path part for operation authByToken
   */
  static readonly AuthByTokenPath = '/auth/auth-by-token';

  /**
   * authenticate by token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authByToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authByToken$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthAuthByTokenReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthByTokenPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * authenticate by token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authByToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authByToken(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthAuthByTokenReqDto;
  }): Observable<AuthLoginResDto> {
    return this.authByToken$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation switchTenant
   */
  static readonly SwitchTenantPath = '/auth/switch-tenant';

  /**
   * switch workplace
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `switchTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  switchTenant$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthSwitchTenantReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.SwitchTenantPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * switch workplace
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `switchTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  switchTenant(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthSwitchTenantReqDto;
  }): Observable<AuthLoginResDto> {
    return this.switchTenant$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation logout
   */
  static readonly LogoutPath = '/auth/logout';

  /**
   * logout
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthLogoutReqDto;
  }): Observable<StrictHttpResponse<AuthLogoutResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LogoutPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLogoutResDto>;
        }),
      );
  }

  /**
   * logout
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthLogoutReqDto;
  }): Observable<AuthLogoutResDto> {
    return this.logout$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLogoutResDto>) => r.body as AuthLogoutResDto),
    );
  }

  /**
   * Path part for operation register
   */
  static readonly RegisterPath = '/auth/register';

  /**
   * register user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `register()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  register$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionAuthRegisterUserReqDtoCommonOptionalFileReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RegisterPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * register user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `register$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  register(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionAuthRegisterUserReqDtoCommonOptionalFileReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.register$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation resendActivateLink
   */
  static readonly ResendActivateLinkPath = '/auth/resend-activate-link';

  /**
   * resend activate link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendActivateLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendActivateLink$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ResendActivateLinkPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * resend activate link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resendActivateLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendActivateLink(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.resendActivateLink$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation forgotPassword
   */
  static readonly ForgotPasswordPath = '/auth/forgot-password';

  /**
   * forgot password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ForgotPasswordPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * forgot password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.forgotPassword$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation renewAccount
   */
  static readonly RenewAccountPath = '/auth/renew-account';

  /**
   * send renew account link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renewAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renewAccount$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RenewAccountPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * send renew account link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renewAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renewAccount(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthForgotPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.renewAccount$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation renewAccountConfirm
   */
  static readonly RenewAccountConfirmPath = '/auth/renew-account-confirm';

  /**
   * renew account confirmation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renewAccountConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renewAccountConfirm$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthConfirmForgotPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RenewAccountConfirmPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * renew account confirmation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renewAccountConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renewAccountConfirm(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthConfirmForgotPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.renewAccountConfirm$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation userConfirm
   */
  static readonly UserConfirmPath = '/auth/user-confirm/{token}';

  /**
   * confirming user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userConfirm()` instead.
   *
   * This method doesn't expect any request body.
   */
  userConfirm$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.UserConfirmPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirming user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userConfirm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userConfirm(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<AuthLoginResDto> {
    return this.userConfirm$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation resetPassword
   */
  static readonly ResetPasswordPath = '/auth/reset-password';

  /**
   * reset password confirmation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthConfirmForgotPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ResetPasswordPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * reset password confirmation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthConfirmForgotPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.resetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation tenantEmailCombination
   */
  static readonly TenantEmailCombinationPath = '/auth/tenants-email-combination';

  /**
   * tenants email combination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantEmailCombination()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantEmailCombination$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    email: string;
    name: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TenantEmailCombinationPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('email', params.email, {});
      rb.query('name', params.name, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * tenants email combination
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantEmailCombination$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantEmailCombination(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    email: string;
    name: string;
  }): Observable<CommonSuccessResDto> {
    return this.tenantEmailCombination$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation resetPasswordCheck
   */
  static readonly ResetPasswordCheckPath = '/auth/reset-password-check/{token}';

  /**
   * reset password check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPasswordCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPasswordCheck$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ResetPasswordCheckPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * reset password check
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPasswordCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetPasswordCheck(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<CommonSuccessResDto> {
    return this.resetPasswordCheck$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation tenantInvite
   */
  static readonly TenantInvitePath = '/auth/tenant-invite';

  /**
   * invite users to workplace
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantInvite$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthInviteReqDto;
  }): Observable<StrictHttpResponse<Array<AuthInviteReqDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TenantInvitePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AuthInviteReqDto>>;
        }),
      );
  }

  /**
   * invite users to workplace
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantInvite(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: AuthInviteReqDto;
  }): Observable<Array<AuthInviteReqDto>> {
    return this.tenantInvite$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AuthInviteReqDto>>) => r.body as Array<AuthInviteReqDto>),
    );
  }

  /**
   * Path part for operation tenantConfirm
   */
  static readonly TenantConfirmPath = '/auth/tenant-confirm-new';

  /**
   * confirm user to workplace and register it, then login
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantConfirm$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TenantConfirmPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirm user to workplace and register it, then login
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantConfirm(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<AuthLoginResDto> {
    return this.tenantConfirm$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation tenantsInviteTokenCheck
   */
  static readonly TenantsInviteTokenCheckPath = '/auth/tenant-invite-token-check/{token}';

  /**
   * workplace invite token check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsInviteTokenCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsInviteTokenCheck$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<CommonEmailDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TenantsInviteTokenCheckPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonEmailDto>;
        }),
      );
  }

  /**
   * workplace invite token check
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsInviteTokenCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsInviteTokenCheck(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<CommonEmailDto> {
    return this.tenantsInviteTokenCheck$Response(params).pipe(
      map((r: StrictHttpResponse<CommonEmailDto>) => r.body as CommonEmailDto),
    );
  }

  /**
   * Path part for operation loginDeviceToken
   */
  static readonly LoginDeviceTokenPath = '/auth/login-device-token';

  /**
   * login device token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginDeviceToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginDeviceToken$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonPathTokenReqDto;
  }): Observable<StrictHttpResponse<PushNotificationsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginDeviceTokenPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PushNotificationsDbDto>;
        }),
      );
  }

  /**
   * login device token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginDeviceToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginDeviceToken(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonPathTokenReqDto;
  }): Observable<PushNotificationsDbDto> {
    return this.loginDeviceToken$Response(params).pipe(
      map((r: StrictHttpResponse<PushNotificationsDbDto>) => r.body as PushNotificationsDbDto),
    );
  }

  /**
   * Path part for operation logoutDeviceToken
   */
  static readonly LogoutDeviceTokenPath = '/auth/logout-device-token';

  /**
   * logout device token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoutDeviceToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logoutDeviceToken$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonPathTokenReqDto;
  }): Observable<StrictHttpResponse<CommonDeleteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LogoutDeviceTokenPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonDeleteResDto>;
        }),
      );
  }

  /**
   * logout device token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logoutDeviceToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logoutDeviceToken(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonPathTokenReqDto;
  }): Observable<CommonDeleteResDto> {
    return this.logoutDeviceToken$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDeleteResDto>) => r.body as CommonDeleteResDto),
    );
  }
}
