import { Injectable } from '@angular/core';
import { Action, Selector, State, Store, StateContext } from '@ngxs/store';
import { append, compose, iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { ChatsStateModel } from '../models/ChatsState';
import { VideoCallsGetResDto } from '../../../api/models/video-calls-get-res-dto';
import { Message, Thread } from '../../components/chat/chat.model';
import { EmojisHelper } from '../../utils/emojis-helper';
import { ConfigService } from '../../services/config.service';
import { ChatsService } from '../../../api/services/chats.service';
import { VideoCallsService } from '../../../api/services/video-calls.service';
import { VideoCallSetLastSessionId } from '../actions/video-calls.action';
import { AuthState } from './auth.state';
import {
  ChatMessagesClear,
  ChatsAddEmojiReaction,
  ChatsClear,
  ChatsClearFile,
  ChatsClearFilesList,
  ChatsClearMessageDraft,
  ChatsClearUploadFiles,
  ChatsCreatePin,
  ChatsDeleteChatsMembers,
  ChatsDeleteEmojiReaction,
  ChatsDeleteMessages,
  ChatsDeletePin,
  ChatsEditGroupName,
  ChatsEmojiPicker,
  ChatsGet,
  ChatsGetFile,
  ChatsGetFilesList,
  ChatsGetFilesListPagination,
  ChatsGetMembers,
  ChatsGetMessages,
  ChatsGetPin,
  ChatsMarkAllAsRead,
  ChatsMarkAsRead,
  ChatsMessageCreate,
  ChatsNewMessageToUpload,
  ChatsOnlyUnreadThreadsFilter,
  ChatsOpenThreadSidebar,
  ChatsOrderPin,
  ChatsOrdersUpdate,
  ChatsPrivateMessagesIsOpening,
  ChatsReconnectStatus,
  ChatsRemoveChatsMembers,
  ChatsResetEmojiReactionIsUpdated,
  ChatsSearchMessages,
  ChatsSet,
  ChatsSetActiveVideoCallRooms,
  ChatsSetChatId,
  ChatsSetChats,
  ChatsSetChatsMembers,
  ChatsSetCurrentChatName,
  ChatsSetIsThreadsForChat,
  ChatsSetMessages,
  ChatsSetSearchResults,
  ChatsSetThreadsCounters,
  ChatsSetThreadsList,
  ChatsSocketDeletedMessage,
  ChatsSocketNewMessage,
  ChatsSocketUpdatedMessage,
  ChatsSocketUpdatedPoll,
  ChatsThreadsListMarkAllAsRead,
  ChatsUpdateChatsMembers,
  ChatsUpdateCounter,
  ChatsUpdateFile,
  ChatsUpdateMessageDraft,
  ChatsUpdateOrderSocketPin,
  ChatsUpdatePin,
  ChatsUpdatePoll,
  ChatsUpdateSocketPin,
  ChatsUpdateThreadInList,
  ChatsUpdateThreadsList,
  ChatsUploadFile,
  ChatsVideoCallParticipantJoined,
  ChatsVideoCallParticipantLeft,
  PinnedScrollingMessage,
  SetOfflineStatus,
  SocketUpdateCalendarEvent,
  ThreadGetCounters,
  ThreadGetList,
  ThreadGetMessages,
  ThreadMarkAsRead,
  ThreadsMarkAsUnRead,
} from '../actions/chats.action';
import { SentryIoService } from '../../services/sentry-io.service';
import { ThreadsSetMessages } from '../actions/threads.action';
import { PinnedMessagesDbDto } from '../../../api/models/pinned-messages-db-dto';
import { OfflineMessagesService } from '../../services/offline-messages.service';
import { TicketService } from '../../services/ticket.service';
import { v4 as uuidv4 } from 'uuid';
import { CalendarEventsDbDto } from '../../../api/models/calendar-events-db-dto';

@State<ChatsStateModel>({
  name: 'Chats',
  defaults: {
    chatId: null,
    messages: {},
    messagesTotalCount: {},
    messagesToUpload: [],
    lastMessageId: null,
    loadedMessagesCount: {},
    loadedPagesCount: {},
    loadedPages: {},
    chats: [],
    chatsLastMessage: {},
    chatMembers: {},
    chatFiles: [],
    chatFilesPagination: null,
    chatsLoaded: false,
    chatLoadedMembers: false,
    sendFileLoading: false,
    selectedChatName: null,
    messagesDrafts: [],
    chatEmojiIsOpen: false,
    threadEmojiIsOpen: false,
    chatEmojiReactionIsOpen: false,
    threadEmojiReactionIsOpen: false,
    emojiPickerLeft: 0,
    emojiPickerTop: 0,
    chatEmojiReactionIsUpdated: false,
    chatInfo: {},
    searchIsActive: false,
    searchText: null,
    searchTotalCount: 0,
    searchCurrentPage: 0,
    searchMessageId: null,
    searchResults: [],
    threadsList: [],
    threadsListInfo: {},
    isOpenThreadSidebar: null,
    privateChatIsOpening: false,
    isThreadsForChat: false,
    currentPage: 0,
    onlyUnreadThreads: false,
    activeVideoCallChats: [],
    pinMessage: [],
    isOffline: false,
    triggerToReconnectSocket: false,
    fileCurrentChat: [],
    fileThreadChat: [],
    requestUuid: '',
  },
})
@Injectable()
export class ChatsState {
  /**
   * get messages loaded status
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getMessagesFullyLoadedStatus(state: ChatsStateModel) {
    return state.messages[state.chatId].length === state.messagesTotalCount[state.chatId];
  }

  /**
   * get last message ID
   * @param {ChatsStateModel} state
   */
  @Selector()
  static getLastMessageId(state: ChatsStateModel) {
    return state.lastMessageId[state.chatId];
  }

  /**
   * get is last message ID
   * @param {ChatsStateModel} state
   */
  @Selector()
  static getLastMessageDownloaded(state: ChatsStateModel) {
    return state.messages[state.chatId][0]._id === state.lastMessageId[state.chatId];
  }

  /**
   * get thread messages loaded status
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getThreadsFullyLoadedStatus(state: ChatsStateModel) {
    return state.threadsList.length === state.threadsListInfo.totalCount;
  }

  /**
   * get search messages loaded status
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchFullyLoadedStatus(state: ChatsStateModel) {
    return state.searchResults.length === state.searchTotalCount;
  }

  /**
   * get chat id
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatId(state: ChatsStateModel) {
    return state.chatId;
  }

  /**
   * get chats
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChats(state: ChatsStateModel) {
    return state.chats;
  }

  /**
   * get threads list
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getThreadsList(state: ChatsStateModel) {
    return state.threadsList;
  }

  /**
   * get threads list info
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getThreadsListInfo(state: ChatsStateModel) {
    return state.threadsListInfo;
  }

  /**
   * get chat Members
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatMembers(state: ChatsStateModel) {
    return (chatId: string) => state.chatMembers[chatId];
  }

  /**
   * get chat files
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatFiles(state: ChatsStateModel) {
    return state.chatFiles;
  }

  /**
   * get chat files
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getCurrentFileChat(state: ChatsStateModel) {
    return state.fileCurrentChat;
  }

  /**
   * get thread chat files
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getThreadFileChat(state: ChatsStateModel) {
    return state.fileThreadChat;
  }

  /**
   * get messages
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getMessages(state: ChatsStateModel) {
    return (chatId: string) => state.messages[chatId];
  }

  @Selector()
  static getMessagesToUpload(state: ChatsStateModel) {
    return state.messagesToUpload;
  }

  @Selector()
  static getStatus(state: ChatsStateModel) {
    return state.isOffline;
  }

  @Selector()
  static getStatusReconnectSocket(state: ChatsStateModel) {
    return state.triggerToReconnectSocket;
  }

  /**
   * get loaded pages
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getLoadedPages(state: ChatsStateModel) {
    return (chatId: string) => state.loadedPages[chatId];
  }

  /**
   * get search current page
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchCurrentPage(state: ChatsStateModel) {
    return state.searchCurrentPage;
  }

  /**
   * get search is active
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchIsActive(state: ChatsStateModel) {
    return state.searchIsActive;
  }

  /**
   * get search is active
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getCurrentPage(state: ChatsStateModel) {
    return state.currentPage;
  }

  /**
   * get searched text
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchedText(state: ChatsStateModel) {
    return state.searchText;
  }

  /**
   * get searched message id
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchMessageId(state: ChatsStateModel) {
    return state.searchMessageId;
  }

  /**
   * get search results
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getSearchResults(state: ChatsStateModel) {
    return state.searchResults;
  }

  /**
   * get loaded chats
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getLoadedChats(state: ChatsStateModel) {
    return state.chatsLastMessage;
  }

  /**
   * get chat send file loading
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatSendFileLoading(state: ChatsStateModel) {
    return state.sendFileLoading;
  }

  /**
   * get messages drafts
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getMessageDraft(state: ChatsStateModel) {
    return (chatId: string) => {
      const draft = state.messagesDrafts.find((item) => item['chatId'] === chatId);
      return draft ? draft.text : '';
    };
  }

  /**
   * get current chat name
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getCurrentChatName(state: ChatsStateModel) {
    return state.selectedChatName;
  }

  /**
   * get current chat emoji state - open/closed
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsEmojiPicker(state: ChatsStateModel) {
    return state.chatEmojiIsOpen;
  }

  /**
   * get current thread emoji state - open/closed
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsThreadEmojiPicker(state: ChatsStateModel) {
    return state.threadEmojiIsOpen;
  }

  /**
   * get chat emoji reaction state - open/closed
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsEmojiReaction(state: ChatsStateModel) {
    return state.chatEmojiReactionIsOpen;
  }

  /**
   * get emoji reaction position
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsEmojiReactionPosition(state: ChatsStateModel) {
    return {
      left: state.emojiPickerLeft,
      top: state.emojiPickerTop,
    };
  }

  /**
   * get emoji reaction position
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsInfo(state: ChatsStateModel) {
    return state.chatInfo;
  }

  /**
   * get loaded messages count by chats
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsLoadedMessagesCount(state: ChatsStateModel) {
    return state.loadedMessagesCount;
  }

  /**
   * get pin messages by chats
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getPinMessages(state: ChatsStateModel) {
    return state.pinMessage;
  }
  /**
   * get loaded pages count by chats
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsLoadedPagesCount(state: ChatsStateModel) {
    return state.loadedPagesCount;
  }

  /**
   * get thread emoji reaction state - open/closed
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatsThreadEmojiReaction(state: ChatsStateModel) {
    return state.threadEmojiReactionIsOpen;
  }

  /**
   *
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static isChatEmojiReactionUpdated(state: ChatsStateModel) {
    return state.chatEmojiReactionIsUpdated;
  }

  /**
   * get thread sidebar state - open/closed
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static isOpenThreadSidebar(state: ChatsStateModel) {
    return state.isOpenThreadSidebar;
  }

  /**
   * get private chat opening state
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static privateChatIsOpening(state: ChatsStateModel) {
    return state.privateChatIsOpening;
  }

  /**
   * get is threads for chat
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static isThreadsForChat(state: ChatsStateModel) {
    return state.isThreadsForChat;
  }

  /**
   * get active video call rooms
   * @param {ChatsStateModel} state
   */
  @Selector()
  static getActiveVideoCallRooms(state: ChatsStateModel) {
    return state.activeVideoCallChats;
  }

  /**
   * get thread files
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getThreadsFilters(state: ChatsStateModel) {
    return { onlyUnreadThreads: state.onlyUnreadThreads, isThreadsForChat: state.isThreadsForChat };
  }

  /**
   * get videoCallId by chatId
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getVideoCallIdByChatId(state: ChatsStateModel) {
    return (chatId: string) => state.chats?.find((chat) => chat._id === chatId)?.videoCallId;
  }

  /**
   * get chat files with pagination
   * @param  {ChatsStateModel} state
   */
  @Selector()
  static getChatFilesPagination(state: ChatsStateModel) {
    return state.chatFilesPagination;
  }

  constructor(
    private store: Store,
    private chatsService: ChatsService,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private videoCallService: VideoCallsService,
    private toastr: ToastrService,
    private offlineService: OfflineMessagesService,
    private sentryService: SentryIoService,
    private ticketService: TicketService,
    private translocoService: TranslocoService,
  ) { }

  @Action(ChatsGetMessages)
  chats_get_messages(
    { setState, patchState, getState, dispatch }: StateContext<ChatsStateModel>,
    action: ChatsGetMessages,
  ) {
    const call = action.payload;

    const query = {
      ...call,
      id: call.chatId,
      page: call.page || 1,
      perPage: call.perPage || 20,
    };

    return this.chatsService.chatsGetChatsMessages(query).pipe(
      tap(
        (res: any) => {
          if (call.isLocalState && res) {
            this.offlineService.setChatMessages(res.results);
          }

          if (res.data.query?.search) {
            this.store.dispatch(
              new ChatsSetSearchResults({
                chatId: res.data.chatId,
                messages: res.results,
                totalCount: res.totalCount,
                lastMessageId: res.data.lastMessageId,
                ignoreTotalCount: !!res.data._id,
                isClear: false,
                isSearch: true,
              }),
            );
          } else {
            this.store.dispatch(
              new ChatsSetMessages({
                chatId: res.data.chatId,
                messages: res.results,
                totalCount: res.totalCount,
                lastMessageId: res.data.lastMessageId,
                ignoreTotalCount: !!res.data._id,
                isClear: false,
                isSearch: false,
                messageId: res.data?.messageId,
                currentPage: res.data?.query.page,
              }),
            );
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ThreadGetMessages)
  thread_get_messages({ setState }: StateContext<ChatsStateModel>, { payload }: ThreadGetMessages) {
    return this.chatsService.threadGetChatsMessages(payload).pipe(
      tap(
        (res: any) => {
          if (payload.isLocalState && res) {
            this.offlineService.setThreadMessages(res.results);
          }

          this.store.dispatch(
            new ThreadsSetMessages({
              threadId: payload.threadId,
              lastMessageId: res.lastMessageId,
              messages: res.results,
              totalCount: res.totalCount,
            }),
          );
          this.sentryService.addBreadcrumb('Received thread messages for thread ' + payload.threadId);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ThreadGetCounters)
  thread_get_counters({ setState }: StateContext<ChatsStateModel>, action: ThreadGetCounters) {
    return this.chatsService.threadGetCounters(action.payload).pipe(
      tap(
        (res: any) => {
          console.log('pobierz wątki');
          //TODO Eddi Threads
          this.store.dispatch(new ChatsSetThreadsCounters(res));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(PinnedScrollingMessage)
  pinned_scrolling_message(
    { patchState, getState, dispatch }: StateContext<ChatsStateModel>,
    { payload }: PinnedScrollingMessage,
  ) {
    const state = getState();

    return this.chatsService.chatsGetMessagesList(payload).pipe(
      tap(
        (res: any) => {
          patchState({ messages: { ...state.messages, [state.chatId]: [] }, currentPage: res.data.page });
          dispatch(
            new ChatsSetMessages({
              chatId: res.data.chatId,
              messages: res.results,
              totalCount: res.totalCount,
              lastMessageId: res.data.lastMessageId,
              ignoreTotalCount: false,
              isClear: false,
              isSearch: false,
              currentPage: res.data?.query.page,
            }),
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ThreadGetList)
  thread_get_list({ setState }: StateContext<ChatsStateModel>, action: ThreadGetList) {
    return this.chatsService.threadGetList(action.payload).pipe(
      tap(
        (res: any) => {
          this.store.dispatch(new ChatsSetThreadsList(res));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ChatsGetMembers)
  chats_get_members({ setState }: StateContext<ChatsStateModel>, action: ChatsGetMembers) {
    return this.chatsService.chatMemberGetByChatId({ id: action.payload }).pipe(
      tap(
        (res: any) => {
          this.store.dispatch(new ChatsSetChatsMembers(res));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ChatsGet)
  chats_get({ setState }: StateContext<ChatsStateModel>, action: ChatsGet) {
    return this.chatsService.chatMemberGetChatsByUserId(action.payload).pipe(
      tap(
        (res: any) => {
          this.store.dispatch(new ChatsSetChats(res));
          this.sentryService.addBreadcrumb(`Chats fetched`);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set active video-call chat rooms
   * @param {patchState}: StateContext<ChatsStateModel>
   */
  @Action(ChatsSetActiveVideoCallRooms)
  chats_set_active_video_call_rooms({ patchState }: StateContext<ChatsStateModel>) {
    this.videoCallService.videoCallsGetActiveRooms().subscribe((rooms) => {
      patchState({ activeVideoCallChats: rooms });
    });
  }

  /**
   * Video-call participant joined
   * @param {setState}: StateContext<ChatStateModel>
   * @param {ChatsVideoCallParticipantJoined} action
   */
  @Action(ChatsVideoCallParticipantJoined)
  video_call_participant_joined({ setState }: StateContext<ChatsStateModel>, action: ChatsVideoCallParticipantJoined) {
    const call = action.payload;
    if (call.isCreator && call.userId === this.store.selectSnapshot(AuthState.getUser)._id) {
      this.store.dispatch(new VideoCallSetLastSessionId(call));
    }

    setState(
      patch({
        activeVideoCallChats: iif(
          (rooms) => !!rooms.find((room) => room.videoCallId === call.videoCallId),
          updateItem<VideoCallsGetResDto>(
            (room) => room.videoCallId === call.videoCallId,
            patch({
              participants: iif(!call.isExternal, append([call.userId])),
              externalParticipants: iif(call.isExternal, append([call.userName])),
            }),
          ),
          iif(
            !call.isExternal,
            append<VideoCallsGetResDto>([
              {
                videoCallId: call.videoCallId,
                sessionId: call.sessionId,
                targetObject: call.targetObject,
                targetObjectId: call.targetObjectId,
                targetObjectData: call.targetObjectData,
                handledCallUsers: call.handledCallUsers,
                participants: [call.userId],
                externalParticipants: [],
              },
            ]),
            append<VideoCallsGetResDto>([
              {
                videoCallId: call.videoCallId,
                sessionId: call.sessionId,
                targetObject: call.targetObject,
                targetObjectId: call.targetObjectId,
                targetObjectData: call.targetObjectData,
                handledCallUsers: call.handledCallUsers,
                participants: [],
                externalParticipants: [call.userName],
              },
            ]),
          ),
        ),
      }),
    );
  }

  /**
   * Video-call participant left
   * @param {setState}: StateContext<ChatStateModel>
   * @param {ChatsVideoCallParticipantLeft} action
   */
  @Action(ChatsVideoCallParticipantLeft)
  video_call_participant_left({ setState }: StateContext<ChatsStateModel>, action: ChatsVideoCallParticipantLeft) {
    setState(
      patch({
        activeVideoCallChats: iif(
          (rooms) => rooms.find((room) => room.videoCallId === action.payload.videoCallId)?.participants?.length === 1,
          removeItem<VideoCallsGetResDto>((room) => room.videoCallId === action.payload.videoCallId),
          updateItem<VideoCallsGetResDto>(
            (room) => room.videoCallId === action.payload.videoCallId,
            patch({
              participants: removeItem<string>((user) => user === action.payload.userId),
            }),
          ),
        ),
      }),
    );
  }

  /**
   * Set chatId action handler
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetChatId} action
   */
  @Action(ChatsSetChatId)
  set_chat_id({ patchState }: StateContext<ChatsStateModel>, action: ChatsSetChatId) {
    patchState({ chatId: action.payload });
  }

  /**
   * Clear upload files
   * @param  {patchState}: StateContext<ChatsStateModel>
   */
  @Action(ChatsClearUploadFiles)
  chat_clear_upload_files({ patchState }: StateContext<ChatsStateModel>) {
    patchState({ messagesToUpload: [] });
  }

  @Action(ChatsReconnectStatus)
  chat_reconnect_change_status({ patchState }: StateContext<ChatsStateModel>, { payload }: ChatsReconnectStatus) {
    patchState({ triggerToReconnectSocket: payload });
  }

  @Action(ChatsNewMessageToUpload)
  chats_new_message_to_upload(
    { setState, getState }: StateContext<ChatsStateModel>,
    { payload }: ChatsNewMessageToUpload,
  ) {
    setState(
      patch({
        messagesToUpload: append<Message>([payload.message]),
      }),
    );
  }

  /**
   * Clear action handler
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsClear} action
   */
  @Action(ChatsClear)
  chats_clear({ patchState }: StateContext<ChatsStateModel>, action: ChatsClear) {
    patchState({ chatId: null });
  }

  /**
   * Clear action handler
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsClear} action
   */
  @Action(ChatMessagesClear)
  chat_messages_clear({ patchState }: StateContext<ChatsStateModel>, action: ChatMessagesClear) {
    patchState({ messages: { [action.payload.chatId]: undefined } });
  }

  /**
   * Got new message via socket
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSocketNewMessage} action
   */
  @Action(ChatsSocketNewMessage)
  chats_socket_new_message(
    { getState, patchState, setState }: StateContext<ChatsStateModel>,
    { payload }: ChatsSocketNewMessage,
  ) {
    const { chats } = getState();
    const chat = chats.find((item) => item._id === payload.message.chatId);
    const isVideoCallMessage = !!payload?.message?.linkObjectData?.videoCallId;
    setState(
      patch({
        chats: updateItem(
          // @ts-ignore
          (item) => item._id === payload.message.chatId,
          patch({
            numberOfUnreadMessages: iif(payload.markUsUnRead, (chat.numberOfUnreadMessages || 0) + 1),
            numberOfUnreadMentions: iif(
              payload.markUsUnRead && payload.hasMention,
              (chat.numberOfUnreadMentions || 0) + 1,
            ),
            chatIsHidden: iif(chat.type === 'direct', false),
            lastMessage: payload.message,
            unreadUpdatedAt: payload.message.updated_at,
            numberOfUnreadServiceMessages: iif(
              payload.markUsUnRead && isVideoCallMessage,
              (chat.numberOfUnreadServiceMessages || 0) + 1,
            ),
          }),
        ),
        messages: patch({
          [payload.message.chatId]: iif(
            (messages: Message[]) =>
              messages &&
              payload.message._id &&
              messages[messages.findIndex((el) => el.timestamp === payload.timestamp && el.isUploading)]?.isUploading,
            updateItem((message) => message.timestamp === payload.timestamp && message.isUploading, payload.message),
            append([payload.message]),
          ),
        }),
        chatsLastMessage: iif(payload.message?.id, patch({ [payload.message.chatId]: payload.message?._id })),
        messagesToUpload: removeItem<Message>((msg) => msg?.timestamp === payload?.timestamp),
      }),
    );

    const newChats = payload.markUsUnRead
      ? chats.map((item) =>
        item._id === payload.message.chatId
          ? {
            ...item,
            numberOfUnreadMessages: item.numberOfUnreadMessages + 1,
            numberOfUnreadMentions: payload.hasMention
              ? (item.numberOfUnreadMentions || 0) + 1
              : item.numberOfUnreadMentions,
            lastMessage: payload.message,
            numberOfUnreadServiceMessages: isVideoCallMessage
              ? (item.numberOfUnreadServiceMessages || 0) + 1
              : item.numberOfUnreadServiceMessages,
          }
          : item,
      )
      : chats.map((item) => (item._id === payload.message.chatId ? { ...item, lastMessage: payload.message } : item));

    this.localStorage.set('chats', newChats);
  }

  /**
   * Updated online status
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSocketUpdatedMessage} action
   */
  @Action(SetOfflineStatus)
  update_offline_status({ patchState }: StateContext<ChatsStateModel>, { payload }: SetOfflineStatus) {
    patchState({ isOffline: payload });
  }

  /**
   * Updated message via socket
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSocketUpdatedMessage} action
   */
  @Action(ChatsSocketUpdatedMessage)
  chats_socket_updated_message(
    { setState, getState }: StateContext<ChatsStateModel>,
    { payload }: ChatsSocketUpdatedMessage,
  ) {
    const { threadsListInfo } = getState();
    let currThread;
    let newLastReplyTime;
    let indexToInsert;

    if (payload.message.threadId) {
      currThread = getState().threadsList.find((thread) => thread._id === payload.message.threadId);
      newLastReplyTime = new Date(payload.message.threadsMessagesInfo?.lastReply).getTime();
      indexToInsert = getState().threadsList.findIndex((thread) => {
        return new Date(thread.threadsMessagesInfo.lastReply).getTime() < newLastReplyTime;
      });
    }

    setState(
      patch({
        messages: patch({
          [payload.message.chatId]: iif(
            (messages: Message[]) => !!messages,
            updateItem((message) => message._id === payload.message._id, payload.message),
          ),
        }),
        threadsList: iif<Thread[]>(
          payload.threadMessage?.isDeleted,
          compose(
            removeItem((thread) => thread._id === payload.message.threadId),
            iif(
              payload.message?.threadsMessagesInfo?.messagesCount,
              compose(
                insertItem(currThread, indexToInsert - 1),
                updateItem<Thread>(
                  indexToInsert - 1,
                  patch({
                    threadsMessagesInfo: patch({
                      messagesCount: payload.message?.threadsMessagesInfo?.messagesCount,
                      lastReply: payload.message?.threadsMessagesInfo?.lastReply,
                    }),
                  }),
                ),
              ),
            ),
          ),
        ),
        threadsListInfo: iif(
          payload.threadMessage?.isDeleted && !payload.message.threadsMessagesInfo.messagesCount,
          patch({
            totalCount: threadsListInfo.totalCount - 1,
          }),
        ),
      }),
    );
  }

  /**
   * Updated message via socket
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSocketUpdatedPoll} action
   */
  @Action(ChatsSocketUpdatedPoll)
  chats_socket_updated_poll(
    { setState, getState }: StateContext<ChatsStateModel>,
    { payload }: ChatsSocketUpdatedPoll,
  ) {
    const oldMessage = getState().messages[payload.message.chatId]?.find(
      (message) => message._id === payload.message._id,
    );
    if (oldMessage) {
      const newMessage: Message = { ...oldMessage, poll: payload.message?.poll };
      setState(
        patch({
          messages: patch({
            [payload.message.chatId]: iif(
              (messages: Message[]) => !!messages,
              updateItem((message) => message._id === payload.message._id, newMessage),
            ),
          }),
        }),
      );
    }
  }

  /**
   * Deleted message via socket
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSocketDeletedMessage} action
   */
  @Action(ChatsSocketDeletedMessage)
  chats_socket_deleted_message(
    { getState, patchState }: StateContext<ChatsStateModel>,
    action: ChatsSocketDeletedMessage,
  ) {
    const { chatId, messages } = getState();

    if (messages[chatId]) {
      patchState({
        messages: {
          ...messages,
          [chatId]: messages[chatId].filter((item) => item['_id'] !== action.payload.message._id),
        },
      });
    }
  }

  /**
   * Delete out of limit messages
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsDeleteMessages} action
   */
  @Action(ChatsDeleteMessages)
  chats_delete_messages({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsDeleteMessages) {
    const state = getState();
    const messages = [...state.messages[state.chatId]];

    messages.splice(
      action.payload.start ? messages.length - action.payload.deleteCount : 0,
      action.payload.deleteCount,
    );

    patchState({ messages: { ...state.messages, [state.chatId]: messages } });
  }

  /**
   * Set messages
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetMessages} action
   */
  @Action(ChatsSetMessages)
  chats_set_messages({ getState, patchState }: StateContext<ChatsStateModel>, { payload }: ChatsSetMessages) {
    const state = getState();
    const chatId = payload.chatId;
    const lastMessageId = payload.lastMessageId;

    if (payload.messages) {
      const regexp = new RegExp(state.searchText, 'ig');
      const messagesOld =
        !payload.isClear && !!state.messages[chatId]
          ? payload?.currentPage === 1 && !state.loadedPages[chatId]?.includes(1)
            ? []
            : state.messages[chatId]
          : [];
      const messagesNew = [
        ...messagesOld?.filter((item) => !payload.messages.find((itemSub) => itemSub._id === item._id)),
        ...(payload.messageId
          ? payload.messages.map((item) => {
            if (item._id === payload.messageId || item._id === state.searchMessageId) {
              return { ...item, text: item.text.replaceAll(regexp, '<b class="searched-text">$&</b>') };
            }
            return item;
          })
          : payload.messages),
      ];

      messagesNew.sort((a, b) => {
        if (a._id > b._id) {
          return 1;
        } else if (a._id < b._id) {
          return -1;
        } else {
          return 0;
        }
      });

      const newState: Partial<ChatsStateModel> = {
        messages: {
          ...state.messages,
          [chatId]: messagesNew,
        },
        lastMessageId: {
          [chatId]: lastMessageId,
        },
      };

      if (payload?.currentPage === 1) {
        newState.chatsLastMessage = {
          ...state.chatsLastMessage,
          [chatId]: messagesNew.length > 0 ? messagesNew[messagesNew.length - 1]._id : null,
        };
      }

      if (payload.messageId) {
        newState.searchMessageId = payload.messageId;
      }
      newState.searchCurrentPage = payload.messageId ? payload?.currentPage : 0;

      if (!payload.messageId && messagesOld.length > 0) {
        newState.loadedPages = {
          ...state.loadedPages,
          [chatId]:
            state.loadedPages.hasOwnProperty(chatId) && !state.loadedPages[chatId].includes(payload?.currentPage)
              ? [...state.loadedPages[chatId], payload?.currentPage]
              : [payload?.currentPage],
        };
        if (payload?.currentPage > 1 && newState.loadedPages[chatId].length > 1) {
          const maxVal = Math.max.apply(null, newState.loadedPages[chatId]);
          newState.loadedPages[chatId] = newState.loadedPages[chatId].filter(
            (val) =>
              (maxVal <= payload?.currentPage && maxVal - 4 < val) ||
              (maxVal > payload?.currentPage && val <= payload?.currentPage),
          );
        }
      } else {
        newState.loadedPages = { ...state.loadedPages, [chatId]: [1] };
      }

      if (!payload.ignoreTotalCount) {
        newState.messagesTotalCount = { ...state.messagesTotalCount, [chatId]: payload.totalCount };
        newState.loadedMessagesCount = { ...state.loadedMessagesCount, [chatId]: newState.messages[chatId].length };
        newState.loadedPagesCount = {
          ...state.loadedPagesCount,
          [chatId]: Math.ceil(newState.messages[chatId].length / this.configService.MESSAGES_PER_PAGE),
        };
      }

      if (payload.isSearch) {
        newState.searchTotalCount = payload.totalCount;
      }

      patchState(newState);
    } else {
      patchState({ chatId });
    }
  }

  /**
   * Search messages
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSearchMessages} action
   */
  @Action(ChatsSearchMessages)
  chats_search_messages({ patchState }: StateContext<ChatsStateModel>, action: ChatsSearchMessages) {
    patchState({
      searchIsActive: action.payload.isActive,
      searchText: action.payload.searchText,
      searchResults: [],
    });

    if (!action.payload.isActive) {
      patchState({ searchMessageId: null });
    }
  }

  /**
   * Set search results
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetSearchResults} action
   */
  @Action(ChatsSetSearchResults)
  chats_set_search_results({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsSetSearchResults) {
    const state = getState();
    const chatId = action.payload.chatId;

    if (action.payload.messages) {
      const searchOld = !action.payload.isClear && !!state.searchResults ? state.searchResults : [];
      const regexp = new RegExp(state.searchText, 'ig');

      const searchNew = [
        ...searchOld.filter((item) => !action.payload.messages.find((itemSub) => itemSub['_id'] === item['_id'])),
        ...action.payload.messages.map((item) => {
          return { ...item, text: item.text.replaceAll(regexp, '<b class="searched-text">$&</b>') };
        }),
      ];

      patchState({ searchResults: searchNew, searchTotalCount: action.payload.totalCount });
    } else {
      patchState({ chatId });
    }
  }

  /**
   * Set chats
   * @param  {patchState}: StateContext<ChatsStateModel>
   */
  @Action(ChatsSet)
  set_chats({ patchState }: StateContext<ChatsStateModel>) {
    patchState({ chats: this.localStorage.get('chats') || [] });
  }

  @Action(ChatsUpdatePoll)
  update_poll({ patchState }: StateContext<ChatsStateModel>, action: ChatsUpdatePoll) {
    return this.chatsService.updatePoll(action.payload);
  }

  /**
   * Set chats
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetChats} action
   */
  @Action(ChatsSetChats)
  chats_set_chats({ patchState }: StateContext<ChatsStateModel>, action: ChatsSetChats) {
    patchState({ chats: action.payload });
    this.localStorage.set('chats', action.payload);
  }

  /**
   * Set chats members
   * @param  { getState, patchState }: StateContext<ChatsStateModel>
   * @param  {ChatsSetChatsMembers} action
   */
  @Action(ChatsSetChatsMembers)
  chats_set_chats_members({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsSetChatsMembers) {
    const state = getState();
    const chatId = action.payload[0]?.chatId;
    if (chatId) {
      patchState({
        chatMembers: { ...state.chatMembers, [chatId]: action.payload },
      });
    }
  }

  /**
   * Remove chats members
   * @param  { getState, patchState }: StateContext<ChatsStateModel>
   * @param  {ChatsRemoveChatsMembers} action
   */
  @Action(ChatsRemoveChatsMembers)
  chats_remove_chats_members({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsRemoveChatsMembers) {
    const state = getState();
    const chatId = action.payload.chatId;
    if (chatId) {
      patchState({
        chatMembers: { ...state.chatMembers, [chatId]: null },
      });
    }
  }

  /**
   * Update chats member username in chats members
   * @param  { getState, patchState }: StateContext<ChatsStateModel>
   * @param  {ChatsUpdateChatsMembers} action
   */
  @Action(ChatsUpdateChatsMembers)
  chats_update_chats_members({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsUpdateChatsMembers) {
    const chatMembers = {};
    const chatMembersOld = getState().chatMembers;
    const userId = action.payload._id;

    for (const key of Object.keys(chatMembersOld)) {
      chatMembers[key] = chatMembersOld[key].map((item) => {
        if (item.userId === userId) {
          return {
            ...item,
            userName: action.payload.userName,
            timezone: action.payload.timezone,
          };
        }

        return item;
      });
    }

    patchState({ chatMembers });
  }

  /**
   * Set chat as read locally
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsMarkAsRead} action
   */
  @Action(ChatsMarkAsRead)
  chats_mark_as_read({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsMarkAsRead) {
    const { chats } = getState();

    const newChats = chats.map((item) => {
      if (item._id === action.payload.chatId) {
        return {
          ...item,
          numberOfUnreadMessages: 0,
          numberOfUnreadMentions: 0,
        };
      }
      return item;
    });

    patchState({ chats: newChats });
    this.localStorage.set('chats', newChats);
  }

  /**
   * Update read counter
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsUpdateCounter} action
   */
  @Action(ChatsUpdateCounter)
  chats_update_read({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsUpdateCounter) {
    const { chats, messages } = getState();
    const message = action.payload.message;
    const isMention = action.payload.hasMention;

    const newChats = chats.map((item) => {
      if (item._id === message.chatId) {
        const numberOfUnreadMessages = item.numberOfUnreadMessages > 0 ? item.numberOfUnreadMessages - 1 : 0;
        const numberOfUnreadMentions =
          isMention && item.numberOfUnreadMentions > 0 ? item.numberOfUnreadMentions - 1 : 0;
        return {
          ...item,
          numberOfUnreadMessages,
          numberOfUnreadMentions,
        };
      }
      return item;
    });

    const messagesById = messages[message.chatId];
    if (messagesById && message.chatId) {
      const filteredMessages = messagesById.filter((msg) => msg._id !== message._id);
      patchState({
        messages: {
          ...messages,
          [message.chatId]: filteredMessages,
        },
      });
    }

    patchState({ chats: newChats });
    this.localStorage.set('chats', newChats);
  }

  /**
   * Set thread as read locally
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ThreadMarkAsRead} action
   */
  @Action(ThreadMarkAsRead)
  thread_mark_as_read({ getState, patchState }: StateContext<ChatsStateModel>, { payload }: ThreadMarkAsRead) {
    const { chats, messages, threadsList } = getState();
    const newChats = chats.map((chat) => {
      if (chat._id === payload.chatId) {
        return {
          ...chat,
          chatThreadsTotalUnreadMessages: payload.chatThreadsTotalUnreadMessages,
          chatThreadsTotalUnreadMentions: payload.chatThreadsTotalUnreadMentions,
        };
      }
      return chat;
    });

    const mapFn = (item) => {
      const info = item.threadsMessagesInfo;
      const threadsMessagesInfo =
        item._id === payload.threadId ? { ...info, numberOfUnreadMessages: 0, numberOfUnreadMentions: 0 } : info;

      return { ...item, threadsMessagesInfo };
    };

    const newThreadsList = threadsList.map(mapFn);
    patchState({ chats: newChats, threadsList: newThreadsList });

    const message = messages[payload.chatId]?.find((item) => item.threadId === payload.threadId);
    if (message) {
      const newMessages = messages[payload.chatId]?.map((item) => {
        if (item.threadId === payload.threadId) {
          return {
            ...item,
            threadsMessagesInfo: {
              ...item.threadsMessagesInfo,
              numberOfUnreadMessages: 0,
              numberOfUnreadMentions: 0,
            },
          };
        }
        return item;
      });

      patchState({ messages: { ...messages, [payload.chatId]: newMessages } });
    }

    this.localStorage.set('chats', newChats);
  }

  /**
   * Set threads as read locally
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsThreadsListMarkAllAsRead} action
   */
  @Action(ChatsThreadsListMarkAllAsRead)
  threads_mark_as_read(
    { getState, patchState, setState }: StateContext<ChatsStateModel>,
    action: ChatsThreadsListMarkAllAsRead,
  ) {
    if (action.payload.success) {
      const { chats, threadsList, threadsListInfo, messages } = getState();

      let newMessages = messages;
      const newChats = chats.map((chat) => {
        if (messages[chat._id]) {
          newMessages = {
            ...newMessages,
            [chat._id]: messages[chat._id]?.map((item) => {
              return {
                ...item,
                threadsMessagesInfo: {
                  ...item.threadsMessagesInfo,
                  numberOfUnreadMessages: 0,
                  numberOfUnreadMentions: 0,
                },
              };
            }),
          };
        }

        return {
          ...chat,
          chatThreadsTotalUnreadMessages: 0,
          chatThreadsTotalUnreadMentions: 0,
        };
      });
      const newThreadsList = threadsList.map((item) => {
        return {
          ...item,
          threadsMessagesInfo: {
            ...item.threadsMessagesInfo,
            numberOfUnreadMessages: 0,
            numberOfUnreadMentions: 0,
          },
        };
      });
      const newThreadsListInfo = {
        totalCount: threadsListInfo.totalCount,
        totalUnreadThreads: 0,
        totalUnreadMessages: 0,
        totalUnreadMentions: 0,
      };

      patchState({
        chats: newChats,
        threadsList: newThreadsList,
        threadsListInfo: newThreadsListInfo,
        messages: newMessages,
      });
      this.localStorage.set('chats', newChats);
    }
  }

  /**
   * Set all chats and threads as read locally
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsMarkAllAsRead} action
   */
  @Action(ChatsMarkAllAsRead)
  mark_all_as_read({ getState, patchState, setState }: StateContext<ChatsStateModel>, action: ChatsMarkAllAsRead) {
    if (action.payload.success) {
      const { chats, threadsList, threadsListInfo, messages } = getState();

      let newMessages = messages;
      const newChats = chats.map((chat) => {
        if (messages[chat._id]) {
          newMessages = {
            ...newMessages,
            [chat._id]: messages[chat._id]?.map((item) => {
              return {
                ...item,
                threadsMessagesInfo: {
                  ...item.threadsMessagesInfo,
                  numberOfUnreadMessages: 0,
                  numberOfUnreadMentions: 0,
                },
              };
            }),
          };
        }

        return {
          ...chat,
          numberOfUnreadMessages: 0,
          numberOfUnreadMentions: 0,
          chatThreadsTotalUnreadMessages: 0,
          chatThreadsTotalUnreadMentions: 0,
        };
      });
      const newThreadsList = threadsList.map((item) => {
        return {
          ...item,
          threadsMessagesInfo: {
            ...item.threadsMessagesInfo,
            numberOfUnreadMessages: 0,
            numberOfUnreadMentions: 0,
          },
        };
      });
      const newThreadsListInfo = {
        totalCount: threadsListInfo.totalCount,
        totalUnreadThreads: 0,
        totalUnreadMessages: 0,
        totalUnreadMentions: 0,
      };

      patchState({
        chats: newChats,
        threadsList: newThreadsList,
        threadsListInfo: newThreadsListInfo,
        messages: newMessages,
      });
      this.localStorage.set('chats', newChats);
    }
  }

  @Action(ThreadsMarkAsUnRead)
  threadsMarkAsUnread({ setState, getState }: StateContext<ChatsStateModel>, { payload }: ThreadsMarkAsUnRead) {
    const currentChat = getState().chats.find((chat) => chat._id === payload.chatId);

    setState(
      patch({
        chats: updateItem(
          (chat: any) => chat._id === payload.chatId,
          patch({
            chatThreadsTotalUnreadMentions: iif(payload.hasMention, currentChat.chatThreadsTotalUnreadMentions + 1),
            chatThreadsTotalUnreadMessages: currentChat.chatThreadsTotalUnreadMessages + 1,
            chatThreadsUnreadUpdatedAt: payload.threadMessage.updated_at,
          }),
        ),
      }),
    );

    this.localStorage.set('chats', getState().chats);
  }

  /**
   * Upload files via chat
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsUploadFile} action
   */
  @Action(ChatsUploadFile)
  chats_upload_file({ getState, patchState }: StateContext<ChatsStateModel>, { payload }: ChatsUploadFile) {
    if (!payload.isAudioMessage) {
      patchState({
        sendFileLoading: true,
      });
    }

    return this.chatsService.chatFilesUpload(payload).pipe(
      tap(
        () => {
          patchState({
            sendFileLoading: false,
          });

          if (payload.isAudioMessage) {
            this.toastr.clear(payload.toastId);
            this.toastr.success(
              this.translocoService.translate('toastr.record-successfully-saved-you-can-leave'),
              this.translocoService.translate('toastr.title-success'),
            );
          }
        },
        (err) => {
          patchState({
            sendFileLoading: false,
          });

          if (payload.isAudioMessage) {
            this.toastr.clear(payload.toastId);
          }

          throw err.error;
        },
      ),
    );
  }

  /**
   * Update message draft
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param action
   */
  @Action(ChatsUpdateMessageDraft)
  setChatsUpdateMessageDraft({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsUpdateMessageDraft) {
    const state = getState();
    if (action.payload.chatId) {
      return patchState({
        messagesDrafts: [
          ...state.messagesDrafts.filter((item) => item['chatId'] !== action.payload.chatId),
          {
            chatId: action.payload.chatId,
            text: action.payload.text,
          },
        ],
      });
    }
  }

  /**
   * Set current chat name
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param action
   */
  @Action(ChatsSetCurrentChatName)
  setChatCurrentName({ patchState }: StateContext<ChatsStateModel>, action: ChatsSetCurrentChatName) {
    return patchState({ selectedChatName: action.payload.chatName });
  }

  /**
   * Clear message draft on send
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param action
   */
  @Action(ChatsClearMessageDraft)
  clearMessageDraft({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsClearMessageDraft) {
    const state = getState();
    return patchState({
      messagesDrafts: state.messagesDrafts.filter((item) => item['chatId'] !== action.payload.chatId),
    });
  }

  /**
   * Set emoji state - open/closed
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsEmojiPicker} action
   */
  @Action(ChatsEmojiPicker)
  chats_emoji_picker({ patchState }: StateContext<ChatsStateModel>, { payload }: ChatsEmojiPicker) {
    if (payload.isThread) {
      // Thread emoji picker is opened/closed
      patchState({
        threadEmojiIsOpen: payload.emojiPickerIsOpen,
      });
    } else if (payload.isThreadEmojiReaction) {
      patchState({
        threadEmojiReactionIsOpen: payload.emojiPickerIsOpen,
        emojiPickerLeft: payload.left,
        emojiPickerTop: payload.top,
        chatInfo: payload.chatInfo,
      });
    } else if (payload.isChatEmojiReaction) {
      patchState({
        chatEmojiReactionIsOpen: payload.emojiPickerIsOpen,
        emojiPickerLeft: payload.left,
        emojiPickerTop: payload.top,
        chatInfo: payload.chatInfo,
      });
    } else {
      // Chat emoji picker is opened/closed
      patchState({
        chatEmojiIsOpen: payload.emojiPickerIsOpen,
      });
    }
  }

  /**
   * add new emoji reaction to message
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsAddEmojiReaction} action
   */
  @Action(ChatsAddEmojiReaction)
  chats_add_message_emoji_reaction(
    { getState, patchState }: StateContext<ChatsStateModel>,
    { payload: { chatId, emoji } }: ChatsAddEmojiReaction,
  ) {
    const { messages } = getState();

    const messagesList = !messages[chatId]
      ? []
      : messages[chatId]
        .map((item) => {
          if (!item['emojis']) {
            return { ...item, emojis: [] };
          }
          return item;
        })
        .map((message) => {
          if (message['_id'] === emoji.messageObjectId) {
            return {
              ...message,
              emojis: [...message['emojis'], emoji],
              aggregatedEmojis: [],
            };
          }
          return message;
        });

    patchState({
      chatEmojiReactionIsUpdated: true,
      messages: {
        ...messages,
        [chatId]: [...messagesList],
      },
    });
  }

  /**
   * delete emoji reaction from message
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsDeleteEmojiReaction} action
   */
  @Action(ChatsDeleteEmojiReaction)
  chats_delete_message_emoji_reaction(
    { getState, patchState }: StateContext<ChatsStateModel>,
    { payload: { chatId, messageId, emojiId } }: ChatsDeleteEmojiReaction,
  ) {
    const { messages } = getState();

    const messagesList = messages[chatId]?.map((message: any) => {
      if (message['_id'] === messageId) {
        return {
          ...message,
          emojis: [...message['emojis'].filter((emoji) => emoji._id !== emojiId)],
          aggregatedEmojis: this.updateAggregatedEmojis(message.emojis, emojiId),
        };
      }

      return message;
    });

    patchState({
      chatEmojiReactionIsUpdated: true,
      messages: {
        ...messages,
        [chatId]: [...messagesList],
      },
    });
  }

  private updateAggregatedEmojis(emojis: any[], emojiId) {
    const username = this.store.selectSnapshot(AuthState.getUser).userName;

    return EmojisHelper.aggregateEmojis(username, emojis, emojiId);
  }

  /**
   * Reset chat emoji reaction is updated
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param action
   */
  @Action(ChatsResetEmojiReactionIsUpdated)
  chats_reset_emoji_reaction_is_updated(
    { patchState }: StateContext<ChatsStateModel>,
    action: ChatsResetEmojiReactionIsUpdated,
  ) {
    return patchState({
      chatEmojiReactionIsUpdated: false,
    });
  }

  /**
   * Set threads list
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetThreadsList} action
   */
  @Action(ChatsSetThreadsList)
  chats_set_threads_list({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsSetThreadsList) {
    const { threadsList, isThreadsForChat, onlyUnreadThreads } = getState();
    const data = action.payload.data;
    const threadsListOld =
      !threadsList || (data.query?.page === 1 && (isThreadsForChat || onlyUnreadThreads)) ? [] : threadsList;
    const threadsListNew: Array<any> = [
      ...threadsListOld.filter((item) => !action.payload.results.find((itemSub) => itemSub._id === item._id)),
      ...(action.payload.results || []),
    ];
    const threadsListInfoNew = {
      totalCount: action.payload.totalCount,
      totalUnreadThreads: action.payload.totalUnreadThreads,
      totalUnreadMessages: action.payload.totalUnreadMessages,
      totalUnreadMentions: action.payload.totalUnreadMentions,
    };

    patchState({
      threadsList: threadsListNew,
      threadsListInfo: threadsListInfoNew,
    });
  }

  @Action(ChatsUpdateThreadsList)
  chatsAddThreadToList({ getState, setState }: StateContext<ChatsStateModel>, { payload }: ChatsUpdateThreadsList) {
    const { threadsList, threadsListInfo, isThreadsForChat, onlyUnreadThreads } = getState();
    const currentThread = threadsList.find((thread) => thread._id === payload.message?.threadId);

    setState(
      patch({
        threadsList: iif<Thread[]>(
          !!currentThread,
          compose(
            removeItem<Thread>((thread) => thread._id === payload.message?.threadId),
            insertItem(currentThread, 0),
            updateItem<Thread>(
              0,
              patch({
                threadsMessagesInfo: patch({
                  messagesCount: currentThread?.threadsMessagesInfo.messagesCount + 1,
                  lastReply: payload.message?.threadsMessagesInfo.lastReply,
                  numberOfUnreadMessages: iif(
                    payload.changeCounter,
                    currentThread?.threadsMessagesInfo.numberOfUnreadMessages + 1,
                  ),
                  numberOfUnreadMentions: iif(
                    payload.changeCounter && payload.hasMention,
                    currentThread?.threadsMessagesInfo.numberOfUnreadMentions + 1,
                  ),
                }),
              }),
            ),
          ),
          iif(
            !!!threadsListInfo?.totalCount || !!threadsList?.length,
            insertItem<Thread>(
              {
                _id: payload.message?.threadId,
                threadsMessagesInfo: {
                  ...payload.message?.threadsMessagesInfo,
                  numberOfUnreadMessages: payload.changeCounter ? 1 : 0,
                  numberOfUnreadMentions: payload.changeCounter && payload.hasMention ? 1 : 0,
                },
                message: payload.message,
              },
              0,
            ),
          ),
        ),
        threadsListInfo: iif(
          !onlyUnreadThreads && !isThreadsForChat,
          patch({
            totalUnreadThreads: iif(
              !!!currentThread?.threadsMessagesInfo.numberOfUnreadMessages && payload.changeCounter,
              threadsListInfo.totalUnreadThreads + 1,
            ),
            totalUnreadMessages: iif(payload.changeCounter, threadsListInfo.totalUnreadMessages + 1),
            totalUnreadMentions: iif(
              payload.hasMention && payload.changeCounter,
              threadsListInfo.totalUnreadMentions + 1,
            ),
            totalCount: threadsListInfo.totalCount + 1,
          }),
        ),
      }),
    );
  }

  @Action(ChatsUpdateThreadInList)
  chatsUpdateThreadInList({ setState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsUpdateThreadInList) {
    const { threadsListInfo } = getState();
    setState(
      patch({
        threadsList: iif(
          payload.isDeleted && payload.linkObject !== 'tickets',
          removeItem<Thread>((thread) => thread._id === payload.threadId),
          updateItem<Thread>((thread) => thread._id === payload.threadId, patch({ message: payload })),
        ),
        threadsListInfo: iif(
          payload.isDeleted && !!payload.threadsMessagesInfo?.messagesCount && payload.linkObject !== 'tickets',
          patch({
            totalCount: threadsListInfo.totalCount - 1,
          }),
        ),
      }),
    );
  }

  /**
   * Set threads counters
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetThreadsCounters} action
   */
  @Action(ChatsSetThreadsCounters)
  chats_set_threads_counters({ patchState, getState }: StateContext<ChatsStateModel>, action: ChatsSetThreadsCounters) {
    const threadsListInfo = {};
    if (action.payload.totalCount) {
      threadsListInfo['totalCount'] = action.payload.totalCount;
    }
    if (action.payload.totalUnreadThreads) {
      threadsListInfo['totalUnreadThreads'] = action.payload.totalUnreadThreads;
    }
    if (action.payload.totalUnreadMessages) {
      threadsListInfo['totalUnreadMessages'] = action.payload.totalUnreadMessages;
    }
    if (action.payload.totalUnreadMentions) {
      threadsListInfo['totalUnreadMentions'] = action.payload.totalUnreadMentions;
    }

    patchState({ threadsListInfo });
  }

  /**
   * Edit chats group name
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsEditGroupName} action
   */
  @Action(ChatsEditGroupName)
  chats_edit_group_name({ getState, patchState }: StateContext<ChatsStateModel>, action: ChatsEditGroupName) {
    const state = getState();
    const chats = state.chats.map((chat) => {
      if (chat._id === action.payload._id) {
        chat = { ...chat, chatName: action.payload.chatName };
      }

      return chat;
    });

    patchState({ chats });
    this.localStorage.set('chats', chats);
  }

  /**
   * Set thread sidebar state - open/closed
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsOpenThreadSidebar} action
   */
  @Action(ChatsOpenThreadSidebar)
  chats_set_open_thread_sidebar({ patchState }: StateContext<ChatsStateModel>, action: ChatsOpenThreadSidebar) {
    patchState({ isOpenThreadSidebar: action.payload });
  }

  /**
   * Set private chat opening state
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsPrivateMessagesIsOpening} action
   */
  @Action(ChatsPrivateMessagesIsOpening)
  chat_set_private_message_opening_status(
    { patchState }: StateContext<ChatsStateModel>,
    action: ChatsPrivateMessagesIsOpening,
  ) {
    patchState({ privateChatIsOpening: action.payload });
  }

  /**
   * Set is threads for chat state
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsSetIsThreadsForChat} action
   */
  @Action(ChatsSetIsThreadsForChat)
  chats_set_is_threads_for_chat({ patchState }: StateContext<ChatsStateModel>, action: ChatsSetIsThreadsForChat) {
    patchState({ isThreadsForChat: action.payload, threadsList: [] });
  }

  @Action(ChatsOnlyUnreadThreadsFilter)
  chatsOnlyUnreadThreadsFilter(
    { patchState }: StateContext<ChatsStateModel>,
    { payload }: ChatsOnlyUnreadThreadsFilter,
  ) {
    patchState({ onlyUnreadThreads: payload, threadsList: [] });
  }

  /**
   * Get chat files list state
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsGetFilesList} action
   */
  @Action(ChatsGetFilesList)
  chats_get_files_list({ patchState }: StateContext<ChatsStateModel>, { payload }: ChatsGetFilesList) {
    return this.chatsService.chatsGetFilesList(payload).pipe(
      tap(
        (result) => {
          const chatFiles =
            result?.length > 0 ? result.map((file) => ({ ...file, parentId: 'Chat', type: 'public' })) : [];

          patchState({ chatFiles });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get chat files list with pagination state
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsGetFilesListPagination} action
   */
  @Action(ChatsGetFilesListPagination)
  chats_get_files_list_pagination(
    { getState, patchState }: StateContext<ChatsStateModel>,
    { payload }: ChatsGetFilesListPagination,
  ) {
    const state = getState();
    return this.chatsService.chatsGetFilesListPagination(payload).pipe(
      tap(
        (result) => {
          const chatFiles =
            result?.results.length > 0
              ? result.results.map((file) => ({ ...file, parentId: 'Chat', type: 'public' }))
              : [];

          patchState({
            chatFilesPagination: result,
            chatFiles: payload.page === 1 ? chatFiles : [...state.chatFiles, ...chatFiles],
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Clear chat files list state
   * @param  {patchState}: StateContext<ChatsStateModel>
   */
  @Action(ChatsClearFilesList)
  chats_clear_files_list({ patchState }: StateContext<ChatsStateModel>) {
    patchState({ chatFiles: [] });
  }

  /**
   * Get chat pin list
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsGetPin} action
   */
  @Action(ChatsGetPin)
  chats_get_pin_messages({ patchState }: StateContext<ChatsStateModel>, { payload }: ChatsGetPin) {
    const { isInternalState, ...rest } = payload;
    return this.chatsService.chatsGetPinnedMessagesList(rest).pipe(
      tap(
        (result) => {
          if (isInternalState) {
            // set pinned messages into service state without changing in global
            this.ticketService.setPinnedMessages(result);
          } else {
            patchState({ pinMessage: result });
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get chat file list
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsGetFile} action
   */
  @Action(ChatsGetFile)
  chats_get_file({ patchState }: StateContext<ChatsStateModel>, { payload }: ChatsGetFile) {
    if (payload.threadId) {
      const media = payload.mainMessage ? [payload.mainMessage, ...payload.chatFiles] : [...payload.chatFiles];
      patchState({ fileThreadChat: media });
    } else {
      patchState({ fileCurrentChat: [...payload.chatFiles] });
    }
    // return this.chatsService.chatsGetMediaFilesList(payload).pipe(
    //   tap(
    //     (result) => {
    //       if (payload.threadId) {
    //         const media = payload.mainMessage ? [payload.mainMessage, ...result] : [...result];
    //         patchState({ fileThreadChat: media });
    //       } else {
    //         patchState({ fileCurrentChat: result });
    //       }
    //     },
    //     (err) => {
    //       throw err.error;
    //     },
    //   ),
    // );
  }

  /**
   * Clear chat file list
   * @param  {patchState}: StateContext<ChatsStateModel>
   */
  @Action(ChatsClearFile)
  chats_clear_file({ patchState }: StateContext<ChatsStateModel>) {
    return patchState({ fileCurrentChat: [] });
  }

  /**
   * Update chat file list
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsUpdateFile} action
   */
  @Action(ChatsUpdateFile)
  chats_update_file({ patchState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsUpdateFile) {
    const filesChat = getState().fileCurrentChat;
    return patchState({ fileCurrentChat: [...filesChat, payload] });
  }

  /**
   * Create pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */
  @Action(ChatsCreatePin)
  chats_create_pin_message({ setState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsCreatePin) {
    // Max order for pinned messages
    payload.body.order = getState().pinMessage?.reduce((max, obj) => (obj.order > max ? obj.order : max), 0);
    return this.chatsService.chatsCreatePinnedMessage(payload).pipe(
      tap(
        (result: any) => {
          const chatId = getState().chatId;
          if (chatId !== result.chatId) {
            // case when we trigger api from another chat
            return;
          }

          setState(
            patch<ChatsStateModel>({
              pinMessage: iif((pins) => pins.every((pin) => pin._id !== result._id), append([result])),
            }),
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */
  @Action(ChatsDeletePin)
  chats_delete_pin_message({ patchState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsDeletePin) {
    return this.chatsService.chatsDeletePinnedMessage(payload).pipe(
      tap(
        () => {
          const state = getState();
          const pins = state.pinMessage.filter((item) => item._id !== payload.pinnedMessageId);
          patchState({ pinMessage: pins });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Order pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */
  @Action(ChatsOrderPin)
  chats_order_pin_message({ patchState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsOrderPin) {
    return this.chatsService.chatsControllerPinnedMessagesOrderUpdate(payload).pipe(
      catchError((err) => {
        throw err.error;
      }),
    );
  }

  /**
   * Update pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */
  @Action(ChatsUpdatePin)
  chats_update_pin_message({ setState, getState }: StateContext<ChatsStateModel>, { payload }: ChatsUpdatePin) {
    return this.chatsService.chatsUpdatePinnedMessage(payload).pipe(
      tap(
        (res: any) => {
          setState(
            patch<ChatsStateModel>({
              pinMessage: updateItem((pin) => pin._id === res._id, res),
            }),
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update socket pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */
  @Action(ChatsUpdateSocketPin)
  chats_socket_update_pin_message(
    { setState, getState, patchState }: StateContext<ChatsStateModel>,
    { payload }: ChatsUpdateSocketPin,
  ) {
    const state = getState();

    this.ticketService.updatePinnedMessage({ payload });
    const chatId = getState().chatId;
    if (chatId !== payload.message?.chatId) {
      // case when we trigger api from another chat
      return;
    }
    if (payload.type === 'delete') {
      const pins = state.pinMessage.filter((item) => item._id !== payload.message._id);
      patchState({ pinMessage: pins });
    } else {
      setState(
        patch<ChatsStateModel>({
          pinMessage: iif(
            (pins: PinnedMessagesDbDto[]) => pins.some((pinned) => pinned._id === payload.message._id),
            updateItem((pin: PinnedMessagesDbDto) => pin._id === payload.message._id, payload.message),
            append([payload.message]),
          ),
        }),
      );
    }
  }

  /**
   * Action socket order pin message
   * @param  {patchState}: StateContext<ChatsStateModel>
   * @param  {ChatsCreatePin} action
   */

  @Action(ChatsUpdateOrderSocketPin)
  chats_socket_order_pin_message(
    { setState, getState, patchState }: StateContext<ChatsStateModel>,
    { payload }: ChatsUpdateOrderSocketPin,
  ) {
    const state = getState();

    const newData = state.pinMessage?.map((pin) => {
      const findPin = payload.find((pinResponse) => pinResponse?._id === pin?._id);
      if (findPin) {
        return { ...pin, order: findPin?.order };
      } else {
        return pin;
      }
    });

    const sortedPins = newData.sort((prev, cur) => prev.order - cur.order);

    patchState({ pinMessage: sortedPins });
  }

  @Action(ChatsMessageCreate)
  chats_create_message({ }, { payload }: ChatsMessageCreate) {
    const offlineMessage: Message[] = this.localStorage.get('uploadMessage') || [];
    if (!offlineMessage.some((msg) => msg.timestamp === payload.timestamp)) {
      this.localStorage.set('uploadMessage', [...offlineMessage, payload]);
    }

    return this.chatsService.chatsMessagesCreate({ body: payload }).pipe(
      tap(
        () => {
          const message: any = this.localStorage.get('uploadMessage') || [];

          const removeOfflineMessage = message.filter((msg) => msg?.timestamp !== payload?.timestamp);
          this.localStorage.set('uploadMessage', removeOfflineMessage);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete chats member
   * @param  { getState, patchState }: StateContext<ChatsStateModel>
   * @param  {ChatsDeleteChatsMembers} action
   */
  @Action(ChatsDeleteChatsMembers)
  chats_delete_chats_members(
    { getState, patchState }: StateContext<ChatsStateModel>,
    { payload }: ChatsDeleteChatsMembers,
  ) {
    const { chatId, userId } = payload;
    const prevChatMembers = getState().chatMembers;
    const chatMemberFromActionChat = prevChatMembers[chatId];
    if (chatMemberFromActionChat) {
      const filteredMembers = chatMemberFromActionChat.filter((member) => member.userId !== userId);
      patchState({ chatMembers: { ...prevChatMembers, [chatId]: filteredMembers } });
    }
  }

  // TODO: Updating orders for group chats
  @Action(ChatsOrdersUpdate)
  chats_orders_update({ getState, setState }: StateContext<ChatsStateModel>, action: ChatsOrdersUpdate) {
    const { requestUuid, chats } = getState();
    let uniqueRequestId = '';
    if (requestUuid) {
      uniqueRequestId = requestUuid;
    } else {
      uniqueRequestId = uuidv4();
    }

    const updatedGroupChats = action.payload.groupChats
      .filter((chat) => chats.some((c) => c._id === chat._id))
      .map((chat) => {
        return updateItem(
          (c: any) => c._id === chat._id,
          patch({
            order: chat.order,
          }),
        );
      });

    setState(
      patch({
        chats: compose(...updatedGroupChats),
        requestUuid: uniqueRequestId,
      }),
    );

    // setState(
    //   patch({
    //     chatMembers: Object.assign(chatMembers, ...updatedGroupChats),
    //     requestUuid: uniqueRequestId,
    //   }),
    // );

    return this.chatsService.chatMemberGetChatsByUserId_1({ body: { ...action.payload, requestId: uniqueRequestId } });
  }

  /**
   * SocketUpdateCalendarEvent
   * @param  {getState, patchState}: StateContext<ChatsStateModel>
   * @param  {SocketUpdateCalendarEvent} action
   */
  @Action(SocketUpdateCalendarEvent)
  socket_updated_calendar_event(
    { setState, getState }: StateContext<ChatsStateModel>,
    { payload }: SocketUpdateCalendarEvent,
  ) {
    const { chatId, messages: chatMessages, threadsList } = getState();
    const messages = chatMessages[chatId];
    const { _id: calendarEventId, start } = payload;
    let stateToUpdate = {};

    const isNeedToUpdateMessages = messages?.some(
      (message: Message & { linkObjectData: CalendarEventsDbDto }) => message.linkObjectId === calendarEventId,
    );
    const isNeedToUpdateThreadsList = threadsList?.some((thread) => thread.message.linkObjectId === calendarEventId);
    if (!isNeedToUpdateMessages && !isNeedToUpdateThreadsList) {
      return;
    }

    if (isNeedToUpdateMessages) {
      const updatedMessages = messages.map((message: Message & { linkObjectData: CalendarEventsDbDto }) => {
        if (message.linkObjectId === calendarEventId) {
          return { ...message, linkObjectData: { ...message.linkObjectData, start } };
        }
        return message;
      });

      stateToUpdate = {
        ...stateToUpdate,
        messages: {
          [chatId]: updatedMessages,
        },
      };
    }

    if (isNeedToUpdateThreadsList) {
      const updatedThreadsList = threadsList?.map((thread) => {
        if (thread.message.linkObjectId === calendarEventId) {
          return {
            ...thread,
            message: { ...thread.message, linkObjectData: { ...thread.message.linkObjectData, start } },
          };
        }
        return thread;
      });

      stateToUpdate = {
        ...stateToUpdate,
        threadsList: updatedThreadsList,
      };
    }

    setState(patch(stateToUpdate));
  }
}
