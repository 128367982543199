<div class="custom-input" [class.w-full]="widthFull" [class.dark]="isDark">
  <p *ngIf="legend">{{legend}}</p>
  <input
    #inputRef
    type="text"
    [value]="value"
    (input)="onChange(inputRef.value)"
    [placeholder]="placeHolder"

    [ngClass]="{
      customClasses: true,
      'w-full': widthFull
    }"
  />
</div>
