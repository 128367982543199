<div class="details" [class.dark]="isDark" *transloco="let t; read: 'modals.payment'">
  <form class="details__form" [formGroup]="pinForm" (ngSubmit)="submitForm()">
    <div class="details__left">
      <h4 class="details__title">{{t('proceed-subscription')}}</h4>

      <div class="details__field">
        <app-input-text formControlName="firstName" legend="First Name" [widthFull]="true" />
        <app-input-text formControlName="lastName" legend="Last Name" [widthFull]="true" />
      </div>

      <div class="details__field">
        <app-input-text formControlName="companyName" legend="Company name" placeHolder="Enter the name" [widthFull]="true" />
        <app-input-text formControlName="address" legend="Address" placeHolder="Enter the address" [widthFull]="true" />
      </div>

      <div class="details__field">
        <app-input-selector
          [items]="countries"
          bindLabel="nameEn"
          bindValue="id"
          formControlName="countryId"
          legend="Country"
          placeHolder="Enter the name"
          [widthFull]="true"
        />
        <app-input-text formControlName="vatId" legend="VAT ID" placeHolder="Enter the name" [widthFull]="true" />
      </div>

      <div class="details__field">
        <app-input-text formControlName="city" legend="City" placeHolder="Select city" [widthFull]="true" />
        <app-input-text formControlName="postalCode" legend="Postal code" placeHolder="Enter the name" [widthFull]="true" />
      </div>

      <div class="details__field">
        <app-input-text formControlName="billingEmail" legend="Billing email address" placeHolder="Enter the email" [widthFull]="true" />
      </div>
    </div>

    <div class="details__right">
      <h4 class="details__title">{{t('subscription-selection')}}</h4>

      <!--BOX WITH INFO AND PRICE-->
      <div class="details__wrapper">

        <!--COMMON INFO-->
        <div class="details__info">
          <h5 class="details__wrapper-title">{{ currentPlan.title }} {{ t('subscription') }}</h5>
          <p class="details__bold">{{ currentPlan.description }}</p>
          <p class="details__bold">Billed {{ paymentAmount.type }}</p>
          <p class="details__paragraph">Period: {{date}} (Duration {{ paymentAmount.durationInDays }} days)</p>
        </div>

        <!--DAYS-->
        <div class="details__box">
          <p class="details__paragraph">{{t('days-to-pay')}}</p>
          <p class="details__paragraph">{{ paymentAmount.durationInDays }}</p>
        </div>

        <div class="details__box">
          <p class="details__paragraph">{{t('estimated-price')}}</p>
          <p class="details__paragraph">{{ paymentAmount.priceForTheDay}} €</p>
        </div>

        <!--PRICE-->
        <div class="details__price">
          <p class="details__bold details__small">{{t('total-price')}}</p>
          <p class="details__bold details__small">{{ paymentAmount.paymentAmount}} €</p>
        </div>

      </div>

      <!--SUBMIT-->
      <app-button typeButton="submit" [isLoading]="isLoading" [label]="t('btn-proceed')" [disabled]="!isValid" [widthFull]="true" customClasses="padding-12" />
      <p class="details__warning">{{t('warning')}}</p>
    </div>
  </form>
</div>
