import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-integrations-modal',
  templateUrl: './chat-integrations.component.html',
  styleUrls: ['./chat-integrations.component.scss'],
})
export class ChatIntegrationsModalComponent {
  public chatId: string;
  public spaceId: string;
  public chatName: string;
  public platform: string;

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}
