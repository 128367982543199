import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DocumentsStateModel } from '../models/DocumentsState';
import {
  DocumentsGet,
  DocumentsFoldersGet,
  DocumentsFolderCreate,
  DocumentsFolderUpdate,
  DocumentsSort,
  DocumentsLinkAdd,
  DocumentsLinkDelete,
  DocumentsUpdateLink,
  DocumentsGetPermissions,
  DocumentsSearchSet,
  DocumentsSearchClear,
  DocumentsAddDocument,
  DocumentsUpdateDocument,
  DocumentsDeleteDocument,
  DocumentGetUrls,
  DocumentDownloadBase64,
  DocumentGetBase64,
} from '../actions/documents.action';
import { FilesService } from '../../../api/services/files.service';
import {
  DocumentsDelete,
  DocumentsFolderDelete,
  DocumentUpdateFile,
  DocumentSaveInDataroom,
} from '../actions/documents.action';
import { FilesDbDto, FoldersDbDto, LinksDbDto } from '../../../api/models';
import { DocumentsService } from '../../services/documents.service';
import { sortKey } from '../../data/sort-types';
import { FullImagePreviewService } from '../../services/full-image-preview.service';

@State<DocumentsStateModel>({
  name: 'Documents',
  defaults: {
    object: null,
    objectId: null,
    breadcrumbs: [],
    documents: [],
    documentsUrls: [],
    documentsSearch: [],
    foldersSearch: [],
    documentBase64: null,
    permissions: null,
    foldersTree: new Map(),
    lastCreatedFolderId: '',
    currentFolder: null,
    isLoadedFolders: false,
    isLoaded: false,
    sort: null,
  },
})
@Injectable()
export class DocumentsState {
  /**
   * get documents
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getDocuments(state: DocumentsStateModel) {
    return state.documents;
  }

  /**
   * get folders
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getFoldersTree(state: DocumentsStateModel) {
    return state.foldersTree;
  }

  /**
   * get current folder
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getCurrentFolder(state: DocumentsStateModel) {
    return state.currentFolder;
  }

  /**
   * get breadcrumbs
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getBreadcrumbs(state: DocumentsStateModel) {
    return state.breadcrumbs;
  }

  /**
   * get search document
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getSearchDocuments(state: DocumentsStateModel) {
    return state.documentsSearch;
  }

  /**
   * get document urls
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getDocumentUrls(state: DocumentsStateModel) {
    return state.documentsUrls;
  }

  /**
   * get id of last created item
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getLastCreatedFolderId(state: DocumentsStateModel) {
    return state.lastCreatedFolderId;
  }

  /**
   * get documents is loaded
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getDocumentsIsLoaded(state: DocumentsStateModel) {
    return state.isLoaded;
  }

  /**
   * get folders is loaded
   * @param  {DocumentsStateModel} state
   */
  @Selector()
  static getFoldersIsLoaded(state: DocumentsStateModel) {
    return state.isLoadedFolders;
  }

  constructor(
    private filesService: FilesService,
    private documentsService: DocumentsService,
    private storage: LocalStorageService,
    private fullImagePreviewService: FullImagePreviewService,
  ) {}

  /**
   * Get folders list action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsFoldersGet} action
   */
  @Action(DocumentsFoldersGet)
  documents_folders_get({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsFoldersGet) {
    patchState({ isLoadedFolders: false });

    return this.filesService.filesGetFolder_1(action.payload).pipe(
      tap(
        (result) => {
          const foldersTree = new Map();

          for (const item of result) {
            const folders = foldersTree?.get(item.parentId) || [];
            foldersTree.set(item.parentId, [...folders, item]);
          }

          const rootFolder = foldersTree.get(null).pop();
          const rootFolders = foldersTree.get(rootFolder._id);
          foldersTree.set('root', rootFolders);
          foldersTree.delete(null);
          foldersTree.delete(rootFolder._id);

          patchState({ foldersTree, isLoadedFolders: true, foldersSearch: result });
        },
        (err) => {
          patchState({ isLoadedFolders: false });
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get folder action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsGet} action
   */
  @Action(DocumentsGet)
  documents_get({ patchState, getState }: StateContext<DocumentsStateModel>, action: DocumentsGet) {
    patchState({ isLoaded: false });

    return this.filesService.filesGetFolder(action.payload).pipe(
      tap(
        (result) => {
          const currentSortParams = getState().sort;
          const documents = this.documentsService.sort(
            result['folderChildren'] as [],
            currentSortParams.sortBy,
            currentSortParams.direction,
          );
          patchState({
            object: action.payload.object,
            objectId: action.payload.objectId,
            documents,
            currentFolder: result['folder'],
            breadcrumbs: result['folderBreadcrumbs'].map((item) => ({ ...item, parentId: item.parentId || 'Home' })),
            isLoaded: true,
          });
        },
        (err) => {
          patchState({ isLoaded: true });
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get document permissions action handler
   * @param  {patchState, getState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsGetPermissions} action
   */
  @Action(DocumentsGetPermissions)
  document_get_permissions(
    { patchState, getState }: StateContext<DocumentsStateModel>,
    action: DocumentsGetPermissions,
  ) {
    const state = getState();
    return this.filesService.documentPermissionsGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            ...state,
            permissions: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create folder action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsFolderCreate} action
   */
  @Action(DocumentsFolderCreate)
  documents_folder_create({ patchState, dispatch }: StateContext<DocumentsStateModel>, action: DocumentsFolderCreate) {
    return this.filesService.filesCreateFolder(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            lastCreatedFolderId: result['_id'],
          });

          dispatch(new DocumentsFoldersGet(action.payload.body));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update folder action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsFolderUpdate} action
   */
  @Action(DocumentsFolderUpdate)
  documents_folder_update(
    {}: StateContext<DocumentsStateModel>,
    action: DocumentsFolderUpdate,
  ): Observable<FoldersDbDto> {
    return this.filesService.filesUpdateFolder(action.payload);
  }

  /**
   * File Delete events action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsDelete} action
   */
  @Action(DocumentsDelete)
  file_delete({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsDelete) {
    return this.filesService.filesDelete(action.payload).pipe(
      tap(
        () => {
          patchState({});
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Folder Delete events action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsFolderDelete} action
   */
  @Action(DocumentsFolderDelete)
  folder_delete({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsFolderDelete) {
    return this.filesService.filesDeleteFolder(action.payload).pipe(
      tap(
        () => {
          patchState({});
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get events action handler
   * @param  {patchState}: StateContext<DocumentsStateModel>
   * @param  {DocumentsGet} action
   */
  @Action(DocumentSaveInDataroom)
  documents_save_in_dataroom({ patchState }: StateContext<DocumentsStateModel>, action: DocumentSaveInDataroom) {
    return this.filesService.filesSaveFileToDataRoom(action.payload).pipe(
      tap(
        (result) => {
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update file
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentUpdateFile} action
   */
  @Action(DocumentUpdateFile)
  documents_update_file(state: StateContext<DocumentsStateModel>, action: DocumentUpdateFile): Observable<FilesDbDto> {
    return this.filesService.filesUpdate(action.payload);
  }

  /**
   * Sort documents
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsSort} action
   */
  @Action(DocumentsSort)
  documents_sort(state: StateContext<DocumentsStateModel>, { payload: { direction, sortBy } }: DocumentsSort): void {
    state.patchState({
      documents: this.documentsService.sort(state.getState().documents, sortBy, direction),
      sort: { direction, sortBy },
    });
    this.storage.set(sortKey, { direction, sortBy });
  }

  /**
   * Add link
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsLinkAdd} action
   */
  @Action(DocumentsLinkAdd)
  documents_link_add(state: StateContext<DocumentsStateModel>, action: DocumentsLinkAdd) {
    return this.filesService.filesCreateLink(action.payload);
  }

  /**
   * Link delete event action handler
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsLinkDelete} action
   */
  @Action(DocumentsLinkDelete)
  link_delete(state: StateContext<DocumentsStateModel>, action: DocumentsLinkDelete) {
    return this.filesService.filesDeleteLink(action.payload);
  }

  /**
   * Update link
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsUpdateLink} action
   */
  @Action(DocumentsUpdateLink)
  documents_update_link(state: StateContext<DocumentsStateModel>, action: DocumentsUpdateLink): Observable<LinksDbDto> {
    return this.filesService.filesUpdateLink(action.payload);
  }

  /**
   * Set folders
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsSearchClear} action
   */
  @Action(DocumentsSearchClear)
  documents_set_folders_search({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsSearchClear) {
    patchState({ documentsSearch: [] });
  }

  /**
   * Search set
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsSearchSet} action
   */
  @Action(DocumentsSearchSet)
  documents_search_set({ patchState, getState }: StateContext<DocumentsStateModel>, action: DocumentsSearchSet) {
    return this.filesService.filesSearch(action.payload).pipe(
      tap(
        (result) => {
          const currentSortParams = getState().sort;
          const defaultFolder = getState().foldersSearch;

          const folders = defaultFolder.length ? defaultFolder.slice(1) : defaultFolder;
          const filteredFolders = folders.filter((document) =>
            document.name.toLowerCase().includes(action.payload.search.toLowerCase()),
          );

          const propertyNames = ['chatsFiles', 'files', 'threadsFiles', 'ticketsFiles', 'documents', 'links'];
          const files = propertyNames.reduce((acc, propertyName) => {
            const results = result[propertyName]?.results || [];
            return [...acc, ...results];
          }, []);

          const combineDocuments = [...filteredFolders, ...files];

          const documents = this.documentsService.sort(
            combineDocuments,
            currentSortParams.sortBy,
            currentSortParams.direction,
          );
          patchState({ documentsSearch: documents });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Add document
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsAddDocument} action
   */
  @Action(DocumentsAddDocument)
  documents_add_document({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsAddDocument) {
    return this.filesService.filesCreateDocument(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update document
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsUpdateDocument} action
   */
  @Action(DocumentsUpdateDocument)
  documents_update_document({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsUpdateDocument) {
    return this.filesService.filesUpdateDocument(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * delete document
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentsDeleteDocument} action
   */
  @Action(DocumentsDeleteDocument)
  documents_delete_document({ patchState }: StateContext<DocumentsStateModel>, action: DocumentsDeleteDocument) {
    return this.filesService.filesDeleteDocument(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * get document urls
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentGetUrls} action
   */
  @Action(DocumentGetUrls)
  documents_get_urls({ patchState }: StateContext<DocumentsStateModel>, action: DocumentGetUrls) {
    return this.filesService.filesGetUrLs(action.payload).pipe(
      tap(
        (res) => {
          patchState({ documentsUrls: res });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * download document in base64
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentDownloadBase64} action
   */
  @Action(DocumentDownloadBase64)
  documents_download_base64({ patchState }: StateContext<DocumentsStateModel>, action: DocumentDownloadBase64) {
    return this.filesService.filesGetBase64(action.payload).pipe(
      tap(
        (res) => {
          this.fullImagePreviewService.setDownloadedBase64File(res);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * get document in base64
   * @param  {state}: StateContext<DocumentsStateModel>
   * @param  {DocumentGetBase64} action
   */
  @Action(DocumentGetBase64)
  documents_get_base64({ patchState }: StateContext<DocumentsStateModel>, action: DocumentGetBase64) {
    return this.filesService.filesGetBase64(action.payload).pipe(
      tap(
        (res) => {
          patchState({ documentBase64: res.data });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
