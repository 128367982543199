<div class="webhook-list-wrapper">
  <div *ngFor="let webhook of webhooks" class="list-item" [ngClass]="{'list-item-dark': darkMode}" t-id="webhooks-list-item">
    <div class="d-flex flex-row">
      <app-webhook-avatar
        class="mr-2"
        [name]="webhook.webhookName"
        [chatId]="webhook.chatId"
        [webhookId]="webhook._id"
      ></app-webhook-avatar>

      <div class="d-flex flex-column">
        <h5 class="mb-0">{{webhook.webhookName}}</h5>
        <div>
          <span>{{webhook.userName}} </span>
          <span>{{webhook.created_at | date : 'dd.MM.yy'}}</span>
        </div>
      </div>
    </div>

    <button
      class="btn dropdown d-flex align-items-center menu-button"
      ngbDropdown
      placement="left"
      container="body"
      [autoClose]="true"
      *ngIf="'spaces::' + spaceId + '::webhookCreate' | checkPermission"
    >
      <app-svg
        name="ellipsis"
        width="20"
        height="5"
        [hoverEffect]="true"
        class="dropdown-toggle cursor-pointer"
        ngbDropdownToggle
        t-id="webhooks-list-menu-actions"
      ></app-svg>
      <div ngbDropdownMenu class="dropdown-menu">
        <a class="dropdown-item d-flex align-items-center" href="javascript:;" (click)="openEditModal(webhook)" t-id="webhooks-list-edit-webhook">
          <app-svg name="pen-to-square" width="14" height="14"></app-svg>
          <span class="ml-2">Edit</span>
        </a>
        <a class="dropdown-item d-flex align-items-center" href="javascript:;" (click)="delete(webhook)" t-id="webhooks-list-delete-webhook">
          <app-svg name="remove" width="14" height="14"></app-svg>
          <span class="ml-2">Remove</span>
        </a>
      </div>
    </button>
  </div>
</div>
