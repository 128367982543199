import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DraftsGetList } from '../../store/actions/board.action';
import { BoardsState } from '../../store/states/boards.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TicketsDraftsResDto } from '../../../api/models/tickets-drafts-res-dto';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss'],
})
export class DraftsComponent implements OnInit {
  drafts$: Observable<Array<TicketsDraftsResDto>> = new Observable();

  draftsVisible = true;
  isChat = false;

  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.isChat = this.router.url.includes('/chat/');
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isChat = event.url.includes('/chat/') || event.url.includes('/wiki/');
      }
    });
    this.store.dispatch(new DraftsGetList());
    this.initDraftsSubscriber();
  }

  private initDraftsSubscriber(): void {
    this.drafts$ = this.store.select(BoardsState.getDrafts).pipe(untilDestroyed(this));
  }
}
