import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContainerRefDirective } from '../../../shared/directives/container-ref.directive';
import { CustomFieldService } from './services/custom-field.service';
import { TicketsFieldsValuesReqDto } from '../../../api/models/tickets-fields-values-req-dto';
import { TicketsFieldsDbDto } from '../../../api/models/tickets-fields-db-dto';
import { SelectLabelsTagsComponent } from './components/SelectLabelsTags/select-labels-tags.component';
import { FieldType } from '../../custom-field/enums/fieldType';
import { SelectLinkRelationshipComponent } from './components/SelectLinkRelationship/select-link-relationship.component';
import { CustomFieldItem } from './classes/custom-fields';
import { TicketsDbDto } from '../../../api/models/tickets-db-dto';
import { TicketsLabelsDbDto } from '../../../api/models/tickets-labels-db-dto';
import { DateTimeComponent } from './components/DateTime/date-time.component';
import { parseISO } from 'date-fns';
import { ITicketForm, TicketData } from '../board-ticket';
import { TicketService } from '../../../shared/services/ticket.service';
import { TicketsFieldsGetListResDto } from '../../../api/models/tickets-fields-get-list-res-dto';
import { map, takeUntil } from 'rxjs/operators';
import { UsersDbDto } from '../../../api/models/users-db-dto';
import { TranslocoService } from '@ngneat/transloco';
import { DefaultFields } from '../fields.types';

export interface TabIndex {
  tabIndex?: string;
  type?: string;
  order?: number;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-custom-fields',
  templateUrl: 'custom-fields.component.html',
  styleUrls: ['custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit, OnChanges {
  @Input() modalData: TicketData;
  @Input() isInternalState = false;
  @Input() isMinimized = false;
  @Input() isDraft = false;
  @Input() isMinimized$: Subject<void> = new Subject<void>();
  @Input() customFieldsValues: Array<TicketsFieldsValuesReqDto> = [];
  @Input() ticketFormData: ITicketForm;
  @Input() ticketSelectParent: BehaviorSubject<TicketsDbDto[]>;
  @Input() datePickedWithoutTime: (event: boolean) => void;
  @Input() timeIsEmpty: boolean;
  @Input() childrenList: any[] = [];
  @Input() selectedLabels;
  @Input() parentIsConflict = new BehaviorSubject<{ message: string; id: string }>(null);
  @Input() selectedLabelsForDraft$?: Observable<string[]>;
  @Output() onChangeCustomFieldData = new EventEmitter<{ [x: string]: any }>();
  @Output() detectValidForm = new EventEmitter<string[]>();
  addLabelPromise: (label: any) => Promise<any>;
  labels: Array<TicketsLabelsDbDto> = [];
  fields: Array<TicketsFieldsDbDto> = [];
  customFieldsForm: FormGroup = this.fb.group({});
  private curTabIndex = 0;
  users$: Observable<any[]>;
  users: UsersDbDto[] = [];
  remappedField = [];
  focusedControl: string;
  tabIndexes: Array<TabIndex>;
  @ViewChild(ContainerRefDirective, { static: true }) customFieldsContainerRef: ContainerRefDirective;
  isCustomFieldsFormSubscribed = false;

  constructor(
    private store: Store,
    private customFieldService: CustomFieldService,
    private fb: FormBuilder,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private ticketService: TicketService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.initSubscribersForMultiplyObjects();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticketFormData?.previousValue) {
      const titleChangedTicketFormValue = this.isChanged(
        changes.ticketFormData.previousValue,
        changes.ticketFormData.currentValue,
        Object.keys(changes.ticketFormData.currentValue),
      );

      if (
        !titleChangedTicketFormValue ||
        Object.keys(this.customFieldsForm.value).includes(titleChangedTicketFormValue)
      ) {
        this.compareFieldsWithTicketData(this.fields);
      }
    }
  }

  isChanged(left: Record<string, any>, right: Record<string, any>, params: string[]): string | undefined {
    for (const param of params) {
      if (left[param] === undefined || right[param] === undefined) {
        continue;
      }

      if (left[param] !== right[param]) {
        return param;
      }
    }
    return;
  }
  subscribeControlValueChanges() {
    if (!this.isCustomFieldsFormSubscribed) {
      this.customFieldsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
        this.isCustomFieldsFormSubscribed = true;
        this.detectValidForm.emit(this.findInvalidControls());
        this.onChangeCustomFieldData.emit(data);
      });
    }
  }

  findInvalidControls(): string[] {
    const invalid = [];
    const controls = this.customFieldsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  initFormControls(fields: Array<TicketsFieldsDbDto>) {
    const formGroup = this.fb.group({});
    for (const field of fields) {
      this.prepareControl(formGroup, field);
    }

    this.customFieldsForm = formGroup;
    this.subscribeControlValueChanges();
  }

  initFieldComponents(fieldItems: CustomFieldItem[]) {
    const viewContainerRef = this.customFieldsContainerRef.viewContainerRef;
    viewContainerRef.clear();

    fieldItems.forEach((field) => {
      const factory = this.resolver.resolveComponentFactory(field.component);
      const componentRef = viewContainerRef.createComponent(factory, field.fieldConfig.order);
      const ComponentInstance = componentRef.instance;

      ComponentInstance.form = this.customFieldsForm;
      ComponentInstance.fieldConfig = field.fieldConfig;
      ComponentInstance.displayName = this.translationDefaultTitle(field.fieldConfig);
      ComponentInstance.customFieldValues = field.customFieldValues;
      ComponentInstance.tabIndexes = this.tabIndexes;

      if (!(ComponentInstance instanceof DateTimeComponent)) {
        ComponentInstance.setFocusedControl = this.setFocusedControl;
        ComponentInstance.inputFocused = this.inputFocused;
      } else {
        ComponentInstance.datePickedWithoutTime = this.datePickedWithoutTime;
        ComponentInstance.timeIsEmpty = this.timeIsEmpty;
      }

      if (ComponentInstance instanceof SelectLinkRelationshipComponent) {
        if (field.fieldConfig.title === 'Assignees') {
          ComponentInstance.modalData = this.modalData;
          ComponentInstance.users$ = this.users$;
          ComponentInstance.childrenList = this.childrenList;
        }

        if (field.fieldConfig.title === 'Parent') {
          ComponentInstance.ticketSelectParent = this.ticketSelectParent;
          ComponentInstance.modalData = this.modalData;
          ComponentInstance.childrenList = this.childrenList;
          ComponentInstance.isForMultiplyObjects = this.modalData.isNeedToSelectObject;
          ComponentInstance.parentIsConflict = this.parentIsConflict;
        }
      }

      if (ComponentInstance instanceof SelectLabelsTagsComponent) {
        if (field.fieldConfig.title === 'Labels') {
          ComponentInstance.object = this.modalData.object;
          ComponentInstance.objectId = this.modalData.objectId;
          ComponentInstance.modalData = this.modalData;
          ComponentInstance.labels = this.labels;
          ComponentInstance.isForMultiplyObjects = this.modalData.isNeedToSelectObject;
          ComponentInstance.isMinimized = this.isMinimized;
          ComponentInstance.isDraft = !!this.modalData.draft;
          ComponentInstance.selectedLabelsForDraft$ = this.selectedLabelsForDraft$;
        }
      }
    });
  }

  initTabIndexes(fields: Array<TicketsFieldsDbDto>): Array<TabIndex> {
    return fields
      .map((field) => ({
        tabIndex: `tab-index-${field.type}-${field.order}`,
        type: field.type,
        order: field.order,
      }))
      .sort((tabA, tabB) => tabA.order - tabB.order);
  }

  compareFieldsWithTicketData(fields: Array<TicketsFieldsDbDto>) {
    fields.forEach((field) => {
      const customField = this.customFieldsForm.get(field.title);
      if (customField && field.isVisible) {
        customField.patchValue(this.getTicketControlValuesByFieldTitle(field));
      }
    });
  }

  instanceOfTicketsFieldsDbDto(object): object is TicketsFieldsDbDto {
    return !!object?._id;
  }

  translationDefaultTitle(field): string {
    if (field.isLocked) {
      switch (field.title) {
        case DefaultFields.Assignees:
          return this.translocoService.translate('modals.board-ticket.default.assignees');
        case DefaultFields.DueDate:
          return this.translocoService.translate('modals.board-ticket.default.due-date');
        case DefaultFields.Labels:
          return this.translocoService.translate('modals.board-ticket.default.labels');
        case DefaultFields.Epics:
          return this.translocoService.translate('modals.board-ticket.default.epics');
        case DefaultFields.Estimation:
          return this.translocoService.translate('modals.board-ticket.default.estimation');
        case DefaultFields.Release:
          return this.translocoService.translate('modals.board-ticket.default.release-version');
        case DefaultFields.Parent:
          return this.translocoService.translate('modals.board-ticket.default.parent');
        default:
          return field.title;
      }
    }

    return field.title;
  }

  checkIfExistingValueInFormIsValid(title: string, value?: string | number | Array<string> | TicketsFieldsDbDto) {
    const fieldsFormValue = this.customFieldsForm.get(title).value;

    if (this.instanceOfTicketsFieldsDbDto(value)) {
      const customFieldValue = this.ticketFormData.customFields.find((item) => item.ticketFieldId === value._id);

      if (customFieldValue) {
        if (value.type === FieldType.Unit) {
          return {
            [value.title]:
              customFieldValue.value && fieldsFormValue[title] !== customFieldValue.value
                ? customFieldValue.value
                : fieldsFormValue[title],
            unit:
              customFieldValue.unit && fieldsFormValue.unit !== customFieldValue.unit
                ? customFieldValue.unit
                : fieldsFormValue.unit,
          };
        } else if (value.type === FieldType.MultiSelect) {
          return JSON.parse(customFieldValue.value) || [];
        } else if (value.type === FieldType.DateTimePicker || value.type === FieldType.DatePicker) {
          return fieldsFormValue && fieldsFormValue !== customFieldValue.value
            ? fieldsFormValue instanceof Date
              ? fieldsFormValue
              : fieldsFormValue
              ? parseISO(fieldsFormValue)
              : null
            : parseISO(customFieldValue.value);
        } else {
          return fieldsFormValue && fieldsFormValue !== customFieldValue.value
            ? fieldsFormValue
            : customFieldValue.value;
        }
      } else {
        if (fieldsFormValue) {
          return fieldsFormValue;
        }
      }
    } else {
      if (value instanceof Array) {
        return fieldsFormValue?.length && fieldsFormValue !== value ? fieldsFormValue : value;
      }
      return fieldsFormValue && fieldsFormValue !== value ? fieldsFormValue : value;
    }
  }

  getTicketControlValuesByFieldTitle(field: TicketsFieldsDbDto) {
    switch (field.title) {
      case 'Assignees': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.ticketMembers);
      }
      case 'Due date': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.dueDate);
      }
      case 'Labels': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.labels);
      }
      case 'Estimation': {
        const estimation = this.customFieldsForm.get(field.title);
        return {
          [field.title]:
            this.ticketFormData.estimateValue && this.ticketFormData.estimateValue !== estimation.value[field.title]
              ? this.ticketFormData.estimateValue
              : estimation.value[field.title],
          unit:
            this.ticketFormData.estimateUnit && this.ticketFormData.estimateUnit !== estimation.value['unit']
              ? this.ticketFormData.estimateUnit
              : estimation.value['unit'],
        };
      }
      case 'Epics': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.epicId);
      }
      case 'Release version': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.releaseVersion);
      }
      case 'Parent': {
        return this.checkIfExistingValueInFormIsValid(field.title, this.ticketFormData.parentId);
      }
      default: {
        if (this.ticketFormData.customFields?.length) {
          const fieldValue = this.checkIfExistingValueInFormIsValid(field.title, field);
          if (fieldValue) {
            return fieldValue;
          } else {
            if (field.type === FieldType.Unit) {
              return { [field.title]: null, unit: null };
            } else {
              return null;
            }
          }
        } else {
          if (field.type === FieldType.Unit) {
            return { [field.title]: null, unit: null };
          } else {
            return null;
          }
        }
      }
    }
  }

  setFocusedControl(dataTabindex: string) {
    this.focusedControl = dataTabindex;
    this.tabIndexes.find((element, index) => {
      if (element.tabIndex === dataTabindex) {
        this.curTabIndex = index;
        return element.tabIndex === dataTabindex;
      }
    });
  }

  inputFocused(): void {
    // setTimeout(() => {
    //   document.getElementById(`${elemId}`)?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    // }, 1000);
  }

  initSubscribersForMultiplyObjects() {
    this.users$ = this.ticketService.users$.pipe(
      untilDestroyed(this),
      takeUntil(this.isMinimized$),
      map((users) => users?.filter((user: any) => user.userName !== 'Quinn')),
    );
    this.users$.pipe(untilDestroyed(this), takeUntil(this.isMinimized$)).subscribe((users) => {
      const filteredAssignees = this.customFieldsForm.controls['Assignees']?.value?.filter((userId) =>
        users.some((user: { _id: string }) => user._id === userId),
      );
      this.customFieldsForm.controls['Assignees']?.setValue(filteredAssignees);
    });
    this.ticketService.fields$.pipe(untilDestroyed(this), takeUntil(this.isMinimized$)).subscribe((fields) => {
      this.prepareFields(fields, true);
    });

    this.ticketService.labels$.pipe(untilDestroyed(this), takeUntil(this.isMinimized$)).subscribe((labels) => {
      this.labels = [...labels];
      const filteredLabels = this.customFieldsForm.controls['Labels']?.value?.filter((labelId) =>
        labels.some((label: { _id: string }) => label._id === labelId),
      );
      this.customFieldsForm.controls['Labels']?.setValue(filteredLabels);
    });
  }

  prepareFields(fields: Partial<TicketsFieldsGetListResDto>, isNeedToUpdateControls = false) {
    if (fields?.fields) {
      let preparedFields;
      if (this.modalData.isNeedToSelectObject) {
        preparedFields = fields.fields
          .filter((field) => field.title !== 'Parent' && field.title !== 'Epics')
          .map((field, index) => ({ ...field, order: index }));
      }

      const filteredFields = preparedFields ? preparedFields : fields.fields;
      this.fields = filteredFields;
      this.tabIndexes = this.initTabIndexes(filteredFields);
      if (!Object.keys(this.customFieldsForm.controls).length) {
        this.initFormControls(filteredFields);
      } else if (isNeedToUpdateControls) {
        this.updateFormControls(filteredFields);
      }
      this.remappedField = this.customFieldService.remapFieldsToFieldComponents(
        filteredFields,
        this.customFieldsValues,
      );

      this.initFieldComponents(this.remappedField);
    }
  }

  updateFormControls(fields: Array<TicketsFieldsDbDto>) {
    const controlsValues = this.customFieldsForm.value;

    fields.forEach((field) => {
      delete controlsValues[field.title];
      if (field.isLocked) {
        return;
      }

      this.prepareControl(this.customFieldsForm, field);
    });

    this.removeControls(Object.keys(controlsValues), this.customFieldsForm);
  }

  prepareControl(formGroup: FormGroup, field: TicketsFieldsDbDto) {
    const validatorsToAdd = [];
    if (field.isMandatory && field.isVisible) {
      validatorsToAdd.push(Validators.required);
    }

    if (field.type === FieldType.Unit) {
      formGroup.addControl(
        field.title,
        this.fb.group({
          [field.title]: [null, validatorsToAdd],
          unit: [null, validatorsToAdd],
        }),
      );
    } else {
      formGroup.addControl(field.title, this.fb.control(null, validatorsToAdd));
    }
  }

  removeControls(titles: string[], formGroup: FormGroup) {
    titles.forEach((title) => {
      formGroup.removeControl(title);
    });
  }
}
