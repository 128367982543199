<div class="poll" [class.poll-dark]="config?.layout.variant === 'Dark'" *transloco="let t; read: 'chat'">
  <div class="poll__wrapper">
    <div class="poll__title">
      <app-svg name="poll" width="14" height="14" aria-hidden="true"></app-svg>
      <b>{{title}}</b>
    </div>

    <div class="poll__date" *ngIf="isDueDate">
      <app-svg name="clock-poll" width="16" height="16" aria-hidden="true"></app-svg>
      <span>{{ t('poll-ends') }} {{ dueDate }}</span>
    </div>

    <div class="poll__count">
      <p>{{userVoteLength}}/{{userLength}} {{ t('poll-answered') }}</p>
    </div>

    <div class="poll__unVote" *ngIf="isFirstVoteUser && afterVoteShow">
      <fieldset *ngIf="!isMultiChoice; else multiChoice" [id]="messagePollId" class="poll__unVote-wrapper">
        <div class="poll__unVote-item" *ngFor="let option of options; let i = index">
          <input
            type="radio"
            [name]="messagePollId"
            [id]="messagePollId + '-' + i"
            [value]="i"
            [formControl]="chosenAnswer"
          >
          <label [for]="messagePollId + '-' + i"></label>
          <span>{{option}}</span>
        </div>
      </fieldset>
      <ng-template #multiChoice>
        <div class="poll__unVote-wrapper">
          <div class="poll__unVote-item" *ngFor="let option of options; let i = index">
            <input
              type="checkbox"
              [id]="messagePollId + '-' + i"
              [value]="option"
              (click)="choseOption(option, i)"
            >
            <label [for]="messagePollId + '-' + i"></label>
            <span>{{option}}</span>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="poll__unVote poll__unVoteWrapper" *ngIf="isFirstVoteUser && !afterVoteShow">
        <fieldset *ngIf="!isMultiChoice; else multiVoteChoice" [id]="messagePollId" class="poll__unVote-wrapper">
            <div class="poll__unVote-items" *ngFor="let option of options; let i = index">
              <div class="poll__unVote-item">
                <input
                  type="radio"
                  [name]="messagePollId"
                  [id]="messagePollId + '-' + i"
                  [value]="i"
                  [formControl]="chosenAnswer"
                >
                <label [for]="messagePollId + '-' + i"></label>
              </div>
              <div class="poll__vote-wrapper">
                <div class="poll__vote-info">
                  <b>{{getPercentageOption(i)}}%</b>
                  <span>{{option}}</span>
                </div>
                <div class="poll__vote-progress" [class.poll__vote-progress-unvote]="!isVoteUser(i)">
                  <app-svg *ngIf="isVoteUser(i)" name="check-circle" width="16" height="16" fill="#6D43D3" aria-hidden="true" class="poll__vote-progress-circle"></app-svg>
                  <app-progress-bar class="w-100" [progress]="getPercentageOption(i)" [isPoll]="true"></app-progress-bar>
                </div>
                <div *ngIf="!isAnonymous" class="poll__vote-avatar">
                  <ng-container *ngFor="let user of getVoteUsers(i); let index = index">
                    <ng-container *ngIf="index < 3">
                      <app-avatar
                        [userId]="user.userId"
                        [width]="24"
                        [height]="24"
                        [showStatus]="false"
                      ></app-avatar>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </fieldset>
        <ng-template #multiVoteChoice>
            <div class="poll__unVote-wrapper">
              <div class="poll__unVote-items" *ngFor="let option of options; let i = index">
                <div class="poll__unVote-item">
                  <input
                    type="checkbox"
                    [id]="messagePollId + '-' + i"
                    [value]="option"
                    (click)="choseOption(option, i)"
                  >
                  <label [for]="messagePollId + '-' + i"></label>
                </div>
                <div class="poll__vote-wrapper">
                <div class="poll__vote-info">
                  <b>{{getPercentageOption(i)}}%</b>
                  <span>{{option}}</span>
                </div>
                <div class="poll__vote-progress" [class.poll__vote-progress-unvote]="!isVoteUser(i)">
                  <app-svg *ngIf="isVoteUser(i)" name="check-circle" width="16" height="16" fill="#6D43D3" aria-hidden="true" class="poll__vote-progress-circle"></app-svg>
                  <app-progress-bar class="w-100" [progress]="getPercentageOption(i)" [isPoll]="true"></app-progress-bar>
                </div>
                <div *ngIf="!isAnonymous" class="poll__vote-avatar">
                  <ng-container *ngFor="let user of getVoteUsers(i); let index = index">
                    <ng-container *ngIf="index < 3">
                      <app-avatar
                        [userId]="user.userId"
                        [width]="24"
                        [height]="24"
                        [showStatus]="false"
                      ></app-avatar>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              </div>
            </div>
          </ng-template>
      </div>

    <div class="poll__vote" *ngIf="!isFirstVoteUser">
      <div *ngFor="let option of options; let i = index" class="poll__vote-wrapper">
        <div class="poll__vote-info">
            <b>{{getPercentageOption(i)}}%</b>
            <span>{{option}}</span>
          </div>
        <div class="poll__vote-progress" [class.poll__vote-progress-unvote]="!isVoteUser(i)">
            <app-svg *ngIf="isVoteUser(i)" name="check-circle" width="16" height="16" fill="#6D43D3" aria-hidden="true" class="poll__vote-progress-circle"></app-svg>
            <app-progress-bar class="w-100" [progress]="getPercentageOption(i)" [isPoll]="true"></app-progress-bar>
          </div>
        <div *ngIf="!isAnonymous" class="poll__vote-avatar">
            <ng-container *ngFor="let user of getVoteUsers(i); let index = index">
              <ng-container *ngIf="index < 3">
                <app-avatar
                  [userId]="user.userId"
                  [width]="24"
                  [height]="24"
                  [showStatus]="false"
                ></app-avatar>
              </ng-container>
            </ng-container>
          </div>
      </div>
    </div>
  </div>
  <div class="poll__buttons">
    <button *ngIf="isFirstVoteUser" [disabled]="!this.chosenAnswer.valid && !this.chosenCoupleAnswer.valid" class="btn btn-solid btn-save" type="submit" (click)="vote()">
      {{ t('btn-poll-vote') }}
    </button>
    <div class="poll__buttons-vote">
      <button *ngIf="!isFirstVoteUser && !isAnonymous" class="btn btn-subtle" type="button" (click)="showResult()">
        {{ t('btn-poll-see-results') }}
      </button>
      <button *ngIf="!isCompleted && !isFirstVoteUser" class="btn btn-subtle" type="button" (click)="cancelVote()">
        {{ t('btn-poll-cancel-vote') }}
      </button>
      <button [disabled]="isLoading" *ngIf="isCreator && !isCompleted && !isFirstVoteUser" class="btn btn-subtle" type="button" (click)="stopPoll()">
        <i *ngIf="isLoading " class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
        {{ isLoading  ? '' : t('btn-stop-poll') }}
      </button>
    </div>
  </div>
</div>
