/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChatDataRoomFileResDto } from '../models/chat-data-room-file-res-dto';
import { ChatFilesDbDto } from '../models/chat-files-db-dto';
import { ChatMediaFilePaginationResDto } from '../models/chat-media-file-pagination-res-dto';
import { ChatsCreateReqDto } from '../models/chats-create-req-dto';
import { ChatsDbDto } from '../models/chats-db-dto';
import { ChatsGetChatsResDto } from '../models/chats-get-chats-res-dto';
import { ChatsMembersCreateWithUserListReqDto } from '../models/chats-members-create-with-user-list-req-dto';
import { ChatsMembersDbDto } from '../models/chats-members-db-dto';
import { ChatsMembersGetUsersByChatIdResDto } from '../models/chats-members-get-users-by-chat-id-res-dto';
import { ChatsMessageCreateReqDto } from '../models/chats-message-create-req-dto';
import { CommonDeleteResDto } from '../models/common-delete-res-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { GroupChatsOrderUpdateReqDto } from '../models/group-chats-order-update-req-dto';
import { MessagesDbDto } from '../models/messages-db-dto';
import { PinnedMessageUpsertReqDto } from '../models/pinned-message-upsert-req-dto';
import { PinnedMessagesDbDto } from '../models/pinned-messages-db-dto';
import { PinnedMessagesOrderUpdateReqDto } from '../models/pinned-messages-order-update-req-dto';
import { PollUpdateReqDto } from '../models/poll-update-req-dto';
import { ThreadMessagesCreateReqDto } from '../models/thread-messages-create-req-dto';

@Injectable({
  providedIn: 'root',
})
export class ChatsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation threadGetList
   */
  static readonly ThreadGetListPath = '/chats/get-thread-list';

  /**
   * get thread-list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `threadGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * chatId
     */
    chatId?: string;

    /**
     * onlyUnread
     */
    onlyUnread?: boolean;
  }): Observable<StrictHttpResponse<Array<MessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ThreadGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
      rb.query('chatId', params.chatId, {});
      rb.query('onlyUnread', params.onlyUnread, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessagesDbDto>>;
        }),
      );
  }

  /**
   * get thread-list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `threadGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * chatId
     */
    chatId?: string;

    /**
     * onlyUnread
     */
    onlyUnread?: boolean;
  }): Observable<Array<MessagesDbDto>> {
    return this.threadGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessagesDbDto>>) => r.body as Array<MessagesDbDto>),
    );
  }

  /**
   * Path part for operation threadGetCounters
   */
  static readonly ThreadGetCountersPath = '/chats/get-thread-counters';

  /**
   * get thread-counters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `threadGetCounters()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetCounters$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * chatId
     */
    chatId?: string;

    /**
     * onlyUnread
     */
    onlyUnread?: boolean;
  }): Observable<StrictHttpResponse<Array<MessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ThreadGetCountersPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('chatId', params.chatId, {});
      rb.query('onlyUnread', params.onlyUnread, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessagesDbDto>>;
        }),
      );
  }

  /**
   * get thread-counters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `threadGetCounters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetCounters(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * chatId
     */
    chatId?: string;

    /**
     * onlyUnread
     */
    onlyUnread?: boolean;
  }): Observable<Array<MessagesDbDto>> {
    return this.threadGetCounters$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessagesDbDto>>) => r.body as Array<MessagesDbDto>),
    );
  }

  /**
   * Path part for operation chatMemberGetByChatId
   */
  static readonly ChatMemberGetByChatIdPath = '/chats/get-chat-members/{id}';

  /**
   * get chat-members
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatMemberGetByChatId()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberGetByChatId$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<Array<ChatsMembersGetUsersByChatIdResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatMemberGetByChatIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ChatsMembersGetUsersByChatIdResDto>>;
        }),
      );
  }

  /**
   * get chat-members
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatMemberGetByChatId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberGetByChatId(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<Array<ChatsMembersGetUsersByChatIdResDto>> {
    return this.chatMemberGetByChatId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<ChatsMembersGetUsersByChatIdResDto>>) =>
          r.body as Array<ChatsMembersGetUsersByChatIdResDto>,
      ),
    );
  }

  /**
   * Path part for operation chatMemberGetChatsByUserId
   */
  static readonly ChatMemberGetChatsByUserIdPath = '/chats/get-chats-by-user';

  /**
   * get user chats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatMemberGetChatsByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberGetChatsByUserId$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<Array<ChatsGetChatsResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatMemberGetChatsByUserIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ChatsGetChatsResDto>>;
        }),
      );
  }

  /**
   * get user chats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatMemberGetChatsByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberGetChatsByUserId(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
  }): Observable<Array<ChatsGetChatsResDto>> {
    return this.chatMemberGetChatsByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatsGetChatsResDto>>) => r.body as Array<ChatsGetChatsResDto>),
    );
  }

  /**
   * Path part for operation chatsGetMessagesList
   */
  static readonly ChatsGetMessagesListPath = '/chats/get-chat-messages/{id}/pinned';

  /**
   * get chat-messages with pinned message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetMessagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetMessagesList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * messageId
     */
    messageId: string;

    /**
     * Limit resulting elements
     */
    perPage: number;

    /**
     * count of page returned after page wit pinned message
     */
    takeCountOfPageAfter: number;
  }): Observable<StrictHttpResponse<Array<MessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetMessagesListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('takeCountOfPageAfter', params.takeCountOfPageAfter, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessagesDbDto>>;
        }),
      );
  }

  /**
   * get chat-messages with pinned message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetMessagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetMessagesList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * messageId
     */
    messageId: string;

    /**
     * Limit resulting elements
     */
    perPage: number;

    /**
     * count of page returned after page wit pinned message
     */
    takeCountOfPageAfter: number;
  }): Observable<Array<MessagesDbDto>> {
    return this.chatsGetMessagesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessagesDbDto>>) => r.body as Array<MessagesDbDto>),
    );
  }

  /**
   * Path part for operation chatsGetChatsMessages
   */
  static readonly ChatsGetChatsMessagesPath = '/chats/get-chat-messages/{id}';

  /**
   * get chat-messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetChatsMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetChatsMessages$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<Array<MessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetChatsMessagesPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessagesDbDto>>;
        }),
      );
  }

  /**
   * get chat-messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetChatsMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetChatsMessages(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<Array<MessagesDbDto>> {
    return this.chatsGetChatsMessages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessagesDbDto>>) => r.body as Array<MessagesDbDto>),
    );
  }

  /**
   * Path part for operation threadGetChatsMessages
   */
  static readonly ThreadGetChatsMessagesPath = '/chats/get-thread-messages';

  /**
   * get thread-messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `threadGetChatsMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetChatsMessages$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
    threadId: string;
  }): Observable<StrictHttpResponse<Array<MessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ThreadGetChatsMessagesPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
      rb.query('threadId', params.threadId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessagesDbDto>>;
        }),
      );
  }

  /**
   * get thread-messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `threadGetChatsMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  threadGetChatsMessages(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
    threadId: string;
  }): Observable<Array<MessagesDbDto>> {
    return this.threadGetChatsMessages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessagesDbDto>>) => r.body as Array<MessagesDbDto>),
    );
  }

  /**
   * Path part for operation chatsGetPinnedMessagesList
   */
  static readonly ChatsGetPinnedMessagesListPath = '/chats/{id}/pinned-messages';

  /**
   * get chat pinned messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetPinnedMessagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetPinnedMessagesList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<Array<PinnedMessagesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetPinnedMessagesListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PinnedMessagesDbDto>>;
        }),
      );
  }

  /**
   * get chat pinned messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetPinnedMessagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetPinnedMessagesList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<Array<PinnedMessagesDbDto>> {
    return this.chatsGetPinnedMessagesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PinnedMessagesDbDto>>) => r.body as Array<PinnedMessagesDbDto>),
    );
  }

  /**
   * Path part for operation chatsCreatePinnedMessage
   */
  static readonly ChatsCreatePinnedMessagePath = '/chats/{id}/pinned-messages';

  /**
   * create chat pinned messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsCreatePinnedMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsCreatePinnedMessage$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PinnedMessageUpsertReqDto;
  }): Observable<StrictHttpResponse<PinnedMessageUpsertReqDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsCreatePinnedMessagePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PinnedMessageUpsertReqDto>;
        }),
      );
  }

  /**
   * create chat pinned messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsCreatePinnedMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsCreatePinnedMessage(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PinnedMessageUpsertReqDto;
  }): Observable<PinnedMessageUpsertReqDto> {
    return this.chatsCreatePinnedMessage$Response(params).pipe(
      map((r: StrictHttpResponse<PinnedMessageUpsertReqDto>) => r.body as PinnedMessageUpsertReqDto),
    );
  }

  /**
   * Path part for operation chatsControllerPinnedMessagesOrderUpdate
   */
  static readonly ChatsControllerPinnedMessagesOrderUpdatePath = '/chats/{id}/pinned-messages/order';

  /**
   * update chat pinned message order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsControllerPinnedMessagesOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsControllerPinnedMessagesOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PinnedMessagesOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsControllerPinnedMessagesOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * update chat pinned message order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsControllerPinnedMessagesOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsControllerPinnedMessagesOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PinnedMessagesOrderUpdateReqDto;
  }): Observable<void> {
    return this.chatsControllerPinnedMessagesOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation chatsDeletePinnedMessage
   */
  static readonly ChatsDeletePinnedMessagePath = '/chats/{id}/pinned-messages/{pinnedMessageId}';

  /**
   * update chat pinned messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsDeletePinnedMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsDeletePinnedMessage$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    pinnedMessageId: string;
    object: string;
    objectId: string;
  }): Observable<StrictHttpResponse<CommonDeleteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsDeletePinnedMessagePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('pinnedMessageId', params.pinnedMessageId, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonDeleteResDto>;
        }),
      );
  }

  /**
   * update chat pinned messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsDeletePinnedMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsDeletePinnedMessage(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    pinnedMessageId: string;
    object: string;
    objectId: string;
  }): Observable<CommonDeleteResDto> {
    return this.chatsDeletePinnedMessage$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDeleteResDto>) => r.body as CommonDeleteResDto),
    );
  }

  /**
   * Path part for operation chatsUpdatePinnedMessage
   */
  static readonly ChatsUpdatePinnedMessagePath = '/chats/{id}/pinned-messages/{pinnedMessageId}';

  /**
   * update chat pinned messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsUpdatePinnedMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsUpdatePinnedMessage$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    pinnedMessageId: string;
    body: PinnedMessageUpsertReqDto;
  }): Observable<StrictHttpResponse<PinnedMessageUpsertReqDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsUpdatePinnedMessagePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('pinnedMessageId', params.pinnedMessageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PinnedMessageUpsertReqDto>;
        }),
      );
  }

  /**
   * update chat pinned messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsUpdatePinnedMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsUpdatePinnedMessage(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    pinnedMessageId: string;
    body: PinnedMessageUpsertReqDto;
  }): Observable<PinnedMessageUpsertReqDto> {
    return this.chatsUpdatePinnedMessage$Response(params).pipe(
      map((r: StrictHttpResponse<PinnedMessageUpsertReqDto>) => r.body as PinnedMessageUpsertReqDto),
    );
  }

  /**
   * Path part for operation chatCreate
   */
  static readonly ChatCreatePath = '/chats/chat-create';

  /**
   * create custom chat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsCreateReqDto;
  }): Observable<StrictHttpResponse<ChatsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChatsDbDto>;
        }),
      );
  }

  /**
   * create custom chat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsCreateReqDto;
  }): Observable<ChatsDbDto> {
    return this.chatCreate$Response(params).pipe(map((r: StrictHttpResponse<ChatsDbDto>) => r.body as ChatsDbDto));
  }

  /**
   * Path part for operation chatMemberCreate
   */
  static readonly ChatMemberCreatePath = '/chats/chat-member-create';

  /**
   * create chat-member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatMemberCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatMemberCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsMembersCreateWithUserListReqDto;
  }): Observable<StrictHttpResponse<Array<ChatsMembersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatMemberCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ChatsMembersDbDto>>;
        }),
      );
  }

  /**
   * create chat-member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatMemberCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatMemberCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsMembersCreateWithUserListReqDto;
  }): Observable<Array<ChatsMembersDbDto>> {
    return this.chatMemberCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatsMembersDbDto>>) => r.body as Array<ChatsMembersDbDto>),
    );
  }

  /**
   * Path part for operation chatMemberDelete
   */
  static readonly ChatMemberDeletePath = '/chats/chat-member-delete/{id}';

  /**
   * delete chat-member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatMemberDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonDeleteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatMemberDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonDeleteResDto>;
        }),
      );
  }

  /**
   * delete chat-member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatMemberDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatMemberDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonDeleteResDto> {
    return this.chatMemberDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDeleteResDto>) => r.body as CommonDeleteResDto),
    );
  }

  /**
   * Path part for operation chatFilesUpload
   */
  static readonly ChatFilesUploadPath = '/chats/chat-file-upload';

  /**
   * uploads files to chat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatFilesUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  chatFilesUpload$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatFilesUploadPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * uploads files to chat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatFilesUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  chatFilesUpload(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<CommonSuccessResDto> {
    return this.chatFilesUpload$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation threadsCreate
   */
  static readonly ThreadsCreatePath = '/chats/thread-create';

  /**
   * uploads files to thread
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `threadsCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  threadsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ThreadsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * uploads files to thread
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `threadsCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  threadsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<CommonSuccessResDto> {
    return this.threadsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation chatsGetFilesList
   */
  static readonly ChatsGetFilesListPath = '/chats/files-list';

  /**
   * get chat files list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetFilesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetFilesList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<Array<ChatFilesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetFilesListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ChatFilesDbDto>>;
        }),
      );
  }

  /**
   * get chat files list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetFilesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetFilesList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<Array<ChatFilesDbDto>> {
    return this.chatsGetFilesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatFilesDbDto>>) => r.body as Array<ChatFilesDbDto>),
    );
  }

  /**
   * Path part for operation chatsGetFilesListPagination
   */
  static readonly ChatsGetFilesListPaginationPath = '/chats/files-list-pagination';

  /**
   * get chat files list with pagination
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetFilesListPagination()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetFilesListPagination$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<ChatDataRoomFileResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetFilesListPaginationPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChatDataRoomFileResDto>;
        }),
      );
  }

  /**
   * get chat files list with pagination
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetFilesListPagination$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetFilesListPagination(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<ChatDataRoomFileResDto> {
    return this.chatsGetFilesListPagination$Response(params).pipe(
      map((r: StrictHttpResponse<ChatDataRoomFileResDto>) => r.body as ChatDataRoomFileResDto),
    );
  }

  /**
   * Path part for operation chatsGetMediaFilesList
   */
  static readonly ChatsGetMediaFilesListPath = '/chats/media-files';

  /**
   * get chat media files list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsGetMediaFilesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetMediaFilesList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
    chatId?: string;
    threadId?: string;
  }): Observable<StrictHttpResponse<ChatMediaFilePaginationResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsGetMediaFilesListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
      rb.query('chatId', params.chatId, {});
      rb.query('threadId', params.threadId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChatMediaFilePaginationResDto>;
        }),
      );
  }

  /**
   * get chat media files list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsGetMediaFilesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatsGetMediaFilesList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
    chatId?: string;
    threadId?: string;
  }): Observable<ChatMediaFilePaginationResDto> {
    return this.chatsGetMediaFilesList$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMediaFilePaginationResDto>) => r.body as ChatMediaFilePaginationResDto),
    );
  }

  /**
   * Path part for operation updatePoll
   */
  static readonly UpdatePollPath = '/chats/poll/{id}';

  /**
   * update poll
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePoll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePoll$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PollUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.UpdatePollPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update poll
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePoll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePoll(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: PollUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.updatePoll$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation chatsMessagesCreate
   */
  static readonly ChatsMessagesCreatePath = '/chats/chats-messages';

  /**
   * chats messages create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatsMessagesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsMessagesCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsMessageCreateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatsMessagesCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * chats messages create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatsMessagesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatsMessagesCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ChatsMessageCreateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.chatsMessagesCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation threadsMessagesCreate
   */
  static readonly ThreadsMessagesCreatePath = '/chats/threads-messages';

  /**
   * threads messages create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `threadsMessagesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  threadsMessagesCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ThreadMessagesCreateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ThreadsMessagesCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * threads messages create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `threadsMessagesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  threadsMessagesCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ThreadMessagesCreateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.threadsMessagesCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation chatMemberGetChatsByUserId_1
   */
  static readonly ChatMemberGetChatsByUserId_1Path = '/chats/group-chats/order';

  /**
   * update group chats order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatMemberGetChatsByUserId_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatMemberGetChatsByUserId_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: GroupChatsOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ChatsService.ChatMemberGetChatsByUserId_1Path, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update group chats order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatMemberGetChatsByUserId_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatMemberGetChatsByUserId_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: GroupChatsOrderUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.chatMemberGetChatsByUserId_1$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
