import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';
import * as moment from 'moment-timezone';

import { CalendarEventsStateModel } from '../models/CalendarEventsState';
import { CalendarEventsService } from '../../../api/services/calendar-events.service';
import {
  CalendarEventCreate,
  CalendarEventDelete,
  CalendarEventDeleteSingle,
  CalendarEventExceptions,
  CalendarEventGet,
  CalendarEventRemove,
  CalendarEventsCheckedCalendarsGet,
  CalendarEventsCheckedCalendarsUpdate,
  CalendarEventsDataUpdate,
  CalendarEventsGet,
  CalendarEventsGetById,
  CalendarEventsSet,
  CalendarEventsStopRemind,
  CalendarEventsUpdate,
  CalendarEventsUpdateSingle,
  CalendarEventsUpdateStatus,
  CalendarTicketsGet,
  CalendarTicketsSet,
} from '../actions/calendar-events.action';

@State<CalendarEventsStateModel>({
  name: 'CalendarEvents',
  defaults: {
    object: null,
    objectId: null,
    events: {},
    tickets: [],
    event: null,
    myCalendars: [],
  },
})
@Injectable()
export class CalendarEventsState {
  /**
   * get events
   * @param  {CalendarEventsStateModel} state
   */
  @Selector()
  static getEvents(state: CalendarEventsStateModel) {
    return (objectId: string) => state.events[objectId || 'personal'] || [];
  }

  @Selector()
  static getTickets(state: CalendarEventsStateModel) {
    return state.tickets;
  }

  /**
   * get event
   * @param  {CalendarEventsStateModel} state
   */
  @Selector()
  static getEvent(state: CalendarEventsStateModel) {
    return state.event;
  }

  /**
   * get my calendars
   * @param  {CalendarEventsStateModel} state
   */
  @Selector()
  static getMyCalendars(state: CalendarEventsStateModel) {
    return state.myCalendars;
  }

  constructor(private calendarEventsService: CalendarEventsService, private localStorage: LocalStorageService) {}

  /**
   * Create calendar event action handler
   * @param  {getState, patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventCreate} action
   */
  @Action(CalendarEventCreate)
  calendar_event_create({ getState, patchState }: StateContext<CalendarEventsStateModel>, action: CalendarEventCreate) {
    const body = { ...action.payload };
    if (body.description === '') {
      delete body.description;
    }
    if (body.place === '') {
      delete body.place;
    }
    return this.calendarEventsService.calendarEventCreate({ body }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set events
   * @param  {patchState}: StateContext<CalendarEventsStateModel>
   */
  @Action(CalendarEventsSet)
  set_chats({ patchState }: StateContext<CalendarEventsStateModel>) {
    patchState({ events: this.localStorage.get('events') || {} });
  }

  /**
   * Get events action handler
   * @param  {getState, patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsGet} action
   */
  @Action(CalendarEventsGet)
  calendar_events_get({ getState, patchState }: StateContext<CalendarEventsStateModel>, action: CalendarEventsGet) {
    const { events } = getState();
    const objectId = action.payload?.objectId || 'personal';

    patchState({ events: this.localStorage.get('events') || {} });

    return this.calendarEventsService.calendarEventGetList(action.payload).pipe(
      tap(
        (result) => {
          const newEvents = { ...events, [objectId]: result };
          this.localStorage.set('events', newEvents);

          // Object + objectId are stored in order to determine if new event belongs to current context
          patchState({
            object: action.payload.object,
            objectId: action.payload.objectId,
            events: newEvents,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get event action handler
   * @param  {patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventGet} action
   */
  @Action(CalendarEventGet)
  calendar_event_get({ patchState, getState }: StateContext<CalendarEventsStateModel>, action: CalendarEventsGet) {
    return this.calendarEventsService.calendarEventGetList(action.payload).pipe(
      tap(
        (result) => {
          const { events } = getState();
          const objectId = action.payload.objectId || 'personal';
          let newEvents = { ...events, [objectId]: [...(events[objectId] || []), ...result] };
          if (objectId !== 'personal') {
            newEvents = { ...newEvents, ['personal']: [...(events['personal'] || []), ...result] };
          }
          this.localStorage.set('events', newEvents);

          // Object + objectId are stored in order to determine if new event belongs to current context
          patchState({
            object: action.payload.object,
            objectId: objectId,
            events: newEvents,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(CalendarTicketsGet)
  calendar_tickets_get({ patchState, getState }: StateContext<CalendarEventsStateModel>, action: CalendarTicketsGet) {
    return this.calendarEventsService.calendarTicketsGetList(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            tickets: result,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set tickets
   * @param  {patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarTicketsSet} action
   */
  @Action(CalendarTicketsSet)
  set_tickets({ patchState }: StateContext<CalendarEventsStateModel>, action: CalendarTicketsSet) {
    patchState({ tickets: action.payload || [] });
  }

  /**
   * Get by id event action handler
   * @param  {patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsGetById} action
   */
  @Action(CalendarEventsGetById)
  calendar_events_get_by_id({ patchState }: StateContext<CalendarEventsStateModel>, action: CalendarEventsGetById) {
    return this.calendarEventsService.calendarEventGetEvent(action.payload).pipe(
      tap(
        (event) => {
          patchState({ event });
          return event;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * update event status handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsUpdateStatus} action
   */
  @Action(CalendarEventsUpdateStatus)
  calendar_events_update_status(
    stateContext: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsUpdateStatus,
  ) {
    return this.calendarEventsService
      .calendarEventUpdateMemberStatus({ id: action.payload.id, body: { status: action.payload.status } })
      .pipe(
        tap(
          () => {},
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * update event status handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsUpdate} action
   */
  @Action(CalendarEventsUpdate)
  calendar_events_update(
    { patchState, getState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsUpdate,
  ) {
    const body = { ...action.payload.body };
    if (body.description === '') {
      delete body.description;
    }
    if (body.place === '') {
      delete body.place;
    }
    return this.calendarEventsService.calendarEventUpdate({ id: action.payload.id, body }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  private getNewEvents(events, objectId, id, result) {
    const filteredEvents = events[objectId] ? events[objectId].filter((event) => event._id !== id) : [];
    let newEvents = { ...events, [objectId]: [...filteredEvents, ...result] };
    if (objectId !== 'personal') {
      const personalEvents = events['personal'] ? events['personal'].filter((event) => event._id !== id) : [];
      newEvents = { ...newEvents, ['personal']: [...personalEvents, ...result] };
    }
    return newEvents;
  }

  /**
   * update event status handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsDataUpdate} action
   */
  @Action(CalendarEventsDataUpdate)
  calendar_events_data_update(
    { patchState, getState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsDataUpdate,
  ) {
    return this.calendarEventsService.calendarEventGetList(action.payload).pipe(
      tap(
        (result) => {
          const { events } = getState();
          const objectId = action.payload.objectId || 'personal';
          const newEvents = this.getNewEvents(events, objectId, action.payload._id, result);

          this.localStorage.set('events', newEvents);
          patchState({ events: newEvents });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * update single event in series handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsUpdateSingle} action
   */
  @Action(CalendarEventsUpdateSingle)
  calendar_events_update_single(
    { patchState, getState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsUpdateSingle,
  ) {
    return this.calendarEventsService.calendarEventsExceptionsCreate({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * delete event handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventDelete} action
   */
  @Action(CalendarEventDelete)
  calendar_events_delete(stateContext: StateContext<CalendarEventsStateModel>, action: CalendarEventDelete) {
    return this.calendarEventsService.calendarEventDelete(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * delete event from socket notification handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventRemove} action
   */
  @Action(CalendarEventRemove)
  calendar_events_remove(
    { patchState, getState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventRemove,
  ) {
    const { events } = getState();
    const objectId = action.payload.objectId || 'personal';
    let newEvents = {
      ...events,
      [objectId]: events[objectId] ? events[objectId].filter((event) => event._id !== action.payload._id) : [],
    };

    if (objectId !== 'personal') {
      newEvents = {
        ...newEvents,
        ['personal']: events['personal'] ? events['personal'].filter((event) => event._id !== action.payload._id) : [],
      };
    }

    this.localStorage.set('events', newEvents);
    patchState({ events: newEvents });
  }

  /**
   * delete event from socket notification handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventExceptions} action
   */
  @Action(CalendarEventExceptions)
  calendar_events_exception(
    { getState, patchState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventExceptions,
  ) {
    const { events } = getState();
    const objectId = action.payload.objectId || 'personal';

    action.payload.exceptionDays.forEach((day) => {
      events[objectId].splice(
        events[objectId].findIndex((event) => event.start === day && event._id === action.payload._id),
        1,
      );
    });

    this.localStorage.set('events', events);
    patchState({ events });
  }

  /**
   * delete single event handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventDeleteSingle} action
   */
  @Action(CalendarEventDeleteSingle)
  calendar_events_delete_single(
    { patchState, getState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventDeleteSingle,
  ) {
    return this.calendarEventsService
      .calendarEventsExceptionsCreate({
        body: action.payload,
      })
      .pipe(
        tap(
          () => {},
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * Get checked calendars action handler
   * @param  {patchState}: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsCheckedCalendarsGet} action
   */
  @Action(CalendarEventsCheckedCalendarsGet)
  calendar_checked_get(
    { patchState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsCheckedCalendarsGet,
  ) {
    patchState({ myCalendars: [] });
    return this.calendarEventsService.calendarEventGetChecksList({}).pipe(
      tap(
        (result) => {
          patchState({ myCalendars: result });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update checked calendars action handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsCheckedCalendarsUpdate} action
   */
  @Action(CalendarEventsCheckedCalendarsUpdate)
  calendar_events_set_my_calendars(
    { patchState }: StateContext<CalendarEventsStateModel>,
    action: CalendarEventsCheckedCalendarsUpdate,
  ) {
    return this.calendarEventsService.calendarEventUpdateChecks(action.payload).pipe(
      tap(
        (result) => {
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Stop event reminder action handler
   * @param  stateContext: StateContext<CalendarEventsStateModel>
   * @param  {CalendarEventsStopRemind} action
   */
  @Action(CalendarEventsStopRemind)
  calendar_events_stop_remind(stateContext: StateContext<CalendarEventsStateModel>, action: CalendarEventsStopRemind) {
    return this.calendarEventsService
      .calendarEventStopReminder({
        id: action.payload.id,
        body: { start: moment().toISOString() },
      })
      .pipe(
        tap(
          (res) => res,
          (err) => {
            throw err.error;
          },
        ),
      );
  }
}
