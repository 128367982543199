import {
  Component,
  Inject,
  HostListener,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { of, Subject, Subscription, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Store, Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import * as moment from 'moment-timezone';
import * as Sentry from '@sentry/angular';
import { LocalStorageService } from 'ngx-localstorage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';

import { environment } from '../../../../mobile/src/environments/environment';
import { ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS } from '../../shared/data/action-logs-templates';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { VideoCallsGetResDto } from '../../api/models/video-calls-get-res-dto';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';
import { UserStatusTypes } from '../../shared/data/user-status-types';
import { TauriService } from '../../shared/services/tauri.service';
import { SocketsService } from '../../shared/services/sockets.service';
import { AudioService } from '../../shared/services/audio.service';
import { ConfigService } from '../../shared/services/config.service';
import { SetEnv } from '../../shared/store/actions/auth.action';
import { ToggleSidebar } from '../../shared/store/actions/configs.action';
import { DocumentsToggleImageGallery } from '../../shared/store/actions/documents.action';
import { GetUsersListByTenant, UpdateUserStatus } from '../../shared/store/actions/users.action';
import { SpaceMemberDelete, SpaceSetInfo, SpacesSet } from '../../shared/store/actions/spaces.action';
import {
  ProjectSetInfo,
  ProjectsEmojiPicker,
  ProjectsSetEmoji,
  ProjectsSet,
  ProjectMemberDelete,
} from '../../shared/store/actions/projects.action';
import {
  ChatsDeleteChatsMembers,
  ChatsSet,
  ChatsSetActiveVideoCallRooms,
  ChatsSetEmoji,
  ChatsTicketTooltip,
} from '../../shared/store/actions/chats.action';
import {
  NotificationsSetPlayRingtone,
  PushNotificationsSettingsCreate,
  PushNotificationsSettingsGet,
} from '../../shared/store/actions/notifications.action';
import { VideoCallSetLastSessions, VideoCallsSendAction } from '../../shared/store/actions/video-calls.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { ChatsState } from '../../shared/store/states/chats.state';
import { ConfigsState } from '../../shared/store/states/configs.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { VideoCallsState } from '../../shared/store/states/video-calls.state';
import { VideoCallModalComponent } from '../../modals/video-call/video-call-modal.component';
import { IncomingCallModalComponent } from '../../modals/incoming-call/incoming-call-modal.component';
import { RedirectService } from '../../shared/services/redirect.service';
import { FullLayoutSocketEnum } from './full-layout.enums';
import { TenantsState } from '../../shared/store/states/tenants.state';
import { ChatType } from '../../shared/components/chat/chat-navigation/enums/chat.enum';
import { FilterType } from '../../shared/components/chat/chat-navigation/enums/filter.enum';
import { SubscriptionGet } from '../../shared/store/actions/payment.action';
import { EnvironmentService } from '../../shared/services/environment.service';

interface IOnMemberDelete {
  userId: string;
  spaceId?: string;
  projectId?: string;
}

interface IHandleRedirect {
  isCurrentUserDeleted: boolean;
  userId: string;
  object: string;
  objectId: string;
}

enum SideBar {
  Notes = 'notes',
  Notifications = 'notifications',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  zIndex$: Observable<boolean>;
  space$: Subscription;
  chats$: Subscription;
  configSub: Subscription;
  layoutSub: Subscription;

  userStatusTypes = UserStatusTypes;
  emojiPickerImage = 'assets/img/emojis/emojis.png';

  platform = 'web';
  hideMenu = false;
  hideAllMenuItems = false;
  darkMode = false;
  config: any = {};
  innerWidth: any;
  bgImage: string;
  bgColor: string;
  menuPosition = 'Side';
  customRecentEmojis = [];
  isMenuCollapsedOnHover = false;
  showSidebar = true;
  currentRoute: string;
  userData: UsersPublicFieldsResDto;
  activeCallRooms: VideoCallsGetResDto[];
  isCallOpened = false;
  isCallStarted = false;
  sidebarConfig = null;
  mutedChats: string[] = [];
  notifyFilter = 'ALL ACTIONS';

  space: any;
  spaceInfo: any;
  projectInfo: any;
  projectEmojiIsOpen = false;
  chatName: string;
  isGroupChat = false;

  tooltipTitle: string = null;
  tooltipStyle: any;
  tooltipArrowStyle: any;

  constructor(
    readonly location: Location,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly store: Store,
    protected actions: Actions,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    protected routerTenantPipe: RouterTenantPipe,
    protected socketsService: SocketsService,
    protected toastrService: ToastrService,
    protected configService: ConfigService,
    protected modalService: NgbModal,
    protected audioService: AudioService,
    protected tauriService: TauriService,
    protected redirectService: RedirectService,
    protected localStorage: LocalStorageService,
    @Inject(DOCUMENT) protected document: Document,
    protected translocoService: TranslocoService,
    protected environmentService: EnvironmentService,
  ) {
    this.config = this.configService.templateConf;
    this.darkMode = this.config.layout.variant !== 'Light';
    this.customRecentEmojis = this.configService.CUSTOM_RECENT_EMOJIS;
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.store.dispatch(new GetUsersListByTenant());
    this.store.dispatch(new SpacesSet());
    this.store.dispatch(new ProjectsSet());
    this.store.dispatch(new ChatsSet());
    this.store.dispatch(new VideoCallSetLastSessions());

    this.store.dispatch(
      new SetEnv({
        ssl: environment.ssl,
        api_root: environment.api_root,
        base_host: environment.base_host,
        main_host: environment.main_host,
      }),
    );

    if (!this.environmentService.isProduction) {
      this.store.dispatch(new SubscriptionGet());
    }

    this.configSub = this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.darkMode = this.config.layout.variant !== 'Light';
      }
      // load layout
      this.loadLayout();
      this.cdr.markForCheck();
    });

    this.currentRoute = window.location.pathname + window.location.search;
    this.updateMobileLayout();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.updateMobileLayout();
      }
    });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.userData = user;

          if (user?.timezone) {
            moment.tz.setDefault(user.timezone);
          }

          Sentry.setUser({
            email: user?.email,
            userName: user?.userName,
            platform: this.platform,
          });
        },
      });

    this.store
      .select(ChatsState.getActiveVideoCallRooms)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms) => {
        this.activeCallRooms = rooms;
        let modalRef: NgbModalRef;

        if (rooms.length && !this.isCallOpened) {
          let chat = null;
          const chats = this.store.selectSnapshot(ChatsState.getChats);
          const incomingCall = rooms.find((room: VideoCallsGetResDto) => {
            chat = chats.find(
              (item) => item.videoCallId === room.videoCallId || item.objectId === room.targetObjectData['objectId'],
            );
            const lastSessionId = this.store.selectSnapshot(VideoCallsState.getLastSessionId)(room.videoCallId);

            return (
              !chat?.noRingtone &&
              room.sessionId !== lastSessionId &&
              !room.handledCallUsers?.includes(this.userData._id as string) &&
              chat?.notifyFilter !== FilterType.NoCall &&
              chat?.notifyFilter !== FilterType.Nothing
            );
          });

          if (incomingCall) {
            if (!this.isCallStarted) {
              this.isCallStarted = true;

              setTimeout(() => {
                modalRef = this.modalService.open(IncomingCallModalComponent, {
                  size: 'sm',
                  backdrop: 'static',
                });
                modalRef.componentInstance.user = this.userData;
                modalRef.componentInstance.room = incomingCall;
                modalRef.componentInstance.chat = chat;
              }, 1000);
            }
          } else {
            this.isCallStarted = false;
          }
        } else {
          this.isCallStarted = false;
          modalRef?.close();
        }

        this.cdr.markForCheck();
      });

    this.store
      .select(VideoCallsState.getIsOpened)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpened) => (this.isCallOpened = isOpened));

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(DocumentsToggleImageGallery))
      .subscribe(({ payload }) => (this.zIndex$ = of(payload)));

    this.actions.pipe(takeUntil(this.destroy$), ofActionDispatched(SpaceSetInfo)).subscribe(({ payload }) => {
      this.projectInfo = null;
      this.spaceInfo = payload;

      this.getPnFilter('spaces', this.spaceInfo._id, this.spaceInfo.chatId);
      this.cdr.detectChanges();
    });

    this.actions.pipe(takeUntil(this.destroy$), ofActionDispatched(ProjectSetInfo)).subscribe(({ payload }) => {
      this.spaceInfo = null;
      this.projectInfo = payload;
      if (this.platform !== 'web') {
        this.getSpace(this.projectInfo.spaceId);
      }

      this.getPnFilter('projects', this.projectInfo._id, this.projectInfo.chatId);
      this.cdr.detectChanges();
    });

    this.actions.pipe(takeUntil(this.destroy$), ofActionDispatched(ProjectsEmojiPicker)).subscribe(({ payload }) => {
      this.projectEmojiIsOpen = payload.projectEmojiPickerIsOpen;
    });

    this.actions.pipe(takeUntil(this.destroy$), ofActionDispatched(ChatsTicketTooltip)).subscribe(({ payload }) => {
      const { tooltipTop, tooltipLeft, tooltipRight, showTicketTooltip, tooltipTitle, tooltipWidth, LeftToRight } =
        payload;

      if (!showTicketTooltip) {
        this.tooltipTitle = null;
      } else {
        this.tooltipTitle = tooltipTitle;
        const sameStyles = {
          display: showTicketTooltip ? 'block' : 'none',
          top: tooltipTop + 'px',
          'max-width': (tooltipWidth || 640) + 'px',
        };

        // tooltip is left to right
        if (LeftToRight) {
          this.tooltipStyle = {
            ...sameStyles,
            left: tooltipLeft + 'px',
          };

          this.tooltipArrowStyle = {
            float: 'left',
            'margin-left': 15 + 'px',
          };
        } else {
          // tooltip is right to left
          this.tooltipStyle = {
            ...sameStyles,
            right: tooltipRight + 'px',
          };

          this.tooltipArrowStyle = {
            float: 'right',
            'margin-right': 15 + 'px',
          };
        }
      }
    });

    if (this.platform === 'web') {
      this.store
        .select(ConfigsState.getSidebarConfigs)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => (this.sidebarConfig = res));

      this.actions
        .pipe(takeUntil(this.destroy$), ofActionSuccessful(NotificationsSetPlayRingtone))
        .subscribe(({ payload }) => {
          if (payload.needPlay) {
            const ringtone = payload.ringtone === 'call' ? 'CALL_SOUND' : 'NOTIFICATION_SOUND';
            this.audioService.playHowl(this.configService[ringtone], payload.ringtone === 'call');
          } else {
            this.audioService.stopHowl();
          }
          this.cdr.detectChanges();
        });
    } else {
      this.actions
        .pipe(takeUntil(this.destroy$), ofActionSuccessful(PushNotificationsSettingsCreate))
        .subscribe(({ payload }) => {
          this.notifyFilter = payload.filter;
          this.cdr.detectChanges();
        });
    }

    this.socketsService.get().on('notification:send:usersStatusUpdate', (data) => {
      this.store.dispatch(new UpdateUserStatus(data));
    });

    this.socketsService.get().on(FullLayoutSocketEnum.SPACES_MEMBER_DELETE, ({ userId, spaceId }) => {
      this.onMemberDelete({ userId, spaceId });
    });

    this.socketsService.get().on(FullLayoutSocketEnum.PROJECTS_MEMBER_DELETE, ({ userId, projectId }) => {
      this.onMemberDelete({ userId, projectId });
    });

    // For electron app
    if (this.tauriService.isTauri) {
      console.log('Is electron');
      this.socketsService.get().on('notification:send:pushNotification', (e) => {
        const action = JSON.parse(e.action);
        console.log('Push notification received', e, action);

        if (!action.chatId || (action.chatId && !this.currentRoute.includes(`/chat/${action.chatId}?page=dm`))) {
          if (
            e.action?.chatId &&
            this.userData.status !== this.userStatusTypes.DO_NOT_DISTURB &&
            (this.mutedChats.length === 0 || this.mutedChats.indexOf(e.action.chatId) === -1)
          ) {
            this.store.dispatch(new NotificationsSetPlayRingtone({ needPlay: true, ringtone: 'notification' }));
          }

          new Notification('Teamplate - ' + (e.notification.title || action.tenantName), {
            body: e.notification.body,
            icon: 'favicon.ico',
            data: { ...e, action },
          }).onclick = (event) => {
            console.log('Push notification performed', event.target);
            const currAction = event.target['data'].action;

            this.tauriService.moveToTop();
            this.performHandler(currAction);
          };
        }
      });
    }
  }

  ngOnDestroy() {
    this.showSidebar = false;
    // Unsubscribe subscriptions
    this.configSub?.unsubscribe();
    this.layoutSub?.unsubscribe();
    this.space$?.unsubscribe();
    this.chats$?.unsubscribe();

    const socket = this.socketsService.get();
    socket.removeListener('notification:send:usersStatusUpdate');
    socket.removeListener(FullLayoutSocketEnum.SPACES_MEMBER_DELETE);
    socket.removeListener(FullLayoutSocketEnum.PROJECTS_MEMBER_DELETE);
    if (this.tauriService.isTauri) {
      socket.removeListener('notification:send:pushNotification');
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  // close emoji picker when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.projectEmojiIsOpen && !event.target.classList.contains('emoji-picker-icon')) {
      const emojiPickerGeneralElement = document.querySelector('.emoji-picker-general');
      const emojiPickerElement = document.querySelector('.emoji-picker-project');
      const emojiMartElement = document.querySelector('.emoji-mart');

      if (
        !emojiPickerGeneralElement?.contains(event.target) &&
        !emojiPickerElement?.contains(event.target) &&
        !emojiMartElement?.contains(event.target)
      ) {
        this.closeProjectEmojiPicker();
      }
    }
  }

  generateQueryParams(action): any {
    let query = {};
    const key = `${action.object}:${action.actionObject}:${action.actionOperationId}`;
    if (ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key] !== undefined) {
      query = ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key]({
        ...action,
        templateData: JSON.parse(action.templateData),
      });
    }
    if (query['urlSuffix']) {
      delete query['urlSuffix'];
    }
    return query;
  }

  private performHandler = (action) => {
    if (action.chatId) {
      const commands = [this.routerTenantPipe.transform(`chat/${action.chatId}`, action.message.tenantName)];
      if (action.type === 'thread') {
        this.router.navigate(commands);
      } else if (action.type === 'direct') {
        this.router.navigate(commands, { queryParams: { page: 'dm' } });
      } else {
        this.router.navigate(commands);
      }
    } else {
      let prefix = '';
      let suffix = '';

      if (action.object === 'spaces') {
        prefix = 'space';
      } else if (action.object === 'projects') {
        prefix = 'project';
      }

      if (action.actionOperationId === 'videoCallsRoomCreate') {
        if (!this.isCallOpened) {
          this.store.dispatch(new ChatsSetActiveVideoCallRooms());

          const roomId = action.url.split('/').pop();
          const activeRoom = this.activeCallRooms?.find((room) => room.videoCallId === roomId);

          if (activeRoom && activeRoom.participants.length > 0) {
            const route = activeRoom.targetObject === 'chat' ? `chat/${activeRoom.targetObjectId}` : 'dash';
            this.router.navigate([this.routerTenantPipe.transform(route, action.tenantName)]);

            this.store.dispatch(new VideoCallsSendAction({ ...activeRoom }));

            const modalRef = this.modalService.open(VideoCallModalComponent, {
              windowClass: 'cropper-modal video-call-modal',
              backdrop: false,
            });
            modalRef.componentInstance.callData = { roomId, user: this.userData };
          } else {
            this.toastrService.info(
              this.translocoService.translate('toastr.video-call-ended'),
              this.translocoService.translate('toastr.title-video-call-ended'),
            );
          }
        } else {
          this.toastrService.info(
            this.translocoService.translate('toastr.you-on-another-call'),
            this.translocoService.translate('toastr.title-video-call-opened'),
          );
        }
      } else if (action.actionObject === 'calendar-events') {
        const route = action.object === 'users' ? 'dash' : `${prefix}/${action.objectId}/calendar`;
        this.router.navigate([this.routerTenantPipe.transform(route, action.tenantName)], {
          queryParams: { event: action.actionObjectId },
        });
      } else if (action.actionObject === 'tickets') {
        const board =
          action.columnName === 'BACKLOG' ? 'backlog' : action.columnName === 'ARCHIVE' ? 'archive' : 'board';
        this.router.navigate(
          [this.routerTenantPipe.transform(`${prefix}/${action.objectId}/${board}`, action.tenantName)],
          { queryParams: { ticket: action.actionObjectId } },
        );
      } else {
        const key = `${action.object}:${action.actionObject}:${action.actionOperationId}`;

        if (ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key] !== undefined) {
          suffix = ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key]({
            ...action,
            templateData: JSON.parse(action.templateData),
          }).urlSuffix;
        }
        if (suffix) {
          suffix = `/${suffix}`;
        }

        this.router.navigate([this.routerTenantPipe.transform(`${prefix}/${action.objectId}${suffix}`)], {
          queryParams: this.generateQueryParams(action),
        });
      }
    }
  };

  getSpace(spaceId) {
    this.space$?.unsubscribe();
    this.space$ = this.store
      .select(SpacesState.getSpace)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(spaceId)),
      )
      .subscribe((res) => {
        this.space = res;
        this.cdr.detectChanges();
      });
  }

  get isNoteBar(): boolean {
    return this.sidebarConfig?.sidebarContentType === SideBar.Notes;
  }

  getPnFilter(object, objectId, chatId) {
    // sometimes objectId can be null, TODO: find why
    if (!objectId) {
      return;
    }

    this.store
      .dispatch(new PushNotificationsSettingsGet({ object, objectId }))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.notifyFilter = res.Notifications.filters[chatId]?.filter || 'ALL ACTIONS';
        this.cdr.detectChanges();
      });
  }

  updateMobileLayout() {
    if (this.platform !== 'web') {
      if (this.currentRoute.includes('/chat/')) {
        this.hideMenu = true;
        this.chats$?.unsubscribe();
        this.chats$ = this.store
          .select(ChatsState.getChats)
          .pipe(takeUntil(this.destroy$))
          .subscribe((chats) => {
            if (chats?.length) {
              const path = this.currentRoute.split('?')[0];
              const chatId = path.split('/').pop();
              const chat = chats.find((item) => item._id === chatId);
              this.chatName = chat?.chatName;

              if (chat?.type === 'group') {
                this.isGroupChat = true;
                this.getSpace(chat.objectId);
              }
              this.cdr.detectChanges();
            }
          });
      } else {
        this.chatName = null;
        this.isGroupChat = false;
        this.hideAllMenuItems = this.currentRoute.includes('/manage-spaces');
        this.hideMenu =
          this.currentRoute.includes('/project/') ||
          this.currentRoute.includes('/space/') ||
          this.currentRoute.includes('/thread/');

        if (!this.currentRoute.includes('/project/')) {
          this.space$?.unsubscribe();
          this.space = null;
        }
        this.cdr.detectChanges();
      }
    }
  }

  loadLayout() {
    // menu position "SIDE" or "TOP"
    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    // Hide/show sidebar menu background image
    this.bgImage = !this.config.layout.sidebar.backgroundImage ? '' : this.config.layout.sidebar.backgroundImageURL;

    // Set sidebar menu background color
    this.bgColor = this.config.layout.sidebar.backgroundColor;

    // toggle side menu
    if (this.config.layout.menuPosition === 'Side') {
      this.isMenuCollapsedOnHover = true;

      // on sidebar collapse
      this.renderer.removeClass(this.document.body, 'menu-expanded');
      this.renderer.removeClass(this.document.body, 'vertical-menu');
      this.renderer.removeClass(this.document.body, 'menu-open');

      this.renderer.addClass(this.document.body, 'vertical-layout');
      this.renderer.addClass(this.document.body, 'menu-hide');
    }

    // Layout variants
    if (this.config.layout.variant === 'Light') {
      this.renderer.removeClass(this.document.body, 'layout-dark');
      this.renderer.removeClass(this.document.body, 'layout-transparent');
    } else if (this.config.layout.variant === 'Dark') {
      this.renderer.removeClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, 'layout-dark');
    } else if (this.config.layout.variant === 'Transparent') {
      this.renderer.addClass(this.document.body, 'layout-dark');
      this.renderer.addClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, this.bgColor);
      this.bgImage = '';
    }

    // For Sidebar width
    if (this.config.layout.sidebar.size === 'sidebar-sm') {
      this.renderer.removeClass(this.document.body, 'sidebar-lg');
      this.renderer.addClass(this.document.body, 'sidebar-sm');
    } else if (this.config.layout.sidebar.size === 'sidebar-lg') {
      this.renderer.removeClass(this.document.body, 'sidebar-sm');
      this.renderer.addClass(this.document.body, 'sidebar-lg');
    } else {
      this.renderer.removeClass(this.document.body, 'sidebar-sm');
      this.renderer.removeClass(this.document.body, 'sidebar-lg');
    }

    // Navbar types
    if (this.config.layout.navbar.type === 'Static') {
      this.renderer.removeClass(this.document.body, 'navbar-sticky');
      this.renderer.addClass(this.document.body, 'navbar-static');
    } else if (this.config.layout.navbar.type === 'Fixed') {
      this.renderer.removeClass(this.document.body, 'navbar-static');
      this.renderer.addClass(this.document.body, 'navbar-sticky');
    }
  }

  emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) => this.emojiPickerImage;

  addEmoji({ emoji }) {
    this.store.dispatch(new ChatsSetEmoji({ selectedEmoji: emoji }));
  }

  addProjectEmoji({ emoji }) {
    this.store
      .dispatch(new ProjectsSetEmoji({ selectedEmoji: emoji.native }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.closeProjectEmojiPicker());
  }

  closeProjectEmojiPicker() {
    this.store.dispatch(new ProjectsEmojiPicker({ projectEmojiPickerIsOpen: false }));
  }

  onRouteActivate(event) {
    // TODO: header update
  }

  toggleSidebar() {
    this.sidebarConfig.isOpened =
      this.sidebarConfig.sidebarContentType === 'notes' ? !this.sidebarConfig.isOpened : true;
    this.store.dispatch(new ToggleSidebar({ isOpened: this.sidebarConfig.isOpened, sidebarContentType: 'notes' }));
    this.cdr.detectChanges();
  }

  onMemberDelete({ userId, spaceId, projectId }: IOnMemberDelete) {
    if (this.platform !== 'web') {
      return;
    }

    const isCurrentUserDeleted = userId === this.userData?._id;

    if (spaceId) {
      this.store
        .dispatch(new SpaceMemberDelete({ spaceId, userId, isCurrentUserDeleted }))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.handleRedirect({ isCurrentUserDeleted, object: ChatType.Space, objectId: spaceId, userId });
        });
    } else if (projectId) {
      this.store
        .dispatch(new ProjectMemberDelete({ projectId, userId, isCurrentUserDeleted }))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.handleRedirect({ isCurrentUserDeleted, object: ChatType.Project, objectId: projectId, userId });
        });
    }
  }

  handleRedirect({ isCurrentUserDeleted, userId, object, objectId }: IHandleRedirect) {
    const actionChat = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.object === object && chat.objectId === objectId);
    this.store.dispatch(new ChatsDeleteChatsMembers({ userId, chatId: actionChat?._id }));
    if (isCurrentUserDeleted) {
      if (window.location.href.includes(actionChat?._id) || window.location.href.includes(objectId)) {
        // case when current user was deleted and navigated on this object then we redirect him
        const tenantName = this.store.selectSnapshot(TenantsState.getTenantName);
        this.router.navigate([this.routerTenantPipe.transform('dash', tenantName)]);
      }
    }
  }
}
