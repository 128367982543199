<div
  class="avatar-container mr-2"
  [class.avatar-breadcrumb]="isBreadcrumb"
  [class.mr-2]="mr"
  [ngStyle]="{
    'width.px': containerWidth,
    'height.px': containerHeight
  }"
>
  <img
    *ngIf="avatarImage"
    src="{{ avatarImage }}"
    alt="Space avatar image"
    class="avatar"
    [ngStyle]="{
      'width.px': containerWidth,
      'height.px': containerHeight
    }"
  />

  <div
    *ngIf="checkAvatarImage"
    class="space-default-avatar"
  >
    {{ avatarText }}
  </div>

  <div *ngIf="overlay" class="img-overlay">
    <i class="fa fa-2x fa-edit"></i>
  </div>
</div>
