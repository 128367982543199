import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { environment } from '../../../environments/environment';
import { AuthState } from '../store/states/auth.state';
import { OnlineStatusService } from '../services/online-status.service';
import { ChatsService } from '../../api/services/chats.service';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private readonly localStorageService: LocalStorageService,
    public onlineStatus: OnlineStatusService,
    public toastr: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  /**
   * Add api token to header in requests
   * @param  {HttpRequest<any>} req
   * @param  {HttpHandler} next
   * @returns Observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.localStorageService.get('accessToken');
    const token = this.store.selectSnapshot(AuthState.getAccessToken) || accessToken;
    const user = this.store.selectSnapshot(AuthState.getUser);

    const headers = {
      setHeaders: {
        'x-lang': user?.localization || this.localStorageService.get(LocalStorageKeys.language) || '',
      },
    };

    if (token !== null) {
      headers.setHeaders['Authorization'] = 'Bearer ' + token;
    }

    req = req.clone(headers);

    if (
      (req.url === `${environment.api_root}${ChatsService.ChatsMessagesCreatePath}` ||
        req.url === `${environment.api_root}${ChatsService.ThreadsMessagesCreatePath}`) &&
      req.method === 'POST'
    ) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status !== 0) {
          this.notifyError(error);
          throw error;
        } else {
          return of(error);
        }
      }),
      finalize(() => this.onlineStatus.setStatus(true)),
    );
  }

  private notifyError(error): void {
    const ignoreToast = ['wiki-pages'];
    const isShowError = !ignoreToast.some((text) => error.url?.includes(text));

    if ((error.error?.statusCode >= 500 || error.status >= 500 || !environment.production) && isShowError) {
      this.toastr.error(this.getErrorMessage(error), this.translocoService.translate('toastr.title-error'));
    }
  }

  private getErrorMessage(error): string {
    let message = '';

    if (Array.isArray(error.error?.message)) {
      message = error.error.message.join(', ');
    } else if (typeof error.error?.message === 'string') {
      message = error.error.message;
    } else {
      message = error.message ?? this.translocoService.translate('toastr.unknown-network-error');
    }

    return message;
  }
}
