import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @Input() videoUrl: string;
  @Input() isDownloading: boolean;
  isPlayerReady = false;

  constructor(private cdr: ChangeDetectorRef) {}
}
