import { NgModule } from '@angular/core';
import { RouterModule, Route, Routes } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { FULL_ROUTES } from './layouts/full/full-layout.routes';
import { CONTENT_ROUTES } from './layouts/content/content-layout.routes';
import { TenantsResolver } from './shared/resolvers/tenants.resolver';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';

import { environment } from '../environments/environment';
import { VideoCallResolver } from './shared/resolvers/video-call.resolver';
import { VideoCallModalComponent } from './modals/video-call/video-call-modal.component';
import { NoInternetPageComponent } from './pages/no-internet-page/no-internet-page.component';
import { PaymentToastComponent } from './pages/payment-toast/payment-toast.component';

// Redirect to root by default
const rootRedirectRoute: Route = {
  path: '',
  redirectTo: 'root',
  pathMatch: 'full',
};

// Redirect from root
const defaultRedirectRoute: Route = {
  path: '',
  redirectTo: environment.subdomains ? '/auth/login' : '/root/auth/login',
  pathMatch: 'full',
};

// Routes for logged in user
const loggedInRoute: Route = {
  path: '',
  component: FullLayoutComponent,
  data: { title: 'full Views' },
  children: FULL_ROUTES,
};

// Routes for not logged in user
const notLoggedInRoute: Route = {
  path: '',
  component: ContentLayoutComponent,
  data: { title: 'content Views' },
  children: CONTENT_ROUTES,
};

// Not found route
const error404PageRoute: Route = {
  path: '/error404',
  component: Error404PageComponent,
};

// Payment Toast
const paymentToast: Route = {
  path: environment.subdomains ? 'payment-toast/:status' : 'root/payment-toast/:status',
  component: PaymentToastComponent,
};

// No internet connection
const noInternetConnection: Route = {
  path: environment.subdomains ? 'connection-lost' : 'root/connection-lost',
  component: NoInternetPageComponent,
};

// Everything that didn't matched redirects to error404
const notFoundRedirectRoute: Route = {
  path: '**',
  redirectTo: environment.subdomains ? '/error404' : '/root/error404',
};

/** Depends on env settings, use subdomains */
const appRoutes: Routes = environment.subdomains
  ? [
      defaultRedirectRoute,
      {
        path: 'meet/:id',
        component: VideoCallModalComponent,
        resolve: [VideoCallResolver],
      },
      {
        path: '',
        resolve: { tenant: TenantsResolver },
        children: [notLoggedInRoute, loggedInRoute, noInternetConnection, paymentToast, notFoundRedirectRoute],
      },
    ]
  : [
      rootRedirectRoute,
      // To mimic subdomain structure on dev environment, we assume root as keyword means we aren't on subdomain now
      {
        path: 'root',
        children: [defaultRedirectRoute, notLoggedInRoute],
      },
      {
        path: 'meet/:id',
        component: VideoCallModalComponent,
        resolve: [VideoCallResolver],
      },
      {
        path: ':tenant',
        resolve: { tenant: TenantsResolver },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'auth/login',
          },
          notLoggedInRoute,
          loggedInRoute,
        ],
      },
      noInternetConnection,
      paymentToast,
      notFoundRedirectRoute,
    ];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [TenantsResolver, VideoCallResolver],
})
export class AppRoutingModule {}
