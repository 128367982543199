<!--  Chats Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [class.navbar-dark]="isDark$ | async" *transloco="let t; read: 'full-layout'">
	<div
		ngbDropdown
		class="sidebar-tenant"
		t-id="chat-navigation-switching-tenants"
		(openChange)="dropdownIsOpen = $event">
		<button
			ngbDropdownToggle
			class="sidebar-tenant_picker"
		>
			<div class="sidebar-tenant_naming sidebar-tenant_naming_current">
				<div *ngIf="!currentTenant?.avatarUrl" class="sidebar-tenant_pic">
					{{ currentTenant?.name?.charAt(0).toUpperCase() }}
				</div>
				<img *ngIf="currentTenant?.avatarUrl" class="sidebar-tenant_img" src="{{currentTenant?.avatarUrl}}"
				     alt="Workplace icon">
				<span t-id="chat-navigation-tenant-name">{{ currentTenant?.name }}</span>

        <svg-icon
          *ngIf="!dropdownIsOpen"
          src="assets/icons/common/arrow--down.svg"
          [ngStyle]="{ transition: '0.2s ease', height: '24px' }"
          [svgStyle]="{ 'width.px': '14', 'height.px': '8' }"
          class="svg-icon ml-2"
        ></svg-icon>
        <svg-icon
          *ngIf="dropdownIsOpen"
          src="assets/icons/common/arrow-up.svg"
          [ngStyle]="{ transition: '0.2s ease', height: '24px' }"
          [svgStyle]="{ 'width.px': '14', 'height.px': '8' }"
          class="svg-icon ml-2"
        ></svg-icon>

			</div>

			<div ngbDropdownMenu>
				<ng-scrollbar [autoHeightDisabled]="false" pointerEventsMethod="scrollbar" style="max-height: 500px" class="sidebar-tenant_scroll" thumbClass="white-scrollbars">
					<div class="sidebar-tenant_wrapper">
						<div class="w-100 position-relative sidebar-tenant_item" *ngFor="let tenant of tenantList">
							<div class="creation-name creation-name_dropdown">
								<div
									class="sidebar-tenant_naming sidebar-tenant_naming-option"
									(click)="changeTenant(tenant)"
									[class.sidebar-tenant_selected]="tenant.name === currentTenant.name"
									t-id="chat-navigation-tenant-list">
									<div class="sidebar-tenant_pic" *ngIf="!tenant.avatarUrl">
										{{ tenant.name?.charAt(0).toUpperCase() }}
									</div>
									<img *ngIf="tenant.avatarUrl" class="sidebar-tenant_img" src="{{tenant.avatarUrl}}" alt="tenant icon">
									<div *ngIf="isBadge(tenant)" class="sidebar-tenant_badge">{{ tenant.badge }}</div>
									<span t-id="chat-navigation-tenant-name"> {{ tenant.name }} </span>
								</div>
							</div>
							<div *ngIf="tenant.ownerUserId === userId" class="sidebar-tenant_edit" (click)="editTenant(tenant)" t-id="chat-navigation-tenant-edit">
                {{ t('chat-navigation.btn-edit-workplace') }}
              </div>
						</div>
					</div>
				</ng-scrollbar>
				<div class="sidebar-tenant_footer-tenant" (click)="createTenant()" t-id="chat-navigation-add-tenant">
					<div class="add"> +</div>
          {{ t('chat-navigation.btn-add-workplace') }}
				</div>
				<div class="sidebar-tenant_footer">
					<div class="sidebar-tenant_footer-logo">
						<img
							src="assets/img/app-logo-{{(isDark$ | async) ? 'dark' : 'light'}}.png"
							alt="logo"
							class="logo-img"
						>
					</div>
				</div>
			</div>
		</button>
	</div>
	<!-- First level menu -->
	<div class="navigation chat-wrapper has-sub">
		<div class="menu-title-wrap open-chat personal-space mx-2">
			<a
				t-id="chat-navigation-personal-space"
				class="menu-title personal-space-link d-flex justify-content-start align-items-center"
				routerLinkActive="active"
				[routerLink]="'/dash/' | routerTenant"
			>
        <svg-icon
          src="assets/icons/common/dashboard.svg"
          class="not-hover"
          [ngStyle]="{ height: '24px' }"
          [svgStyle]="{ 'width.px': '14', 'height.px': '14',  }"
        ></svg-icon>
				<span class="personal-space-link-title">{{ t('chat-navigation.personal-space-title') }}</span>
			</a>
		</div>
	</div>

	<div class="search-container d-flex align-items-center">
		<input
			#searchChat
			id="searchChat"
			class="form-control search-field"
			type="text"
			[placeholder]="'&#xE8BD;&nbsp; ' + t('chat-navigation.search-chat-placeholder')"
			required
			[(ngModel)]="searchedText"
		/>

		<span *ngIf="searchedText?.length > 0" class="d-flex align-items-center justify-content-end">
      <svg-icon
        src="assets/icons/common/clear.svg"
        class="cursor-pointer"
        [svgStyle]="{ 'width.px': '14', 'height.px': '14' }"
        (click)="clearSearchChat()"
      ></svg-icon>
    </span>
	</div>

	<ng-scrollbar #scrollbar pointerEventsMethod="scrollbar" class="scroll-event scrollable-container" visibility="hover" thumbClass="white-scrollbars">
		<div
			class="d-flex align-items-center chat-navigation-categories-title"
			*ngIf="!isSearch && (allUnreadMessage | unread:unreadDirectChats).length > 0"
		>
			<div class="d-flex align-items-center justify-content-start flex-grow-1">
        <div class="arrow-wrapper p-1 mx-1">
          <svg-icon
            *ngIf="toggleSpaces"
            src="assets/icons/common/arrow-down.svg"
            [svgStyle]="{ 'width.px': '14', 'height.px': '8' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('unread')"
          ></svg-icon>
          <svg-icon
            *ngIf="!toggleSpaces"
            src="assets/icons/common/arrow-right.svg"
            [svgStyle]="{ 'width.px': '8', 'height.px': '14' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('unread')"></svg-icon>
        </div>
				<div>{{ t('chat-navigation.unread') }}</div>
			</div>

			<div class="d-flex align-items-center">
				<button class="btn btn-sm btn-subtle mx-2" (click)="readAll()">{{ t('chat-navigation.read-all') }}</button>
				<span>{{ unreadSpaces.length + unreadProjects.length + unreadGroupChats.length + unreadDirectChats.length }}</span>
			</div>
		</div>
		<ul *ngIf="!isSearch && toggleUnread" class="navigation pb-2">
      <li
        *ngFor="let unReadMessage of allUnreadMessage | unread:unreadDirectChats; trackBy: trackByChatId"
        [class]="getClassByTypeChat(unReadMessage)"
        [class.has-unread-messages]="unReadMessage.numberOfUnreadMessages > 0"
      >
        <ng-container
          [ngTemplateOutlet]="getCheckChatType(
            unReadMessage,
            directsMenuItemTemplate,
            groupsMenuItemTemplate,
            chatsMenuItemTemplate,
            spacesMenuItemTemplate
          )"
          [ngTemplateOutletContext]="{
             $implicit: unReadMessage,
             isUnreadList: getCheckGroupProject(unReadMessage),
             spaceId: getCheckIsGroup(unReadMessage)
           }"
        ></ng-container>
      </li>
		</ul>

		<div
			*ngIf="!isSearch && (allUnreadMessage | unread:unreadDirectChats).length > 0"
			class="divider"
		></div>

		<div t-id="chat-navigation-space"
		     class="d-flex align-items-center chat-navigation-categories-title chat-menu-item w-100">
			<div class="d-flex align-items-center justify-content-start flex-grow-1">
        <span class="arrow-wrapper p-1 mx-1">
          <svg-icon
            *ngIf="toggleSpaces"
            src="assets/icons/common/arrow-down.svg"
            [svgStyle]="{ 'width.px': '14', 'height.px': '8' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('space')"
          ></svg-icon>
          <svg-icon
            *ngIf="!toggleSpaces"
            src="assets/icons/common/arrow-right.svg"
            [svgStyle]="{ 'width.px': '8', 'height.px': '14' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('space')"></svg-icon>
        </span>
				<span>{{ t('chat-navigation.spaces') }}</span>
			</div>

			<div class="menu-icons d-flex align-items-center">
				<div class="btn-tooltip" [ngbTooltip]="t('actions-tooltip')" placement="left">
          <div
            t-id="chat-navigation-tree-dot-menu"
            class="btn dropdown d-flex align-items-center"
            appTippy
            [popoverContent]="dropdownSpaces"
            [popoverPlacement]="'bottom-end'"
          >
            <svg-icon
              src="assets/icons/common/ellipsis.svg"
              class="dropdown-toggle cursor-pointer svg-icon"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': '17', 'height.px': '18' }"
            ></svg-icon>
          </div>
          <div class="chat-dropdown" [class.dark]="isDark$ | async" #dropdownSpaces>
            <a class="dropdown-item" [routerLink]="'/manage-spaces/' | routerTenant"
               t-id="chat-navigation-manage-spaces">
              <i class="ft-list mr-2" aria-hidden="true"></i>
              <span>{{ t('chat-navigation.btn-manage-spaces') }}</span>
            </a>
            <a href="javascript:;" class="dropdown-item" (click)="spaceService.createClick()"
               t-id="chat-navigation-create-space">
              <i class="ft-plus mr-2" aria-hidden="true"></i>
              <span>{{ t('chat-navigation.btn-add-spaces') }}</span>
            </a>
          </div>
				</div>
			</div>
		</div>
    <ul *ngIf="!isSearch && toggleSpaces && personalSpace" class="navigation">
      <li
        t-id="chat-navigation-space-item"
        id="spaceItem-{{personalSpace._id}}"
        class="chat-wrapper has-sub space-dnd-item"
      >
        <ng-container
          [ngTemplateOutlet]="spacesMenuItemTemplate"
          [ngTemplateOutletContext]="{ $implicit: personalSpace, withDnd: false }"
        ></ng-container>
      </li>
    </ul>

		<ul
      *ngIf="!isSearch && toggleSpaces"
      cdkDropList
      [cdkDropListData]="commonSpaces"
      (cdkDropListDropped)="onDnDChats($event, ChatTypes.Space)"
      id="spacesContainer"
      class="navigation pb-2"
    >
			<li
				*ngFor="let space of commonSpaces; trackBy: trackById"
        cdkDrag
        t-id="chat-navigation-space-item"
        id="spaceItem-{{space._id}}"
				class="chat-wrapper has-sub space-dnd-item"
				level="{{ level + 1 }}"
			>
				<ng-container
					[ngTemplateOutlet]="spacesMenuItemTemplate"
					[ngTemplateOutletContext]="{ $implicit: space, withDnd: true }"
				></ng-container>

				<ul
          class="menu-content"
          *ngIf="spaceService.getContentVisibility(space)"
          cdkDropList
          [cdkDropListData]="getChatMenuProjectItems(chatMenuProjectItems, space._id)"
          (cdkDropListDropped)="onDnDChats($event, ChatTypes.Project)"
        >
          <ng-container *ngIf="chatMenuProjectItems">
            <ng-container *ngFor="let chatMenuProjectItem of getChatMenuProjectItems(chatMenuProjectItems, space._id); trackBy: trackByChatId">
              <li
                *ngIf="!chatMenuProjectItem.isArchive"
                cdkDrag
                id="projectItem-{{chatMenuProjectItem.objectId}}"
                level="{{ level + 2 }}"
                attr.data-spaceId="{{space._id}}"
                class="chat-menu-item project-dnd-item"
                [class.has-unread-messages]="isBoldChannel(chatMenuProjectItem)"
				        t-id="chat-navigation-project-list-item"
              >
                <ng-container
                  [ngTemplateOutlet]="chatsMenuItemTemplate"
                  [ngTemplateOutletContext]="{ $implicit: chatMenuProjectItem, hasGroupChats: !!getChatMenuGroupItems(chatMenuGroupsItems, space._id)?.length, isProject: true }"
                ></ng-container>
              </li>
            </ng-container>
					</ng-container>
        </ul>

        <ul
          class="menu-content"
          *ngIf="spaceService.getContentVisibility(space)"
          cdkDropList
          [cdkDropListData]="getChatMenuGroupItems(chatMenuGroupsItems, space._id)"
          (cdkDropListDropped)="onDnDChats($event, ChatTypes.GroupChat)"
        >
					<ng-container *ngIf="chatMenuGroupsItems">
						<li
							*ngFor="let groupChatsItem of getChatMenuGroupItems(chatMenuGroupsItems, space._id); trackBy: trackByChatId"
              cdkDrag
              level="{{ level + 2 }}"
              class="chat-menu-item"
              id="groupChatItem-{{groupChatsItem.chatId}}"
							[class.has-unread-messages]="groupChatsItem.numberOfUnreadMessages > 0"
						>
							<ng-container
								[ngTemplateOutlet]="groupsMenuItemTemplate"
								[ngTemplateOutletContext]="{ $implicit: groupChatsItem, spaceId: space?._id }"
							></ng-container>
						</li>
					</ng-container>
				</ul>
			</li>
		</ul>

		<ul *ngIf="isSearch && toggleSpaces" class="navigation pb-2">
			<li
				*ngFor="let space of spaces; trackBy: trackById"
				t-id="chat-navigation-space-item"
				class="chat-wrapper has-sub space-dnd-item"
			>
				<ng-container
					[ngTemplateOutlet]="spacesMenuItemTemplate"
					[ngTemplateOutletContext]="{ $implicit: space }"
				></ng-container>
			</li>

			<li
				*ngFor="let chatMenuProjectItem of chatMenuProjectItems; trackBy: trackByChatId"
				class="chat-menu-item"
				[class.has-unread-messages]="isBoldChannel(chatMenuProjectItem)"
			>
				<ng-container
					[ngTemplateOutlet]="chatsMenuItemTemplate"
					[ngTemplateOutletContext]="{ $implicit: chatMenuProjectItem, isUnreadList: true }"
				></ng-container>
			</li>

			<li
				*ngFor="let groupChatsItem of chatMenuGroupsItems; trackBy: trackByChatId"
				class="chat-menu-item"
				[class.has-unread-messages]="groupChatsItem.numberOfUnreadMessages > 0"
			>
				<ng-container
					[ngTemplateOutlet]="groupsMenuItemTemplate"
					[ngTemplateOutletContext]="{ $implicit: groupChatsItem, isUnreadList: true, spaceId: space?._id }"
				></ng-container>
			</li>
		</ul>

		<div class="divider"></div>

		<div class="d-flex align-items-center chat-navigation-categories-title">
			<div class="flex-grow-1">
				<div class="d-flex align-items-center justify-content-start">
          <span class="arrow-wrapper p-1 mx-1">
          <svg-icon
            *ngIf="toggleDirects"
            src="assets/icons/common/arrow-down.svg"
            [svgStyle]="{ 'width.px': '14', 'height.px': '8' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('direct')"
          ></svg-icon>
          <svg-icon
            *ngIf="!toggleDirects"
            src="assets/icons/common/arrow-right.svg"
            [svgStyle]="{ 'width.px': '8', 'height.px': '14' }"
            [applyClass]="true"
            class="svg-icon"
            (click)="toggleList('direct')"></svg-icon>
        </span>
					<span>{{ t('chat-navigation.direct-messages') }}</span>
				</div>
			</div>
      <svg-icon
        src="assets/icons/common/plus.svg"
        class="svg-icon cursor-pointer"
        [applyClass]="true"
        [svgStyle]="{ 'width.px': '11', 'height.px': '11' }"
        (click)="handlerTenantMembersModal()"
      ></svg-icon>
		</div>
		<ul *ngIf="toggleDirects" class="navigation pb-2">
			<li
				*ngFor="let directChatsItem of chatMenuDirectItems; trackBy: trackByChatId"
				t-id="chat-navigation-direct-messages"
				class="direct-message-item"
				[ngClass]="{
          'has-unread-messages': directChatsItem.numberOfUnreadMessages > 0,
          'd-none': directChatsItem?.chatIsHidden && !directChatsItem?.isShown
        }"
			>
				<ng-container
					[ngTemplateOutlet]="directsMenuItemTemplate"
					[ngTemplateOutletContext]="{ $implicit: directChatsItem }"
				></ng-container>
			</li>
		</ul>

		<div *ngIf="!toggleDirects" class="divider"></div>
	</ng-scrollbar>
</div>
<!-- Chats Sidebar Content Ends -->

<!-- Spaces items template -->
<ng-template #spacesMenuItemTemplate let-menuItem let-withDnd="withDnd" let-isUnreadList="isUnreadList">
	<div
		id="space-{{ menuItem.id }}"
    *transloco="let t; read: 'full-layout'"
		class="menu-title-wrap chat-menu-item"
		[ngClass]="{
      'chat-menu-item-disable': (isLimited && !menuItem.isInFreeTier && isFreePlan) || !menuItem.chatItem,
      'chat-menu-item-active': !!menuItem.chatItem && menuItem.chatItem._id === activeChatId,
      'chat-menu-item-muted': menuItem.chatItem?.notifyFilter === 'NOTHING',
      'has-unread-messages': isBoldChannel(menuItem.chatItem)
    }"
	>
		<div class="open-chat">
			<div class="d-flex align-items-center w-100 pr-1 chat-menu-item-body" [class.space-dnd]="withDnd">
				<a
					[routerLink]="getIsRouteAccess(menuItem) ? ('chat/' + menuItem.chatItem?._id | routerTenant) : null"
					[ngClass]="{
          'chat-menu-title-disable': (isLimited && !menuItem.isInFreeTier && isFreePlan) || !menuItem.chatItem,
          'has-unread-messages-color': isBoldChannel(menuItem.chatItem),
          'space-dnd': withDnd,
          }"
					class="menu-title flex-grow-1"
					(click)="clearSearchChat()"
				>
					<app-space-avatar
            [space]="menuItem"
            class="space-avatar mr-1"
            [containerHeight]="24"
            [containerWidth]="24"
          ></app-space-avatar>
					<span [class.space-dnd]="withDnd" [class.font-bold]="isBoldChannel(menuItem.chatItem)">{{ menuItem.spaceName }}</span>
				</a>

        <svg-icon
          *ngIf="(screenRecord.recordObjectId | async) === menuItem.chatItem?._id"
          src="assets/icons/common/record.svg"
          class="chat-record"
          [applyClass]="true"
          aria-hidden="true"
        ></svg-icon>

        <ng-container *ngIf="isUnreadList">
          <span *ngIf="isNotificationAllow(menuItem.chatItem, true) && !!getLengthUnreadMentionMessage(menuItem.chatItem)" class="primary-circle" [class.space-dnd]="withDnd">
             <svg-icon
               src="assets/icons/common/mention.svg"
               aria-hidden="true"
             ></svg-icon>
          </span>

          <span *ngIf="isNotificationAllow(menuItem.chatItem, true) && !!getLengthUnreadThreadMessage(menuItem.chatItem)" class="primary-circle primary-circle-white" [class.space-dnd]="withDnd">
            <svg-icon
              src="assets/icons/common/thread.svg"
              aria-hidden="true"
            ></svg-icon>
          </span>

          <span *ngIf="isNotificationAllow(menuItem.chatItem, true) && !!getLengthUnreadAllMessage(menuItem.chatItem)" class="primary-circle primary-circle-grey" [class.space-dnd]="withDnd">
            {{ getUnreadAllMessageCount(menuItem.chatItem) }}
          </span>
        </ng-container>
          <span *ngIf="getIsMenuAccess(menuItem, isUnreadList)" class="menu-icons spaces-menu-icons d-flex align-items-center justify-content-end">
          <span *ngIf="!!menuItem.chatItem" class="d-flex" [style.gap.px]="6">
            <svg-icon
              *ngIf="'spaces::' + menuItem._id + '::calendarEventGetList' | checkPermission"
              [routerLink]="'space/' + menuItem._id + '/calendar' | routerTenant"
              [ngbTooltip]="t('chat-navigation.calendar-tooltip')"
              placement="auto"
              src="assets/icons/common/calendar.svg"
              class="svg-icon"
              [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
            ></svg-icon>
            <svg-icon
              *ngIf="'spaces::' + menuItem._id + '::ticketsGetList' | checkPermission"
              [routerLink]="'space/' + menuItem._id + '/board/kanban' | routerTenant"
              [ngbTooltip]="t('chat-navigation.board-tooltip')"
              placement="auto"
              src="assets/icons/common/board.svg"
              class="svg-icon"
              [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
              (click)="redirectToActiveSprint()"
            ></svg-icon>
            <svg-icon
              *ngIf="'spaces::' + menuItem._id + '::filesGet' | checkPermission"
              [routerLink]="'space/' + menuItem._id + '/documents' | routerTenant"
              [ngbTooltip]="t('chat-navigation.dataroom-tooltip')"
              placement="auto"
              src="assets/icons/common/data-room.svg"
              class="svg-icon"
              [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
            ></svg-icon>
          </span>

          <span class="btn-tooltip" [ngbTooltip]="t('actions-tooltip')" placement="auto">
            <div
              t-id="chat-navigation-tree-dot-menu"
              class="btn dropdown d-flex align-items-center"
              appTippy
              [popoverContent]="dropdownSpace"
              [popoverPlacement]="'bottom-end'"
            >
              <svg-icon
                src="assets/icons/common/ellipsis.svg"
                class="svg-icon"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': '17', 'height.px': '18' }"
              ></svg-icon>
            </div>
            <div class="chat-dropdown" [class.dark]="isDark$ | async" #dropdownSpace>
              <a href="javascript:;" class="dropdown-item" (click)="spaceService.updateClick(menuItem._id)"
                 *ngIf="'spaces::' + menuItem._id + '::spacesUpdate' | checkPermission"
                 t-id="chat-navigation-edit-space">
                  <svg-icon
                    src="assets/icons/common/edit.svg"
                    class="svg-icon mr-2 not-hover"
                    [svgStyle]="{ 'width.px': '20', 'height.px': '15' }"
                    aria-hidden="true"
                  ></svg-icon>
                  <span>{{ t('chat-navigation.btn-edit-space') }}</span>
                </a>
                <a href="javascript:;" class="dropdown-item" (click)="openInviteMemberModal('spaces', menuItem._id)"
                   *ngIf="('spaces::' + menuItem._id + '::spacesInvite' | checkPermission) && !menuItem.isPersonal"
                   t-id="chat-navigation-invite-member-space">
                  <svg-icon
                    src="assets/icons/common/invite-member.svg"
                    class="svg-icon mr-2 not-hover"
                    [svgStyle]="{ 'width.px': '20', 'height.px': '15' }"
                    aria-hidden="true"
                  ></svg-icon>
                  <span>{{ t('chat-navigation.btn-invite-members') }}</span>
                </a>
                <a href="javascript:;" *ngIf="('spaces::' + menuItem._id + '::spacesToggleArchiveStatus' | checkPermission) && !menuItem.isPersonal" class="dropdown-item" (click)="handlerArchiveSpaceModal(menuItem)">
                  <svg-icon
                    src="assets/icons/common/archive.svg"
                    class="svg-icon mr-2 not-hover"
                    [svgStyle]="{ 'width.px': '20', 'height.px': '14' }"
                    aria-hidden="true"
                  ></svg-icon>
                  <span>{{ t('chat-navigation.btn-archive-space') }}</span>
                </a>
                <a href="javascript:;" class="dropdown-item" (click)="projectService.createClick(menuItem)"
                   *ngIf="('spaces::' + menuItem._id + '::projectCreate' | checkPermission) && !menuItem.isPersonal"
                   t-id="chat-navigation-create-project">
                  <svg-icon
                    src="assets/icons/common/folder-tree.svg"
                    class="svg-icon mr-2 not-hover"
                    [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
                    aria-hidden="true"
                  ></svg-icon>
                  <span>{{ t('chat-navigation.btn-add-project') }}</span>
                </a>
                <a href="javascript:;" class="dropdown-item" *ngIf="!menuItem.isPersonal" (click)="spaceService.chatGroupCreate(menuItem, platform)"
                   t-id="chat-navigation-create-chat-group">
                  <svg-icon
                    src="assets/icons/common/group-chat.svg"
                    class="svg-icon mr-2 not-hover"
                    [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
                    aria-hidden="true"
                  ></svg-icon>
                  <span>{{ t('chat-navigation.btn-add-group-chat') }}</span>
                </a>
            </div>
          </span>
        </span>

				<a
          *ngIf="!isUnreadList && !menuItem.isPersonal"
					href="javascript:;"
					class="d-flex mr-1"
					[ngbTooltip]="spaceService.getContentVisibility(menuItem) ? t('chat-navigation.collapse-tooltip') : t('chat-navigation.expand-tooltip')"
					placement="auto"
					(click)="spaceService.toggleContentVisibility(menuItem)"
				>
          <span class="arrow-wrapper p-1 mx-1">
            <svg-icon
              src="assets/icons/common/{{ spaceService.getContentVisibility(menuItem) ? 'arrow-up' : 'arrow--down'}}.svg"
              [applyClass]="true"
              class="svg-icon"
              aria-hidden="true"
            ></svg-icon>
          </span>
				</a>
			</div>
		</div>
	</div>
</ng-template>

<!-- Projects items template -->
<ng-template #chatsMenuItemTemplate let-menuItem let-isUnreadList="isUnreadList" let-hasGroupChats="hasGroupChats" let-isProject="isProject">
	<div
		class="menu-title-wrap sub-menu"
    *transloco="let t; read: 'full-layout'"
		[ngClass]="{
      'chat-menu-item-active': activeChatId === menuItem.chatId,
      'chat-menu-item-muted': menuItem.notifyFilter === 'NOTHING',
      'chat-menu-item-disable': isLimited && !menuItem.isInFreeTier && isFreePlan,
    }"
	>
		<div class="open-sub-chat">
			<div class="d-flex align-items-center w-100 pr-1 chat-menu-item-body" [class.p-l-10]="!isUnreadList">
				<div *ngIf="!isUnreadList" class="tree-parent" [class.has-group-chats]="hasGroupChats && isProject">
					<div class="tree-child-one"></div>
					<div class="tree-child-two"></div>
				</div>
				<a
					[routerLink]="getIsRouteProjectAccess(menuItem) ? ('chat/' + menuItem.chatId | routerTenant) : null"
					[class.has-unread-messages-color]="isBoldChannel(menuItem)"
          [class.chat-menu-title-disable]=" isLimited && !menuItem.isInFreeTier && isFreePlan"
					[class.font-bold]="isBoldChannel(menuItem)"
					class="menu-title-sub-menu-desktop flex-grow-1"
					(click)="clearSearchChat()"
				>
					<app-space-avatar
            *ngIf="isUnreadList && menuItem.space"
            [space]="menuItem.space"
            class="space-avatar mr-1"
            [containerHeight]="24"
            [containerWidth]="24"
          >
					</app-space-avatar>
					<app-project-avatar
            [project]="menuItem"
            class="project-avatar mr-1"
            [containerHeight]="24"
            [containerWidth]="24"
          ></app-project-avatar>

					<span [class.font-bold]="isBoldChannel(menuItem)">{{ menuItem.title }}</span>
				</a>

        <svg-icon
          *ngIf="(screenRecord.recordObjectId | async) === menuItem.chatId"
          src="assets/icons/common/record.svg"
          class="chat-record"
          [applyClass]="true"
          aria-hidden="true"
        ></svg-icon>

        <ng-container *ngIf="isUnreadList">
          <span *ngIf="isNotificationAllow(menuItem, true) && !!getLengthUnreadMentionMessage(menuItem)" class="primary-circle">
             <svg-icon
               src="assets/icons/common/mention.svg"
               aria-hidden="true"
             ></svg-icon>
          </span>

          <span *ngIf="isNotificationAllow(menuItem, true) && !!getLengthUnreadThreadMessage(menuItem)" class="primary-circle primary-circle-white">
            <svg-icon
              src="assets/icons/common/thread.svg"
              aria-hidden="true"
            ></svg-icon>
          </span>

          <span *ngIf="isNotificationAllow(menuItem, true) && !!getLengthUnreadAllMessage(menuItem)" class="primary-circle primary-circle-grey">
          {{ getUnreadAllMessageCount(menuItem) }}
        </span>
        </ng-container>

				<span *ngIf="getIsMenuAccess(menuItem, isUnreadList)" class="menu-icons projects-menu-icons d-flex align-items-center justify-content-end">
          <svg-icon
            *ngIf="'projects::' + menuItem.objectId + '::calendarEventGetList' | checkPermission"
            [routerLink]="'project/' + menuItem.objectId + '/calendar' | routerTenant"
            [ngbTooltip]="t('chat-navigation.calendar-tooltip')"
            placement="auto"
            src="assets/icons/common/calendar.svg"
            class="svg-icon"
            [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
          ></svg-icon>
          <svg-icon
            *ngIf="'projects::' + menuItem.objectId + '::ticketsGetList' | checkPermission"
            [routerLink]="'project/' + menuItem.objectId + '/board/kanban' | routerTenant"
            [ngbTooltip]="t('chat-navigation.board-tooltip')"
            placement="auto"
            src="assets/icons/common/board.svg"
            class="svg-icon"
            [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
            (click)="redirectToActiveSprint()"
          ></svg-icon>
          <svg-icon
            *ngIf="'projects::' + menuItem.objectId + '::filesGet' | checkPermission"
            [routerLink]="'project/' + menuItem.objectId + '/documents' | routerTenant"
            [ngbTooltip]="t('chat-navigation.dataroom-tooltip')"
            placement="auto"
            src="assets/icons/common/data-room.svg"
            class="svg-icon"
            [svgStyle]="{ 'width.px': '18', 'height.px': '18' }"
          ></svg-icon>

          <span class="btn-tooltip mr-1" [ngbTooltip]="t('actions-tooltip')" placement="auto"
                [disableTooltip]="actionsDropdownOpen">
            <div
              t-id="chat-navigation-tree-dot-menu"
              class="btn dropdown d-flex align-items-center"
              appTippy
              [popoverContent]="dropdownProject"
              [popoverPlacement]="'bottom-end'"
            >
              <svg-icon
                src="assets/icons/common/ellipsis.svg"
                class="svg-icon"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': '17', 'height.px': '18' }"
              ></svg-icon>
            </div>
            <div class="chat-dropdown" [class.dark]="isDark$ | async" #dropdownProject>
              <a
                href="javascript:;"
                class="dropdown-item"
                (click)="projectService.updateClick(menuItem.objectId)"
                *ngIf="'projects::' + menuItem.objectId + '::projectUpdate' | checkPermission"
                t-id="chat-navigation-edit-project"
              >
                <svg-icon
                  src="assets/icons/common/edit.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '15' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-edit-project') }}</span>
              </a>
              <a href="javascript:;" class="dropdown-item"
                 (click)="openInviteMemberModal('projects', menuItem.objectId)"
                 *ngIf="'projects::' + menuItem.objectId + '::projectInvite' | checkPermission"
                 t-id="chat-navigation-invite-member-project">
                <svg-icon
                  src="assets/icons/common/invite-member.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '15' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-invite-members') }}</span>
              </a>
              <a href="javascript:;" *ngIf="'projects::' + menuItem.objectId + '::projectToggleArchiveStatus' | checkPermission" class="dropdown-item" (click)="handlerArchiveProjectModal(menuItem)">
                <svg-icon
                  src="assets/icons/common/archive.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '14' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-archive-project') }}</span>
              </a>
              <a href="javascript:;" class="dropdown-item dropdown-item-disabled"
                *ngIf="!('projects::' + menuItem.objectId + '::projectToggleArchiveStatus' | checkPermission)"
                t-id="chat-navigation-delete-project"
              >
                <span>{{ t('chat-navigation.no-actions-available') }}</span>
              </a>
            </div>
          </span>
        </span>
			</div>
		</div>
	</div>
</ng-template>

<!-- Group Chats items template -->
<ng-template #groupsMenuItemTemplate let-groupChatsItem let-isUnreadList="isUnreadList" let-spaceId="spaceId">
	<div
		class="menu-title-wrap sub-menu group-chat"
    *transloco="let t; read: 'full-layout'"
		[ngClass]="{
      'chat-menu-item-active': activeChatId === groupChatsItem.chatId,
      'chat-menu-item-disable': isLimited && !groupChatsItem.isInFreeTier && isFreePlan
    }"
	>
		<div class="open-sub-chat d-flex align-items-center pr-1">
			<a
				[routerLink]="getIsRouteProjectAccess(groupChatsItem) ? ('chat/' + groupChatsItem.chatId | routerTenant) : null"
				[class.has-unread-messages-color]="groupChatsItem.numberOfUnreadMessages > 0"
        [class.chat-menu-title-disable]="isLimited && !groupChatsItem.isInFreeTier && isFreePlan"
				[class.p-l-10]="!isUnreadList"
				class="chat-menu-item-body menu-title-sub-menu-desktop d-flex align-items-center w-100"
				(click)="clearSearchChat()"
			>
				<div *ngIf="!isUnreadList" class="tree-parent">
					<div class="tree-child-one"></div>
					<div class="tree-child-two"></div>
				</div>

				<app-space-avatar
					*ngIf="isUnreadList && groupChatsItem.space"
					[space]="groupChatsItem.space"
					class="space-avatar mr-1"
          [containerHeight]="24"
          [containerWidth]="24"
				></app-space-avatar>

				<app-group-avatar></app-group-avatar>

				<span class="flex-grow-1" [class.font-bold]="!!getLengthUnreadAllMessage(groupChatsItem)">
          {{ groupChatsItem.title }}
        </span>

        <svg-icon
          *ngIf="(screenRecord.recordObjectId | async) === groupChatsItem.chatId"
          src="assets/icons/common/record.svg"
          class="chat-record"
          [applyClass]="true"
          aria-hidden="true"
        ></svg-icon>

        <ng-container *ngIf="isUnreadList">
          <span *ngIf="!!getLengthUnreadMentionMessage(groupChatsItem)" class="primary-circle">
             <svg-icon
               src="assets/icons/common/mention.svg"
               aria-hidden="true"
             ></svg-icon>
          </span>

          <span *ngIf="!!groupChatsItem.chatThreadsTotalUnreadMessages" class="primary-circle primary-circle-white">
            <svg-icon
              src="assets/icons/common/thread.svg"
              aria-hidden="true"
            ></svg-icon>
          </span>

          <span *ngIf="!!getLengthUnreadAllMessage(groupChatsItem)" class="primary-circle primary-circle-grey">
          {{ getUnreadAllMessageCount(groupChatsItem) }}
        </span>
        </ng-container>
			</a>

			<span *ngIf="getIsMenuAccess(groupChatsItem, isUnreadList)" class="menu-icons">
        <span class="btn-tooltip mr-1" [ngbTooltip]="t('actions-tooltip')" placement="top" container="auto">
          <div
            t-id="chat-navigation-tree-dot-menu"
            class="btn dropdown d-flex align-items-center"
            appTippy
            [popoverContent]="dropdownGroupChat"
            [popoverPlacement]="'bottom-end'"
          >
            <svg-icon
              src="assets/icons/common/ellipsis.svg"
              class="dropdown-toggle cursor-pointer svg-icon"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': '17', 'height.px': '18' }"
            ></svg-icon>
          </div>
          <div class="chat-dropdown" [class.dark]="isDark$ | async" #dropdownGroupChat>
            <a
              t-id="chat-navigation-add-member-group"
              href="javascript:;"
              class="dropdown-item"
              (click)="spaceService.manageGroupChatMembers(groupChatsItem.chatId, groupChatsItem.objectId)"
            >
                <svg-icon
                  src="assets/icons/common/user-group.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-manage-members') }}</span>
              </a>
              <a *ngIf="'spaces::' + groupChatsItem.objectId + '::webhookCreate' | checkPermission"
                 href="javascript:;"
                 class="dropdown-item"
                 (click)="spaceService.manageGroupChatIntegrations(groupChatsItem.chatId, spaceId, groupChatsItem?.title)"
              >
                <svg-icon
                  src="assets/icons/common/plus.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-manage-integrations') }}</span>
              </a>
              <a
                *ngIf="groupChatsItem.isOwner"
                t-id="chat-navigation-delete-group"
                href="javascript:;"
                class="dropdown-item"
                (click)="spaceService.deleteGroupChat(groupChatsItem.chatId)"
              >
                <svg-icon
                  src="assets/icons/common/trash.svg"
                  class="svg-icon mr-2 not-hover"
                  [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
                  aria-hidden="true"
                ></svg-icon>
                <span>{{ t('chat-navigation.btn-delete-group-chat') }}</span>
              </a>
          </div>
        </span>
      </span>
		</div>
	</div>
</ng-template>

<!-- Direct Chats items template -->
<ng-template #directsMenuItemTemplate let-directChatsItem>
	<div
		class="menu-title-wrap sub-menu d-flex align-items-center pr-1"
    *transloco="let t; read: 'full-layout'"
		[ngClass]="{
      'chat-menu-item-active': activeChatId === directChatsItem.chatId
    }"
	>
		<a class="open-chat" (click)="openDirectChat(directChatsItem)">
      <span class="d-flex align-items-center justify-content-between w-100 pl-1 user-wrapper">
        <app-avatar [userId]="directChatsItem.userId" [width]="24" [height]="24" [statusSize]="9"
                    [notShowProfile]="true"></app-avatar>

        <span
	        class="username-chat-list flex-grow-1 ml-1"
	        [class.has-unread-messages-color]="directChatsItem.numberOfUnreadMessages > 0"
	        [class.font-bold]="directChatsItem.numberOfUnreadMessages > 0"
        >
          {{ directChatsItem.title }}
        </span>

        <svg-icon
          *ngIf="(screenRecord.recordObjectId | async) === directChatsItem.chatId"
          src="assets/icons/common/record.svg"
          class="chat-record"
          [applyClass]="true"
          aria-hidden="true"
        ></svg-icon>


        <span *ngIf="!!getLengthUnreadAllMessage(directChatsItem)" class="primary-circle primary-circle-direct">
          {{ getLengthUnreadAllMessage(directChatsItem) > 9 ? "9+" : getLengthUnreadAllMessage(directChatsItem) }}
        </span>
      </span>
		</a>

		<span class="menu-icons direct-chat-menu-icons d-flex align-items-center">
      <span
	      (click)="hideDirectChat(directChatsItem.chatId)"
	      class="hide-direct-chat font-medium-3 px-1 mr-1"
	      t-id="chat-navigation-hide-DM-chat"
	      aria-hidden="true"
	      [ngbTooltip]="t('chat-navigation.hide-DM-chat-tooltip')"
	      placement="auto"
        [autoClose]="true"
      >
        <svg-icon
          src="assets/icons/common/cross.svg"
          aria-hidden="true"
          class="svg-icon cursor-pointer"
          [applyClass]="true"
          [svgStyle]="{ 'width.px': '10', 'height.px': '10' }"
        ></svg-icon>
      </span>
    </span>
	</div>
</ng-template>
