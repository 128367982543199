import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SprintModal, SprintsStateModel } from '../models/SprintState';
import { BoardsSprintsService } from '../../../api/services/boards-sprints.service';
import { tap } from 'rxjs/operators';
import {
  SprintCreate,
  SprintDelete,
  SprintGet,
  SprintSocketComplete,
  SprintSocketCreate,
  SprintSocketUpdate,
  SprintUpdate,
} from '../actions/sprint.action';
import { SprintsDbDto } from '../../../api/models/sprints-db-dto';
import { patch, updateItem } from '@ngxs/store/operators';

@State<SprintsStateModel>({
  name: 'Sprints',
  defaults: {
    sprints: [],
  },
})
@Injectable()
export class SprintsState {
  /**
   * Get sprints
   * @param  {SprintsStateModel} state
   */
  @Selector()
  static getSprints(state: SprintsStateModel): SprintsStateModel['sprints'] {
    return state.sprints;
  }

  /**
   * Get active sprint
   * @param  {SprintsStateModel} state
   */
  @Selector()
  static getActiveSprint(state: SprintsStateModel): SprintsDbDto | SprintModal | undefined {
    return state.sprints.find((sprint) => sprint?.isActive);
  }

  /**
   * Get sprint by id
   * @param  {SprintsStateModel} state
   */
  @Selector()
  static getSprintById(state: SprintsStateModel, id: string) {
    return state.sprints.find((sprint) => sprint._id === id);
  }

  constructor(private sprintBoardsService: BoardsSprintsService) {}

  /**
   * Get sprint request
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintGet} action
   */
  @Action(SprintGet)
  get_sprint({ patchState }: StateContext<SprintsStateModel>, action: SprintGet) {
    return this.sprintBoardsService.sprintsGetList(action.payload).pipe(
      tap((sprints) => {
        patchState({ sprints });
      }),
    );
  }

  /**
   * Create sprint request
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintCreate} action
   */
  @Action(SprintCreate)
  create_sprint({ patchState, getState }: StateContext<SprintsStateModel>, action: SprintCreate) {
    const state = getState();

    return this.sprintBoardsService.sprintsCreate({ body: action.payload }).pipe(
      tap((sprint) => {
        patchState({ sprints: [...state.sprints, sprint] });
      }),
    );
  }

  /**
   * Sprint socket create
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintSocketCreate} action
   */
  @Action(SprintSocketCreate)
  socket_create_sprint({ patchState, getState }: StateContext<SprintsStateModel>, { payload }: SprintSocketCreate) {
    const state = getState();

    patchState({ sprints: [...state.sprints, payload] });
  }

  /**
   * Update sprint request
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintUpdate} action
   */
  @Action(SprintUpdate)
  update_sprint({ setState, patchState, getState }: StateContext<SprintsStateModel>, action: SprintUpdate) {
    const { end, start, order, title } = action.payload.body;

    setState(
      patch({
        sprints: updateItem<SprintsDbDto | SprintModal>(
          (s) => s._id === action.payload.id,
          patch<SprintsDbDto | SprintModal>({ ...action.payload.body }),
        ),
      }),
    );
    return this.sprintBoardsService.sprintsUpdate(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          const { sprints } = getState();

          patchState({
            sprints,
          });
          throw err.error;
        },
      ),
    );
  }

  /**
   * Sprint socket update
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintSocketUpdate} action
   */
  @Action(SprintSocketUpdate)
  socket_update_sprint({ patchState, getState }: StateContext<SprintsStateModel>, { payload }: SprintSocketUpdate) {
    const state = getState();

    const updatedSprints = state.sprints.map((sprint) => (sprint._id === payload._id ? payload : sprint));
    patchState({ sprints: updatedSprints as SprintModal[] });
  }

  /**
   * Update sprint request
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintDelete} action
   */
  @Action(SprintDelete)
  delete_sprint({ patchState, getState }: StateContext<SprintsStateModel>, action: SprintDelete) {
    const state = getState();

    return this.sprintBoardsService.sprintsDelete(action.payload).pipe(
      tap((deletedSprint) => {
        patchState({ sprints: state.sprints.filter((sprint) => sprint._id !== deletedSprint._id) });
      }),
    );
  }

  /**
   * Sprint socket complete
   * @param  {patchState}: StateContext<SprintsStateModel>
   * @param  {SprintSocketComplete} action
   */
  @Action(SprintSocketComplete)
  socket_complete_sprint({ patchState, getState }: StateContext<SprintsStateModel>, { payload }: SprintSocketComplete) {
    const state = getState();

    patchState({ sprints: state.sprints.filter((sprint) => sprint._id !== payload._id) });
  }
}
