import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BitbucketPRMessageData,
  BitbucketPushMessageCommit,
  BitbucketPushMessageData,
  BitbucketJobStatusData,
  WebhookMessageData,
} from '../../../../../interfaces/webhook-message-data';

enum BitbucketEventTypes {
  REPO_PUSH = 'repo:push',
  PR_CREATED = 'pullrequest:created',
  PR_MERGED = 'pullrequest:fulfilled',
  PR_REJECTED = 'pullrequest:rejected',
  REPO_COMMIT_STATUS_CREATED = 'repo:commit_status_created',
  REPO_COMMIT_STATUS_UPDATED = 'repo:commit_status_updated',
}

@Component({
  selector: 'app-webhook-bitbucket-message',
  templateUrl: './webhook-bitbucket-message.component.html',
  styleUrls: ['./webhook-bitbucket-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookBitbucketMessageComponent {
  @Input() webhookMessageData: WebhookMessageData;

  @Input() layoutVariant: string;

  public BitbucketEventTypes = BitbucketEventTypes;

  public get pushMessageData(): BitbucketPushMessageData {
    return this.webhookMessageData?.data as BitbucketPushMessageData;
  }

  public get prMessageData(): BitbucketPRMessageData {
    return this.webhookMessageData?.data as BitbucketPRMessageData;
  }

  public get commitJobStatusData(): BitbucketJobStatusData {
    return this.webhookMessageData?.data as BitbucketJobStatusData;
  }

  public get statusLabel(): string {
    switch (this.commitJobStatusData?.commit_status?.state) {
      case 'INPROGRESS':
        return 'IN PROGRESS';
      case 'FAILED':
        return 'FAILED';
      case 'SUCCESSFUL':
        return 'SUCCESS';
      default:
        return this.commitJobStatusData?.commit_status?.state;
    }
  }

  public get isJobFailed(): boolean {
    return this.commitJobStatusData?.commit_status?.state === 'FAILED';
  }

  public get branchFullPath(): string {
    return `${this.pushMessageData?.repository?.full_name}/${
      this.headCommit?.hash
        ? this.pushMessageData?.push?.changes[0]?.new?.name
        : this.pushMessageData?.push?.changes[0]?.old?.name
    }`;
  }

  public get prActionTitle(): string {
    let action = '';
    if (this.webhookMessageData?.eventName === this.BitbucketEventTypes.PR_CREATED) {
      action = 'opened';
    } else if (this.webhookMessageData?.eventName === this.BitbucketEventTypes.PR_REJECTED) {
      action = 'canceled';
    } else if (this.webhookMessageData?.eventName === this.BitbucketEventTypes.PR_MERGED) {
      action = 'merged';
    } else {
      action = this.webhookMessageData?.eventName;
    }
    return `Pull request ${action} by`;
  }

  public get showPRDetails(): boolean {
    return this.webhookMessageData?.eventName === this.BitbucketEventTypes.PR_CREATED;
  }

  public get reviewers(): { name: string }[] {
    return this.prMessageData?.pullrequest?.reviewers?.map((r) => {
      return { name: r.display_name };
    });
  }

  public get headCommit(): BitbucketPushMessageCommit {
    if (
      this.pushMessageData?.push?.changes?.length > 0 &&
      this.pushMessageData?.push?.changes[0]?.commits?.length > 0
    ) {
      return this.pushMessageData?.push?.changes[0]?.commits[0];
    }
    return null;
  }

  public get pushMsgDescription(): string {
    if (this.headCommit?.hash) {
      return this.pushMessageData?.push?.changes[0]?.new?.target?.message;
    }
    return `Branch ${this.branchFullPath} was deleted.`;
  }
}
