import { Pipe, PipeTransform } from '@angular/core';
import { PinType } from '../components/chat/chat-pinned-messages/enums/pin-type.enum';

@Pipe({
  name: 'checkIsPin',
})
export class CheckIsPinPipe implements PipeTransform {
  transform<T>(item, pinArray, type) {
    if (type === PinType.Message) {
      return pinArray.some((msg) => msg.linkDocument && msg.linkMessage?._id && msg.linkMessage._id === item._id);
    }
    return false;
  }
}
