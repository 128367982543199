import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { JitsiSocketEventsHandler } from '../data/video-call-types';
import { SpacesDbDto } from '../../api/models/spaces-db-dto';
import { ProjectsDbDto } from '../../api/models/projects-db-dto';
import { ThreadSocketData } from '../interfaces/thread-socket-data';
import { UserStatusTypes } from '../data/user-status-types';
import { RouterTenantPipe } from '../pipes/router-tenant.pipe';
import { SocketsService } from '../services/sockets.service';
import { ConfigService } from '../services/config.service';
import { SentryIoService } from '../services/sentry-io.service';
import {
  ChatsClear,
  ChatsClearUploadFiles,
  ChatsEditGroupName,
  ChatsGet,
  ChatsGetMembers,
  ChatsGetMessages,
  ChatsInit,
  ChatsMarkAllAsRead,
  ChatsMarkAsRead,
  ChatsPrivateMessagesIsOpening,
  ChatsRemoveChatsMembers,
  ChatsSetChats,
  ChatsSetChatsMembers,
  ChatsSetCurrentChatPage,
  ChatsSetMessages,
  ChatsSetSearchResults,
  ChatsSocketDeletedMessage,
  ChatsSocketNewMessage,
  ChatsSocketUpdatedMessage,
  ChatsSocketUpdatedPoll,
  ChatsThreadsListMarkAllAsRead,
  ChatsUpdateChatsMembers,
  ChatsUpdateCounter,
  ChatsUpdateOrderSocketPin,
  ChatsUpdateSocketPin,
  ChatsUpdateThreadInList,
  ChatsUpdateThreadsList,
  SetOfflineStatus,
  SocketUpdateCalendarEvent,
  ThreadGetCounters,
  ThreadGetList,
  ThreadMarkAsRead,
  ThreadsMarkAsUnRead,
} from '../store/actions/chats.action';
import {
  ThreadsCloseSidebarOnDelete,
  ThreadsSocketNewMessage,
  ThreadsSocketUpdatedMessage,
  ThreadsClearData,
} from '../store/actions/threads.action';
import { SocketDeleteEmoji, SocketNewEmoji } from '../store/actions/emojis.action';
import { UpdateUserAvatar, UpdateUsersAfterChange } from '../store/actions/users.action';
import { SpacesGet, SpacesUpdateAfterChange, SpacesUpdateAfterDelete } from '../store/actions/spaces.action';
import { ProjectsGet, ProjectsUpdateAfterChange, ProjectsUpdateAfterDelete } from '../store/actions/projects.action';
import { NotificationsSetPlayRingtone } from '../store/actions/notifications.action';
import { VideoCallSetLastSessionId } from '../store/actions/video-calls.action';
import { TicketsMoveToArchive } from '../store/actions/board.action';
import { AuthByToken, TwoFaClearOption, TwoFaSetOption } from '../store/actions/auth.action';
import { AuthState } from '../store/states/auth.state';
import { ChatsState } from '../store/states/chats.state';
import { ThreadsState } from '../store/states/threads.state';
import { TfaGetOptionsResDto } from '../../api/models/tfa-get-options-res-dto';
import { ZoomService } from '../services/zoom.service';
import { FilterType } from './chat/chat-navigation/enums/filter.enum';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { TranslocoService } from '@ngneat/transloco';

interface IFilterChatIds {
  allChats: any[];
  filter: FilterType;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-listeners',
  styleUrls: [],
  template: '',
})
export class ListenersComponent implements OnInit, OnDestroy {
  userStatusTypes = UserStatusTypes;
  chats$: Subscription;
  mutedChats: string[] = [];

  space: SpacesDbDto = null;
  project: ProjectsDbDto;

  perPage = 20;
  level = 0;
  config: any = {};
  spaces: any[];

  protected innerWidth: any;
  activeChatId: string = null;
  chatsInitialized: string[] = [];

  backgroundMobile = true;
  mentionChats: string[] = [];
  noCallChats: string[] = [];

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.backgroundMobile = !document.hidden;
  }

  @HostListener('window:offline')
  statusOffline() {
    this.store.dispatch(new SetOfflineStatus(true));
  }

  @HostListener('window:resize')
  resizeWindow() {
    this.zoomService.emitZoom();
  }

  @HostListener('window:online')
  statusOnline() {
    this.store.dispatch(new SetOfflineStatus(false));
  }

  constructor(
    private store: Store,
    private actions: Actions,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private socketsService: SocketsService,
    private zoomService: ZoomService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private sentryService: SentryIoService,
    private readonly localStorageService: LocalStorageService,
    private translocoService: TranslocoService,
  ) {
    this.perPage = this.configService.MESSAGES_PER_PAGE;
  }

  ngOnInit() {
    const socket = this.socketsService.get();

    this.chats$ = this.store
      .select(ChatsState.getChats)
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.mutedChats = this.filterChatIds({ allChats: items, filter: FilterType.Nothing });
        this.mentionChats = this.filterChatIds({ allChats: items, filter: FilterType.Mention });
        this.noCallChats = this.filterChatIds({ allChats: items, filter: FilterType.NoCall });
        return items;
      });

    this.actions.pipe(untilDestroyed(this), ofActionDispatched(ChatsClear)).subscribe(() => {
      this.activeChatId = null;
    });

    this.actions.pipe(untilDestroyed(this), ofActionDispatched(ChatsInit)).subscribe((action) => {
      this.activeChatId = action.payload.chatId;

      if (this.activeChatId) {
        if (
          !this.chatsInitialized.includes(this.activeChatId) ||
          this.store.selectSnapshot(ChatsState.getMessages)(this.activeChatId) === undefined
        ) {
          this.store.dispatch(
            new ChatsGetMessages({
              chatId: this.activeChatId,
              page: 1,
              perPage: this.perPage,
            }),
          );

          this.chatsInitialized.push(this.activeChatId);
        }
        this.store.dispatch(new ChatsSetMessages({ chatId: this.activeChatId }));
      }
    });

    this.store.dispatch(new ChatsClear());
    this.store.dispatch(new ChatsGet());

    socket.on('chats:getChatsMessages:response', (e) => {
      if (e.data.query?.search) {
        this.store.dispatch(
          new ChatsSetSearchResults({
            chatId: e.data.chatId,
            messages: e.results,
            totalCount: e.totalCount,
            lastMessageId: e.data.lastMessageId,
            ignoreTotalCount: !!e.data._id,
            isClear: false,
            isSearch: true,
          }),
        );
      } else {
        this.store.dispatch(
          new ChatsSetMessages({
            chatId: e.data.chatId,
            messages: e.results,
            totalCount: e.totalCount,
            lastMessageId: e.data.lastMessageId,
            ignoreTotalCount: !!e.data._id,
            isClear: false,
            isSearch: false,
            messageId: e.data?.messageId,
            currentPage: e.data?.query.page,
          }),
        );
      }
      this.sentryService.addBreadcrumb('Received messages for chat ' + e.data.chatId);
    });

    // socket.on('chats:getThreadMessages:response', (e) => {
    //   this.store.dispatch(
    //     new ThreadsSetMessages({
    //       threadId: e.data.threadId,
    //       lastMessageId: e.data.lastMessageId,
    //       messages: e.results,
    //       totalCount: e.totalCount,
    //     }),
    //   );
    //   this.sentryService.addBreadcrumb('Received thread messages for thread ' + e.data.threadId);
    // });

    socket.on('chats:update:response', (message) => {
      this.store.dispatch(new ChatsSocketUpdatedMessage({ message }));
    });

    socket.on('chats:delete:response', (e) => {
      this.store.dispatch(new ChatsGet());
      this.toastr.success(
        this.translocoService.translate('toastr.message-deleted-from-chat'),
        this.translocoService.translate('toastr.title-success'),
      );
      this.sentryService.addBreadcrumb(`Deleted message ${e._id} from chat ${e.chatId} and re-fetched chats`);
    });

    socket.on('notification:send:chatUpdatedMessage', (e) => {
      if (e.message.isDeleted) {
        this.store.dispatch(new ChatsUpdateCounter(e));
        this.store.dispatch(new ChatsSocketDeletedMessage({ message: e.message }));
      } else {
        this.store.dispatch(new ChatsSocketUpdatedMessage({ message: e.message }));
      }
      if (e.message.threadId) {
        this.store.dispatch(new ChatsUpdateThreadInList(e.message));
        if (e.message.isDeleted) {
          this.store.dispatch(new ThreadsCloseSidebarOnDelete());
          // this.store.dispatch(new ThreadGetCounters());
        }
      }
    });

    socket.on('notification:send:threadUpdatedMessage', (e) => {
      this.store.dispatch(new ThreadsSocketUpdatedMessage(e));
      this.store.dispatch(new ChatsSocketUpdatedMessage(e));
    });

    socket.on('notification:send:chatNewUser', (e) => {
      if (e.chatId) {
        this.store.dispatch(new ChatsGetMembers(e.chatId));
      }
    });

    socket.on('notification:send:threadNewMessage', (e: ThreadSocketData) => {
      const userId = this.store.selectSnapshot(AuthState.getUser)._id;

      this.store.dispatch([
        new ThreadsSocketNewMessage(e),
        new ChatsSocketUpdatedMessage({ message: e.message }),
        new ChatsUpdateThreadsList({ ...e, changeCounter: e.threadMessage.userId !== userId }),
      ]);
      this.threadNotificationHandler(e);

      this.sentryService.addBreadcrumb(
        `New thread message ${e.threadMessage._id} received for ${e.threadMessage.threadId} thread`,
      );
    });

    socket.on('chats:getDirectChatIdByUserId:response', (e) => {
      this.store.dispatch(new ChatsGet());
      this.store.dispatch(new ChatsPrivateMessagesIsOpening(false));
      this.router.navigate([this.routerTenantPipe.transform('chat/' + e.chatId)], { queryParams: { page: 'dm' } });
    });

    socket.on('chats:isHiddenToggle:response', (e) => {
      this.store.dispatch(new ChatsGet());
      const user = this.store.selectSnapshot(AuthState.getUser);
      if (this.activeChatId && this.activeChatId === e.chatId) {
        this.router.navigate([this.routerTenantPipe.transform('/dash/', user.tenantName)]);
      }
    });

    socket.on('notification:send:pinnedMessageUpdate', (res) => {
      this.store.dispatch(
        new ChatsUpdateSocketPin({ type: 'update', message: res.pinnedMessage ? res.pinnedMessage : res }),
      );
    });

    socket.on('notification:send:pinnedMessageOrderUpdate', (e) => {
      this.store.dispatch(new ChatsUpdateOrderSocketPin(e.pinnedMessages));
    });

    socket.on('notification:send:pinnedMessageDelete', (e) => {
      this.store.dispatch(new ChatsUpdateSocketPin({ type: 'delete', message: e.pinnedMessage }));
    });

    socket.on('notification:send:messagePollUpdate', (message) => {
      this.store.dispatch(new ChatsSocketUpdatedPoll({ message: message }));
    });

    socket.on('notification:send:chatNewChat', () => {
      this.store.dispatch(new ChatsGet());
    });

    socket.on('chats:getChats:response', (e) => {
      this.store.dispatch(new ChatsSetChats(e));
      this.sentryService.addBreadcrumb(`Chats fetched`);
    });

    socket.on('notification:send:chatNewMessage', (e) => {
      this.chatsMenuUpdate(e, socket);
      this.sentryService.addBreadcrumb(
        `New message ${e.message?._id} for ${e.chatId} was received, timestamp: ${e.timestamp}`,
      );
    });

    socket.on('chats:markAsRead:response', (e) => {
      this.store.dispatch(new ChatsMarkAsRead({ chatId: e.chatId, type: e.type }));
    });

    socket.on('chats:threadMarkAsRead:response', (e) => {
      this.store.dispatch(new ThreadMarkAsRead(e));
    });

    socket.on('chats:threadMarkAllAsRead:response', (e) => {
      this.store.dispatch(new ChatsThreadsListMarkAllAsRead(e));
    });

    socket.on('chats:markAllAsRead:response', (e) => {
      this.store.dispatch(new ChatsMarkAllAsRead(e));
    });

    socket.on('chats:deleteGroup:response', (e) => {
      if (e.isDeleted) {
        this.store.dispatch(new ChatsRemoveChatsMembers(e._id));
        this.store.dispatch(new ChatsGet());
        this.toastr.success(
          this.translocoService.translate('toastr.group-chat-deleted'),
          this.translocoService.translate('toastr.title-success'),
        );

        if (Capacitor.getPlatform() === 'web') {
          // there is no "space/${e.objectId}/projects" route on mobile
          this.router.navigate([this.routerTenantPipe.transform(`space/${e.objectId}/projects`)]);
        }
      } else {
        this.toastr.success(
          this.translocoService.translate('toastr.group-chat-not-deleted'),
          this.translocoService.translate('toastr.title-error'),
        );
      }
    });

    socket.on('chats:editGroupName:response', (data) => {
      this.store.dispatch(new ChatsEditGroupName(data));
    });

    socket.on('emojis:created', (message) => {
      this.store.dispatch(new SocketNewEmoji(message));
    });

    socket.on('emojis:deleted', (message) => {
      this.store.dispatch(new SocketDeleteEmoji(message));
    });

    // Reconnection happened - renew data
    socket.on('connect', () => {
      console.log('Socket reconnect');
      this.uploadDraftChatMessages();

      // for updating chat menu
      this.store.dispatch(new ChatsGet());

      // for updating current chat page number
      this.store.dispatch(new ChatsSetCurrentChatPage(1));

      // for updating thread list

      this.store.dispatch(
        new ThreadGetList({
          chatId: null,
          page: 1,
          perPage: this.configService.THREADS_PER_PAGE,
        }),
      );

      // for updating current chat messages list
      const lastMessageIds = this.store.selectSnapshot(ChatsState.getLoadedChats);
      if (lastMessageIds && this.activeChatId) {
        const chatIds = Object.keys(lastMessageIds);
        if (this.activeChatId && !chatIds.includes(this.activeChatId)) {
          chatIds.push(this.activeChatId);
        }

        // for (const chatId of chatIds) {
        const request = { chatId: this.activeChatId, page: 1, perPage: this.perPage, skipMarkAsReed: true };
        this.store
          .dispatch(new ChatsGetMessages(request))
          .pipe(untilDestroyed(this))
          .subscribe((store) => {
            const messages = store.Chats?.messages[request.chatId];
            const local: any = this.localStorageService.get('uploadMessage');

            if (local?.length && messages?.length) {
              const deleteLocalMessage = local.filter(
                (obj) => !messages.some((item) => item.timestamp === obj.timestamp && item._id),
              );
              this.localStorageService.set('uploadMessage', deleteLocalMessage);
            }
          });

        this.store.dispatch(new ChatsGetMembers(this.activeChatId));
        // }
      }
      this.store.dispatch(new ThreadsClearData());
    });

    socket
      .fromEvent('notification:send:videoCallsWebhooksEvents')
      .pipe(untilDestroyed(this))
      .subscribe((hook: any) => {
        // hook: eventType: 'PARTICIPANT_JOINED | PARTICIPANT_LEFT', videoCallId, sessionId, userId
        this.store.dispatch(new JitsiSocketEventsHandler[hook.eventType](hook));
      });

    socket.on('videoCalls:sendAction:response', (res) => {
      // res: isHandled, videoCallId, sessionId
      if (res.isHandled) {
        this.store.dispatch(new VideoCallSetLastSessionId(res));
      }
    });

    socket.on('notification:send:usersUpdate', (res) => {
      if (res._id) {
        this.store.dispatch(new UpdateUsersAfterChange(res));
        this.store.dispatch(new ChatsUpdateChatsMembers(res));
        this.store.dispatch(new ChatsGet());
      }
    });
    socket.on('notification:send:usersAvatarUpdate', (res) => {
      if (res.url) {
        this.store.dispatch(new UpdateUserAvatar({ userId: res.objectId, avatarUrl: res.url }));
        this.store.dispatch(new ChatsGet());
      }
    });
    // socket.on('notification:send:usersAvatarDelete', (res) => {});

    /* PROJECTS LISTENERS */
    socket.on('notification:send:spacesUpdate', (res) => {
      if (res._id) {
        this.store.dispatch(new SpacesUpdateAfterChange(res));
      }
    });
    socket.on('notification:send:projectUpdate', (res) => {
      if (res._id) {
        this.store.dispatch(new ProjectsUpdateAfterChange(res));
        this.store.dispatch(new ChatsGet());
      }
    });

    socket.on('notification:send:spacesDelete', (res) => {
      if (res.spaceId) {
        this.store.dispatch(new SpacesUpdateAfterDelete(res));
      }
    });
    socket.on('notification:send:projectDelete', (res) => {
      if (res.projectId) {
        this.store.dispatch(new ProjectsUpdateAfterDelete(res));
        this.store.dispatch(new ChatsGet());
      }
    });

    socket.on('notification:send:spacesAvatarUpdate', (res) => {
      if (res._id) {
        this.store.dispatch(new SpacesUpdateAfterChange(res));
      }
    });
    socket.on('notification:send:projectAvatarUpdate', (res) => {
      if (res._id) {
        this.store.dispatch(new ProjectsUpdateAfterChange(res));
        this.store.dispatch(new ChatsGet());
      }
    });

    socket.on('notification:send:spacesAvatarDelete', (res) => {
      if (res.spaceId) {
        this.store.dispatch(new SpacesUpdateAfterChange({ _id: res.spaceId }));
      }
    });
    socket.on('notification:send:projectAvatarDelete', (res) => {
      if (res.projectId) {
        this.store.dispatch(new ProjectsUpdateAfterChange({ _id: res.projectId }));
        this.store.dispatch(new ChatsGet());
      }
    });

    socket.on('notification:send:ticketsToArchive', (e) => {
      this.store.dispatch(new TicketsMoveToArchive(e));
    });

    socket.on('notification:send:usersAssign', () => {
      const accessToken: string = this.localStorageService.get('accessToken');
      const refreshToken: string = this.localStorageService.get('refreshToken');
      const userId: string = this.localStorageService.get('userId');
      const timezone = moment.tz.guess();
      this.store.dispatch(new AuthByToken({ accessToken, refreshToken, userId, timezone }));
      this.store.dispatch(new ChatsGet());
      this.store.dispatch([SpacesGet, ProjectsGet]);
    });

    socket.on('notification:send:tfaOptionsCreated', (twoFaOptions: TfaGetOptionsResDto) => {
      this.store.dispatch(new TwoFaSetOption(twoFaOptions));
    });

    socket.on('notification:send:tfaOptionsDeleted', () => {
      this.store.dispatch(new TwoFaClearOption());
    });

    socket.on('notification:send:calendarEventUpdate', (e) => {
      this.store.dispatch(new SocketUpdateCalendarEvent(e));
    });

    socket.on('chats:chatMemberGetByChatId:response', (e) => {
      this.store.dispatch(new ChatsSetChatsMembers(e));
    });
  }

  ngOnDestroy() {
    const socket = this.socketsService.get();
    socket.removeListener('notification:send:usersUpdate');
    socket.removeListener('notification:send:usersAvatarUpdate');
    socket.removeListener('notification:send:chatNewChat');
    socket.removeListener('notification:send:chatNewUser');
    socket.removeListener('notification:send:chatNewMessage');
    socket.removeListener('notification:send:threadNewMessage');
    socket.removeListener('notification:send:chatUpdatedMessage');
    socket.removeListener('notification:send:threadUpdatedMessage');
    socket.removeListener('chats:getChats:response');
    socket.removeListener('chats:chatMemberGetByChatId:response');
    socket.removeListener('chats:getChatsMessages:response');
    socket.removeListener('chats:markAsRead:response');
    socket.removeListener('chats:threadMarkAsRead:response');
    socket.removeListener('chats:update:response');
    socket.removeListener('chats:delete:response');
    socket.removeListener('chats:getThreadMessages:response');
    socket.removeListener('chats:getDirectChatIdByUserId:response');
    socket.removeListener('chats:isHiddenToggle:response');
    socket.removeListener('chats:threadMarkAllAsRead:response');
    socket.removeListener('chats:markAllAsRead:response');
    socket.removeListener('chats:deleteGroup:response');
    socket.removeListener('chats:editGroupName:response');
    socket.removeListener('emojis:created');
    socket.removeListener('emojis:deleted');
    /* PROJECTS LISTENERS */
    socket.removeListener('notification:send:spacesUpdate');
    socket.removeListener('notification:send:projectUpdate');
    socket.removeListener('notification:send:spacesDelete');
    socket.removeListener('notification:send:projectDelete');
    socket.removeListener('notification:send:spacesAvatarUpdate');
    socket.removeListener('notification:send:projectAvatarUpdate');
    socket.removeListener('notification:send:spacesAvatarDelete');
    socket.removeListener('notification:send:projectAvatarDelete');
    socket.removeListener('notification:send:ticketsToArchive');
    socket.removeListener('notification:send:usersAssign');
    socket.removeListener('notification:send:tfaOptionsCreated');
    socket.removeListener('notification:send:tfaOptionsDeleted');
    socket.removeListener('notification:send:calendarEventUpdate');

    this.chats$.unsubscribe();
  }

  threadNotificationHandler(threadData): void {
    const user = this.store.selectSnapshot(AuthState.getUser);
    const { messageId } = this.store.selectSnapshot(ThreadsState.getCurrentThreadHeadMessage);

    if (messageId !== threadData.message?._id && user._id !== threadData.threadMessage?.userId) {
      this.store.dispatch(new ThreadsMarkAsUnRead(threadData));
      this.handleChatMessageSound(threadData, user);
    } else {
      if (
        threadData.threadMessage?.threadId &&
        user._id !== threadData.threadMessage?.userId &&
        this.backgroundMobile
      ) {
        this.socketsService.get().emit('chats:threadMarkAsRead', threadData.threadMessage.threadId);
      }
    }
  }

  chatsMenuUpdate(e, socket, isThread = false) {
    const user = this.store.selectSnapshot(AuthState.getUser);

    if (this.activeChatId === e.chatId && this.backgroundMobile) {
      socket.emit('chats:markAsRead', this.activeChatId);
      this.sentryService.addBreadcrumb(`Chat ${e.chatId} was marked as read automatically because it's active chat`);
    } else {
      this.handleChatMessageSound(e, user);
    }

    if (!!e.message.sharedMessage && user._id === e.message.userId) {
      this.toastr.success(
        this.translocoService.translate('toastr.message-has-been-shared'),
        this.translocoService.translate('toastr.title-success'),
      );
    }

    if (!isThread) {
      this.store.dispatch(
        new ChatsSocketNewMessage({ ...e, markUsUnRead: e.message.userId !== user._id || e.message?.isServiceMessage }),
      );
    }
  }

  // DOESNT WORK
  uploadDraftChatMessages(): void {
    this.store.dispatch(new ChatsClearUploadFiles());
  }

  filterChatIds({ allChats, filter }: IFilterChatIds): string[] {
    return (
      allChats?.reduce((acc, item) => {
        if (item.type === 'general' && item.notifyFilter && item.notifyFilter === filter) {
          return [...acc, item._id];
        }
        return acc;
      }, []) || []
    );
  }

  handleChatMessageSound(event, user: UsersPublicFieldsResDto): void {
    if (event.message.userId !== user._id || event.message?.isServiceMessage || event.threadMessage) {
      if (user.status === this.userStatusTypes.DO_NOT_DISTURB) {
        return;
      }

      if (this.mutedChats.includes(event.chatId)) {
        // when the chat option Notify me about - nothing
        return;
      }

      if (!event.hasMention && this.mentionChats.includes(event.chatId)) {
        // when the chat option Notify me about - only mentions and the message without mention
        return;
      }

      if (event.message?.linkObjectData?.videoCallId && this.noCallChats.includes(event.chatId)) {
        // when the chat option Notify me about - no calls and the message is service message with video call ignore it
        return;
      }

      this.store.dispatch(new NotificationsSetPlayRingtone({ needPlay: true, ringtone: 'notification' }));
    }
  }
}
