import { ChangeDetectorRef, Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { jitsiApp } from '../../shared/data/video-call-types';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { VideoCallsGetTokenResDto } from '../../api/models/video-calls-get-token-res-dto';
import { TauriService } from '../../shared/services/tauri.service';
import { VideoCallsService } from '../../api/services/video-calls.service';
import { VideoCallChangeIsOpened } from '../../shared/store/actions/video-calls.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { TenantsState } from '../../shared/store/states/tenants.state';
import { VideoCallsState } from '../../shared/store/states/video-calls.state';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';
import { LocalStorageService } from 'ngx-localstorage';

interface CallData {
  roomId: string;
  user: UsersPublicFieldsResDto;
}

@Component({
  selector: 'app-video-call-modal',
  templateUrl: './video-call-modal.component.html',
  styleUrls: ['./video-call-modal.component.scss'],
})
export class VideoCallModalComponent implements OnInit, AfterViewInit, OnDestroy {
  platform = 'web';
  destroy$ = new Subject<void>();
  options: any;
  api: any;
  isMinimized = false;
  tenantName: string;
  externalKey: string;
  isCallStarted = false;
  isCallFinished = false;
  callData: CallData;

  constructor(
    public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store,
    private videoCallService: VideoCallsService,
    private tauriService: TauriService,
    private toast: ToastrService,
    private activeModal: NgbActiveModal,
    private localStorage: LocalStorageService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.getPlatform();
    this.tenantName = this.store.selectSnapshot(TenantsState.getTenantName);
    this.externalKey = this.route.snapshot.queryParamMap.get('key');

    this.tauriService.on('videoCall', () => {
      const { setupScreenSharingRender } = window['jitsiNodeAPI'].jitsiMeetElectronUtils;
      setupScreenSharingRender(this.api);
    });
  }

  ngAfterViewInit() {
    this.initModal();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(new VideoCallChangeIsOpened({ isOpened: false }));
  }

  initModal(): void {
    if (!this.store.selectSnapshot(VideoCallsState.getIsOpened)) {
      if (this.externalKey) {
        const id = this.route.snapshot.paramMap.get('id');

        this.videoCallService
          .videoCallsGetExternalToken({ id, key: this.externalKey })
          .pipe(takeUntil(this.destroy$))
          .subscribe((jwt: VideoCallsGetTokenResDto) => {
            this.initJitsiConfigExternal(id, jwt);
            this.initJitsi();
          });
      } else {
        this.videoCallService
          .videoCallsGetToken({ id: this.callData.roomId })
          .pipe(takeUntil(this.destroy$))
          .subscribe((jwt: VideoCallsGetTokenResDto) => {
            this.initJitsiConfig(this.callData.user, this.callData.roomId, jwt);
            this.initJitsi();
          });
      }
      this.cdr.detectChanges();
    } else {
      this.toast.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }

  initJitsiConfig(userInfo: UsersPublicFieldsResDto, roomId: string, jwt: VideoCallsGetTokenResDto): void {
    const language = this.localStorage.get(LocalStorageKeys.language);
    this.options = {
      jwt: jwt.token,
      roomName: jitsiApp.id + roomId,
      parentNode: document.querySelector('#jitsi-iframe'),
      subject: this.tenantName,
      lang: language || 'en',
      userInfo: {
        displayName: userInfo?.userName,
      },
      configOverwrite: {
        localSubject: jwt.localSubject,
        brandingRoomAlias: roomId,
        inviteAppName: this.tenantName,
        roomPasswordNumberOfDigits: 10,
        disableProfile: true,
        disableInviteFunctions: true,
        disableModeratorIndicator: false,
        hiddenPremeetingButtons: ['invite'],
        prejoinConfig: {
          enabled: true,
          hideDisplayName: true,
        },
        remoteVideoMenu: {
          disableKick: false,
          disableGrantModerator: false,
        },
      },
    };
  }

  initJitsiConfigExternal(roomId: string, jwt: VideoCallsGetTokenResDto): void {
    const language = this.localStorage.get(LocalStorageKeys.language);
    this.options = {
      jwt: jwt.token,
      roomName: jitsiApp.id + roomId,
      parentNode: document.querySelector('#jitsi-iframe'),
      subject: this.tenantName,
      lang: language || 'en',
      userInfo: {
        displayName: 'External User',
      },
      configOverwrite: {
        localSubject: jwt.localSubject,
        brandingRoomAlias: roomId,
        inviteAppName: this.tenantName,
        roomPasswordNumberOfDigits: 10,
        disableProfile: !!!jwt.isTemporary,
        disableInviteFunctions: true,
        disableModeratorIndicator: false,
        hiddenPremeetingButtons: ['invite'],
        prejoinConfig: {
          enabled: true,
          hideDisplayName: !!!jwt.isTemporary,
        },
        remoteVideoMenu: {
          disableKick: false,
          disableGrantModerator: false,
        },
      },
    };
  }

  initJitsi(): void {
    // @ts-ignore
    this.api = new JitsiMeetExternalAPI(jitsiApp.domainWeb, this.options);
    this.isCallStarted = true;
    this.tauriService.transferData('videoCall');
    this.store.dispatch(new VideoCallChangeIsOpened({ isOpened: true }));

    this.api.addEventListener('readyToClose', () => {
      this.closeModal();
    });
  }

  closeModal(): void {
    this.api?.executeCommand('hangup');
    this.api?.dispose();
    this.activeModal.close();
    this.isMinimized = false;
    this.isCallFinished = true;
    this.cdr.detectChanges();
  }

  execute(command) {
    const jitsiIframe = document.getElementById('jitsi-iframe');
    const modalFrame = document.querySelector('.video-call-modal');
    if (command === 'minimize') {
      this.isMinimized = true;
      jitsiIframe.style.height = '300px';
      jitsiIframe.style.width = '300px';
      jitsiIframe.style.bottom = '10px';
      jitsiIframe.style.right = '10px';
      jitsiIframe.parentElement.style.height = '350px';
      jitsiIframe.parentElement.style.width = '300px';
      modalFrame.classList.add('video-call-modal-min');
    } else if (command === 'maximize') {
      this.isMinimized = false;
      jitsiIframe.style.height = 'calc(100vh - 60px)';
      jitsiIframe.style.width = 'calc(100vw - 40px)';
      jitsiIframe.style.bottom = '20px';
      jitsiIframe.style.right = '20px';
      jitsiIframe.parentElement.style.height = '100vh';
      jitsiIframe.parentElement.style.width = '100vw';
      modalFrame.classList.remove('video-call-modal-min');
    }
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  reloadPage() {
    window.location.reload();
  }
}
