<div class="d-flex align-items-center justify-content-between" [class.mobile-picker]="mobile">
  <div class="input-group date-input mr-2">
    <div class="input-group-prepend position-relative">
      <div class="input-group-text" (click)="onClicked(tabIndexes[0], 'date')">
        <i class="fa fa-calendar"></i>
      </div>
    </div>
    <input
      t-id="data-time-picker-input-date"
      class="form-control"
      #datePicker="ngbDatepicker"
      ngbDatepicker
      container="body"
      name="date"
      autocomplete="off"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [readonly]="invitedUser"
      [attr.data-tabindex]="tabIndexes[0]"
      [(ngModel)]="dateStruct"
      (change)="(changeEvent)"
      (ngModelChange)="updateDate()"
      (click)="onClicked(tabIndexes[0], 'date')"
      (focus)="inputFocused($event.target)"
    />
    <div
      *ngIf="isPastDate"
      t-id="login-form-invalid-tenant-alert"
      class="help-block mt-1 text-danger position-absolute"
      [style]="{ bottom: '-18px' }"
    >
      <i class="ft-alert-circle align-middle"></i> Wrong date
    </div>
  </div>

  <div *ngIf="!dateControlOnly" class="input-group time-input mr-1" [ngClass]="{ 'mobile-time-input': mobile, 'd-none': allDay }">
    <input
      t-id="data-time-picker-input"
      type="text"
      class="form-control ng-untouched ng-pristine ng-valid"
      placeholder="HH:mm"
      appControlledInput
      [disabled]="isDisabled"
      [maxLength]="5"
      [readonly]="invitedUser"
      [attr.data-tabindex]="tabIndexes[1]"
      [(ngModel)]="time"
      (ngModelChange)="updateTime($event)"
      (click)="onClicked(tabIndexes[1])"
      (focus)="inputFocused($event.target)"
    />
  </div>
</div>
