<div
  [ngClass]="[
  'card__item',
   getClass(plan.attribute),
   isDark ? 'dark' : '',
   isAccept ? 'is-accept' : ''
  ]"
  *transloco="let t; read: 'modals.payment'"
>
  <div *ngIf="!isAccept" class="card__wrapper">
    <div class="card__title">
      <h4>{{plan.title}}</h4>
      <div
        *ngIf="plan.isCurrentPlan"
        class="card__current"
      >
        {{ t('current-subscription') }}
      </div>
    </div>
    <div class="card__info">
      <p *ngIf="plan.price" class="card__info-amount">
        <i *ngIf="isBasicPlan && isLoading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
        {{ isBasicPlan && isLoading ? '' : plan.price + ' €'}}
      </p>
      <svg-icon *ngIf="plan.contactUs" [src]="plan.priceSvg" />
      <p class="card__info-period" [class.opacity-0]="plan.contactUs">per month</p>
      <p class="card__info-description">{{ descriptionPlan }}</p>
    </div>

  </div>
  <div class="card__includes">
    <h5 *ngIf="!isAccept" class="card__paragraph card__includes-title">Includes:</h5>
    <ul class="card__includes-list">
      <li *ngFor="let item of plan.includes" class="card__paragraph">
        {{ item }}
      </li>
    </ul>
    <app-button
      *ngIf="!isAccept"
      customClasses="padding-12"
      [widthFull]="true"
      [disabled]="plan.isCurrentPlan"
      [variantButton]="variantButton"
      [label]="titleButton"
      [isLoading]="isLoadingButton"
      (handleClick)="handleClick()"
    />
  </div>
</div>
