import { Component, OnInit } from '@angular/core';
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PollsOption } from '../../shared/components/chat/chat-messages/message-poll/interface/PollsOption';

interface PollResultData {
  title: string;
  options: PollsOption[];
  chatMembers: any;
}

@Component({
  selector: 'app-poll-result',
  templateUrl: './poll-result.component.html',
  styleUrls: ['./poll-result.component.scss'],
})
export class PollResultComponent implements OnInit {
  config: ITemplateConfig;
  pollData: PollResultData;
  chatMembers: any[];
  title: string;
  options: PollsOption[];

  constructor(private configService: ConfigService, private activeModal: NgbActiveModal) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initializeDate(this.pollData);
  }

  public getUserName(userId: string): string {
    return this.chatMembers.find((member) => member.userId === userId)?.userName;
  }

  public closeModal(): void {
    this.activeModal.close();
  }
  private initializeDate(data: PollResultData): void {
    this.title = data.title;
    this.options = data.options;
    this.chatMembers = data.chatMembers;
  }
}
