import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterPipe } from './filter.pipe';
import { RouterTenantPipe } from './router-tenant.pipe';
import { CheckPermissionPipe } from './check-permission.pipe';
import { FromNowPipe } from './from-now.pipe';
import { HighlightChatMembersPipe } from './highlight-chat-members.pipe';
import { NameInitialPipe } from './initial-name.pipe';
import { TruncatePipe } from './truncate.pipe';
import { HighlightTicketsPipe } from './highlight-tickets.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ObsWithStatusPipe } from './obs-with-status.pipe';
import { DocumentLinkPipe } from './document-link.pipe';
import { SortPipe } from './sort.pipe';
import { UnreadPipe } from './unread.pipe';
import { CheckIsPinPipe } from './check-isPin.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    RouterTenantPipe,
    CheckPermissionPipe,
    FromNowPipe,
    HighlightChatMembersPipe,
    NameInitialPipe,
    TruncatePipe,
    HighlightTicketsPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ObsWithStatusPipe,
    DocumentLinkPipe,
    SortPipe,
    UnreadPipe,
    CheckIsPinPipe,
  ],
  imports: [CommonModule],
  exports: [
    HighlightChatMembersPipe,
    FilterPipe,
    RouterTenantPipe,
    CheckPermissionPipe,
    FromNowPipe,
    NameInitialPipe,
    TruncatePipe,
    HighlightTicketsPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ObsWithStatusPipe,
    DocumentLinkPipe,
    SortPipe,
    UnreadPipe,
    CheckIsPinPipe,
  ],
  providers: [RouterTenantPipe, CheckPermissionPipe],
})
export class PipeModule {}
