import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment-timezone';
import { TranslocoService } from '@ngneat/transloco';

// State
import { AuthState } from '../../../../shared/store/states/auth.state';
import { UsersState } from '../../../../shared/store/states/users.state';
import { WikiPageDbDto } from '../../../../api/models/wiki-page-db-dto';

// Services
import { WikiService } from '../wiki.service';
import { LocalStorageService } from 'ngx-localstorage';
import { ConfigService } from '../../../../shared/services/config.service';

// Components
import { AdminUserEditComponent } from '../../../../modals/admin-user-edit/admin-user-edit.component';
import { LocalStorageKeys } from '../../../../types/local-storage-keys.enum';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-wiki-header',
  templateUrl: './wiki-header.component.html',
  styleUrls: ['./wiki-header.component.scss'],
})
export class WikiHeaderComponent implements OnInit {
  public date: string = '';
  public userId: string = '';
  public userName: string = '';
  public title: string = '';
  public wiki: WikiPageDbDto;
  public isDark = false;

  public readonly linkSvg = 'assets/icons/common/link.svg';
  public readonly threadSvg = 'assets/icons/data-room/comment-dots.svg';

  constructor(
    public wikiService: WikiService,
    public modalService: NgbModal,
    public configService: ConfigService,
    protected cdr: ChangeDetectorRef,
    protected store: Store,
    protected localStorage: LocalStorageService,
    protected translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initializeData();
  }

  public toggleEditMode(): void {
    this.wikiService.setEditMode();
  }

  public openUserProfile() {
    const userId = this.store.selectSnapshot(AuthState.getUser)._id;
    const modalRef = this.modalService.open(AdminUserEditComponent, {
      size: 'lg',
    });
    modalRef.componentInstance._id = this.userId;
    modalRef.componentInstance.isEditable = userId === this.userId;
  }

  public copyLink(): void {
    this.wikiService.copyTicketLink();
  }

  public toggleThread(): void {
    if (this.wiki && this.wiki._id === 'get-started') {
      if (this.wikiService.isLoadingThreadCreate) {
        return;
      }

      this.wikiService.isLoadingThreadCreate = true;
      this.wikiService.save(this.wiki.title, this.wiki.content, true, true);
    } else {
      this.wikiService.handleOpenThread.next('');
    }
  }

  private initializeData(): void {
    this.configService.templateConf$.subscribe(({ layout: { variant } }) => (this.isDark = variant === 'Dark'));

    this.wikiService.activeWiki$.pipe(untilDestroyed(this)).subscribe((wiki) => {
      if (wiki) {
        const lang = this.localStorage.get(LocalStorageKeys.language);

        this.wiki = wiki;
        this.title = wiki.title || this.translocoService.translate('wiki.new-doc');
        this.userId = wiki.createdBy || this.localStorage.get('userId');
        this.date =
          lang === 'de'
            ? moment(wiki.created_at, '', 'de').format(
                `DD.MM.YYYY [${this.translocoService.translate('wiki.wiki-at')}] HH:mm [Uhr]`,
              )
            : moment(wiki.created_at).format('M/D/YY [at] h:mm a');
        this.userName = this.store.selectSnapshot(UsersState.getUser)(this.userId)?.userName || '';
        this.cdr.detectChanges();
      }
    });
  }
}
