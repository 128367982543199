import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

// Components
import { WikiHeaderComponent } from '../../../../../../../src/app/pages/full-pages/wiki/wiki-header/wiki-header.component';
import { EditorService } from '../../../../../../../src/app/shared/components/editor/editor.service';
import { WikiService } from '../../../../../../../src/app/pages/full-pages/wiki/wiki.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../../../../../src/app/shared/services/config.service';
import { Store } from '@ngxs/store';
import { LocalStorageService } from 'ngx-localstorage';
import { ConfirmAlert } from '../../../../../../../src/app/shared/alerts/alerts';
import { AuthState } from '../../../../../../../src/app/shared/store/states/auth.state';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-wiki-mobile-header',
  templateUrl: './wiki-mobile-header.component.html',
  styleUrls: [
    '../../../../../../../src/app/pages/full-pages/wiki/wiki-header/wiki-header.component.scss',
    './wiki-mobile-header.component.scss',
  ],
})
export class WikiMobileHeaderComponent extends WikiHeaderComponent {
  @Output() backAction = new EventEmitter();
  @Output() showToastLink = new EventEmitter();
  @Output() handleSave = new EventEmitter();

  @Input() isEdit = false;
  @Input() hasUnsavedChange = false;
  @Input() isLoadingButton = false;

  constructor(
    public wikiService: WikiService,
    public modalService: NgbModal,
    public configService: ConfigService,
    protected cdr: ChangeDetectorRef,
    protected store: Store,
    protected localStorage: LocalStorageService,
    private editorService: EditorService,
    protected translocoService: TranslocoService,
  ) {
    super(wikiService, modalService, configService, cdr, store, localStorage, translocoService);
  }

  public readonly shavronIcon = 'assets/icons/common/arrow-down.svg';
  public readonly redoIcon = 'assets/icons/wiki/redo.svg';
  public readonly undoIcon = 'assets/icons/wiki/undo.svg';

  public get isUndoActive() {
    return this.editorService.isUndoActive;
  }

  public get isRedoActive() {
    return this.editorService.isRedoActive;
  }

  public handleAction() {
    this.backAction.emit();
  }

  public undoHandle($event: MouseEvent) {
    this.editorService.undo$.emit($event);
  }

  public redoHandle($event: MouseEvent) {
    this.editorService.redo$.emit($event);
  }

  public copyLink() {
    super.copyLink();
    this.showToastLink.emit();
  }

  public saveAction(): void {
    this.handleSave.emit();
  }

  public toggleEditMode() {
    if (this.wikiService.hasUnsavedChanges) {
      ConfirmAlert('', {
        platform: this.store.selectSnapshot(AuthState.getPlatform),
        confirmButtonClass: 'btn-solid',
        subject: 'Save changes?',
        text: 'You have made changes. Do you want to save or discard your changes?',
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard',
      }).then(
        () => {
          this.wikiService.save(this.editorService.getEditorTitle(), this.editorService.getEditorContent());
          this.wikiService.hasUnsavedChanges = false;
        },
        () => {
          this.wikiService.clearContent.next('');
          this.wikiService.setEditMode();
          this.wikiService.hasUnsavedChanges = false;
        },
      );
    } else {
      this.wikiService.setEditMode();
    }
  }
}
