import { Injectable } from '@angular/core';
import { CustomFieldItem } from '../classes/custom-fields';
import { TicketsFieldsDbDto } from '../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../api/models/tickets-fields-values-req-dto';
import { FieldType } from '../../../custom-field/enums/fieldType';
import { DateTimeComponent } from '../components/DateTime/date-time.component';
import { SelectLabelsTagsComponent } from '../components/SelectLabelsTags/select-labels-tags.component';
import { TextInputComponent } from '../components/TextInput/text-input.component';
import { InputSelectComponent } from '../components/InputSelect/input-select.component';
import { SelectLinkRelationshipComponent } from '../components/SelectLinkRelationship/select-link-relationship.component';
import { SelectComponent } from '../components/Select/select.component';

@Injectable()
export class CustomFieldService {
  constructor() {}
  remapFieldsToFieldComponents(
    fields: Array<TicketsFieldsDbDto>,
    ticketCustomFieldsValues: Array<TicketsFieldsValuesReqDto>,
  ): Array<CustomFieldItem> {
    return fields.map((field) => {
      const customFieldValues = ticketCustomFieldsValues.find((customField) => customField.ticketFieldId === field._id);
      const Component =
        field.type === FieldType.DatePicker || field.type === FieldType.DateTimePicker
          ? DateTimeComponent
          : field.type === FieldType.Tags
          ? SelectLabelsTagsComponent
          : field.type === FieldType.Unit
          ? InputSelectComponent
          : field.type === FieldType.UserPicker || field.title === 'Parent' || field.title === 'Epics'
          ? SelectLinkRelationshipComponent
          : field.type === FieldType.Select || field.type === FieldType.MultiSelect
          ? SelectComponent
          : TextInputComponent;
      return new CustomFieldItem(Component, field, customFieldValues);
    });
  }
}
