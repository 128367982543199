import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { FilesService } from '../../../../../src/app/api/services/files.service';
import { DataRoomUtiliesService } from '../../../../../src/app/shared/components/documents/services/data-room-utilies.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private fileService: FilesService, private dataRoomUtilies: DataRoomUtiliesService) {}
  downloadFile(document): void {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
      this.downloadIos(document);
    } else {
      this.dataRoomUtilies.downloadClicked(document, Capacitor.getPlatform());
    }
  }

  async downloadIos(document): Promise<void> {
    if (!document.url) {
      this.fileService.filesGetUrl({ id: document._id }).subscribe((res) => {
        Browser.open({ url: res.url });
      });
    } else {
      await Browser.open({ url: document.url });
    }
  }
}
