<ng-container *ngIf="isDraggingFile">
  <div
    class="drag-drop-dir d-flex align-items-center justify-content-center position-absolute"
    [ngClass]="{
      'drag-drop-light': config?.layout.variant === 'Light',
      'drag-drop-dark': config?.layout.variant === 'Dark'
    }"
    (mouseleave)="setIsDraggingFile(false)"
    *transloco="let t; read: 'chat'"
  >
    <div class="drag-drop-text-wrapper d-flex flex-column font-weight-bold align-items-center">
      <div class="drag-drop-file-icon">
        <i class="fa fa-file-o" aria-hidden="true"></i>
      </div>
      <div class="drag-drop-main-text">{{ t('dnd-main-text') }}</div>
      <div class="drag-drop-second-text">{{ t('dnd-second-text') }}</div>
    </div>

    <div appDragDrop
      [component]="component"
      class="drag-drop-text-wrapper-hover position-absolute"
      (draggingFile)="setIsDraggingFile($event)"
      (FileDropped)="fileDropped($event)"
    >
    </div>
  </div>
</ng-container>

