import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, Store } from '@ngxs/store';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { TranslocoService } from '@ngneat/transloco';

import { HtmlHelper } from '../../shared/utils/html-helper';
import { DateTimeHelper } from '../../shared/utils/date-time-helper';
import { UpdateUser } from '../../shared/store/actions/users.action';
import { SpacesService, ProjectsService } from '../../api/services';
import { UserAvatarSet } from '../../shared/store/actions/avatar.action';
import { UsersState } from '../../shared/store/states/users.state';
import { SocketsService } from '../../shared/services/sockets.service';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { AuthState } from '../../shared/store/states/auth.state';
import { UserDeleteComponent } from '../user-delete/user-delete.component';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TwoFaGetOption } from '../../shared/store/actions/auth.action';
import { TfaOptionsDbDto } from '../../api/models/tfa-options-db-dto';
import { TauriService } from '../../shared/services/tauri.service';
import { ConfigService } from '../../shared/services/config.service';
import { LocalStorageService } from 'ngx-localstorage';
import { LangCodes } from '../../types/lang-codes.enum';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';
import { EnvironmentService } from '../../shared/services/environment.service';

@Component({
  selector: 'app-admin-user-edit-modal',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.scss'],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: {
        wheelPropagation: false,
      },
    },
  ],
})
export class AdminUserEditComponent implements OnInit, OnDestroy {
  @ViewChild('avatarInput') avatarInput: ElementRef;
  @ViewChild('imageCropperModal') imageCropperModal;

  maxFileSize = 52428801;
  activeId = 1;
  user: any;
  _id: string;
  spaces: any[];
  projects: any[];
  timezones: any[];
  languages = [
    { label: 'English', value: LangCodes.english },
    { label: 'German', value: LangCodes.deutsch },
  ];
  currentLanguage: string;
  currTz: string;
  localTime = null;
  hoursDiff = null;
  timeInterval: any;

  destroy$: Subject<void> = new Subject<void>();
  userForm: FormGroup;
  cropperModal: NgbModalRef;
  isAvatarImageUploading: boolean;
  selectedUserAvatar: any;
  isEditable = false;
  platform: string;
  isTwoFaEnabled$ = new Subject<boolean>();
  isTwoFaLoading$ = new BehaviorSubject(true);
  twoFaOptions: TfaOptionsDbDto;
  pathToTwoFaSetup = `${window.location.origin}/${environment.subdomains ? '' : 'root/'}two-factor-authentication`;
  isDark = false;

  constructor(
    public dtHelper: DateTimeHelper,
    private htmlHelper: HtmlHelper,
    private cdr: ChangeDetectorRef,
    private modalsService: NgbModal,
    private activeModal: NgbActiveModal,
    private actions: Actions,
    private store: Store,
    private socketsService: SocketsService,
    private projectsService: ProjectsService,
    private spacesService: SpacesService,
    private toastrService: ToastrService,
    private router: Router,
    private tauriService: TauriService,
    private translocoService: TranslocoService,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private environmentService: EnvironmentService,
  ) {}

  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    // Load languages
    this.translocoService.load('de').pipe(takeUntil(this.destroy$)).subscribe();
    this.translocoService.load('en').pipe(takeUntil(this.destroy$)).subscribe();

    this.store.dispatch(new TwoFaGetOption()).subscribe((store) => {
      this.twoFaOptions = store.Auth.twoFaOptions;
      this.isTwoFaEnabled$.next(!!store.Auth.twoFaOptions);
      this.isTwoFaLoading$.next(false);
      this.cdr.detectChanges();
    });
    this.store
      .select(AuthState.getTwoFaOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => {
        this.twoFaOptions = options;
        this.cdr.detectChanges();
      });
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.isDark = templateConf.layout.variant !== 'Light';
        this.cdr.detectChanges();
      }
    });
    this.currTz = moment.tz.guess();
    this.timezones = this.dtHelper.getFormattedTimezones();
    this.initForm();
    this.setUser();
    this.getPlatform();
  }

  ngOnDestroy() {
    clearInterval(this.timeInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  get titleModal(): string {
    return this.user?.isAssistant
      ? this.translocoService.translate('modals.admin-user-edit.bot-profile')
      : this.translocoService.translate('modals.admin-user-edit.user-profile');
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  setCurrentTz() {
    this.userForm.controls.timezone.setValue(this.currTz);
  }

  get isAssistant() {
    return !!this.user?.isAssistant;
  }

  get activeLanguage(): string {
    return this.localStorage.get(LocalStorageKeys.language) || this.languages[0].value;
  }

  setActiveLanguage(language: string) {
    const conf = this.configService.templateConf;
    this.configService.applyTemplateConfigChange({ layout: conf.layout, language });
    if (language) {
      this.translocoService.setActiveLang(language);
    }
  }

  displayTime() {
    const tz = this.user.timezone;
    this.localTime = this.dtHelper.getHoursForTz(tz);
    this.hoursDiff = this.dtHelper.getTzsDiffInHours(tz);
    this.cdr.markForCheck();
  }

  getSpaceRoleName(space: any): string {
    return space.role?.roleName
      ? space.role?.roleName
      : space.ownerId === this.user._id
      ? this.translocoService.translate('modals.admin-user-edit.owner')
      : null;
  }

  getProjectRoleName(space: any, project: any): string {
    return (
      project.role?.roleName || space.role?.roleName || this.translocoService.translate('modals.admin-user-edit.owner')
    );
  }

  /**
   * Close edit modal handler
   */
  close() {
    if (this.userForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
            this.save();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }
  delete() {
    this.activeModal.close();
    this.modalsService.open(UserDeleteComponent, { size: 'md', centered: true, windowClass: 'delete-account-modal' });
  }

  save() {
    const user = this.userForm.value;
    const isEqualTz = user.timezone === this.user.timezone;
    user.email = user.email.toLowerCase();

    if (
      user.userName !== this.user.userName ||
      user.name !== this.user.name ||
      user.email !== this.user.email ||
      user.localization !== this.activeLanguage ||
      !isEqualTz
    ) {
      this.store
        .dispatch(new UpdateUser({ userId: this.user._id, body: user }))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            if (user.localization !== this.activeLanguage) {
              this.setActiveLanguage(user.localization);
            }

            this.toastrService.success(
              this.translocoService.translate('toastr.message-profile-updated'),
              this.translocoService.translate('toastr.title-success'),
            );

            this.activeModal.close();
            if (!isEqualTz) {
              moment.tz.setDefault(user.timezone);
            }
          },
          (err) => {
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    } else {
      this.activeModal.close();
    }
  }

  get isProduction(): boolean {
    return this.environmentService.isProduction;
  }
  get isPaymentActive(): boolean {
    return this.environmentService.isPayment;
  }

  isAdmin(user): boolean {
    if (!user) return false;

    for (const role of user.roles) {
      if (role.roleName === 'Owner') {
        return true;
      }
    }
    return false;
  }

  getUserAvatarImageName() {
    return this.selectedUserAvatar?.target?.files[0]?.name;
  }

  avatarImageChange(event) {
    this.selectedUserAvatar = event;

    if (this.getUserAvatarImageName()) {
      this.cropperModal = this.modalsService.open(this.imageCropperModal, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'cropper-modal',
      });
    }
  }

  /**
   * Update User avatar
   */
  updateUserAvatar(file) {
    if (file.size < this.maxFileSize) {
      this.store
        .dispatch(new UserAvatarSet({ id: this.user._id, file: file }))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.avatarInput.nativeElement.value = '';
          },
          (err) => {
            this.avatarInput.nativeElement.value = '';
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    } else {
      this.toastrService.error(
        this.translocoService.translate('toastr.err-message-file-size', { size: '50MB' }),
        file.name,
      );
    }
  }

  closeCropperModal() {
    this.cropperModal.close();
    this.avatarInput.nativeElement.value = '';
  }

  copyEmail() {
    this.htmlHelper.copyValueToBuffer(this.userForm.value.email);
    this.toastrService.success(
      this.translocoService.translate('toastr.email-address-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  viewDirectChat(userId): void {
    this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
    this.activeModal.close();
  }

  initForm(): void {
    this.userForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      timezone: new FormControl(null, [Validators.required]),
      localization: new FormControl(localStorage.getItem(LocalStorageKeys.language) || this.languages[0].value, [
        Validators.required,
      ]),
    });

    this.currentLanguage = this.activeLanguage;
  }

  setUser(): void {
    this.store
      .select(UsersState.getUser)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this._id)),
      )
      .subscribe((user) => {
        if (user) {
          this.user = user;

          if (user.timezone) {
            this.localTime = this.dtHelper.getHoursForTz(user.timezone);
            this.hoursDiff = this.dtHelper.getTzsDiffInHours(user.timezone);
            this.timeInterval = setInterval(() => this.displayTime(), 10000);
          }

          this.userForm.patchValue({
            userName: user.userName,
            name: user?.name,
            email: user.email,
            timezone: user.timezone,
          });
        }
      });

    this.store
      .select(UsersState.getUserAvatarImageUploadLoading)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.user?._id)),
      )
      .subscribe((res) => (this.isAvatarImageUploading = res));

    this.getProjectsAndSpaces();
  }

  getProjectsAndSpaces(): void {
    const requests = [
      this.projectsService.projectGetProjectsListByUserId({ userId: this.user._id }),
      this.spacesService.spacesGetSpacesListByUserId({ userId: this.user._id }),
    ];

    forkJoin(requests)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([projectsData, spacesData]) => {
        this.spaces = spacesData['results'].map((space) => ({
          ...space,
          roleName: this.getSpaceRoleName(space),
          projects: projectsData['results']
            .filter((p) => p.spaceId === space._id)
            .map((p) => ({ ...p, roleName: this.getProjectRoleName(space, p) })),
        }));
      });
  }

  convertURL(url: string): string {
    const regex = /(?:tauri:\/\/localhost\/|tauri\.localhost\/)([^\/]+)\/.+/;
    const match = url.match(regex);

    if (match) {
      const crew = match[1];
      return `https://${crew}.${environment.main_host}/two-factor-authentication`;
    } else {
      return url;
    }
  }

  navigateToTwoFaSetup() {
    if (this.platform === 'web') {
      if (this.tauriService.isTauri) {
        this.tauriService.openExternalLink(this.convertURL(window.location.href));
      } else {
        window.open(this.pathToTwoFaSetup, '_blank').focus();
      }
    } else {
      this.router.navigate([`/${environment.subdomains ? '' : 'root/'}two-factor-authentication`]);
      this.activeModal.close();
    }
  }

  navigateToTwoFaDisable() {
    if (this.platform === 'web') {
      if (this.tauriService.isTauri) {
        this.tauriService.openExternalLink(this.convertURL(`${window.location.href}`) + '/disable');
      } else {
        window.open(`${this.pathToTwoFaSetup}/disable`, '_blank').focus();
      }
    } else {
      this.router.navigate([`/${environment.subdomains ? '' : 'root/'}two-factor-authentication/disable`]);
      this.activeModal.close();
    }
  }
}
