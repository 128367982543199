import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProjectsState } from '../../../store/states/projects.state';

@Component({
  selector: 'app-project-avatar',
  templateUrl: './project-avatar.component.html',
  styleUrls: ['./project-avatar.component.scss'],
})
export class ProjectAvatarComponent implements OnInit, OnDestroy {
  @Input() project: any;
  @Input() overlay = false;
  @Input() showEmoji = true;
  @Input() isBreadcrumb = false;
  @Input() containerWidth = 35;
  @Input() containerHeight = 35;
  @Input() mr = true;

  avatar$: Subscription;

  constructor(private store: Store) {}

  ngOnInit() {
    this.avatar$ = this.store.select(ProjectsState.getLoadedProjects).subscribe((projects) => {
      const initProject = projects.find(
        (item) =>
          item._id === this.project._id || item._id === this.project.objectId || item._id === this.project.projectId,
      );
      if (this.isBreadcrumb) {
        this.containerWidth = 25;
        this.containerHeight = 25;
        this.mr = false;
      }
      if (initProject) {
        this.project = {
          ...this.project,
          projectAvatarUrl: initProject?.avatarUrl,
          emoji: this.project?.emoji ?? initProject?.emoji,
        };
      }
    });
  }

  ngOnDestroy() {
    this.avatar$?.unsubscribe();
  }

  get isAvatarEmoji(): boolean {
    return this.containerWidth < 30;
  }
}
