import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthState } from '../../shared/store/states/auth.state';
import { Store } from '@ngxs/store';
import { BillingDetailsDbDto } from '../../api/models/billing-details-db-dto';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BillingUpdate, GetCountries } from '../../shared/store/actions/payment.action';
import { PaymentState } from '../../shared/store/states/payment.state';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-billing-edit',
  templateUrl: './billing-edit.component.html',
  styleUrls: ['./billing-edit.component.scss'],
})
export class BillingEditComponent implements OnInit {
  public isLoading = false;
  public billingDetail: BillingDetailsDbDto;
  public countryItems = [];
  public platform = this.store.select(AuthState.getPlatform);
  public billingForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: ['', Validators.required],
    address: ['', Validators.required],
    countryId: ['', Validators.required],
    vatId: ['', Validators.required],
    city: ['', Validators.required],
    postalCode: ['', Validators.required],
    billingEmail: ['', Validators.required],
  });

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly toast: ToastrService,
  ) {}

  ngOnInit() {
    this.initData();
  }

  public get isValid(): boolean {
    return this.billingForm.valid;
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public handleSubmit(): void {
    const data = {
      ...this.billingForm.value,
      countryId: this.billingForm.controls.countryId.value.id,
    };

    this.isLoading = true;
    this.store
      .dispatch(new BillingUpdate({ id: this.billingDetail._id, body: data }))
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.toast.success('Billing details have been updated', 'Success');
        this.activeModal.close();
      });
  }

  private initData(): void {
    this.store
      .dispatch(new GetCountries())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.countryItems = this.store.selectSnapshot(PaymentState.getCountries);

        if (this.billingDetail) {
          this.billingForm.patchValue({
            firstName: this.billingDetail.firstName,
            lastName: this.billingDetail.lastName,
            companyName: this.billingDetail.companyName,
            address: this.billingDetail.address,
            city: this.billingDetail.city,
            postalCode: this.billingDetail.postalCode,
            countryId: this.billingDetail.countryId,
            vatId: this.billingDetail.vatId,
            billingEmail: this.billingDetail.billingEmail,
          });
        }
      });
  }
}
