import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  public formatDate = '';
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const valueFormat = moment(value, this.formatDate).format('DD.MM.YYYY');
      const date = valueFormat?.split('.');
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (this.formatDate) {
      return date ? moment({ ...date, month: date.month - 1 }).format(this.formatDate) : '';
    } else {
      return date ? moment({ ...date, month: date.month - 1 }).format('DD.MM.YYYY') : '';
    }
  }

  setFormat(format: string) {
    this.formatDate = format;
  }
}
