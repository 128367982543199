import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TicketsLabelsDbDto } from '../../../api/models/tickets-labels-db-dto';
import { ConfigService } from '../../services/config.service';
import { ITicketForm } from '../../../modals/board-ticket/board-ticket';

@Component({
  selector: 'app-ticket-coloring',
  templateUrl: 'ticket-coloring.component.html',
  styleUrls: ['ticket-coloring.component.scss'],
})
export class TicketColoringComponent implements OnChanges {
  @Input() object: 'spaces' | 'projects';
  @Input() objectId: string;
  @Input() ticket: ITicketForm;
  @Input() labels: Array<TicketsLabelsDbDto>;
  @Input() uploadedImage: string;
  @Output() onChangeFile = new EventEmitter<File>();
  @Output() onChangeColor = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<{ isBoardApplyAllTickets: boolean }>();
  config: any = {};

  // TODO: Upload image
  // fileToUpload: File | null;

  colors = [
    { checked: false, color: '#FD9A9A' },
    { checked: false, color: '#FDB29A' },
    { checked: false, color: '#FDCF9A' },
    { checked: false, color: '#FDE79A' },
    { checked: false, color: '#FDF39A' },
    { checked: false, color: '#BEEE98' },
    { checked: false, color: '#A6E4A1' },
    { checked: false, color: '#A1E4C0' },
    { checked: false, color: '#A1E4D8' },
    { checked: false, color: '#ACE9FC' },
    { checked: false, color: '#ACDAFC' },
    { checked: false, color: '#B6C6FF' },
    { checked: false, color: '#C4B6FF' },
    { checked: false, color: '#D0B6FA' },
    { checked: false, color: '#F9B6FA' },
    { checked: false, color: '#FAB6D3' },
    { checked: false, color: '#C6C8D7' },
    { checked: false, color: null },
  ];

  constructor(private configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  ngOnChanges(): void {
    if (this.ticket.bgColor) {
      this.colors = this.colors.map((item) => ({
        ...item,
        checked: item.color === this.ticket.bgColor,
      }));
    }
  }

  // TODO: Apply to all tickets
  // applyToAllTickets() {
  //   this.onClose.emit({ isBoardApplyAllTickets: true });
  // }

  getLabel(labelId: string): string {
    return this.labels?.find((label) => label._id === labelId)?.label;
  }

  onColorSelect(color: string | null): void {
    this.onChangeColor.emit(color);
  }

  closeCustomizeTicket(event?: PointerEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.onClose.emit({ isBoardApplyAllTickets: false });
  }

  // TODO: Upload image
  // onFileSelected(event: Event): void {
  //   const target = event.target as HTMLInputElement;
  //   const file: File = target.files.item(0);
  //   this.onChangeFile.emit(file);
  // }
  //
  // removeImage(): void {
  //   this.fileToUpload = null;
  // }
}
