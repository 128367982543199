import { Injectable } from '@angular/core';
import { ContainerRefDirective } from '../directives/container-ref.directive';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface MiniTicket {
  object: 'spaces' | 'projects';
  objectId: string;
  ticketId?: string;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class MinimizeService {
  public fullLayoutMinimizeRef: ContainerRefDirective;

  constructor() {}

  // createComponentRef<T>(component: Type<T>): ComponentRef<T> {
  //   return this.resolver.resolveComponentFactory(component).create(this.injector);
  // }

  minimizeInit(
    modalRef: NgbModalRef,
    options = { width: '532px', height: '532px', right: '20px', borderRadius: '0' },
  ): NgbModalRef {
    const backdropElement = modalRef['_backdropCmptRef'].location.nativeElement;
    const modalElement = modalRef['_windowCmptRef'].location.nativeElement;

    modalRef.componentInstance.onMinimize.pipe(untilDestroyed(this)).subscribe(() => {
      backdropElement.style.setProperty('display', 'none');
      modalElement.style.setProperty('width', options.width);
      modalElement.style.setProperty('height', options.height);
      modalElement.style.setProperty('right', options.right);
      modalElement.style.setProperty('border-radius', options.borderRadius);
      modalElement.style.setProperty('bottom', '20px');
      modalElement.style.setProperty('top', 'unset');
      modalElement.style.setProperty('left', 'unset');
      modalElement.style.setProperty('overflow', 'unset');
      modalElement.style.setProperty('box-shadow', '0 4px 24px rgba(0, 0, 0, 0.15)');
      const modalDialog = modalElement.querySelector('.modal-dialog');
      modalDialog.style.setProperty('max-height', '100%');
      modalDialog.style.setProperty('max-width', '100%');
      modalDialog.style.setProperty('margin', '0 0');
    });

    modalRef.componentInstance.onExpand.pipe(untilDestroyed(this)).subscribe(() => {
      backdropElement.style.setProperty('display', 'block');
      modalElement.style.setProperty('width', '100%');
      modalElement.style.setProperty('height', '100%');
      modalElement.style.setProperty('right', 'unset');
      modalElement.style.setProperty('bottom', 'unset');
      modalElement.style.setProperty('top', '0px');
      modalElement.style.setProperty('left', '0px');
      modalElement.style.setProperty('box-shadow', '');

      const modalDialog = modalElement.querySelector('.modal-dialog');
      modalDialog.style.setProperty('max-height', 'calc(100% - 1rem)');
      modalDialog.style.setProperty('margin', '1.75rem auto');
      modalDialog.style.setProperty('max-width', '');

      modalElement.querySelector('.modal-dialog');
    });

    return modalRef;
  }
}
