import { Injectable } from '@angular/core';
import { isPermissionGranted, requestPermission, sendNotification } from '@tauri-apps/api/notification';

import {
  checkUpdate,
  installUpdate,
  onUpdaterEvent,
} from '@tauri-apps/api/updater'
import { relaunch } from '@tauri-apps/api/process'
import { ConfirmAlert } from '../alerts/alerts';
import { TranslocoService } from '@ngneat/transloco';

interface IBadgeCountType {
  unreadChatsCount: number;
  unreadDirectChatsCount: number;
  notificationsCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class TauriService {
  private ipcRenderer: any;
  private unreadChatsCount = 0;
  private notificationsCount = 0;

  constructor(private translocoService: TranslocoService) {
    // Only available if running in electron
    if (this.isTauri) {
      if ((window as any).require) {
        try {
          this.ipcRenderer = (window as any).require('electron').ipcRenderer;
        } catch (error) {
          throw error;
        }
      } else {
        console.warn(`Electron's IPC was not loaded`);
      }
    }
  }

  /**
   * It checks if the `__TAURI__` variable is defined in the global scope
   * @returns A boolean value.
   */
  get isTauri(): boolean {
    const isTauriEnv = (window as any).__TAURI__ !== undefined;

    return isTauriEnv;
  }

  /* This is a function that sends a notification to the user system, in dev mode icon can be not displayed correctly */
  sendSystemNotification = async (title: string, body: string, icon?: string) => {
    let permissionGranted = await isPermissionGranted();
    if (!permissionGranted) {
      const permission = await requestPermission();
      permissionGranted = permission === 'granted';
    }

    if (permissionGranted) {
      sendNotification({ title, body, icon });
      return;
    }
  };

  /**
   * Transfer data
   *
   * This method provides access to transfer data between Angular and Electron app.
   * NOTE: the channel name would be `fromAngular::<channel>`.
   * To send data to Electron provide second arg as data
   */
  transferData(channel: string, data?: any): void {
    if (!this.ipcRenderer) {
      return;
    }
    this.ipcRenderer.send(`fromAngular::${channel}`, data);
  }

  on(channel: string, listener: any): void {
    if (!this.ipcRenderer) {
      return;
    }
    this.ipcRenderer.on(channel, listener);
  }
  /**
   * Update badge count
   *
   * This method provides access to update Electron icon badge counter in the desktop dock
   */
  updateBadgeCount(data: Partial<IBadgeCountType>) {
    this.unreadChatsCount = data.unreadChatsCount ?? this.unreadChatsCount;
    this.notificationsCount = data.notificationsCount ?? this.notificationsCount;

    this.transferData('updateBadgeCount', this.unreadChatsCount + this.notificationsCount);
  }

  /**
   * Move app window to top
   */
  moveToTop() {
    this.transferData('moveToTop');
  }

  /**
   * The function "openExternalLink" opens a given URL in a new browser tab.
   * @param {string} url - The `url` parameter is a string that represents the URL of the external link
   * that you want to open.
   */
  public openExternalLink(url: string) {
    console.log('[openExternalLink]: ', url);
    (window as any).__TAURI__.shell.open(url);
  }


  public async checkUpdate() {
    setTimeout(async () => {



      if (this.isTauri) {
        const unlisten = await onUpdaterEvent(({ error, status }) => {
          // This will log all updater events, including status updates and errors.
          console.log('Updater event', error, status)
        })

        try {
          const { shouldUpdate, manifest } = await checkUpdate()



          if (shouldUpdate) {
            ConfirmAlert(null, {
              subject: this.translocoService.translate('updater.title', { value: manifest.version }),
              text: this.translocoService.translate('updater.description'),
              cancelButtonText: this.translocoService.translate('updater.btn-update-later'),
              showDenyButton: false,
              denyButtonClass: 'btn-subtle',
              confirmButtonText: this.translocoService.translate('updater.btn-update'),
              confirmButtonClass: 'btn-solid',
              platform: 'web',
            }).then(
              (result) => {
                if (result === 'isDenied') {
                  console.log('Update later');
                }
                if (result === 'isConfirmed') {

                  console.log(
                    `Installing update ${manifest?.version}, ${manifest?.date}, ${manifest?.body}`
                  )
                  console.log('isConfirmed');
                  // Install the update. This will also restart the app on Windows!
                  installUpdate().then(() => {
                    // On macOS and Linux you will need to restart the app manually.
                    // You could use this step to display another confirmation dialog.
                    relaunch();
                  });
                }
              },
              () => { },
            );



          }
        } catch (error) {
          console.error(error)
        }

        unlisten()

      }
    }, 3000)
  }

}
