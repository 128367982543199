<div *ngIf="drafts$ | async as drafts">
  <div
    *ngIf="drafts.length"
    class="drafts-toggle"
    [class.inChat]="isChat"
    [class.hidden-draft]="!draftsVisible" (click)="draftsVisible = !draftsVisible"
  >
    <svg-icon
      *ngIf="draftsVisible"
      src="assets/icons/common/arrow-down.svg"
      [svgStyle]="{ 'width.px': '10', 'height.px': '6' }"
    ></svg-icon>
    <svg-icon
      *ngIf="!draftsVisible"
      src="assets/icons/common/file-check.svg"
      class="visible"
      [svgStyle]="{ 'width.px': '12', 'height.px': '14' }"
    ></svg-icon>
  </div>
  <section class="drafts" [class.visible]="draftsVisible">
      <app-draft *ngFor="let draft of drafts" [draft]="draft"></app-draft>
  </section>
</div>
