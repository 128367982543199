import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-webhook-push-message',
  templateUrl: './webhook-push-message.component.html',
  styleUrls: ['./webhook-push-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookPushMessageComponent {
  @Input() pusherName: string;

  @Input() description: string;

  @Input() repositoryName: string;

  @Input() commitId: string;

  @Input() commitUrl: string;

  @Input() commitToFullPath: string;

  public getShortCommitId(id: string, length = 8): string {
    return id?.slice(0, length);
  }
}
