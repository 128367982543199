<div class="invite-members-modal" *transloco="let t; read: 'modals.invite'">
  <div
    class="modal-header"
    [class.invite-modal]="platform !== 'mobile'"
    [class.invite-modal-mobile]="platform === 'mobile'"
  >
    <h5 class="modal-title">{{ t('invite-members') }}</h5>
    <button t-id="invite-action-invite" type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body position-relative">
    <app-members-invite
	    #membersInviteComponent
      *ngIf="objectId"
      [object]="object"
      [objectId]="objectId"
      [newUsersOnly]="newUsersOnly"
      [platform]="platform"
    ></app-members-invite>
  </div>
</div>
