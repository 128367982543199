<div class="header" *transloco="let t; read: 'wiki'">
  <div *ngIf="!isEdit; else editMode" class="header__wrapper">
    <div class="header__left">
      <svg-icon
        (click)="handleAction()"
        class="header-icon"
        [src]="shavronIcon"
        [applyClass]="true"
      ></svg-icon>
      <p>{{title}}</p>
    </div>

    <div class="header__right">
      <div class="wiki__header-container mr-2" [ngbTooltip]="t('tooltip-open-thread')" placement="bottom" (click)="toggleThread()">
        <svg-icon *ngIf="!wikiService.isLoadingThreadCreate" [src]="threadSvg"  class="wiki__header-thread"></svg-icon>
        <i *ngIf="wikiService.isLoadingThreadCreate" class="fa fa-spinner fa-spin connection_spinner mr-1" aria-hidden="true"></i>
        <span class="wiki__header-counter">{{wikiService.totalCount}}</span>
      </div>
      <svg-icon [src]="linkSvg" (click)="copyLink()" class="wiki__header-link" [ngbTooltip]="t('tooltip-copy-link')" placement="bottom"></svg-icon>
    </div>
  </div>
  <ng-template #editMode>
    <div class="header__wrapper header__icons">
      <div class="header__left">
        <svg-icon
          (click)="toggleEditMode()"
          class="header-icon"
          [src]="shavronIcon"
          [applyClass]="true"
        ></svg-icon>

        <svg-icon [src]="undoIcon" class="header-undo" [ngClass]="{ 'active': isUndoActive }" (click)="undoHandle($event)"></svg-icon>

        <svg-icon [src]="redoIcon" class="header-redo" [ngClass]="{ 'active': isRedoActive }" (click)="redoHandle($event)"></svg-icon>
      </div>
      <button type="button" class="btn btn-solid btn-save" [disabled]="!hasUnsavedChange || isLoadingButton" (click)="saveAction()">{{ t('btn-save') }}</button>
    </div>
  </ng-template>
</div>
