import { Store, State, Selector, Action, StateContext } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import slugify from 'slugify';

import { ProjectsStateModel } from '../models/ProjectsState';
import {
  ProjectCreate,
  ProjectDelete,
  ProjectGetUsersList,
  ProjectInvite,
  ProjectsGet,
  ProjectUpdate,
  ProjectInviteTokenCheck,
  ProjectInviteConfirmExist,
  ProjectInviteConfirmNew,
  ProjectSetAvatarImageUploadLoading,
  ProjectUpdateAvatar,
  ProjectsEmojiPicker,
  ProjectsSetEmoji,
  ProjectsUnassignMember,
  ProjectsRevokeInvite,
  ProjectsUpdateAfterDelete,
  ProjectsUpdateAfterChange,
  ProjectsDeleteBySpace,
  ProjectsResetNumberOfActivities,
  ProjectsSet,
  ProjectGetUserListsByProjectsId,
  ProjectToggleArchiveStatus,
  ProjectOrderUpdate,
  ProjectLeave,
  ProjectMemberDelete,
  ProjectOrders,
  ProjectRestore,
} from '../actions/projects.action';
import { AuthLoginResDto } from '../../../api/models/auth-login-res-dto';
import { ProjectsService } from '../../../api/services/projects.service';
import { ProceedLogin } from '../actions/auth.action';
import { ProjectAvatarSet } from '../actions/avatar.action';
import { LocalStorageService } from 'ngx-localstorage';
import { SpacesStateModel } from '../models/SpacesState';
import { TicketService } from '../../services/ticket.service';
import { AuthState } from './auth.state';
import { UrlHelper } from '../../utils/url-helper';
import { v4 as uuidv4 } from 'uuid';
import { compose, patch, updateItem } from '@ngxs/store/operators';

@State<ProjectsStateModel>({
  name: 'Projects',
  defaults: {
    projectsLoaded: false,
    projects: [],
    users: [],
    allUsers: [],
    pendingUsers: [],
    notActivatedUsers: [],
    usersCandidates: [],
    lastCreatedId: '',
    inviteData: {},
    projectEmojiIsOpen: false,
    emoji: '',
    requestUuid: '',
    restoreProject: null,
  },
})
@Injectable()
export class ProjectsState {
  /**
   * get all available users
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getAllUsers(state: ProjectsStateModel) {
    return [
      ...state.users.map((item) => ({ ...item, status: 'Active' })),
      ...state.pendingUsers.map((item) => ({ ...item, status: 'Pending' })),
      ...state.notActivatedUsers.map((item) => ({ ...item, status: 'Newly invited' })),
    ];
  }

  /**
   * get users
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getUsers(state: ProjectsStateModel) {
    return state.users;
  }

  @Selector()
  /**
   * get invite data
   * @param  {AuthStateModel} state
   */
  static getInviteData(state: ProjectsStateModel) {
    return state.inviteData;
  }

  /**
   * get id of last created item
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getLastCreatedId(state: ProjectsStateModel) {
    return state.lastCreatedId;
  }

  /**
   * get users candidates for invite
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getUsersCandidates(state: ProjectsStateModel) {
    return state.usersCandidates;
  }

  /**
   * get loaded projects
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getLoadedProjects(state: ProjectsStateModel) {
    return state.projects.filter((project) => !project.isArchive);
  }

  /**
   * get archive projects
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getArchiveProjects(state: ProjectsStateModel) {
    return state.projects.filter((project) => project.isArchive);
  }

  /**
   * get projects status (loaded | not)
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getLoadedProjectsStatus(state: ProjectsStateModel) {
    return state.projectsLoaded;
  }

  /**
   * get all loaded users
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getLoadedAllUsers(state: ProjectsStateModel) {
    return state.allUsers;
  }

  @Selector()
  static getProject(state: ProjectsStateModel) {
    return (projectId: string) => state.projects.find((item) => item._id === projectId) || null;
  }

  @Selector()
  static getProjectImageUploadLoading(state: ProjectsStateModel) {
    return (projectId: string) =>
      state.projects.filter((item) => item._id === projectId)[0].avatarImageUploadLoading || null;
  }

  /**
   * get projects emoji state - open/closed
   * @param  {ProjectsStateModel} state
   */
  @Selector()
  static getProjectsEmoji(state: ProjectsStateModel) {
    return state.projectEmojiIsOpen;
  }

  constructor(
    private projectsService: ProjectsService,
    private store: Store,
    private localStorage: LocalStorageService,
    private ticketService: TicketService,
    private urlHelper: UrlHelper,
  ) {}

  /**
   * Create project action handler
   * @param  {getState, setState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectCreate} action
   */
  @Action(ProjectCreate)
  create_project(
    { getState, setState, patchState }: StateContext<ProjectsStateModel>,
    {
      payload: {
        body,
        avatar,
        invitedUsers: { newUsers, existingUsers },
      },
    }: ProjectCreate,
  ) {
    patchState({ lastCreatedId: '' });

    return this.projectsService.projectCreate({ body }).pipe(
      tap(
        (result) => {
          const prevState = getState();
          const projects = [...prevState.projects, result];

          setState({
            ...prevState,
            projectsLoaded: true,
            projects,
            lastCreatedId: result._id as string,
          });
          this.localStorage.set('projects', projects);

          if (avatar) {
            this.store.dispatch(new ProjectAvatarSet({ id: result._id, file: avatar }));
          }

          if (newUsers.length > 0 || existingUsers.length > 0) {
            this.store.dispatch(new ProjectInvite({ projectId: result._id, newUsers, existingUsers }));
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set projects action handler
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsSet} action
   */
  @Action(ProjectsSet)
  set_projects({ patchState }: StateContext<ProjectsStateModel>, action: ProjectsSet) {
    return patchState({ projects: this.localStorage.get('projects') || [] });
  }

  /**
   * Get users by project id action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectGetUserListsByProjectsId} action
   */
  @Action(ProjectGetUserListsByProjectsId)
  getUsersByProjects(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    { payload }: ProjectGetUserListsByProjectsId,
  ) {
    const projects = payload.projects;

    return combineLatest(
      projects.map((project) => {
        return this.projectsService.projectGetUserListByProjectId({ id: project._id, exists: true }).pipe(
          tap(
            (result) => {
              const prevState = getState();
              if (!prevState.allUsers.find(({ projectId }) => projectId === project._id)) {
                return result;
              }
            },
            (err) => {
              throw err.error;
            },
          ),
          map((result) => {
            const prevState = getState();
            if (!prevState.allUsers.find(({ projectId }) => projectId === project._id)) {
              patchState({
                allUsers: [
                  ...prevState.allUsers,
                  {
                    projectId: project._id,
                    users: result['activatedUsers'],
                  },
                ],
              });
            }
          }),
        );
      }),
    );
  }

  /**
   * Get projects action handler
   * @param  {getState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsGet} action
   */
  @Action(ProjectsGet)
  get_projects({ patchState }: StateContext<ProjectsStateModel>, action: ProjectsGet) {
    patchState({ projectsLoaded: false, projects: [] });

    return this.projectsService.projectGetList(action.payload).pipe(
      tap(
        (res) => {
          const projects = res.results.map((item) => ({
            slug: slugify(item.projectName),
            ...item,
          }));

          patchState({ projectsLoaded: true, projects });
          this.localStorage.set('projects', projects);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectUpdate} action
   */
  @Action(ProjectUpdate)
  update_project({ getState, patchState }: StateContext<ProjectsStateModel>, { payload }: ProjectUpdate) {
    const project = payload.project;

    return this.projectsService.projectUpdate({ id: payload.id, body: project }).pipe(
      tap(
        (result) => {
          const prevState = getState();
          const projects = prevState.projects.map((item) =>
            item._id === payload.id
              ? { ...item, ...project, slug: slugify(project.projectName), avatarUrl: payload.avatarUrl }
              : item,
          );

          patchState({ projectsLoaded: true, projects });
          this.localStorage.set('projects', projects);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Reset project numberOfUnreadActivityLogs
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsResetNumberOfActivities} action
   */
  @Action(ProjectsResetNumberOfActivities)
  resetNumberOfActivities(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    action: ProjectsResetNumberOfActivities,
  ) {
    const { projects } = getState();
    const allProjects = projects.map((item) =>
      item._id === action.payload ? { ...item, numberOfUnreadActivityLogs: 0 } : item,
    );

    patchState({ projectsLoaded: true, projects: allProjects });
    this.localStorage.set('projects', allProjects);
  }

  /**
   * Update spaces after change project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsUpdateAfterChange} action
   */
  @Action(ProjectsUpdateAfterChange)
  update_after_change_project(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    action: ProjectsUpdateAfterChange,
  ) {
    const project = action.payload;
    const { projects } = getState();
    const allProjects = projects.map((item) =>
      item._id === project._id
        ? { ...item, ...project, slug: project.projectName ? slugify(project.projectName) : item.slug }
        : item,
    );

    patchState({ projects: allProjects });
    this.localStorage.set('projects', allProjects);
  }

  /**
   * Delete project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectDelete)
  delete_project({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectDelete) {
    return this.projectsService.projectDelete(action.payload).pipe(
      tap(
        (res) => {
          const prevState = getState();
          const projects = prevState.projects.filter((i) => i._id !== action.payload.id);

          patchState({ projectsLoaded: true, projects });
          this.localStorage.set('projects', projects);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update projects after delete project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectsUpdateAfterDelete)
  update_after_delete_project(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    action: ProjectsUpdateAfterDelete,
  ) {
    const prevState = getState();
    const projects = prevState.projects.filter((i) => i._id !== action.payload.projectId);

    patchState({ projects });
    this.localStorage.set('projects', projects);
  }

  /**
   * delete projects when space is deleted
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectsDeleteBySpace)
  delete_project_by_space({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectsDeleteBySpace) {
    const projects = getState().projects.filter((project) => project.spaceId !== action.payload.id);

    patchState({ projects });
    this.localStorage.set('projects', projects);
  }

  /**
   * Get users list action handler
   * @param  {getState, setState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectGetUsersList)
  get_users_list({ getState, setState }: StateContext<ProjectsStateModel>, action: ProjectGetUsersList) {
    const { isInternalState, ...rest } = action.payload;
    return this.projectsService.projectGetUserListByProjectId(rest).pipe(
      tap(
        (result) => {
          if (isInternalState) {
            // set active users into service state without changing in global
            this.ticketService.setTicketUsers(result['activatedUsers']);
          } else {
            const prevState = getState();
            const key = action.payload.exists ? 'users' : 'usersCandidates';
            setState({
              ...prevState,
              [key]: result['activatedUsers'],
              pendingUsers: result['pendingUsers'],
              notActivatedUsers: result['notActivatedUsers'],
            });
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite users action handler
   * @param  {getState, setState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectInvite)
  invite({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectInvite) {
    return this.projectsService.projectInvite({ body: action.payload }).pipe(
      tap(
        (result) => {
          const prevState = getState();
          patchState({
            ...prevState,
            pendingUsers: [
              ...prevState.pendingUsers,
              ...result.existingUsers.map((item) => ({ ...item, status: 'Pending' })),
            ],
            notActivatedUsers: [
              ...prevState.notActivatedUsers,
              ...(result.newUsers as any).map((item) => ({ ...item, status: 'Newly invited', isActive: false })),
            ],
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite token validation action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectInviteTokenCheck)
  project_invite_token_check(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    action: ProjectInviteTokenCheck,
  ) {
    return this.projectsService.projectInviteTokenCheck({ token: action.payload.token }).pipe(
      tap(
        (result) => {
          patchState({
            inviteData: result,
          });
        },
        (err) => {
          const platform = this.store.selectSnapshot(AuthState.getPlatform);
          if (platform === 'web' && err?.status === 404) {
            // In case when invite already accepted we'll try redirect to dashboard
            window.location.href = `${this.urlHelper.getCorrectOrigin()}dash`;
          } else {
            throw err.error;
          }
        },
      ),
    );
  }

  /**
   * confirm invitation for existing user action handler
   * @param  {getState, setState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectInviteConfirmExist)
  act_invite_confirm_exist(
    { getState, setState }: StateContext<ProjectsStateModel>,
    action: ProjectInviteConfirmExist,
  ) {
    return this.projectsService.projectConfirmExist({ token: action.payload.token }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Register by invitation link
   * @param { }: StateContext<ProjectsStateModel>
   * @param  {ProjectInviteConfirmNew} action
   */
  @Action(ProjectInviteConfirmNew)
  project_invite_confirm_new({}: StateContext<ProjectsStateModel>, action: ProjectInviteConfirmNew) {
    return this.projectsService.projectConfirmNew({ body: action.payload }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.localStorage.set('x-session-uuid', result.session);
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(ProjectRestore)
  project_restore({ patchState }: StateContext<ProjectsStateModel>, action: ProjectRestore) {
    return this.projectsService.projectRestore({ id: action.payload }).pipe(
      tap((res) => {
        patchState({ restoreProject: res });
      }),
    );
  }

  /**
   * Update project avatar image
   * @param getState
   * @param patchState
   * @param action
   */
  @Action(ProjectUpdateAvatar)
  projectUpdateAvatar({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectUpdateAvatar) {
    const projects = this.deepClone(getState().projects);
    const filteredProject = (item) => item._id === action.payload.projectId;
    projects.find(filteredProject).avatarUrl = action.payload.avatarUrl;
    return patchState({ projects });
  }

  @Action(ProjectToggleArchiveStatus)
  archive_project({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectToggleArchiveStatus) {
    return this.projectsService.projectToggleArchiveStatus(action.payload).pipe(
      tap(
        (res) => {
          // const { spaces, chatMenuSpacesVisibility } = getState();
          // const spacesVisibility = { ...chatMenuSpacesVisibility };
          // const allSpaces = spaces.filter((i) => i._id !== action.payload.id);
          //
          // delete spacesVisibility?.[action.payload.id];
          //
          // patchState({
          //   spacesLoaded: true,
          //   chatMenuSpacesVisibility: spacesVisibility,
          //   spaces: allSpaces,
          // });
          // this.localStorage.set('chatMenuSpacesVisibility', spacesVisibility);
          // this.localStorage.set('spaces', allSpaces);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update avatar image upload loading of a project
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectSetAvatarImageUploadLoading)
  setSpaceAvatarImageUploadLoading(
    { getState, patchState }: StateContext<ProjectsStateModel>,
    action: ProjectSetAvatarImageUploadLoading,
  ) {
    const projects = this.deepClone(getState().projects);
    const filteredSpace = (item) => item._id === action.payload.id;
    projects.find(filteredSpace).avatarImageUploadLoading = action.payload.status;
    return patchState({ projects });
  }

  /**
   * Set emoji state - open/closed
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsEmojiPicker} action
   */
  @Action(ProjectsEmojiPicker)
  projects_emoji_picker({ patchState }: StateContext<ProjectsStateModel>, action: ProjectsEmojiPicker) {
    patchState({
      projectEmojiIsOpen: action.payload.projectEmojiPickerIsOpen,
    });
  }

  /**
   * Set emoji state - open/closed
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsSetEmoji} action
   */
  @Action(ProjectsSetEmoji)
  projects_set_emoji({ patchState }: StateContext<ProjectsStateModel>, action: ProjectsSetEmoji) {
    patchState({
      emoji: action.payload.selectedEmoji,
    });
  }

  private deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * unassign projects members action handler
   * @param  {getState, setState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectsUnassignMember)
  unassign_projects_members(
    { patchState, getState }: StateContext<ProjectsStateModel>,
    action: ProjectsUnassignMember,
  ) {
    return this.projectsService.projectUnassign(action.payload).pipe(
      tap(
        (res) => {
          const prevState = getState();
          patchState({
            ...prevState,
            users: prevState.users.filter((item) => item['_id'] !== action.payload.userId),
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * projects revoke invite action handler
   * @param  {getState, setState}: StateContext<ProjectsStateModel>
   * @param action
   */
  @Action(ProjectsRevokeInvite)
  projects_revoke_invite({ patchState, getState }: StateContext<ProjectsStateModel>, action: ProjectsRevokeInvite) {
    return this.projectsService.projectRevokeUserInvite(action.payload).pipe(
      tap(
        (res) => {
          const prevState = getState();

          switch (action.payload.status) {
            case 'Pending':
              patchState({
                ...prevState,
                pendingUsers: prevState.pendingUsers.filter((item) => item['_id'] !== action.payload.userId),
              });
              break;
            case 'Newly invited':
              patchState({
                ...prevState,
                notActivatedUsers: prevState.notActivatedUsers.filter((item) => item['email'] !== action.payload.email),
              });

              break;
            default:
              return;
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectOrderUpdate} action
   */
  @Action(ProjectOrderUpdate)
  update_project_order({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectOrderUpdate) {
    const { id, project } = action.payload;
    return this.projectsService.projectMembersUpdateOrder({ id, body: project });
  }

  @Action(ProjectOrders)
  update_order_projects({ getState, setState }: StateContext<ProjectsStateModel>, action: ProjectOrders) {
    const { requestUuid, projects } = getState();
    let uniqueRequestId = '';
    if (requestUuid) {
      uniqueRequestId = requestUuid;
    } else {
      uniqueRequestId = uuidv4();
    }

    const updatedProjects = action.payload.projects
      .filter((project) => projects.some((p) => p._id === project._id))
      .map((project) => {
        return updateItem(
          (p: any) => p._id === project._id,
          patch({
            order: project.order,
          }),
        );
      });

    setState(
      patch({
        projects: compose(...updatedProjects),
        requestUuid: uniqueRequestId,
      }),
    );

    // Name projectsService."projectGetList_1" is generated by api
    // Its api for update orders for projects
    return this.projectsService.projectsOrderUpdate({ body: { ...action.payload, requestId: uniqueRequestId } });
  }

  /**
   * Leave project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectLeave} action
   */
  @Action(ProjectLeave)
  leave_project({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectLeave) {
    const { id } = action.payload;
    return this.projectsService.projectLeave({ id }).pipe(
      tap((res) => {
        const prevState = getState();
        const currentsProject = prevState.projects.filter((project) => project._id !== action.payload.id);
        patchState({
          ...prevState,
          projects: currentsProject,
        });
      }),
    );
  }

  /**
   * Project member delete action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectMemberDelete} action
   */
  @Action(ProjectMemberDelete)
  project_member_delete({ getState, patchState }: StateContext<ProjectsStateModel>, action: ProjectMemberDelete) {
    const { projectId, userId, isCurrentUserDeleted } = action.payload;

    const prevState = getState();

    if (isCurrentUserDeleted) {
      const currentProjects = prevState.projects.filter((project) => project._id !== projectId);
      localStorage.setItem('projects', JSON.stringify(currentProjects));
      patchState({
        ...prevState,
        projects: currentProjects,
      });
    } else {
      patchState({
        ...prevState,
        users: prevState.users.filter((user) => user._id !== userId),
      });
    }
  }
}
