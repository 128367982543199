import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';

import { REGEXP_EMAIL } from '../../shared/data/regexp';
import { QuillModulesForDescription, QuillModulesForChat } from '../../shared/data/quill-configuration';
import { UsersDbDto } from '../../api/models/users-db-dto';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { VideoCallsGetResDto } from '../../api/models/video-calls-get-res-dto';
import { CalendarEvent } from '../../shared/components/calendar/calendar-utils-custom';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { CheckPermissionPipe } from '../../shared/pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';
import { HtmlHelper } from '../../shared/utils/html-helper';
import { DateTimeHelper } from '../../shared/utils/date-time-helper';
import { RouterQueryService } from '../../shared/services/router-query.service';
import { QuillInitializeService } from '../../shared/services/quill/quill-init.service';
import { SocketsService } from '../../shared/services/sockets.service';
import { ConfigService } from '../../shared/services/config.service';
import {
  CalendarEventCreate,
  CalendarEventDelete,
  CalendarEventsGetById,
  CalendarEventsUpdate,
  CalendarEventsUpdateStatus,
  CalendarEventsStopRemind,
  CalendarEventDeleteSingle,
  CalendarEventsUpdateSingle,
  CalendarEventRemove,
  CalendarEventExceptions,
} from '../../shared/store/actions/calendar-events.action';
import { OpenVideoCallMobile } from '../../shared/store/actions/modals.action';
import { GetAttachedUsersList } from '../../shared/store/actions/users.action';
import { SpaceGetUsersList } from '../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../shared/store/actions/projects.action';
import { VideoCallsGetInviteLink } from '../../shared/store/actions/video-calls.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { ChatsState } from '../../shared/store/states/chats.state';
import { UsersState } from '../../shared/store/states/users.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { CalendarEventsState } from '../../shared/store/states/calendar-events.state';
import { VideoCallsState } from '../../shared/store/states/video-calls.state';
import { VideoCallModalComponent } from '../video-call/video-call-modal.component';
import { SpacesDbDto } from '../../api/models/spaces-db-dto';
import { FullImagePreviewService } from '../../shared/services/full-image-preview.service';
import { TranslocoService } from '@ngneat/transloco';
import { MediaService } from '../../shared/services/media.service';
import { PreviewMediaComponent } from '../data-room-modals/preview-media/preview-media.component';
import { PinType } from '../../shared/components/chat/chat-pinned-messages/enums/pin-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MinimizeService } from '../../shared/services/minimize.service';

export enum TabIndexesTypes {
  Input = 'input',
  Select = 'select',
  QuillEditor = 'quill-editor',
  Button = 'button',
}

export enum CalendarModalActions {
  AddNewEvent = 'Add new event',
  ViewEvent = 'View event',
}

export enum CalendarControlTypes {
  Users = 'users',
  Spaces = 'spaces',
  Projects = 'projects',
}

export enum CalendarRepeatTypes {
  Never = 'never',
  Daily = 'daily',
  Workdays = 'workdays',
}

export enum ReminderTypes {
  Without = 'without',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-calendar-event-modal',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventModalComponent implements OnInit, OnDestroy {
  @ViewChild('dataRoomImagePreviewModal') dataRoomImagePreviewModal;
  @ViewChild('descriptionQuill', { static: false }) quill: any;

  platform = 'web';
  platformOS = 'web';
  previewModal: NgbModalRef;

  destroy$ = new Subject<void>();
  eventData$: Subscription;
  refresh: Subject<any> = new Subject();

  eventTypes = [
    { object: 'users', title: 'User' },
    { object: 'spaces', title: 'Space' },
    { object: 'projects', title: 'Project' },
  ];

  modalData: {
    action: string;
    object: string;
    objectId: string;
    displayName?: string;
    event: CalendarEvent;
    status?: string;
    chatMessageId?: string;
    noteId?: string;
    stopChatReminder?: boolean;
  };

  initEvent: any;

  tz: string = null;
  currTz: string = null;
  currTzAbbr = null;
  timezones: any[];
  eventMedia = [];
  spaces: Observable<any[]>;
  projects: Observable<any[]>;
  users: any[];
  space: SpacesDbDto;
  userData: UsersPublicFieldsResDto;
  workdaysToggleButton = false;
  currentCallRoom: any;
  activeCallRooms: VideoCallsGetResDto[];

  isEndTimeInputValid = false;
  isStartTimeInputValid = false;
  readonly repeatTypes = [
    { id: 'never', name: this.translocoService.translate('modals.calendar-event.never') },
    { id: 'daily', name: this.translocoService.translate('modals.calendar-event.daily') },
    { id: 'weekly', name: this.translocoService.translate('modals.calendar-event.weekly') },
    { id: 'every 2 weeks', name: this.translocoService.translate('modals.calendar-event.two-week') },
    { id: 'monthly', name: this.translocoService.translate('modals.calendar-event.monthly') },
    { id: 'yearly', name: this.translocoService.translate('modals.calendar-event.yearly') },
  ];

  readonly reminderTypes = [
    { id: 'without', name: this.translocoService.translate('modals.calendar-event.without') },
    { id: '0m', name: this.translocoService.translate('modals.calendar-event.at-the-time') },
    { id: '5m', name: this.translocoService.translate('modals.calendar-event.five-min') },
    { id: '10m', name: this.translocoService.translate('modals.calendar-event.ten-min') },
    { id: '15m', name: this.translocoService.translate('modals.calendar-event.fifteen-min') },
    { id: '30m', name: this.translocoService.translate('modals.calendar-event.thirty-min') },
    { id: '1h', name: this.translocoService.translate('modals.calendar-event.one-hour') },
    { id: '2h', name: this.translocoService.translate('modals.calendar-event.two-hour') },
    { id: '1d', name: this.translocoService.translate('modals.calendar-event.one-day') },
    { id: '2d', name: this.translocoService.translate('modals.calendar-event.two-day') },
    { id: '1w', name: this.translocoService.translate('modals.calendar-event.one-week') },
  ];

  private handleEscape = false;
  private handleTabPress = false;
  private curTabIndex = 0;
  private maxTabIndex = 0;
  private maxTabIndexCalendarButtons = 18;
  private maxTabIndexDeclineButtons = 3;

  tabIndexes: { tabIndex?: string; type?: string; actionOnFocused?: string; skip?: boolean }[];
  tabIndexesDeclineButtons: { tabIndex?: string }[];
  skipEscapeControls: string[];

  sendEmailCtrlFocused = false;
  allDayCtrlFocused = false;
  onlyWorkdaysCtrlFocused = false;
  notificationCheckboxCtrFocused = false;
  invitedUser = false;
  addGuestPromise: (label: any) => Promise<any>;

  editorModules = {};
  editorCustomModules = {
    toolbar: false,
    magicUrl: true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: function () {
            return false;
          },
        },
      },
    },
  };

  config: any = {};
  editorDescription: any;
  toggleDescriptionEditVisibility = false;
  togglePlaceEditVisibility = false;
  editorPlace: any;
  showStopReminderButton = false;
  showRemindChatControl = true;
  eventPreviousRepeatState = null;
  eventPreviousStart = null;
  eventOriginalStart: Date = null;
  eventOriginalEnd: Date = null;
  eventCreator: any;
  eventCreatorName: string;
  handleRepeatAfterAllDayChanges = false;
  notRepeatableEvent = false;
  showRepeatEventAtControl = false;
  isPastDate = false;

  constructor(
    private actions: Actions,
    private modalsService: NgbModal,
    private activeModal: NgbActiveModal,
    private store: Store,
    private toastr: ToastrService,
    private checkPermissionPipe: CheckPermissionPipe,
    private routerTenantPipe: RouterTenantPipe,
    private ref: ChangeDetectorRef,
    private router: Router,
    private routerQueryService: RouterQueryService,
    private quillInitializeService: QuillInitializeService,
    private socketsService: SocketsService,
    private mediaService: MediaService,
    public configService: ConfigService,
    public dtHelper: DateTimeHelper,
    public htmlHelper: HtmlHelper,
    public fullImagePreviewService: FullImagePreviewService,
    private translocoService: TranslocoService,
    private minimizeService: MinimizeService,
  ) {
    this.config = this.configService.templateConf;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(e) {
    if (this.handleTabPress) {
      if (e.shiftKey && e.keyCode === 9) {
        e.preventDefault();
        this.handleTabKey(false);
      } else if (e.keyCode === 9) {
        e.preventDefault();
        this.handleTabKey(true);
      } else if (e.keyCode === 27) {
        if (this.handleEscape) {
          /* escape */
          if (!this.checkSkipEscape(this.tabIndexes[this.curTabIndex].tabIndex)) {
            e.preventDefault();
            this.close();
          }
        }
      } else if (e.keyCode === 13 && e.ctrlKey) {
        if (!this.invitedUser) {
          e.preventDefault();
          switch (this.modalData.action) {
            case CalendarModalActions.AddNewEvent:
              this.addEventSubmit(this.modalData.event);
              break;
            case CalendarModalActions.ViewEvent:
              this.editEventSubmit(this.modalData.event);
              break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.getPlatform();
    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };

    this.setTabIndexes();
    this.setTabIndexesDeclineButtons();
    this.setSkipEscapeControls();
    this.deletedEventListener();
    this.initModalData();

    this.store
      .select(ChatsState.getActiveVideoCallRooms)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms: VideoCallsGetResDto[]) => {
        this.activeCallRooms = rooms;
        this.updateCurrentVideoCallRoom();
      });

    this.addGuestPromise = (guest) => {
      return new Promise((resolve, reject) => {
        if (REGEXP_EMAIL.test(guest)) {
          if (!this.users?.find((user) => user.email === guest)) {
            const userName = guest.toLowerCase();
            resolve({ _id: userName, userName });
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
    };

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.eventData$?.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  get isMobile(): boolean {
    return this.platform !== 'web';
  }

  get checkIsPersonal() {
    return this.modalData.object === 'spaces' ? this.space && !this.space.isPersonal : true;
  }

  get checkLocalizeEvent() {
    return this.translocoService.translate('calendar.add-new-event');
  }

  editorDescriptionCreated(editorDescription): void {
    this.editorDescription = editorDescription;
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  inputFocused(elemId): void {
    setTimeout(() => {
      document.getElementById(`${elemId}`)?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }, 1000);
  }

  private addSpaceAvatarForProjects(spaces, item) {
    const space = spaces.find((s) => s?._id === item?.spaceId);

    if (!!space) {
      item = { ...item, space: { ...space, avatarUrl: space.avatarUrl } };
    }

    return item;
  }

  // TODO: freezed by the client, remove it later
  // { tabIndex: 'tab-index-stop-remind', type: 'button', skip: false },
  // private maxTabIndexCalendarButtons = 17; set it to 18 if enabled

  private setTabIndexes(): void {
    this.tabIndexes = [
      { tabIndex: 'tab-index-title', type: '' }, // 0
      { tabIndex: 'tab-index-description', type: 'quill-editor' }, // 1
      { tabIndex: 'tab-index-place', type: 'quill-editor' }, // 2
      { tabIndex: 'tab-index-guests', type: 'select' }, // 3
      { tabIndex: 'tab-index-send-email', type: '' }, // 4
      { tabIndex: 'tab-index-day-date-1', type: '' }, // 5
      { tabIndex: 'tab-index-day-time-1', type: '' }, // 6
      { tabIndex: 'tab-index-day-date-2', type: '' }, // 7
      { tabIndex: 'tab-index-day-time-2', type: '' }, // 8
      { tabIndex: 'tab-index-all-day', type: '' }, // 9
      { tabIndex: 'tab-index-timezone', type: 'select' }, // 10
      { tabIndex: 'tab-index-repeat', type: 'select' }, // 11
      { tabIndex: 'tab-index-stop-repeat-at', type: '', skip: true },
      { tabIndex: 'tab-index-repeat-work-days', type: '' },
      { tabIndex: 'tab-index-remind', type: 'select' },
      { tabIndex: 'tab-index-stop-remind-checkbox', type: '', skip: true },
      { tabIndex: 'tab-index-save', type: 'button' },
      { tabIndex: 'tab-index-create', type: 'button' },
      { tabIndex: 'tab-index-cancel', type: 'button' },
      { tabIndex: 'tab-index-delete', type: 'button' },
    ];
  }

  private setTabIndexesDeclineButtons(): void {
    this.tabIndexesDeclineButtons = [
      { tabIndex: 'tab-index-cancel-button' },
      { tabIndex: 'tab-index-accept-button' },
      { tabIndex: 'tab-index-maybe-button' },
      { tabIndex: 'tab-index-decline-button' },
      { tabIndex: 'tab-index-fake-button' },
    ];
  }

  private setSkipEscapeControls(): void {
    this.skipEscapeControls = ['tab-index-guests', 'tab-index-timezone', 'tab-index-repeat', 'tab-index-remind'];
  }

  private handleSkipControl(curTabIndex: number, forward: boolean): void {
    if (this.tabIndexes[curTabIndex]?.skip) {
      if (!forward) {
        this.curTabIndex > 0 ? this.curTabIndex-- : (this.curTabIndex = this.maxTabIndex);
        this.handleSkipControl(this.curTabIndex, false);
      } else {
        this.curTabIndex < this.maxTabIndex ? this.curTabIndex++ : (this.curTabIndex = 0);
        this.handleSkipControl(this.curTabIndex, true);
      }
    }
  }

  private handleInvitedUser(control: any): void {
    setTimeout(() => control.focus(), 0);
  }

  private handleOwnerUser(control: any): void {
    try {
      if (this.tabIndexes[this.curTabIndex].type === TabIndexesTypes.Select) {
        this.setFocusOnObjectSelect(this.tabIndexes[this.curTabIndex].tabIndex);
      } else if (this.tabIndexes[this.curTabIndex].type === TabIndexesTypes.QuillEditor) {
        switch (this.tabIndexes[this.curTabIndex].tabIndex) {
          case 'tab-index-description':
            this.toggleDescriptionEditVisibility = true;
            setTimeout(() => this.editorDescription.focus(), 0);
            break;
          case 'tab-index-place':
            this.togglePlaceEditVisibility = true;
            setTimeout(() => this.editorPlace.focus(), 0);
            break;
        }
      } else {
        control.focus();
      }
    } catch (error) {}
  }

  private handleTabKey(forward: boolean): void {
    let control: any;

    if (!forward) {
      this.curTabIndex > 0 ? this.curTabIndex-- : (this.curTabIndex = this.maxTabIndex);
    } else {
      this.curTabIndex < this.maxTabIndex ? this.curTabIndex++ : (this.curTabIndex = 0);
    }

    this.handleSkipControl(this.curTabIndex, forward);

    if (this.invitedUser) {
      control = <HTMLInputElement>(
        document.querySelector('[data-tabindex="' + this.tabIndexesDeclineButtons[this.curTabIndex].tabIndex + '"]')
      );
      this.handleInvitedUser(control);
    } else {
      control = <HTMLInputElement>(
        document.querySelector('[data-tabindex="' + this.tabIndexes[this.curTabIndex].tabIndex + '"]')
      );
      this.handleOwnerUser(control);
    }
  }

  setCurrentTz() {
    if (this.modalData?.event) {
      this.modalData.event.timezone = this.tz;
    }
  }

  deletedEventListener(): void {
    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(CalendarEventRemove, CalendarEventExceptions))
      .subscribe((action) => {
        if (action.payload._id === this.modalData?.event._id) {
          this.closeHandler();
          if (this.userData._id !== this.modalData.event.userId) {
            this.toastr.info(
              this.translocoService.translate('toastr.info-event-has-been-removed'),
              this.translocoService.translate('toastr.title-attention'),
            );
          }
        }
      });
  }

  private setFocusOnObjectSelect(object: string): void {
    const element = document
      .querySelector(`[data-tabindex="${object}"]`)
      .firstElementChild.firstElementChild.lastElementChild.getElementsByTagName('input');

    if (element.item(0)) {
      element.item(0).focus();
    }
  }

  switchFocused(elem: string, focused: boolean) {
    switch (elem) {
      case 'sendEmail':
        this.sendEmailCtrlFocused = focused;
        break;
      case 'allDay':
        this.allDayCtrlFocused = focused;
        this.handleRepeatAfterAllDayChanges = true;
        break;
      case 'onlyWorkdays':
        this.onlyWorkdaysCtrlFocused = focused;
        break;
      case 'notification':
        this.notificationCheckboxCtrFocused = focused;
        break;
      default:
        break;
    }
  }

  switchPressKey(elem) {
    switch (elem) {
      case 'sendEmail':
        this.modalData.event.sendEmail = !this.modalData.event.sendEmail;
        break;
      case 'allDay':
        this.modalData.event.allDay = !this.modalData.event.allDay;
        this.setAllDayTimeSkip(this.modalData.event.allDay);
        break;
      case 'onlyWorkdays':
        this.modalData.event.workdays = !this.modalData.event.workdays;
        break;
      case 'notification':
        this.modalData.event.remindAsChatMessage = !this.modalData.event.remindAsChatMessage;
        break;
      default:
        break;
    }
  }

  private setAllDayTimeSkip(allDay: boolean): void {
    this.setElementSkip('tab-index-day-time-1', allDay);
    this.setElementSkip('tab-index-day-time-2', allDay);
  }

  private setOnlyWorkdaysSkip(workdays: boolean): void {
    this.setElementSkip('tab-index-repeat-work-days', workdays);
  }

  private skipOnlyWorkdays(): void {
    if (CalendarModalActions.ViewEvent) {
      switch (this.modalData?.event.repeat) {
        case CalendarRepeatTypes.Workdays:
        case CalendarRepeatTypes.Daily:
          this.setOnlyWorkdaysSkip(false);
          break;
        default:
          this.setOnlyWorkdaysSkip(true);
          break;
      }
    }
  }

  private setAddEditButtonsSkip(addMode: boolean): void {
    this.setElementSkip('tab-index-save', addMode);
    this.setElementSkip('tab-index-delete', addMode);
    this.setElementSkip('tab-index-create', !addMode);
  }

  private setUserSpaceProjectControls(event: CalendarEvent): void {
    switch (event.object) {
      case CalendarControlTypes.Spaces:
        this.modalData.event.spaceId = event.objectId;
        break;
      case CalendarControlTypes.Projects:
        this.modalData.event.projectId = event.objectId;
        break;
    }
  }

  private setElementSkip(tabIndex: string, skip: boolean): void {
    this.tabIndexes.forEach((element) => {
      if (element.tabIndex === tabIndex) {
        element.skip = skip;
      }
    });
  }

  blurEditor(event, type) {
    const text = event.editor.root.innerHTML;
    if (text?.replace(/<[^>]+>/g, '').trim() !== '' || /<img /.test(text)) {
      if (type === 'description') {
        this.toggleDescriptionEditVisibility = false;
      }
    }
  }

  controlClicked(dataTabindex: string): void {
    this.handleRepeatAfterAllDayChanges = true;
    const control = this.tabIndexes.find((element, index) => {
      if (element.tabIndex === dataTabindex) {
        this.curTabIndex = index;
        return element.tabIndex === dataTabindex;
      }
    });
  }

  checkSkipEscape(dataTabindex: string): boolean {
    return this.skipEscapeControls.includes(dataTabindex);
  }

  dataIsChanged(): void {
    this.initEvent.end = moment(this.initEvent.end).toDate();
    this.initEvent.start = moment(this.initEvent.start).toDate();
  }

  /**
   * Close modal handler
   */
  close() {
    if (JSON.stringify(this.modalData.event) !== JSON.stringify(this.initEvent)) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
              if(this.modalData?.action === 'Add new event') {
                console.log('nowy');
                this.addEventSubmit(this.modalData.event);
              } else {
                console.log('edycja');
                this.editEventSubmit(this.modalData.event);
              }
          }
        },
        () => {},
      );
    } else {
      this.closeHandler();
    }
  }

  closeHandler(): void {
    this.resetFlags();

    this.skipNotificationCheckbox(true);

    this.routerQueryService.update({ event: null });
    this.activeModal.close();
  }

  private resetFlags(): void {
    this.handleTabPress = false;
    this.invitedUser = false;
    this.handleEscape = false;
    this.showRemindChatControl = false;
    this.eventPreviousRepeatState = null;
    this.handleRepeatAfterAllDayChanges = false;
    this.notRepeatableEvent = false;
    this.showRepeatEventAtControl = false;
    this.eventPreviousStart = null;
    this.eventOriginalStart = null;
    this.eventOriginalEnd = null;
  }

  emptyUsersList() {
    this.users = null;
    if (this.modalData?.event) {
      this.modalData.event.guests = null;
    }
  }

  attachUsersList({ object, objectId }, isEdit = false) {
    let selector: Observable<UsersDbDto[]>;

    if (objectId) {
      if (object === CalendarControlTypes.Spaces) {
        this.store.dispatch(new SpaceGetUsersList({ id: objectId, exists: true }));

        selector = this.store.select(SpacesState.getUsers);
        this.modalData.event.projectId = null;
      } else if (object === CalendarControlTypes.Projects) {
        this.store.dispatch(new ProjectGetUsersList({ id: objectId, exists: true }));

        selector = this.store.select(ProjectsState.getUsers);
        this.modalData.event.spaceId = null;
      }

      selector
        .pipe(
          takeUntil(this.destroy$),
          map((users: []) =>
            this.userData?._id === this.eventCreator
              ? users?.filter((item) => this.userData?._id !== item['_id'])
              : users,
          ),
        )
        .subscribe((users) => {
          this.users = users?.filter((user: any) => user.userName !== 'Quinn');
          if (isEdit) {
            this.addUsersToGuests(this.modalData.event.calendarEventMembers);
          } else {
            this.addAllUsersToGuests();
          }
        });
    } else if (object === CalendarControlTypes.Users) {
      this.store.dispatch(new GetAttachedUsersList({}));

      this.store
        .select(UsersState.getAttachedUsers)
        .pipe(
          takeUntil(this.destroy$),
          map((users: []) =>
            this.userData?._id === this.eventCreator
              ? users?.filter((item) => this.userData?._id !== item['_id'])
              : users,
          ),
        )
        .subscribe((users) => {
          this.users = users?.filter((user: any) => user.userName !== 'Quinn');
          if (isEdit) {
            this.addUsersToGuests(this.modalData.event.calendarEventMembers);
          }
        });
    } else {
      this.emptyUsersList();
    }
  }

  addAllUsersToGuests(): void {
    this.modalData.event.guests = [];

    this.users.forEach((user: any) => {
      if (this.userData?._id !== user._id) {
        this.modalData.event.guests.push(user._id);
      }
    });

    this.modalData.event.guests = JSON.parse(JSON.stringify(this.modalData.event.guests));
  }

  addUsersToGuests(members: any[] = []): void {
    this.modalData.event.guests = [];

    this.users.forEach((user: any) => {
      const member = members.find((m) => m.userId === user._id);
      if (member) {
        this.modalData.event.guests.push(user._id);
      }
    });

    const emails = [];
    this.modalData.event.externalEmails.forEach((item) => {
      emails.push({ _id: item, userName: item });
    });

    this.users = [...this.users, ...emails];

    this.modalData.event.guests = [
      ...JSON.parse(JSON.stringify(this.modalData.event.guests)),
      ...this.modalData.event.externalEmails,
    ];
    this.initEvent = { ...this.modalData.event };
  }

  changeSelectedEventType(type) {
    if (type.object === 'users') {
      this.userBtnClicked();
    } else if (type.object === 'spaces') {
      this.spacesBtnClicked();
    } else if (type.object === 'projects') {
      this.projectsBtnClicked();
    }
  }

  userBtnClicked() {
    if (this.modalData.event.object !== CalendarControlTypes.Users) {
      this.attachUsersList({ object: CalendarControlTypes.Users, objectId: null });
    }

    this.modalData.event.object = CalendarControlTypes.Users;
    this.modalData.event.objectId = null;
    this.modalData.event.spaceId = null;
    this.modalData.event.projectId = null;
    this.modalData.event.guests = [];

    this.skipNotificationCheckbox(true);
    this.modalData.event.remindAsChatMessage = false;

    setTimeout(() => this.setFocusOnObjectSelect('tab-index-' + CalendarControlTypes.Users), 0);
  }

  spacesBtnClicked() {
    this.emptyUsersList();
    this.modalData.event.object = CalendarControlTypes.Spaces;

    this.modalData?.event?.reminder === ReminderTypes.Without
      ? this.skipNotificationCheckbox(true)
      : this.skipNotificationCheckbox(false);

    setTimeout(() => this.setFocusOnObjectSelect('tab-index-' + CalendarControlTypes.Spaces), 0);
  }

  projectsBtnClicked() {
    this.emptyUsersList();
    this.modalData.event.object = CalendarControlTypes.Projects;

    this.modalData?.event?.reminder === ReminderTypes.Without
      ? this.skipNotificationCheckbox(true)
      : this.skipNotificationCheckbox(false);

    setTimeout(() => this.setFocusOnObjectSelect('tab-index-' + CalendarControlTypes.Projects), 0);
  }

  setEventStatus(payload: { status: 'maybe' | 'accepted' | 'declined'; id: string }) {
    this.store.dispatch(new CalendarEventsUpdateStatus(payload));
    this.getEventData(this.modalData.action, this.modalData.event, false);

    this.curTabIndex = 4;
  }

  allDayChange(): void {
    this.setRepeatAfterAllDayChanges(null, null);
  }

  private setRepeatAfterAllDayChanges(start, end): void {
    if (this.handleRepeatAfterAllDayChanges) {
      if (this.modalData.event.allDay) {
        this.notRepeatableEvent = false;
      } else if (start !== null && end !== null) {
        this.notRepeatableEvent = moment(start).format('YYYY-MM-DD') !== moment(end).format('YYYY-MM-DD');
      }
    }
  }

  compareDates(start, end): void {
    if (moment(start).isSameOrAfter(end)) {
      this.modalData.event.end = moment(start).add(30, 'minutes').toDate();
    }
    this.setRepeatAfterAllDayChanges(start, end);
  }

  getEventData(action: string, event: CalendarEvent, eraseModal = true) {
    if (eraseModal) {
      this.modalData.event = null;
    }

    const id = event.id ? event.id : event._id;
    this.eventData$ = this.store
      .dispatch(new CalendarEventsGetById({ id }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          const res = this.store.selectSnapshot(CalendarEventsState.getEvent);
          const result: any = { ...res };

          // receiving an event's initial start/end, not current ones while opening it
          let _result = JSON.parse(JSON.stringify(result));
          this.eventOriginalStart = _result.start;
          this.eventOriginalEnd = _result.end;

          _result = null;

          if (res) {
            const eventStartEnd: any = this.prepareStartEnd(event, res);
            result.start = eventStartEnd.start;
            result.end = eventStartEnd.end;

            const eventMember = result.calendarEventMembers.find(({ userId }) => this.userData?._id === userId);
            result.guests = [];

            if (result.calendarEventMembers?.length > 1) {
              result.calendarEventMembers = result.calendarEventMembers?.reduce((acc, member) => {
                if (member.userId === result.userId && result.userId === this.userData._id) {
                  acc.unshift(member);
                } else {
                  acc.push(member);
                }
                return acc;
              }, []);
            }

            if (!result.place) {
              result.place = '';
            }
            if (!result.description) {
              result.description = '';
            }
            if (result.object === 'spaces') {
              result.spaceId = result.objectId;
            }
            if (result.object === 'projects') {
              result.projectId = result.objectId;
            }

            this.modalData = {
              event: {
                ...result,
                start: this.dtHelper.convertDateForTz(this.currTz, result.start),
                end: this.dtHelper.convertDateForTz(this.currTz, result.end),
              },
              action,
              status: eventMember?.status,
              object: this.modalData.object,
              objectId: this.modalData.objectId,
              stopChatReminder: this.modalData?.stopChatReminder,
              displayName: this.modalData?.displayName,
            };

            this.showRepeatEventAtControl = res.repeat !== CalendarRepeatTypes.Never;
            this.eventCreator = result.userId;
            this.store
              .select(UsersState.getUsersInfo)
              .pipe(takeUntil(this.destroy$))
              .subscribe((users) => {
                const userWithName: any = users[this.eventCreator];
                if (userWithName?.userName) {
                  this.eventCreatorName = userWithName.userName;
                }
              });

            this.eventPreviousRepeatState = this.modalData.event.repeat;
            this.eventPreviousStart = this.modalData.event.start;

            this.initStopRepeatAtControl(this.modalData?.event);

            if (this.checkIfStopChatReminder(this.modalData)) {
              this.stopEventReminder(id);
            }

            this.initChatReminderControl(this.modalData);

            this.attachUsersList({ object: this.modalData.object, objectId: this.modalData.objectId }, true);

            /* handling tab key action */
            this.prepareTabControls();
            this.initWorkdaysToggler();

            // TODO: freezed by the client, remove it later
            // this.initStopReminderButton();

            this.updateCurrentVideoCallRoom();
            if (this.platform === 'web') {
              this.setFocusOnTitle();
            }
            this.ref.detectChanges();
          }
        },
        (error) => {
          this.handleDeclinedRemovedEvent(error);
        },
      );
  }

  private initChatReminderControl(modalData: any) {
    if (modalData?.event?.reminder === ReminderTypes.Without) {
      this.skipNotificationCheckbox(true);
      this.showRemindChatControl = false;
    } else {
      this.skipNotificationCheckbox(false);
      this.showRemindChatControl = true;
    }
  }

  private initWorkdaysToggler(): void {
    switch (this.modalData?.event?.repeat) {
      case CalendarRepeatTypes.Daily:
        this.workdaysToggleButton = true;
        break;
      case CalendarRepeatTypes.Workdays:
        this.modalData.event.repeat = CalendarRepeatTypes.Daily;
        this.workdaysToggleButton = true;
        this.modalData.event.workdays = true;
        break;
      default:
        this.workdaysToggleButton = false;
        this.modalData.event.workdays = false;
        break;
    }
  }

  // TODO: freezed by the client, remove it later
  /* private initStopReminderButton(): void {
    if (moment().toDate() < moment(this.modalData.event.start).toDate() && (this.modalData.event.reminder !== ReminderTypes.Without)) {
      this.showStopReminderButton = true;
    }
  } */

  private prepareTabControls(): void {
    this.invitedUser = this.checkInvitedUser();
    this.maxTabIndex = this.invitedUser ? this.maxTabIndexDeclineButtons : this.maxTabIndexCalendarButtons;

    if (this.invitedUser) {
      setTimeout(() => {
        const control = <HTMLInputElement>(
          document.querySelector('[data-tabindex="' + this.tabIndexesDeclineButtons[this.curTabIndex].tabIndex + '"]')
        );
        this.handleInvitedUser(control);
        this.setUserSpaceProjectControls(this.modalData?.event);
      }, 0);
    } else {
      this.setAllDayTimeSkip(this.modalData?.event?.allDay);
      this.setUserSpaceProjectControls(this.modalData?.event);
      this.skipOnlyWorkdays();
    }
  }

  private resetControls(event: CalendarEvent): void {
    event.repeat = CalendarRepeatTypes.Never;
    event.reminder = ReminderTypes.Without;

    event.workdays = false;
    this.workdaysToggleButton = false;
    this.setOnlyWorkdaysSkip(!this.workdaysToggleButton);
  }

  private setFocusOnTitle(): void {
    if (this.handleTabPress) {
      setTimeout(() => {
        const control_ = <HTMLInputElement>(
          document.querySelector('[data-tabindex="' + this.tabIndexes[this.curTabIndex].tabIndex + '"]')
        );
        if (control_) {
          control_.focus();
        }
      }, 100);
    }
  }

  private initUserSpaceProject(modalData: any): void {
    switch (modalData.object) {
      case CalendarControlTypes.Spaces:
        this.modalData.event.spaceId = modalData.objectId;
        break;
      case CalendarControlTypes.Projects:
        this.modalData.event.projectId = modalData.objectId;
        break;
    }

    this.attachUsersList({ object: modalData.object, objectId: modalData.objectId });
  }

  private prepareStartEnd(event: any, res: any): any {
    const eventStartEnd: any = {};

    if (event.start) {
      eventStartEnd.start = moment(event.start).toDate();
      eventStartEnd.end = moment(event.end).toDate();
    } else {
      eventStartEnd.end = moment(res.end).toDate();
      eventStartEnd.start = moment(res.start).toDate();
    }

    return eventStartEnd;
  }

  private checkInvitedUser(): boolean {
    return (
      this.modalData?.event &&
      !!this.getOwnStatus(this.modalData?.event.calendarEventMembers) &&
      !(this.userData._id === this.modalData?.event.userId) &&
      this.modalData?.action === CalendarModalActions.ViewEvent
    );
  }

  changeHoursInDate(date) {
    const offset = moment.tz(this.tz).format('Z');
    const diff = moment(date).diff(moment(date).utcOffset(offset, true), 'minutes');

    return moment(date).add(diff, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  }

  getTimeIsoString(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z';
  }

  addEventSubmit(event) {
    // TODO: utilize the function like at the update event
    // see if (this.handleEventData(event)) {
    const stopRepeatAt = event?.stopRepeatAt;
    let objectId = undefined;

    if (!event.title) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-title'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!event.object) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-calendar-type'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (event.object === 'spaces') {
      objectId = this.modalData.event.spaceId;
      if (!objectId) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-empty-space'),
          this.translocoService.translate('toastr.title-error'),
        );
        return;
      }
    } else if (event.object === 'projects') {
      objectId = this.modalData.event.projectId;
      if (!objectId) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-empty-project'),
          this.translocoService.translate('toastr.title-error'),
        );
        return;
      }
    }

    if (!event.timezone) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-timezone'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!this.checkStartEndAreValidDates(event)) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-end-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (this.checkAllDayValidDates(event)) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-all-day-parameter'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (event.repeat === CalendarRepeatTypes.Daily && event.workdays) {
      event.repeat = CalendarRepeatTypes.Workdays;
    }

    if (event.allDay) {
      event.start = moment(event.start).format('YYYY-MM-DD 00:00:00.000+0:00');
      event.end = moment(event.end).format('YYYY-MM-DD 00:00:00.000+0:00');
    } else {
      if (moment(event.start).format('YYYY-MM-DD HH:mm:ss') !== moment(event.end).format('YYYY-MM-DD HH:mm:ss')) {
        if (this.currTz === this.tz) {
          event.start = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
          event.end = moment(event.end).format('YYYY-MM-DD HH:mm:ss');
        } else {
          event.start = this.getTimeIsoString(event.start);
          event.end = this.getTimeIsoString(event.end);
        }
      }

      if (stopRepeatAt) {
        event.stopRepeatAt = moment(stopRepeatAt).tz(this.currTz).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    const _event: any = {
      object: event.object,
      objectId,
      title: event.title,
      description: event.description,
      place: event.place,
      addCall: event.addCall,
      guests: event.guests?.filter((item) => !item.includes('@')),
      externalEmails: event.guests?.filter((item) => item.includes('@')),
      sendEmail: event.sendEmail,
      start: moment(event.start).toISOString(),
      end: moment(event.end).toISOString(),
      timezone: event.timezone || this.tz,
      allDay: event.allDay,
      repeat: event.repeat,
      reminder: event.reminder,
      stopRepeatAt: event.stopRepeatAt,
      chatMessageId: this.modalData.chatMessageId,
      noteId: this.modalData.noteId,
    };

    if (event?.remindAsChatMessage) {
      _event.remindAsChatMessage = event.remindAsChatMessage;
    }

    if (event.allDay) {
      _event.start = moment(event.start, 'YYYY-MM-DD 00:00:00.000+0:00').toISOString();
      _event.end = moment(event.end, 'YYYY-MM-DD 00:00:00.000+0:00').toISOString();
    }

    this.store
      .dispatch(new CalendarEventCreate(_event))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          this.closeHandler();
          this.toastr.success(
            this.translocoService.translate('toastr.event-created'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.ref.detectChanges();
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  setStartTimeInputValid(isValid: boolean): void {
    if (isValid !== this.isStartTimeInputValid) {
      this.isStartTimeInputValid = isValid;
      this.ref.detectChanges();
    }
  }

  setEndTimeInputValid(isValid: boolean): void {
    if (isValid !== this.isEndTimeInputValid) {
      this.isEndTimeInputValid = isValid;
      this.ref.detectChanges();
    }
  }

  private checkStartEndAreValidDates(event: CalendarEvent): boolean {
    return moment(event.start).isValid() && moment(event.end).isValid();
  }

  checkAllDayValidDates(event: CalendarEvent): boolean {
    if (event.end.getDate() === moment(event.start).add(1, 'days').toDate().getDate() && !event.end.getHours()) {
      return false;
    }
    return !event.allDay && event.start.getDay() !== event.end.getDay();
  }

  getOwnStatus(members: any[]): any {
    return members?.find(({ userId }) => userId === this.userData?._id);
  }

  changeRepetitionItem() {
    this.workdaysToggleButton = this.modalData?.event.repeat === CalendarRepeatTypes.Daily;
    this.showRepeatEventAtControl = this.modalData?.event.repeat !== CalendarRepeatTypes.Never;

    if (!this.workdaysToggleButton) {
      this.modalData.event.workdays = undefined;
    }

    if (this.modalData?.event.repeat === CalendarRepeatTypes.Never) {
      this.modalData.event.stopRepeatAt = null;
    }

    this.setOnlyWorkdaysSkip(!this.workdaysToggleButton);
  }

  editEventSubmit(event: CalendarEvent): void {
    if (!event.timezone) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-timezone'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (this.eventPreviousRepeatState !== CalendarRepeatTypes.Never) {
      // series event
      if (this.handleEventData(event)) {
        ConfirmAlert(null, {
          subject: this.translocoService.translate('alert.event-editing-subject'),
          text: this.translocoService.translate('alert.event-editing-text'),
          showDenyButton: true,
          denyButtonText: this.translocoService.translate('alert.event-editing-deny-btn'),
          confirmButtonText: this.translocoService.translate('alert.event-editing-confirm-btn'),
          cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
          confirmButtonClass: 'btn-success',
          denyButtonClass: 'btn-solid',
          platform: this.platform,
        }).then(
          (result) => {
            if (result === 'isConfirmed') {
              this.createSingleEventInSeries(event);
            } else if (result === 'isDenied') {
              this.updateEventSeries(event);
            }
          },
          () => {},
        );
      }
    } else {
      // single event
      if (this.handleEventData(event)) {
        this.updateSingleEvent(event);
      }
    }
  }

  private createSingleEventInSeries(event: CalendarEvent): void {
    this.store
      .dispatch(
        new CalendarEventsUpdateSingle({
          calendarEventId: event._id,
          exceptionDays: [event.start],
        }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          event.repeat = CalendarRepeatTypes.Never;
          this.addEventSubmit(event);
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  private updateSingleEvent(event: CalendarEvent): void {
    const _event =
      this.currTz === this.tz
        ? event
        : {
            ...event,
            start: this.getTimeIsoString(event.start),
            end: this.getTimeIsoString(event.end),
          };
    const body = {
      ..._event,
      guests: event.guests?.filter((item) => !item.includes('@')),
      externalEmails: event.guests?.filter((item) => item.includes('@')),
    };

    this.store
      .dispatch(new CalendarEventsUpdate({ id: event._id, body, object: this.modalData.object }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          this.closeHandler();
          this.toastr.success(
            this.translocoService.translate('toastr.event-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  private updateEventSeries(event: CalendarEvent, series: boolean = true): void {
    const seriesWord = series ? 'series' : '';
    let body: any = {};

    if (this.handleEventData(event)) {
      if (series) {
        body._id = event._id;
        body.userId = event.userId;
        body.object = event.object;
        body.objectId = event.objectId;
        body.place = event.place;
        body.guests = event.guests;
        body.addCall = event.addCall;
        body.allDay = event.allDay;
        body.start = event.start;
        body.end = event.end;
        body.timezone = event.timezone;
        body.repeat = event.repeat;
        body.stopRepeatAt = event.stopRepeatAt;
        body.reminder = event.reminder;
        body.remindAsChatMessage = event.remindAsChatMessage;
      } else {
        body = event;
      }

      if (event.repeat === CalendarRepeatTypes.Daily && event.workdays) {
        body.repeat = 'workdays';
      }

      // handle all-day events
      if (moment(event.start).format('YYYY-MM-DD') !== moment(event.end).format('YYYY-MM-DD')) {
        body.stopRepeatAt = moment(event.end).add(1, 'days').format('YYYY-MM-DD');
      } else {
        // handle repeatable events
        if (moment(event.start).isSame(this.eventPreviousStart)) {
          body.title = event.title;
          body.description = event.description;
        }

        if (event.stopRepeatAt) {
          body.stopRepeatAt = moment(event.stopRepeatAt).format('YYYY-MM-DD');
        }

        if (moment(event.start).isSame(this.eventOriginalStart) && moment(event.end).isSame(this.eventOriginalEnd)) {
          body.start = this.eventOriginalStart;
          body.end = this.eventOriginalEnd;
        } else {
          body.start = moment(this.eventOriginalStart)
            .hours(moment(event.start).hours())
            .minutes(moment(event.start).minute());
          body.end = moment(this.eventOriginalEnd).hours(moment(event.end).hours()).minutes(moment(event.end).minute());
        }
      }

      if (this.currTz !== this.tz) {
        body.start = this.getTimeIsoString(body.start);
        body.end = this.getTimeIsoString(body.end);
      }

      this.store
        .dispatch(new CalendarEventsUpdate({ id: event._id, body: body, object: this.modalData.object }))
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe(
          () => {
            if (!moment(event.start).isSame(this.eventPreviousStart)) {
              if (event.repeat !== CalendarRepeatTypes.Never) {
                this.closeHandler();
                this.toastr.success(
                  this.translocoService.translate('toastr.event-series-updated', { seriesWord }),
                  this.translocoService.translate('toastr.title-success'),
                );
              } else {
                this.addEventSubmit(event);
              }
            } else {
              this.closeHandler();
              this.toastr.success(
                this.translocoService.translate('toastr.event-series-updated', { seriesWord }),
                this.translocoService.translate('toastr.title-success'),
              );
            }
          },
          (err) => {
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
  }

  private handleEventData(event: CalendarEvent): boolean {
    let result = true;

    let object = undefined;
    let objectId = undefined;

    if (this.modalData.event.spaceId && this.modalData.event.projectId) {
      result = false;
    } else {
      if (this.modalData.event.spaceId) {
        object = 'spaces';
        objectId = this.modalData.event.spaceId;
      } else if (this.modalData.event.projectId) {
        object = 'projects';
        objectId = this.modalData.event.projectId;
      } else {
        object = 'users';
        objectId = null;
      }
    }

    this.modalData.event.object = object;
    this.modalData.event.objectId = objectId;

    if (!event.title) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-title'),
        this.translocoService.translate('toastr.title-error'),
      );
      result = false;
    }

    if (!this.checkStartEndAreValidDates(event)) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-end-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    return result;
  }

  deleteEvent(event: CalendarEvent, navigate = false): void {
    if (event.repeat === CalendarRepeatTypes.Never && this.eventPreviousRepeatState !== CalendarRepeatTypes.Daily) {
      ConfirmAlert(null, {
        platform: this.platform,
        subject: this.translocoService.translate('alert.delete-event', {
          value: this.translocoService.translate('modals.calendar-event.event'),
        }),
        text: this.translocoService.translate('alert.default-text'),
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
      }).then(
        () => {
          this.store
            .dispatch(new CalendarEventDelete({ id: event._id }))
            .pipe(takeUntil(this.destroy$), take(1))
            .subscribe(
              () => this.handleEventsDeleting(false, navigate),
              (err) => this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
            );
        },
        () => {},
      );
    } else {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.event-deleting-subject'),
        text: '',
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.event-series-deleting-deny-btn'),
        confirmButtonText: this.translocoService.translate('alert.event-deleting-confirm-btn'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        confirmButtonClass: 'btn-warning',
        denyButtonClass: 'btn-danger',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.deleteSingleEvent(event, navigate);
          } else if (result === 'isDenied') {
            this.deleteEventSeries(event._id, navigate);
          }
        },
        () => {},
      );
    }
  }

  private deleteSingleEvent(event: CalendarEvent, navigate = false): void {
    this.store
      .dispatch(
        new CalendarEventDeleteSingle({
          calendarEventId: event._id,
          exceptionDays: [event.start],
        }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => this.handleEventsDeleting(false, navigate),
        (err) => this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  private deleteEventSeries(eventId: string | number, navigate = false): void {
    this.store
      .dispatch(new CalendarEventDelete({ id: eventId }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => this.handleEventsDeleting(true, navigate),
        (err) => this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  handleEventsDeleting(isSeries: boolean, navigate: boolean): void {
    this.toastr.success(
      this.translocoService.translate('toastr.event-series-deleted', { text: isSeries ? 'series' : '' }),
      this.translocoService.translate('toastr.title-success'),
    );
    this.ref.detectChanges();
    if (navigate) {
      this.router.navigate([this.routerTenantPipe.transform('/calendar')]).then();
    } else {
      this.closeHandler();
    }
  }

  // TODO: frozen by the client, remove it later
  handleStopReminderButton(reminder: { id: string }): void {
    /* if (this.modalData.action === CalendarModalActions.ViewEvent) {
      this.showStopReminderButton = reminder.id === ReminderTypes.Without ? false : true;
      this.skipStopReminderButton(!this.showStopReminderButton);
    } */

    this.resetReminderControl(reminder);
  }

  private resetReminderControl(reminder: { id: string }): void {
    this.showRemindChatControl = reminder.id !== ReminderTypes.Without;

    this.skipNotificationCheckbox(!this.showRemindChatControl);
    if (!this.showRemindChatControl) {
      this.modalData.event.remindAsChatMessage = false;
    }
  }

  private checkIfStopChatReminder(modalData: { event: CalendarEvent }): boolean {
    if (this.modalData?.stopChatReminder) {
      if (modalData?.event?.stopRemind?.length) {
        const user = modalData?.event?.stopRemind.find((el) => el.userId === this.userData._id);
        return !user?.userId;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private skipStopReminderButton(skip: boolean): void {
    this.setElementSkip('tab-index-stop-remind', skip);
  }

  private stopEventReminder(id: any): void {
    this.store
      .dispatch(new CalendarEventsStopRemind({ id }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(() =>
        this.toastr.success(
          this.translocoService.translate('toastr.reminder-stopped'),
          this.translocoService.translate('toastr.title-success'),
        ),
      );
  }

  private initStopRepeatAtControl(event: CalendarEvent): void {
    if (event?.stopRepeatAt) {
      event.stopRepeatAt = moment(event?.stopRepeatAt).toDate();
      this.showRepeatEventAtControl = !!event?.stopRepeatAt;
    }
  }

  private skipNotificationCheckbox(skip: boolean): void {
    this.setElementSkip('tab-index-stop-remind-checkbox', skip);
  }

  editorMsClick($event): void {
    if ($event?.target?.innerHTML?.length) {
      if (this.checkIfUrl($event.target.innerHTML)) {
        window.open($event.target.innerHTML, '_blank');
      }
    }
  }

  checkIfUrl(url: string): boolean {
    const urlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (url.match(urlRegex)?.input) {
      return url.match(urlRegex).input === url.match(urlRegex)[0];
    } else {
      return false;
    }
  }

  private handleDeclinedRemovedEvent(error: { statusCode: number; message: string }): void {
    if (error.statusCode === 400) {
      this.closeHandler();
      this.toastr.error(error.message, this.translocoService.translate('toastr.title-error'));
    }
  }

  openVideoCall(): void {
    if (!this.store.selectSnapshot(VideoCallsState.getIsOpened)) {
      this.close();

      const user = this.store.selectSnapshot(AuthState.getUser);
      const roomId = this.modalData.event.videoCallId;

      if (this.platform !== 'web') {
        this.store.dispatch(new OpenVideoCallMobile(roomId));
      } else {
        const modalRef = this.modalsService.open(VideoCallModalComponent, {
          windowClass: 'cropper-modal video-call-modal',
          backdrop: false,
        });
        modalRef.componentInstance.callData = { roomId, user };
      }
    } else {
      this.toastr.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);
  }

  get checkIsMobile(): boolean {
    return this.platform !== 'web';
  }

  initModalData(): void {
    if (this.modalData.action) {
      this.curTabIndex = 0;
      this.handleTabPress = true;
      this.handleEscape = true;
    }

    this.spaces = this.store
      .select(SpacesState.getLoadedSpaces)
      .pipe(
        map((result) =>
          result.filter(
            (item) =>
              !item.isPersonal && this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
          ),
        ),
      );
    this.projects = combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ]).pipe(
      map(([spaces, projects]) =>
        projects
          .filter((item) => this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'))
          .map((item) => this.addSpaceAvatarForProjects(spaces, item)),
      ),
    );

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.userData = user;
          if (user?.timezone) {
            this.currTz = user.timezone;
            this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
          }
          this.tz = moment.tz.guess(true);
          this.timezones = this.dtHelper.getFormattedTimezones();
          this.eventCreator = this.userData._id;
          this.eventCreatorName = this.userData.userName;
          if (this.modalData?.event && !this.modalData?.event?.timezone) {
            this.modalData.event = { ...this.modalData.event, timezone: this.tz, sendEmail: true };
          }
        },
      });
    this.space = this.store
      .selectSnapshot(SpacesState.getLoadedSpaces)
      .find((space) => space._id === this.modalData.objectId);

    if (this.modalData.action === CalendarModalActions.ViewEvent) {
      this.getEventData(this.modalData.action, this.modalData.event);
      this.setAddEditButtonsSkip(false);
    } else {
      this.resetControls(this.modalData.event);
      this.prepareTabControls();

      this.setAddEditButtonsSkip(true);
      if (this.platform === 'web') {
        this.setFocusOnTitle();
      }

      this.initUserSpaceProject(this.modalData);
      this.skipStopReminderButton(true);

      this.initChatReminderControl(this.modalData);
    }
  }

  updateCurrentVideoCallRoom(): void {
    if (this.modalData?.event?.videoCallId) {
      this.currentCallRoom = this.activeCallRooms?.find(
        (room) => room.videoCallId === this.modalData.event.videoCallId,
      );
      if (this.currentCallRoom) {
        this.currentCallRoom = {
          ...this.currentCallRoom,
          callParticipants: [
            ...this.currentCallRoom.participants?.map((userId) => ({ isExternal: false, userId })),
            ...this.currentCallRoom.externalParticipants?.map((userId) => ({ isExternal: true, userId })),
          ],
        };
      }
      this.ref.markForCheck();
    }
  }

  copyVideoCallLink() {
    this.store.dispatch(new VideoCallsGetInviteLink(this.modalData.event.videoCallId)).subscribe((res) => {
      this.htmlHelper.copyValueToBuffer(res.VideoCalls.inviteLink);

      this.toastr.success(
        this.translocoService.translate('toastr.message-link-copied'),
        this.translocoService.translate('toastr.title-success'),
      );
    });
  }

  handleClickInEditor(event: MouseEvent, dataTabindex?: string) {
    if (event?.target['src']) {
      this.handleEditorImageClick(event.target);
      return;
    }

    if (dataTabindex) {
      this.controlClicked(dataTabindex);
    }
  }

  setIsPastDate(value: boolean) {
    this.isPastDate = value;
  }

  private handleEditorImageClick(target: any): void {
    if (
      !this.mediaService.checkIfAvatar(target?.classList) &&
      !!target.closest('.event-description') &&
      this.modalData &&
      this.modalData?.event?.description
    ) {
      const attachment = {
        url: target.src,
        fileName: 'image.jpg',
        originalFileName: 'image.jpg',
        ownerUserId: this.modalData?.event?.userId,
        updated_at: this.modalData?.event['updated_at'],
      };
      this.prepareMediaPreview();
      this.previewMedia(attachment);
    }
  }

  private prepareMediaPreview(): void {
    const imagesUrls = this.mediaService.fetchImagesFromString(this.modalData?.event.description);
    this.eventMedia = imagesUrls.map((url) => ({
      url: url,
      fileName: 'image.jpg',
      originalFileName: 'image.jpg',
      ownerUserId: this.modalData?.event?.userId,
      updated_at: this.modalData?.event['updated_at'],
    }));

    this.eventMedia[0].isFirstMedia = true;
    this.eventMedia[this.eventMedia.length - 1].isLastMedia = true;
  }

  private previewMedia(attachment): void {
    if (this.modalData?.event?.id || this.modalData?.event?._id) {
      const mediaPreviewModal = this.modalsService.open(PreviewMediaComponent, {
        size: 'xl',
        windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
        centered: true,
      });

      mediaPreviewModal.componentInstance.previewData = {
        platform: this.platform,
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        isMobile: this.isMobile,
        currentMedia: attachment,
      };

      mediaPreviewModal.componentInstance.isNotDataRoom = true;
      mediaPreviewModal.componentInstance.pinnedType = PinType.Files;
      mediaPreviewModal.componentInstance.media = this.eventMedia;

      mediaPreviewModal.closed.pipe(untilDestroyed(this)).subscribe(() => {
        let scrollTop = this.quill.quillEditor.scrollingContainer.scrollTop;
        this.quill.quillEditor.focus();
        this.quill.quillEditor.scrollingContainer.scrollTop = scrollTop;
      });

      this.minimizeService.minimizeInit(mediaPreviewModal, {
        width: '360px',
        height: '307px',
        right: '40px',
        borderRadius: '10px',
      });
    }
  }
}
