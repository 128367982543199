<div class="project-settings" t-id="space-page-edit-action" *transloco="let t; read: 'project-settings'">
  <div class="card w-100">
    <div class="card-content">
      <div class="card-body">
        <ng-scrollbar class="scroll-event scrollable-container scroll-info" thumbClass="white-scrollbars">
        <h6 class="project-info">{{object === 'spaces' ? t('space') : t('project')}}{{ t('information') }}</h6>

        <div *ngIf="space && ('spaces::' + space._id + '::spacesUpdate' | checkPermission)" class="card-wrap">
          <div class="card-img-custom position-relative">
            <app-space-avatar
              [space]="space"
              class="project-avatar"
              [class.project-avatar-uploading]="isAvatarImageUploading"
              [containerHeight]="80"
              [containerWidth]="80"
            ></app-space-avatar>

            <div *ngIf="isAvatarImageUploading" class="project-avatar-spinner">
              <app-custom-spinner [smallSize]="true"></app-custom-spinner>
              <p class="project-avatar-uploading-text">{{ t('uploading') }}</p>
            </div>
          </div>

          <div class="card-header py-0 d-flex flex-column justify-content-center">
            <span class="project-title">
              {{ space?.spaceName }}
              <span *ngIf="space.prefix" class="ml-1">({{space.prefix}})</span>
            </span>
            <span class="select-avatar">
              <button *ngIf="avatarImagePreview" class="btn btn-sm btn-solid" (click)="deleteAvatarImage()">
                {{ t('btn-clear-photo') }}
              </button>
              <button *ngIf="!avatarImagePreview" class="btn btn-sm btn-solid" (click)="!isAvatarImageUploading && avatarInput.click()">
                {{ t('btn-upload-photo') }}
              </button>
            </span>
            <span class="size-info">{{ t('photo-size-limit') }}</span>
          </div>
        </div>
        <div *ngIf="space && !('spaces::' + space._id + '::spacesUpdate' | checkPermission)" class="card-wrap">
          <div class="card-img-custom">
            <app-space-avatar [space]="space" [overlay]="false"></app-space-avatar>
          </div>

          <div class="card-header pt-0">
            <h4 class="m-0 font-small-4">
              {{ space?.spaceName }}
              <span *ngIf="space.prefix" class="ml-1">({{space.prefix}})</span>
            </h4>
          </div>
        </div>

        <div *ngIf="project && ('projects::' + project._id + '::projectUpdate' | checkPermission)" class="card-wrap">
          <div class="card-img-custom position-relative">
            <app-project-avatar
              [project]="project"
              class="project-avatar"
              [class.project-avatar-uploading]="isAvatarImageUploading"
              [containerHeight]="80"
              [containerWidth]="80"
            ></app-project-avatar>

            <div *ngIf="isAvatarImageUploading" class="project-avatar-spinner">
              <app-custom-spinner [smallSize]="true"></app-custom-spinner>
              <p class="project-avatar-uploading-text">{{ t('uploading') }}</p>
            </div>
          </div>

          <div class="card-header py-0 d-flex flex-column justify-content-center">
            <span class="project-title">
              {{ project.projectName }}
              <span *ngIf="project.prefix" class="ml-1">({{project.prefix}})</span>
            </span>
            <span class="select-avatar">
              <button *ngIf="avatarImagePreview" class="btn btn-sm btn-solid" (click)="deleteAvatarImage()">
                {{ t('btn-clear-photo') }}
              </button>
              <button *ngIf="!avatarImagePreview" class="btn btn-sm btn-solid" (click)="!isAvatarImageUploading && avatarInput.click()">
                {{ t('btn-upload-photo') }}
              </button>
              <button
                class="btn btn-sm btn-subtle ml-2"
                (click)="selectedEmoji && selectedEmoji !== '' ? clearProjectEmoji($event) : projectEmojiPickerToggle($event)"
              >
                {{
                  selectedEmoji && selectedEmoji !== ''
                    ? t('clear-emoji')
                    : t('selectlabelstab-emoji')
                }}
              </button>
            </span>
            <span class="size-info">{{ t('photo-size-limit') }}</span>
          </div>
        </div>
        <div *ngIf="project && !('projects::' + project._id + '::projectUpdate' | checkPermission)" class="card-wrap">
          <div class="card-img-custom">
            <app-project-avatar [project]="project" class="project-avatar"></app-project-avatar>
          </div>

          <div class="card-header-content">
            <div class="card-header pt-0">
              <h4 class="m-0 font-small-4">
                {{ project.projectName }}
                <span *ngIf="project.prefix" class="ml-1">({{project.prefix}})</span>
              </h4>
            </div>
          </div>
        </div>

        <form #updateForm="ngForm" class="w-100" novalidate>
          <div *ngIf="space && ('spaces::' + space._id + '::spacesUpdate' | checkPermission)" class="project-fields">
            <div class="form-group">
              <label class="form-control-label" for="spaceName">{{ t('form-space-info') }}</label>
              <input
                class="form-control w-100"
                id="spaceName"
                name="spaceName"
                [placeholder]="t('space-name-placeholder')"
                type="text"
                required
                #spaceName="ngModel"
                [(ngModel)]="space.spaceName"
                t-id="space-update-name"
              />
              <small *ngIf="!spaceName.valid && (spaceName.dirty || spaceName.touched)" class="form-text danger">
                {{ t('form-required') }}
              </small>
            </div>

            <div *ngIf="!prefix || space.isPersonal" class="form-group">
              <label class="form-control-label" for="spacePrefix">{{ t('form-space-prefix') }}</label>
              <input
                class="form-control input-md"
                id="spacePrefix"
                name="prefix"
                [placeholder]="t('space-prefix-placeholder')"
                type="text"
                required
                maxlength="3"
                pattern="^[A-Za-z0-9]{1,3}$"
                #spacePrefix="ngModel"
                [(ngModel)]="space.prefix"
              />
              <small *ngIf="!spacePrefix.valid && (spacePrefix.dirty || spacePrefix.touched)" class="form-text danger">
                {{ t('form-required-three-long') }}
              </small>
            </div>

            <div class="form-group">
              <textarea
                class="form-control"
                name="description"
                [placeholder]="t('project-description-placeholder')"
                [(ngModel)]="projectDesc"
              ></textarea>
            </div>
          </div>
          <div *ngIf="project && ('projects::' + project._id + '::projectUpdate' | checkPermission)" class="project-fields">
            <div class="form-group">
              <label class="form-control-label" for="projectName">{{ t('form-project-info') }}</label>
              <input
                class="form-control w-100"
                id="projectName"
                name="projectName"
                [placeholder]="t('project-name-placeholder')"
                type="text"
                required
                #projectName="ngModel"
                [(ngModel)]="project.projectName"
                t-id="project-settings-project-name"
              />
              <small *ngIf="!projectName.valid && (projectName.dirty || projectName.touched)" class="form-text danger">
                {{ t('form-required') }}
              </small>
            </div>

            <div *ngIf="!prefix" class="form-group">
              <label class="form-control-label" for="projectPrefix">{{ t('project-prefix') }}</label>
              <div class="d-flex">
                <span>
                  <input class="form-control form-control_creation input-md" type="text" value="{{projectSpace?.prefix}}-" disabled />
                </span>
                <span class="flex-grow-1 ml-2">
                  <input
                    class="form-control input-md"
                    id="projectPrefix"
                    name="prefix"
                    [placeholder]="t('project-prefix-placeholder')"
                    type="text"
                    required
                    maxlength="6"
                    pattern="^(?=.{1,6}$)[A-Za-z]{1,6}[0-9]{0,3}$"
                    #projectPrefix="ngModel"
                    [(ngModel)]="project.prefix"
                  />
                  <small *ngIf="!projectPrefix.valid && (projectPrefix.dirty || projectPrefix.touched)" class="form-text danger">
                    {{ t('form-required-six-long') }}
                  </small>
                </span>
              </div>
            </div>

            <div class="form-group">
              <textarea
                class="form-control"
                name="description"
                [placeholder]="t('project-description-placeholder')"
                [(ngModel)]="projectDesc"
              ></textarea>
            </div>
          </div>

          <div *ngIf="projectDesc" class="project-fields">
            <h6 class="card-title">{{ t('description-title') }}</h6>
            <p class="py-1">{{ projectDesc }}</p>
          </div>
        </form>

        <h6 class="card-title" [class.mt-3]="!projectDesc">{{ t('notifications') }}</h6>
        <div class="settings-wrapper">
          <label for="selectFilter">{{ t('notify-me-about') }}</label>
          <div ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-text" id="selectFilter" ngbDropdownToggle>
              {{ selectedFilter }}
              <i class="ft-chevron-down font-small-4" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="selectFilter">
              <button *ngFor="let item of notifyFilters" class="w-100" ngbDropdownItem (click)="selectFilter(item)">
                {{ item.label }}
              </button>
            </div>
          </div>
        </div>

        <h6 class="card-title">{{ t('calls-title') }}</h6>
        <div class="settings-wrapper">
          <label for="noRingtoneSwitch">{{ t('form-no-ringtone') }}</label>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="noRingtoneSwitch"
              #noRingtoneCheckbox
              [checked]="noRingtone"
              (change)="toggleNoRingtone(noRingtoneCheckbox.checked)"/>
            <label class="custom-control-label cursor-pointer" for="noRingtoneSwitch"></label>
          </div>
        </div>

        <div *ngIf="space" class="create-info d-flex align-items-center">
          {{ t('created-at') }} {{ space.created_at | date: 'dd.MM.yy - HH:mm' }}
        </div>
        <div *ngIf="project" class="create-info d-flex align-items-center">
          {{ t('created-at') }} {{ project.created_at | date: 'dd.MM.yy - HH:mm' }}
          <div class="bull">&bull;</div>
          {{ t('projects-space') }} {{ projectSpace.spaceName }}
        </div>
        </ng-scrollbar>
      </div>

      <div class="card-footer d-flex align-items-center justify-content-end">
        <button
          *ngIf="space?._id && !space.isPersonal && ('spaces::' + space._id + '::spacesDelete' | checkPermission)"
          class="svg-btn"
          [title]="t('delete-space-title')"
          type="button"
          (click)="spaceService.deleteClick(space._id)"
        >
          <app-svg name="trash" width="14" height="16"></app-svg>
        </button>
        <button
          *ngIf="project?._id && ('projects::' + project._id + '::projectDelete' | checkPermission)"
          class="svg-btn"
          [title]="t('delete-project-title')"
          type="button"
          (click)="projectService.deleteClick(project._id)"
        >
          <app-svg name="trash" width="14" height="16"></app-svg>
        </button>

        <button *ngIf="isModalWindow" class="btn btn-subtle mr-2" type="button" (click)="close.emit('closed')">
          {{ t('btn-cancel') }}
        </button>

        <button type="submit" class="btn btn-solid" [disabled]="!updateForm.valid" (click)="updateSubmit(updateForm)" t-id="space-update-save-btn" id="submit-form">
          {{ t('btn-save') }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- image cropper modal -->
<ng-template #imageCropperModal let-c="close" let-d="dismiss" *transloco="let t; read: 'project-settings'">
  <div class="modal-header">
    <h5 class="modal-title modal-title-text">{{ t('edit-image-title') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeCropperModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <app-image-cropper
      [image]="selectedImage"
      [aspectRatio]="1"
      [resizeToWidth]="128"
      (avatarImagePreview)="setImagePreview($event)"
      (avatarImageFile)="setProjectAvatar($event)"
      (closeCropperModal)="closeCropperModal()"
    ></app-image-cropper>
  </div>
</ng-template>

<input
  #avatarInput
  type="file"
  style="display: none"
  accept=".jpg, .jpeg, .png"
  (change)="avatarChangeEvent($event)"
  [disabled]="(space?._id && !('spaces::' + space._id + '::spacesUpdate' | checkPermission)) ||
    (project?._id && !('projects::' + project._id + '::projectDelete' | checkPermission))"
/>
