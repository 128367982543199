/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthPasswordReqDto } from '../models/auth-password-req-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { UsersChangeRoleReqDto } from '../models/users-change-role-req-dto';
import { UsersDbDto } from '../models/users-db-dto';
import { UsersGetListResDto } from '../models/users-get-list-res-dto';
import { UsersPublicFieldsResDto } from '../models/users-public-fields-res-dto';
import { UsersStatusesListDto } from '../models/users-statuses-list-dto';
import { UsersUpdateReqDto } from '../models/users-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation usersGetListByTenantName
   */
  static readonly UsersGetListByTenantNamePath = '/users/get-list-by-tenant';

  /**
   * get user list by tenant name
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetListByTenantName()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetListByTenantName$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * is Active user
     */
    isActive?: boolean;
  }): Observable<StrictHttpResponse<Array<UsersGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetListByTenantNamePath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('isActive', params.isActive, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersGetListResDto>>;
        }),
      );
  }

  /**
   * get user list by tenant name
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetListByTenantName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetListByTenantName(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * is Active user
     */
    isActive?: boolean;
  }): Observable<Array<UsersGetListResDto>> {
    return this.usersGetListByTenantName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersGetListResDto>>) => r.body as Array<UsersGetListResDto>),
    );
  }

  /**
   * Path part for operation usersGetAttachedUserList
   */
  static readonly UsersGetAttachedUserListPath = '/users/get-attached-user-list';

  /**
   * get attached user list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetAttachedUserList()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetAttachedUserList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<UsersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetAttachedUserListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersDbDto>>;
        }),
      );
  }

  /**
   * get attached user list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetAttachedUserList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetAttachedUserList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<UsersDbDto>> {
    return this.usersGetAttachedUserList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersDbDto>>) => r.body as Array<UsersDbDto>),
    );
  }

  /**
   * Path part for operation usersUpdate
   */
  static readonly UsersUpdatePath = '/users';

  /**
   * update user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: UsersUpdateReqDto;
  }): Observable<StrictHttpResponse<UsersPublicFieldsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UsersPublicFieldsResDto>;
        }),
      );
  }

  /**
   * update user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: UsersUpdateReqDto;
  }): Observable<UsersPublicFieldsResDto> {
    return this.usersUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UsersPublicFieldsResDto>) => r.body as UsersPublicFieldsResDto),
    );
  }

  /**
   * Path part for operation usersGetCompanyMembers
   */
  static readonly UsersGetCompanyMembersPath = '/users/get-company-members';

  /**
   * get user list by tenant name
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetCompanyMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetCompanyMembers$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<UsersGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetCompanyMembersPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersGetListResDto>>;
        }),
      );
  }

  /**
   * get user list by tenant name
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetCompanyMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetCompanyMembers(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<UsersGetListResDto>> {
    return this.usersGetCompanyMembers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersGetListResDto>>) => r.body as Array<UsersGetListResDto>),
    );
  }

  /**
   * Path part for operation usersGetUsersInfo
   */
  static readonly UsersGetUsersInfoPath = '/users/get-users-info';

  /**
   * get users info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetUsersInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetUsersInfo$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userIds
     */
    userIds?: string;
  }): Observable<StrictHttpResponse<Array<UsersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetUsersInfoPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('userIds', params.userIds, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersDbDto>>;
        }),
      );
  }

  /**
   * get users info
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetUsersInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetUsersInfo(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userIds
     */
    userIds?: string;
  }): Observable<Array<UsersDbDto>> {
    return this.usersGetUsersInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersDbDto>>) => r.body as Array<UsersDbDto>),
    );
  }

  /**
   * Path part for operation usersGetRelationalUsers
   */
  static readonly UsersGetRelationalUsersPath = '/users/get-relational-users';

  /**
   * get relational users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetRelationalUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetRelationalUsers$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetRelationalUsersPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        }),
      );
  }

  /**
   * get relational users
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetRelationalUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetRelationalUsers(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<string>> {
    return this.usersGetRelationalUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>),
    );
  }

  /**
   * Path part for operation usersStatusesList
   */
  static readonly UsersStatusesListPath = '/users/get-users-statuses';

  /**
   * get users statuses list by tenantName
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersStatusesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersStatusesList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<UsersStatusesListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersStatusesListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersStatusesListDto>>;
        }),
      );
  }

  /**
   * get users statuses list by tenantName
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersStatusesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersStatusesList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<UsersStatusesListDto>> {
    return this.usersStatusesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersStatusesListDto>>) => r.body as Array<UsersStatusesListDto>),
    );
  }

  /**
   * Path part for operation changeUserRole
   */
  static readonly ChangeUserRolePath = '/users/change-user-role';

  /**
   * change user role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUserRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUserRole$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: UsersChangeRoleReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ChangeUserRolePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * change user role
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeUserRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUserRole(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: UsersChangeRoleReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.changeUserRole$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation usersDeleteAccount
   */
  static readonly UsersDeleteAccountPath = '/users/{id}';

  /**
   * delete user account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDeleteAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersDeleteAccount$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersDeleteAccountPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete user account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersDeleteAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersDeleteAccount(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.usersDeleteAccount$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation transferOwnership
   */
  static readonly TransferOwnershipPath = '/users/{id}/transfer-ownership';

  /**
   * transfer ownership
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferOwnership()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferOwnership$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userWhoTakeOwnershipId
     */
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.TransferOwnershipPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * transfer ownership
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferOwnership$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferOwnership(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userWhoTakeOwnershipId
     */
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.transferOwnership$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
