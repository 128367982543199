import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
// import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ConfigsState } from './states/configs.state';
import { ActionsLogsState } from './states/actions-logs.state';
import { SpacesState } from './states/spaces.state';
import { AuthState } from './states/auth.state';
import { BoardsState } from './states/boards.state';
import { CalendarEventsState } from './states/calendar-events.state';
import { ChatsState } from './states/chats.state';
import { ThreadsState } from './states/threads.state';
import { DocumentsState } from './states/documents.state';
import { NotesState } from './states/notes.state';
import { ModalsState } from './states/modals.state';
import { ProjectsState } from './states/projects.state';
import { RolesState } from './states/roles.state';
import { TenantsState } from './states/tenants.state';
import { UsersState } from './states/users.state';
import { DocumentsPermissionsState } from './states/documents-permissions.state';
import { EmojisState } from './states/emojis.state';
import { NotepadState } from './states/notepad.state';
import { NotificationsState } from './states/notifications.state';

import { environment as env } from '../../../environments/environment';
import { AvatarsState } from './states/avatars.state';
import { VideoCallsState } from './states/video-calls.state';
import { WebhooksState } from './states/webhook.state';
import { LaneBoardState } from './states/lane-board.state';
import { LaneBoardListState } from './states/lane-board-list.state';
import { SprintsState } from './states/sprints.state';
import { BoardsFieldState } from './states/boards-field.state';
import { TenantsSettingsState } from './states/tenants-settings.state';
import { PaymentState } from './states/payment.state';

/** Ngxs store module **/
@NgModule({
  imports: [
    CommonModule,
    NgxsFormPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot({ logger: console, collapsed: false }),
    // NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: env.production }),
    NgxsRouterPluginModule.forRoot(),
    NgxsModule.forRoot(
      [
        ConfigsState,
        ActionsLogsState,
        SpacesState,
        AuthState,
        BoardsState,
        BoardsFieldState,
        LaneBoardState,
        LaneBoardListState,
        CalendarEventsState,
        ChatsState,
        ThreadsState,
        DocumentsState,
        NotesState,
        ModalsState,
        ProjectsState,
        RolesState,
        TenantsState,
        TenantsSettingsState,
        UsersState,
        AvatarsState,
        DocumentsPermissionsState,
        EmojisState,
        NotepadState,
        NotificationsState,
        VideoCallsState,
        WebhooksState,
        SprintsState,
        PaymentState,
      ],
      {
        developmentMode: !env.production,
      },
    ),
  ],
  exports: [NgxsFormPluginModule, NgxsLoggerPluginModule, NgxsReduxDevtoolsPluginModule, NgxsModule],
})
export class NgxsStoreModule {}
