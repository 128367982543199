import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { WebhooksDbDto } from '../../api/models/webhooks-db-dto';
import {
  ClearCreatedWebhook,
  WebhookCreate,
  WebhooksByChatIdGet,
  WebhookUpdate,
} from '../../shared/store/actions/webhook.actions';
import { WebhooksState } from '../../shared/store/states/webhook.state';
import { ConfigService } from '../../shared/services/config.service';
import { AvatarDelete, WebhookAvatarSet } from '../../shared/store/actions/avatar.action';
import { WebhookIntegrationServiceType } from '../../shared/interfaces/webhook-message-data';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { AuthState } from '../../shared/store/states/auth.state';

@Component({
  selector: 'app-webhook-create-update-modal',
  templateUrl: './webhook-create-update-modal.component.html',
  styleUrls: ['./webhook-create-update-modal.component.scss'],
})
export class WebhookCreateUpdateModalComponent implements OnInit, OnDestroy {
  private emojiPickerImage = 'assets/img/emojis/emojis.png';

  private maxFileSize = 52428801; // 50MB

  public chatId: string;

  public isNewWebhook = true;

  public webhook: WebhooksDbDto;

  public webhookItemForm: FormGroup;

  public webhookUrl = '';

  public avatarImagePreview: string;

  public webhookAvatarImage: File;

  public selectedEmoji = '';

  public emojiIsOpen = false;

  public isEmojiTouched = false;

  public customRecentEmojis = [];

  public darkMode = false;

  public isProcessing = false;

  private platform: string;

  public webhookUrlTypes: {
    value: string;
    label: string;
    path: string;
    serviceType: string;
  }[] = [
    {
      value: 'simpleMessage',
      label: this.translocoService.translate('modals.webhook-create-update.simple-message'),
      path: '',
      serviceType: null,
    },
    {
      value: 'github',
      label: 'GitHub',
      path: `/${WebhookIntegrationServiceType.GITHUB}`,
      serviceType: WebhookIntegrationServiceType.GITHUB,
    },
    {
      value: 'gitlab',
      label: 'GitLab',
      path: `/${WebhookIntegrationServiceType.GITLAB}`,
      serviceType: WebhookIntegrationServiceType.GITLAB,
    },
    {
      value: 'bitbucket',
      label: 'Bitbucket',
      path: `/${WebhookIntegrationServiceType.BITBUCKET}`,
      serviceType: WebhookIntegrationServiceType.BITBUCKET,
    },
  ];

  public whUrlTypeValue: string = this.webhookUrlTypes[0].value;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private store: Store,
    private toastrService: ToastrService,
    private modalsService: NgbModal,
    private configService: ConfigService,
    private translocoService: TranslocoService,
  ) {
    this.customRecentEmojis = this.configService.CUSTOM_RECENT_EMOJIS;
    this.darkMode = this.configService.templateConf.layout.variant !== 'Light';
  }

  ngOnInit() {
    this.avatarImagePreview = this.webhook?.avatarUrl ? this.webhook?.avatarUrl : '';
    this.updateWHDataOnload();

    this.buildItemForm(this.webhook);
    this.getPlatform();
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  public close(): void {
    if (this.webhookItemForm.dirty || (this.isNewWebhook && (this.avatarImagePreview || this.selectedEmoji !== ''))) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
            this.saveUpdateNewWebhook();
            this.activeModal.close();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }

  private buildItemForm(item: WebhooksDbDto): void {
    this.webhookItemForm?.reset();
    this.webhookItemForm = this.formBuilder.group({
      webhookName: [item?.webhookName || '', Validators.required],
    });
  }

  public copyToClipboard(): void {
    navigator.clipboard.writeText(this.whUrlWithType(this.whUrlTypeValue));
    this.toastrService.success(
      this.translocoService.translate('toastr.webhook-copied-to-clipboard'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  public saveUpdateNewWebhook(): void {
    if (this.webhookItemForm.valid) {
      const webhookItem = this.webhookItemForm.value;
      if (this.webhook?._id) {
        if (!this.isFileSizeCorrect) {
          return;
        }
        this.isProcessing = true;
        this.store
          .dispatch(
            new WebhookUpdate({
              _id: this.webhook._id,
              webhookName: webhookItem.webhookName,
              chatId: this.chatId,
              emoji: this.selectedEmoji,
            }),
          )
          .pipe(take(1))
          .subscribe(() => {
            if (this.isAvatarChanged) {
              this.store
                .dispatch(new WebhookAvatarSet({ id: this.webhook._id, file: this.webhookAvatarImage }))
                .pipe(take(1))
                .subscribe(
                  () => {
                    console.log('WebhookAvatar uploaded');
                    this.store.dispatch(new WebhooksByChatIdGet(this.webhook.chatId));
                  },
                  (err) => this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error')),
                );
            } else if (this.isAvatarDeleted) {
              this.store
                .dispatch(new AvatarDelete({ type: 'avatar', object: 'webhooks', objectId: this.webhook._id }))
                .pipe(take(1))
                .subscribe(
                  (res) => {
                    console.log('WebhookAvatar deleted');
                    this.store.dispatch(new WebhooksByChatIdGet(this.webhook.chatId));
                  },
                  (err) => this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error')),
                );
            }
            this.activeModal.close();
          });
      } else {
        if (!this.isFileSizeCorrect) {
          return;
        }
        this.isProcessing = true;
        this.store
          .dispatch(
            new WebhookCreate({
              webhookName: webhookItem.webhookName,
              chatId: this.chatId,
              emoji: this.selectedEmoji,
              serviceType: this.getUrlTypeObjByValue(this.whUrlTypeValue).serviceType,
            }),
          )
          .pipe(take(1))
          .subscribe((res) => {
            this.isProcessing = false;
            this.store
              .select(WebhooksState.getCreatedWebhook)
              .pipe(take(1))
              .subscribe((wh) => {
                if (wh?._id) {
                  if (!!this.webhookAvatarImage) {
                    this.store
                      .dispatch(new WebhookAvatarSet({ id: wh._id, file: this.webhookAvatarImage }))
                      .pipe(take(1))
                      .subscribe(
                        () => {
                          console.log('WebhookAvatar uploaded');
                          this.store.dispatch(new WebhooksByChatIdGet(this.webhook.chatId));
                        },
                        (err) =>
                          this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error')),
                      );
                  }
                  this.webhook = wh;
                  this.updateWHDataOnload();
                }
              });
          });
      }
    }
  }

  public get isAvatarDeleted(): boolean {
    return !!this.webhook?.avatarUrl && !this.avatarImagePreview;
  }

  public get isAvatarChanged(): boolean {
    return !!this.avatarImagePreview && this.avatarImagePreview !== this.webhook?.avatarUrl;
  }

  private get isFileSizeCorrect(): boolean {
    if (this.webhookAvatarImage) {
      if (this.webhookAvatarImage.size < this.maxFileSize) {
        return true;
      } else {
        this.toastrService.error(
          this.translocoService.translate('toastr.err-message-file-size', { size: '50MB' }),
          this.webhookAvatarImage.name,
        );
        return false;
      }
    }
    return true;
  }

  private updateWHDataOnload(): void {
    this.isNewWebhook = !this.webhook?._id;

    this.whUrlTypeValue =
      this.isNewWebhook || !this.webhook?.serviceType
        ? this.webhookUrlTypes[0].value
        : this.getUrlTypeObjByServiceType(this.webhook?.serviceType)?.value;

    this.webhookUrl = !this.isNewWebhook
      ? `${this.webhook?.webhookUrl}/${this.webhook?._id}/${this.webhook?.webhookSecretKey}`
      : '';
    this.selectedEmoji = this.webhook?.emoji ? this.webhook?.emoji : '';
  }

  public deleteAvatarImage(): void {
    this.webhookAvatarImage = undefined;
    this.avatarImagePreview = undefined;
  }

  public setImagePreview(imageDataBase64: string): void {
    this.avatarImagePreview = imageDataBase64;
  }

  public setWebhookImageFile(file: File): void {
    this.webhookAvatarImage = file;
  }

  public emojiPickerToggle(): void {
    this.emojiIsOpen = !this.emojiIsOpen;
  }

  public clearEmoji(): void {
    this.isEmojiTouched = true;
    this.selectedEmoji = '';
  }

  public addEmoji(emojiData: { emoji: EmojiData }): void {
    this.isEmojiTouched = true;
    this.selectedEmoji = emojiData?.emoji?.native;
    this.emojiPickerToggle();
  }

  public emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) => this.emojiPickerImage;

  public whUrlWithType(value: string): string {
    const urlType = this.webhookUrlTypes.find((t) => t.value === value);
    return urlType?.path ? `${this.webhookUrl}${urlType?.path}` : this.webhookUrl;
  }

  public getUrlTypeLabel(value: string): string {
    const urlType = this.webhookUrlTypes.find((t) => t.value === value);
    return urlType?.label;
  }

  public getUrlTypeObjByValue(value: string): {
    value: string;
    label: string;
    path: string;
    serviceType: string;
  } {
    return this.webhookUrlTypes.find((t) => t.value === value);
  }

  public getUrlTypeObjByServiceType(serviceType: string): {
    value: string;
    label: string;
    path: string;
    serviceType: string;
  } {
    return this.webhookUrlTypes.find((t) => t.serviceType === serviceType);
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearCreatedWebhook());
  }
}
