import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { TabIndex } from '../../custom-fields.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TicketsDbDto } from '../../../../../api/models/tickets-db-dto';
import { BoardsState } from '../../../../../shared/store/states/boards.state';
import { TicketData } from '../../../board-ticket';
import { ConfigService } from '../../../../../shared/services/config.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-select-link-relationship',
  templateUrl: 'select-link-relationship.component.html',
  styleUrls: ['select-link-relationship.component.scss'],
})
export class SelectLinkRelationshipComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;
  inputFocused: (name: string) => void;
  setFocusedControl: (tabIndex: string) => void;
  modalData: TicketData;
  users$: Observable<any[]>;
  tabIndexes: Array<TabIndex>;
  ticketSelectParent: BehaviorSubject<TicketsDbDto[]>;
  epicsList: any[] = [];
  childrenList: any[] = [];
  loading = false;
  config: any = {};
  displayName: string;
  isForMultiplyObjects = false;
  parentIsConflict = new BehaviorSubject<{ message: string; id: string }>(null);
  errorMessage = null;
  parentIdIsConflicted = null;
  constructor(private store: Store, private configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    if (this.isForMultiplyObjects) {
      return;
    }

    this.parentIsConflict.pipe(untilDestroyed(this)).subscribe((parent) => {
      if (parent) {
        this.errorMessage = parent.message;
        this.parentIdIsConflicted = parent.id;
      } else {
        this.errorMessage = null;
        this.parentIdIsConflicted = null;
      }
    });

    this.store
      .select(BoardsState.getEpicsList)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.epicsList = res?.filter((epic) => !epic.isDeleted);
      });
  }
}
