import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { TauriService } from '../services/tauri.service';

@Injectable({
  providedIn: 'root',
})
export class FilesHelper {
  PLAYABLE_VIDEO_FORMATS = ['m4v', 'mp4', 'webm', 'mov'];
  IMAGES_FORMATS = ['bmp', 'gif', 'jpeg', 'jpg', 'tiff', 'tif', 'svg', 'png'];
  extensionLimit = 10;
  preSignedUrlParam = 'X-Amz-SignedHeaders';

  constructor(
    private toastr: ToastrService,
    private tauriService: TauriService,
    private translocoService: TranslocoService,
  ) {}

  getFileType(fileName) {
    const ext = fileName.split('.').pop().toLowerCase();
    let type = 'file';
    if (this.IMAGES_FORMATS.includes(ext)) {
      type = 'image';
    }
    if (['3gp', 'avi', 'm2v', 'm4p', 'm4v', 'mkv', 'mp2', 'mp4', 'mpe', 'mpeg', 'mpg', 'webm', 'mov'].includes(ext)) {
      type = 'video';
    }
    if (['mp3', 'ogg', 'wav', 'aac', 'flac'].includes(ext)) {
      type = 'music';
    }
    if (['ppt', 'pptx'].includes(ext)) {
      type = 'presentation';
    }
    if (['7z', 'zip', 'zipx', 'rar'].includes(ext)) {
      type = 'archive';
    }

    // Init type of file: PDF
    if (ext === 'pdf') {
      type = 'pdf';
    }
    return type;
  }

  checkFileIsPlayableVideoInWeb(fileName: string) {
    return this.PLAYABLE_VIDEO_FORMATS.includes(this.getFileExtension(fileName));
  }

  checkFileIsAudio(fileName: string) {
    return this.getFileType(fileName) === 'music';
  }

  checkFileIsImage(fileName: string) {
    return this.getFileType(fileName) === 'image';
  }

  checkFileIsVideo(fileName: string) {
    return this.getFileType(fileName) === 'video';
  }

  checkFileIsVideoOrDoc(fileName: string) {
    return !this.checkFileIsAudio(fileName) && !this.checkFileIsImage(fileName);
  }

  convertBase64ImageToFile(dataUri: string, fileName: string, type: string): File {
    const base64 = dataUri.split(',')[1];
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return new File([blob], fileName, { type });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFileExtension(fileName) {
    return fileName.split('.').pop().toLowerCase();
  }

  getFileNameWithoutExtension(fileName) {
    const splitted: string[] = fileName.split('.');
    splitted.splice(-1, 1);

    if (splitted.length === 1) {
      return splitted[0];
    }

    return splitted.join('.');
  }

  getFileNameWithoutExtensionHttp(fileName) {
    let withoutExt = '';

    if (fileName.includes('http')) {
      const splitted: string[] = fileName.split('/');
      if (splitted.length) {
        withoutExt = splitted[splitted.length - 1];
        withoutExt = withoutExt.split('.')[0];
      } else {
        withoutExt = this.getFileNameWithoutExtension(fileName);
      }
    } else {
      withoutExt = this.getFileNameWithoutExtension(fileName);
    }

    return withoutExt;
  }

  private parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        (file) => {
          resolve(file);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        (entries) => {
          resolve(this.buildTree(entries, directoryEntry.name));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  buildTree(entries, name) {
    const tree = { name, files: [], directories: [] };
    const promises = [];
    for (let i = 0; i < entries.length; i++) {
      const entry = name === '' ? entries[i].webkitGetAsEntry() : entries[i];

      if (entry) {
        if (entry.isFile) {
          promises.push(
            this.parseFileEntry(entry).then((file) => {
              tree.files.push(file);
            }),
          );
        } else if (entry.isDirectory) {
          promises.push(
            this.parseDirectoryEntry(entry).then((directory) => {
              tree.directories.push(directory);
            }),
          );
        }
      }
    }

    return Promise.all(promises).then(() => tree);
  }

  async saveTauriFile(blob, filename) {
    const buffer = await blob.arrayBuffer();
    const writeFile = (window as any).__TAURI__.fs.writeBinaryFile(
      { path: filename, contents: new Uint8Array(buffer) },
      { dir: 8 },
    );

    writeFile
      .then(() => {
        this.toastr.success(
          this.translocoService.translate('toastr.file-downloaded-successfully'),
          this.translocoService.translate('toastr.title-success'),
        );
      })
      .catch(() => {
        this.toastr.error(
          this.translocoService.translate('toastr.error-while-saving-file'),
          this.translocoService.translate('toastr.title-error'),
        );
      });
  }

  downloadFile(url, filename, platform, hasCors = true) {
    const isPreSignedUrl = this.isPreSignedUrl(url);
    const updatedUrl = isPreSignedUrl ? url : this.getNewDownloadFileUrlToAvoidCORS(url);
    if (platform === 'web') {
      fetch(updatedUrl, {
        method: 'get',
        mode: hasCors ? 'cors' : 'no-cors',
        credentials: 'omit',
        referrerPolicy: 'no-referrer',
      })
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else {
            window.open(url);
            throw new Error('Network response was not OK');
          }
        })
        .then((blob) => {
          if (this.tauriService.isTauri) {
            this.saveTauriFile(blob, filename);
          } else {
            const anchor = document.createElement('a');
            const href = URL.createObjectURL(blob);
            anchor.href = href;
            anchor.download = `${filename}`;
            anchor.setAttribute('target', '_blank');
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            URL.revokeObjectURL(href);
          }
        })
        .catch((err) => {
          console.error('There has been a problem with your fetch operation:', err);
        });
    } else {
      window.open(url);
    }
  }

  getNewDownloadFileUrlToAvoidCORS(url: string): string {
    if (!url) {
      return url;
    }

    // ignoring cache to resolve issue with S3 CORS issue
    // https://bugs.chromium.org/p/chromium/issues/detail?id=409090
    const ignoreCacheUrl = new URL(url);
    ignoreCacheUrl.searchParams.set('v', Date.now().toString());

    return ignoreCacheUrl.href;
  }

  getFileNameFromBase64(fileName): string {
    let format, nameWithExpansion;
    this.IMAGES_FORMATS.forEach((fileFormat) => {
      if (fileName.includes(fileFormat)) {
        format = fileFormat;
      }
    });

    if (format) {
      nameWithExpansion = `image.${format}`;
    }

    return nameWithExpansion;
  }

  downloadFileFromBase64(url: string, originalFileName: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = originalFileName;
    a.click();
  }

  isPreSignedUrl(url: string) {
    return url.includes(this.preSignedUrlParam);
  }

  isTooLongExtension(fileName) {
    return this.getFileExtension(fileName).length > this.extensionLimit;
  }

  truncate(value: string, args?: number) {
    const limit = args || 10;
    const trail = '...';

    return value?.length > 0 && value?.length > limit ? value?.substring(0, limit) + trail : value;
  }
}
