<ng-container *transloco="let t; read: 'modals.chat-group-members'">
  <div class="modal-header">
    <h5 class="modal-title" [innerHTML]="t('manage-members', { chatName: chat?.chatName })"></h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <section id="ngx">
      <form [formGroup]="membersForm" class="editForm" novalidate>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label" for="chatGroupMembers">{{ t('chat-members') }}</label>
              <ng-select
                id="chatGroupMembers"
                name="chatGroupMembers"
                class="chat-group-members"
                bindLabel="userName"
                bindValue="_id"
                appendTo="body"
                placeholder="{{ t('placeholder-search-user') }}"
                [items]="users"
                [multiple]="true"
                [searchable]="true"
                [formControl]="membersForm.controls['members']"
                [(ngModel)]="chatMembers"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <div class="d-flex align-items-center">
                    <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
                    <div class="ml-1">
                      <div>{{ item.userName }}</div>
                    </div>
                    <div *ngIf="canRemoveItem(item)" class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div >
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index" id="guests_select">
                  <div class="d-flex align-items-center">
                    <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
                    <div class="ml-1">
                      <b>{{ item.userName }}</b>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <small
                class="form-text danger"
                *ngIf="!membersForm.controls['members'].valid && (membersForm.controls['members'].dirty || membersForm.controls['members'].touched)"
              >
                {{ t('field-is-required') }}
              </small>
            </div>
            <div class="form-group text-right">
              <button class="btn btn-subtle btn-cancel mr-2" (click)="close()">{{ t('btn-cancel') }}</button>
              <button
                t-id="chat-group-create-button"
                type="button"
                uiSref="work"
                class="btn btn-solid"
                [disabled]="!membersForm.valid || !isActiveBtn"
                (click)="changeChatGroupMembers(membersForm)"
              >
                {{ t('btn-save') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</ng-container>
