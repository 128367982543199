import { Injectable } from '@angular/core';
import { BoardTicketModalComponent } from '../../../../modals/board-ticket/board-ticket.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PinType } from './enums/pin-type.enum';
import { Store } from '@ngxs/store';
import { LocalStorageService } from 'ngx-localstorage';
import { RolesTypeEnum } from './enums/roles-type.enum';
import { ChatsState } from '../../../store/states/chats.state';
import { MinimizeService } from '../../../services/minimize.service';
import { DraftService } from '../../../services/draft.service';
import { FilesService } from '../../../../api/services/files.service';
import { DocumentDataRoomComponent } from '../../../../modals/data-room-modals/document-data-room/document-data-room.component';
import {
  MediaPreviewTypes,
  PreviewMediaComponent,
} from '../../../../modals/data-room-modals/preview-media/preview-media.component';
import { FilesHelper } from '../../../utils/files-helper';
import { TauriService } from '../../../services/tauri.service';

@Injectable({
  providedIn: 'root',
})
export class ChatPinnedService {
  public platform: string;
  public object: string;
  public objectId: string;
  private userId: string = this.localStorageService.get('userId') || '';

  constructor(
    private modalsService: NgbModal,
    private store: Store,
    private fileService: FilesService,
    private filesHelper: FilesHelper,
    private readonly localStorageService: LocalStorageService,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private tauriService: TauriService,
  ) {}

  // Getter
  public getIsTicketPin(item): boolean {
    return item.linkDocument === PinType.Ticket;
  }

  public getIsDocumentPin(item): boolean {
    return item.linkDocument === PinType.Documents;
  }

  public getIsMediaPin(item): boolean {
    return item.linkDocument === PinType.ChatFile || item.linkDocument === PinType.ThreadsFile;
  }

  public getIsThreadChat(item): boolean {
    return item.linkDocument === PinType.ThreadsFile;
  }

  public getIsFileData(item) {
    return this.getIsTicketMediaPin(item)
      ? item.linkTicketFile
      : this.getIsFilePin(item)
      ? item.linkFile
      : this.getIsThreadChat(item)
      ? item.linkThreadFile
      : item.linkChatFile;
  }

  public getIsTicketMediaPin(item): boolean {
    return item.linkDocument === PinType.TicketFile;
  }

  public getIsFilePin(item): boolean {
    return item.linkDocument === PinType.Files;
  }

  private typeOfPin(item): PinType {
    return item.openInFrame ? PinType.Links : this.getIsThreadChat(item) ? PinType.ThreadsFile : PinType.ChatFile;
  }

  private checkInlineImage(item): boolean {
    return this.filesHelper.getFileType(item.url || '') === MediaPreviewTypes.Image;
  }

  // Methods
  public initData(object: string, objectId: string, platform: string) {
    this.platform = platform;
    this.object = object;
    this.objectId = objectId;
  }

  public handleAction(item) {
    if (this.getIsTicketPin(item)) {
      this.openModal(item);
    } else if (this.getIsMediaPin(item) || this.getIsTicketMediaPin(item)) {
      this.openPreview(item);
    } else if (this.getIsDocumentPin(item)) {
      this.openDocument(item);
    } else if (this.getIsFilePin(item)) {
      this.fileService.filesGetUrl({ id: item.linkFile._id }).subscribe((res) => {
        const media = { ...item, linkFile: { ...item.linkFile, url: res.url } };
        this.openPreview(media);
      });
    } else if (item.openInFrame) {
      this.openPreview(item);
    } else if (item.url) {
      if (this.tauriService.isTauri) {
        this.tauriService.openExternalLink(item.url);
      } else {
        window.open(item.url, '_blank').focus();
      }
    }
  }

  public canDeletePin(item, objectId?: string): boolean {
    this.objectId = objectId || this.objectId;
    const chatId = this.store.selectSnapshot(ChatsState.getChats).find((chat) => chat.objectId === this.objectId)?._id;
    const allUser = this.store.selectSnapshot(ChatsState.getChatMembers)(chatId);
    const currentUser = allUser?.find((user) => user.userId === this.userId);

    return (
      this.userId === item.creatorId ||
      currentUser?.roleName?.includes(RolesTypeEnum.Owner || RolesTypeEnum.SpaceLeader || RolesTypeEnum.ProjectLeader)
    );
  }

  private openModal(item): void {
    const draft = this.draftService.isHasActiveDraft(item.linkTicket?._id);

    if (draft) {
      this.draftService.openTicketOrDraft(draft, {
        id: item.linkTicket?._id,
        object: item.linkTicket?.object,
        objectId: item.linkTicket?.objectId,
      });
    } else {
      const ticketModalRef = this.modalsService.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => ticketModalRef.componentInstance.closeImagePreview(true),
      });
      ticketModalRef.componentInstance.ticketData = {
        id: item.linkTicket?._id,
        object: item.linkTicket?.object,
        objectId: item.linkTicket?.objectId,
      };
      this.minimizeService.minimizeInit(ticketModalRef);
    }
  }

  private openPreview(item): void {
    const isMobile: boolean = this.platform !== 'web';
    const refModal = this.modalsService.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
      centered: true,
    });

    refModal.componentInstance.previewData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      isMobile,
      currentMedia: item.openInFrame ? item : this.getIsFileData(item),
    };

    refModal.componentInstance.isInlineImage = this.checkInlineImage(item);
    refModal.componentInstance.isNotDataRoom = true;
    refModal.componentInstance.pinnedType = this.typeOfPin(item);

    this.minimizeService.minimizeInit(refModal, {
      width: '360px',
      height: '307px',
      right: '40px',
      borderRadius: '10px',
    });
  }

  private openDocument(item): void {
    const refModal = this.modalsService.open(DocumentDataRoomComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'document-create-modal',
    });

    refModal.componentInstance.dataDocument = {
      object: this.object,
      objectId: this.objectId,
      platform: this.platform,
      document: item.linkDocumentFile,
      isMobile: this.platform !== 'web',
      openByPin: true,
      isEdit: true,
    };

    refModal.componentInstance.documentForm.setValue({
      title: item.linkDocumentFile.name,
      content: item.linkDocumentFile.content,
    });
  }
}
