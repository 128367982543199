<div [formGroup]="unitFormGroup" *ngIf="fieldConfig.isVisible">
  <legend>{{displayName}} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
  <fieldset class="form-group position-relative">
    <div class="d-flex" *transloco="let t; read: 'modals.custom-fields'">
      <div class="w-50">
        <input
          t-id="board-ticket-estimation"
          [id]="uniqFieldId"
          type="number"
          min="0"
          placeholder="{{ t('placeholder-type-value') }}"
          formControlName="estimateValue"
          class="form-control estimation-control"
          [formControlName]="fieldConfig.title"
          [attr.data-tabindex]="tabIndexes[fieldConfig.title]?.tabIndex"
          (click)="setFocusedControl(tabIndexes[fieldConfig.title]?.tabIndex)"
          (focus)="inputFocused(uniqFieldId)"
          (keydown.enter)="$event.preventDefault()"
          (keydown)="checkNumber($event)"
        />
      </div>

      <ng-select
        t-id="board-ticket-type-estimation"
        [id]="uniqFieldId"
        class="estimation-type-select w-50 ml-2"
        bindLabel="title"
        bindValue="unit"
        placeholder="{{ t('placeholder-select-unit') }}"
        [items]="isEstimation ? estimationTypes : fieldConfig.formatMultiple"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        formControlName="unit"
        [attr.data-tabindex]="tabIndexes[fieldConfig.order]?.tabIndex"
        (click)="setFocusedControl(tabIndexes[fieldConfig.title]?.tabIndex)"
        (focus)="inputFocused(uniqFieldId)"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear" id="board-ticket-type-estimation">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="ticket-title">{{ isEstimation ? item.title : item }}</span>
            <svg-icon
              src="assets/icons/common/unlink.svg"
              class="actions svg-icon clear-btn"
              [class.dark]="config.layout.variant === 'Dark'"
              [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
              (click)="clear(item)"
            ></svg-icon>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </fieldset>
</div>
