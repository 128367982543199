import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ConfigService } from '../../../services/config.service';
import { ComponentType } from '../../../store/models/DocumentsState';

@Component({
  selector: 'app-drag-drop-view',
  templateUrl: './drag-drop-view.component.html',
  styleUrls: ['./drag-drop-view.component.scss'],
})
export class DragDropViewComponent {
  @Input() isDraggingFile: boolean;
  @Input() component: ComponentType;

  @Output() draggingFile = new EventEmitter<boolean>();
  @Output() FileDropped = new EventEmitter<FileList>();

  config: any = {};

  constructor(protected configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  fileDropped(files: FileList): void {
    this.FileDropped.emit(files);
  }

  setIsDraggingFile(isDragging: boolean): void {
    this.draggingFile.emit(isDragging);
  }
}
