import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs';
import { QuillEditorComponent } from 'ngx-quill';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';

import { ConfigService } from '../../services/config.service';
import { QuillInitializeService } from '../../services/quill/quill-init.service';
import { EditorService } from './editor.service';

import { ConfirmAlert } from '../../alerts/alerts';
import { AuthState } from '../../store/states/auth.state';
import { QuillModulesForMobileWiki, QuillModulesForDescription } from '../../data/quill-configuration';
import { PreviewMediaComponent } from '../../../modals/data-room-modals/preview-media/preview-media.component';
import { MediaService } from '../../services/media.service';
import { WikiService } from '../../../pages/full-pages/wiki/wiki.service';
import { MinimizeService } from '../../services/minimize.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.scss'],
})
export class EditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() object: string;
  @Input() objectId: string;
  @Input() isEditMode = false;
  @Input() initTitle = '';
  @Input() isMobile = false;
  @Input() platform: string = 'web';
  @Input() initContent = '';
  @Input() users: string[] = [];
  @Input() isLoadingButton = false;
  @Input() toolbarPlacement: 'top' | 'bottom' = 'top';
  @Input() isOpenThread = false;

  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('toolbar') toolbar: ElementRef;

  @Output() onSave = new EventEmitter<{ title: string; content: string }>();
  @Output() cancelActions = new EventEmitter();
  @Output() hasUnsavedChanges = new EventEmitter<boolean>();

  editorModules: any;
  wikiMedia = [];
  startContent = '';
  isChangedQuill = false;
  editorForm: FormGroup;

  public isDark = false;
  public readonly avatarsLimitation = 3;
  public readonly avatarSize = 24;
  public readonly statusSize = 9;

  constructor(
    private _renderer: Renderer2,
    private configService: ConfigService,
    private router: Router,
    private formBuilder: FormBuilder,
    private quillInitializeService: QuillInitializeService,
    private modalsService: NgbModal,
    private editorService: EditorService,
    private store: Store,
    private mediaService: MediaService,
    private wikiService: WikiService,
    private minimizeService: MinimizeService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.editorModules = {
      ...(this.isMobile ? QuillModulesForMobileWiki : QuillModulesForDescription),
      magicUrl: true,
      QuillMarkdown: true,
      history: {
        delay: 300,
        maxStack: 100,
      },

      ...(!this.isMobile
        ? {
            imageResize: {
              modules: ['Resize', 'DisplaySize'],
            },
          }
        : {}),
    };

    this.initSubscribers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initTitle?.currentValue) {
      this.editorService.setEditorTitle(changes.initTitle.currentValue);
    }

    if (changes.initContent) {
      this.buildItemForm({ content: changes.initContent.currentValue });

      this.editorService.setEditorContent(changes.initContent.currentValue);
    }

    if (changes.isEditMode) {
      this.editor?.setDisabledState(!this.isEditMode);
      this.editor?.quillEditor.focus();
    }
  }

  ngOnDestroy(): void {}

  get isUndoActive() {
    const isUndoActive = this.editor?.quillEditor?.history.stack.undo.length;
    this.editorService.isUndoActive = isUndoActive;
    return isUndoActive;
  }

  get isRedoActive() {
    const isRedoActive = this.editor?.quillEditor?.history.stack.redo.length;
    this.editorService.isRedoActive = isRedoActive;
    return isRedoActive;
  }

  get editorTitle(): string {
    const contentValue = this.editorForm.get('content').value;
    this.isChangedQuill =
      this.initTitle !== this.editorService.getEditorTitle() || (contentValue && this.initContent !== contentValue);

    this.hasUnsavedChanges.emit(this.isChangedQuill);

    return this.editorService.getEditorTitle();
  }

  set editorTitle(content: string) {
    this.editorService.setEditorTitle(content);
  }

  private buildItemForm(item: { content: string }) {
    this.editorForm?.reset();

    this.editorForm = this.formBuilder.group({
      content: [item.content || '', Validators.required],
    });
  }

  editorChanged(event) {
    const content = event.editor.root.innerHTML;

    this.editorService.setEditorContent(content);

    this.isChangedQuill = this.initContent !== this.editorService.getEditorContent();

    this.hasUnsavedChanges.emit(this.isChangedQuill);
  }

  editorCreated(editor: any) {
    QuillInitializeService.handleEditorCreated(editor);

    this._renderer.appendChild(
      this.toolbar.nativeElement,
      (this.editor['elementRef'] as ElementRef).nativeElement.querySelector('.ql-toolbar'),
    );

    this.editor.setDisabledState(!this.isEditMode);
  }

  handleClickInEditor(event: MouseEvent) {
    this.editor.quillEditor?.getModule('imageResize')?.hide();

    if (event?.target['src']) {
      this.prepareMediaPreview();
      this.openImagePreview(event.target['src']);
    }
  }

  openImagePreview(imageUrl: string) {
    const mediaPreviewModal = this.modalsService.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
      centered: true,
    });

    mediaPreviewModal.componentInstance.previewData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      isMobile: this.isMobile,
      currentMedia: {
        url: imageUrl,
        fileName: 'image.jpg',
        originalFileName: 'image.jpg',
        ownerUserId: this.wikiService.activeWiki.value?.updatedBy,
        updated_at: this.wikiService.activeWiki.value?.updated_at,
        created_at: this.wikiService.activeWiki.value?.created_at,
      },
    };

    mediaPreviewModal.componentInstance.media = this.wikiMedia;
    mediaPreviewModal.componentInstance.isEditWiki = this.isEditMode;
    mediaPreviewModal.componentInstance.isNotDataRoom = true;

    mediaPreviewModal.closed.pipe(untilDestroyed(this)).subscribe(() => {
      let scrollTop = this.editor.quillEditor.scrollingContainer.scrollTop;
      this.editor.quillEditor.focus();
      this.editor.quillEditor.scrollingContainer.scrollTop = scrollTop;
    });

    this.minimizeService.minimizeInit(mediaPreviewModal, {
      width: '360px',
      height: '307px',
      right: '20px',
      borderRadius: '10px',
    });
  }

  private prepareMediaPreview(): void {
    const imagesUrls = this.mediaService.fetchImagesFromString(this.editorService.getEditorContent());
    this.wikiMedia = imagesUrls.map((url) => ({
      url: url,
      fileName: 'image.jpg',
      originalFileName: 'image.jpg',
      ownerUserId: this.wikiService.activeWiki.value?.updatedBy,
      updated_at: this.wikiService.activeWiki.value?.updated_at,
      created_at: this.wikiService.activeWiki.value?.created_at,
    }));

    this.wikiMedia[0].isFirstMedia = true;
    this.wikiMedia[this.wikiMedia.length - 1].isLastMedia = true;
  }

  private initSubscribers() {
    this.configService.templateConf$
      .pipe(untilDestroyed(this))
      .subscribe(({ layout: { variant } }) => (this.isDark = variant === 'Dark'));

    this.wikiService.clearContent.pipe(untilDestroyed(this)).subscribe(() => {
      this.editorForm.controls.content.setValue(this.initContent);
      this.editorService.setEditorTitle(this.initTitle);
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationCancel),
        untilDestroyed(this),
      )
      .subscribe((event: NavigationCancel) => {
        ConfirmAlert('', {
          platform: this.store.selectSnapshot(AuthState.getPlatform),
          confirmButtonClass: 'btn-solid',
          subject: this.translocoService.translate('alert.save-changes-subject'),
          text: this.translocoService.translate('alert.save-changes-text'),
          confirmButtonText: this.translocoService.translate('alert.btn-save'),
          cancelButtonText: this.translocoService.translate('alert.btn-discard'),
        }).then(
          () => {
            this.save();
            this.hasUnsavedChanges.emit(false);
            this.router.navigate([event.url]).then();
          },
          () => {
            this.editorService.setEditorTitle(this.initTitle);
            this.editorService.setEditorContent(this.initContent);
            this.hasUnsavedChanges.emit(false);
            this.router.navigate([event.url]).then();
          },
        );
      });

    this.editorService.undo$.pipe(untilDestroyed(this)).subscribe(() => this.doUndo());
    this.editorService.redo$.pipe(untilDestroyed(this)).subscribe(() => this.doRedo());

    this.editorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.editorService.isRedoActive = this.editor?.quillEditor?.history.stack.redo.length > 0;
      this.editorService.isUndoActive = this.editor?.quillEditor?.history.stack.undo.length > 0;
    });
  }

  public doRedo() {
    this.editor.quillEditor.history.redo();
  }

  public doUndo() {
    this.editor.quillEditor.history.undo();
  }

  save() {
    this.onSave.emit({ title: this.editorService.getEditorTitle(), content: this.editorForm.get('content').value });
  }

  handleCancel() {
    this.cancelActions.emit();
  }

  submitForm() {}
}
