<ng-container *ngIf="!readOnly">
  <section class="chat-app-form" [class.with-toolbar]="showEditorToolbar" *transloco="let t; read: 'chat'">
    <div class="input-group d-flex align-items-center" [class.recorder-active]="isChatFileRecording">
      <div *ngIf="!isChatFileRecording || chatId !== (recordService.recordObjectId | async)" class="editor w-100">
        <div class="input-group-prepend">
          <div class="input-group-text pl-0">
            <div t-id="chat-app-form-attachment-open-action" class="editor-btn ml-0" (click)="fileInput.click()">
              <app-svg name="paperclip" width="14" height="16" aria-hidden="true"></app-svg>
            </div>

            <div class="editor-btn emoji-picker-icon" (click)="emojiPickerToggle()">
              <app-svg name="face-smile" width="16" height="16" aria-hidden="true"></app-svg>
            </div>

            <div class="editor-btn" [class.active]="showEditorToolbar" (click)="toggleEditorToolbar()">
              <app-svg name="square-a" width="14" height="14" aria-hidden="true"></app-svg>
            </div>

            <div
              ngbDropdown
              class="editor-btn"
              placement="top-center"
              (click)="createLaneBoard()"
              t-id="chat-app-form-lane-board"
            >
              <div
                class="dropdown-toggle d-flex cursor-pointer align-items-center"
                ngbDropdownToggle
              >
                <app-svg
                  name="board"
                  width="14"
                  height="14"
                  aria-hidden="true"
                ></app-svg>
              </div>
            </div>

            <div *ngIf="checkIsPersonal && checkDirectMessage" class="editor-btn" (click)="createPoll()" >
              <app-svg name="poll" width="14" height="14" aria-hidden="true" t-id="chat-app-form-create-poll-action"></app-svg>
            </div>
          </div>
        </div>

        <quill-editor
          #chatInput
          id="chatInput"
          t-id="chat-app-form-chat-input"
          class="form-control chat-editor ql-editor"
          bounds="self"
          [ngClass]="{
          'chat-editor-light': config?.layout.variant === 'Light',
          'chat-editor-files': files?.length,
          'chat-editor-dark': config?.layout.variant === 'Dark'
        }"
          [placeholder]="t('chat-input-placeholder')"
          [modules]="editorModules"
          [appFocus]="true"
          [required]="true"
          [sanitize]="true"
          [trimOnValidation]="true"
          (onEditorCreated)="editorCreated($event)"
          (onContentChanged)="contentChanged($event)"
          (onSelectionChanged)="selectionChanged($event)"
          (onFocus)="editorFocused($event)"
          (onBlur)="editorBlurred($event)"
          [(ngModel)]="editorContent"

          [mention]="mentionChatMembers$ | async"
          [mentionConfig]="{ dropUp: true, mentionSelect: mentionFormat }"
          (searchTerm)="changeMentionSearch($event)"
          (opened)="mentionSelectOpened()"
          (closed)="mentionSelectClosed()"
          (paste)="handlePaste($event)"
        ></quill-editor>

        <div class="upload-files" *ngIf="files?.length">
          <app-message-files
            *ngFor="let file of files"
            [isMobile]="isNotMobile"
            [file]="file"
            [isUpload]="uploadFiles"
            [platform]="platform"
            [chatId]="chatId"
            [object]="object"
            [objectId]="objectId"
          ></app-message-files>
        </div>

        <div class="input-group-append align-items-center">
          <div
            *ngIf="platform === 'web' || tauriService.isTauri"
            class="dropdown screen-record-dropdown"
            placement="top-left"
            display="static"
            ngbDropdown
            [autoClose]="true"
          >
            <button ngbDropdownToggle class="editor-btn rec-btn" [disabled]="recordService.isRecordInProgress | async">
              <app-svg name="record" width="24" height="24" aria-hidden="true"></app-svg>
            </button>
            <ul class="dropdown-menu dropdown-menu-right text-left m-0 pb-0" ngbDropdownMenu>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="startScreenRecord(false, recordStartedFrom.Chat)"
              >
                <app-svg name="screen-record" width="24" height="24" [fill2]="config?.layout.variant === 'Dark' ? '#010101' : '#fff'"></app-svg>
                <span class="ml-1">{{ t('btn-record-screen') }}</span>
              </li>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="startScreenRecord(true, recordStartedFrom.Chat)"
              >
                <app-svg name="screen-record-with-mic" width="24" height="24" [fill2]="config?.layout.variant === 'Dark' ? '#010101' : '#fff'"></app-svg>
                <span class="ml-1">{{ t('btn-record-screen-mic') }}</span>
              </li>
            </ul>
          </div>

          <button
            class="editor-btn rec-btn"
            appDebounceClick
            [debounceTime]="700"
            [disabled]="recordService.isRecordInProgress | async"
            (debounceClick)="startAudioRecord(recordStartedFrom.Chat)"
          >
            <app-svg name="microphone" width="11" height="16" aria-hidden="true"></app-svg>
          </button>

          <button
            t-id="chat-app-form-send-action"
            class="editor-btn send-btn"
            [disabled]="isSendBtnDisabled"
            (click)="onAddMessage('button')"
          >
            <app-svg *ngIf="!uploadFiles" name="paper-plane" width="16" height="14"></app-svg>
            <i *ngIf="uploadFiles" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <div *ngIf="chatId === (recordService.recordObjectId | async)" class="recorder">
        <img
          *ngIf="isVoiceRecording"
          src="assets/img/animations/audio-wave-{{config?.layout?.variant === 'Dark' ? 'dark' : 'light'}}.gif"
          alt="audio-wave"
          class="audio-wave"
        />

        <div class="input-group-prepend d-flex align-items-center">
          <div class="input-group-text mr-2">
            <app-svg name="record" width="24" height="24" aria-hidden="true" class="screen-record"></app-svg>
          </div>
          <div>{{ screenRecordTime }}</div>
        </div>

        <div *ngIf="!isVoiceRecording" class="input-group-append">
          <button class="editor-btn cancel-btn" ngbTooltip="Cancel record" placement="top" (click)="resetRecord()">
            <app-svg name="trash" width="14" height="16" aria-hidden="true"></app-svg>
          </button>
          <button class="editor-btn send-btn" ngbTooltip="Send record" placement="top" (click)="proceedRecording()">
            <app-svg name="paper-plane" width="16" height="14"></app-svg>
          </button>
        </div>

        <div *ngIf="isVoiceRecording" class="input-group-append">
          <button class="editor-btn cancel-btn" ngbTooltip="Cancel record" placement="top" (click)="resetRecord()">
            <app-svg name="trash" width="14" height="16" aria-hidden="true"></app-svg>
          </button>

          <button
            class="editor-btn send-btn"
            [ngbTooltip]="t('send-record-tooltip')"
            placement="top"
            appDebounceClick
            [debounceTime]="700"
            (debounceClick)="proceedRecording()"
          >
            <app-svg name="paper-plane" width="16" height="14"></app-svg>
          </button>
        </div>
      </div>
    </div>

    <!-- List of suggested emojis starts -->
    <div
      class="emojis-list-wrapper"
      [ngClass]="{ 'emojis-list-direct': object === 'users', 'emojis-list-general': object !== 'users' }"
    >
      <ul
        *ngIf="emojiSearchMode"
        class="list-group emojis-list"
        [ngClass]="{
        'emojis-list-light': config?.layout.variant === 'Light',
        'emojis-list-dark': config?.layout.variant === 'Dark'
      }"
      >
        <li
          *ngFor="let emoji of emojiSearchResults; let i = index"
          class="list-group-item"
          [class.selected]="i === arrowKeyLocation"
          (click)="emojiSelected(emoji)"
        >
          <div class="row emoji-details">
            <div class="col-1 text-center">
              <ngx-emoji [emoji]="emoji" [backgroundImageFn]="emojiPickerImageFn"></ngx-emoji>
            </div>
            <div class="col-11 text-left">
              <div class="row">
                <div class="col">
                  <span class="emoji-name">{{ emoji.name }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="emoji-colons">{{ emoji.colons }}</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- List of suggested emojis ends -->
  </section>
</ng-container>

<input t-id="chat-app-form-file-input" #fileInput multiple type="file" style="display: none" (change)="fileChange($event)" />
