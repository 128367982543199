import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import * as moment from 'moment-timezone';

import { environment } from '../../../environments/environment';
import { RouterTenantPipe } from '../pipes/router-tenant.pipe';
import { RedirectService } from '../services/redirect.service';
import { AuthByToken, Logout } from '../store/actions/auth.action';
import { AuthState } from '../store/states/auth.state';

/** Guard to check if user is not signed in (used for login/logout etc.) **/
@Injectable()
export class NonAuthGuard {
  constructor(
    private store: Store,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private readonly localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private redirectService: RedirectService,
  ) {}

  /**
   * guard for router; checks for current auth status & redirects user to home page if user is ready logged in
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const accessToken: string = this.localStorageService.get('accessToken') || this.cookieService.get('accessToken');
    const refreshToken: string = this.localStorageService.get('refreshToken') || this.cookieService.get('refreshToken');
    const userId: string = this.localStorageService.get('userId') || this.cookieService.get('userId');
    const isRootDomain = environment.subdomains ? window.location.hostname === environment.base_host : false;
    const isAddTenant = this.localStorageService.get('isAddTenant');

    return new Promise((resolve) => {
      if (accessToken && refreshToken && userId && !isRootDomain && !isAddTenant) {
        const timezone = moment.tz.guess();
        this.store.dispatch(new AuthByToken({ accessToken, refreshToken, userId, timezone })).subscribe(() => {
          const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

          if (isAuthenticated) {
            const location = window.location.pathname.split('/');
            const item = environment.production ? 3 : 4;
            const index = environment.subdomains ? 2 : 3;
            const parsedUrl = state.url.split('?');
            const locationPath = parsedUrl[0].split('/');
            const params = parsedUrl[1] ? new URLSearchParams(parsedUrl[1]) : null;

            if (location[3] && location[3] === 'reset-password') {
              this.store
                .dispatch(new Logout({}))
                .pipe(take(1))
                .subscribe(() => window.location.reload());
            } else if (
              (location[item] && location[item] === 'confirm-invitation') ||
              (((locationPath[index] && locationPath[index] === 'register') ||
                (locationPath[index] && locationPath[index] === 'login')) &&
                params?.get('action'))
            ) {
              resolve(true);
            } else {
              const user = this.store.selectSnapshot(AuthState.getUser);
              if (!this.redirectService.isRedirectActive) {
                this.router.navigate([this.routerTenantPipe.transform('dash', user.tenantName)]);
              }
            }
            resolve(false);
          }

          resolve(true);
        });
      } else {
        this.localStorageService.remove('isAddTenant');
        resolve(true);
      }
    });
  }
}
