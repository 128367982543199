<ng-container *ngIf="media?.length && currentMedia">
  <div class="modal-header d-flex align-items-center w-100" *transloco="let t; read: 'chat'">
    <app-avatar [userId]="currentMedia.user?.id" [ngClass]="'mr-1'"></app-avatar>
    <div class="d-flex flex-column">
      <div class="d-flex ml-1">
        <span
          class="message-username image-preview-name"
          [ngClass]="{
            'image-preview-name': platform === 'web',
            'image-preview-name-mobile': platform !== 'web',
            'message-username-light': config?.layout.variant === 'Light',
            'message-username-dark': config?.layout.variant === 'Dark'
          }"
        >
          {{ currentMedia.fileName }}
        </span>
        <span
          *ngIf="fileExt"
          class="message-username"
          [ngClass]="{
            'message-username-light': config?.layout.variant === 'Light',
            'message-username-dark': config?.layout.variant === 'Dark'
          }"
        >
          .{{ fileExt }}
        </span>
      </div>

      <div>
        <span class="message-username ml-1" [ngClass]="imageUsername">
          {{ currentMedia.user?.name }}
        </span>
        <span
          *ngIf="currentMedia.updated_at !== ''"
          class="message-date ml-1"
          [ngClass]="messageDate"
        >
          <span>{{ currentMedia.updated_at | date : 'dd.MM.yy' : tz }}</span>
          <span> {{ t('at') }} at </span>
          <span>{{ currentMedia.updated_at | date : 'HH:mm' : tz }}</span>
        </span>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <div class="d-flex align-items-center">
      <i
        *ngIf="platform === 'web'"
        class="fa fa-download image-preview-modal-actions mr-2"
        aria-hidden="true"
        [ngbTooltip]="t('download-file-tooltip')"
        container="body"
        (click)="downloadFile()"
      ></i>

      <div *ngIf="platform === 'web'" [ngClass]="actionsDivider"></div>
      <div
        class="pin mr-2"
        aria-label="Pin"
        [ngClass]="{
            'pinned-icon-light': config?.layout.variant === 'Light',
            'pinned-icon-dark': config?.layout.variant === 'Dark'
          }"
      >
        <ng-container *ngIf="isLoadingPin; else pinnedActions">
          <i class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
        </ng-container>
        <ng-template #pinnedActions>
          <svg-icon
            *ngIf="!isPinned && isLimitPins"
            class="pin-icon-modal"
            src="assets/icons/pin/pin-modal.svg"
            [applyClass]="true"
            (click)="pinMedia()"
          ></svg-icon>
          <svg-icon
            *ngIf="isPinned && canUnpinImage"
            class="unpin-icon-modal"
            src="assets/icons/pin/unpin-message.svg"
            [applyClass]="true"
            (click)="unPinMedia()"
          ></svg-icon>
        </ng-template>
      </div>

      <app-svg
        name="times"
        width="15"
        height="15"
        aria-hidden="true"
        class="svg-icon image-preview-modal-actions mr-1"
        (click)="closeMediaPreview.emit(true)"
      ></app-svg>
    </div>
  </div>

  <div class="modal-body p-0 modal-body-image-preview" *transloco="let t; read: 'chat'">
    <div class="d-flex h-100"
         [style.max-height.%]="90"
         [style.height.%]="100"
    >
      <i
        *ngIf="platform === 'web'"
        class="fa fa-chevron-left image-preview-arrows"
        aria-hidden="true"
        (click)="prev()"
        [ngStyle]="{ visibility: currentMedia.isFirstPreviewMedia ? 'hidden' : 'visible' }"
      ></i>
      <div
        class="d-flex align-items-center justify-content-center"
        [ngSwitch]="currentMedia.fileType"
        [ngClass]="{
          'image-preview-wrapper': platform === 'web',
          'image-preview-wrapper-mobile': platform !== 'web'
        }"
      >
        <!-- IMAGE CONTAINER -->
        <div *ngSwitchCase="MediaPreviewType.Image" [style.height.%]="100">
          <pinch-zoom
            [style.height.%]="100"
            class="d-flex align-items-center justify-content-center bg-transparent"
            [ngClass]="{
             'image-preview-wrapper': platform === 'web',
             'image-preview-wrapper-mobile': platform !== 'web'
             }"
            [minScale]="1"
            [limit-zoom]="10"
          >
            <img class="image-preview" src="{{ currentMedia.filePath }}" />
          </pinch-zoom>
        </div>

        <!-- VIDEO CONTAINER -->
        <div *ngSwitchCase="MediaPreviewType.Video" class="video-player-wrapper h-100">
          <div *ngIf="isDownloadingFile">{{ t('downloading-video') }}</div>
          <app-video-player [videoUrl]="currentMedia.filePath" [isDownloading]="isDownloadingFile"></app-video-player>
        </div>

        <!-- MUSIC CONTAINER -->
        <div *ngSwitchCase="MediaPreviewType.Music" class="video-player-wrapper">
          <audio controls>
            <source src="{{ currentMedia.filePath }}" />
          </audio>
        </div>

        <!-- DEFAULT CONTAINER -->
        <span *ngSwitchDefault class="d-flex flex-column image-preview">
          <span class="mb-2 text-center"><i class="ft-file font-large-3"></i></span>
          <span>{{ t('no-preview-for-media') }}</span>
        </span>
      </div>
      <i
        *ngIf="platform === 'web'"
        class="fa fa-chevron-right image-preview-arrows"
        aria-hidden="true"
        (click)="next()"
        [ngStyle]="{ visibility: currentMedia.isLastPreviewMedia ? 'hidden' : 'visible' }"
      ></i>
    </div>
  </div>
</ng-container>
