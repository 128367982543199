import * as moment from 'moment-timezone';
import { TreeType } from '../components/documents/documents.component';

const toGermanDateFormat = (date: string) => moment(date).format('DD.MM.YYYY, HH:mm');

export const ACTION_LOGS_TEMPLATES = {
  // object:actionObject:actionOperationId[:actionCrud]
  'spaces:documents:filesUpload': (data) => `<b>${data.userName}</b> uploaded ${data.fileName}`,
  'spaces:documents:filesCreateFolder': (data) => `<b>${data.userName}</b> created the folder '${data.folderName}'`,
  'spaces:documents:filesCreateLink': (data) => `<b>${data.userName}</b> created the link '${data.linkName}'`,
  'projects:projects:projectCreate': (data) => `Project ${data.projectName} was created`,
  'spaces:invite:spacesAssignUser': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  'spaces:invite:spacesConfirmNew': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  'spaces:invite:spacesConfirmExist': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  'projects:documents:filesUpload': (data) => `<b>${data.userName}</b> uploaded ${data.fileName}`,
  'projects:documents:filesCreateFolder': (data) => `<b>${data.userName}</b> created the folder '${data.folderName}'`,
  'projects:documents:filesCreateLink': (data) => `<b>${data.userName}</b> created the link '${data.linkName}'`,
  'projects:invite:projectAssignUser': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  'projects:invite:projectConfirmNew': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  'projects:invite:projectConfirmExist': (data) => `<b>${data.userName}</b> joined as ${data.roleName}`,
  // event was created
  'spaces:calendar-events:calendarEventCreate': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.spaceName}</b>`,
  'projects:calendar-events:calendarEventCreate': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.projectName}</b>`,
  'users:calendar-events:calendarEventCreate': (data) =>
    `You was invited to join "${data.eventName}" by <b>${data.userName}</b>`,
  'tenants:calendar-events:calendarEventCreate': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.tenantName}</b>`,
  // invited to event
  'spaces:calendar-events:calendarEventMembersUpdate:create': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.spaceName}</b>`,
  'projects:calendar-events:calendarEventMembersUpdate:create': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.projectName}</b>`,
  'users:calendar-events:calendarEventMembersUpdate:create': (data) =>
    `You was invited to join "${data.eventName}" by <b>${data.userName}</b>`,
  'tenants:calendar-events:calendarEventMembersUpdate:create': (data) =>
    `You was invited to join "${data.eventName}" in <b>${data.tenantName}</b>`,
  // removed from event
  'spaces:calendar-events:calendarEventMembersUpdate:delete': (data) =>
    `You was removed from event "${data.eventName}" in <b>${data.spaceName}</b>`,
  'projects:calendar-events:calendarEventMembersUpdate:delete': (data) =>
    `You was removed from event "${data.eventName}" in <b>${data.projectName}</b>`,
  'users:calendar-events:calendarEventMembersUpdate:delete': (data) =>
    `You was removed from event "${data.eventName}" by <b>${data.userName}</b>`,
  'tenants:calendar-events:calendarEventMembersUpdate:delete': (data) =>
    `You was removed from event "${data.eventName}" in <b>${data.tenantName}</b>`,
  // event was updated
  'spaces:calendar-events:calendarEventUpdate': (data) =>
    `Event "${data.eventName}" was updated in <b>${data.spaceName}</b>`,
  'projects:calendar-events:calendarEventUpdate': (data) =>
    `Event "${data.eventName}" was updated in <b>${data.projectName}</b>`,
  'users:calendar-events:calendarEventUpdate': (data) =>
    `Event "${data.eventName}" was updated by <b>${data.userName}</b>`,
  'tenants:calendar-events:calendarEventUpdate': (data) =>
    `Event "${data.eventName}" was updated in <b>${data.tenantName}</b>`,
  // event was deleted
  'spaces:calendar-events:calendarEventDelete': (data) =>
    `Event "${data.eventName}" was deleted in <b>${data.spaceName}</b>`,
  'projects:calendar-events:calendarEventDelete': (data) =>
    `Event "${data.eventName}" was deleted in <b>${data.projectName}</b>`,
  'users:calendar-events:calendarEventDelete': (data) =>
    `Event "${data.eventName}" was deleted by <b>${data.userName}</b>`,
  'tenants:calendar-events:calendarEventDelete': (data) =>
    `Event "${data.eventName}" was deleted in <b>${data.tenantName}</b>`,
  // event member status
  'spaces:calendar-events:calendarEventUpdateMemberStatus': (data) =>
    `<b>${data.userName}</b> responded to "${data.eventName}" with: ${data.decision}`,
  'projects:calendar-events:calendarEventUpdateMemberStatus': (data) =>
    `<b>${data.userName}</b> responded to "${data.eventName}" with: ${data.decision}`,
  'users:calendar-events:calendarEventUpdateMemberStatus': (data) =>
    `<b>${data.userName}</b> responded to "${data.eventName}" with: ${data.decision}`,
  'tenants:calendar-events:calendarEventUpdateMemberStatus': (data) =>
    `<b>${data.userName}</b> responded to "${data.eventName}" with: ${data.decision}`,
  // event will start at
  'spaces:calendar-events:calendarEventsRemind': (data) =>
    `Calendar event "${data.eventName}"` +
    (data.isStarted ? '' : ` will start at ${toGermanDateFormat(data.start)}`) +
    (data.attempt ? `<br><em>Reminder ${data.attempt.value}${data.attempt.period} before</em>` : ''),
  'projects:calendar-events:calendarEventsRemind': (data) =>
    `Calendar event "${data.eventName}"` +
    (data.isStarted ? '' : ` will start at ${toGermanDateFormat(data.start)}`) +
    (data.attempt ? `<br><em>Reminder ${data.attempt.value}${data.attempt.period} before</em>` : ''),
  'users:calendar-events:calendarEventsRemind': (data) =>
    `Calendar event "${data.eventName}"` +
    (data.isStarted ? '' : ` will start at ${toGermanDateFormat(data.start)}`) +
    (data.attempt ? `<br><em>Reminder ${data.attempt.value}${data.attempt.period} before</em>` : ''),
  'tenants:calendar-events:calendarEventsRemind': (data) =>
    `Calendar event "${data.eventName}"` +
    (data.isStarted ? '' : ` will start at ${toGermanDateFormat(data.start)}`) +
    (data.attempt ? `<br><em>Reminder ${data.attempt.value}${data.attempt.period} before</em>` : ''),
  // social
  'spaces:social-media:socialMediaCreatePost': (data) =>
    `Please review a post on ${data.networkName} for <b>${data.spaceName}</b>`,
  default: (data) => (typeof data === 'string' ? data : JSON.stringify(data)),
};

export const ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS = {
  'spaces:documents:filesUpload': (data) => ({
    urlSuffix: 'documents',
    tab: 'tabDataRoom', // for mobile only
    tree: TreeType.storage,
    file: data.actionObjectId,
    folder: data.templateData.folderId,
  }),
  'spaces:documents:filesCreateFolder': (data) => ({
    urlSuffix: 'documents',
    tab: 'tabDataRoom', // for mobile only
    tree: TreeType.storage,
    folder: data.actionObjectId,
  }),
  'spaces:documents:filesCreateLink': (data) => ({ urlSuffix: 'documents', folder: data.templateData.folderId }),
  'spaces:invite:spacesAssignUser': () => ({ urlSuffix: 'members' }),
  'spaces:invite:spacesConfirmNew': () => ({ urlSuffix: 'members' }),
  'spaces:invite:spacesConfirmExist': () => ({ urlSuffix: 'members' }),
  'projects:documents:filesUpload': (data) => ({
    urlSuffix: 'documents',
    tab: 'tabDataRoom', // for mobile only
    tree: TreeType.storage,
    folder: data.templateData.folderId,
    file: data.actionObjectId,
  }),
  'projects:documents:filesCreateFolder': (data) => ({
    urlSuffix: 'documents',
    tab: 'tabDataRoom', // for mobile only
    tree: TreeType.storage,
    folder: data.actionObjectId,
  }),
  'projects:documents:filesCreateLink': (data) => ({ urlSuffix: 'documents', folder: data.templateData.folderId }),
  'projects:invite:projectAssignUser': () => ({ urlSuffix: 'members' }),
  'projects:invite:projectConfirmNew': () => ({ urlSuffix: 'members' }),
  'projects:invite:projectConfirmExist': () => ({ urlSuffix: 'members' }),
  'spaces:calendar-events:calendarEventCreate': (data) => ({ urlSuffix: 'calendar', event: data.actionObjectId }),
  'projects:calendar-events:calendarEventCreate': (data) => ({ urlSuffix: 'calendar', event: data.actionObjectId }),
  'spaces:calendar-events:calendarEventUpdateMemberStatus': (data) => ({
    urlSuffix: 'calendar',
    event: data.actionObjectId,
  }),
  'projects:calendar-events:calendarEventUpdateMemberStatus': (data) => ({
    urlSuffix: 'calendar',
    event: data.actionObjectId,
  }),
  'spaces:social-media:socialMediaCreatePost': () => ({ urlSuffix: 'social-media' }),
  default: () => '',
};
