<div class="modal-body url" [class.url-dark]="isDarkTheme" *transloco="let t; read: 'modals.url-change'">
  <div class="url__wrapper">
    <div class="url__title">{{ t('change-url') }}</div>
    <div class="url__item">
      <label for="url">{{ t('form-url') }}</label>
      <input
        [formControl]="urlData"
        t-id="url-change"
        id="url"
        [placeholder]="t('type-something-placeholder')"
        type="text"
        class="form-control"
      >
    </div>

    <div class="url__item">
      <label for="title">{{ t('form-title') }}</label>
      <input
        [formControl]="titleData"
        t-id="url-title"
        id="title"
        [placeholder]="t('type-something-placeholder')"
        type="text"
        class="form-control"
      >
    </div>

    <div class="url__buttons">
      <button class="btn btn-solid btn-save" type="button" (click)="openUrl()">
        {{ t('btn-open-url') }}
      </button>

      <div class="url__buttons-right">
        <button class="btn btn-subtle" type="button" (click)="closeModal()">
          {{ t('btn-cancel') }}
        </button>
        <button class="btn btn-solid btn-save" type="button" (click)="saveUrl()">
          {{ t('btn-save') }}
        </button>
      </div>
    </div>
  </div>
</div>
