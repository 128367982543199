import { Store } from '@ngxs/store';
import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core';

import { ComponentType } from '../store/models/DocumentsState';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Input() component: ComponentType = 'Chats';
  @Output() FileDropped = new EventEmitter<FileList>();
  @Output() draggingFile = new EventEmitter<boolean>();

  isDraggingFile: boolean;
  constructor(private store: Store) {}

  // Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.handleEventListener(true);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.handleEventListener(false);
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  ondrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.FileDropped.emit(files);
    }

    this.handleEventListener(false);
  }

  private handleEventListener(isDragging: boolean): void {
    if (this.isDraggingFile === isDragging) {
      return;
    }

    this.isDraggingFile = isDragging;
    this.draggingFile.emit(isDragging);
  }
}
