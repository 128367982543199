import {
  Component,
  Input,
  TemplateRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, take, tap, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';

import { environment } from '../../../../environments/environment';
import { ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS } from '../../data/action-logs-templates';
import { ActionLog, actionObjectCorrection, ActionObjects, OperationIds } from '../../data/action-logs-types';
import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { VideoCallsGetResDto } from '../../../api/models/video-calls-get-res-dto';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { ConfigService } from '../../services/config.service';
import { SocketsService } from '../../services/sockets.service';
import { TauriService } from '../../services/tauri.service';

import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { CalendarEventModalComponent } from '../../../modals/calendar-event/calendar-event.component';
import { VideoCallModalComponent } from '../../../modals/video-call/video-call-modal.component';
import { ToggleSidebar } from '../../store/actions/configs.action';
import { ActionsLogsGet } from '../../store/actions/actions-logs.action';
import { OpenVideoCallMobile } from '../../store/actions/modals.action';
import { ColumnsGetList, TicketsGetList } from '../../store/actions/board.action';
import { CalendarEventsStopRemind } from '../../store/actions/calendar-events.action';
import { HasUnreadNotificationsUpdate } from '../../store/actions/auth.action';
import { ChatsSetActiveVideoCallRooms } from '../../store/actions/chats.action';
import { SpacesResetNumberOfActivities } from '../../store/actions/spaces.action';
import { ProjectsResetNumberOfActivities } from '../../store/actions/projects.action';
import { ConfigsState } from '../../store/states/configs.state';
import { ActionsLogsState } from '../../store/states/actions-logs.state';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { AuthState } from '../../store/states/auth.state';
import { BoardsState } from '../../store/states/boards.state';
import { ChatsState } from '../../store/states/chats.state';
import { VideoCallsState } from '../../store/states/video-calls.state';
import { MinimizeService } from '../../services/minimize.service';
import { DraftService } from '../../services/draft.service';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { LocalStorageService } from 'ngx-localstorage';
import { ActionLogService } from '../../services/action-log.service';
import { ActionOperationIdEnum } from '../../enums/action-operation-id.enum';

@Component({
  selector: 'app-action-logs',
  templateUrl: './action-logs.component.html',
  styleUrls: ['./action-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLogsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalPostApproveContent') modalPostApproveContent: TemplateRef<any>;

  @Input() platform = 'web';
  @Input() object: string;
  @Input() objectId: string;
  @Input() isNavbar = false;
  @Input() isMobile = false;

  destroy$: Subject<void> = new Subject<void>();
  user$: Subscription;
  modalRef: NgbModalRef;
  modalData: any;

  config: any;
  actionsLogs: any[];
  isLogLoaded = false;
  isCallOpened = false;
  sidebarConfig = null;
  hasUnreadNotifications = false;
  spaces: any[];
  projects: any[];
  lang = this.localStorage.get(LocalStorageKeys.language);
  userData: UsersPublicFieldsResDto;
  activeCallRooms: VideoCallsGetResDto[];

  constructor(
    private cdr: ChangeDetectorRef,
    private modal: NgbModal,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private actions: Actions,
    private store: Store,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private checkPermissionPipe: CheckPermissionPipe,
    private socket: SocketsService,
    private configService: ConfigService,
    private toastrService: ToastrService,
    private socketsService: SocketsService,
    private tauriService: TauriService,
    private translocoService: TranslocoService,
    private localStorage: LocalStorageService,
    private actionLogService: ActionLogService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.store
      .select(SpacesState.getLoadedSpaces)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.spaces = res));

    this.store
      .select(ProjectsState.getLoadedProjects)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.projects = res));

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => (this.userData = user),
      });

    this.store
      .select(ChatsState.getActiveVideoCallRooms)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms) => (this.activeCallRooms = rooms));

    this.store
      .select(VideoCallsState.getIsOpened)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpened) => (this.isCallOpened = isOpened));

    if (this.platform === 'web') {
      this.store
        .select(ConfigsState.getSidebarConfigs)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.sidebarConfig = res;
          this.cdr.detectChanges();
        });
    }

    this.getActionLogs();
    this.notificationUpdatesListener();

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(HasUnreadNotificationsUpdate))
      .subscribe(({ payload }) => {
        if (payload) {
          this.hasUnreadNotifications = true;
          this.dispatchActionsLogsGet({ object: 'users' });
        } else {
          if (this.tauriService.isTauri) {
            this.tauriService.updateBadgeCount({ notificationsCount: 0 });
          }
        }
      });

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isLogLoaded && changes.objectId && !!changes.objectId.currentValue) {
      this.getActionLogs();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  markAsRead(notificationId: string = null) {
    if (notificationId) {
      this.socketsService.get().emit('users:usersNotificationStatusesSkip', { notificationId });
    } else {
      this.socketsService.get().emit('users:usersNotificationStatusesSkip');
    }
  }

  toggleNotifications() {
    const notificationDrp: HTMLElement = document.getElementById('drp-notification') as HTMLElement;
    notificationDrp.click();
  }

  toggleSidebar() {
    if (this.platform === 'web') {
      this.sidebarConfig.isOpened =
        this.sidebarConfig.sidebarContentType === 'notifications' ? !this.sidebarConfig.isOpened : true;
      this.store.dispatch(
        new ToggleSidebar({ isOpened: this.sidebarConfig.isOpened, sidebarContentType: 'notifications' }),
      );
      this.cdr.detectChanges();
    }

    if (this.platform !== 'web' || this.sidebarConfig?.isOpened) {
      this.toggleNotifications();
    }
  }

  dispatchActionsLogsGet(payloadObject) {
    this.store
      .dispatch(new ActionsLogsGet(payloadObject))
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(() => {
          this.isLogLoaded = true;
          this.cdr.detectChanges();
        }),
      )
      .subscribe((result) => {
        if (payloadObject.object === 'spaces') {
          this.store.dispatch(new SpacesResetNumberOfActivities(payloadObject.objectId));
        } else if (payloadObject.object === 'projects') {
          this.store.dispatch(new ProjectsResetNumberOfActivities(payloadObject.objectId));
        }
      });
  }

  getActionLogs() {
    this.isLogLoaded = false;
    this.cdr.detectChanges();
    if (this.checkPermissionPipe.transform(this.object + '::' + this.objectId + '::actionsLogGetList')) {
      this.dispatchActionsLogsGet({ object: this.object, objectId: this.objectId });
    } else {
      this.isLogLoaded = true;
      this.cdr.detectChanges();
    }
  }

  openUserChat(userId: string): void {
    this.socket.get().emit('chats:getDirectChatIdByUserId', { userId });
  }

  updateUserNotifications(): void {
    if (this.hasUnreadNotifications) {
      this.hasUnreadNotifications = false;
    }

    // Updating electron badge counter
    // TODO: @UnreadNotifications we can set the exact number of unread notifications
    //       currently we don't know which notification has been read or not
    if (this.tauriService.isTauri) {
      const { hasUnreadNotifications } = this.store.selectSnapshot(AuthState.getUser);
      this.tauriService.updateBadgeCount({ notificationsCount: hasUnreadNotifications ? 1 : 0 });
    }
  }

  notificationUpdatesListener(): void {
    if (this.object === 'users') {
      this.store
        .select(ActionsLogsState.getNotifications)
        .pipe(takeUntil(this.destroy$))
        .subscribe((notifications) => {
          if (notifications && notifications.length) {
            this.actionsLogs = this.getActionsWithTemplate(notifications);
            this.hasUnreadNotifications = true;
            this.updateUserNotifications();
            this.cdr.detectChanges();
          }
        });
    } else {
      this.store
        .select(ActionsLogsState.getActionsLogs)
        .pipe(takeUntil(this.destroy$))
        .subscribe((actionsLogs) => {
          if (actionsLogs && actionsLogs.length) {
            this.actionsLogs = this.getActionsWithTemplate(actionsLogs);
            this.cdr.detectChanges();
          }
        });
    }
  }

  getActionsWithTemplate(actions: any[]) {
    return [...actions].map((item) => {
      return { ...item, htmlText: this.actionLogService.parseTemplate(item) };
    });
  }

  trackById(index, item): string {
    return item._id;
  }

  generateRouterLink(action: any) {
    let prefix = '';
    let suffix = '';
    let query: any;
    let id = action.objectId;

    if (action.object === 'users') {
      return this.routerTenantPipe.transform('/dash');
    }
    if (action.object === 'spaces') {
      prefix = 'space';
      const item = this.spaces.find((subItem) => subItem._id === action.objectId);
      if (item) {
        id = item.slug ? item.slug : item._id;
      }
    } else if (action.object === 'projects') {
      prefix = 'project';
      const item = this.projects.find((subItem) => subItem._id === action.objectId);
      if (item) {
        id = item.slug ? item.slug : item._id;
      }
    }

    const key = action.object + ':' + action.actionObject + ':' + action.actionOperationId;
    if (ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key] !== undefined) {
      query = ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key]({
        ...action,
        templateData: JSON.parse(action.templateData),
      });
      if (query?.urlSuffix) {
        suffix = '/' + query?.urlSuffix;
      }
    }

    if (
      action.actionOperationId === OperationIds.FileUpload ||
      action.actionOperationId === OperationIds.FilesCreateFolder
    ) {
      if (this.isMobile) {
        return this.routerTenantPipe.transform(prefix + '/' + this.objectId);
      }
      return this.routerTenantPipe.transform(prefix + '/' + this.objectId + '/documents');
    }

    return this.routerTenantPipe.transform('/' + prefix + '/' + id + suffix);
  }

  generateQueryParams(action: any) {
    let query = {};
    const key = action.object + ':' + action.actionObject + ':' + action.actionOperationId;
    if (ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key] !== undefined) {
      query = ACTION_LOGS_URL_SUFFIXES_AND_QUERY_PARAMS[key]({
        ...action,
        templateData: JSON.parse(action.templateData),
      });
    }
    delete query['urlSuffix'];
    return query;
  }

  generateNewLinkOrParam(action: any, type: 'queryParams' | 'routerLink') {
    if (!(<any>Object).values(OperationIds).includes(action.getOperationId)) {
      if (type === 'routerLink') {
        return this.generateRouterLink(action);
      } else if (type === 'queryParams') {
        return this.generateQueryParams(action);
      }
    }
    return null;
  }

  getCalendarEventData(action: ActionLog) {
    const modalRef = this.modal.open(CalendarEventModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      action: 'View event',
      displayName: this.translocoService.translate('calendar.view-event'),
      object: action.object,
      objectId: action.objectId,
      event: {
        _id: action.actionObjectId,
      },
    };
  }

  private handleCalendarEventData(action: ActionLog): void {
    const actionUrl = this.getActionUrl(action, 'calendar');

    if (this.platform === 'web') {
      if (actionUrl === this.router.url.split('?')[0]) {
        this.getCalendarEventData(action);
      } else {
        this.router.navigate([actionUrl], { queryParams: { event: action.actionObjectId } });
      }
    } else {
      this.getCalendarEventData(action);
    }
  }

  private openThreadWithMessage(action: ActionLog): void {
    const actionUrl = `/${action.tenantName}${action.url}`;

    this.router.navigate([actionUrl], { queryParams: { thread: action.actionObjectId } });
  }

  private openBoardWithTicket(action: ActionLog): void {
    forkJoin([
      this.store.dispatch(new ColumnsGetList({ object: action.object, objectId: action.objectId })),
      this.store.dispatch(new TicketsGetList({ object: action.object, objectId: action.objectId })),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const tickets: any[] = this.store.selectSnapshot(BoardsState.getTicketsList);
        const columns: any[] = this.store.selectSnapshot(BoardsState.getColumnsList);

        if (tickets?.length && columns?.length) {
          const ticket = tickets.find((t) => t._id === action.actionObjectId);

          const board = this.getBoardName(columns, ticket);
          const actionUrl = this.getActionUrl(action, board);

          if (this.platform === 'web') {
            if (actionUrl === this.router.url.split('?')[0]) {
              this.openTicketModal(action);
            } else {
              if (environment.subdomains) {
                this.router.navigate([actionUrl], { queryParams: { ticket: action.actionObjectId } });
              } else {
                this.router.navigate(['/' + action.tenantName + actionUrl], {
                  queryParams: { ticket: action.actionObjectId },
                });
              }
            }
          } else {
            this.openTicketModal(action);
          }
        } else {
          this.openTicketModal(action);
        }
      });
  }

  private openTicketModal(action: ActionLog): void {
    const draft = this.draftService.isHasActiveDraft(action.actionObjectId);

    if (draft) {
      this.draftService.openTicketOrDraft(draft, {
        id: action.actionObjectId,
        object: action.object as 'spaces' | 'projects',
        objectId: action.objectId,
      });
    } else {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: action.actionObjectId,
        object: action.object,
        objectId: action.objectId,
      };

      this.minimizeService.minimizeInit(modalRef);
    }
  }

  private getBoardName(columns: any[], ticket: any): string {
    let board = 'board/kanban';

    if (ticket) {
      const column = columns.find((c) => c._id === ticket.columnId);

      if (column?.title !== 'ARCHIVE') {
        board = 'board/backlog';
      } else {
        board = 'board/archive';
      }
    }

    return board;
  }

  private getActionUrl(action: ActionLog, slug: string): string {
    const _tenantName = environment.production ? '' : `/${action.tenantName}`;

    return action.object === 'users'
      ? `${_tenantName}/${actionObjectCorrection[action.object]}`
      : `${_tenantName}/${actionObjectCorrection[action.object]}/${action.objectId}/${slug}`;
  }

  actionButton(action: ActionLog) {
    if (this.object === 'users') {
      if (this.platform !== 'web' || !this.sidebarConfig?.isOpened) {
        this.toggleNotifications();
      }

      this.markAsRead(action._id);

      if (!(<any>Object).values(OperationIds).includes(action.getOperationId)) {
        console.log(1);
        return null;
      } else if (action.getOperationId === OperationIds.CalendarEventGetList) {
        console.log(2);
        this.handleCalendarEventData(action);
      } else if (action.getOperationId === OperationIds.ActionsLogGetList) {
        console.log(3);
        if (action.actionObject === ActionObjects.Tickets) {
          console.log(4);
          this.openBoardWithTicket(action);
        } else if (action.actionObject === ActionObjects.Threads) {
          console.log(5);
          this.openThreadWithMessage(action);
        } else if (action.actionObject === ActionObjects.Sprints) {
          console.log(6);
          console.log(this.routerTenantPipe.transform(action.url));
          this.router.navigate([this.routerTenantPipe.transform(action.url)]);
        } else if (action.actionObject === ActionObjects.Spaces) {
          console.log(8);
          console.log(this.routerTenantPipe.transform(action.url));

          this.router.navigate([this.routerTenantPipe.transform(action.url)]);
        } else if (action.actionObject === ActionObjects.Projects) {
          console.log(9);
          console.log(this.routerTenantPipe.transform(action.url));

          this.router.navigate([this.routerTenantPipe.transform(action.url)]);
        }
      } else if (action.getOperationId === OperationIds.VideoCallRoomCreated) {
        console.log(10);
        this.openVideoCall(action);
      }
    } else {
      const actionUrl = this.generateNewLinkOrParam(action, 'routerLink');
      const queryParams = this.generateNewLinkOrParam(action, 'queryParams');

      if (actionUrl) {
        if (
          action.actionOperationId === OperationIds.FileUpload ||
          action.actionOperationId === OperationIds.FilesCreateFolder
        ) {
          if (this.isMobile) {
            this.router.navigate([actionUrl], { queryParams });
          } else {
            const { tab, ...rest } = queryParams;
            this.router.navigate([actionUrl], { queryParams: rest });
          }
        } else {
          this.router.navigate([actionUrl], { queryParams });
        }
      }
    }
  }

  reminderNotification(actions: any[]) {
    if (actions && actions.length > 0) {
      const reminds = actions.filter((item) => item.actionOperationId === ActionOperationIdEnum.CALENDAR_EVENTS_REMIND);

      if (reminds.length > 0) {
        let reminderContent = '';
        const reminders = [];

        reminds.map((item) => {
          const templateData = JSON.parse(item.templateData);
          const eventAttempt = this.actionLogService.getAttemptInMinutes(templateData.attempt);
          const eventStart = templateData.start;
          const dif = moment(eventStart).diff(moment(new Date()), 'minutes');

          // time before end show toast ~ 1 minute
          if (
            dif > 0 &&
            (!templateData.attempt || (templateData.attempt && dif + 1 === eventAttempt)) &&
            moment().add(dif, 'minutes').toDate() <= moment(eventStart).toDate()
          ) {
            if (reminders.indexOf(item.actionObjectId) === -1) {
              reminders.push(item.actionObjectId);
              reminderContent +=
                (reminderContent === '' ? '' : '<hr class="hr-info">') + this.actionLogService.parseTemplate(item);
            }
          }
        });

        if (reminderContent !== '') {
          this.toastrService.info(reminderContent, this.translocoService.translate('toastr.title-reminder'), {
            enableHtml: true,
          });
        }
      }
    }
  }

  stopReminder(action: any) {
    const eventStart = JSON.parse(action.templateData).start;
    if (moment().toDate() < moment(eventStart).toDate()) {
      this.store
        .dispatch(new CalendarEventsStopRemind({ id: action.actionObjectId }))
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.reminder-stoped'),
              this.translocoService.translate('toastr.title-success'),
            );
          },
          (err) => {
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
  }

  openVideoCall(action: ActionLog): void {
    if (!this.isCallOpened) {
      this.store.dispatch(new ChatsSetActiveVideoCallRooms());

      const roomId = action.url.split('/').pop();
      const activeRoom = this.activeCallRooms?.find((room) => room.videoCallId === roomId);

      if (activeRoom && activeRoom.participants.length > 0) {
        if (this.platform === 'web') {
          const modalRef = this.modal.open(VideoCallModalComponent, {
            windowClass: 'cropper-modal video-call-modal',
            backdrop: false,
          });
          modalRef.componentInstance.callData = { roomId, user: this.userData };
        } else {
          this.store.dispatch(new OpenVideoCallMobile(roomId));
        }
      } else {
        this.toastrService.info(
          this.translocoService.translate('toastr.video-call-ended'),
          this.translocoService.translate('toastr.title-video-call-ended'),
        );
      }
    } else {
      this.toastrService.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }
}
