<ng-container *ngIf="sortByList.length">
  <div
    class="sorting-toggle d-flex align-items-center"
    [ngClass]="{ 'sorting-toggle--dark': (config$ | async)?.layout.variant === 'Dark' }"
    *transloco="let t; read: 'data-room'"
  >
    <span class="text-nowrap mr-1">{{ t('form-sort-by') }}</span>
    <ng-select
      class="sort-select"
      bindLabel="label"
      bindValue="value"
      [searchable]="false"
      [items]="sortByList"
      [clearable]="false"
      [(ngModel)]="sortBy"
      (change)="toggleSortBy()"
      (close)="select.blur()"
    >
    </ng-select>
    <button class="btn rounded-circle d-inline-flex p-1" (click)="toggleDirection()">
      <i class="direction"
         [ngClass]="{
        'ft-arrow-up': direction === 'ASC',
        'ft-arrow-down': direction === 'DESC'
      }"
      ></i>
    </button>
  </div>
</ng-container>
