<!-- image cropper modal -->
<ng-template #imageCropperModal let-c="close" let-d="dismiss" *transloco="let t; read: 'modals.project'">
  <div class="modal-header">
    <h5 class="modal-title">{{ t('title-edit-image') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeCropperModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <app-image-cropper
      [image]="imageChangedEvent"
      [aspectRatio]="1"
      [resizeToWidth]="128"
      (avatarImagePreview)="setImagePreview($event)"
      (avatarImageFile)="setProjectImageFile($event)"
      (closeCropperModal)="closeCropperModal()"
    ></app-image-cropper>
  </div>
</ng-template>

<!-- project create modal -->
<div class="modal-header">
	<button t-id="project-create-action" type="button" class="close" aria-label="Close" (click)="close()">
		<app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
	</button>
</div>
<div class="modal-body" *transloco="let t">
	<section id="ngx">
		<aw-wizard #wizard navBarLocation='bottom' [disableNavigationBar]="true">
			<aw-wizard-step class="wizard-step wizard-step_wrapper">
				<div class="creation-pic">
					<img
						src="assets/img/project-creation-{{ config?.layout.variant === 'Light' ? 'light' : 'dark' }}.png"
						alt="space"/>
				</div>
				<div class="creation-title">
					{{ t('modals.project.title-create-new-project') }}
				</div>
				<div class="creation-hint">
					{{ t('modals.project.project-creation-hint') }}
				</div>
				<form #form_step1="ngForm" class="editForm" novalidate>
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label" for="projectName">{{ t('modals.project.form-project-name') }}</label>
								<input
									class="form-control form-control_creation input-md"
									t-id="project-create-name"
									#projectName="ngModel"
									id="projectName"
									name="projectName"
									type="text"
									ngModel
									required
									[placeholder]="t('common.type-something-placeholder')"
									[appFocus]="platform === 'web'"
								/>
								<small
									class="form-text danger"
									*ngIf="!projectName.valid && (projectName.dirty || projectName.touched)"
								>
									{{ t('common.form-required') }}
								</small>
							</div>
							<div class="form-group">
								<label class="form-control-label" for="projectPrefix">{{ t('modals.project.form-project-prefix') }}</label>
                <div class="d-flex">
                  <span>
                    <input class="form-control form-control_creation input-md" type="text" value="{{spacePrefix}}-" disabled />
                  </span>
                  <span class="flex-grow-1 ml-2">
                    <input
                      class="form-control form-control_creation input-md"
                      t-id="project-create-prefix"
                      #projectPrefix="ngModel"
                      id="projectPrefix"
                      name="prefix"
                      type="text"
                      ngModel
                      required
                      maxlength="6"
                      [placeholder]="t('modals.project.project-prefix-placeholder')"
                      pattern="^(?=.{1,6}$)[A-Za-z]{1,6}[0-9]{0,3}$"
                    />
                    <small
                      class="form-text danger"
                      *ngIf="!projectPrefix.valid && (projectPrefix.dirty || projectPrefix.touched)"
                    >
                      {{ t('common.form-required') }}
                    </small>
                  </span>
							</div>
							</div>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12">
							<div class="form-group creation-buttons text-center">
								<button
									*ngIf="!stepInEditMode"
									t-id="project-create-next-step-action"
									type="button"
									uiSref="work"
									class="btn btn-solid btn-modal w-100"
									(click)="nextStep(wizard)"
									[disabled]="!form_step1.valid"
								>
									{{ t('common.btn-next') }}
								</button>
								<button
									*ngIf="stepInEditMode"
									t-id="project-create-next-step-corfirm-action"
									type="button"
									uiSref="work"
									class="btn btn-solid btn-modal w-100"
									(click)="confirmEdit(wizard)"
									[disabled]="!form_step1.valid"
								>
									{{ t('common.confirm') }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</aw-wizard-step>

			<aw-wizard-step class="wizard-step wizard-step_wrapper" awOptionalStep>
				<div class="creation-pic">
					<img src="assets/img/creation-avatar-{{ config?.layout.variant === 'Light' ? 'light' : 'dark' }}.png" alt="avatar"/>
				</div>
				<div class="creation-title">
					{{ t('modals.project.title-add-avatar') }}
				</div>
				<form #form_step2="ngForm" class="editForm" novalidate>
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<div class="creation-naming">
									<div class="creation-name">
								    <span [ngStyle]="{'background': color.value}">
									    {{ projectName.value?.charAt(0).toUpperCase() }}
								    </span>
										{{projectName.value}}
									</div>
									<div class="creation-color">
										<ng-select
											[items]="objectColors"
											[(ngModel)]="pickedSpaceColor"
											[clearable]="false"
											ngModel
											#color='ngModel'
											class="creation-picker"
											appendTo='body'
											bindValue="color"
											name="color">
											<ng-template ng-label-tmp let-item="item">
												<div class="creation-color-preview" [ngStyle]="{'background': item}"></div>
											</ng-template>
											<ng-template ng-option-tmp let-item="item">
												<div class="creation-name creation-name_dropdown">
										    <span [ngStyle]="{'background': item}">
											    {{ projectName.value?.charAt(0).toUpperCase() }}
										    </span>
												</div>
											</ng-template>
										</ng-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="creation-divider"></div>
					<div class="row">
						<span class="col-12 form-control-label">{{ t('modals.project.set-your-avatar') }}</span>
						<div class="col-12 avatar-actions">
							<img
								*ngIf="avatarImagePreview"
								[src]="avatarImagePreview"
								class="avatar-image-preview"
								alt="Space avatar Image"
							/>
							<div *ngIf="!avatarImagePreview" class="selected-emoji mt-1">{{ selectedEmoji }}</div>
							<div class="d-flex justify-content-center align-items-center button-wrapper button-wrapper_project">
								<button
									class="btn btn-solid picker-icon"
									(click)="avatarImagePreview ? deleteAvatarImage() : avatarImageInput.click()">
                  {{ avatarImagePreview ? t('modals.project.btn-remove-photo') : t('modals.project.btn-upload-photo') }}
								</button>
								<div class="d-flex justify-content-center align-items-center ml-1">
									<button
										id="projectEmoji"
										class="btn btn-solid picker-icon emoji-picker-icon"
										(click)="selectedEmoji && selectedEmoji !== '' ? clearProjectEmoji() : projectEmojiPickerToggle()">
										{{ selectedEmoji && selectedEmoji !== '' ? t('modals.project.btn-clear-emoji') : t('modals.project.btn-select-emoji')}}
									</button>
								</div>
								<span class="button-hint">{{ t('common.size-limit-50mb') }}</span>
							</div>
						</div>
					</div>
					<div class="creation-divider"></div>
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<span class="form-control-label">{{ t('modals.project.select-default-avatar') }}</span>
								<div class="creation-avatars">
									<img
										*ngFor="let avatar of defaultAvatars"
										[src]="avatar"
										class="avatar-image-preview"
										alt="Space avatar Image"
										(click)="setDefaultAvatar(avatar)"
										t-id="space-create-default-avatar"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="row mt-3 mb-3">
						<div class="col-12">
							<div class="form-group d-flex">
								<button
									*ngIf="!stepInEditMode"
									type="button"
									uiSref="work"
									class="btn btn-subtle mr-2 btn-modal"
									(click)="previousStep(wizard)"
									t-id="project-create-back-button">
									{{ t('common.btn-back') }}
								</button>
								<button
									*ngIf="!stepInEditMode"
									t-id="project-create-next-step-1-action"
									type="button"
									uiSref="work"
									class="btn btn-solid btn-modal"
									(click)="nextStep(wizard)"
								>
									{{ t('common.btn-next') }}
								</button>
								<button
									*ngIf="stepInEditMode"
									t-id="project-create-next-step-1-action"
									type="button"
									uiSref="work"
									class="btn btn-solid btn-modal w-100"
									(click)="confirmEdit(wizard)"
									[disabled]="!form_step1.valid"
								>
									{{ t('common.btn-confirm') }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</aw-wizard-step>

			<aw-wizard-step class="wizard-step wizard-step_invite" awOptionalStep>
				<div class="creation-pic">
					<img
						src="assets/img/svg/invite-empty-state-{{ config?.layout.variant === 'Light' ? 'light' : 'dark' }}.svg"
						class="image"
						alt="empty-state"
					>
				</div>
				<div class="creation-title">
					{{ t('modals.project.title-invite-teammates') }}
				</div>
				<app-members-invite
					object="projects"
          [platform]="platform"
					[objectCreating]="true"
					[parentSpaceId]="spaceId"
					[usersInEditMode]="stepInEditMode"
					(inviteObjectMembers)="inviteProjectMembers($event, wizard)"
				>
					<ng-container *ngIf="!stepInEditMode" previous-step-button>
						<button type="button" uiSref="work" class="btn btn-subtle mr-2 btn-modal" (click)="previousStep(wizard)" t-id="project-create-back-button-invite">
							{{ t('common.btn-back') }}
						</button>
					</ng-container>
				</app-members-invite>
			</aw-wizard-step>

			<aw-wizard-step class="wizard-step wizard-step_invite">
				<div class="creation-title mb-3">
					{{ t('modals.project.title-check-your-details') }}
				</div>
				<div class="creation-detail">
					{{ t('modals.project.details-name') }}
					<div class="creation-detail_navigation creation-name" (click)="goToStep(0, wizard)">
						{{projectName.value}}
						<i class="ft-chevron-right ml-1"></i>
					</div>
				</div>
				<div class="creation-divider"></div>

				<div class="creation-detail">
					{{ t('modals.project.details-avatar') }}
					<div class="creation-detail_navigation creation-name" (click)="goToStep(1, wizard)">
						<img
							*ngIf="avatarImagePreview"
							[src]="avatarImagePreview"
							class="creation-detail_avatar mr-1"
							alt="Space avatar Image"
						/>
						<span
							*ngIf="!avatarImagePreview && !selectedEmoji.length"
							[ngStyle]="{'background': color.value}">
						    {{ projectName.value?.charAt(0).toUpperCase() }}
				    </span>
						<div *ngIf="selectedEmoji.length && !avatarImagePreview" class="selected-emoji">{{ selectedEmoji }}</div>
						<i class="ft-chevron-right"></i>
					</div>
				</div>
				<div class="creation-divider"></div>

				<div class="creation-detail">
					{{ t('modals.project.details-teammates') }}
					<div class="creation-detail_navigation" (click)="goToStep(2, wizard)">
						<span class="creation-detail_edit">{{ t('common.btn-edit') }}</span>
					</div>
				</div>
				<div class="creation-members">
					<div class="creation-members_user" *ngFor="let member of invitedUsersPreview">
						<div class="creation-members_info">
							<i *ngIf="member.isExternalUser" class="fa fa-envelope-o envelope-icon" aria-hidden="true"></i>
							<app-avatar
								*ngIf="!member.isExternalUser"
								[userId]="member._id"
								[width]="40"
								[height]="40"
								[showStatus]="false">
							</app-avatar>
							<div>
								<div class="creation-members_name">{{ member.userName | limitTo : 20 }}</div>
								<div class="creation-members_email">{{ member.email  | limitTo : 20 }}</div>
							</div>
						</div>
						<div class="creation-members_role">
							{{ member.roleName }}
						</div>
					</div>
				</div>

				<div class="row mt-4 mb-3">
					<div class="col-12">
						<div class="form-group d-flex">
							<button type="button" uiSref="work" class="btn btn-subtle mr-2 btn-modal" (click)="previousStep(wizard)" t-id="project-create-back-button-create">
								{{ t('common.btn-back') }}
							</button>
							<button
								t-id="project-create-button"
								type="button"
								uiSref="work"
								class="btn btn-solid btn-modal"
								[disabled]="isLoading"
								(click)="createProject(form_step1, form_step2)"
							>
								{{ t('common.btn-create') }}
							</button>
						</div>
					</div>
				</div>
			</aw-wizard-step>
		</aw-wizard>
	</section>
</div>

<input
  #avatarImageInput
  type="file"
  style="display: none"
  accept=".jpg, .jpeg, .png"
  (change)="avatarChangeEvent($event)"
/>
