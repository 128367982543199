import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Actions, Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from '../../shared/services/config.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../shared/services/project.service';
import { FormControl, Validators } from '@angular/forms';
import { UsersDbDto } from '../../api/models/users-db-dto';
import { UsersState } from '../../shared/store/states/users.state';
import { LocalStorageService } from 'ngx-localstorage';
import { takeUntil } from 'rxjs/operators';
import { DeleteUserAccount, TransferUserPermission } from '../../shared/store/actions/users.action';

@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss'],
})
export class UserDeleteComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  platform: string;
  users: UsersDbDto[] = [];
  allUsers: UsersDbDto[] = [];
  isUploading: boolean;
  currentUser: UsersDbDto;
  passwordUser = new FormControl('', [Validators.required]);
  userExtend = new FormControl(null, [Validators.required]);
  type: string;
  config: any = {};
  constructor(
    private actions: Actions,
    private store: Store,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    protected toastrService: ToastrService,
    public modalService: NgbModal,
    private readonly localStorageService: LocalStorageService,
    public projectService: ProjectService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    const userId = this.localStorageService.get('userId');
    this.store.select(UsersState.getTenantUsers).subscribe((users) => {
      this.currentUser = users.find((user) => user._id === userId);
      this.users = users.filter((user) => user._id !== userId);
    });

    this.config = this.configService.templateConf;
  }

  get showSelectTransfer() {
    const roles: any = this.currentUser.roles;
    return (
      this.users.length &&
      roles.some(
        (role: any) =>
          role.roleName === 'Owner' || role.roleName === 'Space Leader' || role.roleName === 'Project Leader',
      )
    );
  }

  get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.activeModal.close();
  }

  confirm() {
    this.isUploading = true;
    if (this.showSelectTransfer) {
      if (!this.userExtend.valid) {
        this.isUploading = false;
        return;
      }
      this.store
        .dispatch(
          new TransferUserPermission({
            userId: this.currentUser._id,
            idExtend: this.userExtend.value,
            passwordData: this.passwordUser.value,
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.isUploading = false;
            this.activeModal.close();
          },
          (err) => {
            this.isUploading = false;
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    } else {
      this.store
        .dispatch(new DeleteUserAccount({ userId: this.currentUser._id, passwordData: this.passwordUser.value }))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.isUploading = false;
            this.activeModal.close();
          },
          (err) => {
            this.isUploading = false;
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
  }
}
