import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '../../shared/store/states/auth.state';
import { Store } from '@ngxs/store';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';

@Component({
  selector: 'app-payment-toast',
  templateUrl: './payment-toast.component.html',
  styleUrls: ['./payment-toast.component.scss'],
})
export class PaymentToastComponent implements OnInit {
  public status: string = 'success';
  public title: string = '';
  public description: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private routerTenantPipe: RouterTenantPipe,
  ) {}

  ngOnInit() {
    this.status = this.route.snapshot.paramMap.get('status');
    this.title = this.isSuccess ? 'Payment was completed!' : 'Payment was failed...';
    this.description = this.isSuccess
      ? 'You now have access to all the features of the Basic membership. Your subscription will be renewed on date. You can cancel the subscription at any time in your profile settings.'
      : 'Failed to process payment. Full features of the Basic Subscription are currently unavailable.';
  }

  public get isSuccess(): boolean {
    return this.status === 'success';
  }

  public handleSuccessClick(): void {
    const user = this.store.selectSnapshot(AuthState.getUser);
    this.router.navigate([this.routerTenantPipe.transform('dash', user?.tenantName)]);
  }
  public handleFailedClick(): void {
    const user = this.store.selectSnapshot(AuthState.getUser);
    this.router.navigate([this.routerTenantPipe.transform('dash', user?.tenantName)]);
  }
}
