import { Injectable } from '@angular/core';

import { DocumentsDbDto, FilesDbDto, FoldersDbDto, LinksDbDto } from '../../api/models';
import { SortBy, SortDirection } from '../data/sort-types';
import { TauriService } from './tauri.service';

enum DocumentType {
  Folder = 'Folder',
  File = 'File',
  Link = 'Link',
}

type Document = FoldersDbDto | FilesDbDto | LinksDbDto | DocumentsDbDto;

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private tauriService: TauriService) {}
  documentIs(document: Document): DocumentType {
    if ((document as FilesDbDto).originalFileName !== undefined || (document as DocumentsDbDto).content) {
      return DocumentType.File;
    } else if ((document as LinksDbDto).link !== undefined) {
      return DocumentType.Link;
    } else {
      return DocumentType.Folder;
    }
  }

  sort(documents: Document[], sortBy: string, direction: SortDirection): Document[] {
    const folders = documents
      .filter((document) => this.documentIs(document) === DocumentType.Folder)
      .sort((a, b) => this.compareDocuments(a, b, sortBy, direction));
    const filesAndLinks = documents
      .filter((document) => this.documentIs(document) !== DocumentType.Folder)
      .sort((a, b) => this.compareDocuments(a, b, sortBy, direction));
    return folders.concat(filesAndLinks);
  }

  private compareDocuments(a: Document, b: Document, sortBy: string, direction: SortDirection): number {
    if (sortBy === SortBy.Created || sortBy === SortBy.Updated) {
      return this.compareByDate(a, b, sortBy, direction);
    } else if (sortBy === SortBy.Name) {
      return this.compareByName(a, b, direction);
    }
  }

  private compareByDate(a: Document, b: Document, sortBy: string, direction: SortDirection): number {
    return direction === SortDirection.Ascending
      ? new Date(a[sortBy]).valueOf() - new Date(b[sortBy]).valueOf()
      : new Date(b[sortBy]).valueOf() - new Date(a[sortBy]).valueOf();
  }

  private compareByName(a: Document, b: Document, direction: SortDirection): number {
    return direction === SortDirection.Ascending
      ? this.getDocumentName(a).localeCompare(this.getDocumentName(b))
      : this.getDocumentName(b).localeCompare(this.getDocumentName(a));
  }

  private getDocumentName(document: Document): string {
    return (document as FilesDbDto).originalFileName ?? (document as FoldersDbDto).name;
  }

  getFolderUrl(document, env) {
    const object = document.object === 'spaces' ? 'space' : 'project';
    const host =
      `http${env.ssl || this.tauriService?.isTauri ? 's' : ''}://` +
      (env.main_host || env.base_host !== 'localhost:4200'
        ? `${document.tenantName}.${env.main_host}`
        : `${env.base_host}/${document.tenantName}`);

    return `${host}/${object}/${document.objectId}/documents?folder=${document._id}`;
  }
}
