import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthState } from '../../shared/store/states/auth.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  public departmentItems = [];
  public numberItems = [];
  public platform = this.store.select(AuthState.getPlatform);
  public contactUsForm: FormGroup = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    company: ['', Validators.required],
    department: ['', Validators.required],
    number: ['', Validators.required],
    more: [''],
  });

  // SVG PATH
  public readonly closeSvg = 'assets/icons/common/close.svg';

  constructor(private activeModal: NgbActiveModal, private readonly fb: FormBuilder, private readonly store: Store) {}

  public handleCloseModal(): void {
    this.activeModal.close();
  }

  public handleSubmit(): void {
    // TODO: Integrate with BE
  }
}
