<div class="document__header">
  <h3>{{titleText}}</h3>
  <div class="actions-ticket-minimize svg-icon">
    <ng-container *ngIf="!isLoading$ && !isMobile && isEdit; else loaderPin">
      <svg-icon
        *ngIf="ableToPin"
        class="preview__header-icon preview__header-pinned mr-2"
        [src]="pinIcon"
        [applyClass]="true"
        (click)="actionPinMedia()"
      ></svg-icon>
      <svg-icon
        *ngIf="ableToUnpin"
        class="actions-ticket-minimize svg-icon mr-2"
        [src]="unpinIcon"
        [applyClass]="true"
        (click)="actionPinMedia()"
      ></svg-icon>
    </ng-container>
    <ng-template #loaderPin>
      <i *ngIf="!isMobile && isEdit" class="fa fa-spinner fa-spin connection_spinner mr-3" aria-hidden="true"></i>
    </ng-template>

    <svg-icon
      class="document__header-icon"
      [src]="closeIcon"
      [applyClass]="true"
      (click)="closeModal()"
    ></svg-icon>
  </div>
</div>

<ng-container *transloco="let t; read: 'modals.document-data-room'">
  <div class="document__body">
    <div class="document__body-location">
      <p>{{ t('location') }}</p>
      <div class="document__body-breadcrumbs">
        <app-space-avatar *ngIf="objectInfo?.spaceName" [containerHeight]="24" [containerWidth]="24" [space]="objectInfo?.space"></app-space-avatar>
        <a *ngIf="objectInfo?.spaceId" (click)="$event.stopPropagation(); closeModal()" [routerLink]="LinkSpaceSprint | routerTenant">
          {{ objectInfo?.spaceName }}
        </a>

        <svg-icon
          *ngIf="objectInfo?.projectId"
          class="document__body-icon"
          [src]="arrowRightIcon"
          [applyClass]="true"
        ></svg-icon>

        <app-project-avatar *ngIf="objectInfo?.projectId" [containerHeight]="24" [containerWidth]="24" [project]="objectInfo?.project"></app-project-avatar>
        <a *ngIf="objectInfo?.projectId" (click)="$event.stopPropagation(); closeModal()"  [routerLink]="LinkProjectSprint | routerTenant">
          {{ objectInfo?.projectName }}
        </a>
      </div>
    </div>
    <form [formGroup]="documentForm">
      <input
        t-id="name-document"
        id="nameDocument"
        placeholder="{{ t('placeholder-title') }}"
        formControlName="title"
        tabindex="1"
        type="text"
        required
        class="form-control mb-2"
      >

      <quill-editor
        t-id="document-content"
        class="content-editor"
        bounds="self"
        [class.isMobile]="isMobile"
        [class.ql-editor-mobile]="platform !== 'web'"
        [modules]="editorModules"
        [placeholder]="t('placeholder-type-content')"
        formControlName="content"
        (onEditorCreated)="editorDescriptionCreated($event)"
        (click)="handleClickInEditor($event)"
      ></quill-editor>
    </form>
  </div>

  <div class="document__footer">
    <button t-id="document-cancel-action" type="button" class="btn btn-subtle" (click)="closeModal()">{{ t('btn-cancel') }}</button>

    <button t-id="document-save-action" type="button" class="btn btn-solid" (click)="isEdit ? updateDocument() : formSubmit()" [disabled]="isLoading || isInvalidForm">
      <i *ngIf="isLoading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
      {{ isLoading ? '' : buttonText }}
    </button>
  </div>
</ng-container>

<!-- image full-size preview modal -->
<ng-template #dataRoomImagePreviewModal let-c="close" let-d="dismiss">
  <app-chat-image-preview
    #imagePreviewComponent
    [platform]="platform"
    [object]="object"
    [objectId]="objectId"
    [imagePreviewMessage]="fullImagePreviewService.imagePreview"
    [isLastPreviewImage]="fullImagePreviewService.isLastPreviewImage"
    [isFirstPreviewImage]="fullImagePreviewService.isFirstPreviewImage"
    [updateEvent]="fullImagePreviewService.updateEvent"
    [isOpenNotFromTheChat]="true"
    (closePreviewModal)="previewModal.close()"
    (goToPreviousImage)="fullImagePreviewService.goToPreviousImage()"
    (goToNextImage)="fullImagePreviewService.goToNextImage()"
    (downloadFile)="fullImagePreviewService.downloadFile()"
  ></app-chat-image-preview>
</ng-template>
