import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

import { ACTION_LOGS_TEMPLATES } from '../data/action-logs-templates';
import { ActionOperationIdEnum } from '../enums/action-operation-id.enum';

@Injectable({
  providedIn: 'root',
})
export class ActionLogService {
  parseTemplate(action: any) {
    const data = JSON.parse(action.templateData);
    if (data.start) {
      data.start = moment(data.start).format('dddd MMMM DD, YYYY HH:mm');
    }

    let key = action.object + ':' + action.actionObject + ':' + action.actionOperationId;
    if (action.actionOperationId === ActionOperationIdEnum.CALENDAR_EVENT_MEMBERS_UPDATE) {
      key += ':' + action.actionCrud;
    }

    if (ACTION_LOGS_TEMPLATES[key] !== undefined) {
      return ACTION_LOGS_TEMPLATES[key](data);
    } else {
      return ACTION_LOGS_TEMPLATES['default'](data);
    }
  }

  getAttemptInMinutes(attempt) {
    let minutes = 0;

    if (attempt) {
      const value = attempt.value;
      const period = attempt.period;

      if (period === 'm') {
        minutes = value;
      } else if (period === 'h') {
        minutes = value * 60;
      } else if (period === 'd') {
        minutes = value * 60 * 24;
      } else if (period === 'w') {
        minutes = value * 60 * 24 * 7;
      }
    }

    return minutes;
  }
}
