import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaymentStateModel } from '../models/PaymentState';
import { SubscriptionsService } from '../../../api/services/subscriptions.service';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import {
  BillingCreate,
  BillingDetailsGet,
  BillingDetailsWithCountries,
  BillingUpdate,
  DeleteSubscribe,
  GetCountries,
  PaymentAmount,
  SubscribeSet,
  SubscriptionGet,
  SubscriptionTypesGet,
} from '../actions/payment.action';
import { PaymentsService } from '../../../api/services/payments.service';
import { BillingDetailsService } from '../../../api/services/billing-details.service';
import { LocalStorageService } from 'ngx-localstorage';

@State<PaymentStateModel>({
  name: 'Payment',
  defaults: {
    subscription: { plan: 'free', subscription: null },
    subscriptionTypes: [],
    paymentAmount: null,
    billingDetails: null,
    countries: [],
  },
})
@Injectable()
export class PaymentState {
  @Selector()
  /**
   * get subscription data
   * @param  {PaymentStateModel} state
   */
  static getSubscription(state: PaymentStateModel) {
    return state.subscription;
  }

  @Selector()
  /**
   * get subscription type data
   * @param  {PaymentStateModel} state
   */
  static getSubscriptionType(state: PaymentStateModel) {
    return state.subscriptionTypes;
  }

  @Selector()
  /**
   * get payment amount
   * @param  {PaymentStateModel} state
   */
  static getPaymentAmount(state: PaymentStateModel) {
    return state.paymentAmount;
  }

  @Selector()
  /**
   * get billingDetails
   * @param  {PaymentStateModel} state
   */
  static getBillingDetails(state: PaymentStateModel) {
    return state.billingDetails;
  }

  @Selector()
  /**
   * get countries
   * @param  {PaymentStateModel} state
   */
  static getCountries(state: PaymentStateModel) {
    return state.countries;
  }

  constructor(
    private readonly subscriptionService: SubscriptionsService,
    private readonly paymentService: PaymentsService,
    private readonly billingService: BillingDetailsService,
    private readonly localStorage: LocalStorageService,
  ) {}

  /**
   * Subscription get
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(SubscriptionGet)
  subscription_get({ patchState }: StateContext<PaymentStateModel>) {
    return this.subscriptionService.subscriptionGet().pipe(
      tap((result) => {
        if (result && moment().isBefore(result?.expiredAt)) {
          this.localStorage.set('subscribe', 'basic');
          patchState({ subscription: { plan: 'basic', subscription: result } });
        } else {
          this.localStorage.set('subscribe', 'free');
          patchState({ subscription: { plan: 'free', subscription: null } });
        }
      }),
    );
  }

  /**
   * Subscription types get
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(SubscriptionTypesGet)
  subscription_types_get({ patchState }: StateContext<PaymentStateModel>) {
    return this.subscriptionService.subscriptionGetTypes().pipe(
      tap((result) => {
        patchState({ subscriptionTypes: result });
      }),
    );
  }

  /**
   * Payment amount
   * @param  { patchState }: StateContext<PaymentStateModel>
   * @param payload
   */
  @Action(PaymentAmount)
  payment_amount({ patchState }: StateContext<PaymentStateModel>, { payload }: PaymentAmount) {
    return this.paymentService.paymentsAmount({ type: payload }).pipe(
      tap((result) => {
        patchState({ paymentAmount: result });
      }),
    );
  }

  /**
   * Billing details with countries
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(BillingDetailsWithCountries)
  billing_details_with_countries({ patchState }: StateContext<PaymentStateModel>) {
    return this.billingService.billingDetailsGetWithCountries().pipe(
      tap((result) => {
        patchState({ billingDetails: result.billingDetails, countries: result.countries });
      }),
    );
  }

  /**
   * Subscribe create
   * @param  { patchState }: StateContext<PaymentStateModel>
   * @param payload
   */
  @Action(SubscribeSet)
  subscribe_set({ patchState, dispatch }: StateContext<PaymentStateModel>, { payload }: SubscribeSet) {
    return this.paymentService.paymentsCreate({ body: payload }).pipe(
      tap(() => {
        dispatch(SubscriptionGet);
      }),
    );
  }

  /**
   * Billing create
   * @param  { patchState }: StateContext<PaymentStateModel>
   * @param payload
   */
  @Action(BillingCreate)
  billing_create({ patchState }: StateContext<PaymentStateModel>, { payload }: BillingCreate) {
    return this.billingService.billingDetailsCreate({ body: payload });
  }

  /**
   * Billing create
   * @param  { patchState }: StateContext<PaymentStateModel>
   * @param payload
   */
  @Action(BillingUpdate)
  billing_update({ patchState }: StateContext<PaymentStateModel>, { payload }: BillingUpdate) {
    return this.billingService.billingDetailsUpdate({ id: payload.id, body: payload.body }).pipe(
      tap((result) => {
        patchState({ billingDetails: result });
      }),
    );
  }

  /**
   * Billing get
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(BillingDetailsGet)
  billing_get({ patchState }: StateContext<PaymentStateModel>) {
    return this.billingService.billingDetailsGet().pipe(
      tap((result) => {
        patchState({ billingDetails: result });
      }),
    );
  }

  /**
   * Countries get
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(GetCountries)
  countries_get({ patchState }: StateContext<PaymentStateModel>) {
    return this.billingService.billingDetailsGetCountries().pipe(
      tap((result) => {
        patchState({ countries: result });
      }),
    );
  }

  /**
   * Delete subscribe
   * @param  { patchState }: StateContext<PaymentStateModel>
   */
  @Action(DeleteSubscribe)
  delete_subscribe({ getState, patchState }: StateContext<PaymentStateModel>) {
    const id = getState().subscription.subscription?._id;

    if (!id) {
      return;
    }

    return this.subscriptionService.subscriptionDelete({ id }).pipe(
      tap(() => {
        this.localStorage.set('subscribe', 'free');
        patchState({ subscription: { plan: 'free', subscription: null } });
      }),
    );
  }
}
