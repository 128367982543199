import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { ModalsStateModel } from '../models/ModalsState';

@State<ModalsStateModel>({
  name: 'Modals',
  defaults: {
    currentModalName: '',
  },
})
@Injectable()
export class ModalsState {}
