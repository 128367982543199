<div *ngIf="(!avaUrl && !avaEmoji); else imageAva"
     [style.width.px]="width"
     [style.height.px]="height"
     class="wh-avatar"
>
  {{prefix}}
</div>

<ng-template #imageAva>
  <img
    *ngIf="avaUrl; else emojiAva"
    height="{{ height }}"
    width="{{ width }}"
    src="{{ avaUrl }}"
    alt="Webhook avatar image"
    class="avatar"
  />

  <ng-template #emojiAva>
    <div *ngIf="avaEmoji"
         [style.width.px]="width"
         [style.height.px]="height"
         [style.font-size.px]="emojiFontSize"
         class="webhook-emoji"
    >
      {{ avaEmoji }}
    </div>
  </ng-template>
</ng-template>
