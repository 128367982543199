import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appControlledInput]',
})
export class ControlledInputDirective {
  private specialKeys: Array<string> = [':', 'Backspace'];
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if ((isNaN(event.key) && !this.specialKeys.includes(event.key)) || event.code === 'Space') {
      event.preventDefault();
      event.returnValue = null;
      return;
    }
  }
}
