import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { UsersStateModel } from '../models/UsersState';
import { UsersService } from '../../../api/services/users.service';
import {
  GetUsersListByTenant,
  GetAttachedUsersList,
  UpdateUserAvatar,
  UpdateUser,
  UpdateUserStatus,
  UpdateUsersAfterChange,
  QueueGetBulkUsersInfo,
  GetBulkUsersInfo,
  ChangeUserRole,
  DeleteUserAccount,
  TransferUserPermission,
} from '../actions/users.action';
import { UpdateTheme, UserSetAvatarImageUploadLoading } from '../actions/users.action';
import { SpaceGetUsersList } from '../actions/spaces.action';
import { ProjectGetUsersList } from '../actions/projects.action';
import { LogoutProcess, UpdateAuthUser } from '../actions/auth.action';
import { ChatsGet } from '../actions/chats.action';

@State<UsersStateModel>({
  name: 'Users',
  defaults: {
    tenantUsers: [],
    tenantUsersById: {},
    attachedUsers: [],
    usersStatusesUpdates: {},
    usersInfo: {},
  },
})
@Injectable()
export class UsersState {
  usersInfoQueue = [];
  usersInfoTimer;

  /**
   * get users info
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUsersInfo(state: UsersStateModel) {
    return state.usersInfo;
  }

  /**
   * get users statuses updates received via socket
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUsersStatusesUpdates(state: UsersStateModel) {
    return state.usersStatusesUpdates;
  }

  /**
   * get tenant users list
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getTenantUsers(state: UsersStateModel) {
    return state.tenantUsers;
  }

  /**
   * get tenant users list
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getAttachedUsers(state: UsersStateModel) {
    return state.attachedUsers;
  }

  @Selector()
  static getUser(state: UsersStateModel) {
    return (userId: string) => state.tenantUsersById[userId];
  }

  /**
   * get user avatar image upload loading
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUserAvatarImageUploadLoading(state: UsersStateModel) {
    return (userId: string) => state.tenantUsersById[userId][`avatarImageUploadLoading`];
  }

  constructor(private usersService: UsersService, private store: Store) {}

  /**
   * Get users list by tenant action handler
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: GetUsersListByTenant
   */
  @Action(GetUsersListByTenant)
  get_users_list_by_tenant({ patchState }: StateContext<UsersStateModel>, action: GetUsersListByTenant) {
    return this.usersService.usersGetListByTenantName({ isActive: true }).pipe(
      tap(
        (result) => {
          const tenantUsersById = {};
          for (const user of result) {
            tenantUsersById[user._id as string] = user;
          }
          patchState({
            tenantUsers: result,
            tenantUsersById,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get all attached users
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: GetAttachedUsersList
   */
  @Action(GetAttachedUsersList)
  get_attached_users_list({ patchState }: StateContext<UsersStateModel>, action: GetAttachedUsersList) {
    return this.usersService.usersGetAttachedUserList({}).pipe(
      tap(
        (result) => {
          patchState({
            attachedUsers: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update avatar of a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUserAvatar
   */
  @Action(UpdateUserAvatar)
  updateUserAvatar({ getState, patchState }: StateContext<UsersStateModel>, action: UpdateUserAvatar) {
    const tenantUsersById = this.deepClone(getState().tenantUsersById);
    const tenantUsers = this.deepClone(getState().tenantUsers);
    const usersInfo = this.deepClone(getState().usersInfo);

    tenantUsersById[action.payload.userId].avatarUrl = action.payload.avatarUrl;

    for (const user of tenantUsers) {
      if (user._id === action.payload.userId) {
        user.avatarUrl = action.payload.avatarUrl;
      }
    }

    if (usersInfo[action.payload.userId]) {
      usersInfo[action.payload.userId].avatarUrl = action.payload.avatarUrl;
    }

    return patchState({ tenantUsersById, tenantUsers, usersInfo });
  }

  /**
   * Update avatar image upload loading of a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action
   */
  @Action(UserSetAvatarImageUploadLoading)
  setUserAvatarImageUploadLoading(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: UserSetAvatarImageUploadLoading,
  ) {
    const tenantUsersById = this.deepClone(getState().tenantUsersById);
    tenantUsersById[action.payload.userId].avatarImageUploadLoading = action.payload.status;
    const tenantUsers = this.deepClone(getState().tenantUsers);
    for (const user of tenantUsers) {
      if (user._id === action.payload.userId) {
        user.avatarImageUploadLoading = action.payload.status;
      }
    }
    return patchState({ tenantUsersById, tenantUsers });
  }

  /**
   * Update a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUser
   */
  @Action(UpdateUser)
  updateUser({ getState, patchState }: StateContext<UsersStateModel>, action: UpdateUser) {
    const userDto = this.deepClone(getState().tenantUsersById)[action.payload.userId];
    for (const key of Object.keys(action.payload.body)) {
      userDto[key] = action.payload.body[key];
    }

    return this.usersService.usersUpdate({ body: userDto }).pipe(
      tap((result) => {
        const tenantUsersById = this.deepClone(getState().tenantUsersById);
        tenantUsersById[action.payload.userId].name = result['name'];
        tenantUsersById[action.payload.userId].userName = result['userName'];
        tenantUsersById[action.payload.userId].email = result['email'];
        tenantUsersById[action.payload.userId].timezone = result['timezone'];
        tenantUsersById[action.payload.userId].uiTheme = result['uiTheme'];

        const tenantUsers: any[] = this.deepClone(getState().tenantUsers);
        for (const user of tenantUsers) {
          if (user._id === action.payload.userId) {
            user.name = result['name'];
            user.userName = result['userName'];
            user.email = result['email'];
            user.uiTheme = result['uiTheme'];
          }
        }

        const usersInfo = this.deepClone(getState().usersInfo);
        usersInfo[action.payload.userId].userName = userDto.userName;
        if (userDto.status) {
          usersInfo[action.payload.userId].status = userDto.status;
        }

        patchState({ tenantUsersById, tenantUsers, usersInfo });

        this.store.dispatch(new UpdateAuthUser(userDto));
      }),
    );
  }

  /**
   * Update some user after change
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUsersAfterChange
   */
  @Action(UpdateUsersAfterChange)
  updateUsers({ getState, patchState }: StateContext<UsersStateModel>, action: UpdateUsersAfterChange) {
    const tenantUsersById = this.deepClone(getState().tenantUsersById);
    tenantUsersById[action.payload._id].userName = action.payload.userName;
    tenantUsersById[action.payload._id].name = action.payload.name;
    tenantUsersById[action.payload._id].email = action.payload.email;

    const tenantUsers: any[] = this.deepClone(getState().tenantUsers);
    for (const user of tenantUsers) {
      if (user._id === action.payload._id) {
        user.userName = action.payload.userName;
        user.name = action.payload.name;
        user.email = action.payload.email;
      }
    }

    const userDto = this.deepClone(getState().tenantUsersById)[action.payload._id];
    for (const key of Object.keys(action.payload)) {
      userDto[key] = action.payload[key];
    }
    const usersInfo = this.deepClone(getState().usersInfo);
    usersInfo[action.payload._id].userName = userDto.userName;
    usersInfo[action.payload._id].name = userDto.name;
    usersInfo[action.payload._id].email = userDto.email;

    patchState({ tenantUsersById, tenantUsers, usersInfo });
  }

  /**
   * Update a user theme
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(UpdateTheme)
  updateTheme({ getState }: StateContext<UsersStateModel>, action: UpdateTheme) {
    const user = this.deepClone(getState().tenantUsersById[action.payload.userId]);
    user.uiTheme = action.payload.uiTheme;
    this.store.dispatch(new UpdateUser({ userId: action.payload.userId, body: user }));
  }

  /**
   * Delete user account
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(DeleteUserAccount)
  delete_user({ getState, dispatch }: StateContext<UsersStateModel>, action: DeleteUserAccount) {
    const { userId, passwordData } = action.payload;
    return this.usersService.usersDeleteAccount({ id: userId, body: { password: passwordData } }).pipe(
      tap(
        (result) => {
          dispatch(new LogoutProcess(result));
          dispatch(ChatsGet);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete user account
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(TransferUserPermission)
  transfer_user({ getState, dispatch }: StateContext<UsersStateModel>, action: TransferUserPermission) {
    const { idExtend, passwordData } = action.payload;
    return this.usersService.transferOwnership({ id: idExtend, body: { password: passwordData } }).pipe(
      tap(
        (result) => {
          dispatch(new DeleteUserAccount(action.payload));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update users status user
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action
   */
  @Action(UpdateUserStatus)
  updateUserStatus({ getState, patchState }: StateContext<UsersStateModel>, action: UpdateUserStatus) {
    const { userId, status } = action.payload;
    const { usersStatusesUpdates } = getState();
    patchState({
      usersStatusesUpdates: { ...usersStatusesUpdates, [userId]: status },
    });
  }

  private deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Get bulk users info queue
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: QueueGetBulkUsersInfo
   */
  @Action(QueueGetBulkUsersInfo)
  queueGetBulkUsersInfo({ getState, patchState }: StateContext<UsersStateModel>, action: QueueGetBulkUsersInfo) {
    const userId = action.payload;
    const { usersInfo } = getState();
    if (!!userId && !this.usersInfoQueue.includes(userId) && !usersInfo[userId]) {
      this.usersInfoQueue.push(userId);
      if (this.usersInfoTimer) {
        clearTimeout(this.usersInfoTimer);
      }
      this.usersInfoTimer = setTimeout(() => {
        this.usersInfoTimer = null;
        this.store.dispatch(new GetBulkUsersInfo({}));
      }, 200);
    }
  }

  /**
   * Get bulk users info queue
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: GetBulkUsersInfo
   */
  @Action(GetBulkUsersInfo)
  getBulkUsersInfo({ getState, patchState }: StateContext<UsersStateModel>, action: GetBulkUsersInfo) {
    if (this.usersInfoQueue.length > 0) {
      const { usersInfo } = getState();
      const userIds = this.usersInfoQueue.filter((item) => !!item).join(',');
      if (!!userIds) {
        return this.usersService.usersGetUsersInfo(this.usersInfoQueue.length === 0 ? {} : { userIds }).pipe(
          tap(
            (result) => {
              this.usersInfoQueue = [];
              const resultObj = {};
              for (const item of result) {
                resultObj[item._id as string] = {
                  avatarUrl: item.avatarUrl,
                  status: item.status,
                  userName: item.userName,
                  isAssistant: item.isAssistant,
                };
              }
              patchState({
                usersInfo: { ...usersInfo, ...resultObj },
              });
            },
            (err) => {
              throw err.error;
            },
          ),
        );
      }
    }
  }

  /**
   * Change user role
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: ChangeUserRole
   */
  @Action(ChangeUserRole)
  changeUserRole({ getState, patchState }: StateContext<UsersStateModel>, action: ChangeUserRole) {
    return this.usersService.changeUserRole({ body: action.payload }).pipe(
      tap(
        (result) => {
          if (result.success) {
            if (action.payload.object === 'spaces') {
              this.store.dispatch(new SpaceGetUsersList({ id: action.payload.objectId, exists: true }));
            } else if (action.payload.object === 'projects') {
              this.store.dispatch(new ProjectGetUsersList({ id: action.payload.objectId, exists: true }));
            } else if (action.payload.object === 'tenants') {
              this.store.dispatch(new GetUsersListByTenant());
            }
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
