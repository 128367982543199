import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService, StepEnum } from './payment.service';
import { Store } from '@ngxs/store';
import { SubscriptionTypesGet } from '../../shared/store/actions/payment.action';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnDestroy, OnInit {
  public readonly closeSvg = 'assets/icons/common/close.svg';

  constructor(private activeModal: NgbActiveModal, private paymentService: PaymentService, private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new SubscriptionTypesGet());
  }

  ngOnDestroy() {
    this.paymentService.activeStep = StepEnum.Choice;
    this.paymentService.selectedPlan = null;
  }

  public get isChoiceStep(): boolean {
    return this.paymentService.activeStep === StepEnum.Choice;
  }

  public get isDetailStep(): boolean {
    return this.paymentService.activeStep === StepEnum.DetailForm;
  }

  public get isFrameStep(): boolean {
    return this.paymentService.activeStep === StepEnum.Frame;
  }

  public get isAcceptStep(): boolean {
    return this.paymentService.activeStep === StepEnum.Accept;
  }

  public handleCloseModal(): void {
    this.activeModal.close();
  }
}
