import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GitHubJobStatusData,
  GitHubPullRequestMessageData,
  GitHubPushMessageData,
  WebhookMessageData,
} from '../../../../../interfaces/webhook-message-data';

enum GithubEventTypes {
  PULL_REQUEST_OPENED = 'pull_request:opened',
  PULL_REQUEST_CLOSED = 'pull_request:closed',
  PUSH = 'push',
  WORKFLOW_JOB_IN_PROGRESS = 'workflow_job:in_progress',
  WORKFLOW_JOB_COMPLETED = 'workflow_job:completed',
}

@Component({
  selector: 'app-webhook-github-message',
  templateUrl: './webhook-github-message.component.html',
  styleUrls: ['./webhook-github-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookGithubMessageComponent {
  @Input() webhookMessageData: WebhookMessageData;

  @Input() layoutVariant: string;

  public GithubEventTypes = GithubEventTypes;

  public get pushMessageData(): GitHubPushMessageData {
    return this.webhookMessageData?.data as GitHubPushMessageData;
  }

  public get jobMessageData(): GitHubJobStatusData {
    return this.webhookMessageData?.data as GitHubJobStatusData;
  }

  public get isJobFailed(): boolean {
    return this.jobMessageData?.workflow_job?.conclusion === 'failure';
  }

  private get statusName(): string {
    if (this.webhookMessageData.eventName === this.GithubEventTypes.WORKFLOW_JOB_COMPLETED) {
      return this.jobMessageData.workflow_job.conclusion;
    }
    return this.jobMessageData.workflow_job.status;
  }

  private get workflowName(): string {
    return `Workflow job ${this.jobMessageData?.workflow_job?.run_id}`;
  }

  public get statusLabel(): string {
    switch (this.statusName) {
      case 'in_progress':
        return 'IN PROGRESS';
      case 'failure':
        return 'FAILED';
      case 'success':
        return 'SUCCESS';
      default:
        return this.statusName;
    }
  }

  public get prMessageData(): GitHubPullRequestMessageData {
    return this.webhookMessageData?.data as GitHubPullRequestMessageData;
  }

  public get prActionTitle(): string {
    let action = '';
    if (this.webhookMessageData?.eventName === this.GithubEventTypes.PULL_REQUEST_OPENED) {
      action = 'opened';
    } else if (
      this.webhookMessageData?.eventName === this.GithubEventTypes.PULL_REQUEST_CLOSED &&
      this.prMessageData?.pull_request.merged
    ) {
      action = 'merged';
    } else if (
      this.webhookMessageData?.eventName === this.GithubEventTypes.PULL_REQUEST_CLOSED &&
      !this.prMessageData?.pull_request.merged
    ) {
      action = 'closed';
    } else {
      action = this.prMessageData?.action;
    }
    return `Pull request ${action} by`;
  }

  public get fullBranchPath(): string {
    const mainName = this.pushMessageData?.repository?.full_name;
    const branchName = this.pushMessageData?.ref;
    return branchName?.replace('ref', mainName);
  }

  public get showMRDetails(): boolean {
    return this.webhookMessageData?.eventName === this.GithubEventTypes.PULL_REQUEST_OPENED;
  }

  public get reviewers(): { name: string }[] {
    return this.prMessageData?.pull_request?.requested_reviewers?.map((r) => {
      return { name: r.login };
    });
  }

  public get assignees(): { name: string }[] {
    return this.prMessageData?.pull_request?.assignees?.map((r) => {
      return { name: r.login };
    });
  }

  public get pushMsgDescription(): string {
    if (this.pushMessageData?.head_commit?.id) {
      return this.pushMessageData?.head_commit?.message;
    }
    return `Branch ${this.fullBranchPath} was deleted.`;
  }
}
