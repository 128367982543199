<div class="modal-header" *transloco="let t; read: 'modals.incoming-call'">
	<h5 class="modal-title">{{ t('title-incoming-call') }}</h5>
	<button type="button" class="close" aria-label="Close" (click)="rejectVideoCall()">
    <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
	</button>
</div>

<div class="modal-body" *transloco="let t; read: 'modals.incoming-call'">
	<section class="incoming-call-modal">
    <!-- Modal content starts -->
    <div class="d-flex flex-column align-items-center">
      <div class="participants d-flex align-items-center">
        <ng-container *ngFor="let caller of participants; let i = index">
          <ng-container *ngIf="i < MAX_LINED_UP_PARTICIPANTS">
            <app-avatar class="d-block participant" height="64" width="64" [userId]="caller?._id"></app-avatar>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="participants?.length > MAX_LINED_UP_PARTICIPANTS">
          <h4 class="ml-3 mb-0">+{{ participants.length - MAX_LINED_UP_PARTICIPANTS }}</h4>
        </ng-container>
      </div>

      <h4 class="my-2 text-center">
        <ng-container *ngFor="let caller of participants; let i = index">
          <ng-container *ngIf="i < MAX_LINED_UP_PARTICIPANTS">
            {{ caller?.userName + (participants.length > 1 && i < MAX_LINED_UP_PARTICIPANTS - 1 && i < participants.length - 1 ? ', ' : '') }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="participants?.length > MAX_LINED_UP_PARTICIPANTS">
          <br><small>{{ t('and-more', { value: participants.length - MAX_LINED_UP_PARTICIPANTS }) }}</small>
        </ng-container>
      </h4>

      <div *ngIf="object === 'spaces' || object === 'projects'" class="d-flex flex-column align-items-center">
        <p class="mb-2 text-center">{{ t('called-in') }}</p>
        <div class="d-flex align-items-center">
          <app-space-avatar *ngIf="space" [space]="space" class="space-avatar"></app-space-avatar>
          <app-project-avatar *ngIf="project" [project]="project" class="project-avatar"></app-project-avatar>

          <h5 class="ml-1 mb-0">{{ chat?.chatName }}</h5>
        </div>
      </div>

      <div *ngIf="room.targetObject === 'event'" class="d-flex flex-column align-items-center mt-2">
        <h4>{{ t('title-event') }}</h4>
        <h5>{{ room.targetObjectData.title }}</h5>
      </div>

      <div *ngIf="!object && chat?.chatName && chat?.type !== 'direct'">
        <p class="mb-2 text-center">{{ t('called-in') }}</p>
        <div class="d-flex align-items-center">
          <i class="icon ft-hash"></i>

          <h5 class="ml-1 mb-0">{{ chat?.chatName }}</h5>
        </div>
      </div>

      <div *ngIf="object === 'tenants' || (!chat?.chatName && room.targetObject !== 'event')">
        <h2>{{ t('global-call') }}</h2>
      </div>

      <div *ngIf="objectId && chat?.type !== 'group'" class="custom-control custom-switch d-flex align-content-center cursor-pointer mt-2">
        <input
          type="checkbox"
          id="noRingtoneToggle"
          class="custom-control-input"
          #noRingtoneInput
          [checked]="noRingtone"
          [disabled]="isUpdating"
          (change)="toggleNoRingtone(noRingtoneInput.checked)"
        />
        <label class="custom-control-label" for="noRingtoneToggle">
          <span class="mr-1">{{ t('mute-this-channel') }}</span>
        </label>
      </div>

      <div class="incoming-call-buttons d-flex align-items-center mt-3">
        <button class="btn btn-danger px-2 mr-2" (click)="rejectVideoCall()">
          <span class="d-flex align-items-center">
            <i class="ft-phone-off mr-1"></i>
            <span>{{ t('btn-decline') }}</span>
          </span>
        </button>

        <button class="btn btn-solid px-2" (click)="openVideoCall()">
          <span class="d-flex align-items-center">
            <i class="ft-phone mr-1"></i>
            <span>{{ t('btn-accept') }}</span>
          </span>
        </button>
      </div>
    </div>
    <!-- Modal content ends -->
	</section>
</div>
