import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FoldersDbDto } from '../../../../../api/models/folders-db-dto';
import { FilesDbDto } from '../../../../../api/models/files-db-dto';
import { LinksDbDto } from '../../../../../api/models/links-db-dto';
import { FileTypeEnum } from '../../enums/file-type.enum';
import { ImagePath, PreviewImageGrid } from '../../enums/image-path';
import { FilesHelper } from '../../../../utils/files-helper';
import { DataRoomUtiliesService } from '../../services/data-room-utilies.service';
import { DocumentsState } from '../../../../store/states/documents.state';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PinModalService } from '../../../../services/pin-modal.service';
import { DocumentsDbDto } from '../../../../../api/models/documents-db-dto';
import { PinType } from '../../../chat/chat-pinned-messages/enums/pin-type.enum';
import { FullImagePreviewService } from '../../../../services/full-image-preview.service';
import { DocumentAccessTypes } from '../../../../../api/models/document-access-types';
import { TreeType } from '../../documents.component';
import { TranslocoService } from '@ngneat/transloco';

type Document = FoldersDbDto | FilesDbDto | LinksDbDto | DocumentsDbDto;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-data-room-file-grid',
  templateUrl: './data-room-file-grid.component.html',
  styleUrls: ['./data-room-file-grid.component.scss'],
  providers: [PinModalService],
})
export class DataRoomFileGridComponent implements OnInit {
  @Input() file: any | Document;
  @Input() object: string;
  @Input() objectId: string;
  @Input() platform: string;
  @Input() isMobile = false;
  @Input() currRootFolder?: TreeType;

  @Output() viewFile = new EventEmitter();
  @Output() removeFileData = new EventEmitter();
  @Output() downloadFile = new EventEmitter();

  @ViewChild('videoElement', { static: false }) video: ElementRef;
  public isLoaded = false;
  public isPlayingVideo = false;
  public urlVideo: string;
  private timer: any;
  public isPrivate = false;

  constructor(
    public dataRoomUtilies: DataRoomUtiliesService,
    public pinModalService: PinModalService,
    private filesHelper: FilesHelper,
    private store: Store,
    public fullImagePreviewService: FullImagePreviewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.isPrivate = this.file?.type === DocumentAccessTypes.Private;
    if (!this.isPrivate) {
      this.isPrivate = this.pinModalService.defineIsFileHasPrivateParentFolders();
    }
  }

  public get isImage(): boolean {
    return this.file.fileName && this.filesHelper.getFileType(this.file.fileName) === FileTypeEnum.Image;
  }

  public get isVideo(): boolean {
    return this.file.fileName && this.filesHelper.getFileType(this.file.fileName) === FileTypeEnum.Video;
  }

  public get isInternalDocument(): boolean {
    return !!this.file.content;
  }

  public get fileName(): string {
    return this.file.fileName ? this.file.originalFileName : this.file.name;
  }

  public get showPreviewIcon(): boolean {
    return !(this.isImage && this.isLoaded) && !this.isVideo && !this.urlVideo;
  }

  public getIconFile(isGrid = false): string {
    if (this.file.content) {
      return isGrid ? PreviewImageGrid.File : ImagePath.File;
    } else if (this.file.link) {
      return isGrid ? PreviewImageGrid.Link : ImagePath.Link;
    } else if (this.file.fileName) {
      switch (this.filesHelper.getFileType(this.file.fileName)) {
        case FileTypeEnum.Image:
          return isGrid ? PreviewImageGrid.Image : ImagePath.Image;
        case FileTypeEnum.Video:
          return isGrid ? PreviewImageGrid.Video : ImagePath.Video;
        case FileTypeEnum.Music:
          return isGrid ? PreviewImageGrid.Video : ImagePath.Music;
        case FileTypeEnum.Archive:
          return isGrid ? PreviewImageGrid.Archive : ImagePath.Archive;
        case FileTypeEnum.Presentation:
          return isGrid ? PreviewImageGrid.Presentation : ImagePath.Presentation;
        default:
          return isGrid ? PreviewImageGrid.File : ImagePath.File;
      }
    }
  }

  public get isNotLink(): boolean {
    return !this.file.link && this.file.originalFileName;
  }

  public get isPossiblePin(): boolean {
    return (
      (!this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins) ||
      (this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess)
    );
  }

  public get textForActionPin(): string {
    return !this.pinModalService.pinnedMedia
      ? this.translocoService.translate('data-room.pin-chat')
      : this.translocoService.translate('data-room.unpin-chat');
  }

  public get iconPin(): string {
    return ImagePath.PinFile;
  }

  public get iconUnPin(): string {
    return ImagePath.UnPinFile;
  }

  public onImageLoad(): void {
    this.isLoaded = true;
  }

  public downloadDocument(): void {
    if (this.isMobile) {
      this.downloadFile.emit(this.file);
    } else {
      this.fullImagePreviewService.dataRoomDownloadFiles({
        ...this.file,
        fromDataRoom: this.currRootFolder === TreeType.storage,
      });
    }
  }

  public viewClicked(): void {
    this.viewFile.emit(this.file);
  }

  public deleteClicked(): void {
    this.removeFileData.emit(this.file);
  }

  public startVideo(): void {
    // Start video preview after 1.5 seconds
    this.timer = setTimeout(() => {
      this.video.nativeElement.currentTime = 0;
      this.video.nativeElement.play();
      this.isPlayingVideo = true;

      this.cdr.detectChanges();
    }, 1500);
  }

  public stopVideo(): void {
    clearTimeout(this.timer);
    this.video.nativeElement.pause();
    this.video.nativeElement.currentTime = 0;
    this.isPlayingVideo = false;
  }

  public actionPinMedia(): void {
    let linkDocument: PinType;
    if (this.file.link) {
      linkDocument = PinType.Links;
    } else if (this.isInternalDocument) {
      linkDocument = PinType.Documents;
    } else {
      linkDocument = PinType.Files;
    }

    if (!this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins) {
      this.pinModalService.pinMedia(linkDocument);
    } else if (this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess) {
      this.pinModalService.unPinMedia();
    }
  }

  private initializeData() {
    if (this.file.url && this.isVideo) {
      this.urlVideo = this.file.url;
    } else {
      this.store
        .select(DocumentsState.getDocumentUrls)
        .pipe(untilDestroyed(this))
        .subscribe((documentUrls) => {
          this.urlVideo = this.isVideo && documentUrls?.find((url) => url.fileId === this.file._id)?.url;
          this.cdr.detectChanges();
        });
    }

    this.pinModalService.initializeSubscribe(this.file._id, this.object, this.objectId, this.platform, '', true);
  }
}
