import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, HostListener, ChangeDetectorRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { endOfDay, startOfDay } from 'date-fns';
import * as moment from 'moment-timezone';

import {
  QuillModulesForDescription,
  QuillModulesForChat,
  processDotKeyChange,
} from '../../shared/data/quill-configuration';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { ConfigService } from '../../shared/services/config.service';
import { QuillInitializeService } from '../../shared/services/quill/quill-init.service';
import {
  NoteCreate,
  NoteDelete,
  NotesLabelCreate,
  NotesLabelsGet,
  NoteUpdate,
} from '../../shared/store/actions/notes.action';
import { NotesGet } from '../../shared/store/actions/notes.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { NotesState } from '../../shared/store/states/notes.state';
import { BoardTicketModalComponent } from '../board-ticket/board-ticket.component';
import { CalendarEventModalComponent } from '../calendar-event/calendar-event.component';
import { FullImagePreviewService } from '../../shared/services/full-image-preview.service';
import { MinimizeService } from '../../shared/services/minimize.service';

export enum ConversionActionType {
  Ticket = 'TICKET',
  Event = 'EVENT',
}

@Component({
  selector: 'app-note-modal',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteModalComponent implements OnInit, OnDestroy {
  @ViewChild('dataRoomImagePreviewModal') dataRoomImagePreviewModal;

  MAX_IMAGE_FILE_SIZE: number;
  destroy$: Subject<void> = new Subject<void>();
  platform = 'web';
  platformOS = 'web';
  currTzAbbr = null;
  previewModal: NgbModalRef;
  config: any = {};
  user: any;
  editorModules: any;
  notes: Observable<{}>;
  noteForm: FormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    content: ['', Validators.required],
    labels: [[]],
  });
  convertDropdownOpen = false;
  convertIntoList: { title: string; icon: string; action: ConversionActionType }[] = [
    { title: 'Event', icon: 'calendar', action: ConversionActionType.Event },
    { title: 'Ticket', icon: 'board', action: ConversionActionType.Ticket },
  ];

  addLabelPromise: (label: any) => Promise<any>;
  labels: any[] = [];
  selectedLabels;
  loading = false;

  modalData: {
    action: string;
    object: string;
    objectId: string;
    chatMessageId?: string;
    note: {
      _id: string;
      title: string;
      text: string;
      labels: any[];
      created_at: string;
      linkObject: string;
      linkObjectData: any;
    };
  };

  isNoteCreating = false;
  editorDescription: any;

  private curTabIndex = 0;
  private maxTabIndex = 1;
  handleTabPress = false;
  tabIndexes: { tabIndex?: string; type?: string }[] = [
    { tabIndex: 'tab-index-title', type: 'input' },
    { tabIndex: 'tab-index-description', type: 'quill-editor' },
  ];

  constructor(
    private quillInitializeService: QuillInitializeService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private actions: Actions,
    private formBuilder: FormBuilder,
    private modalsService: NgbModal,
    private minimizeService: MinimizeService,
    private activeModal: NgbActiveModal,
    private store: Store,
    public cdr: ChangeDetectorRef,
    public fullImagePreviewService: FullImagePreviewService,
    private translocoService: TranslocoService,
  ) {
    this.MAX_IMAGE_FILE_SIZE = this.configService.MAX_IMAGE_FILE_SIZE;
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.getPlatform();
    this.buildItemForm({ content: '' });
    this.initModalData();
    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });

    this.store.dispatch(new NotesLabelsGet({}));
    this.store
      .select(NotesState.getNotesLabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.labels = [...res];
      });

    this.addLabelPromise = (label) => {
      return new Promise((resolve) => {
        this.loading = true;

        this.store
          .dispatch(new NotesLabelCreate({ label }))
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res.Notes?.lastCreatedLabel) {
              resolve(res.Notes?.lastCreatedLabel);
              this.loading = false;
            }
          });
      });
    };

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        if (user?.timezone) {
          this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // TODO: add a flag like at CalendarEvent and BoardTicker components
  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    if (this.handleTabPress) {
      if (e.shiftKey && e.keyCode === 9) {
        // tab key shift
        e.preventDefault();
        this.handleTabKey(false);
      } else if (e.keyCode === 9) {
        // tab key
        this.handleTabKey(true);
        e.preventDefault();
      } else if (e.keyCode === 83 && e.ctrlKey && e.altKey) {
        // handle Ctrl+Alt+S
        // save a note
        e.preventDefault();
        this.formSubmit();
      }
    }
  }

  private handleTabKey(forward: boolean): void {
    let control: any;
    if (!forward) {
      this.curTabIndex > 0 ? this.curTabIndex-- : (this.curTabIndex = this.maxTabIndex);
    } else {
      this.curTabIndex < this.maxTabIndex ? this.curTabIndex++ : (this.curTabIndex = 0);
    }

    control = <HTMLInputElement>(
      document.querySelector('[data-tabindex="' + this.tabIndexes[this.curTabIndex].tabIndex + '"]')
    );

    this.setFocusOnControl(control);
  }

  private setFocusOnControl(control: any): void {
    try {
      if (this.tabIndexes[this.curTabIndex].type === 'quill-editor') {
        switch (this.tabIndexes[this.curTabIndex].tabIndex) {
          case 'tab-index-description':
            this.editorDescription.focus();
            break;
        }
      } else {
        control.focus();
      }
    } catch (error) {}
  }

  editorDescriptionCreated(editorDescription): void {
    this.editorDescription = editorDescription;
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  private buildItemForm(item: any) {
    this.noteForm?.reset();

    this.noteForm = this.formBuilder.group({
      title: [item.title || '', Validators.required],
      content: [item.content || '', Validators.required],
      labels: [item.labels || []],
    });
  }

  /**
   * Close modal handler
   */
  close() {
    if (this.noteForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.formSubmit();
          }
          if (result === 'isDenied') {
            this.closeHandler();
          }
        },
        () => {},
      );
    } else {
      this.closeHandler();
    }
  }

  closeHandler(): void {
    this.isNoteCreating = false;
    this.activeModal.close();
    this.handleTabPress = false;
  }

  checkDescriptionImageMaxSize(description: string): boolean {
    return !(description?.length > this.MAX_IMAGE_FILE_SIZE);
  }

  testInput(text) {
    return text?.replace(/<[^>]+>/g, '').trim() === '' && !/<img /.test(text);
  }

  isEmptyFields(newData) {
    return (!newData?.title || newData?.title?.length === 0) && (!newData?.content || this.testInput(newData?.content));
  }

  formSubmit() {
    const newData = { ...this.noteForm.value };
    const imgRex = /<img.*?src="(.*?)".*?>/gim;

    if (this.isEmptyFields(newData)) {
      this.toastr.error(
        this.translocoService.translate('toastr.title-content-not-empty'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!this.checkDescriptionImageMaxSize(newData?.content)) {
      this.toastr.error(
        this.translocoService.translate('toastr.size-limit-50mb'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    this.isNoteCreating = true;
    this.cdr.detectChanges();

    this.store
      .dispatch(
        !this.checkIsUpdate()
          ? new NoteCreate({
              objectId: this.modalData.objectId,
              object: this.modalData.object,
              title: newData?.title,
              text: newData?.content,
              labels: newData?.labels,
              chatMessageId: this.modalData.chatMessageId,
            })
          : new NoteUpdate({
              id: this.modalData.note._id,
              body: {
                title: newData?.title,
                text: newData?.content,
                labels: newData?.labels,
              },
            }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          this.toastr.success(
            this.checkIsUpdate()
              ? this.translocoService.translate('toastr.note-successfully-updated')
              : this.translocoService.translate('toastr.note-successfully-created'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.isNoteCreating = false;

          if (this.checkIsUpdate()) {
            this.dispatchNotesGetRequests();
          }

          this.activeModal.close();
        },
        (err) => {
          this.isNoteCreating = false;
          this.cdr.detectChanges();
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  dispatchNotesGetRequests = () => {
    this.store.dispatch(new NotesGet({ object: this.modalData.object, objectId: this.modalData.objectId }));
  };

  checkIsUpdate() {
    return this.modalData?.action === this.translocoService.translate('notes.edit-note');
  }

  deleteNote(noteId) {
    ConfirmAlert(this.translocoService.translate('alert.note-title'), { platform: this.platform }).then(
      () => {
        this.store
          .dispatch(new NoteDelete({ id: noteId }))
          .pipe(takeUntil(this.destroy$), take(1))
          .subscribe(
            () => {
              this.toastr.success(
                this.translocoService.translate('toastr.note-successfully-deleted'),
                this.translocoService.translate('toastr.title-success'),
              );
              this.dispatchNotesGetRequests();
              this.activeModal.close();
            },
            (err) => {
              this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
            },
          );
      },
      () => {},
    );
  }

  convertDropdownToggle(e) {
    this.convertDropdownOpen = e;
  }

  convertNote(action: ConversionActionType, noteId: string, title: string, text: string): void {
    if (action === ConversionActionType.Ticket) {
      const modalRef = this.modalsService.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        noteId: noteId,
        data: { title: title, description: text },
        ticketCreator: this.user._id,
        showToastMessage: true,
      };

      this.minimizeService.minimizeInit(modalRef);
    }
    if (action === ConversionActionType.Event) {
      const modalRef = this.modalsService.open(CalendarEventModalComponent, {
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.modalData = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        action: 'Add new event',
        displayName: this.translocoService.translate('calendar.add-new-event'),
        noteId: noteId,
        event: {
          title: title,
          description: text,
          repeat: 'never',
          reminder: 'without',
          object: this.modalData.object,
          start: startOfDay(new Date()),
          end: endOfDay(new Date()),
          spaceId: this.modalData.object === 'spaces' ? this.modalData.objectId : undefined,
          projectId: this.modalData.object === 'projects' ? this.modalData.objectId : undefined,
          allDay: false,
          workdays: false,
        },
      };
    }
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);
  }

  initModalData(): void {
    this.handleTabPress = true;
    this.noteForm.controls.title.setValue(this.modalData.note.title);
    this.noteForm.controls.content.setValue(this.modalData.note.text);
    this.noteForm.controls.labels.setValue(this.modalData.note.labels);
    this.selectedLabels = this.modalData.note.labels;
  }

  handleClickInEditor(event: MouseEvent) {
    if (event?.target['src']) {
      this.openImagePreview(event.target['src']);
    }
  }

  openImagePreview(imageUrl: string) {
    this.fullImagePreviewService.prepareImages(this.noteForm.controls.content.value, { user: this.user });
    this.fullImagePreviewService.openImagePreview(imageUrl);

    if (this.fullImagePreviewService.imagePreview) {
      this.previewModal = this.modalsService.open(this.dataRoomImagePreviewModal, {
        backdrop: 'static',
        windowClass: 'cropper-modal',
      });
      this.fullImagePreviewService.updateModal();
    }
  }
}
