import { Pipe, PipeTransform } from '@angular/core';
import { ChatType } from '../components/chat/chat-navigation/enums/chat.enum';
import { FilterType } from '../components/chat/chat-navigation/enums/filter.enum';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'unread',
})
export class UnreadPipe implements PipeTransform {
  transform(chats: [], direct = []) {
    const directChats = direct.length ? this.sortingChat(direct) : [];

    // const otherChats = this.sortingChat(chats);

    const filteredChats = chats.filter(
      (chat: any) => chat.notifyFilter !== FilterType.Nothing && chat.chatItem?.notifyFilter !== FilterType.Nothing,
    );

    const mentionSpaceProject = filteredChats
      .map((chat: any) => {
        if (chat.object === ChatType.Project) {
          return this.checkMention(chat);
        } else if (chat.object === ChatType.Space) {
          return this.checkMention(chat.chatItem) ? chat : null;
        } else {
          return chat;
        }
      })
      .filter((chat) => chat);

    return [...directChats, ...mentionSpaceProject];
  }

  checkMention(chat) {
    if (chat.notifyFilter === FilterType.Mention) {
      return chat.numberOfUnreadMentions + chat.chatThreadsTotalUnreadMentions ? chat : null;
    } else {
      return chat;
    }
  }

  sortingChat(chats) {
    return chats.sort((firstChat, secondChat) => {
      const oneDate =
        firstChat.unreadUpdatedAt > firstChat.chatThreadsUnreadUpdatedAt
          ? firstChat.unreadUpdatedAt
          : firstChat.chatThreadsUnreadUpdatedAt;
      const secondDate =
        secondChat.unreadUpdatedAt > secondChat.chatThreadsUnreadUpdatedAt
          ? secondChat.unreadUpdatedAt
          : secondChat.chatThreadsUnreadUpdatedAt;
      return moment(secondDate).diff(oneDate);
    });
  }
}
