<ng-container *ngIf="isNotImageType">
  <div
    class="file file-pdf-preview"
    [ngClass]="
    {
      'file-upload': file,
      'file-upload-mobile': isMobile && file
    }"
    (click)="handleClickFile()"
    *transloco="let t; read: 'chat'"
  >
    <div class="file__preview" [class.greyBackground]="greyBackground">
      <img
        *ngIf="isImageFile"
        class="file__preview-image"
        [src]="fileRead?.imageBase64String"
        alt="preview"
      />
      <video
        *ngIf="isVideoFile"
        class="file__preview-video"
        [src]="fileRead?.imageBase64String"
        [muted]="'muted'"
        preload="auto"
        loop
      ></video>
      <svg-icon
        *ngIf="iconForUploadFile"
        [src]="iconType"
      ></svg-icon>
    </div>
    <div class="file__info">
      <div class="file__info-naming" [class.deletedName]="!fileData">
        <span class="file__info-naming-main">{{ nameFile }}</span>
        <span class="file__info-naming-extension">.{{ extensionFile }}</span>
      </div>
      <div *ngIf="!!sizeFile && !isUpload && !file?.error" class="file__info-size">{{ filesHelper.formatBytes(sizeFile) }}</div>
      <app-progress-bar *ngIf="isUpload && !file.error" [progress]="file.progress || 0" class="card-body__checklist-progress-bar"></app-progress-bar>
      <div *ngIf="file?.error" class="file__upload-error">{{ t('upload-error') }}</div>
      <div *ngIf="file && (!isUpload || file.error)" (click)="uploadFileService.deleteFile(file)" class="file__info-delete">
        <svg-icon
          [src]="closeIcon"
        ></svg-icon>
      </div>
      <div *ngIf="fileData" class="file__download" (click)="downloadFile($event)">
        <svg-icon
          [src]="downloadIcon"
        ></svg-icon>
      </div>
    </div>
  </div>
</ng-container>

<img *ngIf="!isNotImageType && fileData" class="preview-image" [src]="fileData.croppedImageUrl" alt="image" (click)="handleClickFile()">
