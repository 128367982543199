import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

import { GenericMessageComponent } from '../generic-message/generic-message.component';
import { FilesHelper } from '../../../../utils/files-helper';
import { ConfigService } from '../../../../services/config.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-image-message',
  templateUrl: './image-message.component.html',
  styleUrls: ['./image-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageMessageComponent extends GenericMessageComponent implements AfterViewInit, OnDestroy {
  @Output() imageClicked = new EventEmitter<any>();
  @Output() recheckScroll = new EventEmitter();

  showImageContainer = true;

  constructor(
    public cdr: ChangeDetectorRef,
    public filesHelper: FilesHelper,
    protected configService: ConfigService,
    public store: Store,
  ) {
    super(cdr, filesHelper, configService, store);
  }

  ngAfterViewInit(): void {
    if (this.message?.fileData) {
      this.showImageContainer = true;
    }
  }

  emitImagePreview(message) {
    if (this.context !== 'forward-message') {
      this.imageClicked.emit(message);
    }
  }
}
