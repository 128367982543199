export const filterWithSub = (array: any[], text: string) => {
  return array
    .map((wiki) => {
      const title = !wiki.title && wiki.parentId ? 'New page' : !wiki.title ? 'New doc' : wiki.title;
      const wikiTitle = title.toLowerCase();
      const filteredPages = (wiki.children || []).filter((page) =>
        page.title.toLowerCase().trim().includes(text.toLowerCase().trim()),
      );

      if (wikiTitle.includes(text.toLowerCase().trim()) || filteredPages.length > 0) {
        return { ...wiki, children: filteredPages };
      }

      return null;
    })
    .filter(Boolean);
};
