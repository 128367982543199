import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Models
import { Message } from '../../chat.model';
import { FileTypeEnum } from '../../../documents/enums/file-type.enum';
import { IUpdatedFile } from '../../../../../modals/file-upload-modal/file-upload-modal.component';
import {
  MediaPreviewTypes,
  PreviewMediaComponent,
} from '../../../../../modals/data-room-modals/preview-media/preview-media.component';

// Others
import { UploadFile, UploadFileService } from '../../../../services/upload-file.service';
import { FilesHelper } from '../../../../utils/files-helper';
import { FullImagePreviewService } from '../../../../services/full-image-preview.service';
import { MinimizeService } from '../../../../services/minimize.service';
import { ModalService } from '../../../../services/modal.service';
import { DownloadService } from '../../../../../../../mobile/src/app/shared/services/download.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-message-files',
  templateUrl: './message-files.component.html',
  styleUrls: ['./message-files.component.scss'],
})
export class MessageFilesComponent implements OnInit, OnChanges {
  @Input() message: Message | any;
  @Input() file: UploadFile;
  @Input() platform: string;
  @Input() chatId: string;
  @Input() isMobile = false;
  @Input() object: string;
  @Input() objectId: string;
  @Input() isUpload = false;

  public type = 'file';
  public fileRead: IUpdatedFile;
  public fileData: any;

  public readonly fileIcon = 'assets/icons/data-room/file.svg';
  public readonly closeIcon = 'assets/icons/common/close.svg';
  public readonly downloadIcon = 'assets/icons/common/download.svg';
  public readonly archiveIcon = 'assets/icons/data-room/archive.svg';
  public readonly linkIcon = 'assets/icons/data-room/link.svg';
  public readonly presentIcon = 'assets/icons/data-room/present.svg';
  public readonly videoIcon = 'assets/icons/data-room/video.svg';
  public readonly deleteIcon = 'assets/icons/data-room/trash.svg';

  constructor(
    public filesHelper: FilesHelper,
    public uploadFileService: UploadFileService,
    private fullImagePreviewService: FullImagePreviewService,
    private downloadService: DownloadService,
    private modalsService: NgbModal,
    private ref: ChangeDetectorRef,
    private minimizeService: MinimizeService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.setTypeFile();
    this.uploadFileService.changedFile.pipe(untilDestroyed(this)).subscribe(() => {
      this.ref.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      this.setTypeFile();
    }
  }

  public get iconType() {
    switch (this.type) {
      case FileTypeEnum.Archive:
        return this.archiveIcon;
      case FileTypeEnum.Presentation:
        return this.presentIcon;
      case FileTypeEnum.Link:
        return this.linkIcon;
      case FileTypeEnum.Video:
        return this.videoIcon;
      default:
        return this.fileData || this.file ? this.fileIcon : this.deleteIcon;
    }
  }

  public get isNotImageType() {
    return this.type !== 'image';
  }

  public get isPdfType() {
    return this.type === MediaPreviewTypes.Pdf;
  }

  public get sizeFile(): number {
    return this.fileData?.size || this.file?.size;
  }

  public get nameFile(): string {
    return this.file ? this.nameUploadFile : this.nameMessageFile;
  }

  public get extensionFile(): string {
    return this.file ? this.extensionUploadFile : this.extensionMessageFile;
  }

  public get greyBackground(): boolean {
    return this.type === FileTypeEnum.Archive || !this.fileData;
  }

  public get isImageFile(): boolean {
    return (
      this.file &&
      this.filesHelper.getFileType(this.file.name) === MediaPreviewTypes.Image &&
      this.filesHelper.getFileExtension(this.file.name) !== MediaPreviewTypes.Svg
    );
  }

  public get isVideoFile(): boolean {
    return this.file && this.filesHelper.getFileType(this.file.name) === MediaPreviewTypes.Video;
  }

  public get iconForUploadFile(): boolean {
    return (
      !this.file ||
      (this.file &&
        this.filesHelper.getFileType(this.file.name) !== MediaPreviewTypes.Image &&
        this.filesHelper.getFileType(this.file.name) !== MediaPreviewTypes.Video)
    );
  }

  private get nameUploadFile(): string {
    return this.filesHelper.getFileNameWithoutExtension(this.file.name);
  }

  private get extensionUploadFile(): string {
    return this.filesHelper.getFileExtension(this.file.name);
  }

  private get extensionMessageFile(): string {
    return this.fileData
      ? this.filesHelper.getFileExtension(this.fileData?.originalFileName || this.fileData?.name)
      : '';
  }

  private get nameMessageFile(): string {
    return this.fileData
      ? this.fileData.link
        ? 'Link'
        : this.filesHelper.getFileNameWithoutExtension(this.fileData?.originalFileName || this.fileData?.name)
      : 'File was deleted';
  }

  public downloadFile(event: MouseEvent): void {
    event.stopPropagation();

    if (this.isMobile) {
      this.downloadService.downloadFile(this.fileData);
    } else {
      if (this.message.fileData) {
        // file from the chat
        this.fullImagePreviewService.dataRoomDownloadFiles({ ...this.fileData, fromDataRoom: false });
      } else {
        // file from data room
        this.fullImagePreviewService.dataRoomDownloadFiles(this.fileData);
      }
    }
  }

  public handleClickFile(): void {
    // Preview Pdf modal
    if (this.type === MediaPreviewTypes.Pdf && this.message.fileData) {
      const previewModal = this.modalsService.open(PreviewMediaComponent, {
        size: 'xl',
        windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
        centered: true,
      });

      previewModal.componentInstance.previewData = {
        platform: this.platform,
        object: this.object,
        objectId: this.objectId,
        isMobile: this.isMobile,
        currentMedia: this.fileData,
      };

      previewModal.componentInstance.isNotDataRoom = true;

      this.minimizeService.minimizeInit(previewModal, {
        width: '360px',
        height: '307px',
        right: '40px',
        borderRadius: '10px',
      });
    } else {
      this.modalService.viewLinkedEntity(this.message, {
        platform: this.platform,
        object: this.object,
        objectId: this.objectId,
      });
    }
  }

  private readerFiles(): void {
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.fileRead = Object.assign(this.file, { imageBase64String: reader.result }) as IUpdatedFile;
      this.ref.detectChanges();
    };
  }

  private setTypeFile() {
    this.fileData =
      this.message?.linkFile ||
      this.message?.fileData ||
      this.message?.linkChatFile ||
      this.message?.linkTicketFile ||
      this.message?.linkThreadFile ||
      this.message?.linkInlineFile;

    if (this.fileData) {
      this.type = this.fileData.link ? MediaPreviewTypes.Link : this.filesHelper.getFileType(this.fileData?.fileName);
    } else {
      this.type = 'file';
    }

    if (this.file) {
      this.readerFiles();
    }
  }
}
