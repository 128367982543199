<div class="payment-toast">
  <div class="payment-toast__wrapper">
    <img *ngIf="isSuccess" ngSrc="assets/img/pages/toast-success.png" alt="toast-success" height="128" width="128">
    <img *ngIf=!isSuccess ngSrc="assets/img/pages/toast-failed.png" alt="toast-failed" height="128" width="128">

    <h4>{{title}}</h4>
    <p>{{description}}</p>

    <app-button *ngIf="isSuccess" customClasses="padding-12" label="Back to teamplate" (handleClick)="handleSuccessClick()" />
    <app-button *ngIf="!isSuccess" customClasses="padding-12" label="Try again" (handleClick)="handleFailedClick()" />
  </div>
</div>

