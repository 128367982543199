<div class="position-relative">
  <div
    *ngIf="!project.emoji || !showEmoji"
    class="avatar-container"
    [class.mr-2]="mr"
    [class.avatar-breadcrumb]="isBreadcrumb"
    [ngStyle]="{
      'width.px': containerWidth,
      'height.px': containerHeight
    }"
  >
    <img
      *ngIf="project.avatarUrl || project.projectAvatarUrl"
      src="{{ project.avatarUrl || project.projectAvatarUrl }}"
      class="avatar-project-image"
      alt="Project avatar image"
      [ngStyle]="{
        'width.px': containerWidth,
        'height.px': containerHeight
      }"
    />

    <div
      *ngIf="!project.avatarUrl && !project.projectAvatarUrl"
      class="project-default-avatar"
    >
      {{ (project?.projectName || project?.chatName || project?.spaceName || project?.prefix)?.charAt(0).toUpperCase() }}
    </div>

    <div *ngIf="overlay" class="img-overlay">
      <i class="fa fa-2x fa-edit"></i>
    </div>
  </div>


  <div
    *ngIf="project.emoji && showEmoji"
    class="avatar-container"
    [class.mr-2]="mr"
    [class.avatar-breadcrumb]="isBreadcrumb"
    [ngStyle]="{
      'width.px': containerWidth,
      'height.px': containerHeight
    }"
  >
    <div class="project-emoji-avatar" [class.size-emoji]="isAvatarEmoji">
      {{ project.emoji }}
    </div>
  </div>
</div>

