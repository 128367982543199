<div @container>
  <div class="bottom-sheet-bg-overlay" (click)="close.emit()"></div>

  <div
    @slideUpToggle
    id="bottomSheet"
    [ngClass]="{
      'bottom-sheet-container-dark': options?.theme === 'Dark',
      'bottom-sheet-container-light': options?.theme === 'Light',
      'full-size-bottom-sheet': isFullSize
    }"
  >
    <div>
      <div
        [ngClass]="{
          'bottom-sheet-header-dark': options?.theme === 'Dark',
          'bottom-sheet-header-light': options?.theme === 'Light',
          'with-close-button': options?.title === '-'
        }"
      >
        <div *ngIf="options?.title === '-'" class="bottom-sheet-close-btn" (click)="toggleSize.emit()"><div></div></div>
        <h4
          *ngIf="options?.title !== '-'"
          [ngClass]="{ 'title-dark': options?.theme === 'Dark', 'title-light': options?.theme === 'Light' }"
        >
          {{ options?.title }}
        </h4>
      </div>

      <div class="bottom-sheet-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
