import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-avatar',
  templateUrl: './group-avatar.component.html',
  styleUrls: ['./group-avatar.component.scss'],
})
export class GroupAvatarComponent {
  @Input() isBreadcrumb = false;

  constructor() {}
}
