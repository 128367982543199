import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';

import { environment } from '../../../environments/environment';

@Pipe({
  name: 'routerTenant',
})
export class RouterTenantPipe implements PipeTransform {
  constructor(private store: Store) {}

  /**
   * Add tenant prefix to link if present in current route
   * Links are relative since they can be present in different contexts
   * Ignore in subdomain mode (as all cross domain redirects will be handled outside of angular router)
   * @param  {} value link in string format
   * @param  {string} tenant? tenant [optional], if empty, take from route
   */
  public transform(value, tenant?: string) {
    if (environment.subdomains) {
      return value;
    }

    const snap = this.store.selectSnapshot(RouterState);
    const defaultTenant: string =
      typeof snap.state?.root.firstChild?.paramMap.get === 'function'
        ? snap.state.root.firstChild.paramMap.get('tenant')
        : null;
    const urlTenant: string = tenant || defaultTenant;

    return urlTenant === null ? '/root/' + value : '/' + urlTenant + '/' + value;
  }
}
