import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { TabIndex } from '../../custom-fields.component';
import { FieldType } from '../../../../custom-field/enums/fieldType';

@Component({
  selector: 'app-select',
  styleUrls: ['select.component.scss'],
  templateUrl: 'select.component.html',
})
export class SelectComponent {
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;
  inputFocused: (name: string) => void;
  setFocusedControl: (tabIndex: string) => void;
  tabIndexes: Array<TabIndex>;
  displayName: string;

  constructor(private translocoService: TranslocoService) {}

  get isMultiple() {
    return this.fieldConfig.type === FieldType.MultiSelect;
  }

  get placeholder() {
    return `${this.translocoService.translate('modals.custom-fields.select')} ${this.fieldConfig.title}`;
  }
}
