import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, of, Subject, Subscription, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';

import packageInfo from '../../../../../package.json';
import { environment } from '../../../../environments/environment';
import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { SubscriptionsResDto, TenantsListResDto, VideoCallsGetResDto } from '../../../api/models';
import { UserStatusTypes } from '../../data/user-status-types';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { ConfigService } from '../../services/config.service';
import { CustomizerService } from '../../services/customizer.service';
import { SocketsService } from '../../services/sockets.service';
import { TauriService } from '../../services/tauri.service';
import { RedirectService } from '../../services/redirect.service';
import { AudioService } from '../../services/audio.service';
import { SpaceService } from '../../services/space.service';
import { ProjectService } from '../../services/project.service';
import { VideoCallsService } from '../../../api/services/video-calls.service';
import { RecordService, RecordStartedFrom } from '../../services/record.service';
import {
  PushNotificationsSettingsCreate,
  PushNotificationsSettingsGet,
} from '../../store/actions/notifications.action';
import { AddNewActionLog, AddNewNotification } from '../../store/actions/actions-logs.action';
import { HasUnreadNotificationsUpdate, Logout } from '../../store/actions/auth.action';
import { UpdateTheme, UpdateUser } from '../../store/actions/users.action';
import { ChatsGetMembers, ChatsSetActiveVideoCallRooms } from '../../store/actions/chats.action';
import { DocumentsToggleImageGallery } from '../../store/actions/documents.action';
import { VideoCallsSendAction } from '../../store/actions/video-calls.action';
import { SpacesGet } from '../../store/actions/spaces.action';
import { ProjectsGet } from '../../store/actions/projects.action';
import { AuthState } from '../../store/states/auth.state';
import { ChatsState } from '../../store/states/chats.state';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { VideoCallsState } from '../../store/states/video-calls.state';
import { TenantsSettingsState } from '../../store/states/tenants-settings.state';

import { InviteModalComponent } from '../../../modals/invite-modal/invite-modal.component';
import { AdminUserEditComponent } from '../../../modals/admin-user-edit/admin-user-edit.component';
import { PageDetailsModalComponent } from '../../../modals/page-details/page-details-modal.component';
import { ChatGroupMembersModalComponent } from '../../../modals/chat-group-members/chat-group-members.component';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { VideoCallModalComponent } from '../../../modals/video-call/video-call-modal.component';
import { TenantsState } from '../../store/states/tenants.state';
import { MinimizeService } from '../../services/minimize.service';
import { DraftService } from '../../services/draft.service';
import { ArchiveModalComponent } from '../../../modals/archive-modal/archive-modal.component';
import { ChatGptSettingsModalComponent } from '../../../modals/chat-gpt-settings/chat-gpt-settings.component';
import { GetTenantsSettings } from '../../store/actions/tenant-settings.action';
import { RolesTypeEnum } from '../chat/chat-pinned-messages/enums/roles-type.enum';
import { ZoomService } from '../../services/zoom.service';
import { ActionLogService } from '../../services/action-log.service';
import { ActionOperationIdEnum } from '../../enums/action-operation-id.enum';
import { PaymentService } from '../../../modals/payment-modal/payment.service';
import { EnvironmentService } from '../../services/environment.service';
import { AttributeEnum } from '../../../modals/payment-modal/payment.const';
import { PaymentState } from '../../store/states/payment.state';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild('recordDropdown') recordDropdown: NgbDropdown;
  @ViewChild('workspaceTitleInput') workspaceTitleInput: ElementRef;
  @ViewChild('dataRoomModalTemplate') dataRoomModalTemplate;
  @ViewChild('avatarInput') avatarInput: ElementRef;

  @Input() showSidebar: boolean;

  config: any = {};
  platform = 'web';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  innerWidth: any;
  transparentBGClass = '';
  webAppVer: string = packageInfo.version || '0';
  buildNumber: string = environment.build_number || '';
  actualRoleName = ' ';
  zoom = '100%';

  destroy$: Subject<void> = new Subject<void>();
  router$: Subscription;
  layoutSub: Subscription;
  configSub: Subscription;
  control = new FormControl();
  userStatusTypes = UserStatusTypes;
  user: UsersPublicFieldsResDto;
  zIndex$: Observable<boolean>;
  currentCallRoom: any;
  activeCallRooms: VideoCallsGetResDto[];
  subscription: { plan: string; subscription: SubscriptionsResDto };
  invitedRoomId: string;
  roomId: string;

  dataRoomModal: NgbModalRef;
  recorderFile: File;
  uploadFile: any;
  screenRecordTime: string;
  screenRecordTimeSub: Subscription;
  recordStartedFrom = RecordStartedFrom;

  workSpace$: Subscription;
  prefix: string;
  object: any = null;
  objectId: any = null;
  chatId: any = null;
  eventChatId: any = null;
  space: any;
  project: any;
  chats: any[];
  chat: any;
  chatType: string;
  chatMembers: any[] = [];
  roles: any[];
  actionsDropdownOpen = false;
  workspaceTitle: string;
  notifyFilter: string = null;
  showEditGroupNameInput = false;
  numberOfUnreadActivities = 0;
  notificationsToggled = false;
  laneBoardSpace = null;
  laneBoardProject = null;

  chatGptApiKey = null;
  currentTenant: TenantsListResDto = null;
  isGroupChatOwner = false;

  public readonly paymentIcon = 'assets/icons/payment/stars.svg';

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected configService: ConfigService,
    protected socketsService: SocketsService,
    protected cdr: ChangeDetectorRef,
    protected store: Store,
    protected actions: Actions,
    protected routerTenantPipe: RouterTenantPipe,
    protected videoCallService: VideoCallsService,
    protected customizerService: CustomizerService,
    protected redirectService: RedirectService,
    protected toastrService: ToastrService,
    protected audioService: AudioService,
    protected readonly localStorageService: LocalStorageService,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private zoomService: ZoomService,
    public tauriService: TauriService,
    public recordService: RecordService,
    public spaceService: SpaceService,
    public projectService: ProjectService,
    public modalService: NgbModal,
    public paymentService: PaymentService,
    private translocoService: TranslocoService,
    private actionLogService: ActionLogService,
    private readonly environmentService: EnvironmentService,
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.store
      .select(TenantsSettingsState.getChatGptApiKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatGptApiKey) => (this.chatGptApiKey = chatGptApiKey));

    this.store
      .select(ChatsState.getChats)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chats) => (this.chats = chats));
    this.invitedRoomId = this.store.selectSnapshot(VideoCallsState.getInvitedRoomId);

    this.store
      .select(PaymentState.getSubscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.subscription = value;
      });

    const actualizeRole = () => {
      if (this.roles?.length) {
        const tenantRole = this.roles.find((item) => item.object === 'tenants');
        const urlParams = this.router.url.split('/');

        if (urlParams.includes('dash')) {
          this.actualRoleName = '';
        } else if (tenantRole) {
          this.actualRoleName = tenantRole.roleName;
        } else {
          // default role (no matches => will be overridden)
          this.actualRoleName = this.roles[0].roleName;

          urlParams.shift();
          if (!environment.subdomains) {
            urlParams.shift();
          }

          if (urlParams[0] === 'chat') {
            const chat = this.store.selectSnapshot(ChatsState.getChats).find((item) => item._id === urlParams[1]);
            if (!!chat && chat.type !== 'direct') {
              let chatSpaceId = null,
                chatProjectId = null;
              if (chat.type === 'group') {
                chatSpaceId = chat.objectId;
              } else if (chat.object === 'projects') {
                chatSpaceId = chat.spaceId;
                chatProjectId = chat.objectId;
              } else {
                chatSpaceId = chat.objectId;
              }
              const chatRoleProject = this.roles.find(
                (item) => item.object === 'projects' && item.objectId === chatProjectId,
              );
              if (chatRoleProject) {
                this.actualRoleName = chatRoleProject.roleName;
              } else {
                const chatRoleSpace = this.roles.find(
                  (item) => item.object === 'spaces' && item.objectId === chatSpaceId,
                );
                if (chatRoleSpace) {
                  this.actualRoleName = chatRoleSpace.roleName;
                }
              }
            } else if (!!chat && chat.type === 'direct') {
              this.actualRoleName = '';
            }
          } else if (urlParams[0] === 'space') {
            const spaceRole = this.roles.find(
              (item) => item.object === 'spaces' && (!item.objectId || item.objectId === urlParams[1]),
            );
            if (spaceRole) {
              this.actualRoleName = spaceRole.roleName;
            }
          } else if (urlParams[0] === 'project') {
            const projectRole = this.roles.find(
              (item) => item.object === 'projects' && (!item.objectId || item.objectId === urlParams[1]),
            );
            if (projectRole) {
              this.actualRoleName = projectRole.roleName;
            } else {
              const project = this.store
                .selectSnapshot(ProjectsState.getLoadedProjects)
                .find((item) => item._id === urlParams[1]);
              if (project) {
                this.project = project;
                const spaceRole = this.roles.find(
                  (item) => item.object === 'spaces' && item.objectId === project['spaceId'],
                );
                if (spaceRole) {
                  this.actualRoleName = spaceRole.roleName;
                }
              }
            }
          }
        }
        this.cdr.detectChanges();
      }
    };

    this.getObject(this.router.url);
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getObject(event.url);
      }
    });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        const tenants = this.store.selectSnapshot(AuthState.getTenantList);

        this.currentTenant = tenants.find((tenant) => tenant?.name === user?.tenantName);

        if (this.checkIsOwner) {
          this.store.dispatch(new GetTenantsSettings());
        }

        this.cdr.detectChanges();
      });

    this.store
      .select(AuthState.getRoles)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.roles = res;
        actualizeRole();
      });

    this.store.dispatch(new ChatsSetActiveVideoCallRooms());

    this.store
      .select(ChatsState.getActiveVideoCallRooms)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms) => {
        this.activeCallRooms = rooms;
        this.updateCurrentVideoCallRoom();
      });

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(PushNotificationsSettingsCreate))
      .subscribe(({ payload }) => {
        if (this.object && this.objectId) {
          this.notifyFilter = payload.filter;
          this.cdr.detectChanges();
        }
      });

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe(() => actualizeRole());

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(DocumentsToggleImageGallery))
      .subscribe(({ payload }) => (this.zIndex$ = of(payload)));

    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionDispatched(AddNewNotification),
        filter((v) => v?.payload?.actionOperationId === ActionOperationIdEnum.CALENDAR_EVENTS_REMIND),
      )
      .subscribe(({ payload }) => {
        this.reminderNotification(payload);
      });

    const socket = this.socketsService.get();
    socket.on('notification:send:actionLogsCreate', (log) => {
      this.store.dispatch(new AddNewNotification(log));
      this.store.dispatch(new HasUnreadNotificationsUpdate({ hasUnreadNotifications: true }));
    });

    socket.on('notification:send:activityStreamLogsCreate', (log) => {
      const ignoreGet = [
        'chatsCreatePinnedMessage',
        'filesUpload',
        'wikiPagesDelete',
        'wikiPagesUpdate',
        'wikiPagesCreate',
      ];
      if (!ignoreGet.includes(log.actionOperationId)) {
        if (log.object === 'spaces') {
          this.store.dispatch(new SpacesGet({}));
        } else if (log.object === 'projects') {
          this.store.dispatch(new ProjectsGet({}));
        }
      }

      this.store.dispatch(new AddNewActionLog(log));
    });

    socket.on('users:usersNotificationStatusesSkip:response', (res) => {
      this.store.dispatch(new HasUnreadNotificationsUpdate(res));
    });
  }

  ngAfterViewInit() {
    this.zoom = this.localStorageService.get('zoom') || '100%';
    // TODO: Refactor and improve zooming
    // @ts-ignore
    document.body.style.zoom = this.zoom;

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    });

    if (this.invitedRoomId) {
      this.openVideoCallModal(this.invitedRoomId);
    }
  }

  ngOnDestroy() {
    this.layoutSub?.unsubscribe();
    this.configSub?.unsubscribe();

    const socket = this.socketsService.get();
    socket.removeListener('notification:send:actionLogsCreate');
    socket.removeListener('notification:send:activityStreamLogsCreate');
    socket.removeListener('users:usersNotificationStatusesSkip:response');

    this.workSpace$?.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  getObject(url: string) {
    this.workSpace$?.unsubscribe();
    this.workspaceTitle = null;
    this.chat = null;
    this.chatId = null;
    this.object = null;
    this.objectId = null;
    this.space = null;
    this.project = null;
    this.laneBoardSpace = null;
    this.laneBoardProject = null;

    const path = url.split('?')[0];
    const pathParams = path.split('/');

    if (url.includes('/chat/')) {
      this.chatId = pathParams.pop();
      const chat = this.getChat();
      this.object = chat?.object;
      this.objectId = chat?.objectId;
    } else if (url.includes('/space/') || url.includes('/project/')) {
      const i = pathParams.findIndex((item) => item === 'space' || item === 'project');
      this.object = pathParams[i] + 's';
      this.objectId = pathParams[i + 1];
      this.chatId = this.getChat()?._id;
    } else if (url.includes('/dash')) {
      this.workspaceTitle = this.translocoService.translate('full-layout.workspace-title-dashboard');
    } else if (url.includes('/manage-spaces')) {
      this.workspaceTitle = this.translocoService.translate('full-layout.workspace-title-manage-spaces');
    } else if (url.includes('/lane-board')) {
      const temp = this.router.url.split('/');
      const objectId = temp[temp.length - 1];

      if (objectId) {
        this.laneBoardSpace = this.store.selectSnapshot(SpacesState.getSpace)(objectId);
        if (!this.laneBoardSpace) {
          this.laneBoardProject = this.store.selectSnapshot(ProjectsState.getProject)(objectId);
        }
        this.workspaceTitle = this.laneBoardSpace?.spaceName || this.laneBoardProject?.projectName;
      }
    }

    if (this.chatId) {
      this.getChatInfo();
      this.updateCurrentVideoCallRoom();
    }
  }

  getChat() {
    const chat =
      this.object && this.objectId
        ? this.chats.find((item) => item.object === this.object && item.objectId === this.objectId)
        : this.chats.find((item) => item._id === this.chatId);
    if (chat) {
      if (chat.object === 'projects') {
        this.chat = {
          ...chat,
          title: chat.chatName,
          chatId: chat._id,
          parentObjectId: chat.spaceId,
          numberOfUnreadMentions: chat.numberOfUnreadMentions + chat.chatThreadsTotalUnreadMentions,
          space: null,
        };
      } else {
        this.chat = { ...chat };
      }
    }

    this.isGroupChatOwner = this.chat?.userId === this.user?._id;
    return this.chat;
  }

  get isProduction(): boolean {
    return this.environmentService.isProduction;
  }

  get checkIsPersonal(): boolean {
    return this.object === 'spaces' ? this.space && !this.space.isPersonal : true;
  }

  get chatGptStatusTitle() {
    return this.chatGptApiKey
      ? this.translocoService.translate('full-layout.gpt-status-connected')
      : this.translocoService.translate('full-layout.gpt-status-disconnected');
  }

  get checkIsOwner() {
    return this.user?.roles?.some((role: any) => role.roleName === RolesTypeEnum.Owner);
  }

  get chatGptIconName() {
    return this.chatGptApiKey ? 'chat-gpt-connected' : 'chat-gpt';
  }

  get isFreeSubscription(): boolean {
    return this.subscription.plan === AttributeEnum.Free;
  }

  getChatInfo() {
    this.prefix = this.object === 'spaces' ? 'space' : this.object === 'projects' ? 'project' : this.object;
    this.destroy$ = new Subject<any>();

    if (!this.store.selectSnapshot(ChatsState.getChatMembers)(this.chatId)?.length) {
      this.store.dispatch(new ChatsGetMembers(this.chatId));
    }

    this.store
      .select(ChatsState.getChatMembers)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.chatId)),
      )
      .subscribe((members) => (this.chatMembers = members));

    if (this.object && this.objectId) {
      this.store
        .dispatch(new PushNotificationsSettingsGet({ object: this.object, objectId: this.objectId }))
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.notifyFilter = res.Notifications.filters[this.chatId]?.filter || 'ALL ACTIONS';
          this.cdr.markForCheck();
        });
    } else {
      this.notifyFilter = null;
    }

    if (this.object === 'spaces' || this.chat?.type === 'group') {
      this.workSpace$ = combineLatest([
        this.store.select(ChatsState.getChats),
        this.store.select(SpacesState.getSpace).pipe(map((filterFn) => filterFn(this.objectId))),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([chats, space]) => {
          this.setNavbarParams(chats);

          if (space) {
            const chatId = chats.find((item) => item.objectId === space._id)._id;
            this.space = { ...space, chatId };
            this.numberOfUnreadActivities = this.chat?.type === 'group' ? null : space.numberOfUnreadActivityLogs;
            this.cdr.detectChanges();
          }
        });
    } else if (this.object === 'projects') {
      this.workSpace$ = combineLatest([
        this.store.select(ChatsState.getChats),
        this.store.select(SpacesState.getSpace).pipe(map((filterFn) => filterFn(this.chat.spaceId))),
        this.store.select(ProjectsState.getProject).pipe(map((filterFn) => filterFn(this.objectId))),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([chats, space, project]) => {
          this.setNavbarParams(chats);

          if (space) {
            const chat = chats.find((item) => item.objectId === space._id);
            this.space = { ...space, chatId: chat?._id || '' };
          }

          this.project = project;
          this.chat = { ...this.chat, avatarUrl: project?.avatarUrl, emoji: project?.emoji };
          this.numberOfUnreadActivities = project?.numberOfUnreadActivityLogs;
          this.cdr.detectChanges();
        });
    } else {
      this.numberOfUnreadActivities = null;
      this.workSpace$ = this.store
        .select(ChatsState.getChats)
        .pipe(takeUntil(this.destroy$))
        .subscribe((chats) => this.setNavbarParams(chats));
    }
  }

  setNavbarParams(chats) {
    this.chats = chats;
    const chat = this.getChat();

    if (chat) {
      this.chatType = chat.type;
      this.workspaceTitle = chat.chatName;

      this.cdr.detectChanges();
    }
  }

  actionsDropdownToggle(e, isNotifications = false): void {
    this.actionsDropdownOpen = e;
  }

  /**
   * Invite users button click handler (emit open modal action)
   */
  openInviteMemberModal(object, objectId) {
    const modalRef = this.modalService.open(InviteModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.object = object;
    modalRef.componentInstance.objectId = objectId;
    modalRef.componentInstance.newUsersOnly = false;
  }

  loadLayout() {
    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }
  }

  /**
   * Emit logout action
   */
  logout() {
    const tenants = this.store.selectSnapshot(AuthState.getTenantList);
    const currentTenant = tenants.find(
      (tenant) => tenant.name === this.store.selectSnapshot(TenantsState.getTenantName),
    );

    tenants.forEach((tenant) => {
      if (currentTenant.name !== tenant.name) {
        this.socketsService.get().emit('tenants.unsubscribe.updates', { userId: tenant.userId });
      }
    });

    this.store.dispatch(new Logout({}));
  }

  openUserEdit(activeId = 1) {
    const modalRef = this.modalService.open(AdminUserEditComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance._id = this.user._id;
    modalRef.componentInstance.isEditable = true;
    modalRef.componentInstance.activeId = activeId;
    this.dropdown.close();
  }

  switchDarkMode(darkMode: boolean) {
    if (darkMode) {
      this.customizerService.switchLayout('Dark');
      this.store.dispatch(new UpdateTheme({ uiTheme: 'Dark', userId: this.user._id }));
    } else {
      this.customizerService.switchLayout('Light');
      this.store.dispatch(new UpdateTheme({ uiTheme: 'Light', userId: this.user._id }));
    }
  }

  updateStatus(status: string) {
    this.store
      .dispatch(new UpdateUser({ userId: this.user._id, body: { status } }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => this.cdr.detectChanges(),
        (err) => this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  openVideoCallModal(roomId): void {
    if (!this.store.selectSnapshot(VideoCallsState.getIsOpened)) {
      const user = this.store.selectSnapshot(AuthState.getUser);
      const modalRef = this.modalService.open(VideoCallModalComponent, {
        windowClass: 'cropper-modal video-call-modal',
        backdrop: false,
      });
      modalRef.componentInstance.callData = { roomId, user };
    } else {
      this.toastrService.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }

  screenRecordActions(): void {
    this.recordService.recordStartedFrom.pipe(takeUntil(this.destroy$), take(1)).subscribe((place) => {
      switch (place) {
        case this.recordStartedFrom.Ticket:
          this.stopScreenRec(true);
          break;
        case this.recordStartedFrom.Navbar:
          if (this.recordService.isRecording) {
            this.stopScreenRec();
            this.recordDropdown.toggle();
          } else {
            this.recordDropdown.toggle();
          }
          break;
        case null:
          this.recordDropdown.toggle();
      }
    });
  }

  openDataRoomUploader(): void {
    this.screenRecordToData();
    this.dataRoomModal = this.modalService.open(this.dataRoomModalTemplate, {
      backdrop: 'static',
      windowClass: this.platform !== 'mobile' ? 'dataroom-modal' : '',
    });
  }

  closeDataRoomModal() {
    this.dataRoomModal.close();
  }

  openTicketModal(ticket?): void {
    this.screenRecordToData();

    const payload = {
      ticketCreator: this.user._id,
      chatType: 'direct',
      data: {
        description: '',
        title: '',
        fileData: [this.uploadFile],
      },
      object: null,
      objectId: null,
      selectedMembersIds: [],
      showToastMessage: true,
      ticketCreatedFromRecord: true,
      isNeedToSelectObject: true,
    };
    const ticketData = {
      ...ticket,
      id: ticket?._id,
      data: ticket,
    };

    if (ticket?._id) {
      const draft = this.draftService.isHasActiveDraft(ticket._id);

      if (draft) {
        this.draftService.openTicketOrDraft(draft, ticketData);
      }
    } else {
      const modalRef = this.modalService.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = ticket ? ticketData : payload;

      this.minimizeService.minimizeInit(modalRef);
    }
  }

  screenRecordToData(): void {
    const date = new Date();
    this.recorderFile = new File(
      [this.recordService.recordedFile],
      `${date.getDate()}${date.getDay()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}.mp4`,
      { type: `video/mp4` },
    );
    this.uploadFile = {
      file: this.recorderFile,
      name: this.recorderFile.name,
      originalFileName: this.recorderFile.name,
      screenRecord: true,
    };
  }

  startScreenRec(isRecWithMic: boolean): void {
    const recordStartedFrom = RecordStartedFrom;
    this.recordService.startScreenRecord(isRecWithMic, recordStartedFrom.Navbar);
    this.screenRecordTimeSub = this.recordService.recordDuration.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.screenRecordTime = res;
      this.cdr.markForCheck();
    });
  }

  stopScreenRec(sendToThread?: boolean): void {
    this.recordService.stopRecording().then(() => (sendToThread ? this.recordService.sendFileToThread() : null));
    this.screenRecordTime = null;
    this.screenRecordTimeSub?.unsubscribe();
  }

  showGroupNameInput() {
    this.showEditGroupNameInput = true;
    setTimeout(() => this.workspaceTitleInput.nativeElement.focus(), 0);
  }

  onChangeGroupName(event, chatId) {
    this.showEditGroupNameInput = false;
    if (event.target.value && event.target.value.trim() !== '') {
      this.chat = { ...this.chat, chatName: event.target.value.trim() };
      this.socketsService.get().emit('chats:editGroupName', { chatId, chatName: event.target.value.trim() });
      this.cdr.detectChanges();
    }
  }

  manageGroupChatMembers(spaceId, platform = 'web'): void {
    const modalRef = this.modalService.open(ChatGroupMembersModalComponent, {
      size: 'lg',
      windowClass: `chat-group-members-modal chat-group-members-modal--${platform}`,
    });
    modalRef.componentInstance.chatId = this.chatId;
    modalRef.componentInstance.spaceId = spaceId;
  }

  openVideoCall(currRoomId: string = null): void {
    if (!this.store.selectSnapshot(VideoCallsState.getIsOpened)) {
      const roomId = currRoomId || this.store.selectSnapshot(ChatsState.getVideoCallIdByChatId)(this.chatId);

      if (roomId) {
        const modalRef = this.modalService.open(VideoCallModalComponent, {
          windowClass: 'cropper-modal video-call-modal',
          backdrop: false,
        });
        modalRef.componentInstance.callData = { roomId, user: this.user };
      } else {
        this.toastrService.error(
          this.translocoService.translate('toastr.video-call-not-found'),
          this.translocoService.translate('toastr.title-error'),
        );
      }

      if (this.currentCallRoom) {
        this.rejectVideoCall();
      }
    } else {
      this.toastrService.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }

  rejectVideoCall() {
    this.store.dispatch(new VideoCallsSendAction({ ...this.currentCallRoom }));
  }

  updateCurrentVideoCallRoom(): void {
    if (this.activeCallRooms?.length) {
      const activeRoom = this.activeCallRooms.find((room) => room.targetObjectData['objectId'] === this.objectId);
      this.roomId =
        activeRoom?.targetObject === 'event'
          ? activeRoom.videoCallId
          : this.store.selectSnapshot(ChatsState.getVideoCallIdByChatId)(this.chatId);
      this.eventChatId =
        activeRoom?.targetObject === 'event'
          ? this.store
              .selectSnapshot(ChatsState.getChats)
              .find((chat) => chat.objectId === activeRoom.targetObjectData['objectId'])?._id
          : null;
      this.currentCallRoom = this.activeCallRooms?.find((room) => room.videoCallId === this.roomId);
      if (this.currentCallRoom) {
        this.currentCallRoom = {
          ...this.currentCallRoom,
          callParticipants: [
            ...this.currentCallRoom.participants?.map((userId) => ({ isExternal: false, userId })),
            ...this.currentCallRoom.externalParticipants?.map((userId) => ({ isExternal: true, userId })),
          ],
        };
      }
    } else {
      this.currentCallRoom = null;
    }
    this.cdr.markForCheck();
  }

  handlerArchiveSpaceModal(space) {
    const modalRef = this.modalService.open(ArchiveModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.pageData = {
      space: space,
      type: 'space',
    };

    modalRef.result
      .then((res) => res && this.router.navigate([this.routerTenantPipe.transform('dash', space.tenantName)]))
      .catch(() => {});
  }

  handlerArchiveProjectModal(project) {
    const modalRef = this.modalService.open(ArchiveModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.pageData = {
      project,
      type: 'project',
    };

    modalRef.result
      .then((res) => res && this.router.navigate([this.routerTenantPipe.transform('dash', project.tenantName)]))
      .catch(() => {});
  }

  handlerPageDetailsModal(currentTab: string) {
    const modalRef = this.modalService.open(PageDetailsModalComponent, { size: 'lg' });
    modalRef.componentInstance.pageData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      chatId: this.chatId,
      chat: this.chat,
      membersChat: this.chatMembers,
      space: this.space,
      project: this.project,
      currentTab,
    };
  }

  setZoom(type = null) {
    const page = document.body;
    // @ts-ignore
    const scale = parseInt(page.style.zoom, 10) || 100;

    if (type === 'in') {
      // @ts-ignore
      page.style.zoom = scale + 5 + '%';
    } else if (type === 'out') {
      // @ts-ignore
      page.style.zoom = scale - 5 + '%';
    } else {
      // @ts-ignore
      page.style.zoom = '100%';
    }

    // @ts-ignore
    this.zoom = page.style.zoom;
    this.zoomService.emitZoom();

    this.localStorageService.set('zoom', this.zoom);
  }

  openChatGptSettings() {
    const modalRef = this.modalService.open(ChatGptSettingsModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.apiKey.setValue(this.chatGptApiKey);
    modalRef.componentInstance.chatId = this.chatId;
  }

  public handleOpenPaymentModal(): void {
    this.paymentService.openPaymentModal();
  }

  reminderNotification(action: any) {
    if (action.actionOperationId !== ActionOperationIdEnum.CALENDAR_EVENTS_REMIND) {
      return;
    }

    const reminderContent = action.templateData ? this.actionLogService.parseTemplate(action) : '';
    if (reminderContent !== '') {
      this.toastrService.info(reminderContent, this.translocoService.translate('toastr.title-reminder'), {
        enableHtml: true,
      });
    }
  }
}
