import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFieldModalComponent } from '../../../modals/custom-field/custom-field.component';
import { DeleteField, FieldGetList, UpdateFields } from '../../store/actions/board-field.action';
import { Store } from '@ngxs/store';
import { typeDescription } from '../../../modals/custom-field/const/typeDescription';
import { anotherTypesDescription } from './const/anotherTypesDescription';
import { ConfigService, ITemplateConfig } from '../../services/config.service';
import { TicketsFieldsDbDto } from '../../../api/models/tickets-fields-db-dto';
import { BoardsFieldState } from '../../store/states/boards-field.state';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmAlert } from '../../alerts/alerts';
import { FieldType } from '../../../modals/custom-field/enums/fieldType';
import { ToastrService } from 'ngx-toastr';
import { separateFormatPicker } from './const/separeteDate';
import { DefaultFields } from '../../../modals/board-ticket/fields.types';

@Component({
  selector: 'app-customize-ticket-fields',
  templateUrl: 'customize-ticket-fields.component.html',
  styleUrls: ['customize-ticket-fields.component.scss'],
})
export class CustomizeTicketFieldsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @Input() object: string;
  @Input() objectId: string;
  @Input() platform: string;
  @Output() close = new EventEmitter<string>();
  displayedColumns = ['name', 'mandatory', 'onCard', 'type', 'format', 'visible', 'actions'];
  dataSource: Array<TicketsFieldsDbDto> = [];
  fieldsCompare: Array<TicketsFieldsDbDto> = [];
  fieldsToUpdate: Array<TicketsFieldsDbDto> = [];
  isLoading = new BehaviorSubject(false);
  types = [...typeDescription, ...anotherTypesDescription];
  config: ITemplateConfig;
  isFieldsLoaded = new BehaviorSubject(false);
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modalsService: NgbModal,
    private configService: ConfigService,
    private store: Store,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  get countCustomFields() {
    return this.dataSource.filter((field) => !field?.isLocked).length;
  }

  ngOnInit(): void {
    this.store
      .dispatch(
        new FieldGetList({
          object: this.object,
          objectId: this.objectId,
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFieldsLoaded.next(true);
      });
    this.store
      .select(BoardsFieldState.getFieldList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fields) => {
        if (fields?.fields) {
          if (this.dataSource.length && this.fieldsToUpdate.length) {
            this.dataSource = this.dataSource.map((field) => {
              const fieldInNew = fields.fields.find((newField) => field._id === newField._id);
              return fieldInNew ? { ...fieldInNew, isVisible: field.isVisible } : field;
            });
            this.fieldsToUpdate = [...this.dataSource];
          } else {
            this.dataSource = fields.fields;
          }

          this.dataSource = this.dataSource.map((field) => ({
            ...field,
            displayName: field.isLocked ? this.translationDefaultField(field.title) : field.title,
          }));

          this.fieldsCompare = fields.fields;
          this.cdr.detectChanges();
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.scrollbarRef) {
      this.scrollbarRef.scrolled
        .pipe(
          map((e: any) => e.target.scrollLeft > 4),
          tap(
            (isScrollStarted) =>
              isScrollStarted &&
              document.querySelectorAll('.name').forEach((field) => {
                field.classList.add('scrolled');
              }),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  translationDefaultField(title: string): string {
    switch (title) {
      case DefaultFields.Assignees:
        return this.translocoService.translate('modals.board-ticket.default.assignees');
      case DefaultFields.DueDate:
        return this.translocoService.translate('modals.board-ticket.default.due-date');
      case DefaultFields.Labels:
        return this.translocoService.translate('modals.board-ticket.default.labels');
      case DefaultFields.Epics:
        return this.translocoService.translate('modals.board-ticket.default.epics');
      case DefaultFields.Estimation:
        return this.translocoService.translate('modals.board-ticket.default.estimation');
      case DefaultFields.Release:
        return this.translocoService.translate('modals.board-ticket.default.release-version');
      case DefaultFields.Parent:
        return this.translocoService.translate('modals.board-ticket.default.parent');
      default:
        return title;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.isLoading.unsubscribe();
  }

  typeTitle(item): string {
    return this.types.find((type) => type.type === item)?.title || '';
  }

  onScrollToStart() {
    document.querySelectorAll('.name').forEach((field) => field.classList.remove('scrolled'));
  }

  drop(event: CdkDragDrop<Array<TicketsFieldsDbDto>>) {
    if (event.previousIndex !== event.currentIndex && event.currentIndex > 6) {
      const arrayForSort = [...this.dataSource];

      moveItemInArray(arrayForSort, event.previousIndex, event.currentIndex);
      this.dataSource = [...arrayForSort];
      this.fieldsToUpdate =
        JSON.stringify(arrayForSort) !== JSON.stringify(this.fieldsCompare) ? [...arrayForSort] : [];
    }
  }

  onChangeFieldVisible(fieldToUpdate: TicketsFieldsDbDto) {
    this.fieldsToUpdate = (this.fieldsToUpdate.length ? this.fieldsToUpdate : this.dataSource).map((field) =>
      field._id === fieldToUpdate._id ? { ...field, isVisible: !field.isVisible } : field,
    );

    this.dataSource = [...this.fieldsToUpdate];

    if (JSON.stringify(this.fieldsToUpdate) === JSON.stringify(this.fieldsCompare)) {
      this.fieldsToUpdate = [];
    }
  }

  openFieldModal(field?: TicketsFieldsDbDto) {
    const modalRef = this.modalsService.open(CustomFieldModalComponent, {
      size: 'md',
      centered: true,
      windowClass: 'custom-fields',
    });

    if (field) {
      let format = field.format;
      modalRef.componentInstance.fieldId = field._id;

      if (field.type === FieldType.DateTimePicker) {
        const formatDataTimePicker = separateFormatPicker(field.format);

        format = formatDataTimePicker[0];
        modalRef.componentInstance.timeOption.setValue(formatDataTimePicker[1]);
      }

      if (field.formatMultiple) {
        modalRef.componentInstance.options = field.formatMultiple;
      }

      modalRef.componentInstance.fieldForm.setValue({
        title: field.title,
        description: field.description,
        type: field.type,
        format: format,
        formatMultiple: field.formatMultiple,
        isMandatory: field.isMandatory,
        isOnCard: field.isOnCard,
      });
      modalRef.componentInstance.formatValue = field.type;
    }

    modalRef.componentInstance.customData = {
      object: this.object,
      objectId: this.objectId,
    };
  }

  saveFieldsVisible() {
    const orderingArraySort = this.fieldsToUpdate.map((field, index) => ({ ...field, order: index }));

    this.isLoading.next(true);

    this.store
      .dispatch(
        new UpdateFields({
          object: this.object,
          objectId: this.objectId,
          fields: orderingArraySort,
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isLoading.next(false);
          this.toastr.success(
            this.translocoService.translate('toastr.fields-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.close.emit('saved');
          this.fieldsToUpdate = [];
        },
        () => {
          this.isLoading.next(false);
        },
      );
  }

  deleteFieldModal(id: string) {
    ConfirmAlert(null, {
      subject: this.translocoService.translate('modals.page-details.are-you-sure'),
      text: this.translocoService.translate('modals.page-details.are-you-sure-desc'),
      confirmButtonText: this.translocoService.translate('common.btn-remove'),
      confirmButtonClass: 'btn-solid',
      platform: this.platform,
    }).then(
      () => {
        this.store.dispatch(new DeleteField({ id })).pipe(takeUntil(this.destroy$), take(1));
      },
      () => {},
    );
  }
}
