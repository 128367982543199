import { APP_INITIALIZER, ErrorHandler, forwardRef, NgModule, Provider } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule } from 'ngx-socket-io';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LinkyModule } from 'ngx-linky';
import { QuillModule } from 'ngx-quill';
import { MentionModule } from 'angular-mentions';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoDirective } from '@ngneat/transloco';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import packageInfo from '../../package.json';
import { ApiModule } from './api/api.module';

import { environment } from '../environments/environment';
import { QuillConfig } from './shared/data/quill-configuration';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';

import { NgxsStoreModule } from './shared/store/store.module';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';

import { AuthGuard } from './shared/guards/auth-guard.service';
import { NonAuthGuard } from './shared/guards/non-auth-guard.service';
import { RedirectService } from './shared/services/redirect.service';
import { RouterQueryService } from './shared/services/router-query.service';
import { SocketsService } from './shared/services/sockets.service';
import { FilesHelper } from './shared/utils/files-helper';

import { SpaceCreateModalComponent } from './modals/space-create/space-create.component';
import { SpaceUpdateModalComponent } from './modals/space-update/space-update.component';
import { ProjectCreateModalComponent } from './modals/project-create/project-create.component';
import { ProjectUpdateModalComponent } from './modals/project-update/project-update.component';
import { InviteModalComponent } from './modals/invite-modal/invite-modal.component';
import { AdminUserEditComponent } from './modals/admin-user-edit/admin-user-edit.component';
import { BoardTicketModalComponent } from './modals/board-ticket/board-ticket.component';
import { CalendarEventModalComponent } from './modals/calendar-event/calendar-event.component';
import { NoteModalComponent } from './modals/note/note.component';
import { ChatGroupCreateModalComponent } from './modals/chat-group-create/chat-group-create.component';
import { VideoCallModalComponent } from './modals/video-call/video-call-modal.component';
import { ScreenPickerModalComponent } from './modals/screen-picker/screen-picker-modal.component';
import { NoInternetPageComponent } from './pages/no-internet-page/no-internet-page.component';
import { IncomingCallModalComponent } from './modals/incoming-call/incoming-call-modal.component';
import { PageDetailsModalComponent } from './modals/page-details/page-details-modal.component';
import { TenantMembersComponent } from './modals/tenant-members/tenant-members.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { TenantUpdateComponent } from './modals/tenant-update/tenant-update.component';
import { SprintCreateEditComponent } from './modals/sprint-create-edit/sprint-create-edit.component';
import { DragNDropService } from './shared/services/drag-n-drop.service';
import { SprintCompleteComponent } from './modals/sprint-complete/sprint-complete.component';
import { SprintDeleteComponent } from './modals/sprint-delete/sprint-delete.component';
import { ArchiveModalComponent } from './modals/archive-modal/archive-modal.component';
import { SpaceDeleteComponent } from './modals/space-delete/space-delete.component';
import { SpaceDeleteInfoComponent } from './modals/space-delete-info/space-delete-info.component';
import { UserDeleteComponent } from './modals/user-delete/user-delete.component';
import { CustomFieldModalComponent } from './modals/custom-field/custom-field.component';
import { CustomFieldsComponent } from './modals/board-ticket/custom-fields/custom-fields.component';
import { TextInputComponent } from './modals/board-ticket/custom-fields/components/TextInput/text-input.component';
import { SelectLabelsTagsComponent } from './modals/board-ticket/custom-fields/components/SelectLabelsTags/select-labels-tags.component';
import { DateTimeComponent } from './modals/board-ticket/custom-fields/components/DateTime/date-time.component';
import { CustomFieldService } from './modals/board-ticket/custom-fields/services/custom-field.service';
import { InputSelectComponent } from './modals/board-ticket/custom-fields/components/InputSelect/input-select.component';
import { SelectLinkRelationshipComponent } from './modals/board-ticket/custom-fields/components/SelectLinkRelationship/select-link-relationship.component';
import { SelectComponent } from './modals/board-ticket/custom-fields/components/Select/select.component';
import { EpicTicketComponent } from './modals/epic-ticket/epic-ticket.component';
import { PollModalComponent } from './modals/poll-modal/poll-modal.component';
import { PollResultComponent } from './modals/poll-result/poll-result.component';
import { PinCreateComponent } from './modals/pin-create/pin-create.component';
import { TicketService } from './shared/services/ticket.service';
import { PinnedMobileComponent } from './modals/pinned-mobile/pinned-mobile.component';
import { PinnedMobileMessageComponent } from './modals/pinned-mobile/components/pinned-mobile-message/pinned-mobile-message.component';
import { PinnedMobileLinkComponent } from './modals/pinned-mobile/components/pinned-mobile-link/pinned-mobile-link.component';
import { PreviewMediaComponent } from './modals/data-room-modals/preview-media/preview-media.component';
import { DocumentDataRoomComponent } from './modals/data-room-modals/document-data-room/document-data-room.component';
import { PinnedEmptyStateComponent } from './modals/pinned-mobile/components/pinned-empty-state/pinned-empty-state.component';
import { ChatGptSettingsModalComponent } from './modals/chat-gpt-settings/chat-gpt-settings.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { ArchwizardModule } from '@achimha/angular-archwizard';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UrlChangeModalComponent } from './modals/url-change-modal/url-change-modal.component';
import { WikiRenameComponent } from './modals/wiki-rename/wiki-rename.component';
import { WikiMobileModalComponent } from './modals/wiki-mobile-modal/wiki-mobile-modal.component';
import { WikiMobileHeaderComponent } from '../../mobile/src/app/shared/components/wiki-mobile/wiki-mobile-header/wiki-mobile-header.component';
import { TranslocoRootModule } from './transloco-root.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { UserPlanComponent } from './modals/admin-user-edit/components/user-plan/user-plan.component';
import { PaymentModalComponent } from './modals/payment-modal/payment-modal.component';
import { PaymentChoiceStepComponent } from './modals/payment-modal/components/payment-choice-step/payment-choice-step.component';
import { PaymentCardComponent } from './modals/payment-modal/components/payment-card/payment-card.component';
import { PaymentService } from './modals/payment-modal/payment.service';
import { PaymentDetailStepComponent } from './modals/payment-modal/components/payment-detail-step/payment-detail-step.component';
import { PaymentAcceptStepComponent } from './modals/payment-modal/components/payment-accept-step/payment-accept-step.component';
import { PaymentFeatureCardComponent } from './modals/payment-modal/components/payment-feature-card/payment-feature-card.component';
import { PaymentToastComponent } from './pages/payment-toast/payment-toast.component';
import { PaymentFrameComponent } from './modals/payment-modal/components/payment-frame/payment-frame.component';
import { ContactUsComponent } from './modals/contact-us/contact-us.component';
import { BillingEditComponent } from './modals/billing-edit/billing-edit.component';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   wheelPropagation: false,
// };

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

Sentry.init(
  {
    dsn: 'https://1e91c3428c014f50ae3b5be6dcd29fa5@o548694.ingest.sentry.io/5671606',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://api.teamplate.io'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    tracesSampleRate: 1.0,
    release: packageInfo.version,
    environment: environment.sentryEnv,
    // Filter all errors with status 400+
    beforeSend: (event, hint) => {
      const error: any = hint.originalException;
      let errorStatus = null;

      if (typeof error === 'string') {
        errorStatus = parseInt(error.split(' ')[0], 10);
      }

      if (error && errorStatus && errorStatus >= 400 && errorStatus < 500) {
        return null;
      }

      return event;
    },
  }
);

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ArchwizardModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PinchZoomModule,
    NgbModule,
    // PerfectScrollbarModule,
    NgxsStoreModule,
    ToastrModule.forRoot(),
    ApiModule.forRoot({ rootUrl: environment.api_root }),
    SocketIoModule.forRoot({
      url: environment.socket_url,
      options: { autoConnect: false, reconnection: true, reconnectionDelay: 5000 },
    }),
    PickerModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    QuillModule.forRoot(QuillConfig),
    LinkyModule,
    // HTMLEscapeUnescapeModule,
    MentionModule,
    NgScrollbarModule,
    DragDropModule,
    PdfViewerModule,
    AngularSvgIconModule.forRoot(),
    TranslocoRootModule,
    TranslocoDirective,
    NgOptimizedImage,
  ],
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    Error404PageComponent,
    PaymentToastComponent,
    SpaceCreateModalComponent,
    SpaceUpdateModalComponent,
    ProjectCreateModalComponent,
    ProjectUpdateModalComponent,
    InviteModalComponent,
    AdminUserEditComponent,
    UserPlanComponent,
    BoardTicketModalComponent,
    ChatGptSettingsModalComponent,
    CalendarEventModalComponent,
    NoteModalComponent,
    ChatGroupCreateModalComponent,
    VideoCallModalComponent,
    NoInternetPageComponent,
    ScreenPickerModalComponent,
    IncomingCallModalComponent,
    PageDetailsModalComponent,

    // PAYMENT
    PaymentModalComponent,
    PaymentChoiceStepComponent,
    PaymentDetailStepComponent,
    PaymentFeatureCardComponent,
    PaymentFrameComponent,
    PaymentAcceptStepComponent,
    PaymentCardComponent,
    ContactUsComponent,
    BillingEditComponent,

    CustomFieldModalComponent,
    PollModalComponent,

    TextInputComponent,
    DateTimeComponent,
    InputSelectComponent,
    SelectLinkRelationshipComponent,
    SelectLabelsTagsComponent,
    SelectComponent,

    TenantMembersComponent,
    TenantUpdateComponent,
    SprintCreateEditComponent,
    SprintCompleteComponent,
    SprintDeleteComponent,
    ArchiveModalComponent,
    SpaceDeleteComponent,
    SpaceDeleteInfoComponent,
    UserDeleteComponent,
    CustomFieldsComponent,
    EpicTicketComponent,
    PollResultComponent,
    PinCreateComponent,
    PinnedMobileComponent,
    PinnedMobileMessageComponent,
    PinnedMobileLinkComponent,
    PreviewMediaComponent,
    DocumentDataRoomComponent,
    PinnedEmptyStateComponent,
    UrlChangeModalComponent,
    WikiRenameComponent,
    WikiMobileModalComponent,
    WikiMobileHeaderComponent,
  ],
  providers: [
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    AuthGuard,
    NonAuthGuard,
    NgbActiveModal,
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor(error: any, defaultExtractor: (errorNew: unknown) => unknown): any {
          let extractedError = defaultExtractor(error);
          if (!extractedError && error.statusCode) {
            // Changing request error message from sentry default 'Handle unknown error'
            extractedError = `${error.statusCode} ${error.message}`;
          }
          return extractedError;
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    RedirectService,
    RouterQueryService,
    SocketsService,
    FilesHelper,
    DragNDropService,
    CustomFieldService,
    TicketService,
    PaymentService,
    provideNgxMask(),
  ],
})
export class AppModule {}
