import { Injectable } from '@angular/core';
import { Action, Selector, State, Store, StateContext } from '@ngxs/store';
import { compose, patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { BoardsStateModel } from '../models/BoardsState';
import { TicketsDbDto } from '../../../api/models/tickets-db-dto';
import { EstimationSessionsResDto } from '../../../api/models/estimation-sessions-res-dto';
import { BoardsService } from '../../../api/services/boards.service';
import {
  ColumnsCreate,
  ColumnsUpdate,
  ColumnsDelete,
  ColumnsGetList,
  ColumnsSetLastAddedId,
  TicketsCreate,
  TicketsGetList,
  TicketsGetShortList,
  TicketsGetUserList,
  TicketsGetFilesList,
  TicketsGetById,
  TicketsUpdate,
  TicketsUpdateColumn,
  TicketsUpdateOrdersInColumns,
  TicketsDelete,
  TicketsSetLastAddedId,
  TicketsMembersCreate,
  TicketsMembersUpdate,
  TicketsMembersGetList,
  TicketsFilesUpload,
  TicketsFilesDelete,
  TicketsLabelCreate,
  TicketsLabelsGet,
  TicketsSocketUpdate,
  TicketsMoveAllToArchive,
  TicketsMoveToArchive,
  TicketsOpenTicketChange,
  BoardsGetChecked,
  TicketsSocketUpdateUserBoard,
  TicketsCreateEstimationSession,
  TicketsDeleteEstimationSession,
  TicketsGetEstimationSession,
  TicketsUpdateEstimationSession,
  TicketsJoinMemberToEstimationSession,
  TicketsUpdateMemberEstimationSession,
  TicketsSocketUpdateEstimationSession,
  TicketsUpdateLocal,
  TicketsMove,
  TicketsGetChecklistItems,
  TicketsAddChecklistItems,
  TicketsDeleteChecklistItem,
  TicketsUpdateChecklistItem,
  TicketsDeleteChecklist,
  TicketsOrderUpdate,
  TicketUpdateChecklist,
  TicketClearLocalList,
  BoardsGetSettings,
  BoardsSetSettings,
  BoardsApplyTicketSettings,
  ColumnsSocketUpdate,
  ColumnsSocketMove,
  TicketsSocketMove,
  TicketsUpdateOrders,
  ColumnsUpdateOrders,
  TicketsClear,
  SetObjectObjectId,
  DraftsGetList,
  DraftGetById,
  DraftDelete,
  DraftCreate,
  DraftUpdate,
} from '../actions/board.action';
import { AuthState } from './auth.state';
import { ToastrService } from 'ngx-toastr';
import { BoardsTicketsService } from '../../../api/services/boards-tickets.service';
import { BoardsSettingsService } from '../../../api/services/boards-settings.service';
import { TicketsOrderUpdateReqDto } from '../../../api/models/tickets-order-update-req-dto';
import { ColumnsOrderUpdateReqDto } from '../../../api/models/columns-order-update-req-dto';
import { TicketService } from '../../services/ticket.service';
import { BoardsDraftsService } from '../../../api/services/boards-drafts.service';
import { DraftService } from '../../services/draft.service';

@State<BoardsStateModel>({
  name: 'Boards',
  defaults: {
    tickets: [],
    ticketsArchive: [],
    checkListItems: [],
    userTickets: null,
    myBoards: [],
    ticketsInfo: [],
    chatTickets: {},
    dataRoomTickets: new Map(),
    ticketFiles: null,
    labels: [],
    ticket: null,
    columns: [],
    members: [],
    object: null,
    objectId: null,
    columnArchiveId: null,
    lastAddedColumnId: null,
    lastAddedTicketId: null,
    lastDeletedTicketId: null,
    lastCreatedLabel: null,
    isOpenedTicket: false,
    estimation: null,
    settings: null,
    requestUuid: null,
    createUpdateTicketRequestUuid: null,
    drafts: [],
    draft: null,
  },
})
@Injectable()
export class BoardsState {
  /**
   * Get tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketsList(state: BoardsStateModel): Array<TicketsDbDto> {
    return state.tickets.filter((ticket) => !ticket?.isEpic);
  }

  /**
   * Get tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getAllTicketsList(state: BoardsStateModel) {
    return state.tickets;
  }

  /**
   * Get tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketsByColumn(state: BoardsStateModel) {
    const isCurrentSprint = (ticket: TicketsDbDto, sprintId: string) => ticket.sprintId === sprintId;
    const isCurrentColumn = (ticket: TicketsDbDto, columnId: string) => ticket.columnId === columnId;
    const isNotSprint = (ticket: TicketsDbDto) => !ticket?.sprintId;
    const isNotBacklog = (ticket: TicketsDbDto) => ticket.columnId !== ticket.objectId;

    return (columnId: string, sprintId?: string) =>
      state.tickets.filter((ticket) => {
        if (isNotBacklog(ticket)) {
          if (sprintId) {
            return isCurrentColumn(ticket, columnId) && isCurrentSprint(ticket, sprintId);
          } else {
            return isCurrentColumn(ticket, columnId) && isNotSprint(ticket);
          }
        }
      });
  }

  /**
   * Get tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketsArchiveList(state: BoardsStateModel) {
    return state.ticketsArchive.filter((ticket) => !ticket?.isEpic);
  }

  /**
   * Get epics list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getEpicsList(state: BoardsStateModel) {
    return state.tickets.filter((ticket) => ticket?.isEpic);
  }

  /**
   * Get user tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getUserTicketsList(state: BoardsStateModel) {
    return state.userTickets;
  }

  /**
   * get my calendars
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getMyBoards(state: BoardsStateModel) {
    return state.myBoards;
  }

  /**
   * Get estimation session
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getEstimateSession(state: BoardsStateModel) {
    return state.estimation;
  }

  /**
   * Get short tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getChatTicketsList(state: BoardsStateModel) {
    return (objectId: string) => state.chatTickets[objectId];
  }

  /**
   * Get chat tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getShortTicketsList(state: BoardsStateModel) {
    return state.dataRoomTickets;
  }

  /**
   * get ticket files
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketFiles(state: BoardsStateModel) {
    return (id: string) => state.ticketFiles[id];
  }

  /**
   * get tickets labels
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketsLabels(state: BoardsStateModel) {
    return state.labels;
  }

  /**
   * Get tickets list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicket(state: BoardsStateModel) {
    return state.ticket;
  }

  /**
   * Get checklist
   * @param {BoardsStateModel} state
   */
  @Selector()
  static getChecklist(state: BoardsStateModel) {
    return state.checkListItems;
  }

  /**
   * Get ticket members
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getTicketMembersList(state: BoardsStateModel) {
    return state.members;
  }

  /**
   * Get last added column ID
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getLastAddedColumnId(state: BoardsStateModel) {
    return state.lastAddedColumnId;
  }

  /**
   * Get last added ticket ID
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getLastAddedTicketId(state: BoardsStateModel) {
    return state.lastAddedTicketId;
  }

  /**
   * Get columns list
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getColumnsList(state: BoardsStateModel) {
    return state.columns;
  }

  /**
   * Get is opened ticket
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getIsOpenedTicket(state: BoardsStateModel) {
    return state.isOpenedTicket;
  }

  /**
   * Get board settings
   * @param  {BoardsStateModel} state
   */
  @Selector()
  static getBoardSettings(state: BoardsStateModel) {
    return state.settings;
  }

  @Selector()
  static getDrafts(state: BoardsStateModel) {
    return state.drafts;
  }

  @Selector()
  static getDraft(state: BoardsStateModel) {
    return state.draft;
  }

  constructor(
    private boardsService: BoardsService,
    private boardServiceTickets: BoardsTicketsService,
    private boardSettingsService: BoardsSettingsService,
    private draftsService: BoardsDraftsService,
    private draftService: DraftService,
    private store: Store,
    private toastr: ToastrService,
    private ticketService: TicketService,
  ) {}

  /**
   * Create tickets action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsCreate} action
   */

  @Action(SetObjectObjectId)
  set_object_objectId({ getState, patchState }: StateContext<BoardsStateModel>, action: SetObjectObjectId) {
    patchState({
      object: action.payload.object,
      objectId: action.payload.objectId,
    });
  }
  @Action(TicketsCreate)
  tickets_create({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsCreate) {
    if (!action.payload.columnId) {
      delete action.payload.columnId;
    }
    if (!action.payload?.description) {
      delete action.payload?.description;
    }
    return this.boardServiceTickets.ticketsCreate({ body: action.payload }).pipe(
      tap(
        (ticket) => {
          const { tickets, createUpdateTicketRequestUuid } = getState();

          if (ticket && action.payload.requestId !== createUpdateTicketRequestUuid) {
            if (
              !tickets.some((ticketState) => ticketState._id === ticket._id) &&
              tickets.some((ticketState) => ticketState.objectId === ticket.objectId)
            ) {
              patchState({
                tickets: [
                  ...tickets,
                  { ...ticket, ...action.payload, order: Math.max(...tickets.map((t) => t.order)) + 1 },
                ],
              });
            }

            this.store.dispatch(new TicketsSetLastAddedId(ticket._id));
            return ticket;
          }

          return null;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update tickets orders action handler
   * @param  {setState}: StateContext<BoardsStateModel>
   * @param  {TicketsCreate} action
   */
  @Action(TicketsUpdateOrders)
  tickets_update_orders({ getState, setState }: StateContext<BoardsStateModel>, action: TicketsUpdateOrders) {
    const { requestUuid } = getState();
    let uniqueRequestId = '';
    if (requestUuid) {
      uniqueRequestId = requestUuid;
    } else {
      uniqueRequestId = uuidv4();
    }
    const remappedTicketsToUpdateOrders: TicketsOrderUpdateReqDto = {
      tickets: action.payload.tickets.map((ticket) => ({
        _id: ticket._id,
        order: ticket.order,
        orderInBacklog: ticket.orderInBacklog,
        columnId: ticket.columnId,
        ...(action.payload.ticketIdToUpdate === ticket._id && action.payload.sprintId
          ? { sprintId: action.payload.sprintId }
          : { sprintId: ticket.sprintId }),
      })),
      actionTicketId: action.payload.ticketIdToUpdate,
      object: action.payload.object,
      objectId: action.payload.objectId,
      requestId: uniqueRequestId,
    };

    const updatedTickets = action.payload.tickets
      .map((ticket) => {
        const index = remappedTicketsToUpdateOrders.tickets.findIndex((stateTicket) => stateTicket._id === ticket._id);

        if (index > -1) {
          return updateItem(
            (t: TicketsDbDto) => t._id === ticket._id,
            patch({
              order: ticket.order,
              orderInBacklog: ticket.orderInBacklog,
              columnId: ticket.columnId,
              sprintId: ticket.sprintId,
              priority: ticket.priority,
            }),
          );
        }
      })
      .filter((f) => f);

    setState(
      patch({
        tickets: compose(...updatedTickets),
        requestUuid: uniqueRequestId,
      }),
    );

    if (remappedTicketsToUpdateOrders.tickets.length) {
      return this.boardServiceTickets.ticketsControllerTicketsOrderUpdate({ body: remappedTicketsToUpdateOrders }).pipe(
        tap(
          (result) => {
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Move tickets via socket notification
   * @param {getState, patchState}: StateContext<BoardsStateModel>
   * @param {TicketsSocketMove} action
   */
  @Action(TicketsSocketMove)
  tickets_socket_move({ getState, setState }: StateContext<BoardsStateModel>, action: TicketsSocketMove) {
    const { tickets, requestUuid } = getState();
    if (action.payload.requestId !== requestUuid) {
      const updatedTickets = action.payload.tickets
        .map((ticketOrder) => {
          const ticketFromState = tickets.find((t) => t._id === ticketOrder._id);

          if (ticketFromState) {
            return updateItem(
              (t: TicketsDbDto) => t._id === ticketOrder._id,
              patch({
                ...ticketOrder,
              }),
            );
          }
        })
        .filter((f) => f);

      setState(
        patch({
          tickets: compose(...updatedTickets),
        }),
      );
    }
  }

  /**
   * Get tickets list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetList} action
   */
  @Action(TicketsGetList)
  tickets_get({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetList) {
    const request = action.payload;

    if (request.object && request.objectId) {
      return this.boardServiceTickets
        .ticketsGetList({
          object: request.object,
          objectId: request.objectId,
          isArchive: request.isArchive,
          ...(request.search ? { search: request.search } : {}),
          ...(request.short === 'chat' ? { short: 'chat' } : {}),
        })
        .pipe(
          tap(
            (result) => {
              if (result?.results) {
                if (request.isArchive) {
                  patchState({ ticketsArchive: result.results });
                } else {
                  if (request.short === 'chat') {
                    const { chatTickets } = getState();
                    patchState({ chatTickets: { ...chatTickets, [request.objectId]: result.results } });
                  } else {
                    if (request.isInternalState) {
                      this.ticketService.setTickets(result.results);
                    } else {
                      patchState({ tickets: result.results });
                    }
                  }
                }
              }

              return result.results;
            },
            (err) => {
              throw err.error;
            },
          ),
        );
    }
  }

  /**
   * Get short tickets list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetShortList} action
   */
  @Action(TicketsGetShortList)
  tickets_get_short_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetShortList) {
    if (action.payload.object && action.payload.objectId) {
      return this.boardServiceTickets.ticketsGetList(action.payload).pipe(
        tap(
          (result) => {
            if (result?.results) {
              const dataRoomTickets = new Map();

              for (const ticket of result.results) {
                const folders = dataRoomTickets?.get('root') || [];
                dataRoomTickets.set('root', [
                  ...folders,
                  {
                    ...ticket,
                    name: `${ticket.boardAbbreviation}-${ticket.counter}`,
                    parentId: 'Board',
                    type: 'public',
                  },
                ]);
              }

              patchState({ dataRoomTickets, ticketsInfo: result.results });
            }

            return result?.results;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Get user tickets list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetUserList} action
   */
  @Action(TicketsGetUserList)
  tickets_get_user_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetUserList) {
    return this.boardsService.usersTicketsGetList(action.payload).pipe(
      tap(
        (result) => {
          patchState({ userTickets: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetChecklistItems} action
   */
  @Action(TicketsGetChecklistItems)
  tickets_get_check_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetChecklistItems) {
    return this.boardServiceTickets.checkListItemsGetList(action.payload).pipe(
      tap(
        (result) => {
          patchState({ checkListItems: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Add check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsAddChecklistItems} action
   */
  @Action(TicketsAddChecklistItems)
  tickets_add_check_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsAddChecklistItems) {
    return this.boardServiceTickets.checkListItemsCreate(action.payload).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({ checkListItems: [...state.checkListItems, result] });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update local check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketUpdateChecklist} action
   */
  @Action(TicketUpdateChecklist)
  tickets_update_checklist({ patchState }: StateContext<BoardsStateModel>, action: TicketUpdateChecklist) {
    return patchState({ checkListItems: action.payload });
  }

  /**
   * Clear local check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketClearLocalList} action
   */
  @Action(TicketClearLocalList)
  tickets_clear_checklist({ patchState }: StateContext<BoardsStateModel>, action: TicketClearLocalList) {
    return patchState({ checkListItems: [] });
  }

  /**
   * Clear local check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketClearLocalList} action
   */
  @Action(TicketsClear)
  tickets_clear({ patchState, getState }: StateContext<BoardsStateModel>, action: TicketsClear) {
    const state = getState();
    if (state.tickets.length && state.tickets[0].objectId === action.payload) {
      return null;
    } else {
      return patchState({ tickets: [] });
    }
  }

  /**
   * Add check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsDeleteChecklistItem} action
   */
  @Action(TicketsDeleteChecklistItem)
  tickets_delete_check_list(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: TicketsDeleteChecklistItem,
  ) {
    return this.boardServiceTickets.checkListItemsDeleteOne(action.payload).pipe(
      tap(
        () => {
          const state = getState();
          const checkListItem = state.checkListItems.filter((item) => item._id !== action.payload.id);
          patchState({ checkListItems: checkListItem });
          if (!action.payload.isConvert) {
            this.toastr.success('Item of checklist has been delete.', 'Item delete');
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateChecklistItem} action
   */
  @Action(TicketsUpdateChecklistItem)
  tickets_update_check_list(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: TicketsUpdateChecklistItem,
  ) {
    return this.boardServiceTickets.checkListItemsUpdate(action.payload).pipe(
      tap(
        (result) => {
          const state = getState();
          const checkListItem = state.checkListItems.map((item) => (item._id === result._id ? result : item));
          patchState({ checkListItems: checkListItem });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete all check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsDeleteChecklist} action
   */
  @Action(TicketsDeleteChecklist)
  tickets_delete_all_check_list(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: TicketsDeleteChecklist,
  ) {
    return patchState({ checkListItems: [] });
  }

  /**
   * Order check list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsOrderUpdate} action
   */
  @Action(TicketsOrderUpdate)
  tickets_order_check_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsOrderUpdate) {
    const { id, body, checklist } = action.payload;
    return this.boardServiceTickets.checkListItemsControllerCheckListItemUpdateOrder({ id, body }).pipe(
      tap(
        (result) => {
          patchState({ checkListItems: checklist });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get checked calendars action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {BoardsGetChecked} action
   */
  @Action(BoardsGetChecked)
  boards_get_checked({ patchState }: StateContext<BoardsStateModel>, action: BoardsGetChecked) {
    patchState({ myBoards: [] });
    // return this.boardsService.boardsGetChecksList({}).pipe(
    //   tap(
    //     (result) => {
    //       patchState({ myBoards: result });
    //       return result;
    //     },
    //     (err) => {
    //       throw err.error;
    //     },
    //   ),
    // );
  }

  /**
   * Get ticket files list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetFilesList} action
   */
  @Action(TicketsGetFilesList)
  tickets_get_files_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetFilesList) {
    if (action.payload.id) {
      const { ticketFiles } = getState();
      return this.boardServiceTickets.ticketsGetOne(action.payload).pipe(
        tap(
          (result) => {
            if (result.fileData) {
              patchState({
                ticketFiles: {
                  ...ticketFiles,
                  [result._id]: result.fileData.map((file) => {
                    return {
                      ...file,
                      parentId: result._id,
                      type: 'public',
                    };
                  }),
                },
              });
            }

            return result.fileData;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Get tickets list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetById} action
   */
  @Action(TicketsGetById)
  tickets_get_by_id({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsGetById) {
    return this.boardServiceTickets.ticketsGetOne({ id: action.payload.id }).pipe(
      tap(
        (result) => {
          patchState({
            ticket: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set last added column ID
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {ColumnsSetLastAddedId} action
   */
  @Action(ColumnsSetLastAddedId)
  columns_set_last_added_id({ patchState }: StateContext<BoardsStateModel>, action: ColumnsSetLastAddedId) {
    patchState({
      lastAddedColumnId: action.payload,
    });
  }

  /**
   * Set last added ticket ID
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsSetLastAddedId} action
   */
  @Action(TicketsSetLastAddedId)
  tickets_set_last_added_id({ patchState }: StateContext<BoardsStateModel>, action: TicketsSetLastAddedId) {
    if (action.payload) {
      patchState({
        lastAddedTicketId: action.payload,
      });
    }
  }

  /**
   * Move tickets
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMove} action
   */
  @Action(TicketsMove)
  tickets_move({ patchState }: StateContext<BoardsStateModel>, action: TicketsMove) {
    return this.boardServiceTickets.ticketsControllerTicketsMove({ body: action.payload }).pipe(
      tap(
        (result) => {
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update tickets via socket notification
   * @param {patchState}: StateContext<BoardsStateModel>
   * @param {TicketsSocketUpdate} action
   */
  @Action(TicketsSocketUpdate)
  tickets_socket_update({ patchState, getState }: StateContext<BoardsStateModel>, action: TicketsSocketUpdate) {
    const {
      tickets,
      ticketsArchive,
      lastAddedTicketId,
      lastDeletedTicketId,
      columnArchiveId,
      createUpdateTicketRequestUuid,
    } = getState();
    const isNewTicket = !tickets.some((ticket) => ticket._id === action.payload._id);
    const isNewArchiveTicket = !ticketsArchive.some((ticket) => ticket._id === action.payload._id);
    const remapTickets = (t) => t.map((ticket) => (ticket._id === action.payload._id ? action.payload : ticket));
    const filterTickets = (t) => t.filter((ticket) => ticket._id !== action.payload._id);

    if (isNewTicket && lastAddedTicketId !== action.payload._id) {
      let uniqueRequestId = '';
      if (createUpdateTicketRequestUuid) {
        uniqueRequestId = createUpdateTicketRequestUuid;
      } else {
        uniqueRequestId = uuidv4();
      }

      patchState({
        tickets: [...tickets, action.payload],
        createUpdateTicketRequestUuid: uniqueRequestId,
      });
    } else if (action.payload.requestId !== createUpdateTicketRequestUuid) {
      const dataToUpdate =
        action.payload.columnId === columnArchiveId
          ? {
              ticketsArchive: isNewArchiveTicket
                ? [...ticketsArchive, action.payload]
                : action.payload.isDeleted && action.payload._id !== lastDeletedTicketId
                ? filterTickets(ticketsArchive)
                : remapTickets(ticketsArchive),
            }
          : {
              tickets:
                action.payload.isDeleted && action.payload._id !== lastDeletedTicketId
                  ? filterTickets(tickets)
                  : remapTickets(tickets),
            };
      patchState(dataToUpdate);
    }
  }

  /**
   * Update user tickets via socket notification
   * @param {getState, patchState}: StateContext<BoardsStateModel>
   * @param {TicketsSocketUpdateUserBoard} action
   */
  @Action(TicketsSocketUpdateUserBoard)
  tickets_socket_update_user_board(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: TicketsSocketUpdateUserBoard,
  ) {
    const { userTickets } = getState();
    const ticket: TicketsDbDto = { ...action.payload };
    const user = this.store.selectSnapshot(AuthState.getUser);
    const ticketMember = ticket?.ticketsMembers.find((item) => item.userId === user._id);

    const newTickets = {
      todo:
        ticket.column.title === 'TODO' && ticketMember && !ticket.isDeleted
          ? userTickets.todo.find((item) => item._id === ticket._id)
            ? userTickets.todo.map((item) => {
                if (item._id === ticket._id) {
                  return { ...item, ...ticket };
                }
                return item;
              })
            : [...userTickets.todo, ticket]
          : userTickets.todo.filter((item) => item._id !== ticket._id),
      inWork:
        ticket.column.title !== 'TODO' &&
        ticket.column.title !== 'DONE' &&
        ticket.column.title !== 'ARCHIVE' &&
        ticketMember &&
        !ticket.isDeleted
          ? userTickets.inWork.find((item) => item._id === ticket._id)
            ? userTickets.inWork.map((item) => {
                if (item._id === ticket._id) {
                  return { ...item, ...ticket };
                }
                return item;
              })
            : [...userTickets.inWork, ticket]
          : userTickets.inWork.filter((item) => item._id !== ticket._id),
      labels: userTickets.labels,
      customFieldsSettings: userTickets.customFieldsSettings,
    };

    patchState({ userTickets: newTickets });
  }

  /**
   * Update tickets local action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateLocal} action
   */
  @Action(TicketsUpdateLocal)
  tickets_update_local({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsUpdateLocal) {
    // Hint: used local update tickets for dnd
    const { tickets } = getState();
    const updatedTickets = tickets?.map((ticket) =>
      ticket._id === action.payload._id ? { ...ticket, ...action.payload } : ticket,
    );

    patchState({ tickets: updatedTickets || [] });
  }

  /**
   * Update tickets action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdate} action
   */
  @Action(TicketsUpdate)
  tickets_update({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsUpdate) {
    let body: any;
    const { tickets, ticketsArchive, columnArchiveId, createUpdateTicketRequestUuid } = getState();

    let uniqueRequestId = '';
    if (createUpdateTicketRequestUuid) {
      uniqueRequestId = createUpdateTicketRequestUuid;
    } else {
      uniqueRequestId = uuidv4();
    }

    patchState({
      createUpdateTicketRequestUuid: uniqueRequestId,
      ...(action.payload.tickets ? { tickets: action.payload.tickets } : {}),
    });

    if (action.payload.tickets) {
      patchState({
        tickets: action.payload.tickets,
      });
    }

    if (!action.payload.columnId) {
      delete action.payload.columnId;
    }
    if (!action.payload?.body?.description) {
      body = { ...action.payload?.body };
    } else {
      body = action.payload?.body;
    }

    body.requestId = uniqueRequestId;

    if (action.payload.ticketUpdateId) {
      return this.boardServiceTickets.ticketsUpdate({ id: action.payload.ticketUpdateId, body }).pipe(
        tap(
          (result) => {
            if (!action.payload?.tickets) {
              const resultToUpdate = (t: Array<TicketsDbDto>) =>
                t.map((ticket) =>
                  ticket._id === action.payload.ticketUpdateId
                    ? {
                        ...result,
                        ...body,
                        checkListItems: result?.checkListItems ? result.checkListItems : [],
                        parentId: result?.parentId ? result.parentId : null,
                        priority: Number.isInteger(result.priority) ? result.priority : null,
                      }
                    : ticket,
                );

              const ticketsToUpdate =
                action.payload.columnId === columnArchiveId
                  ? { ticketsArchive: resultToUpdate(ticketsArchive) }
                  : { tickets: resultToUpdate(tickets) };
              patchState(ticketsToUpdate);
            }
            return result;
          },
          (err) => {
            patchState({
              tickets,
            });
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Update tickets column action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateColumn} action
   */
  @Action(TicketsUpdateColumn)
  tickets_update_column(
    { getState, patchState, setState }: StateContext<BoardsStateModel>,
    action: TicketsUpdateColumn,
  ) {
    const { tickets } = getState();
    const body = action.payload?.body;

    const ticketsToUpdate = tickets
      .map((ticket) => {
        const ticketToUpdate = action.payload.tickets.find((t) => t._id === ticket._id);

        if (ticketToUpdate && ticketToUpdate?._id === ticket._id) {
          return updateItem<TicketsDbDto>((t) => t._id === ticketToUpdate._id, ticketToUpdate);
        } else {
          if (ticket.columnId === body.columnId && ticket.order >= body.order) {
            return updateItem<TicketsDbDto>((t) => t.columnId === body.columnId && t.order >= body.order, {
              ...ticket,
              order: ticket.order + 1,
            });
          }
          if (body?.prevColumnId && ticket.columnId === body.prevColumnId && ticket.order > body.order) {
            return updateItem<TicketsDbDto>((t) => t.columnId === body.prevColumnId && t.order > body.order, {
              ...ticket,
              order: ticket.order - 1,
            });
          }
        }
      })
      .filter((t) => t);

    setState(
      patch({
        tickets: compose(...ticketsToUpdate),
      }),
    );

    if (action.payload.ticketUpdateId) {
      return this.boardServiceTickets.ticketsUpdate({ id: action.payload.ticketUpdateId, body }).pipe(
        tap(
          (result) => {
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Update tickets orders in column action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateOrdersInColumns} action
   */
  @Action(TicketsUpdateOrdersInColumns)
  tickets_update_orders_in_columns(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: TicketsUpdateOrdersInColumns,
  ) {
    if (action.payload) {
      const state = getState();
      const tickets = JSON.parse(JSON.stringify(state.tickets));

      action.payload.map((ticket) => {
        const result = tickets.find((t) => t._id === ticket.ticketId);
        result.order = ticket.order;
      });

      patchState({
        tickets: JSON.parse(JSON.stringify(tickets)),
      });

      return;
    }
  }

  /**
   * Delete tickets action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsDelete} action
   */
  @Action(TicketsDelete)
  tickets_delete({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsDelete) {
    const { tickets, ticketsArchive, columnArchiveId } = getState();
    const isArchiveTicket =
      ticketsArchive.find((ticket) => ticket._id === action.payload.ticketDeleteId)?.columnId === columnArchiveId;
    if (action.payload.ticketDeleteId) {
      return this.boardServiceTickets.ticketsDelete({ id: action.payload.ticketDeleteId }).pipe(
        tap(
          (result) => {
            const filterDeletedTicket = (ticketsToFilter) =>
              ticketsToFilter.filter((ticket) => ticket._id !== result._id);
            patchState(
              isArchiveTicket
                ? { ticketsArchive: filterDeletedTicket(ticketsArchive), lastDeletedTicketId: result._id }
                : { tickets: filterDeletedTicket(tickets), lastDeletedTicketId: result._id },
            );
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Upload tickets files handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsFilesUpload} action
   */
  @Action(TicketsFilesUpload)
  tickets_files_upload({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsFilesUpload) {
    if (action.payload.ticketId) {
      const body = action.payload;

      return this.boardsService.ticketsFilesUpload({ body }).pipe(
        tap(
          (result) => {
            if (result) {
              return result;
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Delete tickets files handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsFilesDelete} action
   */
  @Action(TicketsFilesDelete)
  tickets_files_delete({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsFilesDelete) {
    if (action.payload.id) {
      return this.boardsService.ticketsFilesDelete({ id: action.payload.id }).pipe(
        tap(
          (result) => {
            if (result.success) {
              // TODO: update ticket
              return result;
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Create column action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {ColumnsCreate} action
   */
  @Action(ColumnsCreate)
  columns_create({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsCreate) {
    if (action.payload.columnTitle) {
      return this.boardsService
        .columnsCreate({
          body: {
            title: action.payload.columnTitle,
            object: action.payload.object,
            objectId: action.payload.objectId,
          },
        })
        .pipe(
          tap(
            (result) => {
              const state = getState();
              if (result) {
                if (!state.columns.some((column) => column._id === result._id)) {
                  patchState({
                    columns: [...state.columns, result],
                  });
                }
                this.store.dispatch(new ColumnsSetLastAddedId(result._id));
              }

              return result;
            },
            (err) => {
              throw err.error;
            },
          ),
        );
    }
  }

  /**
   * Get columns list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsCreate} action
   */
  @Action(ColumnsGetList)
  columns_get({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsGetList) {
    const { isInternalState, object, objectId } = action.payload;
    if (object && objectId) {
      return this.boardsService
        .columnsGetList({ object: action.payload.object, objectId: action.payload.objectId })
        .pipe(
          tap(
            (result) => {
              if (isInternalState) {
                // set columns into service state without changing in global
                this.ticketService.setTicketColumns(result);
              } else if (action.payload.forRecount) {
                this.ticketService.setColumnsForRecount(result);
              } else {
                const columnArchiveId = result.find((column) => column.title === 'ARCHIVE')?._id;
                if (result) {
                  patchState({
                    columns: result,
                    columnArchiveId,
                  });
                }

                return result;
              }
            },
            (err) => {
              throw err.error;
            },
          ),
        );
    }
  }

  /**
   * Update column action handler
   * @param  {getState, setState}: StateContext<BoardsStateModel>
   * @param  {ColumnsUpdate} action
   */
  @Action(ColumnsUpdate)
  columns_update({ getState, setState }: StateContext<BoardsStateModel>, action: ColumnsUpdate) {
    if (action.payload.columnUpdateId) {
      const { columns } = getState();
      const { ...body } = action.payload.body;

      setState(
        patch({
          columns: columns.map((column) =>
            column._id === action.payload.columnUpdateId ? { ...column, ...body } : column,
          ),
        }),
      );

      return this.boardsService.columnsUpdate({ id: action.payload.columnUpdateId, body: body }).pipe(
        tap(
          (result) => {
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Update columns orders via socket notification
   * @param {getState, patchState}: StateContext<BoardsStateModel>
   * @param {ColumnsSocketUpdate} action
   */
  @Action(ColumnsUpdateOrders)
  columns_update_orders({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsUpdateOrders) {
    if (action.payload?.columns?.length) {
      const { columns, requestUuid } = getState();
      let uniqueRequestId = '';
      if (requestUuid) {
        uniqueRequestId = requestUuid;
      } else {
        uniqueRequestId = uuidv4();
      }

      const remappedColumnsToUpdateOrders: ColumnsOrderUpdateReqDto = {
        columns: action.payload.columns
          .map((column) => {
            if (column.title !== 'ARCHIVE') {
              return {
                _id: column._id,
                order: column.order,
              };
            }
          })
          .filter((c) => c && c._id),
        object: action.payload.object,
        objectId: action.payload.objectId,
        requestId: uniqueRequestId,
      };

      const updatedColumns = columns
        .map((columnFromState) => {
          const updatedColumn = remappedColumnsToUpdateOrders.columns.find((t) => t._id === columnFromState._id);

          if (updatedColumn) {
            return {
              ...columnFromState,
              order: updatedColumn.order,
            };
          }
          return columnFromState;
        })
        .filter((c) => c.title !== 'ARCHIVE');

      const archiveColumnInArr = columns.filter((column) => column.title === 'ARCHIVE');

      patchState({
        columns: [...updatedColumns.sort((a, b) => a.order - b.order), ...archiveColumnInArr],
        requestUuid: uniqueRequestId,
      });

      return this.boardsService.boardsControllerColumnsOrderUpdate({ body: remappedColumnsToUpdateOrders }).pipe(
        tap(
          (result) => {
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Move columns via socket notification
   * @param {getState, setState}: StateContext<BoardsStateModel>
   * @param {ColumnsSocketMove} action
   */
  @Action(ColumnsSocketMove)
  columns_socket_move({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsSocketMove) {
    const { columns, requestUuid } = getState();

    if (action.payload.requestId !== requestUuid && action.payload?.columns?.length) {
      const updatedColumns = columns.map((columnFromState) => {
        const columnOrder = action.payload.columns.find((c) => c._id === columnFromState._id);

        if (columnOrder) {
          return {
            ...columnFromState,
            ...columnOrder,
          };
        }
        return columnFromState;
      });

      patchState({
        columns: updatedColumns.sort((a, b) => a.order - b.order),
      });
    }
  }

  /**
   * Update columns via socket notification
   * @param {setState}: StateContext<BoardsStateModel>
   * @param {ColumnsSocketUpdate} action
   */
  @Action(ColumnsSocketUpdate)
  columns_socket_update({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsSocketUpdate) {
    const { columns } = getState();
    const isNewColumn = !columns.some((column) => column._id === action.payload._id);
    if (!action.payload?.['columns']?.length) {
      const archiveColumnInArr = columns.filter((column) => column.title === 'ARCHIVE');
      const columnsWIthOutArchive = columns.filter((column) => column.title !== 'ARCHIVE');

      // filter if delete column action
      const filteredColumns = columnsWIthOutArchive.filter(
        (column) => !action.payload.isDeleted || (action.payload.isDeleted && column._id !== action.payload._id),
      );

      // order: columns.length to define order for the new column
      patchState({
        columns: isNewColumn
          ? [
              ...filteredColumns.sort((a, b) => a.order - b.order),
              { ...action.payload, order: columns.length },
              ...archiveColumnInArr,
            ]
          : [
              ...filteredColumns
                .map((column) => (column._id === action.payload._id ? action.payload : column))
                .sort((a, b) => a.order - b.order),
              ...archiveColumnInArr,
            ],
      });
    }
  }

  /**
   * Delete columns action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {ColumnsDelete} action
   */
  @Action(ColumnsDelete)
  columns_delete({ getState, patchState }: StateContext<BoardsStateModel>, action: ColumnsDelete) {
    if (action.payload.columnDeleteId) {
      return this.boardsService.columnsDelete({ id: action.payload.columnDeleteId }).pipe(
        tap(
          (result) => {
            const { columns } = getState();
            patchState({ columns: columns.filter((column) => column._id !== result._id) });
            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Tickets members get list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMembersGetList} action
   */
  @Action(TicketsMembersGetList)
  tickets_members_get_list({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsMembersGetList) {
    if (action.payload.ticketId) {
      return this.boardsService.ticketsMembersGetList({ id: action.payload.ticketId }).pipe(
        tap(
          (result) => {
            if (result) {
              patchState({ members: result });
            }

            return result;
          },
          (err) => {
            throw err.error;
          },
        ),
      );
    }
  }

  /**
   * Tickets members create action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMembersCreate} action
   */
  @Action(TicketsMembersCreate)
  tickets_members_create({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsMembersCreate) {
    let ticketId = null;
    let userIds: string[] = [];

    if (action.payload.ticketId && action.payload.userIds) {
      ticketId = action.payload.ticketId;
      userIds = action.payload.userIds;

      return this.boardsService
        .ticketsMembersCreate({
          body: {
            userIds: userIds,
            ticketId: ticketId,
          },
        })
        .pipe(
          tap(
            (result) => {
              const state = getState();

              if (result) {
                patchState({
                  members: [...state.members, ...result],
                });
              }

              return result;
            },
            (err) => {
              throw err.error;
            },
          ),
        );
    }
  }

  /**
   * Tickets members update action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMembersUpdate} action
   */
  @Action(TicketsMembersUpdate)
  tickets_members_update({ patchState }: StateContext<BoardsStateModel>, action: TicketsMembersUpdate) {
    if (action.payload.ticketUpdateId) {
      return this.boardsService
        .ticketsMembersUpdate({
          id: action.payload.ticketUpdateId,
          body: {
            userIds: action.payload.userIds,
          },
        })
        .pipe(
          tap(
            (result) => {
              if (result) {
                patchState({
                  members: [...result],
                });
              }

              return result;
            },
            (err) => {
              throw err.error;
            },
          ),
        );
    }
  }

  /**
   * Create tickets label action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsLabelCreate} action
   */
  @Action(TicketsLabelCreate)
  tickets_label_create({ getState, patchState }: StateContext<BoardsStateModel>, action: TicketsLabelCreate) {
    return this.boardsService.ticketsLabelsCreate({ body: action.payload }).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            labels: [...state.labels, result],
            lastCreatedLabel: result,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get tickets labels action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsLabelsGet} action
   */
  @Action(TicketsLabelsGet)
  tickets_labels_get({ patchState }: StateContext<BoardsStateModel>, action: TicketsLabelsGet) {
    const { isInternalState, ...rest } = action.payload;
    if (!isInternalState) {
      patchState({ labels: [] });
    }

    return this.boardsService.ticketsLabelsGetList(rest).pipe(
      tap(
        (result) => {
          if (isInternalState) {
            // set ticket labels into service state without changing in global
            this.ticketService.setTicketLabels(result);
          } else {
            patchState({
              labels: result,
            });
            return result;
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Moving all column tickets to Archive action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMoveAllToArchive} action
   */
  @Action(TicketsMoveAllToArchive)
  move_all_to_archive({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsMoveAllToArchive) {
    return this.boardsService.ticketsToArchive({ body: payload }).pipe(
      tap(
        (result) => {
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Moving tickets to Archive action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsMoveToArchive} action
   */
  @Action(TicketsMoveToArchive)
  move_to_archive({ getState, patchState }: StateContext<BoardsStateModel>, { payload }: TicketsMoveToArchive) {
    const { tickets } = getState();
    const newTickets = tickets.map((ticket) => {
      if (ticket.columnId === payload.prevColumnId) {
        return { ...ticket, columnId: payload.archiveColumnId };
      }
      return ticket;
    });

    patchState({ tickets: newTickets });
  }

  /**
   * Moving tickets to Archive action handler
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsOpenTicketChange} action
   */
  @Action(TicketsOpenTicketChange)
  openTicketChange({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsOpenTicketChange) {
    patchState({ isOpenedTicket: payload });
  }

  /**
   * Create estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsSocketUpdateEstimationSession} action
   */
  @Action(TicketsSocketUpdateEstimationSession)
  socketUpdateEstimateSession(
    { patchState }: StateContext<BoardsStateModel>,
    { payload }: TicketsSocketUpdateEstimationSession,
  ) {
    patchState({ estimation: payload });
  }

  /**
   * Create estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsCreateEstimationSession} action
   */
  @Action(TicketsCreateEstimationSession)
  createEstimateSession({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsCreateEstimationSession) {
    return this.boardsService.boardsControllerCreateEstimationSession(payload).pipe(
      tap((estimation) => {
        patchState({ estimation: estimation as EstimationSessionsResDto });
      }),
    );
  }

  /**
   * Update estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateEstimationSession} action
   */
  @Action(TicketsUpdateEstimationSession)
  updateEstimateSession({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsUpdateEstimationSession) {
    return this.boardsService.boardsControllerUpdateEstimationSession(payload).pipe(
      tap((estimation) => {
        patchState({ estimation: estimation as EstimationSessionsResDto });
      }),
    );
  }

  /**
   * Get estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetEstimationSession} action
   */
  @Action(TicketsGetEstimationSession)
  getEstimateSession({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsGetEstimationSession) {
    return this.boardsService.boardsControllerGetEstimationSession(payload).pipe(
      tap((estimation) => {
        patchState({ estimation });
      }),
    );
  }

  /**
   * Delete estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsDeleteEstimationSession} action
   */
  @Action(TicketsDeleteEstimationSession)
  deleteEstimateSession({ patchState }: StateContext<BoardsStateModel>, { payload }: TicketsDeleteEstimationSession) {
    return this.boardsService.boardsControllerDeleteEstimationSession(payload).pipe(
      tap(() => {
        patchState({ estimation: null });
      }),
    );
  }

  /**
   * Join member estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsJoinMemberToEstimationSession} action
   */
  @Action(TicketsJoinMemberToEstimationSession)
  joinMemberEstimateSession(
    { patchState, getState }: StateContext<BoardsStateModel>,
    { payload }: TicketsJoinMemberToEstimationSession,
  ) {
    const state = getState();
    return this.boardsService.boardsControllerCreateEstimationSessionMember(payload).pipe(
      tap((member) => {
        const isExistMembers = state.estimation?.members?.length;

        if (isExistMembers) {
          patchState({ estimation: { ...state.estimation, ...{ members: [...state.estimation?.members, member] } } });
        }

        if (!isExistMembers) {
          patchState({ estimation: { ...state.estimation, ...{ members: [member] } } });
        }
      }),
    );
  }

  /**
   * Update member estimation session
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsUpdateMemberEstimationSession} action
   */
  @Action(TicketsUpdateMemberEstimationSession)
  updateMemberEstimateSession(
    { patchState, getState }: StateContext<BoardsStateModel>,
    { payload }: TicketsUpdateMemberEstimationSession,
  ) {
    const state = getState();
    return this.boardsService.boardsControllerUpdateEstimationSessionMember(payload).pipe(
      tap((member) => {
        patchState({
          estimation: {
            ...state.estimation,
            ...{
              members: state.estimation?.members.map((storeMember) =>
                storeMember._id === member._id ? member : storeMember,
              ),
            },
          },
        });
      }),
    );
  }

  /**
   * Get user tickets list action handler
   * @param  {getState, patchState}: StateContext<BoardsStateModel>
   * @param  {TicketsGetUserList} action
   */
  @Action(BoardsGetSettings)
  board_get_settings({ getState, patchState }: StateContext<BoardsStateModel>, action: BoardsGetSettings) {
    return this.boardSettingsService.boardSettingsGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({ settings: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(BoardsSetSettings)
  board_set_settings({ getState, patchState }: StateContext<BoardsStateModel>, action: BoardsSetSettings) {
    return this.boardSettingsService.boardSettingsSet({ body: action.payload }).pipe(
      tap(
        (result) => {
          patchState({ settings: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(BoardsApplyTicketSettings)
  board_apply_ticket_settings(
    { getState, patchState }: StateContext<BoardsStateModel>,
    action: BoardsApplyTicketSettings,
  ) {
    return this.boardSettingsService.boardApplyTicketSettings(action.payload).pipe(
      tap(
        (result) => {
          patchState({ settings: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DraftsGetList)
  drafts_get_list({ patchState }: StateContext<BoardsStateModel>) {
    return this.draftsService.ticketsDraftsGetList().pipe(
      tap(
        (result) => {
          this.draftService.setDrafts(result);
          patchState({ drafts: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DraftGetById)
  draft_get_by_id({ patchState }: StateContext<BoardsStateModel>, action: DraftGetById) {
    return this.draftsService.ticketsDraftsGetOne(action.payload).pipe(
      tap(
        (result) => {
          patchState({ draft: result });

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DraftCreate)
  draft_create({ getState, patchState }: StateContext<BoardsStateModel>, action: DraftCreate) {
    return this.draftsService.ticketsDraftsCreate(action.payload).pipe(
      tap(
        (result) => {
          const { drafts } = getState();
          this.draftService.setDrafts([...drafts, result]);
          patchState({ drafts: [...drafts, result] });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DraftUpdate)
  draft_update({ getState, patchState }: StateContext<BoardsStateModel>, action: DraftUpdate) {
    return this.draftsService.ticketsDraftsUpdate(action.payload).pipe(
      tap(
        (result) => {
          const { drafts } = getState();

          if (action.payload.isNeedToSaveChanges) {
            const filteredDrafts = drafts.filter((draft) => draft._id !== action.payload.id);
            this.draftService.setDrafts(filteredDrafts);
            patchState({ drafts: filteredDrafts });
          } else {
            const remappedDrafts = drafts.map((draft) => (draft._id === result._id ? result : draft));
            this.draftService.setDrafts(remappedDrafts);
            patchState({ drafts: remappedDrafts });
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DraftDelete)
  draft_delete({ getState, patchState }: StateContext<BoardsStateModel>, action: DraftDelete) {
    return this.draftsService.ticketsDraftsDelete(action.payload).pipe(
      tap(
        () => {
          const { drafts } = getState();
          const filteredDrafts = drafts.filter((draft) => draft._id !== action.payload.id);
          this.draftService.setDrafts(filteredDrafts);
          patchState({ drafts: filteredDrafts });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
