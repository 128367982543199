<div class="accept-step" *transloco="let t; read: 'modals.payment'">
  <h3 class="accept-step__title">{{ t('accept-title') }}</h3>
  <p class="accept-step__paragraph">{{ t('accept-description') }}</p>

  <div class="accept-step__box">
    <app-payment-feature-card *ngFor="let feature of features" [feature]="feature" />
  </div>

  <div class="accept-step__buttons">
    <app-button [label]="t('btn-retrain')" customClasses="padding-12" [widthFull]="true" (handleClick)="handleBack()"></app-button>
    <app-button [label]="t('downgrade', { value: currentPlan.title})" variantButton="none" [isLoading]="isLoading" [widthFull]="true" (handleClick)="handleAccept()"></app-button>
  </div>
</div>
