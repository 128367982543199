<div class="modal-body custom-field" [class.custom-dark]="config?.layout.variant === 'Dark'" *transloco="let t; read: 'modals.custom-field'">
  <h3 class="custom-field__title">{{ t(fieldId ? 'edit-custom-field' : 'add-custom-field') }}</h3>
  <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" class="sidebar-tenant_scroll custom-field-scroll" thumbClass="white-scrollbars">
  <form [formGroup]="fieldForm" (ngSubmit)="submitForm()" class="fieldForm">
      <div class="custom-field__name">
        <label for="nameField">{{ t('name') }} <span>*</span></label>
        <input
          t-id="name-field"
          id="nameField"
          placeholder="{{ t('placeholder-type-something') }}"
          formControlName="title"
          tabindex="1"
          type="text"
          required
          class="form-control"
        >
        <div
          *ngIf="createSubmit && (customFormControl.title.invalid && customFormControl.title.errors?.required)"
          t-id="login-form-invalid-tenant-alert"
          class="help-block mt-1 text-danger"
        >
          <i class="ft-alert-circle align-middle"></i> {{ t('this-is-required') }}
        </div>
      </div>

      <div class="custom-field__description">
        <label for="descriptionField">{{ t('description') }}</label>
        <textarea
          t-id="description-field"
          id="descriptionField"
          class="form-control"
          name="description"
          placeholder="{{ t('placeholder-type-something') }}"
          formControlName="description"
        ></textarea>
      </div>

      <div class="custom-field__type">
        <label for="typeField">{{ t('type') }} <span>*</span></label>
        <ng-select
          t-id="type-field"
          id="typeField"
          class="typeField"
          bindLabel="userName"
          bindValue="type"
          placeholder="{{ t('placeholder-select-type') }}"
          appendTo="body"
          [items]="items"
          [clearable]="false"
          [searchable]="false"
          [closeOnSelect]="true"
          formControlName="type"
        >
          <ng-template ng-label-tmp let-item="item" id="selectTypeField">
            <div class="d-flex align-items-center">
              <app-svg
                t-id="type-field-image"
                class="cursor-pointer svg-icon"
                [name]="item.type"
                width="18"
                height="18"
                fill="#565466"
              ></app-svg>
              <div class="ml-1 custom-field__type-selected">
                {{ item.title }}
              </div>
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" id="selectTypeoption">
            <div>
              <div class="d-flex align-items-center">
                <app-svg
                  t-id="type-field-image"
                  class="cursor-pointer svg-icon"
                  [name]="item.type"
                  width="18"
                  height="18"
                  fill="#565466"
                ></app-svg>
                <div class="ml-1 custom-field__type-title">
                  {{ item.title }}
                </div>
              </div>
              <div class="custom-field__type-description">{{item.description}}</div>
            </div>
          </ng-template>
        </ng-select>
        <div
          *ngIf="createSubmit && (customFormControl.type.invalid && customFormControl.type.errors?.required)"
          t-id="login-form-invalid-tenant-alert"
          class="help-block mt-1 text-danger"
        >
          <i class="ft-alert-circle align-middle"></i> {{ t('this-is-required') }}
        </div>
      </div>

      <div *ngIf="isShowFormat" class="custom-field__format">
        <div *ngIf="isShowFormatSelect; else formatInput" class="custom-field__format-select">
          <label for="formatField">{{ t(isDateTimePicker ? 'date-format' : 'format') }} <span>*</span></label>
          <ng-select
            t-id="format-field"
            id="formatField"
            class="formatField"
            placeholder="{{ t('placeholder-select-format') }}"
            appendTo="body"
            [searchable]="false"
            [items]="formatDate"
            [clearable]="false"
            [closeOnSelect]="true"
            formControlName="format"
          >
            <ng-template ng-option-tmp let-item="item" id="selectTypeFormat">
              <div class="custom-field__type-selected">
                {{ getMomentDate(item) }}
              </div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" id="selectTypeFormatOption">
              <div class="custom-field__type-selected">
                {{ getMomentDate(item) }}
              </div>
            </ng-template>

          </ng-select>
          <div
            *ngIf="createSubmit && (customFormControl.format.invalid && customFormControl.format.errors?.required)"
            t-id="login-form-invalid-tenant-alert"
            class="help-block mt-1 text-danger"
          >
            <i class="ft-alert-circle align-middle"></i> {{ t('this-is-required') }}
          </div>

          <div *ngIf="isDateTimePicker" class="custom-field__format-time">
            <label for="formatField">{{ t('time-format') }} <span>*</span></label>
            <ng-select
              t-id="format-field"
              id="formatTimeField"
              class="formatField"
              placeholder="{{ t('placeholder-select-format') }}"
              appendTo="body"
              [items]="formatTime"
              [clearable]="false"
              [searchable]="false"
              [closeOnSelect]="true"
              [formControl]="timeOption"
              [required]="isDateTimePicker"
            >
              <ng-template ng-option-tmp let-item="item" id="selectTypeTimeFormat">
                <div class="custom-field__type-selected">
                  {{ getMomentDate(item) }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item" id="selectTypeFormatTimeOption">
                <div class="custom-field__type-selected">
                  {{ getMomentDate(item) }}
                </div>
              </ng-template>

            </ng-select>
            <div
              *ngIf="createSubmit && (timeOption.invalid && timeOption.errors?.required)"
              t-id="login-form-invalid-tenant-alert"
              class="help-block mt-1 text-danger"
            >
              <i class="ft-alert-circle align-middle"></i> {{ t('this-is-required') }}
            </div>
          </div>
        </div>
        <ng-template #formatInput>
          <div class="custom-field__format-input">
            <label for="formatField">{{ t('options') }} <span>*</span></label>
            <div class="custom-field__format-input-wrapper">
              <input
                t-id="option-field"
                id="option"
                placeholder="{{ t('placeholder-type-something') }}"
                tabindex="1"
                type="text"
                class="form-control"
                [formControl]="currentOption"
              >
              <button [disabled]="checkValidate" class="btn btn-subtle" type="button" (click)="pushOption()">Add</button>
            </div>
            <div
              *ngIf="createSubmit && (customFormControl.formatMultiple.invalid && customFormControl.formatMultiple.errors?.required)"
              t-id="login-form-invalid-tenant-alert"
              class="help-block mt-1 text-danger"
            >
              <i class="ft-alert-circle align-middle"></i> {{ t('this-is-required') }}
            </div>
            <div
              class="list-options"
              cdkDropList
              (cdkDropListDropped)="dragDropOption($event)"
            >
              <div
                class="option" [class.optionDark]="config?.layout.variant === 'Dark'"
                *ngFor="let option of options"
                cdkDrag
                cdkDragLockAxis="y"
                [cdkDragStartDelay]="50"
              >
                <div class="option-name">{{option}}</div>
                <app-svg
                  t-id="page-details-title-action"
                  class="cursor-pointer cancel-icon svg-icon"
                  name="times"
                  width="10"
                  height="10"
                  aria-label="Close"
                  (click)="delete(option)"
                ></app-svg>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="custom-field__accepted">
        <div class="d-flex align-items-center">
          <input
            id="mandatory-field"
            class="mr-2"
            type="checkbox"
            formControlName="isMandatory"
          />
          <span>{{ t('make-field-mandatory') }}</span>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input
            id="display-field"
            class="mr-2"
            type="checkbox"
            formControlName="isOnCard"
          />
          <span>{{ t('display-on-card') }}</span>
        </div>
      </div>

      <div class="custom-field__buttons">
        <button class="btn btn-subtle" type="button" (click)="closeModal()">{{ t('btn-cancel') }}</button>
        <button [disabled]="(isLoading | async) || !this.fieldForm.valid" class="btn btn-solid btn-save" type="submit">
          <i *ngIf="isLoading | async" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
          {{ (isLoading | async) ? '' : t(fieldId ? 'btn-edit' : 'btn-add') }}
        </button>
      </div>

    </form>
  </ng-scrollbar>
</div>
