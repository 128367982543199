<div class="row integrations-wrapper m-0" *transloco="let t; read: 'webhooks'">
  <div *ngIf="platform === 'web'" class="col-md-4 pt-2 tab-links" [class.tab-links-dark]="darkMode">
    <!-- Nav tabs -->
    <ul class="nav nav-pills flex-column" role="tablist">
      <li class="tab-item">
        <a href="javascript:;"
           id="incoming-webhooks-tab"
           data-toggle="tab"
           role="tab"
           aria-controls="action-logs"
           aria-selected="true"
           [ngClass]="{active: activeTab === 'incoming-webhooks'}"
           (click)="activeTab = 'incoming-webhooks'" >
          <span class="align-middle">{{t('incoming-webhooks')}}</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="col-md-8 tab-content" [class.pt-0]="platform !== 'web'">
    <!-- Tab panes -->
    <div class="content-wrapper">
      <!-- Action Logs Tab -->
      <div
        *ngIf="activeTab === 'incoming-webhooks'"
        role="tabpanel"
        aria-labelledby="incoming-webhooks-tab"
        class="tab-pane tab-activity"
      >
        <div class="d-flex justify-content-between">
          <span *ngIf="platform === 'web'" class="content-header">
            {{t('incoming-webhooks')}}
          </span>
          <button
            *ngIf="'spaces::' + spaceId + '::webhookCreate' | checkPermission"
            class="btn btn-solid d-flex align-items-center header-button"
            (click)="createUpdateWebhookModal()"
            t-id="integrations-new-webhook-button"
          >
            <span>{{t('new-webhooks')}}</span>
          </button>
        </div>

        <app-webhooks-list
          [chatId]="this.chatId"
          [spaceId]="spaceId"
          [darkMode]="darkMode"
          (editeModalOpen)="createUpdateWebhookModal($event)"
        ></app-webhooks-list>
      </div>
    </div>
  </div>
</div>
