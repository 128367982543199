<div class="choice-step" [class.dark]="isDark" *transloco="let t; read: 'modals.payment'">
    <div class="choice-step__info">
      <h4 class="choice-step__title">{{ isFreeCurrentPlan ? t('free-title-choice') : t('title-choice') }}</h4>
      <p class="choice-step__paragraph">
        {{
        isFreeCurrentPlan
          ? t('free-description-choice', { value: currentPlan.title})
          : t('description-choice', { value: currentPlan.title})
        }}
      </p>

      <div class="choice-step__buttons">
        <app-button customClasses="padding-8" [label]="t('monthly')" [variantButton]="isChoiceMonthly" (handleClick)="handleChoiceMonthly()"/>
        <app-button customClasses="padding-8" [label]="t('yearly')" [variantButton]="isChoiceYearly" (handleClick)="handleChoiceYearly()"/>
      </div>

    </div>
    <div class="card__items">
      <app-payment-card *ngFor="let plan of plans" [isCurrentPlan]="currentPlanAttribute" [isLoading]="isLoading" [plan]="plan" [paymentType]="activeDate"></app-payment-card>
    </div>
  </div>
