export const environment = {
  name: 'dev',
  payment_limitation: false,
  is_desktop: false,
  production: true,
  subdomains: true,
  payment: false,
  ssl: true,
  base_host: 'teamplate-dev.com',
  main_host: 'teamplate-dev.com',
  api_root: 'https://api.teamplate-dev.com',
  socket_url: 'https://api.teamplate-dev.com/',
  sentryEnv: 'dev',
  jitsiAppId: 'vpaas-magic-cookie-3157909463504c28a75d5418205b11aa/',
  build_number: `dev`,
};
