import { ChangeDetectionStrategy, ChangeDetectorRef, Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { GenericMessageComponent } from '../generic-message/generic-message.component';

import WaveSurfer from 'wavesurfer.js';
import { takeUntil } from 'rxjs/operators';
import { FilesHelper } from '../../../../utils/files-helper';
import { ConfigService } from '../../../../services/config.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-audio-message',
  templateUrl: './audio-message.component.html',
  styleUrls: ['./audio-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioMessageComponent extends GenericMessageComponent implements OnInit, AfterViewInit, OnDestroy {
  AudioMessage: WaveSurfer;
  isReadyAudioMessage = false;
  AudioMessageState = false;

  constructor(
    public cdr: ChangeDetectorRef,
    public filesHelper: FilesHelper,
    protected configService: ConfigService,
    public store: Store,
  ) {
    super(cdr, filesHelper, configService, store);
  }

  ngOnInit(): void {
    if (this.message.isAudioMessage) {
      this.AudioMessage?.destroy();
      this.AudioMessage = null;
      this.populateAudioMessage();
    }
  }

  ngAfterViewInit(): void {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;

        if (this.AudioMessage) {
          const { waveColor, progressColor } = this.getWaveFormColors();

          this.AudioMessage.setWaveColor(waveColor);
          this.AudioMessage.setProgressColor(progressColor);
        }
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.message.isAudioMessage) {
      this.AudioMessage?.destroy();
    }
    super.ngOnDestroy();
  }

  getWaveFormColors() {
    const waveColor = this.config?.layout.variant === 'Light' ? '#683cc133' : '#686868';
    const progressColor = this.config?.layout.variant === 'Light' ? '#7441db' : '#444444';

    return { waveColor, progressColor };
  }

  populateAudioMessage() {
    const { waveColor, progressColor } = this.getWaveFormColors();

    setTimeout(() => {
      const selector = 'waveform-' + this.message._id + '-' + (this.context || '');
      if (document.getElementById(selector)) {
        this.AudioMessage = WaveSurfer.create({
          container: `#${selector}`,
          waveColor,
          progressColor,
        });

        this.AudioMessage.load(this.message?.fileData.url);

        this.AudioMessage.on('ready', () => {
          this.isReadyAudioMessage = true;
          this.cdr.detectChanges();
        });
      }
    }, 100);
  }

  playPauseAudioMessage() {
    if (!this.AudioMessageState) {
      this.AudioMessage.play();
    } else {
      this.AudioMessage.pause();
    }

    this.AudioMessageState = !this.AudioMessageState;
    this.cdr.detectChanges();
  }
}
