import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DraftService } from '../../../services/draft.service';

import { TicketsDraftsResDto } from '../../../../api/models/tickets-drafts-res-dto';
import { ConfigService, ITemplateConfig } from '../../../services/config.service';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss'],
})
export class DraftComponent {
  @Input() draft: TicketsDraftsResDto;
  private config: ITemplateConfig;

  constructor(
    public draftService: DraftService,
    private configService: ConfigService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  public get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get nameDraft(): string {
    return this.draft.title === 'New ticket' ? this.translocoService.translate('board.draft') : this.draft.title;
  }
}
