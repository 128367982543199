<div class="modal-header d-flex align-items-center">
  <app-svg
    t-id="page-details-title-action"
    class="cursor-pointer cancel-icon svg-icon"
    name="times"
    width="10"
    height="10"
    aria-label="Close"
    (click)="closeModal()"
  ></app-svg>
</div>

<div class="modal-body poll" [class.poll-dark]="config?.layout.variant === 'Dark'" *transloco="let t; read: 'modals.poll'">
  <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" class="sidebar-tenant_scroll poll-scroll" thumbClass="white-scrollbars">
    <h3 class="poll__title">{{ t('title-poll-results') }}</h3>
    <div class="poll__subTitle">
      <app-svg name="poll" width="14" height="14" aria-hidden="true"></app-svg>
      <h4>{{title}}</h4>
    </div>
    <div class="poll__wrapper">
      <ng-container *ngFor="let option of options">
            <div *ngIf="option.voter.length" class="poll__item">
              <div class="poll__item-info">
                <div class="poll__item-title">
                  <p>{{option.name}} - {{option.percent}}%</p>
                </div>
                <div class="poll__item-votes">
                  <p>{{ t('votes', { value: option.voter.length }) }}</p>
                </div>
              </div>
              <div class="poll__item-users">
              <div *ngFor="let user of option.voter" class="poll__item-user">
                <app-avatar
                  [userId]="user.userId"
                  [width]="30"
                  [height]="30"
                  [showStatus]="false"
                ></app-avatar>
                <span>{{ getUserName(user.userId) }}</span>
              </div>
            </div>
            </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
