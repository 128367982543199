import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { TenantsGetListResDto } from '../../api/models/tenants-get-list-res-dto';
import { AvatarDelete, TenantAvatarSet } from '../../shared/store/actions/avatar.action';
import { TenantsGetList } from '../../shared/store/actions/tenants.action';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { AuthState } from '../../shared/store/states/auth.state';

@Component({
  selector: 'app-tenant-update',
  templateUrl: './tenant-update.component.html',
  styleUrls: ['./tenant-update.component.scss'],
})
export class TenantUpdateComponent implements OnInit {
  iconPreviewURL: string | ArrayBuffer;
  iconFile: File;
  isLoading: boolean;
  platform: string;
  @Input() tenant: TenantsGetListResDto;
  @ViewChild('avatarImageInput') avatarImageInput: ElementRef;

  constructor(
    private store: Store,
    private activeModal: NgbActiveModal,
    private toast: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.iconPreviewURL = this.tenant.avatarUrl;
    this.getPlatform();
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  iconChange(event: Event): void {
    const reader = new FileReader();
    const target = event.target as HTMLInputElement;
    const file = target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.iconFile = file;
      this.iconPreviewURL = reader.result;
    };
  }

  removeIcon(): void {
    this.avatarImageInput.nativeElement.value = '';
    this.iconFile = null;
    this.iconPreviewURL = '';
  }

  onSubmit(): void {
    this.isLoading = true;
    this.store
      .dispatch(
        this.iconFile
          ? new TenantAvatarSet({ id: this.tenant._id, file: this.iconFile })
          : new AvatarDelete({ type: 'avatar', object: 'tenants', objectId: this.tenant._id }),
      )
      .subscribe(
        () => {
          this.closeModal('success');
          this.store.dispatch(new TenantsGetList());
          this.toast.success(this.translocoService.translate('toastr.tenant-icon-updated'));
        },
        (error) => {
          this.toast.error(error.message, this.translocoService.translate('toastr.something-went-wrong'));
        },
      );
  }

  closeModal(result?: string): void {
    if (this.iconPreviewURL !== this.tenant.avatarUrl && result !== 'success') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (confirmResult) => {
          if (confirmResult === 'isConfirmed') {
            this.activeModal.close(confirmResult);
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close(result);
    }
  }
}
