import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminUserEditComponent } from '../../../modals/admin-user-edit/admin-user-edit.component';
import { QueueGetBulkUsersInfo } from '../../store/actions/users.action';
import { UsersState } from '../../store/states/users.state';
import { AuthState } from '../../store/states/auth.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges, OnDestroy {
  @Input() userId: string = null;
  @Input() width = 30;
  @Input() height = 30;
  @Input() defaultAvatar = 'assets/img/portrait/small/default-avatar.png';
  @Input() showStatus = true;
  @Input() notShowProfile = false;
  @Input() showUserName = false;
  @Input() statusSize = 10;
  @Input() border;
  @Input() hasBorder = false;
  @Input() isBreadcrumb: boolean;
  @Input() isDropdownItem: boolean;
  @Input() isRecording = false;
  @Input() isExternal = false;

  avatarUrl: string;
  status: string = null;
  userName: string;
  prefix: string;
  isAssistant = false;

  usersStatusesUpdates$: Subscription;
  usersInfo$: Subscription;

  constructor(private store: Store, private cdr: ChangeDetectorRef, protected modalService: NgbModal) {}

  ngOnChanges() {
    this.initAvatar();
  }

  ngOnDestroy() {
    this.usersStatusesUpdates$?.unsubscribe();
    this.usersInfo$?.unsubscribe();
  }

  initAvatar() {
    this.usersInfo$?.unsubscribe();
    this.store.dispatch(new QueueGetBulkUsersInfo(this.userId));

    this.usersInfo$ = this.store
      .select(UsersState.getUsersInfo)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (this.userId && (this.isExternal || this.userId.includes('@'))) {
          this.avatarUrl = null;
          this.status = null;
          this.userName = this.userId;
          this.prefix = this.userId.charAt(0);
        } else {
          const prefix: string = res[this.userId]?.userName || this.userId;
          this.avatarUrl = res[this.userId]?.avatarUrl;
          this.status = res[this.userId]?.status;
          this.userName = res[this.userId]?.userName;
          this.prefix = prefix?.charAt(0)?.toUpperCase();
          this.cdr.detectChanges();
        }
      });

    this.store
      .select(UsersState.getTenantUsers)
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        if (users.some((user) => user._id === this.userId && user.isAssistant)) {
          this.isAssistant = true;
          this.cdr.detectChanges();
        }
      });

    this.usersStatusesUpdates$?.unsubscribe();
    this.usersStatusesUpdates$ = this.store
      .select(UsersState.getUsersStatusesUpdates)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (this.userId) {
          if (this.userId && res[this.userId]) {
            this.status = res[this.userId];
            this.cdr.detectChanges();
          }
        }
      });
  }

  openUserProfile() {
    if (!this.notShowProfile) {
      const userId = this.store.selectSnapshot(AuthState.getUser)._id;
      const modalRef = this.modalService.open(AdminUserEditComponent, {
        size: 'lg',
      });
      modalRef.componentInstance._id = this.userId;
      modalRef.componentInstance.isEditable = userId === this.userId;
    }
  }
}
