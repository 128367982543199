import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightTickets',
})
export class HighlightTicketsPipe implements PipeTransform {
  constructor() {}

  transform(text: string, chatTickets: any): string {
    if (!chatTickets) {
      return text;
    }

    if (!text) {
      return;
    }

    const boardAbbreviation = chatTickets[0]?.boardAbbreviation;
    const regex = new RegExp(boardAbbreviation + '-[0-9]+', 'g');
    const potentialTickets: string[] = Array.from((text as any).matchAll(regex), (match) => match[0]);
    const allTickets = chatTickets.map((item) => item.boardAbbreviation + '-' + item.counter);

    let result = text;
    if (potentialTickets.length > 0) {
      result = potentialTickets
        .filter((ticket) => allTickets.includes(ticket))
        .reduce((content, ticket) => this.setHighlightChatTickets(content, ticket), text);
    }

    return result;
  }

  private setHighlightChatTickets(content: string, ticket: string): string {
    return content.replace(new RegExp(ticket, 'g'), `<span class="highlight-chat-tickets">${ticket}</span>`);
  }
}
