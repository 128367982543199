import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DateTimeHelper } from '../../../utils/date-time-helper';
import { ConfigService } from '../../../services/config.service';
import { ChatsState } from '../../../store/states/chats.state';

@Component({
  selector: 'app-chat-users-list',
  templateUrl: './chat-users-list.component.html',
  styleUrls: ['./chat-users-list.component.scss'],
})
export class ChatUsersListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() platform = 'web';
  @Input() membersChat: any[];
  @Input() object: string;
  @Input() objectId: string;
  @Output() viewDirectChat = new EventEmitter<any>();

  destroy$: Subject<void> = new Subject<void>();
  config: any = {};
  members: any[];
  chatIsOpening: boolean;
  timeInterval: any;

  constructor(
    public dtHelper: DateTimeHelper,
    private actions: Actions,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.store
      .select(ChatsState.privateChatIsOpening)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.chatIsOpening = status;
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.membersChat && !!changes.membersChat.currentValue) {
      if (this.membersChat?.length > 0) {
        if (this.timeInterval) {
          clearInterval(this.timeInterval);
        }

        this.timeInterval = setInterval(() => this.displayTime(), 10000);
        this.members = this.membersChat
          .filter((user) => user.userName !== 'Quinn')
          .map((member) => {
            return {
              ...member,
              tzDiff: member.timezone ? this.dtHelper.getTzsDiffInHours(member.timezone) : 0,
              time: member.timezone ? this.dtHelper.getHoursForTz(member.timezone) : null,
            };
          });
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.timeInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  displayTime() {
    this.members = this.members
      .filter((user) => user.userName !== 'Quinn')
      .map((member) => {
        return { ...member, time: member.timezone ? this.dtHelper.getHoursForTz(member.timezone) : null };
      });
    this.cdr.markForCheck();
  }
}
