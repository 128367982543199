<ng-container *transloco="let t; read: 'modals.board-ticket'">
  <div class="d-flex align-items-center w-100">
    <input
      id="checklist-checkbox"
      class="mr-2 checklist-checkbox"
      type="checkbox"
      [formControl]="status"
      t-id="checklist-checkbox-item"
    />
    <input
      type="text"
      class="checklist-item-title checklist-create-item"
      [placeholder]="t('checklist-placeholder')"
      [formControl]="title"
      [autofocus]="true"
      t-id="checklist-list-item"
    />
  </div>
  <button
  t-id="checklist-dot-menu-item"
  type="button"
  class="checklist-item-actions btn dropdown d-flex align-items-center p-0"
  ngbDropdown
  placement="left"
  container="null"
  [autoClose]="true"
>
  <app-svg name="ellipsis" width="17" height="4" [hoverEffect]="true" class="dropdown-toggle cursor-pointer" ngbDropdownToggle></app-svg>
  <div ngbDropdownMenu class="dropdown-menu">
    <a href="javascript:;" class="dropdown-item"
       t-id="checklist-convert-into-ticket"
       *ngIf="ticket.id && !ticket.isCopyTicket"
       (click)="convertInTicket()"
    >
      <span>{{ t('checklist-convert') }}</span>
    </a>
    <a href="javascript:;" class="dropdown-item"
       t-id="checklist-delete-item"
       (click)="deleteItem()"
    >
      <span class="checklist-danger">{{ t('checklist-delete-item') }}</span>
    </a>
  </div>
</button>
</ng-container>
