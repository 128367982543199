<div [formGroup]="form" *ngIf="fieldConfig.isVisible">
  <legend>{{displayName}} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
  <fieldset class="form-group position-relative">
    <input
      t-id="board-ticket-release-versions"
      [id]="uniqFieldId"
      placeholder="{{ t('placeholder-type-value') }}"
      class="form-control release-control"
      [formControlName]="fieldConfig.title"
      [type]="fieldConfig.type"
      [attr.data-tabindex]="tabIndexes[fieldConfig.order]?.tabIndex"
      (click)="setFocusedControl(tabIndexes[fieldConfig.order]?.tabIndex)"
      (focus)="inputFocused(uniqFieldId)"
      (keydown.enter)="$event.preventDefault()"
      (keydown)="checkNumber($event)"
      *transloco="let t; read: 'modals.custom-fields'"
    />
  </fieldset>
</div>
