<ng-container *transloco="let t; read: 'modals.custom-fields'">
  <div [formGroup]="form" *ngIf="fieldConfig.isVisible">
    <legend>{{displayName}} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
    <fieldset class="form-group position-relative" [class.margin-null]="isForMultiplyObjects">
      <ng-select
              t-id="custom-board-ticket-label"
              id="customTicketLabels"
              class="labels-select"
              multiple="true"
              bindLabel="label"
              bindValue="_id"
              placeholder="{{ t('placeholder-search-or-add-new') }}"
              [items]="labels"
              [addTag]="addLabelPromise"
              [trackByFn]="trackByFn"
              [loading]="loading"
              [formControlName]="fieldConfig.title"
              [(ngModel)]="selectedLabels"
              [attr.data-tabindex]="tabIndexes[fieldConfig.order]?.tabIndex"
              (click)="setFocusedControl(tabIndexes[fieldConfig.order]?.tabIndex)"
              (focus)="inputFocused('customTicketLabels')"
      >
        <ng-template ng-tag-tmp let-search="searchTerm">
          <b>{{ t('add-new') }}</b>: {{search}}
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ item.label }}
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
      </ng-select>
    </fieldset>
    <span class="copy-ticket-label" *ngIf="isForMultiplyObjects">{{ t('select-labels-belongs-project') }}</span>
  </div>
</ng-container>
