import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {
  @Input() width = 20;
  @Input() height = 20;
  @Input() fill = '#A8AABD';
  @Input() fill2 = '#FFFFFF';
  @Input() name: any;
  @Input() hoverEffect = false;

  constructor() {}

  ngOnInit(): void {}
}
