import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { FileUploader } from 'ng2-file-upload';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { environment } from '../../../../environments/environment';
import { defaultSortParams, sortKey, SortParams } from '../../data/sort-types';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { ConfigService } from '../../services/config.service';
import { RecordService } from '../../services/record.service';
import {
  DocumentSaveInDataroom,
  DocumentsFolderCreate,
  DocumentsGet,
  DocumentsSort,
} from '../../store/actions/documents.action';
import { AuthState } from '../../store/states/auth.state';
import { DocumentsState } from '../../store/states/documents.state';
import { ProjectsState } from '../../store/states/projects.state';
import { SpacesState } from '../../store/states/spaces.state';
import { FilesHelper } from '../../utils/files-helper';

@Component({
  selector: 'app-chat-dataroom-location',
  templateUrl: './dataroom-location.component.html',
  styleUrls: ['./dataroom-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataroomLocationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() object: string;
  @Input() objectId: string;
  @Input() file;
  @Input() platform = 'web';
  @Input() messageType: string;
  @Output() closeDataRoomModal = new EventEmitter();
  @ViewChild('folderCreateModal') folderCreateModal: TemplateRef<any>;

  destroy$: Subject<void> = new Subject<void>();
  config: any = {};
  documents: any[];
  selectedDocument: any;
  users: Observable<any[]>;
  roles: Observable<any[]>;
  spaces: Observable<any>;
  projects: Observable<any>;
  currentFolder: Record<string, any>;
  newFileName: string;
  isSavingFile = false;
  newFolderName: string;
  folderModalRef: NgbModalRef;
  uploader: FileUploader;
  uploaderEndpoint = `${environment.api_root}/files`;
  addFolderPromise: (label: any) => Promise<any>;
  mainFolder: Record<string, any>;
  isLoading$: Subject<boolean> = new Subject<boolean>();
  folderSteps: Record<string, any>[] = [];

  constructor(
    public filesHelper: FilesHelper,
    readonly cdr: ChangeDetectorRef,
    private store: Store,
    private modal: NgbModal,
    private toastr: ToastrService,
    private configService: ConfigService,
    private screenRecord: RecordService,
    private localStorageService: LocalStorageService,
    private checkPermissionPipe: CheckPermissionPipe,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    if (this.messageType === 'group') {
      this.objectId = null;
    }

    this.clearLinkedData();
    this.initFileUploader();

    this.addFolderPromise = (label) => {
      return new Promise(() => {
        this.newFolderName = label;
        this.folderModalRef = this.modal.open(this.folderCreateModal, {
          size: 'md',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'new-folder-create-modal',
        });
      });
    };

    this.store
      .select(DocumentsState.getDocuments)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (this.objectId) {
          this.documents = res.filter((document) => !document.originalFileName && !document.link && !document.content);
        }
      });

    this.store
      .select(DocumentsState.getCurrentFolder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFolder) => {
        if (currentFolder?.type === 'public' && !currentFolder?.parentId) {
          this.mainFolder = currentFolder;
        }
      });

    this.spaces = this.store.select(SpacesState.getLoadedSpaces).pipe(
      takeUntil(this.destroy$),
      map((result) =>
        result.filter((item) => this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate')),
      ),
    );

    this.projects = combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([spaces, projects]) =>
        projects
          .filter((item) => this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'))
          .map((item) => this.addSpaceAvatarForProjects(spaces, item)),
      ),
    );

    this.newFileName = this.filesHelper.getFileNameWithoutExtension(this.file.originalFileName);

    this.initializeDocumentSort();

    if (!this.object) {
      this.presetObject();
    }

    this.updateCurrentFolder(null);
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  initFileUploader(): void {
    this.uploader = new FileUploader({
      authToken: 'Bearer ' + this.store.selectSnapshot(AuthState.getAccessToken),
      autoUpload: false,
      isHTML5: true,
      url: this.uploaderEndpoint,
    });
  }

  updateCurrentFolder(_id: string = null) {
    if (this.object && this.objectId) {
      this.isLoading$.next(true);
      this.store
        .dispatch(
          new DocumentsGet({
            _id: _id ? _id : undefined,
            object: this.object,
            objectId: this.objectId,
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.currentFolder = this.store.selectSnapshot(DocumentsState.getCurrentFolder);
            this.isLoading$.next(false);
            this.selectedDocument = this.currentFolder['_id'];
          },
          (err) => {
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
            this.isLoading$.next(false);
            this.cdr.detectChanges();
          },
        );
    } else {
      this.documents = [];
    }
  }

  breadcrumbClicked(breadcrumb) {
    this.updateCurrentFolder(breadcrumb._id);
  }

  handleFolderChange(document) {
    this.handleFolderSteps(document);
    if (document && !document.originalFileName && !document.link) {
      this.updateCurrentFolder(document._id);
    }
  }

  private addSpaceAvatarForProjects(spaces, item) {
    const space = spaces.find((s) => s?._id === item?.spaceId);

    if (space) {
      item = { ...item, space: { avatarUrl: space.avatarUrl } };
    }

    return item;
  }

  handleSaveSubmit() {
    this.isSavingFile = true;
    this.cdr.detectChanges();

    this.store
      .dispatch(
        new DocumentSaveInDataroom({
          body: {
            folderId: this.currentFolder['_id'],
            fileId: this.file['_id'],
            fileName: this.newFileName,
            fileSource: this.messageType === 'thread' ? 'threadsFiles' : 'chatsFiles',
            type: 'public',
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.closeDataRoomModal.emit();
          this.toastr.success(
            this.translocoService.translate('toastr.file-successfully-saved'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.isSavingFile = false;
          this.cdr.detectChanges();
        },
        (err) => {
          this.toastr.error(err?.message, this.translocoService.translate('toastr.title-error'));
          this.isSavingFile = false;
          this.cdr.detectChanges();
        },
      );
  }

  handleUploadSubmit(): void {
    this.isSavingFile = true;
    this.uploader.addToQueue([this.file.file]);
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (_, form) => {
      form.append('folderId', this.currentFolder['_id']);
      form.append('type', 'public');
    };

    this.uploader.onSuccessItem = () => {
      this.toastr.success(
        this.translocoService.translate('toastr.record-successfully-uploaded'),
        this.translocoService.translate('toastr.title-success'),
      );
      this.isSavingFile = false;
      this.screenRecord.clearRecord();
      this.uploader.clearQueue();
      this.closeDataRoomModal.emit();
    };

    this.uploader.onErrorItem = () => {
      this.uploader.clearQueue();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-uploading-file'),
        this.translocoService.translate('toastr.title-error'),
      );
    };

    this.uploader.uploadAll();
  }

  closeCreateFolderModal() {
    this.folderModalRef.close();
  }

  handleFolderCreateSubmit() {
    this.selectedDocument = null;
    this.isLoading$.next(true);
    this.cdr.detectChanges();

    this.store
      .dispatch(
        new DocumentsFolderCreate({
          body: {
            object: this.object,
            objectId: this.objectId,
            name: this.newFolderName,
            parentId: this.currentFolder?._id,
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          const document = { _id: this.store.selectSnapshot(DocumentsState.getLastCreatedFolderId) };
          this.handleFolderChange(document);
          this.documents = [];
          this.closeCreateFolderModal();
          this.isLoading$.next(false);
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.isLoading$.next(false);
          this.cdr.detectChanges();
        },
      );
  }

  private initializeDocumentSort(): void {
    const sortParams: SortParams = this.localStorageService.get(sortKey) || defaultSortParams;
    this.store.dispatch(new DocumentsSort(sortParams));
  }

  objectSelected(isSpaces: boolean): void {
    this.object = isSpaces ? 'spaces' : 'projects';
    this.documents = [];
    this.selectedDocument = null;
    this.objectId = null;
  }

  updateObjectsData(object, objectId): void {
    this.object = object;
    this.objectId = objectId;
    this.updateCurrentFolder();
  }

  clearLinkedData(): void {
    if (this.messageType === 'direct') {
      this.objectId = null;
      this.object = null;
    }
  }

  closeModal(): void {
    this.clearLinkedData();
    this.closeDataRoomModal.emit();
  }

  presetObject(): void {
    if (this.messageType === ('direct' || 'group')) {
      this.object = 'spaces';
    }
  }

  handleFolderSteps(document) {
    if (document) {
      this.folderSteps.push(this.currentFolder);
    } else if (!document && this.folderSteps.length) {
      const prevStep = this.folderSteps.pop();
      this.updateCurrentFolder(prevStep._id);
    } else if (this.currentFolder._id !== this.mainFolder._id) {
      this.updateCurrentFolder(this.mainFolder._id);
    }
  }
}
