import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ConfigService } from '../../services/config.service';

import { SortDirection, SortParams } from '../../data/sort-types';

@Component({
  selector: 'app-sorting-toggle',
  templateUrl: './sorting-toggle.component.html',
  styleUrls: ['./sorting-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingToggleComponent {
  @Input() direction: SortDirection;
  @Input() sortByList = [];
  @Input() sortBy: string;
  @Output() sortChange = new EventEmitter<SortParams>();
  @ViewChild(NgSelectComponent) select: NgSelectComponent;
  config$ = this.configService.templateConf$;

  constructor(private configService: ConfigService, private readonly cdr: ChangeDetectorRef) {}

  toggleDirection(): void {
    this.direction = this.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    const sortParams = { sortBy: this.sortBy, direction: this.direction, updateDirection: true };
    this.sortChange.emit(sortParams);
    this.cdr.detectChanges();
  }

  toggleSortBy(): void {
    const sortParams = { sortBy: this.sortBy, direction: this.direction };
    this.sortChange.emit(sortParams);
  }
}
