<div
	class="create-event-modal"
	[ngClass]="{
    'create-event-modal-mobile': platform !== 'web',
    'create-event-modal-mobile-invited': invitedUser
  }"
  *transloco="let t; read: 'modals.calendar-event'"
>
	<div class="modal-header">
		<h5 class="modal-title mb-0">{{ modalData?.displayName }}</h5>
		<button type="button" class="close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
		</button>
	</div>

	<ng-scrollbar class="scroll-event scrollable-container" thumbClass="white-scrollbars">
		<div class="modal-body">
			<div id="tab-key-active-controls" class="modal-body__event-wrapper" *ngIf="!!modalData?.event">
				<div class="col-xl-7 add-event-wrapper">
          <div class="d-flex align-items-start justify-content-between mt-0" [class.header-mobile]="checkIsMobile">
            <div
              class="d-flex align-items-start position-relative"
              [class.calendar-wrapper]="modalData?.event.object !== 'spaces' && modalData?.event.object !== 'projects'"
            >
              <ng-select
                *ngIf="checkIsPersonal"
                t-id="calendar-event-select-event-type"
                id="eventType"
                class="event-type-select mr-2"
                placeholder="{{ t('placeholder-select-event-type') }}"
                bindLabel="title"
                bindValue="object"
                [items]="eventTypes"
                [clearable]="false"
                [searchable]="false"
                [closeOnSelect]="true"
                [(ngModel)]="modalData?.event.object"
                (change)="changeSelectedEventType($event)"
                (focus)="inputFocused('eventType')"
              ></ng-select>

              <div class="form-group calendar-select-wrapper">
                <!-- fake users select control -->
                <div class="position-absolute overflow-hidden" style="width:0; height:0" [attr.data-tabindex]="'tab-index-users'">
                  <div>
                    <div>
                      <div><input type="text"></div>
                    </div>
                  </div>
                </div>

                <!-- SPACES NG-SELECT -->
                <ng-select
                  *ngIf="checkIsPersonal"
                  class="calendar-dropdown customized-select"
                  [class.d-none]="modalData?.event.object !== 'spaces'"
                  [items]="spaces | async"
                  id="eventSpace"
                  name="event-spaceId"
                  placeholder="{{ t('placeholder-select-space') }}"
                  bindLabel="spaceName"
                  bindValue="_id"
                  appendTo="body"
                  required
                  [clearOnBackspace]="true"
                  [readonly]="invitedUser"
                  [searchable]="platform === 'web'"
                  [attr.data-tabindex]="'tab-index-spaces'"
                  [(ngModel)]="modalData?.event.spaceId"
                  (change)="attachUsersList({ object: 'spaces', objectId: $event?._id })"
                  (focus)="inputFocused('eventSpace')"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="sp-list-item">
                      <app-space-avatar [containerWidth]="24" [containerHeight]="24" [space]="item" class="sp-list-item-avatar mr-1"></app-space-avatar>
                      <span>{{ item.spaceName }}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <div class="sp-list-item">
                      <app-space-avatar [containerWidth]="24" [containerHeight]="24" [space]="item" class="sp-list-item-avatar mr-1"></app-space-avatar>
                      <span>{{ item.spaceName }}</span>
                    </div>
                  </ng-template>
                </ng-select>

                <!-- PROJECTS NG-SELECT -->
                <ng-select
                  t-id="calendar-event-select-project"
                  class="calendar-dropdown customized-select"
                  [class.d-none]="modalData?.event.object !== 'projects'"
                  [items]="projects | async"
                  id="eventProject"
                  name="event-projectId"
                  placeholder="{{ t('placeholder-select-project') }}"
                  bindLabel="projectName"
                  bindValue="_id"
                  appendTo="body"
                  [readonly]="invitedUser"
                  [searchable]="platform === 'web'"
                  [attr.data-tabindex]="'tab-index-projects'"
                  [(ngModel)]="modalData?.event.projectId"
                  (change)="attachUsersList({ object: 'projects', objectId: $event?._id })"
                  (focus)="inputFocused('eventProject')"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="sp-list-item">
                      <app-space-avatar [containerWidth]="24" [containerHeight]="24" [space]="item.space" class="sp-list-item-avatar mr-1"></app-space-avatar>
                      <app-project-avatar [containerWidth]="24" [containerHeight]="24" [project]="item" class="sp-list-item-avatar mr-1"></app-project-avatar>
                      <span>{{ item.projectName }}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="sp-list-item">
                      <app-space-avatar [containerWidth]="24" [containerHeight]="24" [space]="item.space" class="sp-list-item-avatar mr-1"></app-space-avatar>
                      <app-project-avatar [containerWidth]="24" [containerHeight]="24" [project]="item" class="sp-list-item-avatar mr-1"></app-project-avatar>
                      <span>{{ item.projectName }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
            </div>

            <div *ngIf="!!modalData?.event" class="d-flex align-items-center ml-2">
              <div class="creator-title">{{ t('creator') }}</div>
              <div class="creator-name mx-2">{{ eventCreatorName }}</div>
              <app-avatar [userId]="eventCreator" [width]="36" [height]="36"></app-avatar>
            </div>
          </div>

					<div class="add-event-wrapper__block">
						<div class="add-event-wrapper__block__event-title position-relative">
							<textarea
								t-id="calendar-event-title"
								id="eventTitle"
								class="form-control title-control"
								[class.title-control--light]="config?.layout.variant === 'Light'"
								[class.title-control--dark]="config?.layout.variant === 'Dark'"
                placeholder="{{ t('placeholder-event-name') }}"
                required
                appTextareaAutoresize
                [appFocus]="!modalData?.event.title && platform === 'web'"
                [readonly]="invitedUser"
                [attr.data-tabindex]="tabIndexes[0]?.tabIndex"
                [(ngModel)]="modalData?.event.title"
                (keyup)="refresh.next()"
                (keydown.enter)="$event.preventDefault()"
                (click)="controlClicked(tabIndexes[0]?.tabIndex)"
                (focus)="inputFocused('eventTitle')"
							></textarea>

							<div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
						</div>
					</div>

					<div class="add-event-wrapper__block">
						<span>{{ t('description-or-attachments') }}</span>
						<div class="add-event-wrapper__block__description position-relative">
              <div (click)="editorMsClick($event)" class="w-100">
								<quill-editor
									id="eventDescription"
                  #descriptionQuill
									class="event-description"
                  bounds="self"
                  [class.show-toolbar]="toggleDescriptionEditVisibility || modalData?.event?.description?.length === 0"
									placeholder="{{ t(invitedUser ? 'no-description' : 'add-event-description') }}"
									[modules]="editorModules"
                  [attr.data-tabindex]="tabIndexes[1]?.tabIndex"
									[(ngModel)]="modalData?.event.description"
                  (onFocus)="toggleDescriptionEditVisibility = true;inputFocused('eventDescription')"
                  (onBlur)="blurEditor($event, 'description')"
									(keyup)="refresh.next()"
                  (onEditorCreated)="editorDescriptionCreated($event)"
									(click)="handleClickInEditor($event, tabIndexes[1]?.tabIndex)"
								></quill-editor>
							</div>

              <div *ngIf="toggleDescriptionEditVisibility" class="d-flex align-items-center justify-content-between">
                <div class="font-small-1">{{ t('max-image-files-size', { size: '50' }) }}</div>
                <button
                  *ngIf="modalData?.event?.description?.length > 0"
                  class="btn btn-sm btn-subtle"
                  type="button"
                  (click)="toggleDescriptionEditVisibility = false;"
                >{{ t('btn-close') }}</button>
              </div>

							<div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
						</div>
					</div>

					<div class="add-event-wrapper__block">
						<span>{{ t('place') }}</span>
						<div class="add-event-wrapper__block__location position-relative">
							<div (click)="editorMsClick($event)">
								<quill-editor
									id="eventPlace"
									class="event-place"
                  bounds="self"
									placeholder="{{ t(invitedUser ? 'no-place' : 'add-place-or-link') }}"
									[modules]="editorCustomModules"
                  [attr.data-tabindex]="tabIndexes[2]?.tabIndex"
									[(ngModel)]="modalData?.event.place"
                  (onEditorCreated)="editorDescriptionCreated($event)"
									(keyup)="refresh.next()"
									(click)="controlClicked(tabIndexes[2]?.tabIndex)"
                  (onFocus)="inputFocused('eventPlace')"
								></quill-editor>
							</div>

							<div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
						</div>
					</div>

					<div class="add-event-wrapper__block add-event-wrapper-call">
            <div *ngIf="!modalData?.event.videoCallId" class="custom-control custom-switch" t-id="calendar-event-add-call">
              <input
                id="videoCallSwitch"
                class="custom-control-input"
                type="checkbox"
                [checked]="modalData?.event.addCall"
                [(ngModel)]="modalData?.event.addCall"
              />
              <label class="custom-control-label" for="videoCallSwitch" [class.switch-ctrl-focused]="sendEmailCtrlFocused">
                {{ t('add-video-call') }}
              </label>
            </div>

            <div *ngIf="!!modalData?.event.videoCallId" class="d-flex align-items-center justify-content-between w-100">
              <div class="d-flex align-items-center">
                <button
                  class="btn btn-outline d-flex align-items-center mr-2"
                  [class.ripple-button]="currentCallRoom && currentCallRoom.videoCallId === modalData.event.videoCallId"
                  (click)="openVideoCall()"
                >
                  <app-svg name="video" class="mr-1" [height]="10" [width]="15"></app-svg>
                  <span>{{ t(currentCallRoom && currentCallRoom.videoCallId === modalData.event.videoCallId ? 'btn-join-call' : 'btn-start-call') }}</span>
                </button>

                <ng-container *ngTemplateOutlet="videoCallParticipants"></ng-container>
              </div>

              <button class="btn btn-subtle d-flex align-items-center" (click)="copyVideoCallLink()">
                <app-svg name="copy" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
                <span>{{ t('btn-copy-link') }}</span>
              </button>
            </div>
					</div>

          <div class="add-event-wrapper__block" *ngIf="modalData.event._id">
            <span>{{ t('created-at') }}</span>
            <div class="event-date">
              {{ modalData.event.created_at | date : 'dd.MM.yyyy - HH:mm' : currTzAbbr }}
            </div>
          </div>

          <div class="add-event-wrapper__block" *ngIf="modalData.event._id">
            <span>{{ t('updated-at') }}</span>
            <div class="event-date">
              {{ modalData.event.updated_at | date : 'dd.MM.yyyy - HH:mm' : currTzAbbr }}
            </div>
          </div>
				</div>

				<div class="col-xl-5 add-event-wrapper">
          <div class="add-event-wrapper__block mt-0" *ngIf="checkIsPersonal">
            <span>{{ t('guests') }}</span>
            <div class="add-event-wrapper__block__guests align-items-center">
              <ng-select
                id="eventGuests"
                class="guests-select"
                name="event-guests"
                placeholder="{{ t('add-or-invite-members') }}"
                bindLabel="userName"
                bindValue="_id"
                multiple="true"
                appendTo="body"
                [items]="users"
                [addTag]="addGuestPromise"
                [readonly]="invitedUser"
                [searchable]="true"
                [attr.data-tabindex]="tabIndexes[3]?.tabIndex"
                [(ngModel)]="modalData?.event.guests"
                (click)="controlClicked(tabIndexes[3]?.tabIndex)"
                (focus)="inputFocused('eventGuests')"
              >
                <ng-template ng-tag-tmp let-search="searchTerm">
                  <b>{{ t('invite') }}</b>: {{search}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear" id="guests_selected">
                  <div class="d-flex align-items-center">
                    <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
                    <div class="ml-1">
                      {{ item.userName }}
                    </div>
                    <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index" id="guests_select">
                  <div class="d-flex align-items-center">
                    <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
                    <span class="ml-2">{{ item.userName }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="add-event-wrapper__block flex-row" *ngIf="checkIsPersonal">
            <div class="custom-control custom-switch">
              <input
                id="sendEmailSwitch"
                class="custom-control-input"
                type="checkbox"
                [readonly]="invitedUser"
                [checked]="modalData?.event.sendEmail"
                [(ngModel)]="modalData?.event.sendEmail"
                [attr.data-tabindex]="tabIndexes[4]?.tabIndex"
                (focus)="switchFocused('sendEmail', true)"
                (blur)="switchFocused('sendEmail', false)"
                (keyup.enter)="switchPressKey('sendEmail')"
                (keyup.space)="switchPressKey('sendEmail')"
                (click)="controlClicked(tabIndexes[4]?.tabIndex)"
              />
              <label class="custom-control-label" for="sendEmailSwitch" [class.switch-ctrl-focused]="sendEmailCtrlFocused" t-id="calendar-event-send-email-switch">
                {{ t('send-email-invitation') }}
              </label>
            </div>
          </div>

					<div class="add-event-wrapper__block">
						<span>{{ t('time') }}</span>
						<div class="add-event-wrapper__block__time position-relative">
              <div class="flex-column">
                <div class="d-flex justify-content-between w-100">
                  <span class="pt-1">{{ t('start-date') }}</span>
                  <app-date-time-picker
                    t-id="calendar-event-date-picker-start"
                    name="starts-at"
                    placeholder="{{ t('placeholder-not-set') }}"
                    startOrEnd="start"
                    [mobile]="platform === 'mobile'"
                    [mobileCalendarEvent]="platform === 'mobile'"
                    [allDay]="modalData?.event.allDay"
                    [invitedUser]="invitedUser"
                    [tabIndexes]="['tab-index-day-date-1','tab-index-day-time-1']"
                    [(ngModel)]="modalData?.event.start"
                    (ngModelChange)="refresh.next()"
                    (setStartTimeInputsValid)="setStartTimeInputValid($event)"
                    (setEndTimeInputsValid)="setEndTimeInputValid($event)"
                    (clicked)="controlClicked($event)"
                  ></app-date-time-picker>
                </div>

                <div class="d-flex justify-content-between w-100">
                  <span class="pt-1">{{ t('end-date') }}</span>
                  <app-date-time-picker
                    t-id="calendar-event-date-picker-stop-calendar"
                    name="ends-at"
                    placeholder="{{ t('placeholder-not-set') }}"
                    startOrEnd="end"
                    [mobile]="platform === 'mobile'"
                    [mobileCalendarEvent]="platform === 'mobile'"
                    [allDay]="modalData?.event.allDay"
                    [invitedUser]="invitedUser"
                    [tabIndexes]="['tab-index-day-date-2','tab-index-day-time-2']"
                    [isPastDateDisabled]="true"
                    [pastDateStartFrom]="modalData?.event.start"
                    [(ngModel)]="modalData?.event.end"
                    (ngModelChange)="refresh.next()"
                    (setEndTimeInputsValid)="setEndTimeInputValid($event)"
                    (clicked)="controlClicked($event)"
                    (setIsPastDate)="setIsPastDate($event)"
                  ></app-date-time-picker>
                </div>

                <div class="add-event-wrapper__block__time__title position-relative">
                  <div t-id="calendar-event-time-switch" class="custom-control custom-switch">
                    <input
                      id="timeSwitch"
                      class="custom-control-input"
                      type="checkbox"
                      [readonly]="invitedUser"
                      [checked]="modalData?.event.allDay"
                      [attr.data-tabindex]="tabIndexes[9]?.tabIndex"
                      [(ngModel)]="modalData?.event.allDay"
                      (focus)="switchFocused('allDay', true)"
                      (blur)="switchFocused('allDay', false)"
                      (keyup.enter)="switchPressKey('allDay')"
                      (keyup.space)="switchPressKey('allDay')"
                      (ngModelChange)="refresh.next()"
                      (change)="allDayChange()"
                      (click)="controlClicked(tabIndexes[9]?.tabIndex)"
                    />
                    <label class="custom-control-label" for="timeSwitch" [class.switch-ctrl-focused]="allDayCtrlFocused">
                      {{ t('all-day') }}
                    </label>
                  </div>

                  <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
                </div>
              </div>

              <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
            </div>
					</div>

          <div class="add-event-wrapper__block">
            <span>{{ t('timezone') }}</span>
            <div class="flex-column">
              <ng-select
                *ngIf="modalData?.action === 'Add new event' || userData._id === modalData?.event.userId"
                [items]="timezones"
                id="eventTimezone"
                name="timezone"
                placeholder="{{ t('select-timezone') }}"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                required
                [attr.data-tabindex]="tabIndexes[10]?.tabIndex"
                [(ngModel)]="modalData?.event.timezone"
                (click)="controlClicked(tabIndexes[10]?.tabIndex)"
                (focus)="inputFocused('eventTimezone')"
              ></ng-select>
              <div *ngIf="modalData?.action === 'Add new event' || userData._id === modalData?.event.userId" class="mt-1">
                <small>{{ t('local-time-zone') }}: <b>{{ dtHelper.getTimezoneName(tz) }}</b></small>
                  <button *ngIf="modalData?.event.timezone !== tz" class="btn btn-xs btn-solid set-tz ml-1 h-100" (click)="setCurrentTz()">
                    Set this
                  </button>
              </div>
              <div *ngIf="modalData?.event.userId && userData._id !== modalData?.event.userId" class="mt-1">
                <span *ngIf="modalData?.event.timezone">{{ dtHelper.getTimezoneName(modalData?.event.timezone) }}</span>
                <span *ngIf="!modalData?.event.timezone">{{ t('timezone-not-set') }}</span>
              </div>
            </div>

            <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
          </div>

					<div class="add-event-wrapper__block">
						<span>{{ t('repeat') }}</span>
						<div class="add-event-wrapper__block__repeat align-items-center">
							<div>
								<ng-select
                  t-id="calendar-event-repeat-select"
                  id="eventRepeat"
                  name="event-repeat"
                  placeholder="{{ t('placeholder-select-value') }}"
									bindLabel="name"
									bindValue="id"
									appendTo="body"
                  [items]="repeatTypes"
									[searchable]="platform !== 'mobile'"
									[clearable]="false"
                  [readonly]="invitedUser || notRepeatableEvent"
                  [attr.data-tabindex]="tabIndexes[11]?.tabIndex"
                  [(ngModel)]="modalData?.event.repeat"
									(change)="changeRepetitionItem()"
									(click)="controlClicked(tabIndexes[11]?.tabIndex)"
                  (focus)="inputFocused('eventRepeat')"
								></ng-select>
							</div>
						</div>
					</div>

					<div class="add-event-wrapper__block" [class.d-none]="!showRepeatEventAtControl">
						<span>{{ t('stop-repeat') }}</span>
						<div class="add-event-wrapper__block__repeat align-items-center">
							<div>
								<app-date-time-picker
									name="stop-repeat-at"
									placeholder="{{ t('placeholder-not-set') }}"
									startOrEnd="start"
									[mobile]="platform === 'mobile'"
									[mobileCalendarEvent]="platform === 'mobile'"
                  [readonly]="invitedUser"
                  [dateControlOnly]="true"
									[tabIndexes]="['tab-index-stop-repeat-at']"
                  [(ngModel)]="modalData?.event.stopRepeatAt"
                  (ngModelChange)="refresh.next()"
                  (clicked)="controlClicked($event)"
								></app-date-time-picker>
							</div>
						</div>
					</div>

					<div class="d-flex position-relative mt-1" *ngIf="workdaysToggleButton">
						<div t-id="calendar-event-only-workday" class="custom-control custom-switch">
							<input
                id="workdaysSwitch"
                class="custom-control-input"
								type="checkbox"
                [readonly]="invitedUser"
								[checked]="modalData?.event.workdays"
                [attr.data-tabindex]="tabIndexes[13]?.tabIndex"
                [(ngModel)]="modalData?.event.workdays"
                (focus)="switchFocused('onlyWorkdays', true)"
                (blur)="switchFocused('onlyWorkdays', false)"
                (keyup.enter)="switchPressKey('onlyWorkdays')"
                (keyup.space)="switchPressKey('onlyWorkdays')"
                (ngModelChange)="refresh.next()"
								(click)="controlClicked(tabIndexes[13]?.tabIndex)"
							/>
							<label class="custom-control-label" for="workdaysSwitch" [class.switch-ctrl-focused]="onlyWorkdaysCtrlFocused">
                {{ t('only-workdays') }}
              </label>
						</div>
						<div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
					</div>

					<div class="add-event-wrapper__block">
						<span>{{ t('remind') }}</span>
						<div class="add-event-wrapper__block__remind align-items-center">
							<div>
								<ng-select
                  t-id="calendar-event-remind-select"
                  [items]="reminderTypes"
                  id="eventReminder"
                  name="event-reminder"
                  placeholder="{{ t('placeholder-interval') }}"
									bindLabel="name"
									bindValue="id"
									appendTo="body"
                  [clearable]="false"
                  [readonly]="invitedUser"
									[searchable]="platform !== 'mobile'"
                  [attr.data-tabindex]="tabIndexes[14]?.tabIndex"
                  [(ngModel)]="modalData?.event.reminder"
                  (change)="handleStopReminderButton($event)"
                  (click)="controlClicked(tabIndexes[14]?.tabIndex)"
                  (focus)="inputFocused('eventReminder')"
								></ng-select>
							</div>
						</div>

						<!-- CHAT NOTIFICATION BLOCK -->
						<div class="my-3 d-none" [class.d-flex]="modalData?.event.object !== 'users' && showRemindChatControl">
							<div class="custom-control custom-switch" t-id="calendar-event-remind-in-chat">
                <input
                  id="chatNotificationSwitch"
                  class="custom-control-input"
                  type="checkbox"
                  [readonly]="invitedUser"
                  [checked]="modalData?.event.remindAsChatMessage"
                  [attr.data-tabindex]="tabIndexes[15]?.tabIndex"
                  [(ngModel)]="modalData?.event.remindAsChatMessage"
                  (focus)="switchFocused('notification', true)"
                  (blur)="switchFocused('notification', false)"
                  (keyup.enter)="switchPressKey('notification')"
                  (keyup.space)="switchPressKey('notification')"
                  (ngModelChange)="refresh.next()"
                  (click)="controlClicked(tabIndexes[15]?.tabIndex)"
                />
                <label class="custom-control-label" for="chatNotificationSwitch" [class.switch-ctrl-focused]="notificationCheckboxCtrFocused">
                  {{ t('remind-chat-message') }}
                </label>
              </div>
						</div>

						<!-- STOP REMINDER BLOCK BLOCK -->
						<!-- TODO: frozen by the client, remove it later -->
						<!-- <div class="my-3" [class.d-none]="userData._id !== modalData?.event.userId && !showStopReminderButton"> -->
						<!-- <div class="my-3" [class.d-none]="!showStopReminderButton">
              <button class="btn btn-danger" type="button" [attr.data-tabindex]="tabIndexes[16]?.tabIndex" (click)="handleStopReminder()">
                Stop Reminder
              </button>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</ng-scrollbar>

	<div class="modal-footer d-flex align-items-center" *ngIf="!!modalData?.event">
		<div *ngIf="invitedUser" class="modal-footer__invited m-0">
			<button class="btn-fake-button" [attr.data-tabindex]="tabIndexesDeclineButtons[4]?.tabIndex"></button>
			<button
				class="btn btn-subtle btn-decline"
				[class.active-status]="getOwnStatus(modalData?.event.calendarEventMembers)?.status === 'declined'"
        [attr.data-tabindex]="tabIndexesDeclineButtons[3]?.tabIndex"
				(click)="setEventStatus({ status: 'declined', id: modalData?.event._id })"
			>
        {{ t('btn-decline') }}
			</button>
			<button
				class="btn btn-subtle btn-maybe"
				[class.active-status]="getOwnStatus(modalData?.event.calendarEventMembers)?.status === 'maybe'"
        [attr.data-tabindex]="tabIndexesDeclineButtons[2]?.tabIndex"
				(click)="setEventStatus({ status: 'maybe', id: modalData?.event._id })"
			>
        {{ t('btn-maybe') }}
			</button>
			<button
				class="btn btn-subtle btn-accept"
				[class.active-status]="getOwnStatus(modalData?.event.calendarEventMembers)?.status === 'accepted'"
        [attr.data-tabindex]="tabIndexesDeclineButtons[1]?.tabIndex"
				(click)="setEventStatus({ status: 'accepted', id: modalData?.event._id })"
			>
        {{ t('btn-accept') }}
			</button>
			<button
				class="btn btn-subtle btn-cancel"
        type="button"
        [attr.data-tabindex]="tabIndexesDeclineButtons[0]?.tabIndex"
				(click)="close()"
			>
        {{ t('btn-cancel') }}
			</button>
		</div>

		<button
			*ngIf="userData._id === modalData?.event.userId"
			t-id="calendar-event-delete-action"
			class="svg-btn"
      type="button"
			ngbTooltip="{{ t('tooltip-delete-event') }}"
      [attr.data-tabindex]="tabIndexes[19]?.tabIndex"
			(click)="deleteEvent(modalData?.event)"
		>
      <app-svg name="trash" width="14" height="16"></app-svg>
		</button>

		<button
			*ngIf="!invitedUser"
			t-id="calendar-event-canel-action"
			class="btn btn-subtle btn-cancel"
      type="button"
      [attr.data-tabindex]="tabIndexes[18]?.tabIndex"
			(click)="close()"
		>
      {{ t('btn-close-modal') }}
		</button>

		<button
      *ngIf="modalData?.action === 'Add new event'"
			t-id="calendar-event-create-action"
			class="btn btn-solid btn-add"
      type="button"
      [disabled]="isPastDate"
      [attr.data-tabindex]="tabIndexes[17]?.tabIndex"
			(click)="addEventSubmit(modalData.event)"
		>
      {{ t('btn-create') }} test
		</button>

		<button
			*ngIf="userData._id === modalData?.event.userId"
			t-id="calendar-event-save-action"
			class="btn btn-solid btn-save"
      type="button"
      [disabled]="isPastDate"
      [attr.data-tabindex]="tabIndexes[16]?.tabIndex"
			(click)="editEventSubmit(modalData.event)"
		>
      {{ t('btn-save') }}
		</button>
	</div>
</div>

<ng-template #videoCallParticipants>
  <div
    *ngIf="currentCallRoom && currentCallRoom.videoCallId === modalData.event.videoCallId && currentCallRoom.callParticipants.length > 0"
    class="video-call_participants d-flex align-items-center mr-2"
  >
    <ng-container *ngFor="let member of currentCallRoom?.callParticipants; let i = index">
      <div *ngIf="i < configService.MAX_LINED_UP_BOARD_MEMBERS-1" class="member">
        <app-avatar [userId]="member.userId" [width]="33" [height]="33" [isExternal]="member.isExternal"></app-avatar>
      </div>
    </ng-container>

    <div
      *ngIf="currentCallRoom?.callParticipants.length > configService.MAX_LINED_UP_BOARD_MEMBERS-1"
      ngbDropdown
      class="d-inline-block"
      autoClose="outside"
    >
      <div
        ngbDropdownToggle
        data-toggle="dropdown"
        class="dropdown-toggle d-flex justify-content-center align-items-center"
        [ngClass]="{
          'more-members-button-light': configService.templateConf?.layout.variant === 'Light',
          'more-members-button-dark': configService.templateConf?.layout.variant === 'Dark'
        }"
      >
        +{{ currentCallRoom?.callParticipants.length - (configService.MAX_LINED_UP_BOARD_MEMBERS-1) }}
      </div>
      <div ngbDropdownMenu>
        <span
          *ngFor="let moreMember of currentCallRoom?.callParticipants
            | slice:configService.MAX_LINED_UP_BOARD_MEMBERS-1:currentCallRoom?.callParticipants.length"
        >
          <button ngbDropdownItem class="w-100">
            <span class="d-flex">
              <app-avatar
                class="dropdown-avatar"
                [userId]="moreMember.userId"
                [width]="25"
                [height]="25"
                [ngClass]="'mr-2'"
                [showUserName]="true"
                [isDropdownItem]='true'
                [isExternal]="moreMember.isExternal"
              ></app-avatar>
            </span>
          </button>
        </span>
      </div>
    </div>
  </div>
</ng-template>
