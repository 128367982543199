<div class="modal-body space-delete-info" [class.layout-dark-info]="config?.layout.variant === 'Dark'"
     t-id="space-delete-info-modal" *transloco="let t; read: 'modals.space'">
  <img src="assets/img/svg/social-media.svg" alt="social">
  <div class="space-delete-info__title">{{ space ? t('title-space-deleted') : t('title-project-deleted') }}</div>

  <div class="space-delete-info__description"
       [innerHTML]="space ? t('delete-space-description', { value: '<b>support@teamplate.io</b>' }) : t('delete-project-description', { value: '<b>support@teamplate.io</b>' })">
  </div>
  <button class="btn btn-solid btn-save w-100" type="button" (click)="close()" t-id="space-delete-info-close-btn">
    {{ t('btn-close') }}
  </button>
</div>
