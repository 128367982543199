export enum OperationIds {
  CalendarEventGetList = 'calendarEventGetList',
  ActionsLogGetList = 'actionsLogGetList',
  TicketsMembersCreate = 'ticketsMembersCreate',
  TicketsMembersUpdate = 'ticketsMembersUpdate',
  VideoCallRoomCreated = 'videoCallsRoomCreate',
  FileUpload = 'filesUpload',
  FilesCreateFolder = 'filesCreateFolder',
  // ProjectGetList = 'projectGetList',
  // SpacesGetList = 'spacesGetList',
}

export enum ActionObjects {
  Tickets = 'tickets',
  Threads = 'threads',
  CalendarEvents = 'calendar-events',
  Invite = 'invite',
  Sprints = 'sprints',
  Spaces = 'spaces',
  Projects = 'projects',
}

export interface ActionLog {
  _id: string;
  actionCrud?: string;
  actionObject?: string;
  actionObjectId?: string;
  actionOperationId?: string;
  getOperationId?: string;
  templateData?: string;
  object?: string;
  objectId?: string;
  tenantName?: string;
  ownerUserId?: string;
  url?: string;
  created_at?: string;
}

export const actionObjectCorrection = { spaces: 'space', projects: 'project', users: 'dash' };
