<div class="contact-us" [class.mobile]="(platform | async) !== 'web'" *transloco="let t; read: 'fields'">
  <div class="contact-us__header">
    <svg-icon [src]="closeSvg" (click)="handleCloseModal()"></svg-icon>
  </div>
  <div class="contact-us__body">
    <form class="contact-us__form" [formGroup]="contactUsForm" (ngSubmit)="handleSubmit()">
      <h3 class="contact-us__title">{{ t('contact-us') }}</h3>
      <div class="contact-us__fields">
        <app-input-text formControlName="first_name" [placeHolder]="t('placeholder-first-name')" [legend]="t('first-name')" [widthFull]="true" />
        <app-input-text formControlName="last_name" [placeHolder]="t('placeholder-last-name')" [legend]="t('last-name')" [widthFull]="true" />
        <app-input-text formControlName="email" [placeHolder]="t('placeholder-email')" [legend]="t('work-email')" [widthFull]="true" />
        <app-input-text formControlName="phone" [placeHolder]="t('placeholder-phone')" [legend]="t('phone')" [widthFull]="true" />
        <app-input-text formControlName="company" [placeHolder]="t('placeholder-company')" [legend]="t('company')" [widthFull]="true" />

        <app-input-selector
          [items]="departmentItems"
          bindLabel="label"
          bindValue="value"
          formControlName="department"
          [legend]="t('department')"
          [placeHolder]="t('placeholder-select')"
          [widthFull]="true"
        />

        <app-input-selector
          [items]="numberItems"
          bindLabel="label"
          bindValue="value"
          formControlName="number"
          [legend]="t('number-employees')"
          [placeHolder]="t('placeholder-select')"
          [widthFull]="true"
        />

        <app-input-textarea
          formControlName="more"
          [placeHolder]="t('placeholder-else')"
          [legend]="t('else')"
          [widthFull]="true"
          [isResize]="false"
          rows="4"
        />
      </div>

      <app-button typeButton="submit" label="Submit" [widthFull]="true" customClasses="padding-12" />
    </form>
  </div>
</div>
