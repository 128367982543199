<div [formGroup]="form" *ngIf="fieldConfig.isVisible">
  <legend>{{displayName}} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
  <fieldset class="form-group position-relative">
    <ng-select
      [id]="'ticket'+fieldConfig.title"
      class="labels-select"
      [class.multiple]="isMultiple"
      [multiple]="isMultiple"
      [placeholder]="placeholder"
      [items]="fieldConfig.formatMultiple"
      [searchable]="false"
      [closeOnSelect]="!isMultiple"
      [formControlName]="fieldConfig.title"
      [attr.data-tabindex]="tabIndexes[fieldConfig.order]?.tabIndex"
      (click)="setFocusedControl(tabIndexes[fieldConfig.order]?.tabIndex)"
    >
      <ng-template ng-label-tmp let-item="item" let-item$="item$" let-index="index" let-clear="clear">
        <span>{{ item }}</span>
        <span *ngIf="isMultiple" class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
    </ng-select>
  </fieldset>
</div>
