<div
	class="notes h-100"
  *transloco="let t"
	[ngClass]="{
    'notes-ios': platform === 'ios',
    'notes-android': platform === 'android'
  }"
>
	<div class="card m-0 notes-container h-100">
		<div class="notes-header">
			<div
				class="d-flex align-items-center justify-content-between"
				[ngClass]="{
          'notes-header-light': config?.layout.variant === 'Light',
          'notes-header-dark': config?.layout.variant === 'Dark'
        }"
			>
				<span class="notes-title flex-grow-1">{{ t('full-layout.notes-title') }}</span>
				<span
					class="badge notes-badge"
					[ngClass]="{
            'notes-badge-light': config?.layout.variant === 'Light',
            'notes-badge-dark': config?.layout.variant === 'Dark'
          }"
				>
          {{ notes?.length }}
        </span>

        <button *ngIf="sidebarConfig?.isOpened" class="btn btn-sm btn-text pin-notes ml-2" (click)="closeSidebar()">
          {{ t('full-layout.btn-close') }}
        </button>
			</div>

			<div class="notes-actions d-flex align-items-center justify-content-between">
				<ng-select
					t-id="notes-filter-by-labels"
					class="notes-select"
					[items]="labels"
					[placeholder]="t('full-layout.notes-select-placeholder')"
					bindLabel="label"
					bindValue="_id"
					[closeOnSelect]="false"
					[multiple]="true"
					[(ngModel)]="selectedLabels"
					(change)="filterNotesByLabels()"
				>
					<ng-template ng-multi-label-tmp let-items="items">
		        <span class="ng-placeholder d-flex align-items-center">
<!--			        Label<span *ngIf="getSelectedItemsCount(items, labels) > 1">s</span>-->
              {{
                t('full-layout.notes-select-option-placeholder', {
                  value: getSelectedItemsCount(items, labels) > 1 ? 's' : ''
                })
              }}
              <span class="primary-circle ml-1">
                {{ getSelectedItemsCount(items, labels) }} / {{labels.length}}
              </span>
		        </span>
					</ng-template>

					<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
						<input
							id="item-{{ index }}"
							type="checkbox"
							class="mr-2"
							[class.ml-3]="item.spaceId"
							[ngModel]="item$.selected"
						/>
						<span t-id="notes-list-labels">{{ item.label }}</span>
					</ng-template>
				</ng-select>

				<button
					*ngIf="!isMobile && (object + '::' + objectId + '::notesPost' | checkPermission)"
					type="button"
					t-id="notes-add-note-action"
					class="btn btn-solid notes-button d-flex align-items-center"
					(click)="addNote()"
				>
          <app-svg name="plus" width="11" height="11" class="mr-1"></app-svg>
          <span>{{ t('full-layout.btn-add-note') }}</span>
				</button>
			</div>
		</div>
    <div class="card-content card-content-notes height-notes" t-id="notes-list-item">
      <div class="card-body card-body-notes">
        <ng-scrollbar visibility="hover" thumbClass="white-scrollbars">
          <ul id="notes-list-group" class="list-group" [dragula]="notesContainer">
            <li
              *ngFor="let note of notes; let i = index"
              class="list-group-item list-group-item-action flex-column align-items-start cursor-pointer mb-1"
              [attr.data-note-id]="note._id"
              [class.is-draggable]="platform === 'web' && !note.linkObject"
            >
              <div id="note-{{ i }}" class="note-body">
                <div class="d-flex w-100 justify-content-between note-date align-items-start">
                  <small
                    [ngClass]="{
                      'note-time-light': config?.layout.variant === 'Light',
                      'note-time-dark': config?.layout.variant === 'Dark'
                    }"
                  >
                    {{ note.created_at | fromNow }}
                  </small>
                  <div
                    appTippy
                    [popoverContent]="dropdown"
                    [popoverPlacement]="'bottom-end'"
                    class="dropdown convert-note-dropdown"
                  >
                    <button
                      *ngIf="(note.title || note.text) && !note.linkObject"
                      class="btn btn-sm note-button"
                      placement="auto"
                      [ngbTooltip]="t('full-layout.convert-into-tooltip')"
                      [disableTooltip]="convertDropdownOpen || platform !== 'web'"
                    >
                      <app-svg name="arrow-right-arrow-left" width="18" height="16"></app-svg>
                    </button>
                  </div>
                  <div class="note-actions__menu" #dropdown [class.dark]="config?.layout.variant === 'Dark'">
                    <a
                      *ngFor="let item of convertIntoList"
                      href="javascript:;"
                      class="note-actions__menu-item d-flex align-items-center"
                      (click)="convertNote(item.action, note._id, note.title, note.text)"
                    >
                      <app-svg name="{{ item.icon }}" width="16" height="16"></app-svg>
                      <span class="ml-2">{{ item.title }}</span>
                    </a>
                  </div>
                </div>
                <div
                  id="note-text-{{ i }}"
                  class="pb-2 note-text"
                  [ngClass]="{
                    'note-text-light': config?.layout.variant === 'Light',
                    'note-text-dark': config?.layout.variant === 'Dark'
                  }"
                  (click)="viewNote(note)"
                >
                  <div class="note-container">
                    <div class="note-title-container" *ngIf="note.title">{{ note.title }}</div>
                    <div class="note-desc-container font-weight-light font-small-3" *ngIf="note.text"
                         [innerHtml]="note.text | safeHtml"></div>
                  </div>
                </div>
              </div>

              <div *ngIf="note.linkObject || isOverflown(i)"
                   class="d-flex align-items-center justify-content-between mt-2 badge-show-more-container">
                <!-- LINKED OBJECT BADGES -->
                <app-linked-object-badge
                  *ngIf="note.linkObject"
                  [object]="note.linkObjectData.object"
                  [objectId]="note.linkObjectData.objectId"
                  [message]="note"
                  [platform]="'web'"
                  [withView]="false"
                >
                </app-linked-object-badge>

                <div class="show-more-container show-more mt-1 ml-1">
                  <span *ngIf="isOverflown(i) && !showLessButton[i]" class="show-more-text" (click)="showMoreClicked(i)">
                    {{ t('full-layout.btn-show-more') }}
                  </span>
                  <span *ngIf="showLessButton[i]" class="show-more-text" (click)="showLessClicked(i)">
                    {{ t('full-layout.btn-show-less') }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </ng-scrollbar>
      </div>
    </div>
	</div>

  <!-- ADD NOTE BUTTON FOR MOBILE -->
  <div
    *ngIf="isMobile && (object + '::' + objectId + '::notesPost' | checkPermission)"
    class="add-fab-button add-note-fab-button"
    (click)="addNote()"
  >
    <app-svg name="plus" width="17" height="17" aria-hidden="true"></app-svg>
  </div>
  <!-- ADD NOTE BUTTON FOR MOBILE END -->
</div>
