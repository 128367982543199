import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';

import { DEFAULT_AVATARS, OBJECT_COLORS, PICKED_OBJECT_COLOR, InvitedMembers } from '../../shared/data/object-creation';
import { SpaceCreate } from '../../shared/store/actions/spaces.action';
import { ConfigService } from '../../shared/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthState } from '../../shared/store/states/auth.state';
import { ChatsGet } from '../../shared/store/actions/chats.action';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ConfirmAlert } from '../../shared/alerts/alerts';

@Component({
  selector: 'app-space-create-modal',
  templateUrl: './space-create.component.html',
  styleUrls: ['../project-create/project-create.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpaceCreateModalComponent implements OnInit, OnDestroy {
  @ViewChild('imageCropperModal') imageCropperModal;
  @ViewChild('avatarImageInput') avatarImageInput: ElementRef;
  @ViewChild('wizard') wizard: WizardComponent;
  @ViewChild('form_step1') form: NgForm;

  platform: string;
  config: any = {};
  spaceAvatarImage: any;
  avatarImagePreview: any;
  imageChangedEvent: any;
  newInvitedUsers: any;
  existingInvitedUsers: any;
  invitedUsersPreview: any;

  destroy$: Subject<void> = new Subject<void>();
  pickedSpaceColor = PICKED_OBJECT_COLOR;
  objectColors = OBJECT_COLORS;
  defaultAvatars = DEFAULT_AVATARS;
  cropperModal: NgbModalRef;
  stepInEditMode = false;
  isLoading: boolean;

  constructor(
    private actions: Actions,
    private modalsService: NgbModal,
    private store: Store,
    private toastr: ToastrService,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private http: HttpClient,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
    });
    this.getPlatform();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createSpace(form1: NgForm, form2: NgForm) {
    this.isLoading = true;
    const formData = { ...form1.value, ...form2.value };
    for (const i of Object.keys(formData)) {
      formData[i] = formData[i].trim();
      if (formData[i] === '') {
        formData[i] = null;
      }
    }
    this.store
      .dispatch(
        new SpaceCreate({
          body: formData,
          avatar: this.spaceAvatarImage,
          invitedUsers: { newUsers: this.newInvitedUsers, existingUsers: this.existingInvitedUsers },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            this.translocoService.translate('toastr.space-successfully-created'),
            this.translocoService.translate('toastr.title-success'),
          );

          this.store.dispatch(new ChatsGet());
          this.activeModal.close();
          this.deleteAvatarImage();
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  avatarChangeEvent(event: any): void {
    this.imageChangedEvent = event;

    if (this.imageChangedEvent?.target?.files[0]?.name) {
      this.cropperModal = this.modalsService.open(this.imageCropperModal, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'cropper-modal',
      });
    }
  }

  deleteAvatarImage() {
    this.spaceAvatarImage = undefined;
    this.avatarImagePreview = undefined;
    this.imageChangedEvent = undefined;
    this.avatarImageInput.nativeElement.value = '';
  }

  closeCropperModal() {
    this.cropperModal.close();
    this.avatarImageInput.nativeElement.value = '';
  }

  closeCreateSpaceModal() {
    if (this.form.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.btn-discard'),
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {

          }
        },
        () => {
          this.activeModal.close();
          this.deleteAvatarImage();
        },
      );
    } else {
      this.activeModal.close();
      this.deleteAvatarImage();
    }
  }

  setImagePreview(event) {
    this.avatarImagePreview = event;
  }

  setSpaceImageFile(file) {
    this.spaceAvatarImage = file;
  }

  setDefaultAvatar(url): void {
    this.avatarImagePreview = url;
    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      blob.arrayBuffer().then((res) => {
        this.spaceAvatarImage = new File([res], 'default avatar', { type: 'img/png' });
      });
    });
  }

  inviteSpaceMembers({ newUsers, existingUsers, invitedMembersList }: InvitedMembers, wizard: WizardComponent) {
    this.newInvitedUsers = newUsers;
    this.existingInvitedUsers = existingUsers;
    this.invitedUsersPreview = invitedMembersList;
    this.stepInEditMode = false;
    wizard.goToNextStep();
  }

  goToStep(index: number, wizard: WizardComponent): void {
    this.stepInEditMode = true;
    wizard.goToStep(index);
  }

  confirmEdit(wizard: WizardComponent): void {
    wizard.goToStep(3);
    this.stepInEditMode = false;
  }

  nextStep(wizard: WizardComponent) {
    wizard.goToNextStep();
  }

  previousStep(wizard: WizardComponent) {
    wizard.goToPreviousStep();
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
