import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { VideoCallsSetInvitedRoomId } from '../store/actions/video-calls.action';

@Injectable()
export class VideoCallResolver {
  constructor(private router: Router, private store: Store) {}

  public resolve(route: ActivatedRouteSnapshot): Promise<string> {
    return new Promise<string>((resolve) => {
      resolve('');
      const key = route.queryParams.key;

      this.store.dispatch(new VideoCallsSetInvitedRoomId(route.paramMap.get('id')));
      if (!key) {
        this.router.navigate(['']);
      }
    });
  }
}
