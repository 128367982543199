import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigService, ITemplateConfig } from '../../../../services/config.service';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements ControlValueAccessor {
  @Input() placeHolder: string = 'Type something';
  @Input() legend: string;
  @Input() customClasses: string;
  @Input() widthFull: boolean = false;

  public value = '';
  private config: ITemplateConfig;

  constructor(private readonly configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  // ControlValueAccessor
  public onChange: (value: string) => void;
  public onTouched: () => void;

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
