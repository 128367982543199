import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs/operators';

import { ChatsClearFilesList, ChatsGetFilesListPagination } from '../store/actions/chats.action';
import { ChatsState } from '../store/states/chats.state';
import { ChatFileSortBy, DataRoomSortBy, IChatFilesPaginationQuery, DataRoomPageType } from '../data/pagination';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class PaginationDummyService {
  private page = 1;
  private perPage = 20;
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: Observable<boolean> = this.isLoading.asObservable();
  private anchor: Element;
  private observer: IntersectionObserver;
  private options = { root: null };
  private object: string;
  private objectId: string;
  private chatFilesPaginationLastPage: boolean;
  private sort: string;
  private sortBy: string;
  private pageType: BehaviorSubject<DataRoomPageType> = new BehaviorSubject(null);
  private className = 'anchor';

  constructor(private store: Store) {
    this.store
      .select(ChatsState.getChatFilesPagination)
      .pipe(untilDestroyed(this), delay(500))
      .subscribe((chatFilesPagination) => {
        if (chatFilesPagination) {
          const {
            data: { isLastPage },
          } = chatFilesPagination;
          this.chatFilesPaginationLastPage = isLastPage;
          this.completeLoading();
        }
      });
  }

  onGetItems({ object, objectId, page, perPage, sortBy, sort }: IChatFilesPaginationQuery): Observable<any> {
    const pageType = this.getPageType();

    if (this.isLoading.getValue() || !object || !objectId || !pageType) {
      return;
    }

    this.object = object;
    this.objectId = objectId;
    this.page = page || this.page;
    this.perPage = perPage || this.perPage;
    this.sort = sort || this.sort;
    this.sortBy = sortBy || this.sortBy;
    if (pageType === DataRoomPageType.Chat && (!this.chatFilesPaginationLastPage || this.page === 1)) {
      this.isLoading.next(true);
      return this.store.dispatch(
        new ChatsGetFilesListPagination({
          object,
          objectId,
          page: this.page,
          perPage: this.perPage,
          sort: this.sort,
          sortBy: ChatFileSortBy[this.sortBy] || this.sortBy,
        }),
      );
    }
  }

  completeLoading(): void {
    this.page += 1;
    this.isLoading.next(false);
    this.setAnchor();
  }

  setAnchor(): void {
    const anchor = document.querySelector(`.${this.className}`);
    if (!anchor || (anchor && this.anchor)) {
      return;
    }

    this.observer?.disconnect();
    this.anchor = anchor;

    if (anchor) {
      this.observer = new IntersectionObserver(([entry]) => {
        entry.isIntersecting && this.scrolled();
      }, this.options);

      this.observer.observe(anchor);
    }
  }

  scrolled(): void {
    this.onGetItems({ object: this.object, objectId: this.objectId });
  }

  dataRoomSortBy(): typeof DataRoomSortBy {
    return DataRoomSortBy;
  }

  resetOptions(): void {
    this.page = 1;
    this.object = null;
    this.anchor = null;
    this.store.dispatch(new ChatsClearFilesList({}));
  }

  getClasName(): string {
    return this.className;
  }

  setPageType(type: DataRoomPageType): void {
    this.pageType.next(type);
  }

  getPageType(): DataRoomPageType {
    return this.pageType.getValue();
  }
}
