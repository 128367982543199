import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements AfterViewInit {
  @Input() appFocus: boolean;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    if (this.appFocus) {
      // set timeout is needed for the space-create modal
      setTimeout(() => {
        this.element.nativeElement.focus();
      }, 100);
    }
  }
}
