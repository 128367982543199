<div
  *ngIf="message.text && !message.webhookMessageData; else webhookData"
  id="message-webhook-{{message._id}}"
  appExternalLink
  class="message-text"
  [ngClass]="{
    'message-text-light': config?.layout.variant === 'Light',
    'message-text-dark': config?.layout.variant === 'Dark'
  }"
  [innerHtml]="message.text | safeHtml"
>
</div>

<ng-template #webhookData>
  <!-- github message -->
  <app-webhook-github-message
    *ngIf="webhookMessageData?.serviceType === WebhookServiceTypes.GITHUB"
    [layoutVariant]="config?.layout.variant"
    [webhookMessageData]="webhookMessageData"
  ></app-webhook-github-message>

  <!-- gitlab message -->
  <app-webhook-gitlab-message
    *ngIf="webhookMessageData?.serviceType === WebhookServiceTypes.GITLAB"
    [layoutVariant]="config?.layout.variant"
    [webhookMessageData]="webhookMessageData"
  ></app-webhook-gitlab-message>

  <app-webhook-bitbucket-message
    *ngIf="webhookMessageData?.serviceType === WebhookServiceTypes.BITBUCKET"
    [layoutVariant]="config?.layout.variant"
    [webhookMessageData]="webhookMessageData"
  ></app-webhook-bitbucket-message>

</ng-template>

