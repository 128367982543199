import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { RouterTenantPipe } from '../pipes/router-tenant.pipe';
import { ConfirmAlert } from '../alerts/alerts';
import { SocketsService } from './sockets.service';
import {
  SpaceDelete,
  SpaceLeave,
  SpaceToggleArchiveStatus,
  SpaceToggleChatMenuVisibility,
} from '../store/actions/spaces.action';
import { AuthState } from '../store/states/auth.state';
import { SpacesState } from '../store/states/spaces.state';
import { SpaceCreateModalComponent } from '../../modals/space-create/space-create.component';
import { SpaceUpdateModalComponent } from '../../modals/space-update/space-update.component';
import { ChatGroupCreateModalComponent } from '../../modals/chat-group-create/chat-group-create.component';
import { ChatGroupMembersModalComponent } from '../../modals/chat-group-members/chat-group-members.component';
import { ChatsGet, ChatsGetMembers } from '../store/actions/chats.action';
import { ChatIntegrationsModalComponent } from '../../modals/chat-integrations/chat-integrations.component';
import { SpaceDeleteComponent } from '../../modals/space-delete/space-delete.component';

@Injectable({
  providedIn: 'root',
})
export class SpaceService {
  constructor(
    private store: Store,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    protected socketsService: SocketsService,
    protected toastrService: ToastrService,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
  ) {}

  /**
   * Create space button click handler (emit open modal action)
   */
  createClick() {
    this.modalService.open(SpaceCreateModalComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: `creation-modal ${this.store.selectSnapshot(AuthState.getPlatform) !== 'web' ? 'mobile' : ''}`,
    });
  }

  /**
   * Update space button handler (emit show modal)
   */
  updateClick(objectId) {
    const modalRef = this.modalService.open(SpaceUpdateModalComponent, { size: 'lg' });
    modalRef.componentInstance.objectId = objectId;
  }

  /**
   * Unarchive space button handler (emit confirm alert)
   */

  unArchiveSpace(space) {
    ConfirmAlert(this.translocoService.translate('alert.space-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      confirmButtonClass: 'btn-solid',
      confirmButtonText: this.translocoService.translate('alert.btn-unarchive'),
      subject: this.translocoService.translate('alert.unarchive-space-subject'),
      text: '',
    }).then(
      () => {
        this.store.dispatch(new SpaceToggleArchiveStatus({ id: space._id })).subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.space-has-been-unarchived', { spaceName: space.spaceName }),
              this.translocoService.translate('toastr.title-success'),
              {
                enableHtml: true,
              },
            );

            this.store.dispatch(new ChatsGet());
          },
          (err) => {
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
      },
      () => {},
    );
  }

  /**
   * Delete space button handler (emit confirm alert)
   */
  deleteClick(id) {
    ConfirmAlert(this.translocoService.translate('alert.space-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
    }).then(
      () => {
        this.store.dispatch(new SpaceDelete({ id })).subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.space-successfully-deleted'),
              this.translocoService.translate('toastr.space-deleted-title'),
            );

            this.store.dispatch(new ChatsGet());

            this.router.navigate([this.routerTenantPipe.transform('dash')]);
          },
          (err) => {
            this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
      },
      () => {},
    );
  }

  /**
   * Leave space button handler (emit confirm alert)
   */

  leaveClick(space) {
    this.store.dispatch(new SpaceLeave({ id: space._id })).subscribe(
      () => {
        this.toastrService.success(
          this.translocoService.translate('toastr.you-leaved-from-space'),
          this.translocoService.translate('toastr.space-leave'),
        );

        this.store.dispatch(new ChatsGet());
      },
      (err) => {
        this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
      },
    );
  }

  /**
   * Delete space button handler (emit confirm alert) with Password
   */
  deletePasswordClick(space) {
    const modalRef = this.modalService.open(SpaceDeleteComponent, { size: 'md', centered: true });
    modalRef.componentInstance.SpaceData = {
      space: space,
    };
  }

  /**
   * Create group chat button handler (emit show modal)
   */
  chatGroupCreate(space, platform): void {
    const modalRef = this.modalService.open(ChatGroupCreateModalComponent, {
      size: 'md',
      windowClass: `create-chat-group-modal create-chat-group-modal--${platform}`,
    });
    modalRef.componentInstance.space = space;
  }

  manageGroupChatMembers(chatId, spaceId, platform = 'web'): void {
    this.store.dispatch(new ChatsGetMembers(chatId)).subscribe(() => {
      const modalRef = this.modalService.open(ChatGroupMembersModalComponent, {
        size: 'lg',
        windowClass: `chat-group-members-modal chat-group-members-modal--${platform}`,
      });
      modalRef.componentInstance.chatId = chatId;
      modalRef.componentInstance.spaceId = spaceId;
    });
  }

  deleteGroupChat(id) {
    ConfirmAlert(this.translocoService.translate('alert.group-chat-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
      text: this.translocoService.translate('alert.default-text'),
      confirmButtonText: this.translocoService.translate('alert.confirm-button'),
      subject: this.translocoService.translate('alert.default-subject', {
        value: this.translocoService.translate('alert.group-chat-title'),
      }),
    }).then(
      () => this.socketsService.get().emit('chats:deleteGroup', { id }),
      () => {},
    );
  }

  getContentVisibility(space): boolean {
    return this.store.selectSnapshot(SpacesState.getChatMenuSpaceVisibility)(space?._id);
  }

  getSpaceById(spaceId) {
    return this.store.selectSnapshot(SpacesState.getSpace)(spaceId);
  }

  toggleContentVisibility(space): void {
    this.store.dispatch(new SpaceToggleChatMenuVisibility(space));
  }

  manageGroupChatIntegrations(chatId: string, spaceId: string, chatName: string): void {
    const modalRef = this.modalService.open(ChatIntegrationsModalComponent, {
      size: 'lg',
      windowClass: 'chat-group-webhooks-modal',
    });
    modalRef.componentInstance.chatId = chatId;
    modalRef.componentInstance.spaceId = spaceId;
    modalRef.componentInstance.chatName = chatName;
    modalRef.componentInstance.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
