<ng-template #folderCreateModal let-close="close" *transloco="let t">
  <div class="modal-header">
    <h5 class="modal-title">{{ t('full-layout.btn-create-new-folder') }}</h5>
    <button type="button" class="close" (click)="closeCreateFolderModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <form action="#" class="form form-horizontal">
      <div class="form-body">
        <div class="form-group">
          <label>{{ t('full-layout.form-folder-name') }}</label>
          <input
            type="text"
            name="folder-name"
            required
            class="form-control"
            [(ngModel)]="newFolderName"
            [appFocus]="platform === 'web'"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-light': !isDarkTheme,
        'btn-dark': isDarkTheme
      }"
      (click)="closeCreateFolderModal()"
    >
      {{ t('full-layout.btn-cancel') }}
    </button>
    <button type="button" class="btn btn-solid" (click)="handleFolderCreateSubmit()" [disabled]="!newFolderName">
      {{ t('full-layout.btn-create') }}
      <i class="fa fa-spinner" aria-hidden="true" *ngIf="isLoading$ | async"></i>
    </button>
  </div>
</ng-template>

<div
  class="modal-header-v2"
  *transloco="let t"
  [ngClass]="{
    'modal-light': !isDarkTheme,
    'modal-header-dark': isDarkTheme
  }"
>
  <h2 class="d-flex flex-row mb-3">{{ t('full-layout.save-to-dataroom') }}</h2>
</div>

<div
  class="modal-body-v2"
  *transloco="let t"
  [ngClass]="{
    'modal-light': !isDarkTheme,
    'modal-dark': isDarkTheme
  }"
>
  <div class="position-relative mb-2 boards-wrapper">
    <div class="boards-buttons">
      <div class="no-padding position-relative w-100">
        <div class="btn-group gap-8-px w-100" role="group">
          <button
            *ngIf="(spaces | async)?.length > 0"
            type="button"
            t-id="data-room-select-space-to-save"
            class="btn object-btn object-btn-space"
            [ngClass]="{
              'selected-calendar-btn': object === 'spaces',
              'btn-solid': object === 'spaces',
              'btn-outline': object !== 'spaces'
            }"
            (click)="objectSelected(true)"
          >
            {{ t('full-layout.btn-select-space') }}
          </button>

          <button
            *ngIf="(projects | async)?.length > 0"
            type="button"
            t-id="data-room-select-project-to-save"
            class="btn object-btn object-btn-project"
            [ngClass]="{
              'selected-calendar-btn': object === 'projects',
              'btn-solid': object === 'projects',
              'btn-outline': object !== 'projects'
            }"
            (click)="objectSelected(false)"
          >
            {{ t('full-layout.btn-select-project') }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-group boards-select-wrapper my-3">
      <!-- SPACES NG-SELECT -->
      <label
        [class.d-none]="object !== 'spaces'"
        for="data-room-save-file-select-space"
      >
        {{ t('full-layout.form-select-space') }}
      </label>
      <ng-select
        [class.d-none]="object !== 'spaces'"
        t-id="data-room-save-file-select-space"
        id="data-room-save-file-select-space"
        class="calendar-dropdown"
        [placeholder]="t('full-layout.select-space-placeholder')"
        name="event-spaceId"
        bindLabel="spaceName"
        bindValue="_id"
        appendTo="body"
        [items]="spaces | async"
        [searchable]="platform === 'web'"
        [clearOnBackspace]="true"
        [attr.data-tabindex]="'tab-index-spaces'"
        [(ngModel)]="objectId"
        (change)="updateObjectsData('spaces', $event?._id)"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="sp-list-item">
            <app-space-avatar containerWidth='24' containerHeight="24" [space]="item" class="mr-1 sp-list-item-avatar"></app-space-avatar>
            <b>{{ item.spaceName }}</b>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="sp-list-item">
            <app-space-avatar containerWidth='24' containerHeight="24" [space]="item" class="mr-1 sp-list-item-avatar"></app-space-avatar>
            <b>{{ item.spaceName }}</b>
          </div>
        </ng-template>
      </ng-select>

      <!-- PROJECTS NG-SELECT -->
      <label
        [class.d-none]="object !== 'projects'"
        for="data-room-save-file-select-space"
      >
        {{ t('full-layout.form-select-project') }}
      </label>
      <ng-select
        [class.d-none]="object !== 'projects'"
        t-id="data-room-save-file-select-project"
        [placeholder]="t('full-layout.select-project-placeholder')"
        name="event-projectId"
        bindLabel="projectName"
        bindValue="_id"
        appendTo="body"
        [items]="projects | async"
        [searchable]="platform === 'web'"
        [attr.data-tabindex]="'tab-index-projects'"
        [(ngModel)]="objectId"
        (change)="updateObjectsData('projects', $event?._id)"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="sp-list-item">
	          <app-space-avatar containerWidth='24' containerHeight="24" [space]="item.space" class="mr-1 sp-list-item-avatar"></app-space-avatar>
            <app-project-avatar containerWidth='24' containerHeight="24" [project]="item" class="mr-1 sp-list-item-avatar"></app-project-avatar>
            <b>{{ item.projectName }}</b>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div class="sp-list-item">
	          <app-space-avatar containerWidth='24' containerHeight="24" [space]="item.space" class="mr-1 sp-list-item-avatar"></app-space-avatar>
            <app-project-avatar containerWidth='24' containerHeight="24" [project]="item" class="mr-1 sp-list-item-avatar"></app-project-avatar>
            <b>{{ item.projectName }}</b>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div>
    <label for="saveToDataroomFolder">{{ t('full-layout.form-folder') }}</label>
    <fieldset class="form-group position-relative">
      <ng-select
        t-id="save-to-dataroom-folder"
        id="saveToDataroomFolder"
        class="folder-select"
        bindLabel="name"
        bindValue="_id"
        [placeholder]="t('full-layout.folder-select-placeholder')"
        [items]="documents"
        [(ngModel)]="selectedDocument"
        [addTag]="addFolderPromise"
        [loading]="isLoading$ | async"
        [disabled]="isLoading$ | async"
        (change)="handleFolderChange($event)"
      >
        <ng-template ng-tag-tmp let-search="searchTerm">
          <b>{{ t('full-layout.folder-add-new') }}</b>: {{search}}
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ item.name }}
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
      </ng-select>
    </fieldset>
  </div>

  <div class="d-flex">
    <form
      action="#"
      class="form form-horizontal w-100"
    >
      <div class="form-body">
        <div class="form-group">
          <label>{{ t('full-layout.form-file-name') }}</label>
          <input
            t-id="dataroom-location-file-name"
            #input
            autocomplete="off"
            name="file-name"
            type="text"
            class="form-control"
            [placeholder]="t('full-layout.file-name-placeholder')"
            [(ngModel)]="newFileName"
          />
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer d-flex" *transloco="let t">
  <span class="flex-grow-1"></span>
  <span>
      <button
        type="button"
        class="btn mr-1 action-button"
        [ngClass]="{
          'btn-light': !isDarkTheme,
          'btn-dark': isDarkTheme
        }"
        (click)="closeModal()"
      >
        {{ t('full-layout.btn-cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-solid action-button"
        (click)="file?.screenRecord ? handleUploadSubmit() : handleSaveSubmit()"
        [disabled]="!newFileName"
        t-id="dataroom-location-save-action"
      >
        {{ t('full-layout.btn-save') }}
        <i class="fa fa-spinner" aria-hidden="true" *ngIf="isSavingFile"></i>
      </button>
    </span>
</div>
