import { Injectable, NgZone } from '@angular/core';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import LinkBlot from './LinkBlot';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlChangeModalComponent } from '../../../modals/url-change-modal/url-change-modal.component';
import QuillMarkdown from 'quilljs-markdown';
import ImageResize from 'quill-image-resize';

@Injectable({
  providedIn: 'root',
})
export class QuillInitializeService {
  static modalService: NgbModal;
  //  Creating of new links through the toolbar
  static quillToolbarLinkHandler({ editorInstance }) {
    editorInstance.focus();
    const range = editorInstance.getSelection();
    const modalRef = LinkBlot.modalService.open(UrlChangeModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.titleData.setValue(editorInstance.getText(range.index, range.length));

    modalRef.result
      .then((result) => {
        // tslint:disable-next-line:no-shadowed-variable
        const { index } = editorInstance.getSelection(true);
        // tslint:disable-next-line:no-shadowed-variable
        const nextIndex = index + result.title?.length || 0;
        // adding a whole new blob
        if (range.length === 0) {
          // just add the text first - doing it this way will
          //   help avoiding issues with text caret caused by contenteditable=true on the html element
          editorInstance.insertText(index, result.title);
          editorInstance.insertText(nextIndex, ' ');
          editorInstance.setSelection(index, result.title?.length);
        }
        editorInstance.format('link', { url: result.url, target: '_blank' }, Quill.sources.USER);
        editorInstance.setSelection(nextIndex + 1, 0);
      })
      .catch(() => {});
  }

  // backspace does not work well with contentEditable=true (like the link format)
  // where selection enters inside the element and then is unable to continue - delete the whole block instead when we reach it
  /**
   *  {
   *    format: ['link'],
   *    key: 'backspace',
   *  }
   * */
  static quillBackspaceFix = function (range, context) {
    if (range.index === 0 && range.length === 0) {
      return;
    }
    if (range.length === 0) {
      const size = context?.format?.link ? context.prefix.length : 1;
      this.quill.deleteText(range.index - size, size, Quill.sources.USER);
    } else {
      this.quill.deleteText(range, Quill.sources.USER);
    }
  };

  /**
   *  {
   *    collapsed: true,
   *    format: { list: false },
   *    key: 110, // dot key (or 190!)
   *    prefix: /^\s*?((?!(?:1)$)\d+)$/, // all numbers except '1'
   *  }
   * */
  static processDotKeyChange = function (range: any, ctx: any) {
    // cleanup the current line
    this.quill.deleteText(range.index - ctx.offset, ctx.offset);
    const selection = this.quill.getSelection(true);
    const listIndent = '      ';
    // Change the dot character to the following to bypass the automatic list item generation.
    // https://www.compart.com/en/unicode/U+2024
    const specialCharacter = '․';
    this.quill.insertText(selection, `${listIndent}${ctx.prefix.trim()}${specialCharacter}`);

    // Do not display the character entered by the user
    return false;
  };

  static handleEditorCreated(editorInstance): void {
    editorInstance.keyboard.addBinding(
      {
        collapsed: true,
        format: { list: false },
        key: 190, // dot key
        prefix: /^\s*?((?!(?:1)$)\d+)$/, // all numbers except '1'
      },
      QuillInitializeService.processDotKeyChange,
    );

    editorInstance.keyboard.addBinding(
      {
        collapsed: true,
        format: { list: false },
        key: 110, // dot key
        prefix: /^\s*?((?!(?:1)$)\d+)$/, // all numbers except '1'
      },
      QuillInitializeService.processDotKeyChange,
    );

    editorInstance.keyboard.addBinding(
      {
        format: ['link'],
        key: 'backspace',
      },
      QuillInitializeService.quillBackspaceFix,
    );

    const toolbar = editorInstance.getModule('toolbar');
    toolbar?.addHandler('link', () => {
      QuillInitializeService.quillToolbarLinkHandler({ editorInstance });
    });
  }

  constructor(private modalService: NgbModal, private ngZone: NgZone) {
    LinkBlot.ngZone = ngZone;
    LinkBlot.modalService = modalService;
    QuillInitializeService.modalService = modalService;
    Quill.register('formats/link', LinkBlot, true);
    Quill.register('modules/magicUrl', MagicUrl);
    Quill.register('modules/QuillMarkdown', QuillMarkdown, true);
    Quill.register('modules/imageResize', ImageResize);
  }
}
