import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { ConfigService } from '../../shared/services/config.service';
import { AuthState } from '../../shared/store/states/auth.state';
import { ArchiveType } from './enums/archive-type.enum';
import { SpaceToggleArchiveStatus } from '../../shared/store/actions/spaces.action';
import { ChatsGet } from '../../shared/store/actions/chats.action';
import { ToastrService } from 'ngx-toastr';
import { ProjectToggleArchiveStatus } from '../../shared/store/actions/projects.action';
import { SpaceDeleteComponent } from '../space-delete/space-delete.component';
import { ProjectService } from '../../shared/services/project.service';

export interface PageData {
  space?: {};
  project?: {};
  type: ArchiveType;
  delete?: boolean;
}
@Component({
  selector: 'app-archive-modal',
  templateUrl: './archive-modal.component.html',
  styleUrls: ['./archive-modal.component.scss'],
})
export class ArchiveModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  pageData: PageData;
  platform: string;
  space: any;
  project: any;
  title: string;
  titleObj: string;
  delete = false;
  type: string;
  config: any = {};
  constructor(
    private actions: Actions,
    private store: Store,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    protected toastrService: ToastrService,
    public modalService: NgbModal,
    public projectService: ProjectService,
    private translocoService: TranslocoService,
  ) {}

  public get handleTextModal(): string {
    return this.type === ArchiveType.Space
      ? this.translocoService.translate('data-room.space')
      : this.translocoService.translate('data-room.project');
  }

  public get handleTypeModal(): boolean {
    return this.type === ArchiveType.Space;
  }

  ngOnInit(): void {
    this.config = this.configService.templateConf;
    this.initModalData(this.pageData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(successful = false) {
    this.activeModal.close(successful);
  }

  handleArchive() {
    this.store.dispatch(new SpaceToggleArchiveStatus({ id: this.space._id })).subscribe(
      () => {
        this.toastrService.success(
          this.translocoService.translate('toastr.space-has-been-unarchived', { spaceName: this.space?.spaceName }),
          this.translocoService.translate('toastr.title-success'),
          {
            enableHtml: true,
          },
        );

        this.store.dispatch(new ChatsGet());

        this.close();
      },
      (err) => {
        this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
      },
    );
  }

  handleArchiveProject() {
    this.store.dispatch(new ProjectToggleArchiveStatus({ id: this.project._id })).subscribe(
      () => {
        this.toastrService.success(
          this.translocoService.translate('toastr.project-has-been-unarchived', {
            projectName: this.project?.projectName,
          }),
          this.translocoService.translate('toastr.title-success'),
          {
            enableHtml: true,
          },
        );

        this.store.dispatch(new ChatsGet());

        this.close(true);
      },
      (err) => {
        this.toastrService.error(err.message, this.translocoService.translate('toastr.title-error'));
      },
    );
  }

  handleDelete() {
    if (this.type === 'space') {
      const modalRef = this.modalService.open(SpaceDeleteComponent, { size: 'md', centered: true });
      modalRef.componentInstance.SpaceData = {
        space: this.space,
      };
      this.activeModal.close();
    } else {
      const modalRef = this.modalService.open(SpaceDeleteComponent, { size: 'md', centered: true });
      modalRef.componentInstance.SpaceData = {
        project: this.project,
      };
      this.activeModal.close();
    }
  }

  initModalData(data: PageData): void {
    this.space = data.space;
    this.project = data.project;
    this.type = data.type;
    this.delete = data.delete;
    this.title =
      this.delete && this.type === ArchiveType.Space
        ? this.translocoService.translate('modals.archive.delete-space')
        : this.delete
        ? this.translocoService.translate('modals.archive.delete-project')
        : this.type === ArchiveType.Space
        ? this.translocoService.translate('modals.archive.archive-space', { value: this.space.spaceName })
        : this.translocoService.translate('modals.archive.archive-project', { value: this.project.projectName });
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);

    this.titleObj = `<span>${this.space ? this.space.spaceName : this.project.projectName}</span>`;
  }
}
