import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersDbDto } from '../../api/models';
import { SocketsService } from '../../shared/services/sockets.service';
import { ChatsPrivateMessagesIsOpening } from '../../shared/store/actions/chats.action';
import { GetUsersListByTenant } from '../../shared/store/actions/users.action';
import { UsersState } from '../../shared/store/states/users.state';
import { AuthState } from '../../shared/store/states/auth.state';

@Component({
  selector: 'app-tenant-members-modal',
  templateUrl: './tenant-members.component.html',
  styleUrls: ['./tenant-members.component.scss'],
})
export class TenantMembersComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  platform: string;
  allUsers: UsersDbDto[];

  constructor(
    private actions: Actions,
    private store: Store,
    private socketsService: SocketsService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);

    this.actions.pipe(takeUntil(this.destroy$), ofActionSuccessful(GetUsersListByTenant)).subscribe(() => {
      this.store
        .select(UsersState.getTenantUsers)
        .pipe(
          takeUntil(this.destroy$),
          map((items) => {
            const members = [...items];
            return members.length > 0 ? members.sort((a, b) => a.userName.localeCompare(b.userName)) : members;
          }),
        )
        .subscribe((users) => {
          const user = this.store.selectSnapshot(AuthState.getUser);
          this.allUsers = users.filter((item) => item._id !== user._id);
          this.cdr.detectChanges();
        });
    });

    this.store.dispatch(new GetUsersListByTenant());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Close modal handler
   */
  close() {
    this.activeModal.close();
  }

  viewDirectChat(userId): void {
    this.store.dispatch(new ChatsPrivateMessagesIsOpening(true));
    this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
    this.close();
  }
}
