import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../payment.service';
import { IPlan } from '../../payment.const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService, ITemplateConfig } from '../../../../shared/services/config.service';
import { Store } from '@ngxs/store';
import { PaymentState } from '../../../../shared/store/states/payment.state';
import { PaymentsAmountResDto } from '../../../../api/models/payments-amount-res-dto';
import { BillingDetailsCountryResDto } from '../../../../api/models/billing-details-country-res-dto';
import { BillingDetailsDbDto } from '../../../../api/models/billing-details-db-dto';
import * as moment from 'moment';
import { BillingCreate, BillingUpdate, SubscribeSet } from '../../../../shared/store/actions/payment.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BillingDetailsResDto } from '../../../../api/models/billing-details-res-dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-detail-step',
  templateUrl: './payment-detail-step.component.html',
  styleUrls: ['./payment-detail-step.component.scss'],
})
export class PaymentDetailStepComponent implements OnInit {
  public date: string;
  public isLoading = false;
  public currentPlan: IPlan;
  public paymentAmount: PaymentsAmountResDto;
  public billingDetails: BillingDetailsResDto | BillingDetailsDbDto;
  public countries: BillingDetailsCountryResDto[];
  public pinForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: ['', Validators.required],
    address: ['', Validators.required],
    city: ['', Validators.required],
    postalCode: ['', Validators.required],
    countryId: ['', Validators.required],
    vatId: ['', Validators.required],
    billingEmail: ['', Validators.required],
  });

  private config: ITemplateConfig;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly fb: FormBuilder,
    private readonly configService: ConfigService,
    private readonly store: Store,
    private readonly toast: ToastrService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initData();
  }

  public get isValid(): boolean {
    return this.pinForm.valid;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public submitForm(): void {
    const data = {
      ...this.pinForm.value,
      countryId: this.pinForm.controls.countryId.value.id,
    };

    this.isLoading = true;
    this.store
      .dispatch(
        this.billingDetails ? new BillingUpdate({ id: this.billingDetails._id, body: data }) : new BillingCreate(data),
      )
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.isLoading = false;
          return of(null);
        }),
      )
      .subscribe(() => {
        this.store
          .dispatch(new SubscribeSet({ subscriptionType: this.paymentService.typeOfPayment }))
          .pipe(
            untilDestroyed(this),
            finalize(() => (this.isLoading = false)),
          )
          .subscribe(() => {
            this.toast.success('Subscribe has been added', 'Success');
            this.paymentService.handleClose();

            // TODO: Set iframe step
          });
      });
  }

  private transformDate(): string {
    const type = this.paymentService.typeOfPayment;
    const isMonthly = type === 'monthly';
    const currentDate = moment();
    const endOfMonth = isMonthly ? moment().endOf('month') : moment().add(1, 'year');
    return `${currentDate.format(isMonthly ? 'DD.MM' : 'DD.MM.YYYY')}-${endOfMonth.format(
      isMonthly ? 'DD.MM' : 'DD.MM.YYYY',
    )}`;
  }

  private initData(): void {
    this.currentPlan = this.paymentService.selectedPlan;

    this.paymentAmount = this.store.selectSnapshot(PaymentState.getPaymentAmount);
    this.billingDetails = this.store.selectSnapshot(PaymentState.getBillingDetails);
    this.countries = this.store.selectSnapshot(PaymentState.getCountries);
    if (this.billingDetails) {
      this.pinForm.patchValue({
        firstName: this.billingDetails.firstName,
        lastName: this.billingDetails.lastName,
        companyName: this.billingDetails.companyName,
        address: this.billingDetails.address,
        city: this.billingDetails.city,
        postalCode: this.billingDetails.postalCode,
        countryId: this.billingDetails.countryId,
        vatId: this.billingDetails.vatId,
        billingEmail: this.billingDetails.billingEmail,
      });
    }

    this.date = this.transformDate();
  }
}
