<ng-container *transloco="let t; read: 'modals.user-delete'">
  <div class="modal-header d-flex align-items-center">
    <app-svg
      t-id="page-details-title-action"
      class="cursor-pointer cancel-icon svg-icon"
      name="times"
      width="10"
      height="10"
      aria-label="Close"
      (click)="close()"
    ></app-svg>
  </div>

  <div class="modal-body page-delete" [class.layout-dark-archive]="isDarkTheme">
    <h3 class="page-delete__title">{{ t('title-account-deletion') }}</h3>
    <div class="page-delete__description">{{ t('description-account-deletion') }}</div>
    <div class="page-delete__selectUser w-100" *ngIf="showSelectTransfer">
      <label for="selectUsersTenant">{{ t('form-user') }}</label>
      <ng-select
        t-id="select-user"
        id="selectUsersTenant"
        class="mb-1"
        bindLabel="userName"
        bindValue="_id"
        [placeholder]="t('select-user-placeholder')"
        appendTo="body"
        [items]="users"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        [formControl]="userExtend"
      >
        <ng-template ng-label-tmp let-item="item" id="selectUserTenant">
          <div class="d-flex align-items-center">
            <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
            <div class="ml-1 page-delete__userName">
              {{ item.userName }}
            </div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" id="select-user-option">
          <div class="d-flex align-items-center" t-id="select-user-select">
            <app-avatar [userId]="item._id" height="24" width="24" statusSize="9"></app-avatar>
            <div class="ml-2 page-delete__userName">
              {{ item.userName }}
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="page-delete__password">
      <label for="passwordSpace">{{ t('form-password') }}</label>
      <input
        t-id="password-space"
        id="passwordSpace"
        [placeholder]="t('enter-password-placeholder')"
        [formControl]="passwordUser"
        tabindex="1"
        type="password"
        required
        class="form-control"
      >
    </div>
    <div class="page-delete__button">
      <button class="btn btn-subtle" type="button" (click)="close()">{{ t('btn-cancel') }}</button>
      <button [disabled]="isUploading || (!userExtend.valid && showSelectTransfer) || !passwordUser.valid" class="btn btn-solid btn-save" type="button" (click)="confirm()">
        <i *ngIf="isUploading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
        {{ isUploading ? '' : t('btn-delete-account') }}
      </button>
    </div>
  </div>
</ng-container>
