import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';
import { GenericMessageComponent } from '../generic-message/generic-message.component';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { FilesHelper } from '../../../../utils/files-helper';
import { ConfigService, ITemplateConfig } from '../../../../services/config.service';
import { WebhookIntegrationServiceType, WebhookMessageData } from '../../../../interfaces/webhook-message-data';

@Component({
  selector: 'app-webhook-message',
  templateUrl: './webhook-message.component.html',
  styleUrls: ['./webhook-message.component.scss'],
  providers: [SafeHtmlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookMessageComponent extends GenericMessageComponent implements OnInit {
  public config: ITemplateConfig;

  public WebhookServiceTypes = WebhookIntegrationServiceType;

  constructor(cdr: ChangeDetectorRef, configService: ConfigService, store: Store, public filesHelper: FilesHelper) {
    super(cdr, filesHelper, configService, store);
  }

  ngOnInit(): void {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  public get webhookMessageData(): WebhookMessageData {
    return this.message?.webhookMessageData;
  }
}
