import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { SlideUpToggleAnimation } from './utility/bottom-sheet-animation';
import { AngularBottomSheetConfig } from './utility/bottom-sheet-interface';
import { animateChild, query, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  animations: [
    SlideUpToggleAnimation,
    trigger('container', [transition(':enter, :leave', [query('@*', animateChild(), { optional: true })])]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent {
  @Input() options: AngularBottomSheetConfig;
  @Input() isFullSize = false;
  @Output() close = new EventEmitter();
  @Output() toggleSize = new EventEmitter();
  nativeElement: HTMLElement;

  constructor(public element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }
}
