import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-webhook-job-status',
  templateUrl: './webhook-job-status.component.html',
  styleUrls: ['./webhook-job-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookJobStatusComponent {
  @Input() state: string;

  @Input() jobType: string;

  @Input() pipeName: string;

  @Input() pipeUrl: string;

  @Input() repoFullName: string;

  @Input() isFailed: boolean;
}
