import { Component, OnInit } from '@angular/core';
import { AttributeEnum, IPlan, plans } from '../../payment.const';
import { TVariant, VariantEnum } from '../../../../shared/components/ui-elements/button/button.component';
import { ConfigService, ITemplateConfig } from '../../../../shared/services/config.service';
import { SubscriptionsTypesResDto } from '../../../../api/models/subscriptions-types-res-dto';
import { Store } from '@ngxs/store';
import { PaymentState } from '../../../../shared/store/states/payment.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaymentService } from '../../payment.service';

export type TDate = 'monthly' | 'yearly';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-choice-step',
  templateUrl: './payment-choice-step.component.html',
  styleUrls: ['./payment-choice-step.component.scss'],
})
export class PaymentChoiceStepComponent implements OnInit {
  public isLoading = true;
  public currentPlan: IPlan;
  public plans: IPlan[] = plans;
  public activeDate: TDate = 'monthly';
  public currentPlanAttribute = 'free';
  public basicType: SubscriptionsTypesResDto[] = [];

  private config: ITemplateConfig;

  constructor(
    private readonly configService: ConfigService,
    private readonly store: Store,
    private readonly paymentService: PaymentService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.initData();
  }

  public get isFreeCurrentPlan(): boolean {
    return this.currentPlan.isCurrentPlan && this.currentPlan.attribute === AttributeEnum.Free;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get isChoiceMonthly(): TVariant {
    return this.activeDate === 'monthly' ? VariantEnum.Primary : VariantEnum.Secondary;
  }

  public get isChoiceYearly(): TVariant {
    return this.activeDate === 'yearly' ? VariantEnum.Primary : VariantEnum.Secondary;
  }

  public get basicPlan(): number {
    return this.plans.findIndex((plan) => plan.attribute === AttributeEnum.Basic);
  }

  public currentIndexPlan(attribute: string): number {
    return this.plans.findIndex((plan) => plan.attribute === attribute);
  }

  public handleChoiceMonthly(): void {
    this.activeDate = 'monthly';
    this.paymentService.typePayment(this.activeDate);
    this.changeType();
  }
  public handleChoiceYearly(): void {
    this.activeDate = 'yearly';
    this.paymentService.typePayment(this.activeDate);
    this.changeType();
  }

  private changeType(): void {
    if (this.basicPlan !== -1 && this.basicType.length) {
      const basicPlan = this.plans[this.basicPlan];
      basicPlan.price = this.basicType.find(
        (type) => basicPlan.attribute === type.name && type.type === this.activeDate,
      )?.price;
    }
  }

  private initData(): void {
    // SET CURRENT PLAN
    const currentSubscription = this.store.selectSnapshot(PaymentState.getSubscription);
    const currentIndex = this.currentIndexPlan(currentSubscription.plan);
    this.plans.forEach((plan, index) => {
      plan.isCurrentPlan = index === currentIndex;
    });

    this.currentPlan = this.plans[currentIndex];
    this.currentPlanAttribute = this.currentPlan?.attribute;

    // SET LOADER
    this.isLoading = true;

    // SUBSCRIBE TO AMOUNT MONTHLY/YEARLY
    this.store
      .select(PaymentState.getSubscriptionType)
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.basicType = value;

        if (this.basicType.length) {
          this.isLoading = false;
        }

        this.changeType();
      });
  }
}
