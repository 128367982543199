import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { RolesStateModel } from '../models/RolesState';
import { RolesGet, RoleMembersUpdate } from '../actions/roles.action';
import { RolesService } from '../../../api/services/roles.service';

@State<RolesStateModel>({
  name: 'Roles',
  defaults: {
    roles: [],
  },
})
@Injectable()
export class RolesState {
  /**
   * get roles
   * @param  {RolesStateModel} state
   */
  @Selector()
  static getRoles(state: RolesStateModel) {
    return state.roles;
  }

  constructor(private rolesService: RolesService, private store: Store) {}

  /**
   * Get roles action handler
   * @param  {patchState}: StateContext<RolesStateModel>
   * @param  {RolesGet} action
   */
  @Action(RolesGet)
  roles_get({ patchState }: StateContext<RolesStateModel>, action: RolesGet) {
    return this.rolesService.roleGetList({}).pipe(
      tap(
        (result) => {
          patchState({ roles: result });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update user role action handler
   * @param  {getState, setState, patchState}: StateContext<RolesStateModel>
   * @param  {RoleMembersUpdate} action
   */
  @Action(RoleMembersUpdate)
  role_members_update({ getState, setState, patchState }: StateContext<RolesStateModel>, action: RoleMembersUpdate) {
    return this.rolesService.roleMemberUpdate(action.payload).pipe(
      tap(
        (result) => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
