import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FoldersDbDto } from '../../../../../api/models/folders-db-dto';
import { FilesDbDto } from '../../../../../api/models/files-db-dto';
import { LinksDbDto } from '../../../../../api/models/links-db-dto';
import { ImagePath } from '../../enums/image-path';
import { ClickType } from '../../enums/file-type.enum';
import { DataRoomUtiliesService } from '../../services/data-room-utilies.service';
import { DocumentsDbDto } from '../../../../../api/models/documents-db-dto';
import { TreeType } from '../../documents.component';

type Document = FoldersDbDto | FilesDbDto | LinksDbDto | DocumentsDbDto;
@Component({
  selector: 'app-data-room-grid',
  templateUrl: './data-room-grid.component.html',
  styleUrls: ['./data-room-grid.component.scss'],
})
export class DataRoomGridComponent implements OnInit, OnChanges {
  @Input() documents: Document[] = [];
  @Input() object: string;
  @Input() objectId: string;
  @Input() isMobile = false;
  @Input() platform: string;
  @Input() currRootFolder?: TreeType;
  @Output() viewClicked = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @Output() downloadFile = new EventEmitter();

  public folders: Document[] = [];
  public files: Document[] = [];
  public isLoaded = false;

  constructor(public dataRoomUtilies: DataRoomUtiliesService) {}

  ngOnInit(): void {
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documents) {
      this.initializeData();
    }
  }

  // Getters
  public getFolderIcon(document): string {
    return document.type === 'private' ? ImagePath.PrivateIcon : ImagePath.PublicIcon;
  }

  public clickedFile(file): void {
    this.viewClicked.emit(file);
  }

  public handleClick(clickType: string, file) {
    if (clickType === ClickType.SingleClick) {
      this.clickedFile(file);
    } else if (clickType === ClickType.DoubleClick) {
      // this.clickedFile(file);
    }
  }

  public removeFileData(file): void {
    this.removeFile.emit(file);
  }

  public downloadFileEmit(document): void {
    this.downloadFile.emit(document);
  }

  private initializeData(): void {
    this.folders = this.documents.filter(
      (document: any) => !document.originalFileName && !document.link && !document.content,
    );
    this.files = this.documents.filter(
      (document: any) => document.originalFileName || document.link || document.content,
    );
  }
}
