import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NotesStateModel } from '../models/NotesState';
import {
  NoteCreate,
  NotesGet,
  NoteDelete,
  NoteUpdate,
  NotesLabelCreate,
  NotesLabelsGet,
} from '../actions/notes.action';
import { NotesService } from '../../../api/services/notes.service';

@State<NotesStateModel>({
  name: 'Notes',
  defaults: {
    object: null,
    objectId: null,
    notes: [],
    labels: [],
    lastCreatedLabel: null,
  },
})
@Injectable()
export class NotesState {
  /**
   * get notes
   * @param  {NotesStateModel} state
   */
  @Selector()
  static getNotes(state: NotesStateModel) {
    return state.notes;
  }

  /**
   * get notes labels
   * @param  {NotesStateModel} state
   */
  @Selector()
  static getNotesLabels(state: NotesStateModel) {
    return state.labels;
  }

  constructor(private notesService: NotesService, private store: Store) {}

  /**
   * Create notes action handler
   * @param  {getState, patchState}: StateContext<NotesStateModel>
   * @param  {NoteCreate} action
   */
  @Action(NoteCreate)
  note_create({ getState, patchState }: StateContext<NotesStateModel>, action: NoteCreate) {
    return this.notesService.notesPost({ body: action.payload }).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            notes: [...state.notes, result],
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get notes action handler
   * @param  {patchState}: StateContext<NotesStateModel>
   * @param  {NotesGet} action
   */
  @Action(NotesGet)
  notes_get({ patchState }: StateContext<NotesStateModel>, action: NotesGet) {
    patchState({ notes: [] });
    return this.notesService.notesGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            notes: result,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update notes action handler
   * @param  {getState, patchState}: StateContext<NotesStateModel>
   * @param  {NoteUpdate} action
   */
  @Action(NoteUpdate)
  note_update({ getState, patchState }: StateContext<NotesStateModel>, action: NoteUpdate) {
    return this.notesService.notesUpdate({ id: action.payload.id, body: action.payload.body }).pipe(
      tap(
        (result) => {
          const notes = this.deepClone(getState().notes);
          for (const note of notes) {
            if (note._id === action.payload.id) {
              note.title = result['title'];
              note.text = result['text'];
              note.labels = result['labels'];
            }
          }
          patchState({ notes });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete notes action handler
   * @param  {getState, patchState}: StateContext<NotesStateModel>
   * @param  {NoteDelete} action
   */
  @Action(NoteDelete)
  note_delete({ getState, patchState }: StateContext<NotesStateModel>, action: NoteDelete) {
    return this.notesService.notesDelete(action.payload).pipe(
      tap(
        (result) => {
          const notes = this.deepClone(getState().notes);
          const filteredNotes = notes.filter((note) => note._id !== action.payload.id);

          patchState({
            notes: [...filteredNotes],
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create notes label action handler
   * @param  {getState, patchState}: StateContext<NotesStateModel>
   * @param  {NotesLabelCreate} action
   */
  @Action(NotesLabelCreate)
  notes_label_create({ getState, patchState }: StateContext<NotesStateModel>, action: NotesLabelCreate) {
    return this.notesService.notesLabelsCreate({ body: action.payload }).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            labels: [...state.labels, result],
            lastCreatedLabel: result,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get notes labels action handler
   * @param  {patchState}: StateContext<NotesStateModel>
   * @param  {NotesLabelsGet} action
   */
  @Action(NotesLabelsGet)
  notes_labels_get({ patchState }: StateContext<NotesStateModel>, action: NotesLabelsGet) {
    patchState({ labels: [] });

    return this.notesService.notesLabelsGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            labels: result,
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  private deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }
}
