<ng-container *transloco="let t; read: 'modals.board-ticket'">
  <div class="d-flex align-items-center justify-content-between">
    <legend class="mb-0">{{ t('checklist-title') }}</legend>
    <button
      *ngIf="isCreateChecklist"
      t-id="checklist-dot-menu"
      type="button"
      class="btn dropdown d-flex align-items-center p-0"
      ngbDropdown
      [placement]="platform !== 'web' ? 'left' : 'bottom'"
      container="null"
      [autoClose]="true"
    >
      <app-svg name="ellipsis" width="17" height="4" [hoverEffect]="true" class="dropdown-toggle cursor-pointer" ngbDropdownToggle></app-svg>
      <div ngbDropdownMenu class="dropdown-menu">
        <a href="javascript:;" class="dropdown-item"
           t-id="checklist-delete-checklist"
           (click)="deleteChecklist()"
        >
          <span>{{ t('checklist-delete') }}</span>
        </a>
      </div>
    </button>
  </div>
  <button
    *ngIf="!isCreateChecklist"
    t-id="board-ticket-add-checklist"
    class="d-flex align-items-center btn btn-sm btn-subtle add-sub-task mt-2"
    type="button"
    (click)="createChecklist()"
  >
    <app-svg name="plus" width="9" height="9" class="mr-1"></app-svg>
    <span>{{ t('checklist-create') }}</span>
  </button>
  <div *ngIf="isCreateChecklist" class="checklist-content">
    <div class="checklist-progress">
      <div class="d-flex align-items-center">
        <app-svg name="checkbox" fill="none" width="18" height="18"></app-svg>
        <div class="checklist-progress-number" t-id="checklist-progress-bar-number">{{progressBar}}%</div>
      </div>
      <app-progress-bar [progress]="progressBar" class="checklist-progress-bar"></app-progress-bar>
    </div>
    <div
      class="checklist-items"
      [class.checklist__drag]="dragStyle"
      cdkDropList
      (cdkDropListDropped)="dropItemChecklist($event)"
      [class.checklist-items-dark]="config?.layout.variant === 'Dark'"
    >
      <div class="checklist-item"
           *ngFor="let item of checklistItem"
           cdkDrag [cdkDragData]="item"
           [cdkDragStartDelay]="50"
           [class.checklist-item-dark]="config?.layout.variant === 'Dark'"
      >
        <app-checklist-item [item]="item" class="checklist-item-container" [ticket]="ticket"></app-checklist-item>
      </div>
      <div class="checklist-item checklist-item-add" *ngIf="isCreateChecklistItem">
        <div class="checklist-item-wrapper">
          <input
            id="checklist-checkbox-edit"
            class="mr-2 checklist-checkbox"
            type="checkbox"
            disabled
          />
          <input type="text" class="checklist-create-item" [placeholder]="t('checklist-placeholder')" [formControl]="newItem" (keydown.escape)="clearItem($event)" (keydown.enter)="addItem($event)" required [autofocus]="true" t-id="checklist-checkbox-input-name"/>
        </div>
        <div class="checklist-item-add-list">
          <div class="checklist-item-add-button">
            <button [disabled]="isUploading" t-id="checklist-item-create-action" class="btn btn-solid btn-save" (click)="addItem($event)">
              <i *ngIf="isUploading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
              {{ isUploading ? '' : t('checklist-add') }}
            </button>
            <button class="btn btn-subtle" type="button" (click)="cancelCreate()">{{ t('checklist-cancel') }}</button>
          </div>
          <div class="editor-btn emoji-picker-icon" (click)="emojiPickerToggle()">
            <app-svg name="face-smile" width="16" height="16" aria-hidden="true"></app-svg>
          </div>
          <emoji-mart
            *ngIf="isOpenEmojiPicker"
            class="webhook-emoji-picker"
            title=""
            emoji=""
            [autoFocus]="true"
            [isNative]="true"
            [backgroundImageFn]="emojiPickerImageFn"
            (emojiSelect)="addEmoji($event)"
            (clickOutside)="closeEmojiPicker()"
          ></emoji-mart>
        </div>
      </div>
    </div>
    <button
      *ngIf="!isCreateChecklistItem"
      t-id="checklist-add-item"
      class="d-flex align-items-center btn btn-sm btn-subtle add-sub-task mt-1"
      type="button"
      (click)="createChecklistItem()"
    >
      <app-svg name="plus" width="9" height="9" class="mr-1"></app-svg>
      <span>{{ t('checklist-add-item') }}</span>
    </button>
  </div>
</ng-container>
