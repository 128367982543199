import { EventEmitter, Component, Input, Output } from '@angular/core';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-chat-message-details',
  templateUrl: './chat-message-details.component.html',
  styleUrls: ['./chat-message-details.component.scss'],
})
export class ChatMessageDetailsComponent {
  @Input() message: any;
  @Input() tz = null;

  config: any = {};

  constructor(private configService: ConfigService) {
    this.config = this.configService.templateConf;
  }
}
